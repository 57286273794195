<div class="header papers-border-bottom">
  <div class="btn-toolbar">

    <!-- Based on filter menu -->
    <div class="btn-group me-1" ngbDropdown placement="bottom-left" container="body" *ngIf="collections?.length > 1 || collections === null">
      <button type="button" class="btn btn-secondary" ngbDropdownToggle>
        <fa-icon [icon]="['fal', 'box']"></fa-icon> Library
      </button>

      <div class="dropdown-menu dropdown-menu-left papers-scrollbar" ngbDropdownMenu>
        <ng-container *ngFor="let collection of collections | sort:'name'">
          <button type="button" class="dropdown-item"
            [routerLink]="['/search', 'recommend', collection.id]"
            routerLinkActive="checked">{{collection.name || 'My Papers'}}</button>
            <div class="dropdown-divider" *ngIf="!collection.shared"></div>
        </ng-container>
      </div>
    </div>

    <div class="btn-group me-1" ngbDropdown placement="bottom-left" container="body" *ngIf="lists?.length > 0 || lists === null">
      <button type="button" class="btn btn-secondary" ngbDropdownToggle>
        <fa-icon [icon]="['fal', 'list']"></fa-icon> List
      </button>

      <div class="dropdown-menu dropdown-menu-left papers-scrollbar" ngbDropdownMenu>
        <button type="button" class="dropdown-item"
          [routerLink]="[]"
          [queryParams]="{list_id: ''}"
          queryParamsHandling="merge"
          routerLinkActive="checked"
          [ngClass]="{ 'checked': !route.snapshot.queryParams['list_id'] }">Entire Library
        </button>
        <div class="dropdown-divider"></div>
        <button type="button" class="dropdown-item"
          *ngFor="let list of lists | sort:'name'"
          [routerLink]="[]"
          [queryParams]="{list_id: list.id}"
          queryParamsHandling="merge"
          routerLinkActive="checked">{{list.name}}
        </button>
      </div>
    </div>

    <!-- Publicatioon date filter menu -->
    <div class="btn-group me-1" ngbDropdown placement="bottom-left" container="body">
      <button type="button" class="btn btn-secondary" ngbDropdownToggle>
        <fa-icon [icon]="['fal', 'calendar']"></fa-icon> Publication date
      </button>

      <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
        <button type="button" class="dropdown-item"
          *ngFor="let o of fromDateOptions"
          [routerLink]="[]"
          [queryParams]="{from_date: o.date}"
          queryParamsHandling="merge"
          routerLinkActive="checked">{{o.label}}
        </button>
        <button type="button" class="dropdown-item"
          [routerLink]="[]"
          [queryParams]="{from_date: ''}"
          queryParamsHandling="merge"
          routerLinkActive="checked"
          [ngClass]="{ 'checked': !route.snapshot.queryParams['from_date'] }">Published anytime
        </button>
      </div>
    </div>

    <!-- Access type filter menu -->
    <div class="btn-group me-1" ngbDropdown placement="bottom-left" container="body">
      <button type="button" class="btn btn-secondary" ngbDropdownToggle>
        <fa-icon [icon]="['fal', 'dot-circle']" *ngIf="!rlaOA.isActive && !rlaNonOA.isActive"></fa-icon>
        <fa-icon [icon]="['fal', 'unlock']" *ngIf="rlaOA.isActive"></fa-icon>
        <fa-icon [icon]="['fal', 'lock']" *ngIf="rlaNonOA.isActive"></fa-icon>
        Access type
      </button>

      <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
        <button type="button" class="dropdown-item"
          [routerLink]="[]"
          [queryParams]="{oa: ''}"
          queryParamsHandling="merge"
          routerLinkActive="checked" [ngClass]="{
            'checked': !rlaOA.isActive && !rlaNonOA.isActive
          }">Any
        </button>
        <button type="button" class="dropdown-item"
          [routerLink]="[]"
          [queryParams]="{oa: true}"
          queryParamsHandling="merge"
          routerLinkActive="checked"
          #rlaOA="routerLinkActive">Open Access
        </button>
        <button type="button" class="dropdown-item"
          [routerLink]="[]"
          [queryParams]="{oa: false}"
          queryParamsHandling="merge"
          routerLinkActive="checked"
          #rlaNonOA="routerLinkActive">Non Open Access
        </button>
      </div>
    </div>


    <!-- Sorting options menu -->
    <div class="btn-group ms-auto me-1" ngbDropdown placement="bottom-right">
      <button type="button" class="btn btn-secondary" ngbDropdownToggle>
        <fa-icon [icon]="['fal', order === 'asc' ? 'sort-alpha-down' : 'sort-alpha-up']"></fa-icon>
      </button>

      <div class="dropdown-menu dropdown-menu-left papers-scrollbar" ngbDropdownMenu>
        <ng-container *ngFor="let opt of sortOptions">
          <div class="dropdown-divider" *ngIf="!opt.key"></div>

          <button *ngIf="opt.key" type="button" class="dropdown-item"
            [ngClass]="{ 'checked': sort == opt.key }"
            (click)="onSortChange(opt.key)">{{opt.value}}</button>
        </ng-container>

        <div class="dropdown-divider"></div>

        <button type="button" class="dropdown-item"
          *ngFor="let opt of orderOptions"
          [ngClass]="{ 'checked': order == opt.key }"
          (click)="onOrderChange(opt.key)">{{opt.value}}</button>
      </div>
    </div>

    <div class="btn-group">
      <button
        type="button"
        class="btn"
        (click)="changeView('list')"
        [ngClass]="{
          'btn-primary': viewName == 'list',
          'btn-secondary': viewName === 'table'
        }">
        <fa-icon [icon]="['fal', 'table']"></fa-icon>
      </button>
      <button
        type="button"
        class="btn"
        (click)="changeView('table')"
        [ngClass]="{
          'btn-primary': viewName == 'table',
          'btn-secondary': viewName === 'list'
        }">
        <fa-icon [icon]="['fal', 'list']"></fa-icon>
      </button>
    </div>
    
  </div>
</div>