<list-explorer-button
  (click)="$event.stopPropagation()"
  [indent]="indent"
  [disabled]="loading$ | async"
  [expandable]="node.children.length > 0"
  [(expanded)]="expanded"
  (expandedChange)="onExpandedChange($event)">

  <ng-container icon>
    <rcp-svg-icon name="list"></rcp-svg-icon>
  </ng-container>

  <div class="list" (click)="onMouseClick($event)">
    <ng-container *ngIf="!(loading$ | async), else loadingTemplate">

      <span class="icon">

        <ng-container [ngSwitch]="true">
          <ng-container *ngSwitchCase="hasItem">
            <fa-icon [icon]="['fal', 'check-square']"></fa-icon>
          </ng-container>
          <ng-container *ngSwitchCase="hasItemInChildrenShown">
            <rcp-svg-icon name="square" width="12.25" height="12.25"></rcp-svg-icon>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <fa-icon [icon]="['fal', 'square']"></fa-icon>
          </ng-container>
        </ng-container>

      </span>

    </ng-container>

    <ng-template #loadingTemplate>
      <fa-icon [icon]="['fas', 'spinner-third']" [spin]="true" class="icon"></fa-icon>
    </ng-template>

    <span class="text">{{ node.list.name }}</span>
  </div>

  <ng-template #expandedTemplate>
    <list-explorer-list
      *ngFor="let node of node.children; trackBy: listTrackByFn"
      [indent]="(indent||0)+1"
      [level]="level+1"
      [maxDepth]="maxDepth-1"
      [collection]="collection"
      [node]="node"
      [item]="item"
      [hasItem]="item.list_ids.includes(node.list.id)"
      (addToList)="addToList.emit($event)"
      (removeFromList)="removeFromList.emit($event)">
    </list-explorer-list>
  </ng-template>

</list-explorer-button>
