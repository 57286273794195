<form #bulkLocatePDFForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">Locate PDFs</h4>
  </div>

  <div class="modal-body">

    <b class="papers-label">Options</b>

    <div class="row mt-2">
      <div class="col-sm-12">

        <!-- <div class="form-check form-switch mt-2">
          <input
            class="form-check-input"
            type="checkbox"
            id="skip-existing-checkbox"
            name="skip-existing-checkbox"
            [(ngModel)]="state.skipItemsWithFile">
          <label class="form-check-label" for="skip-existing-checkbox">Skip references that already have a file</label>
        </div> -->

        <div class="form-check form-switch mt-2">
          <input
            class="form-check-input"
            type="checkbox"
            id="failed-list-checkbox"
            name="failed-list-checkbox"
            [(ngModel)]="state.faliedListEnabled">
          <label class="form-check-label" for="failed-list-checkbox">Save failed references to list</label>
        </div>

        <div class="form-floating mt-2" *ngIf="state.faliedListEnabled">
          <input
            name="failedlist-name-input"
            type="text"
            class="form-control"
            #listInput="ngModel"
            [(ngModel)]="state.failedListName"
            autocomplete="off"
            maxlength="50"
            emptyValidator
            required>
          <span class="form-text text-muted" *ngIf="listInput.control.errors?.required && listInput.touched">List name is required.</span>
          <span class="form-text text-muted" *ngIf="listInput.control.errors?.empty && listInput.touched">List name name cant't be empty.</span>
          <label for="failedlist-name-input">List Name</label>
        </div>

      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="close()">Cancel</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!bulkLocatePDFForm.form.valid">Start</button>
  </div>

</form>
