import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, startWith, shareReplay, tap } from 'rxjs/operators';
import { ContextMenuService, ContextMenuComponent } from '@readcube/ngx-contextmenu';
import { DragAndDropService, DragHelperEvent } from '@readcube/rcp-drag-and-drop';

import { SharedService, ListNode, ListTreeService } from '../../common';
import { IDataUser } from '../../common-data';
import { IDataArticle } from '../../search-data';
import { LibraryService } from '../../library';
import { DataSmartListService, DataListService, IDataItem, IDataSmartList, IDataCollection } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { ImporterService } from '../../importer';
import { ExporterService } from '../../exporter';
import { BulkService } from '../../bulk';
import { FullTextService } from '../../full-text/services/full-text.service';
import { LibraryNavigationService } from '../services/library-navigation.service';

import { environment } from 'environment';

type CollectionDroppables = IDataItem[] | IDataArticle[] | ListNode | DataTransfer;

@Component({
  selector: 'library-navigation-collection',
  templateUrl: './library-navigation-collection.component.html',
  styleUrls: ['./library-navigation-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryNavigationCollectionComponent implements OnInit {
  @Input()
  indent = 0;

  @Input()
  collection: IDataCollection;

  @Input()
  expanded = true;

  @ViewChild('contextMenuComponent', { static: true })
  contextMenuComponent: ContextMenuComponent;

  user$: Observable<IDataUser>;
  active$: Observable<boolean>;
  dragActive$: Observable<boolean>;
  disabled$: Observable<boolean>;
  lists$: Observable<ListNode[]>;
  smartLists$: Observable<IDataSmartList[]>;

  loading$ = new ReplaySubject<boolean>(1);
  loadingLists$ = new ReplaySubject<boolean>(1);

  maxListsDepth: number;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public contextMenu: ContextMenuService,
    public shared: SharedService,
    public library: LibraryService,
    public navigation: LibraryNavigationService,
    public importer: ImporterService,
    public exporter: ExporterService,
    public bulk: BulkService,
    public dragAndDrop: DragAndDropService,
    public dataList: DataListService,
    public dataSmartList: DataSmartListService,
    public fullText: FullTextService,
    public listTree: ListTreeService,
    public shell: AppShellService
  ) {
    this.maxListsDepth = environment.maxListsDepth;
  }

  ngOnInit() {
    this.loadingLists$.next(true);

    this.lists$ = this.dataList.getAllByCollectionId(this.collection.id).pipe(
      map(lists => this.listTree.fromLists(lists)),
      map(root => root.children)
    ).pipe(
      tap(() => this.loadingLists$.next(false))
    );

    this.smartLists$ = this.dataSmartList.query({ collection_id: this.collection.id }).pipe(
      map(lists => lists.filter(list => !list.incomplete)),
      map(lists => lists.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.active$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.isActive()),
      startWith(this.isActive()),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.dragActive$ = this.dragAndDrop.event$.pipe(
      map(event => event.name === 'start'),
      startWith(false),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.disabled$ = this.dragAndDrop.event$.pipe(
      map(event => event.name === 'start' && !this.isDroppable(event)),
      startWith(false),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  openContextMenu(event: MouseEvent) {
    this.contextMenu.show.next({
      contextMenu: this.contextMenuComponent,
      anchorElement: event.currentTarget,
      event: event,
      item: null
    });
    event.preventDefault();
    event.stopPropagation();
  }

  onExpandedChange(value: boolean) {
    this.navigation.setCollectionExpanded(this.collection.id, value);

    if (value)
      this.loadingLists$.next(true);
  }

  onDrop(event: DragHelperEvent<CollectionDroppables>) {
    const collectionId = this.collection.id;
    switch (event.type) {
      case 'articles':
        const articles = <IDataArticle[]>event.data;
        this.loading$.next(true);
        this.shared.addToLibrary(articles, collectionId)
          .finally(() => this.loading$.next(false));
        break;
      case 'items':
        const items = <IDataItem[]>event.data;
        this.loading$.next(true);
        this.library.copyItems(items, { collectionId: collectionId })
          .finally(() => this.loading$.next(false));
        break;
      case 'list':
        const node = <ListNode>event.data;
        this.loading$.next(true);
        this.navigation.moveList(node.list, null)
          .finally(() => this.loading$.next(false));
        break;
      case 'files':
        const data = <DataTransfer>event.data;
        const files = Array.from(data.files);
        this.importer.openImportDialog(collectionId, null, null, files);
        break;
    }
  }

  isDroppable(event: DragHelperEvent<CollectionDroppables>): boolean {
    if (!this.canDropItems()) {
      return false;
    }
    switch (event.type) {
      case 'articles':
        return true;
      case 'items':
        // Accepts only items that are not already in this collection.
        const items = <IDataItem[]>event.data;
        return items.map(item => item.collection_id)
          .indexOf(this.collection.id) === -1;
      case 'list':
        // List can only be moved to another list within same collection.
        const node = <ListNode>event.data;
        return !!node.list.parent_id &&
          node.list.collection_id === this.collection.id &&
          node.getHeight() < this.maxListsDepth;
      case 'files':
        return true;
    }
    return false;
  }

  createList() {
    this.navigation.openEditList({
      collection_id: this.collection.id
    }).then(list => {
      if (!list) return;
      this.navigation.expandPathToList(list.collection_id, list.id);
      this.router.navigate(['/library', list.collection_id, 'list', list.id], {
        relativeTo: this.route
      });
    });
  }

  createSmartList() {
    this.navigation.openEditSmartList(this.collection, {
      collection_id: this.collection.id
    }).then(list => {
      if (!list) return;
      this.navigation.setCollectionExpanded(list.collection_id, true);
      this.router.navigate(['/library', list.collection_id, 'smartlist', list.id], {
        queryParams: { query: list.query },
        relativeTo: this.route
      });
    });
  }

  exportToBIB() {
    this.exporter.openExportItemsDialog({ type: 'bib', collection: this.collection });
  }

  exportToRIS() {
    this.exporter.openExportItemsDialog({ type: 'ris', collection: this.collection });
  }

  exportToCSV() {
    this.exporter.openExportItemsDialog({ type: 'csv', collection: this.collection });
  }

  exportToXLSX() {
    this.exporter.openExportItemsDialog({ type: 'xlsx', collection: this.collection });
  }

  canDropItems(): boolean {
    return this.shared.user?.licence.active &&
      this.collection.user?.can_create_item;
  }

  canManageList(): boolean {
    return this.shared.user?.licence.active &&
      this.collection.user?.can_manage_list;
  }

  canManageSmartlist(): boolean {
    return this.shared.user?.licence.active &&
      this.collection.user?.can_manage_smartlist;
  }

  canExportLibrary(): boolean {
    return this.shared.user?.licence.active;
  }

  canShareCollection(): boolean {
    return this.shared.user && !this.shared.user.licence.disable_public_sharing && this.shared.user.licence.active;
  }

  share() {
    this.navigation.share('collection', this.collection);
  }

  canCreateFilteredList(): boolean {
    return this.shared.user?.licence?.active
      && this.shared?.user?.licence?.filtered_lists;
  }

  createFilteredList() {
    this.fullText.createFilteredList(this.collection.id);
  }

  canSetNotifications() {
    return this.shared.user?.licence?.active
      && this.shared?.user?.licence?.collection_email_notifications
      && this.collection.organization;
  }

  setNotifications() {
    this.library.setNotifications(this.collection);
  }

  canCopyItems(): boolean {
    return this.shared.user?.licence?.active && this.collection.user?.can_copy_item;
  }

  copyItems() {
    this.bulk.bulkCopyItems(this.collection.id);
  }

  canDeleteItems(): boolean {
    return this.shared.user?.licence?.active && this.collection.user?.can_delete_item;
  }

  deleteItems() {
    this.bulk.bulkDeleteItems(this.collection.id);
  }

  canUpdateDetails(): boolean {
    return this.shared.user?.licence?.active && this.collection.user?.can_create_item;
  }

  updateDetails() {
    this.bulk.bulkUpdateDetails(this.collection.id);
  }

  canLocatePDFs(): boolean {
    return this.shared.user?.licence?.active;
  }

  locatePDFs() {
    this.bulk.locateAllPDFs(this.collection.id);
  }

  canLinkFiles(): boolean {
    return this.shared.user?.licence?.active
      && this.shared.user?.licence?.link_files
      && this.collection.organization;
  }

  linkFiles() {
    this.bulk.bulkLinkFiles(this.collection.id);
  }

  canExportPDFs(): boolean {
    return this.shared.user?.licence?.active && this.shared?.user?.licence?.collection_export;
  }

  exportPDFs() {
    this.bulk.bulkExportPDFs(this.collection.id);
  }

  private isActive(): boolean {
    const route = ['/library', this.collection.id, 'all'];
    const urlTree = this.router.createUrlTree(route);
    return this.router.isActive(urlTree, {
      paths: 'subset',
      matrixParams: 'ignored',
      queryParams: 'ignored',
      fragment: 'ignored'
    });
  }

  canLiteratureReview(): boolean {
    return ['admin', 'reviewer'].includes(this.shared.user?.review_role);
  }

  addToLiteratureReview() {
    this.bulk.bulkLiteratureReview(this.collection.id, null);
  }

  linkToLiteratureReview() {
    this.library.linkToLiteratureReview(this.collection.id);
  }
}
