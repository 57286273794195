import { Injectable } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { switchMap, shareReplay, mergeMap } from 'rxjs/operators';

import { DataUserApiService, IDataUserActivationResponse, IDataUser } from './data-user-api.service';

@Injectable()
export class DataUserService {
  protected cache$: Observable<IDataUser>;
  protected reload$ = new Subject<void>();

  constructor(
    public api: DataUserApiService
  ) { }

  get(cached = true): Observable<IDataUser> {
    const get$ = this.api.get();
    const ws$ = get$.pipe(
      switchMap(user => this.api.listen(user.id)),
      mergeMap(() => get$)
    );
    const reload$ = this.reload$.pipe(switchMap(() => get$));
    if (!this.cache$ || !cached) {
      this.cache$ = merge(get$, ws$, reload$).pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }
    return this.cache$;
  }

  activate(): Promise<IDataUserActivationResponse> {
    return this.api.activate().then(response => {
      if (response.success) {
        this.reload();
      }
      return response;
    });
  }

  reload() {
    this.reload$.next();
  }
}
