<div class="form-floating">
  <select name="import-from-select" class="form-select"
    (change)="onTypeChange($event)" [(ngModel)]="importType">
    <option [ngValue]="v" *ngFor="let v of availableImportTypes">{{v.name}}</option>
  </select>
  <label for="import-from-select">Import from</label>
</div>

<div class="mt-3" *ngIf="importType?.accept && importType?.multiple">
  <label class="form-control btn btn-secondary pointer" style="line-height: 50px">

    <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
    <span *ngIf="!selectedFiles.length"> Browse files</span>
    <span *ngIf="selectedFiles.length"> Selected {{selectedFiles.length}} file{{selectedFiles.length > 1 ? 's' : ''}}</span>

    <input type="file" id="files" name="files[]"
      (change)="onFileSelect($event)"
      [accept]="importType.accept"
      multiple
      hidden>
  </label>
</div>

<div class="mt-3" *ngIf="importType?.accept && !importType?.multiple">
  <label class="form-control btn btn-secondary pointer" style="line-height: 50px">

    <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
    <span *ngIf="!selectedFiles.length"> Browse files</span>
    <span *ngIf="selectedFiles.length"> Selected {{selectedFiles.length}} file{{selectedFiles.length > 1 ? 's' : ''}}</span>

    <input type="file" id="files" name="files[]"
      (change)="onFileSelect($event)"
      [accept]="importType.accept"
      hidden>
  </label>
</div>
