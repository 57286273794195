export const environment = {
  name: 'development',
  production: false,
  baseUrls: {
    papers: '//papersapp.com',
    readcube: '//staging.readcube.com',
    releaseNotes: '//www.papersapp.com/release-notes/',
    updateManifest: '//app-dev.readcube.com/update.json',
    support: '//support.readcube.com',
    webapp: '//app-dev.readcube.com',
    sync: 'https://sync-staging.readcube.com',
    services: 'https://services-staging.readcube.com',
    contentServices: 'https://content-services-staging.readcube.com',
    smartciteAssets: 'https://smartcite-assets.readcube.com',
    push: 'https://push-staging.readcube.com/bayeux',
    metrics: 'https://metrics-api.dimensions.ai',
    publicLists: 'https://lists-staging.papersapp.com',
    dashboard: 'https://dashboard-staging.readcube.com',
    review: 'https://review-staging.readcube.com',
    api: 'https://api.readcube.com'
  },
  clientName: 'webapp',
  uploadWithHash: true,
  maxRequestRetryAttempts: 5,
  bulkRequestSize: 50,
  wsChannelPrefix: '/staging',
  maxListsDepth: 7,
  extensionIdMetaTag: 'readcube_staging_extension_id',
  extensionVersionMetaTag: 'readcube_staging_extension_version',
  chromeExtensionId: 'hokplgjdclnemllndjjaddcoicapnppf',
  firefoxExtensionId: '{003fb763-c222-4734-9773-bc19ba823079}',
  edgeExtensionId: 'EdgeExtension_DigitalScienceAnywhereAccess_bms0cd5w0pryg',
  edgeChromiumExtensionId: 'ahmmhplnlcgfgmhfiklfnhbegdjcekjg',
  minExtensionVersion: '4.27.0',
  altmetricToken: '190e62ffe743e9fc44da8419f01ade8f',
  trackJsApp: 'rcp-webapp-dev',
  trackJsToken: '4587af51bbb542afbbc9d93bf9567159',
  trackJsEnabled: true,
  throttleItemsReload: 3000,
  throttleListsReload: 500,
  delayBeforeItemsReload: 8000,
  delayBeforeListsReload: 500,
  debugScrollSession: false,
  scrollSessionExpireTime: 600,
  scrollBatchSizeForItems: 50,
  bulkRequestInterval: 200,
  bulkExtensionTimeout: 2 * 60000,
};
