import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AppSessionService } from './app-session.service';
import { environment } from 'environment';

declare var Faye: IFaye;

interface IFaye {
  Client: IFayeClientConstructable
}

interface IFayeClientConstructable {
  new(url: string, modifiers?: IFayeModifiers): IFayeClient;
}

interface IFayeClient {
  setHeader(key: string, value: string): void
  disable(endpoint: string): void
  publish(channel: string, data: any): void
  subscribe(channel: string, callback: (message: any) => void): IFayeSubscription
}

interface IFayeModifiers {
  endpoints?: { [name: string]: string; }
  timeout?: number
  retry?: number
}

interface IFayeSubscription {
  then(callback: () => void): IFayeSubscription
  withChannel(callback: (channel: string, message: string) => void): IFayeSubscription
  cancel(): void
}

@Injectable()
export class AppWebSocketService {
  protected client: IFayeClient;

  constructor(
    protected session: AppSessionService
  ) {
    this.client = new Faye.Client(environment.baseUrls.push, {
      timeout: 25,
      retry: 5
    });
  }

  listen<T=any>(channel: string): Observable<T> {
    return new Observable((subscriber: Subscriber<T>) => {
      const chan = [environment.wsChannelPrefix, channel].join('');
      const sub = this.client.subscribe(chan, msg => {
        if (msg.sync_client_id !== this.session.clientId) {
          if (!environment.production) {
            console.log(msg);
          }
          subscriber.next(<T>msg);
        }
      });
      return () => sub.cancel();
    });
  }
}
