<div class="input-group">
  <ng-container *ngIf="getCapabilities() as capabilities">
    <ng-container *ngIf="capabilities.length == 1; else selectInputType">
      <span class="input-group-text input-type-width">{{getCapabilityLabel(capabilities[0])}}</span>
    </ng-container>

    <ng-template #selectInputType>
      <select type="text" class="form-control input-type-width" [(ngModel)]="value.type" (ngModelChange)="onChange(value)" (change)="focus()">
        <option [ngValue]="cap" *ngFor="let cap of capabilities">{{getCapabilityLabel(cap)}}</option>
      </select>
    </ng-template>
  </ng-container>


  <ng-container [ngSwitch]="true">

    <ng-container *ngSwitchCase="['is', 'is_not', 'is_after', 'is_before', 'is_more', 'is_less'].includes(value.type)">
      <ng-template [ngTemplateOutlet]="optionInput" [ngTemplateOutletContext]="{ options }"
        *ngIf="getFieldOptions() as options; else textInput"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="['date', 'date_after', 'date_before'].includes(value.type)">
      <input type="text" class="hidden-input" #d="ngbDatepicker"
        ngbDatepicker (dateSelect)="selectDate($event)"
        placement="bottom-left" container="body">

      <input type="text" class="form-control"
        [(ngModel)]="value.input_text" (ngModelChange)="onChange(value)"
        placeholder="YYYY-MM-DD" autocomplete="false" #focusInput>

      <button class="btn btn-secondary" (click)="d.toggle()" type="button">
        <fa-icon [icon]="['fal', 'calendar']"></fa-icon>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="['boolean', 'exists'].includes(value.type)">
      <span class="input-group-text">
        <div class="form-check form-switch checkbox-lg">
          <input class="form-check-input" type="checkbox"
            [(ngModel)]="value.input_bool" (ngModelChange)="onChange(value)"
            autocomplete="off">
        </div>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="value.type == 'range'">
        <input  type="text" class="form-control btn btn-secondary"
          [(ngModel)]="value.input_range[0]" (ngModelChange)="onChange(value)"
          placeholder="From" autocomplete=off #focusInput>
        <span class="input-group-text">...</span>
        <input type="text" class="form-control btn btn-secondary"
          [(ngModel)]="value.input_range[1]" (ngModelChange)="onChange(value)"
          placeholder="To" autocomplete=off>
    </ng-container>

  </ng-container>

</div>

<ng-template #optionInput let-options="options">
  <select #focusInput type="text" class="form-control" 
    [(ngModel)]="value.input_text" (ngModelChange)="onChange(value)">
    <option [ngValue]="option.value" *ngFor="let option of options | sort:'display'">{{option.display}}</option>
  </select>
</ng-template>

<ng-template #textInput>
  <input #focusInput type="text" class="form-control"
    [(ngModel)]="value.input_text" (ngModelChange)="onChange(value)"
    placeholder="Enter text..." autocomplete=off>
</ng-template>