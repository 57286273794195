import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppCommonModule } from '../common';
import { AppUserMenuModule } from '../user-menu';

import { SearchNavigationComponent } from './components/search-navigation.component';
import { SearchNavigationFiltersComponent } from './components/search-navigation-filters.component';
import { ModalCustomizeFiltersComponent } from './modals/modal-customize-filters.component';
import { SearchNavigationFiltersService } from './services/search-navigation-filters.service';
import { SearchNavigationQueryService } from './services/search-navigation-query.service';
import { DataFiltersApiService } from './services/data-filters-api.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxChartsModule,
    NgxSliderModule,
    NgbAccordionModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
    ReadCubeSvgIconsModule,
    AppCommonModule,
    AppUserMenuModule,
  ],
  declarations: [
    SearchNavigationComponent,
    SearchNavigationFiltersComponent,
    ModalCustomizeFiltersComponent
  ]
})
export class AppSearchNavigationModule {
  public static forRoot(): ModuleWithProviders<AppSearchNavigationModule> {
    return {
      ngModule: AppSearchNavigationModule,
      providers: [
        SearchNavigationFiltersService,
        SearchNavigationQueryService,
        DataFiltersApiService
      ]
    }
  }
}
