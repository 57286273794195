<div ngbDropdown #drop="ngbDropdown" autoClose="outside" placement="bottom-start" container="body">

  <div class="btn-group field-button">
    <button type="button" class="btn btn-secondary w-100" ngbDropdownToggle (click)="drop.open(); focus()"><span>{{value?.display}}</span></button>
  </div>

  <div  ngbDropdownMenu>
    <div class="input-group search-box">
      <div class="input-group-prepend">
        <button type="button" class="btn btn-outline-secondary"><fa-icon [icon]="['fal', 'search']"></fa-icon></button>
      </div>
      <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="searchTerm" #searchInput>
    </div>

    <div class="scrollable papers-scrollbar">

      <ng-container *ngFor="let field of fields | sort:'display'">
        <ng-container *ngIf="matchSearchTerm(field)">
          <button ngbDropdownItem #fieldOption (click)="selectField(field); $event.preventDefault(); drop.close()">{{field.display}}</button>
        </ng-container>
      </ng-container>

      <div class="dropdown-divider" *ngIf="customFields.length"></div>

      <ng-container *ngFor="let field of customFields | sort:'display'">
        <ng-container *ngIf="matchSearchTerm(field)">
          <button ngbDropdownItem #customFieldOption (click)="selectField(field); $event.preventDefault(); drop.close()">{{field.display}}</button>
        </ng-container>
      </ng-container>

    </div>

  </div>
</div>
