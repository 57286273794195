import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, merge, Subject, asyncScheduler } from 'rxjs';
import { scan, map, filter, mergeMap, startWith, tap, shareReplay, throttleTime, delay } from 'rxjs/operators';

import { environment } from 'environment';
import { DataListApiService, IDataListQueryParams, IDataList } from './data-list-api.service';

const CMP_LIST = (a: IDataList, b: IDataList) => a.collection_id === b.collection_id && a.id === b.id;

export interface IDataReloadListsParams {
  collectionId: string;
}

@Injectable()
export class DataListService {
  protected cache$: { [key: string]: Observable<IDataList[]>; } = {};
  protected reload$ = new Subject<IDataReloadListsParams>();
  protected update$ = new ReplaySubject<IDataList[]>(1);
  protected concat$ = new ReplaySubject<IDataList[]>(1);

  constructor(
    public api: DataListApiService
  ) { }

  create(list: Partial<IDataList>): Promise<IDataList> {
    return this.api.post(list).then(list => this.pushDownstream(list).pop());
  }

  query(params: IDataListQueryParams): Observable<IDataList[]> {
    let update = false;
    const query$ = this.api.query(params);
    const notify$ = this.api.listen(params.collection_id).pipe(mergeMap(() => query$));
    const reload$ = this.reload$.pipe(
      filter(reload => reload.collectionId === params.collection_id),
      throttleTime(environment.throttleListsReload, asyncScheduler, { leading: true, trailing: true }),
      delay(environment.delayBeforeListsReload),
      mergeMap(() => this.api.query(params))
    );
    return merge(query$, notify$, reload$).pipe(
      mergeMap(result => merge(
        this.update$.pipe(tap(() => update = true)),
        this.concat$.pipe(tap(() => update = false))
      ).pipe(
        startWith(result.rows),
        map(lists => lists.filter(l => l.collection_id === params.collection_id)),
        scan((acc, val) => this.accumulator(acc, val, update))
      ))
    );
  }

  getAllByCollectionId(collectionId: string): Observable<IDataList[]> {
    if (!this.cache$[collectionId]) {
      this.cache$[collectionId] = this.query({ collection_id: collectionId }).pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }
    return this.cache$[collectionId];
  }

  get(collectionId: string, listId: string): Observable<IDataList> {
    const get$ = this.api.get(collectionId, listId);
    const notify$ = this.api.listen(collectionId, listId);
    const reload$ = notify$.pipe(filter(data => data.pop().action === 'updated'), mergeMap(() => get$));
    const update$ = this.update$.pipe(
      map(lists => lists.filter(i => i.collection_id === collectionId && i.id === listId)),
      filter(lists => lists.length > 0),
      map(lists => lists[0])
    );
    return merge(get$, reload$).pipe(
      mergeMap(item => update$.pipe(
        startWith(item)
      ))
    );
  }

  update(list: Partial<IDataList>): Promise<IDataList> {
    return this.api.patch(list)
      .then(list => this.pushDownstream(list, true).pop());
  }

  delete(collectionId: string, listId: string): Promise<IDataList> {
    return this.api.delete(collectionId, listId)
      .then(list => this.pushDownstream(list, true).pop());
  }

  addItems(collectionId: string, listId: string, itemIds: string[]): Promise<IDataList> {
    return this.api.addItems(collectionId, listId, itemIds)
      .then(list => this.pushDownstream(list, true).pop());
  }

  removeItems(collectionId: string, listId: string, itemIds: string[]): Promise<IDataList> {
    return this.api.removeItems(collectionId, listId, itemIds)
      .then(list => this.pushDownstream(list, true).pop());
  }

  share(collectionId: string, listId: string): Observable<IDataList> {
    return this.api.share(collectionId, listId).pipe(
      tap(list => this.pushDownstream(list, true))
    );
  }

  stopShare(collectionId: string, listId: string): Observable<IDataList> {
    return this.api.stopShare(collectionId, listId).pipe(
      tap(list => this.pushDownstream(list, true))
    );
  }

  reloadRows(params: IDataReloadListsParams) {
    this.reload$.next(params);
  }

  pushDownstream(lists: IDataList | IDataList[], update: boolean = false): IDataList[] {
    lists = Array.isArray(lists) ? lists : [lists];
    if (update) {
      this.update$.next(lists);
    } else {
      this.concat$.next(lists);
    }
    return lists;
  }

  protected accumulator(acc: IDataList[], val: IDataList[], update = false): IDataList[] {
    if (update) {
      return acc.map(a => val.find(b => CMP_LIST(a, b)) || a).filter(a => !a.deleted);
    }
    return acc.concat(acc.length ? val.filter(a => !acc.find(b => CMP_LIST(a, b))) : val);
  }
}
