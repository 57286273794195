import { Injectable } from '@angular/core';
import { NEVER, Observable, ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IDataArticle } from '../../search-data';
import { AppShellService } from '../../app-shell.service';
import { AppStorageService } from '../../app-storage.service';

@Injectable()
export class SearchSidepanelService {
  public article$: Observable<IDataArticle> = NEVER;
  public loading$ = new ReplaySubject<boolean>();

  constructor(
    protected modal: NgbModal,
    protected router: Router,
    protected shell: AppShellService,
    protected storage: AppStorageService,
  ) { }

  open(route: ActivatedRoute, articles: IDataArticle[], panel: string): Promise<boolean> {
    this.setActive(panel);
    const queryParams: Params = {
      'article_id': articles.map(i => i.id).join(',')
    };
    return this.router.navigate([{
      outlets: { 'sidepanel': [ panel ] }
    }], {
      relativeTo: route,
      queryParamsHandling: 'merge',
      queryParams: queryParams,
      replaceUrl: true
    }).then(navigated => {
      if (navigated) {
        this.setActive(panel);
      }
      return navigated;
    });
  }

  close(route: ActivatedRoute, queryParams?: Params) {
    return this.router.navigate([{
      outlets: { 'sidepanel': null }
    }], {
      relativeTo: route,
      queryParamsHandling: 'merge',
      queryParams: queryParams,
      replaceUrl: true
    });
  }

  navigate(route: ActivatedRoute, path: string) {
    return this.router.navigate([{
      outlets: { 'sidepanel': [ path ] }
    }], {
      relativeTo: route,
      queryParamsHandling: 'merge',
      replaceUrl: true
    }).then(navigated => {
      if (navigated) {
        this.setActive(path);
      }
      return navigated;
    });
  }

  setActive(panel: string) {
    this.storage.set('search-active-panel', panel);
  }

  getActive(): string {
    return this.storage.get('search-active-panel', 'details');
  }
}
