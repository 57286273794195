import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NEVER, Observable, ReplaySubject } from 'rxjs';
import { strtrunc } from '@readcube/rcp-common';

import { ModalEditListComponent } from '../../common';
import { DataItemService, IDataCollection, IDataItem, IDataItemFile, IDataList } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { AppStorageService } from '../../app-storage.service';

import { ModalEditTagsComponent } from '../modals/modal-edit-tags.component';
import { ModalListsEditorComponent } from '../modals/modal-edit-lists.component';

const REMOVE_FILE_MODAL_TITLE = 'Confirm remove';
const REMOVE_FILE_MODAL_TEXT = `Are you sure you want to permanently remove this file from your library?`;

const EMAIL_TITLE_MAXLENGTH = 300;
const EMAIL_AUTHORS_MAXLENGTH = 500;
const EMAIL_ABSTRACT_MAXLENGTH = 800;
const EMAIL_JOURNAL_MAXLENGTH = 200;
const EMAIL_TOTAL_MAXLENGTH = 2000;

@Injectable()
export class LibrarySidepanelService {
  public item$: Observable<IDataItem> = NEVER;
  public collection$: Observable<IDataCollection> = NEVER;
  public loading$ = new ReplaySubject<boolean>();

  constructor(
    protected modal: NgbModal,
    protected router: Router,
    protected dataItem: DataItemService,
    protected storage: AppStorageService,
    protected shell: AppShellService
  ) { }

  getActive(): string {
    return this.storage.get('library-active-panel', 'details');
  }

  setActive(panel: string) {
    this.storage.set('library-active-panel', panel);
  }

  open(route: ActivatedRoute, items: IDataItem[], panel?: string): Promise<boolean> {
    panel = panel || this.getActive();
    const queryParams: Params = {
      'item_id': items.map(i => i.id).join(',')
    };
    if (items.length === 1) {
      queryParams['collection_id'] = items[0].collection_id;
    }
    return this.router.navigate([{
      outlets: { 'sidepanel': [panel] }
    }], {
      relativeTo: route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    }).then(navigated => {
      if (navigated) {
        this.setActive(panel);
      }
      return navigated;
    });
  }

  close(route: ActivatedRoute, items?: IDataItem[]): Promise<boolean> {
    items = items || [];
    return this.router.navigate([{
      outlets: { 'sidepanel': null }
    }], {
      relativeTo: route,
      queryParams: {
        'collection_id': null,
        'item_id': items.map(i => i.id).join(',') || null
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  navigate(route: ActivatedRoute, path: string) {
    return this.router.navigate([{
      outlets: { 'sidepanel': [path] }
    }], {
      queryParamsHandling: 'merge',
      replaceUrl: true
    }).then(navigated => {
      if (navigated) {
        this.setActive(path);
      }
      return navigated;
    });
  }

  setNotes(item: IDataItem, notes: string): Promise<IDataItem> {
    return this.dataItem.update(item.collection_id, item.id, {
      user_data: { notes }
    });
  }

  removeFile(item: IDataItem, file: IDataItemFile): Promise<IDataItem> {
    return this.shell.openConfirm<IDataItem>({
      title: REMOVE_FILE_MODAL_TITLE,
      message: REMOVE_FILE_MODAL_TEXT,
      confirmButtonText: 'Remove',
      progressText: 'Removing...'
    }, () => {
      return this.dataItem.removeFile(item.collection_id, item.id, { hash: file.sha256 });
    });
  }

  openEditTags(item: IDataItem): Promise<any> {
    const modalRef = this.modal.open(ModalEditTagsComponent);
    const componentInstance = <ModalEditTagsComponent>modalRef.componentInstance;
    componentInstance.item = item;
    return modalRef.result.catch(err => {
      // Dialog dismissed
    });
  }

  openEditLists(item: IDataItem, collection: IDataCollection): Promise<any> {
    const modalRef = this.modal.open(ModalListsEditorComponent);
    const componentInstance = <ModalListsEditorComponent>modalRef.componentInstance;
    componentInstance.item = item;
    componentInstance.collection = collection;
    return modalRef.result.catch(err => {
      // Dialog dismissed
    });
  }

  openEditList(list: Partial<IDataList>): Promise<any> {
    const modalRef = this.modal.open(ModalEditListComponent);
    const componentInstance = <ModalEditListComponent>modalRef.componentInstance;
    componentInstance.list = <IDataList>list;
    return modalRef.result.catch(err => {
      // Dialog dismissed
    });
  }

  promptMisingRecommendedFields(fields: string[]): Promise<boolean> {
    return this.shell.showConfirm({
      title: 'Warning',
      message: 'The following recommended fields are empty:',
      displayList: fields,
      cancelButtonText: 'Continue Editing',
      confirmButtonText: 'Save'
    }).then(result => result?.confirmed);
  }

  getShareByEmailBody(item: IDataItem): string {
    let body: string;
    const length = item.article.title?.length + item.article?.authors.join(', ').length + item.article.abstract?.length + item.article.journal?.length;

    if (length > EMAIL_TOTAL_MAXLENGTH) {
      const title = strtrunc(item.article.title, EMAIL_TITLE_MAXLENGTH, true);
      const abstract = strtrunc(item.article.abstract, EMAIL_ABSTRACT_MAXLENGTH, true);
      const journal = strtrunc(item.article.journal, EMAIL_JOURNAL_MAXLENGTH, true);

      let totalAuthorChars = 0;
      let authorsText = '';

      for (const author of item.article.authors) {
        totalAuthorChars = totalAuthorChars + author.length;
        if (totalAuthorChars > EMAIL_AUTHORS_MAXLENGTH) {
          authorsText = authorsText + ' et al.';
          break;
        }
        authorsText = authorsText ? (authorsText + ', ' + author) : author;
      }

      body = `\n${title}\n\n${authorsText}\n\n${abstract}\n\n${journal}`;
    } else {
      body = `\n${item.article.title}\n\n${item.article.authors.join(', ')}\n\n${item.article.abstract}\n\n${item.article.journal}\n`;
    }

    body = `${body}\n${item.ext_ids.doi ? item.ext_ids.doi : item.ext_ids.pmid}\n\nVia Papers @ https://papersapp.com`;
    return body;
  }
}
