import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';

import { ListExplorerListComponent } from './components/list-explorer-list.component';
import { ListExplorerButtonComponent } from './components/list-explorer-button.component';
import { ListExplorerService } from './services/list-explorer.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeSvgIconsModule
  ],
  declarations: [
    ListExplorerListComponent,
    ListExplorerButtonComponent
  ],
  exports: [
    ListExplorerListComponent
  ],
  providers: [
    ListExplorerService
  ]
})
export class AppListExplorerModule { }
