<library-menu #itemsMenu
  [items]="highlighted?.length ? highlighted : selected"
  [collection]="null"
  (onDeleted)="onDeleted($event)"
  (onEditDetails)="onEditDetails($event)"
  (onOpenResolver)="onOpenResolver($event)"
  (onMenuClose)="onMenuClose($event)">
</library-menu>


<common-sidepanel-layout [sidepanelOpen]="sidePanelOpen$ | async" (sidepanelClosed)="closeSidePanel()">
  <ng-container content>
    <div class="wrapper">
      <library-header
        #libraryHeader
        class="header"
        searchPlaceholder="Enter search terms..."
        [sort]="sort$ | async"
        [order]="order$ | async"
        [query]="query$ | async"
        [(viewName)]="viewName"
        (viewNameChange)="onViewNameChange($event)">
      </library-header>

      <div #selectionContainer
        class="content"
        [rcpDraggable]="selected.length > 0"
        rcpDragType="items"
        rcpDragEffect="move"
        [rcpDragData]="selected">

        <ng-container [ngSwitch]="viewName">
          <library-results-list
            *ngSwitchCase="'list'"
            [disabled]="true"
            [multiselect]="false"
            [items]="items$ | async"
            [selected]="selected"
            [highlighted]="highlighted"
            [loading]="loading$ | async"
            [finished]="finished$ | async"
            (open)="onItemDblClick($event)"
            (loadNext)="onLoadNext($event)"
            (highlightedChange)="highlightItems($event)"
            (selectedChange)="selectItems($event)"
            (contextmenu)="itemsMenu.open($event)">
          </library-results-list>

          <library-results-table
            *ngSwitchCase="'table'"
            [organization]="true"
            [disabled]="true"
            [multiselect]="false"
            [items]="items$ | async"
            [sort]="sort$ | async"
            [order]="order$ | async"
            [selected]="selected"
            [highlighted]="highlighted"
            [loading]="loading$ | async"
            [finished]="finished$ | async"
            (open)="onItemDblClick($event)"
            (loadNext)="onLoadNext($event)"
            (highlightedChange)="highlightItems($event)"
            (selectedChange)="selectItems($event)"
            (contextmenu)="itemsMenu.open($event)">
          </library-results-table>
        </ng-container>
      </div>

    </div>
  </ng-container>

  <ng-container footer>{{statusText$ | async | capitalize}}</ng-container>

  <ng-container sidepanel>
    <div class="wrapper sidepanel">
      <div class="navigation papers-border-bottom">
        <ul>
          <li ngbTooltip="Details" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('details')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'details' }">
              <rcp-svg-icon name="info" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Edit" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('edit')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'edit' }">
              <rcp-svg-icon name="cite" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Share" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('share')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'share' }">
              <rcp-svg-icon name="share" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Notes" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('notes')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'notes' }">
              <rcp-svg-icon name="comment" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Metrics" openDelay="1000" placement="bottom" *ngIf="shared.user?.licence.active">
            <a href (click)="openSidePanel('metrics')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'metrics' }">
              <rcp-svg-icon name="metrics" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Metrics" openDelay="1000" placement="bottom" *ngIf="!shared.user?.licence.active">
            <a href (click)="openSubscriptionModal()" rcpPreventDefault class="disabled">
              <rcp-svg-icon name="metrics" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Files" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('files')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'files' }">
              <rcp-svg-icon name="files" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Export" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('export')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'export' }">
              <rcp-svg-icon name="export" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>

          <li class="right" ngbTooltip="Options" openDelay="1000" placement="bottom">
            <a href (click)="itemsMenu.open($event, true)" rcpPreventDefault>
              <rcp-svg-icon name="gear" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
        </ul>
      </div>

      <div class="content">
        <router-outlet name="sidepanel"></router-outlet>
      </div>
    </div>
  </ng-container>

</common-sidepanel-layout>
