<div class="body">
  <div class="title">{{job.name}}</div>
  <div class="controls">
    <span (click)="onRemove($event)" class="btn-link">
      {{job.snapshot.status === 'running' || job.snapshot.status === 'pending' ? 'stop' : 'remove'}}
    </span>
  </div>
</div>

<ngb-progressbar [type]="type$ | async" height="14px" [animated]="processing$ | async" [striped]="processing$ | async"
  [value]="percent$ | async">{{progress$ | async}}</ngb-progressbar>

<div class="error-container" *ngIf="job.parserError$ | async as parserError">
  <div class="text-danger" *ngIf="parserError.errors?.length as errorCount">
    <fa-icon [icon]="['fal', 'exclamation-circle']"></fa-icon>
    {{errorCount}} {{errorCount > 1 ? 'Errors' : 'Error'}}
  </div>
  <div class="text-warning" *ngIf="parserError.warnings?.length as warningCount">
    <fa-icon [icon]="['fal', 'exclamation-circle']"></fa-icon>
    {{warningCount}} {{warningCount > 1 ? 'Warnings' : 'Warning'}}
  </div>
</div>

<div class="error-container" *ngIf="error$ | async as error">
  <div class="text-danger">
    <fa-icon [icon]="['fal', 'exclamation-circle']"></fa-icon> {{error}}
  </div>
</div>