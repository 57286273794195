<context-menu #selectedContextMenu (close)="onMenuClose.emit($event)">
  <ng-template contextMenuItem [subMenu]="openSubMenu" [visible]="canOpen">Open...</ng-template>
  <ng-template contextMenuItem [subMenu]="copySubMenu">Copy...</ng-template>
  <ng-template contextMenuItem [subMenu]="mergeSubMenu" [visible]="canMerge && canManageItem">Merge Into...</ng-template>

  <ng-container *ngIf="canManageItem">
    <ng-template contextMenuItem divider="true" *ngIf="canAddFlag || canRemoveFlag || canMarkUnread || canMarkRead"></ng-template>
    <ng-template contextMenuItem (execute)="setFlag($event.item, true)" [visible]="canAddFlag">Add Flag</ng-template>
    <ng-template contextMenuItem (execute)="setFlag($event.item, false)" [visible]="canRemoveFlag">Remove Flag</ng-template>
    <ng-template contextMenuItem (execute)="setUnread($event.item, false)" [visible]="canMarkUnread">Mark as Read</ng-template>
    <ng-template contextMenuItem (execute)="setUnread($event.item, true)" [visible]="canMarkRead">Mark as Unread</ng-template>
  </ng-container>

  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="locatePDF($event.item)" [visible]="canLocatePDF">Locate PDF</ng-template>
  <ng-template contextMenuItem (execute)="linkFile($event.item)" [visible]="canLinkFile && canManageItem">Link Existing Files</ng-template>
  <ng-template contextMenuItem (execute)="viewInLibrary($event.item)" [visible]="canViewInLibrary">View in Library</ng-template>
  <ng-template contextMenuItem (execute)="copyToMyLibrary($event.item)" [visible]="canCopyToMyLibrary && canCopyItem">Copy to My Papers</ng-template>
  <ng-template contextMenuItem (execute)="downloadFile($event.item)" [visible]="canDownloadFile && canDownloadFileForCollection">Download File</ng-template>
  <ng-template contextMenuItem (execute)="delete($event.item)" [visible]="canDeleteItem">Delete from Library</ng-template>
  <ng-template contextMenuItem (execute)="removeFromList(collectionId, listId, $event.item)" [visible]="collectionId && listId && canManageList">Remove Items from List</ng-template>
  <ng-template contextMenuItem [subMenu]="exportSubMenu">Export to...</ng-template>
  <ng-template contextMenuItem [subMenu]="literatureReviewSubMenu" [visible]="canLiteratureReview">Literature Review</ng-template>

  <ng-template contextMenuItem>Analyze</ng-template>
  <ng-template contextMenuItem>Create Dashboard</ng-template>


  <ng-container *ngIf="canManageItem">
    <ng-template contextMenuItem divider="true" *ngIf="canEditDetails || canClearDetails || canUpdateDetails || canResolve"></ng-template>
    <ng-template contextMenuItem (execute)="editDetails($event.item)" [visible]="canEditDetails">Edit Details</ng-template>
    <ng-template contextMenuItem (execute)="clearDetails($event.item)" [visible]="canClearDetails">Clear Details</ng-template>
    <ng-template contextMenuItem (execute)="updateDetails($event.item)" [visible]="canUpdateDetails">Update Details</ng-template>
    <ng-template contextMenuItem (execute)="openResolver($event.item)" [visible]="canResolve">Match</ng-template>
  </ng-container>

  <ng-template contextMenuItem divider="true" *ngIf="canOpenCopyright || canOrderReprint"></ng-template>
  <ng-template contextMenuItem (execute)="openCopyright($event.item)" [visible]="canOpenCopyright">Open Copyright Status</ng-template>
  <ng-template contextMenuItem [subMenu]="setCopyrightStatusSubmenu" [visible]="canSetCopyright">Set Copyright Status</ng-template>
  <ng-template contextMenuItem (execute)="openOrderReprint($event.item)" [visible]="canOrderReprint">Order Reprints</ng-template>
</context-menu>

<context-menu #openSubMenu>
  <ng-container *ngIf="items[0] as item">
    <ng-template contextMenuItem (execute)="viewPDF($event.item, $event.event)" [visible]="canViewPDF">View PDF</ng-template>
    <ng-template contextMenuItem divider="true" *ngIf="canViewPDF"></ng-template>
    <ng-template contextMenuItem (execute)="openPublisherWithProxy($event.item, $event.event)" *ngIf="canOpenPublisherWithProxy(item)">Publisher Website with Proxy</ng-template>
    <ng-template contextMenuItem (execute)="openPublisher($event.item, $event.event)" *ngIf="canOpenPublisher(item)">Publisher Website</ng-template>
    <ng-template contextMenuItem (execute)="openDimensions($event.item, $event.event)" *ngIf="canOpenDimensions(item)">In Dimensions</ng-template>
    <ng-template contextMenuItem (execute)="openPubmed($event.item, $event.event)" *ngIf="canOpenPubmed(item)">In Pubmed</ng-template>
    <ng-template contextMenuItem (execute)="openEBSCO($event.item, $event.event)" *ngIf="canOpenEBSCO(item)">In EBSCO</ng-template>
  </ng-container>
</context-menu>

<context-menu #copySubMenu>
  <ng-container *ngIf="items[0] as item">
    <ng-template contextMenuItem (execute)="copyURL($event.item)">Reference URL</ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem (execute)="library.copyBibTeXToClipboard($event.item)">BibTeX {{items.length > 1 ? 'Entries' : 'Entry'}}</ng-template>
    <ng-template contextMenuItem (execute)="library.copySummaryToClipboard($event.item)">Formatted {{items.length > 1 ? 'Summaries' : 'Summary'}}</ng-template>
    <ng-template contextMenuItem (execute)="library.copyReferenceToClipboard($event.item, collection)">Formatted {{items.length > 1 ? 'References' : 'Reference'}}</ng-template>
    <ng-container *ngIf="canCopyCitekey">
      <ng-template contextMenuItem divider="true"></ng-template>
      <ng-template contextMenuItem (execute)="library.copyCitekeyToClipboard($event.item)">{{items.length > 1 ? 'Citekeys' : 'Citekey'}}</ng-template>
    </ng-container>
    <ng-container *ngIf="items.length === 1">
      <ng-template contextMenuItem divider="true" *ngIf="item.article.title || item.article.abstract || item.article.journal || item.article.authors?.length"></ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].article.title })" [visible]="item.article.title">Title</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].article.abstract })" [visible]="item.article.abstract">Abstract</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].article.journal })" [visible]="item.article.journal">Journal</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].article.authors })" [visible]="item.article.authors?.length">Authors</ng-template>
      <ng-template contextMenuItem divider="true" *ngIf="library.isIdentified(item)"></ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].ext_ids.patent_id })" [visible]="item.ext_ids.patent_id">Patent ID</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].ext_ids.doi })" [visible]="item.ext_ids.doi">DOI</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].ext_ids.pmid })" [visible]="item.ext_ids.pmid">PMID</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].ext_ids.pmcid })" [visible]="item.ext_ids.pmcid">PMCID</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].ext_ids.arxiv })" [visible]="item.ext_ids.arxiv">arXiv Identifier</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].article.isbn })" [visible]="item.article.isbn">ISBN</ng-template>
      <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].article.issn })" [visible]="item.article.issn">ISSN</ng-template>
    </ng-container>
  </ng-container>
</context-menu>

<context-menu #mergeSubMenu>
  <ng-template contextMenuItem *ngFor="let item of items" (execute)="merge(items, item)">{{item.article.title || 'Untitled'}}</ng-template>
</context-menu>

<context-menu #exportSubMenu>
  <ng-template contextMenuItem (execute)="exportBIB($event.item)">BibTeX (.bib)</ng-template>
  <ng-template contextMenuItem (execute)="exportRIS($event.item)">RefMan (.ris)</ng-template>
  <ng-template contextMenuItem (execute)="exportCSV($event.item)">Comma-separated Values (.csv)</ng-template>
  <ng-template contextMenuItem (execute)="exportXLSX($event.item)">Microsoft Excel (.xlsx)</ng-template>
  <ng-template contextMenuItem (execute)="exportJSON($event.item)">JavaScript Object Notation (.json)</ng-template>
</context-menu>

<context-menu #setCopyrightStatusSubmenu>
  <ng-template contextMenuItem (execute)="setCopyrightStatus($event.item, 'covered')" [checked]="items[0]?.user_data?.copyright_status === 'covered'">Green</ng-template>
  <ng-template contextMenuItem (execute)="setCopyrightStatus($event.item, 'not_covered')" [checked]="items[0]?.user_data?.copyright_status === 'not_covered'">Red</ng-template>
  <ng-template contextMenuItem (execute)="setCopyrightStatus($event.item, null)" [checked]="!items[0]?.user_data?.copyright_status">None</ng-template>
</context-menu>

<context-menu #literatureReviewSubMenu>
  <ng-template contextMenuItem (execute)="addToLiteratureReview($event.item)">Add to Existing Project</ng-template>
  <ng-template contextMenuItem (execute)="shared.openLiteratureReview()">Go to Projects</ng-template>
</context-menu>
