<form (submit)="submit()" [formGroup]="form">

  <div class="modal-header">
    <h4 class="modal-title">Edit tags</h4>
    <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body">
    <div *ngIf="error$ | async as error" class="alert alert-danger">{{error}}</div>

    <div class="row">
      <div class="col-sm-12">
        <rcp-token-field
          #tagsInput
          name="tags-input"
          class="form-control"
          [search]="search$"
          [(text)]="text"
          [focusFirst]="false"
          [maxLength]="50"
          formControlName="tags"
          placeholder="Enter your tags here...">

          <ng-template #tokenTemplate let-tag="item">
            <span>{{tag}}</span>
          </ng-template>

          <ng-template #resultTemplate let-result="result" let-term="term">
            <span>{{result}}</span>
          </ng-template>
        </rcp-token-field>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-link me-auto text-decoration-none text-danger" (click)="removeAllTags()"><fa-icon [icon]="['fas', 'trash']"></fa-icon> Remove All Tags</button>
    <button type="button" class="btn btn-lg btn-secondary" (click)="close()" [disabled]="disabled$ | async">Close</button>
    <button type="button" class="btn btn-lg btn-primary" (click)="submit()" [disabled]="disabled$ | async">{{saving ? 'Saving...' : 'Save'}}</button>
  </div>

</form>