<form  [formGroup]="form">
  <!-- <pre>{{form.value|json}}</pre> -->

  <div formGroupName="words">
    <label class="papers-label mb-0">Words</label>

    <ng-container *ngFor="let ctrl of form.get('words')?.controls; let i = index;" [formGroupName]="i">
      <query-label-dropdown *ngIf="i > 0" [options]="{ 'AND': 'And', 'OR': 'Or' }" formControlName="operator"></query-label-dropdown>

      <div class="row mt-1">
        <div class="col-sm-10">
          <query-words #wordValue formControlName="value"></query-words>
        </div>
        <div class="col-sm-2">
          <button type="button" class="btn btn-secondary float-end ms-1" (click)="removeWords(i)"><fa-icon [icon]="['fas', 'trash']"></fa-icon></button>
          <button type="button" class="btn btn-secondary float-end" (click)="addWords(i+1, QueryOperator.And)"><fa-icon [icon]="['fas', 'plus']"></fa-icon></button>
        </div>
      </div>
    </ng-container>

    <div class="row mt-2" *ngIf="!form.get('words')?.controls.length">
      <div class="col-sm-4">
        <button type="button" class="form-control btn btn-secondary float-end" (click)="addWords()"><fa-icon [icon]="['fas', 'plus']"></fa-icon> Add</button>
      </div>
    </div>
  </div>



  <div formGroupName="fields" class="mt-5">
    <label class="papers-label mb-0">Field</label>

    <ng-container *ngFor="let ctrl of form.get('fields')?.controls; let i = index;" [formGroupName]="i">
      <query-label-dropdown *ngIf="i > 0" [options]="{ 'AND': 'And', 'OR': 'Or' }" formControlName="operator"></query-label-dropdown>

      <div class="row mt-1">
        <div class="col-sm-4">
          <query-field-select [fields]="fields" [customFields]="customFields" formControlName="field" (ngModelChange)="updateFormGroupValue($event, i)"></query-field-select>
        </div>

        <div class="col-sm-6" *ngIf="ctrl.get('field')?.value as field">
          <query-field-value #fieldValue formControlName="value" [field]="field" [fieldInputCapabilities]="fieldInputCapabilities" [fieldInputOptions]="fieldInputOptions"></query-field-value>
        </div>

        <div class="col-sm-2">
          <button type="button" class="btn btn-secondary float-end ms-1" (click)="removeField(i)"><fa-icon [icon]="['fas', 'trash']"></fa-icon></button>
          <button type="button" class="btn btn-secondary float-end" (click)="addField(i+1, QueryOperator.And)"><fa-icon [icon]="['fas', 'plus']"></fa-icon></button>
        </div>
      </div>
    </ng-container>

    <div class="row mt-2" *ngIf="!form.get('fields')?.controls.length">
      <div class="col-sm-4">
        <button type="button" class="form-control btn btn-secondary" (click)="addField()"><fa-icon [icon]="['fas', 'plus']"></fa-icon> Add</button>
      </div>
    </div>
  </div>

</form>
