import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDataArticle } from '../../search-data';
import { DataDimensionsApiService, IDataDimensionsSearchParams } from './data-dimensions-api.service';

@Injectable()
export class DataDimensionsService {
  constructor(
    public api: DataDimensionsApiService
  ) { }

  search(params: IDataDimensionsSearchParams): Observable<Partial<IDataArticle>[]> {
    return this.api.search(params);
  };
}
