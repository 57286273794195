<div class="modal-header mb-4" style="min-width: 600px">
  <h4 class="modal-title">Import files</h4>
</div>



<ng-container *ngIf="currentPage === 1">
  <div class="modal-body" style="width: 600px">
    <div class="mt-4">
      <rcp-importer-source-select
        [availableImportTypes]="availableImportTypes"
        [(importType)]="selectedImportType"
        [(selectedFiles)]="selectedFiles">
      </rcp-importer-source-select>
    </div>

    <div class="mt-4">
      <rcp-importer-destination-select
        [(collectionId)]="collectionId"
        [(listId)]="listId"
        [showLists]="true">
      </rcp-importer-destination-select>
    </div>
  </div>

  <div class="modal-footer mt-4">
    <button type="button" class="btn btn-lg me-1 btn-secondary" (click)="close()">Cancel</button>
    <button type="button" class="btn btn-lg btn-primary" (click)="next()" [disabled]="!canNext()">
      Next <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
    </button>
  </div>
</ng-container>



<ng-container *ngIf="currentPage === 2">
  <div class="modal-body" [style.width.px]="modalWidth" [ngSwitch]="selectedImportType?.type">
    <ng-container *ngIf="currentPage === 2 && hasTemplatesEnabled">
      <rcp-common-templates-menu
        class="pull-end"
        [collectionId]="collectionId"
        [type]="selectedImportType?.type"
        [options]="options"
        (optionsChange)="loadOptions($event)">
      </rcp-common-templates-menu>
    </ng-container>

    <rcp-importer-error-banner [error]="error" *ngIf="error && showError"></rcp-importer-error-banner>

    <rcp-importer-options-pdf #optionsRef [collectionId]="collectionId" [files]="selectedFiles" [(options)]="options" [(error)]="error" *ngSwitchCase="'pdf'"></rcp-importer-options-pdf>
    <rcp-importer-options-bib #optionsRef [collectionId]="collectionId" [files]="selectedFiles" [(options)]="options" [(error)]="error" *ngSwitchCase="'bib'"></rcp-importer-options-bib>
    <rcp-importer-options-ris #optionsRef [collectionId]="collectionId" [files]="selectedFiles" [(options)]="options" [(error)]="error" *ngSwitchCase="'ris'"></rcp-importer-options-ris>
    <rcp-importer-options-nbib #optionsRef [collectionId]="collectionId" [files]="selectedFiles" [(options)]="options" [(error)]="error" *ngSwitchCase="'nbib'"></rcp-importer-options-nbib>
    <rcp-importer-options-csv #optionsRef [collectionId]="collectionId" [files]="selectedFiles" [(options)]="options" [(error)]="error" *ngSwitchCase="'csv'"></rcp-importer-options-csv>
    <rcp-importer-options-media #optionsRef [collectionId]="collectionId" [files]="selectedFiles" [(options)]="options" [(error)]="error" *ngSwitchCase="'media'"></rcp-importer-options-media>
  </div>

  <div class="modal-footer mt-4">
    <button type="button" class="btn btn-lg me-1 btn-secondary" (click)="back()">
      <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon> Back
    </button>
    <button type="button" class="btn btn-lg btn-primary" (click)="import()">
      <fa-icon [icon]="['fas', 'file-import']"></fa-icon> Import
    </button>
  </div>
</ng-container>
