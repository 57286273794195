import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, map, reduce, takeUntil } from 'rxjs';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import { asBib, asRis } from '@readcube/rcp-citation-export';
import { asCiteprocItem } from '@readcube/readcube-citeproc';
import { ItemToTablesConverter, toCsv, toXlsx } from '@readcube/rcp-csv-items';

import { ITemplateOptionsCsv, SharedService } from '../../common';
import { DialogService } from 'src/app/common/services/dialog.service';
import { DataItemService, DataListService, IDataCollection, IDataItem, IDataList } from '../../library-data';
import { IDataArticle } from '../../search-data';
import { AppShellService } from '../../app-shell.service';

import { environment } from 'environment';

export type EXPORT_TYPE_ID = 'csv' | 'bib' | 'ris' | 'xlsx' | 'json';

const EXPORT_MIME_TYPES = {
  RIS: 'application/x-research-info-systems',
  BIB: 'application/x-bibtex',
  CSV: 'text/csv;charset=utf-8',
  TEXT: 'text/plain;charset=utf-8',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export interface IExportType {
  type: EXPORT_TYPE_ID,
  name: string,
  description: string,
  visible: boolean,
}

export interface IExportArticlesParams {
  articles: IDataArticle[];
}

export interface IExportItemsParams {
  type?: EXPORT_TYPE_ID;
  collection?: IDataCollection;
  list?: IDataList;
  items?: Partial<IDataItem>[];
  options?: ITemplateOptionsCsv;
}

@Injectable()
export class ExporterService {
  constructor(
    protected http: HttpClient,
    protected modal: NgbModal,
    protected dataItem: DataItemService,
    protected dataList: DataListService,
    protected shared: SharedService,
    protected dialog: DialogService,
    protected shell: AppShellService,
  ) { }

  openExportItemsDialog(params: IExportItemsParams, path?: string[]) {
    if (!path) {
      if (params.type === 'csv' && !!params.collection) {
        path = ['exporter', 'options', 'csv'];
      } else {
        path = ['exporter', 'options'];
      }
    }
    this.dialog.openDialog(path, {
      params: JSON.stringify(params)
    });
  }

  closeExportItemsDialog() {
    this.dialog.closeDialog();
  }

  async getAvailableExportTypes(params: IExportItemsParams): Promise<IExportType[]> {
    return [
      {
        type: 'bib',
        name: 'Bibliography file (.bib)',
        description: '',
        visible: true,
      },
      {
        type: 'ris',
        name: 'Research Information Systems (.ris)',
        description: '',
        visible: true,
      },
      {
        type: 'csv',
        name: 'Comma-separated values (.csv)',
        description: '',
        visible: true,
      },
      {
        type: 'xlsx',
        name: 'Microsoft Excel (.xlsx)',
        description: '',
        visible: true,
      },
      {
        type: 'json',
        name: 'JavaScript Object Notation (.json)',
        description: '',
        visible: !!params.items?.length,
      },
    ];
  }

  async export(params: IExportItemsParams): Promise<void> {
    if (params.items) {
      switch (params.type) {
        case 'bib':
          return this.exportBIB(params);
        case 'csv':
          return this.exportCSV(params);
        case 'ris':
          return this.exportRIS(params);
        case 'xlsx':
          return this.exportXLSX(params);
        case 'json':
          return this.exportJSON(params);
      }
    }
    if (params.list) {
      switch (params.type) {
        case 'bib':
          return this.exportListBIB(params);
        case 'csv':
          return this.exportListCSV(params);
        case 'ris':
          return this.exportListRIS(params);
        case 'xlsx':
          return this.exportListXLSX(params);
      }
    }
    if (params) {
      switch (params.type) {
        case 'bib':
          return this.exportLibraryBIB(params);
        case 'csv':
          return this.exportLibraryCSV(params);
        case 'ris':
          return this.exportLibraryRIS(params);
        case 'xlsx':
          return this.exportLibraryXLSX(params);
      }
    }
  }

  async exportArticlesToBIB(params: IExportArticlesParams): Promise<void> {
    return this.exportBIB({
      items: this.mapArticlesToItem(params.articles)
    });
  }

  async exportArticlesToRIS(params: IExportArticlesParams): Promise<void> {
    return this.exportRIS({
      items: this.mapArticlesToItem(params.articles)
    });
  }

  async exportArticlesToJSON(params: IExportArticlesParams): Promise<void> {
    return this.exportJSON({
      items: this.mapArticlesToItem(params.articles)
    });
  }

  async exportBIB(params: IExportItemsParams): Promise<void> {
    const newLine = '\r\n';
    const fileName = this.getExportFileName(params.items, 'bib');
    const mapping = params.collection?.custom_type_schema;
    return Promise.all(params.items.map(item => {
      return asBib({ item, mapping, newLine }).concat(newLine);
    })).then(res => {
      return this.saveBlobParts(fileName, res, EXPORT_MIME_TYPES.BIB);
    });
  }

  async exportRIS(params: IExportItemsParams): Promise<void> {
    const newLine = '\r\n';
    const fileName = this.getExportFileName(params.items, 'ris');
    const mapping = params.collection?.custom_type_schema;
    const baseAppUrl = `https:${environment.baseUrls.webapp}`;
    const baseWebUrl = `https:${environment.baseUrls.readcube}`;
    return Promise.all(params.items.map(item => {
      return asRis({ item, mapping, newLine, baseAppUrl, baseWebUrl }).concat(newLine);
    })).then(res => {
      return this.saveBlobParts(fileName, res, EXPORT_MIME_TYPES.RIS);
    });
  }

  async exportCSV(params: IExportItemsParams): Promise<void> {
    const fileName = this.getExportFileName(params.items, 'csv');
    let csvText = toCsv(params.items, {
      customFields: params.collection.custom_fields,
      customSchema: params.collection.custom_type_schema,
      baseAppUrl: `https:${environment.baseUrls.webapp}`,
      baseWebUrl: `https:${environment.baseUrls.readcube}`,
      allowCopyrightStatus: this.shared.user?.licence?.copyright_show_status,
    });
    csvText = this.customizeExportCSV(csvText, params.options);
    return this.saveBlobParts(fileName, [csvText], EXPORT_MIME_TYPES.CSV);
  }

  async exportXLSX(params: IExportItemsParams): Promise<void> {
    const fileName = this.getExportFileName(params.items, 'xlsx');
    const buffer = toXlsx(params.items, {
      customFields: params.collection.custom_fields,
      customSchema: params.collection.custom_type_schema,
      baseAppUrl: `https:${environment.baseUrls.webapp}`,
      baseWebUrl: `https:${environment.baseUrls.readcube}`,
      allowCopyrightStatus: this.shared.user?.licence?.copyright_show_status,
    });
    const parts = [new Uint8Array(buffer)];
    const blob = new Blob(parts, { type: 'application/xlsx' });
    return this.saveBlobParts(fileName, [blob], 'application/xlsx;charset=utf-8');
  }

  async exportJSON(params: IExportItemsParams): Promise<void> {
    return Promise.all(params.items.map(item => asCiteprocItem({
      customSchema: params.collection?.custom_type_schema,
      customFields: params.collection?.custom_fields,
      item,
    }))).then(res => {
      const json = JSON.stringify(res, null, '\t');
      const fileName = this.getExportFileName(params.items, 'json');
      return this.saveBlobParts(fileName, [json], 'application/json;charset=utf-8');
    });
  }

  async exportListBIB(params: IExportItemsParams): Promise<void> {
    const newLine = '\r\n';
    const mapping = params.collection.custom_type_schema;
    const fileName = `${params.list.name}.bib`;
    const progressModal = await this.shell.showProgress();

    const stream$ = this.dataItem.queryAll({
      collection_id: params.collection.id,
      list_id: params.list.id,
    }).pipe(
      reduce((chunks, result) => {
        progressModal.title = 'Exporting your list...';
        progressModal.stoppable = true;
        progressModal.progress$.next(chunks.length / result.total * 100);
        progressModal.message$.next(`Processed <b>${chunks.length}</b> out of <b>${result.total}</b> items...`);
        const chunk = result.rows.map(item => {
          return asBib({ item, mapping, newLine }).concat(newLine);
        });
        return chunks.concat(chunk);
      }, []),
      takeUntil(progressModal.stop$)
    );

    const bibText = await firstValueFrom(stream$);
    this.saveBlobParts(fileName, bibText, EXPORT_MIME_TYPES.BIB);
    progressModal.close();
  };

  async exportListRIS(params: IExportItemsParams): Promise<void> {
    const newLine = '\r\n';
    const mapping = params.collection.custom_type_schema;
    const baseAppUrl = `https:${environment.baseUrls.webapp}`;
    const baseWebUrl = `https:${environment.baseUrls.readcube}`;
    const fileName = `${params.list.name}.ris`;
    const progressModal = await this.shell.showProgress();

    const stream$ = this.dataItem.queryAll({
      collection_id: params.collection.id,
      list_id: params.list.id,
    }).pipe(
      reduce((chunks, result) => {
        progressModal.title = 'Exporting your list...';
        progressModal.stoppable = true;
        progressModal.progress$.next(chunks.length / result.total * 100);
        progressModal.message$.next(`Processed <b>${chunks.length}</b> out of <b>${result.total}</b> items...`);
        const chunk = result.rows.map(item => {
          return asRis({ item, mapping, newLine, baseAppUrl, baseWebUrl })
            .concat(newLine);
        });
        return chunks.concat(chunk);
      }, []),
      takeUntil(progressModal.stop$)
    );

    const risText = await firstValueFrom(stream$);
    this.saveBlobParts(fileName, risText, EXPORT_MIME_TYPES.RIS);
    progressModal.close();
  };

  async exportListCSV(params: IExportItemsParams): Promise<void> {
    const customFields = params.collection.custom_fields;
    const customSchema = params.collection.custom_type_schema;
    const baseAppUrl = `https:${environment.baseUrls.webapp}`;
    const baseWebUrl = `https:${environment.baseUrls.readcube}`;
    const allowCopyrightStatus = this.shared.user?.licence?.copyright_show_status;
    const csvOptions = { customFields, customSchema, baseAppUrl, baseWebUrl, allowCopyrightStatus };
    const fileName = `${params.list.name}.csv`;
    const progressModal = await this.shell.showProgress();

    const stream$ = this.dataItem.queryAll({
      collection_id: params.collection.id,
      list_id: params.list.id,
    }).pipe(
      reduce((csv, result) => {
        progressModal.title = 'Exporting your library...';
        progressModal.stoppable = true;
        progressModal.progress$.next(csv.rows.length / result.total * 100);
        progressModal.message$.next(`Processed <b>${csv.rows.length}</b> out of <b>${result.total}</b> items...`);
        csv.addItems(result.rows);
        return csv;
      }, new ItemToTablesConverter(csvOptions)),
      takeUntil(progressModal.stop$),
      map(csv => csv.getCsv())
    );

    let csvText = await firstValueFrom(stream$);
    csvText = this.customizeExportCSV(csvText, params.options);
    this.saveBlobParts(fileName, [csvText], EXPORT_MIME_TYPES.CSV);
    progressModal.close();
  };

  async exportListXLSX(params: IExportItemsParams): Promise<void> {
    const customFields = params.collection.custom_fields;
    const customSchema = params.collection.custom_type_schema;
    const baseAppUrl = `https:${environment.baseUrls.webapp}`;
    const baseWebUrl = `https:${environment.baseUrls.readcube}`;
    const allowCopyrightStatus = this.shared.user?.licence?.copyright_show_status;
    const csvOptions = { customFields, customSchema, baseAppUrl, baseWebUrl, allowCopyrightStatus };
    const fileName = `${params.list.name}.xlsx`;
    const progressModal = await this.shell.showProgress();

    const stream$ = this.dataItem.queryAll({
      collection_id: params.collection.id,
      list_id: params.list.id,
    }).pipe(
      reduce((csv, result) => {
        progressModal.title = 'Exporting your library...';
        progressModal.stoppable = true;
        progressModal.progress$.next(csv.rows.length / result.total * 100);
        progressModal.message$.next(`Processed <b>${csv.rows.length}</b> out of <b>${result.total}</b> items...`);
        csv.addItems(result.rows);
        return csv;
      }, new ItemToTablesConverter(csvOptions)),
      takeUntil(progressModal.stop$),
      map(csv => csv.getXlsx())
    );

    const xlsxText = await firstValueFrom(stream$);
    this.saveBlobParts(fileName, [xlsxText], EXPORT_MIME_TYPES.XLSX);
    progressModal.close();
  };

  async exportLibraryBIB(params: IExportItemsParams): Promise<any> {
    const newLine = '\r\n';
    const mapping = params.collection.custom_type_schema;
    const fileName = `${params.collection.name || 'My Library'}.bib`;
    const progressModal = await this.shell.showProgress();

    const stream$ = this.dataItem.queryAll({
      collection_id: params.collection.id
    }).pipe(
      reduce((chunks, result) => {
        progressModal.title = 'Exporting your library...';
        progressModal.stoppable = true;
        progressModal.progress$.next(chunks.length / result.total * 100);
        progressModal.message$.next(`Processed <b>${chunks.length}</b> out of <b>${result.total}</b> items...`);
        const chunk = result.rows.map(item => {
          return asBib({ item, mapping, newLine }).concat(newLine);
        });
        return chunks.concat(chunk);
      }, []),
      takeUntil(progressModal.stop$)
    );

    const bibText = await firstValueFrom(stream$);
    this.saveBlobParts(fileName, bibText, EXPORT_MIME_TYPES.BIB);
    progressModal.close();
  };

  async exportLibraryRIS(params: IExportItemsParams): Promise<void> {
    const newLine = '\r\n';
    const mapping = params.collection.custom_type_schema;
    const baseAppUrl = `https:${environment.baseUrls.webapp}`;
    const baseWebUrl = `https:${environment.baseUrls.readcube}`;
    const fileName = `${params.collection.name || 'My Library'}.ris`;
    const progressModal = await this.shell.showProgress();

    const stream$ = this.dataItem.queryAll({
      collection_id: params.collection.id
    }).pipe(
      reduce((chunks, result) => {
        progressModal.title = 'Exporting your library...';
        progressModal.stoppable = true;
        progressModal.progress$.next(chunks.length / result.total * 100);
        progressModal.message$.next(`Processed <b>${chunks.length}</b> out of <b>${result.total}</b> items...`);
        const chunk = result.rows.map(item => {
          return asRis({ item, mapping, newLine, baseAppUrl, baseWebUrl })
            .concat(newLine);
        });
        return chunks.concat(chunk);
      }, []),
      takeUntil(progressModal.stop$)
    );

    const risText = await firstValueFrom(stream$);
    this.saveBlobParts(fileName, risText, EXPORT_MIME_TYPES.RIS);
    progressModal.close();
  };

  async exportLibraryCSV(params: IExportItemsParams): Promise<void> {
    const customFields = params.collection.custom_fields;
    const customSchema = params.collection.custom_type_schema;
    const baseAppUrl = `https:${environment.baseUrls.webapp}`;
    const baseWebUrl = `https:${environment.baseUrls.readcube}`;
    const allowCopyrightStatus = this.shared.user?.licence?.copyright_show_status;
    const csvOptions = { customFields, customSchema, baseAppUrl, baseWebUrl, allowCopyrightStatus };
    const fileName = `${params.collection.name || 'My Library'}.csv`;
    const progressModal = await this.shell.showProgress();

    const stream$ = this.dataItem.queryAll({
      collection_id: params.collection.id
    }).pipe(
      reduce((csv, result) => {
        progressModal.title = 'Exporting your library...';
        progressModal.stoppable = true;
        progressModal.progress$.next(csv.rows.length / result.total * 100);
        progressModal.message$.next(`Processed <b>${csv.rows.length}</b> out of <b>${result.total}</b> items...`);
        csv.addItems(result.rows);
        return csv;
      }, new ItemToTablesConverter(csvOptions)),
      takeUntil(progressModal.stop$),
      map(csv => csv.getCsv())
    );

    let csvText = await firstValueFrom(stream$);
    csvText = this.customizeExportCSV(csvText, params.options);
    this.saveBlobParts(fileName, [csvText], EXPORT_MIME_TYPES.CSV);
    progressModal.close();
  }

  async exportLibraryXLSX(params: IExportItemsParams): Promise<void> {
    const customFields = params.collection.custom_fields;
    const customSchema = params.collection.custom_type_schema;
    const baseAppUrl = `https:${environment.baseUrls.webapp}`;
    const baseWebUrl = `https:${environment.baseUrls.readcube}`;
    const allowCopyrightStatus = this.shared.user?.licence?.copyright_show_status;
    const xlsxOptions = { customFields, customSchema, baseAppUrl, baseWebUrl, allowCopyrightStatus };
    const fileName = `${params.collection.name || 'My Library'}.xlsx`;
    const progressModal = await this.shell.showProgress();

    const stream$ = this.dataItem.queryAll({
      collection_id: params.collection.id
    }).pipe(
      reduce((csv, result) => {
        progressModal.title = 'Exporting your library...';
        progressModal.stoppable = true;
        progressModal.progress$.next(csv.rows.length / result.total * 100);
        progressModal.message$.next(`Processed <b>${csv.rows.length}</b> out of <b>${result.total}</b> items...`);
        csv.addItems(result.rows);
        return csv;
      }, new ItemToTablesConverter(xlsxOptions)),
      takeUntil(progressModal.stop$),
      map(csv => csv.getXlsx())
    );

    const xlsxText = await firstValueFrom(stream$);
    this.saveBlobParts(fileName, [xlsxText], EXPORT_MIME_TYPES.XLSX);
    progressModal.close();
  }

  protected mapArticlesToItem(articles: IDataArticle[]): Partial<IDataItem>[] {
    return articles.map(article => {
      return {
        article: { ...article, authors: article.authors.map(a => a.name) },
        ext_ids: article.ext_ids
      };
    });
  }

  protected saveBlobParts(fileName: string, entries: BlobPart[], type: string = EXPORT_MIME_TYPES.TEXT) {
    const blob = new Blob(entries, { type });
    saveAs(blob, fileName);
  }

  protected customizeExportCSV(csvText: string, options: ITemplateOptionsCsv): string {
    if (!options.customizeExport) {
      const parsedResult = Papa.parse(csvText, { header: true });
      return Papa.unparse(parsedResult.data, {
        header: options.exportHeader,
        delimiter: options.exportDelimiter || ',',
        columns: parsedResult.meta.fields
      });
    }

    const customMappings = options.csvMapping.filter(opt => opt.enabled);
    const parsedResult = Papa.parse(csvText, {
      header: true,
      transformHeader(header) {
        const customMapping = customMappings.find(opt => opt.key === header);
        if (options.customizeHeader && customMapping?.exportHeader) {
          return customMapping.exportHeader;
        }
        return header;
      }
    });

    const enabledColumns = parsedResult.meta.fields.filter(header => {
      return customMappings.find(opt => opt.exportHeader == header)
    });

    return Papa.unparse(parsedResult.data, {
      header: options.exportHeader,
      delimiter: options.exportDelimiter || ',',
      columns: enabledColumns,
    });
  }

  protected getExportFileName(items: any[], ext: string): string {
    if (items.length != 1) {
      const date = new Date();
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      return `export_${y}-${m}-${d}.${ext}`;
    }
    const item = items[0];
    const authors = item.article.authors ?? [];
    const name: string[] = [];
    if (authors.length > 0) {
      name.push(authors[0].split(/[\s]+/).pop());
    } else {
      name.push('anonymous');
    }
    if (item.article.year) {
      name.push(item.article.year.toString());
    }
    return name.join('')
      .toLowerCase()
      .replace(/\W/g, '')
      .trim()
      .concat('.', ext);
  }
}
