import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ITemplateOptionsCsv } from '../../common';
import { ExporterService, IExportItemsParams } from '../services/exporter.service';
import { ExporterOptionsCsvComponent } from './exporter-options-csv.component';

@Component({
  templateUrl: './exporter-modal-options-csv.component.html'
})
export class ExporterModalOptionsCsvComponent implements OnInit {
  params: IExportItemsParams;

  @ViewChild('optionsRef', { static: false })
  optionsRef: ExporterOptionsCsvComponent;

  constructor(
    public route: ActivatedRoute,
    public exporter: ExporterService
  ) {
    this.params = JSON.parse(this.route.snapshot.queryParams['params']);
  }

  get modalWidth(): number {
    switch (this.params.type) {
      case 'csv':
        return 800;
      default:
        return 600;
    }
  }

  get hasTemplatesEnabled(): boolean {
    return ['csv'].includes(this.params.type);
  }

  ngOnInit() {
    this.exporter.getAvailableExportTypes(this.params).then(types => {
      this.params.type = types.find(t => t.type === this.params.type).type;
    });
  }

  loadOptions(options: ITemplateOptionsCsv) {
    if (this.optionsRef.loadOptions) {
      this.optionsRef.loadOptions(options);
    }
  }

  back() {
    this.exporter.openExportItemsDialog(this.params, ['exporter', 'options']);
  }

  close() {
    this.exporter.closeExportItemsDialog();
  }

  export() {
    this.exporter.export(this.params);
    this.exporter.closeExportItemsDialog();
  }
}
