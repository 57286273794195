import { ChangeDetectionStrategy, Component, ElementRef, Input,
  QueryList, ViewChild, ViewChildren, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QueryField } from '../services/query-builder.service';

@Component({
  selector: 'query-field-select',
  styleUrls: ['./query-field-select.component.scss'],
  templateUrl: './query-field-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QueryFieldSelectComponent),
      multi: true
    }
  ]
})
export class QueryFieldSelectComponent implements ControlValueAccessor {
  value: QueryField;
  onChange: (value: QueryField) => any;
  onTouched: () => any;

  @Input()
  fields: QueryField[] = [];

  @Input()
  customFields: QueryField[] = [];

  @Input()
  searchTerm = '';

  @ViewChild('searchInput')
  protected searchInput: ElementRef;

  @ViewChildren('fieldOption')
  protected fieldOptions: QueryList<ElementRef>;

  @ViewChildren('customFieldOption')
  protected customFieldOptions: QueryList<ElementRef>;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: QueryField) {
    this.value = value;
  }

  matchSearchTerm(field: QueryField): boolean {
    const term = this.searchTerm.toLowerCase();
    const key = (field.key||'').toLowerCase();
    const display = (field.display||'').toLowerCase();
    return display.includes(term) || key.includes(term);
  }

  selectField(value: QueryField) {
    this.value = value;
    this.onChange(this.value);
    this.onTouched();
  }

  focus() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }
}
