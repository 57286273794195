import { Directive, forwardRef } from '@angular/core';
import { NG_ASYNC_VALIDATORS, AsyncValidator, FormControl } from '@angular/forms';
import { of as observableOf } from 'rxjs';

import { DataCollectionService, IDataCollectionEmail } from '../../library-data';

@Directive({
  selector: '[emailInEnterpriseValidator][ngModel]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => EmailInEnterpriseValidator),
      multi: true
    }
  ]
})
export class EmailInEnterpriseValidator implements AsyncValidator {
  cache = new Map<string, IDataCollectionEmail>();

  constructor(
    private dataCollection: DataCollectionService
  ) { }

  validate(control: FormControl) {
    let checkEmails: string[];
    if (control.value instanceof Array) {
      checkEmails = control.value;
    } else if (control.value) {
      checkEmails = [control.value];
    } else {
      return observableOf(null);
    }
    if (!checkEmails.length) {
      return observableOf(null);
    }
    return this.dataCollection.api.checkEmails(checkEmails)
      .then(emailsInfo => emailsInfo.filter(info => info.error == 'not_in_organization').map(info => info.email))
      .then(emails => emails.length ? { emailNotInEnterprise: emails } : null);
  }
}
