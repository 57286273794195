<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>

<div class="modal-body">
  <p [innerHtml]="message"></p>

  <ul *ngIf="displayList.length">
    <li *ngFor="let item of displayList">{{item}}</li>
  </ul>

  <div *ngIf="checkboxLabel" class="form-check">
    <input class="form-check-input" type="checkbox" id="setting" (change)="onCheckboxChange($event)">
    <label class="form-check-label" for="setting">{{checkboxLabel}}</label>
  </div>
</div>

<div class="modal-footer">
  <button *ngIf="showCancel" type="button" class="btn btn-lg btn-secondary" (click)="cancel()" ngbAutofocus>{{cancelButtonText}}</button>
  <button type="button" class="btn btn-lg btn-primary" (click)="confirm()">{{confirmButtonText}}</button>
</div>
