import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SearchService } from '../services/search.service';

@Component({
  templateUrl: './search-landing.component.html',
  styleUrls: ['./search-landing.component.scss']
})
export class SearchLandingComponent implements AfterViewInit {
  @ViewChild('searchInput', { static: true })
  inputRef: ElementRef;

  @ViewChild('searchBtn', { static: true })
  btnRef: ElementRef;

  @Input()
  selectedQueryType = '';
  
  @Input()
  query = '';

  constructor(public searchService: SearchService) {
    this.selectedQueryType = searchService.queryTypes[0].key;
  }

  ngAfterViewInit() {
    this.inputRef.nativeElement?.focus({ preventScroll: true });
  }

  onSearchEnterKey(event?: KeyboardEvent): void {
    this.btnRef.nativeElement.click();
  }

  get isBtnDisabled(): boolean {
    return !this.query;
  }

  onChange(value: string): void {
    this.query = value.trim();
  }
}