import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface IModalBulkLocatePDFState {
  confirmed: boolean;
  skipItemsWithFile?: boolean;
  faliedListEnabled: boolean;
  failedListName?: string;
}

@Component({
  templateUrl: './modal-bulk-locate-pdf.component.html'
})
export class ModalBulkLocatePDFComponent {

  state: IModalBulkLocatePDFState = {
    confirmed: false,
    skipItemsWithFile: true,
    faliedListEnabled: false,
    failedListName: '',
  };

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  submit() {
    this.activeModal.close(Object.assign(this.state, { confirmed: true }));
  }

  close() {
    this.activeModal.close(this.state);
  }
}
