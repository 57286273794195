import { Observable, Subscription, of, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { mergeMap, map, startWith, scan, filter, catchError, tap } from 'rxjs/operators';
import { fromWorker } from 'observable-webworker';
import { readTextFromFile } from '@readcube/rcp-common';
import { asRcItems as parseRis } from '@readcube/ris-parser';
import { asRcItems as parseBib } from '@readcube/bib-parser';
import { asRcItems as parseNbib } from '@readcube/nbib-parser';
import { asRcItems as parseCsv } from '@readcube/rcp-csv-items';

import { IDataBulkProgressEvent, IDataCollection, IDataItem } from '../../library-data';
import { IImportJob, IImportJobSnapshot, ImportAPI, ParserError, ParserErrorRecord, STATUS_FLAG } from './importer.service';
import { WorkerInput } from '../workers/import.worker';
import { detect } from 'detect-browser';
import * as uuid from 'uuid';

interface ParserResponse {
  rcItems: IDataItem[];
  errors: any[];
  warns: any[];
  prettyErrors: ParserErrorRecord[];
  prettyWarns: ParserErrorRecord[];
}

export class ImportMetaJob implements IImportJob {
  public id: string;
  public name: string;
  public detail: string;
  public inProgress$ = new ReplaySubject<boolean>(1);
  public completed$ = new Subject<boolean>();
  public status$: Observable<STATUS_FLAG>;
  public progress$: Observable<number>;
  public errors$: Observable<string[]>;
  public parserError$: Observable<ParserError>;
  public snapshot: IImportJobSnapshot;

  protected statusSource = new ReplaySubject<STATUS_FLAG>(1);
  protected errorsSource = new ReplaySubject<string[]>(1);
  protected parserErrorSource = new ReplaySubject<ParserError>(1);
  protected progressSource = new ReplaySubject<number>(1);
  protected sub: Subscription;

  constructor(
    public file: File,
    public collectionId: string,
    public listId: string = null,
    protected fileType: 'bib' | 'nbib' | 'ris' | 'csv',
    protected api: ImportAPI,
    protected options: any,
  ) {
    this.id = uuid.v4();
    this.name = file.name;
    this.snapshot = { errors: [], progress: 0, status: 'queued' };
    this.status$ = this.statusSource.pipe(
      startWith(<STATUS_FLAG>'queued')
    );
    this.progress$ = this.progressSource.asObservable();
    this.errors$ = this.errorsSource.pipe(
      scan((acc, val) => acc.concat(val))
    );
    this.parserError$ = this.parserErrorSource.asObservable();
  }

  run() {
    const collection$ = this.api.dataCollection.get(this.collectionId);
    const stop$ = this.status$.pipe(
      map(status => status === 'paused'),
      filter(stopped => stopped),
      startWith(false)
    );
    const readPromise = readTextFromFile(this.file);
    this.setStatus('running');

    this.sub = combineLatest([readPromise, collection$]).pipe(
      mergeMap(([text, collection]) => {

        // Handle by worker if browser supports it
        if (detect()?.name !== 'firefox') {
          const workerInput: WorkerInput = {
            text,
            collection,
            fileType: this.fileType,
            options: this.options,
          };
          const workerInstance = new Worker(new URL('../workers/import.worker', import.meta.url), { type: 'module' });
          return fromWorker(() => workerInstance, of(workerInput), null, { terminateOnComplete: true }).pipe(
            map(result => [collection, result])
          );
        }

        // Fallback if no worker
        const parse = {
          'bib': (text: string) => parseBib({ text, hash: this.hasher }),
          'nbib': (text: string) => parseNbib({ text, hash: this.hasher }),
          'ris': (text: string) => parseRis({ text, hash: this.hasher }),
          'csv': (text: string) => parseCsv(text, {
            csvMapping: this.options.csvMapping,
            customFields: collection.custom_fields,
            customSchema: collection.custom_type_schema,
            allowCopyrightStatus: this.options.allowCopyrightStatus
          }),
        }[this.fileType];

        return Promise.all([collection, parse(text)])
      }),
      mergeMap(([collection, results]: [IDataCollection, ParserResponse]) => {
        const isCsv = this.fileType === 'csv';
        const items = results.rcItems.map(item => {
          // Remove tags because server does not handle access rights.
          // https://readcube.atlassian.net/browse/WEBAPP-1879
          if (!collection.user.can_tag_item) {
            item.user_data.tags = [];
          }
          return {
            ...item,
            resolve: isCsv ? this.options?.resolveMetadata : false,
            skip_resolve: isCsv ? !this.options?.resolveMetadata : true,
            merge: this.options?.mergeDuplicates,
            skip_merge: !this.options?.mergeDuplicates
          };
        });
        if (results.errors?.length || results.warns?.length) {
          this.setParserError(this.mapErrorsFromParser(results));
        }
        return this.api.dataItem.bulkPost(this.collectionId, items, this.listId, stop$, isCsv)
          .pipe(tap((result) => this.onImportProgress(result)));
      }),
      catchError(err => {
        console.error(err);
        this.setError('Invalid file format.');
        this.setStatus('failed');
        return of(err);
      })
    ).subscribe();
  }

  stop() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.setStatus('paused');
  }

  mapErrorsFromParser(payload: ParserResponse): ParserError {
    return {
      successCount: payload.rcItems.length,
      errors: payload.prettyErrors,
      warnings: payload.prettyWarns
    };
  }

  protected hasher(str: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    return crypto.subtle.digest('SHA-256', data).then(buffer => {
      const arr = Array.from(new Uint8Array(buffer));
      const hex = arr.map(b => b.toString(16).padStart(2, '0')).join('');
      return hex;
    });
  }

  protected setStatus(status: STATUS_FLAG) {
    const finished = ['completed', 'failed'].includes(status);
    this.snapshot.status = status;
    this.statusSource.next(status);
    this.inProgress$.next(!finished);
    if (status == 'completed') {
      this.completed$.next(true);
    }
    if (finished) {
      this.statusSource.complete();
      this.progressSource.complete();
      this.errorsSource.complete();
      this.inProgress$.complete();
      this.completed$.complete();

      // Clear reference to file binary so GC can do it's thing.
      this.file = null;
    }
  }

  protected setProgress(progress: number) {
    this.snapshot.progress = progress;
    this.progressSource.next(progress);
  }

  protected setError(err: string) {
    this.snapshot.errors.push(err);
    this.errorsSource.next([err]);
  }

  protected setParserError(err: ParserError) {
    this.snapshot.parserError = err;
    this.parserErrorSource.next(err);
  }

  protected onImportProgress(result: IDataBulkProgressEvent<IDataItem[]>) {
    this.setProgress(result.percent);
    if (result.percent === 100) {
      if (result.data.length === 0) {
        this.setError('No valid entries found in file.');
        this.setStatus('failed');
      } else {
        this.setStatus('completed');
      }
    }
  }
}
