import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
  TemplateRef,
  ViewChild,
  HostBinding,
  HostListener,
  ContentChild,
  ElementRef,
  ChangeDetectionStrategy
} from '@angular/core';

import {
  Subscription,
  merge as observableMerge,
  fromEvent as observableFromEvent
} from 'rxjs';

@Component({
  selector: 'list-explorer-button',
  templateUrl: './list-explorer-button.component.html',
  styleUrls: ['./list-explorer-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListExplorerButtonComponent implements OnInit, OnDestroy  {
  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Input()
  expandable = false;

  @Input()
  expanded = false;

  @Input()
  indent = 0;

  @Output()
  expandedChange = new EventEmitter<boolean>();

  @ViewChild('button', { static: true })
  buttonRef: ElementRef;

  @ViewChild('caret', { static: true })
  caretRef: ElementRef;

  @ContentChild('expandedTemplate', { read: TemplateRef, static: true })
  templateRef: TemplateRef<HTMLTemplateElement>;

  expandedToggleSub: Subscription;

  constructor() { }

  ngOnInit() {
    const caretClick$ = observableFromEvent(this.caretRef.nativeElement, 'click', { capture: true,  });
    const dblClick$ = observableFromEvent(this.buttonRef.nativeElement, 'dblclick', { capture: true });

    this.expandedToggleSub = observableMerge(caretClick$, dblClick$).subscribe((event: MouseEvent) => {
      this.expanded = !this.expanded;
      this.expandedChange.emit(this.expanded);
      event.stopPropagation();
    });
  }

  ngOnDestroy() {
    this.expandedToggleSub?.unsubscribe();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopPropagation();
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: Event) {
    event.stopPropagation();
  }
}
