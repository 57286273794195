
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom, map, Subscription } from 'rxjs';
import CitationExportPanel from '@readcube/citation-export-panel';

import { SharedService } from '../../common';
import { ExporterService } from '../../exporter';
import { AppShellService } from '../../app-shell.service';
import { LibrarySidepanelService } from '../services/library-sidepanel.service';
import { DataStylesService } from '../../common-data';
import { IDataCollection, IDataItem } from '../../library-data';

@Component({
  templateUrl: './library-sidepanel-export.component.html',
  styleUrls: ['./library-sidepanel.component.scss']
})
export class LibrarySidepanelExportComponent implements OnInit, OnDestroy {
  item: IDataItem;
  collection: IDataCollection;
  itemSub: Subscription;
  collectionSub: Subscription;

  @ViewChild('styleWidget', { static: true })
  styleWidget: ElementRef;

  cep: any;

  constructor(
    public sidepanel: LibrarySidepanelService,
    public router: Router,
    public route: ActivatedRoute,
    public shared: SharedService,
    public exporter: ExporterService,
    public styles: DataStylesService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.cep = new CitationExportPanel({
      container: this.styleWidget.nativeElement,
      history: { key: this.shared.user.id },
      syncApi: {
        collections: {
          get: ({ collectionId }) => {
            return firstValueFrom(this.sidepanel.collection$.pipe(map(collection => ({ collection }))));
          }
        },
        citations: {
          mergedStyles: () => this.styles.getMergedStyles(),
          info: () => this.styles.getStyleInfo()
        }
      }
    });

    this.cep.init().then(() => {
      this.itemSub = this.sidepanel.item$.subscribe(item => {
        if (!this.item || item.id != this.item.id) {
          this.item = item;
          this.cep.item = item;
        }
      });

      this.cep.evtout.on('copy', (e, text, html) => {
        this.shell.copyToClipboard({ text, html });
      });
      this.cep.evtout.on('edit-item', (e, item) => {
        this.router.navigate([{
          outlets: { 'sidepanel': ['edit'] }
        }], {
          relativeTo: this.route.parent,
          queryParamsHandling: 'merge'
        });
      });
      this.cep.evtout.on('change', (e, style) => {
        const lastUsedStyle = this.cep.getLastUsed({ key: this.shared.user.id });
        this.styles.preloadLastUsedStyle(lastUsedStyle, this.shared.user.id);
      });
    });

    this.collectionSub = this.sidepanel.collection$.subscribe(collection => {
      this.collection = collection;
    });
  }

  ngOnDestroy() {
    this.itemSub?.unsubscribe();
    this.collectionSub?.unsubscribe();
    this.cep.destroy();
  }
}
