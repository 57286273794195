<ng-template #loadingTemplate>
  <common-loader-circles></common-loader-circles>
</ng-template>



<div class="wrapper papers-scrollbar" *ngIf="item">

  <library-sidepanel-header
    [journal]="item.article.journal"
    [year]="item.article.year"
    [title]="item.article.title"
    [chapter]="item.article.chapter">
  </library-sidepanel-header>

  <div class="authors" *ngIf="item.article.authors as authors">
    <ul>
      <li *ngFor="let author of authorsCollapsed ? authors.slice(0, limit) : authors">
        <a href class="text-decoration-none" (click)="onAuthorClick($event, author)">{{item.import_data?.source !== 'janssen_endnote' ? (author | formatAuthor) : author}}</a>
      </li>
    </ul>
  
    <div class="toggle-button" *ngIf="limit < authors.length">
      <a href="#" (click)="onAuthorsToggleClick($event)">
        {{authorsCollapsed ? 'more' : 'less'}}
        <fa-icon [icon]="['fas', authorsCollapsed ? 'caret-down' : 'caret-up']"></fa-icon>
      </a>
    </div>
  </div>
  
  <div class="abstract" *ngIf="item.article.abstract">
    <rcp-katex-paragraph [paragraph]="item.article.abstract"></rcp-katex-paragraph>
  </div>

  <div class="content">
    <div class="left">
      <div class="mb-4">
        <span class="me-2">
          <common-stars-rating [disabled]="!collection?.user?.can_create_item" [showEmpty]="true" [(rating)]="item.user_data.rating" (ratingChange)="setRating($event)"></common-stars-rating>
        </span>

        <span class="me-2">
          <common-color-picker [disabled]="!collection?.user?.can_create_item" [(color)]="item.user_data.color" (colorChange)="setColorLabel($event)"></common-color-picker>
        </span>

        <span class="me-2">
          <common-toggle-flag [disabled]="!collection?.user?.can_create_item" [(value)]="item.user_data.star" (valueChange)="setFlag($event)"></common-toggle-flag>
        </span>
      </div>

      <div class="mb-2">
        <div class="papers-label">Tags
          <span style="padding: 4px" (click)="openEditTags()" ngbTooltip="Add or edit tags">
            <a href *ngIf="collection?.user?.can_tag_item" rcpPreventDefault>
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
            </a>
          </span>
        </div>
        <div *ngIf="item.user_data.tags?.length">
          <span *ngFor="let tag of item.user_data.tags" class="badge papers-badge" [ngbTooltip]="tag" [disableTooltip]="tag?.length < 28" openDelay="700" placement="top">
            <a [routerLink]="['/library', item.collection_id, 'tag', tag, { outlets: { sidepanel: 'details' } }]" queryParamsHandling="merge">{{tag}}</a>
          </span>
        </div>
      </div>

      <div class="mb-2">
        <div class="papers-label">Lists
          <span style="padding: 4px"  (click)="openEditLists()" ngbTooltip="Add or edit lists">
            <a href *ngIf="collection?.user?.can_manage_list" rcpPreventDefault>
              <fa-icon placement="right" [icon]="['fas', 'plus-circle']"></fa-icon>
            </a>
          </span>
        </div>

        <ng-template #listTipTemplate let-listId="listId">
          <ng-container *ngIf="collection.name">{{collection.name}}&nbsp;&raquo; </ng-container>
          <span *ngFor="let list of getListPath(listId) | async; let last = last;">{{list.name}}<ng-container *ngIf="!last">&nbsp;&rsaquo; </ng-container></span>
        </ng-template>

        <div *ngIf="lists$ | async as lists">
          <span *ngFor="let list of lists" class="badge papers-badge" #listTip="ngbTooltip" [ngbTooltip]="listTipTemplate" triggers="manual" placement="top" container="body"
            (mouseenter)="(!!list.parent_id || collection.shared) && listTip.open({ listId: list.id })" (mouseleave)="listTip.close()">
            <a [routerLink]="['/library', item.collection_id, 'list', list.id, { outlets: { sidepanel: 'details' } }]" queryParamsHandling="merge">{{list.name}}</a>
          </span>
        </div>
      </div>

      <div class="mb-2" *ngIf="hasMetrics()">
        <div class="papers-label">Metrics</div>
        <span class="badge papers-badge" *ngIf="item.metrics_data.times_cited">
          <a href (click)="openMetrics()" rcpPreventDefault>{{item.metrics_data.times_cited | number | replace:'.':','}} Citations <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></a>
        </span>
        <span class="badge papers-badge" *ngIf="item.metrics_data.field_citation_ratio">
          <a href (click)="openMetrics()" rcpPreventDefault>{{item.metrics_data.field_citation_ratio | number:'1.2-2'}} FCR <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></a>
        </span>
      </div>

      <div class="mb-2" *ngIf="item.user_data.citekey as citekey">
        <div class="papers-label">Citekey</div>
        <span class="badge papers-badge"  [ngbTooltip]="citekey" openDelay="1000">{{(citekey.length>28)? (citekey | slice:0:28)+'...':(citekey)}}</span>
        <span  class="badge papers-badge" ngbTooltip="Copy to clipboard" openDelay="1000">
          <a href (click)="shell.copyToClipboard({ text: citekey })" rcpPreventDefault><fa-icon [icon]="['fas', 'clipboard']"></fa-icon></a>
        </span>
      </div>

      <div class="mb-2" *ngIf="library.isIdentified(item) || item.custom_metadata?.url">
        <div class="papers-label">Identifiers</div>
        <div *ngFor="let id of item.ext_ids | keyvalue">
          <span  class="badge papers-badge" [ngbTooltip]="id.value" openDelay="1000">
            <a href (click)="openWebByExtId(id.value, id.key)" rcpPreventDefault>{{(id.key === 'patent_id') ? 'patent' : id.key}}: {{id.value | slice:0:24}} <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></a>
          </span>
          <span  class="badge papers-badge" ngbTooltip="Copy to clipboard" openDelay="1000">
            <a href (click)="shell.copyToClipboard({ text: id.value })" rcpPreventDefault><fa-icon [icon]="['fas', 'clipboard']"></fa-icon></a>
          </span>
        </div>
        <span class="badge papers-badge" *ngIf="item.custom_metadata?.url as url">
          <a href (click)="shell.openURL(url)" rcpPreventDefault>url: {{url | slice:0:28}}... <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></a>
        </span>
      </div>

      <div class="mb-2" *ngIf="hasCustomFields()">
        <div class="papers-label">Custom Fields</div>
        <ng-container *ngFor="let field of collection.custom_fields">
          <span class="badge papers-badge" *ngIf="field.show_in_details && getCustomFieldValue(field) as value" [ngbTooltip]="field.display + ': ' + value" [disableTooltip]="(field.display?.length + value?.length) < 28" openDelay="700">
            <a href (click)="openCustomFieldSearch($event, field)" rcpPreventDefault>{{field.display}}: {{value}}</a>
          </span>
        </ng-container>
      </div>
    </div>

    <div class="right mb-4">
      <div class="mb-2" [hidden]="hideThumb" *ngIf="thumbURL && !shared.user?.licence?.hide_thumbnails">
        <a href (click)="onThumbClick($event)" rcpPreventDefault>
          <img class="thumb papers-border" [src]="thumbURL" (error)="hideThumb = true">
        </a>
      </div>

      <ng-container *ngIf="!(sidepanel.loading$ | async); else loadingTemplate">

        <div class="mb-2" *ngIf="canViewPDF, else getPDFTemplate">
          <button type="button" class="btn btn-primary papers-file-button" [disabled]="download && (download.inProgress$ | async)" (click)="viewPDF($event)">
            <div class="icon" *ngIf="!download || !(download.inProgress$ | async)">
              <rcp-svg-icon name="pdf-file" width="38" height="38"></rcp-svg-icon>
            </div>
            <div class="text" *ngIf="!download || !(download.inProgress$ | async); else progressTemplate">View PDF</div>

            <ng-template #progressTemplate>
              <div class="text">
                <ng-container [ngSwitch]="download.status$ | async">
                  <ng-container *ngSwitchCase="'downloading'">Downloading... {{download.percent$ | async}}&#37;</ng-container>
                  <ng-container *ngSwitchDefault>Searching...</ng-container>
                </ng-container>
              </div>
            </ng-template>
          </button>
        </div>

        <ng-template #getPDFTemplate>
          <div class="mb-2" *ngIf="canGetPDF, else locatePDFTemplate">
            <button type="button" class="btn btn-primary papers-file-button" (click)="getPDF($event)">
              <div class="icon">
                <rcp-svg-icon name="pdf-file" width="38" height="38"></rcp-svg-icon>
              </div>
              <div class="text">Get PDF</div>
            </button>
          </div>
        </ng-template>

        <ng-template #locatePDFTemplate>
          <div class="mb-2" *ngIf="canLocatePDF, else viewPubmedTemplate">
            <button type="button" class="btn btn-secondary papers-locate-button" (click)="locatePDF($event)"
              [disabled]="(download?.inProgress$ | async) || (import?.inProgress$ | async)">
              <div class="icon">
                <rcp-svg-icon name="search-small" width="30" height="30"></rcp-svg-icon>
              </div>
              <div class="text">
                <div class="big">Locate PDF</div>
                <div class="small">
                  <ng-container *ngIf="!download || !(download.inProgress$ | async); else downloadTemplate">
                    <ng-container *ngIf="!import || !(import.inProgress$ | async); else importTemplate">
                      Attempt download
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <ng-template #downloadTemplate>
                <ng-container [ngSwitch]="download.status$ | async">
                  <span *ngSwitchCase="'downloading'">Downloading... {{download.percent$ | async}}&#37;</span>
                  <span *ngSwitchDefault>Searching...</span>
                </ng-container>
              </ng-template>
              <ng-template #importTemplate>
                <ng-container [ngSwitch]="import.status$ | async">
                  <span *ngSwitchCase="'queued'">Waiting...</span>
                  <span *ngSwitchCase="'pending'">Processing...</span>
                  <span *ngSwitchDefault>Uploading... <ng-container *ngIf="import.progress$ | async as progress">{{progress}}&#37;</ng-container></span>
                </ng-container>
              </ng-template>
            </button>
          </div>
        </ng-template>

        <ng-template #viewPubmedTemplate>
          <div class="mb-2" *ngIf="!!item.ext_ids.pmid, else offlineButtonTemplate">
            <button type="button" class="btn btn-primary papers-file-button" (click)="viewPubmed($event)">
              <div class="icon">
                <rcp-svg-icon name="pdf-file" width="38" height="38"></rcp-svg-icon>
              </div>
              <div class="text text--indent">View on PubMed</div>
            </button>
          </div>
        </ng-template>

        <ng-template #offlineButtonTemplate>
          <div class="mb-2" *ngIf="item.ext_ids.doi || item.ext_ids.pmid" ngbTooltip="You are offline">
            <button type="button" class="btn btn-primary papers-file-button papers-file-button--disabled" disabled>
              <div class="icon">
                <rcp-svg-icon name="pdf-file" width="38" height="38"></rcp-svg-icon>
              </div>
              <div class="text">Get PDF</div>
            </button>
          </div>
        </ng-template>

        <div class="mb-2" *ngIf="item.primary_file_type == 'pdf' && !library.isIdentified(item) && canManageItem">
          <button type="button" class="btn btn-secondary w-100" (click)="openResolver(item)">Match</button>
        </div>

        <div class="text-center" *ngIf="hasLinkResolverURL">
          <button type="button" (click)="openWebByLinkResolverURL()" class="btn btn-link">{{shared.user.link_resolver_name}}</button>
        </div>

      </ng-container>

    </div>
  </div>

</div>
