import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { IDataItem } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { LibrarySidepanelService } from '../services/library-sidepanel.service';

@Component({
  selector: 'library-sidepanel-metrics',
  templateUrl: './library-sidepanel-metrics.component.html',
  styleUrls: [
    './library-sidepanel.component.scss',
    './library-sidepanel-metrics.component.scss'
  ],
})
export class LibrarySidepanelMetricsComponent implements OnInit, OnDestroy {
  item: IDataItem;
  itemSub: Subscription;
  chartType = 'cumulative';

  constructor(
    public sidepanel: LibrarySidepanelService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.itemSub = this.sidepanel.item$.subscribe(item => {
      this.item = item;
    });
  }

  ngOnDestroy() {
    this.itemSub.unsubscribe();
  }
}
