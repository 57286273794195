import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, defer, forkJoin, switchMap, from, of, lastValueFrom, firstValueFrom } from 'rxjs';
import { groupBy, mergeMap, reduce, tap, map } from 'rxjs/operators';

import { asBib } from '@readcube/rcp-citation-export';
import { gen as citationGen } from '@readcube/citation-gen';
import { NATURE_STYLE, READCUBE_CUSTOM_STYLE } from 'strings';

import { AppShellService, fixBibliographyEntry } from '../../app-shell.service';
import { AppStorageService } from '../../app-storage.service';
import { ExtensionService } from '../../extension';
import { DataListService, DataItemService, IDataItem, DataCollectionService, IDataCollectionEditable,
  IDataCollection, IDataList, IDataItemFile, IDataItemAnnotation } from '../../library-data';
import { ModalEditCollectionComponent, ModalSharedLibraryLimitComponent, ReaderService, SharedService } from '../../common';
import { DataStylesApiService, IDataUser } from '../../common-data';

import { environment } from 'environment';

import {
  FLAG_ITEM_MODAL_TITLE,
  FLAG_ITEM_MODAL_TEXT,
  UNREAD_ITEM_MODAL_TITLE,
  UNREAD_ITEM_MODAL_TEXT,
  SET_COPYRIGHT_STATUS_ITEM_MODAL_TITLE,
  SET_COPYRIGHT_STATUS_ITEM_MODAL_TEXT,
  LINK_FILE_ITEM_MODAL_TITLE,
  LINK_FILE_ITEM_MODAL_TEXT
} from 'strings';

import { ModalCopyItemsComponent } from '../../bulk/modals/modal-copy-items.component';
import { ModalReviewLinkComponent } from '../../common/modals/modal-review-link.component';
import { ModalNotificationsComponent } from 'src/app/common/modals/modal-notifications.component';

export interface IItemCopyOptions {
  collectionId: string;
  listId?: string;
  tag?: string;
}

export interface IItemsCopyGroup {
  items: IDataItem[];
  collectionId: string;
  importOptions: IItemImportOptions;
}

export interface IItemImportOptions {
  copyUserData: boolean;
  mergeDuplicates: boolean;
  excludeFilesWithoutCopyright?: boolean;
  includeFiles?: boolean;
}

@Injectable()
export class LibraryService {
  constructor(
    protected modal: NgbModal,
    protected dataList: DataListService,
    protected dataItem: DataItemService,
    protected dataCollection: DataCollectionService,
    protected stylesApi: DataStylesApiService,
    protected reader: ReaderService,
    protected extension: ExtensionService,
    protected shared: SharedService,
    protected shell: AppShellService,
    protected storage: AppStorageService,
  ) { }

  getViewName(): string {
    return this.storage.get('library-view', 'table');
  }

  setViewName(view: string) {
    this.storage.set('library-view', view);
  }

  getViewSizes(): number[] {
    return this.storage.get('library-view-sizes', [62, 38]);
  }

  setViewSizes(sizes: number[]) {
    this.storage.set('library-view-sizes', sizes);
  }

  getSorting(): string {
    return this.storage.get('library-sort', 'created');
  }

  setSorting(sort: string) {
    this.storage.set('library-sort', sort);
  }

  getOrdering(): string {
    return this.storage.get('library-order', 'desc');
  }

  setOrdering(order: string) {
    this.storage.set('library-order', order);
  }

  getThumbnailURL(item: IDataItem, file?: IDataItemFile): string {
    if (!file && item.status_data?.thumbnail) {
      let url = item.status_data.thumbnail;
      return !url.startsWith('http') ? 'https:' + url : url;
    }
    file = file ?? item.files?.find(f => f.sha256 === item.primary_file_hash);
    if (file && file.sha256 && file.file_type === 'pdf') {
      return `https://images.readcube-cdn.com/prerendered/${file.sha256}/1.jpg`;
    }
    return '';
  }

  createReference(collectionId: string, listId: string | null, title = ''): Promise<IDataItem> {
    return this.dataItem.create({
      'collection_id': collectionId,
      'article': { title }
    }).then(item => {
      if (listId) {
        return this.dataList.addItems(collectionId, listId, [item.id]).then(() => item);
      }
      return item;
    });
  }

  setFlag(items: IDataItem[], collectionId: string, value = true): Promise<IDataItem[]> {
    const patch = items.map(item => ({
      id: item.id,
      user_data: { star: value }
    }));
    if (items.length < environment.bulkRequestSize) {
      return lastValueFrom(this.dataItem.bulkUpdate(collectionId, patch)).then(r => r.data);
    }
    return this.shell.openConfirm<IDataItem[]>({
      title: FLAG_ITEM_MODAL_TITLE,
      message: FLAG_ITEM_MODAL_TEXT
    }, (helper) => {
      helper.setPercent(0);
      return lastValueFrom(this.dataItem.bulkUpdate(collectionId, patch).pipe(map(r => {
        helper.setPercent(r.percent);
        return r.data;
      })));
    });
  }

  setCopyrightStatus(items: IDataItem[], collectionId: string, value: 'covered' | 'not_covered'): Promise<IDataItem[]> {
    const patch = items.map(item => ({
      id: item.id,
      user_data: { copyright_status: value }
    }));
    if (items.length < environment.bulkRequestSize) {
      return lastValueFrom(this.dataItem.bulkUpdate(collectionId, patch)).then(r => r.data);
    }
    return this.shell.openConfirm<IDataItem[]>({
      title: SET_COPYRIGHT_STATUS_ITEM_MODAL_TITLE,
      message: SET_COPYRIGHT_STATUS_ITEM_MODAL_TEXT
    }, (helper) => {
      helper.setPercent(0);
      return lastValueFrom(this.dataItem.bulkUpdate(collectionId, patch).pipe(map(r => {
        helper.setPercent(r.percent);
        return r.data;
      })));
    });
  }

  isIdentified(item: IDataItem): boolean {
    return Object.keys(item.ext_ids).filter(key => !!key).length > 0;
  }

  hasLinkResolverURL(item: IDataItem, user: IDataUser): boolean {
    return !!item.ext_ids.doi && !!user?.link_resolver_url && !!user?.link_resolver_name;
  }

  canSetPrimary(item: IDataItem, file: IDataItemFile): boolean {
    file = file ?? item.files?.find(f => f.sha256 === item.primary_file_hash);
    return item.files.indexOf(file) > 0;
  }

  canDeleteFile(item: IDataItem, file: IDataItemFile): boolean {
    return true;
  }

  canDownloadFile(item: IDataItem, file?: IDataItemFile): boolean {
    file = file ?? item.files?.find(f => f.sha256 === item.primary_file_hash);
    return !item.drm && !!file && !!file.sha256;
  }
  
  canLinkFile(item: IDataItem) {
    return !!item.status_data?.items?.filter(status => status.collection_id !== item.collection_id)
      .filter(status => status.has_file).length;
  }

  linkFile(items: IDataItem[], collectionId: string) {
    if (items.length < environment.bulkRequestSize) {
      return lastValueFrom(this.dataItem.bulkLinkFiles(collectionId, items)).then(r => r.data);
    }
    return this.shell.openConfirm<IDataItem[]>({
      title: LINK_FILE_ITEM_MODAL_TITLE,
      message: LINK_FILE_ITEM_MODAL_TEXT
    }, (helper) => {
      helper.setPercent(0);
      return lastValueFrom(this.dataItem.bulkLinkFiles(collectionId, items).pipe(map(r => {
        helper.setPercent(r.percent);
        return r.data;
      })));
    });
  }

  setUnread(items: IDataItem[], collectionId: string, value = true): Promise<IDataItem[]> {
    const patch = items.map(item => ({ id: item.id }));
    const action = value ? 'unview' : 'view';
    if (items.length < environment.bulkRequestSize) {
      return lastValueFrom(this.dataItem.bulkUpdate(collectionId, patch, action)).then(r => r.data);
    }
    return this.shell.openConfirm<IDataItem[]>({
      title: UNREAD_ITEM_MODAL_TITLE,
      message: UNREAD_ITEM_MODAL_TEXT
    }, (helper) => {
      helper.setPercent(0);
      return lastValueFrom(this.dataItem.bulkUpdate(collectionId, patch, action).pipe(map(r => {
        helper.setPercent(r.percent);
        return r.data;
      })));
    });
  }

  copyItems(items: IDataItem[], options: IItemCopyOptions): Promise<IDataItem[]> {
    const collectionId = options.collectionId;
    const listIds = options.listId ? [options.listId] : [];
    const tag = options.tag;
    return lastValueFrom(from(items).pipe(
      groupBy(item => item.collection_id),
      mergeMap(group => forkJoin([
        group.pipe(reduce((acc, curr) => acc.concat(curr), [])),
        of(group.key)
      ])),
      map(result => {
        return <IItemsCopyGroup>({
          items: <IDataItem[]>result[0],
          collectionId: <string>result[1],
          importOptions: {}
        });
      }),
      switchMap(g => this.promptCopyItems(g, collectionId)),
      switchMap(g => this.createCopiedItems(g, collectionId, listIds)),
      switchMap(i => this.addTagToItems(i, collectionId, tag)),
      tap(() => this.dataList.reloadRows({ collectionId })),
      tap(() => this.dataItem.reloadRows({ collectionId }))
    ));
  }

  createCopiedItems(group: IItemsCopyGroup, collectionId: string, listIds: string[] = []): Observable<IDataItem[]> {
    const itemIds = group.items.map(item => item.id);
    const fromCollectionId = group.collectionId;
    if (fromCollectionId === collectionId) {
      if (listIds.length > 0) {
        return from(this.dataList.addItems(collectionId, listIds[0], itemIds).then(() => {
          // Note: add list_id to item so we don't need to reload items again.
          group.items.forEach(item => item.list_ids.push(listIds[0]));
          return group.items;
        }));
      } else {
        return of(group.items);
      }
    } else {
      const options = group.importOptions;
      const copyFiles: 'all' | 'copyright_covered' = options.includeFiles
        ? (options.excludeFilesWithoutCopyright && this.shared.user?.licence?.copyright_show_status ? 'copyright_covered' : 'all')
        : null;
      const importOptions = {
        copy_user_data: options.copyUserData,
        copy_files: copyFiles,
        merge: options.mergeDuplicates,
      };
      return this.dataItem.bulkCopy(itemIds.map(id => `${fromCollectionId}:${id}`), collectionId, listIds, importOptions)
        .pipe(map(result => result.data));
    }
  }

  removeFromList(collectionId: string, listId: string, items: IDataItem[]): Promise<void> {
    const itemIds = items.map(item => item.id);
    return this.dataList.removeItems(collectionId, listId, itemIds)
      // Note: removes list_id from every item so we don't need to reload it.
      .then(() => items.forEach(item => {
        let i = item.list_ids.indexOf(listId);
        item.list_ids.splice(i, 1);
      }))
      .then(() => this.dataList.reloadRows({ collectionId }))
      .then(() => this.dataItem.reloadRows({ collectionId }));
  }

  copySummaryToClipboard(items: Partial<IDataItem>[]) {
    const result = citationGen({ items, style: READCUBE_CUSTOM_STYLE });
    this.shell.copyToClipboard(fixBibliographyEntry(result.bibliographyEntry));
  }

  copyReferenceToClipboard(items: Partial<IDataItem>[], collection?: IDataCollection) {
    const result = citationGen({
      items,
      style: this.stylesApi.lastUsedStyle || NATURE_STYLE,
      mapping: collection?.custom_type_schema,
      customFields:  collection?.custom_fields
    });
    this.shell.copyToClipboard(fixBibliographyEntry(result.bibliographyEntry));
  }

  copyBibTeXToClipboard(items: Partial<IDataItem>[]) {
    this.shell.copyToClipboard({ text: items.map(item => asBib({ item })).join('\n\r') });
  }

  copyCitekeyToClipboard(items: IDataItem[]) {
    const text = items.map(item => item.user_data.citekey).join(',');
    this.shell.copyToClipboard({ text });
  }

  canLocateItemPDF(item: IDataItem) {
    return !item.drm && !!item.ext_ids.doi;
  }

  canViewItemPDF(item: IDataItem, file?: IDataItemFile) {
    if (!!file) return file.file_type === 'pdf';
    const isFree = item.status_data?.open_access || item.status_data?.free_access;
    const hasFile = item.primary_file_hash && item.primary_file_type === 'pdf';
    const hasDirectUrl = !!this.reader.getDirectUrl(item);
    const canLinkFile = this.canLinkFile(item);
    return hasFile || (isFree && hasDirectUrl) || canLinkFile;
  }

  viewItemPDF(item: IDataItem, file?: IDataItemFile, annotation?: IDataItemAnnotation) {
    if (item.primary_file_hash) {
      this.reader.viewItemPDF({ item, file, annotation });
    } else if (this.canLinkFile(item)) {
      this.linkFile([item], item.collection_id).then(items => {
        this.reader.viewItemPDF({ item: items[0], file: item.files[0], annotation });
      });
    } else if (this.extension.isInstalled()) {
      this.reader.viewPublicPDF(item);
    } else {
      this.reader.viewItemPDF({ item });
    }
  }

  linkToLiteratureReview(collectionId: string, listId?: string) {
    const modalRef = this.modal.open(ModalReviewLinkComponent);
    const componentInstance = <ModalReviewLinkComponent>modalRef.componentInstance;
    componentInstance.collectionId = collectionId;
    componentInstance.listId = listId;
  }

  setNotifications(collection: IDataCollection, list?: IDataList): Promise<void> {
    const modalRef = this.modal.open(ModalNotificationsComponent, { backdrop: 'static' });
    const instance = <ModalNotificationsComponent>modalRef.componentInstance;
    instance.collection = collection;
    instance.list = list;
    return modalRef.result.finally(() => Promise.resolve());
  }

  createCollection() {
    const collectionEditable: Partial<IDataCollectionEditable> = {
      'name': '',
      'role_settings': {
        member_annotate_item: true,
        member_copy_item: true,
        member_download_file: true,
        member_print_file: true,
        member_publish_list: false,
        member_tag_item: true,
        member_create_item: true,
        member_delete_item: true,
        member_manage_list: true,
        member_manage_smartlist: true,
        viewer_annotate_item: false,
        viewer_copy_item: false,
        viewer_download_file: false,
        viewer_print_file: false,
        viewer_publish_list: false,
        viewer_tag_item: false,
        viewer_create_item: false,
        viewer_delete_item: false,
        viewer_manage_list: false,
        viewer_manage_smartlist: false
      }
    };
    this.checkCreateSharedLibrary().then(canCreate => {
      let modalRef: any, componentInstance: any;
      if (canCreate) {
        modalRef = this.modal.open(ModalEditCollectionComponent);
        componentInstance = <ModalEditCollectionComponent>modalRef.componentInstance;
        componentInstance.collectionEditable = collectionEditable;
      } else {
        modalRef = this.modal.open(ModalSharedLibraryLimitComponent);
        componentInstance = <ModalSharedLibraryLimitComponent>modalRef.componentInstance;
        componentInstance.user = this.shared.user;
      }
    });
  }

  protected checkCreateSharedLibrary(): Promise<boolean> {
    return firstValueFrom(this.dataCollection.query().pipe(
      map(arr => arr.filter(c => c.shared && c.status != 'deleted')),
      map(sharedLibraries => {
        if (this.shared.user.licence.collection_count_limit === null) {
          return true;
        }
        return sharedLibraries.length < this.shared.user.licence.collection_count_limit;
      })
    ));
  }

  protected addTagToItems(items: IDataItem[], collectionId: string, tag?: string): Observable<IDataItem[]> {
    if (!tag) {
      return of(items);
    }
    const itemPartials = items.map(item => ({
      id: item.id,
      user_data: { tags: item.user_data.tags.concat(tag) }
    }));
    return this.dataItem.bulkUpdate(collectionId, itemPartials)
      .pipe(map(result => result.data));
  }

  protected promptCopyItems(group: IItemsCopyGroup, fromCollectionId: string): Observable<IItemsCopyGroup> {
    return defer(() => {
      return new Promise<IItemsCopyGroup>((resolve, reject) => {
        if (group.collectionId !== fromCollectionId) {
          setTimeout(() => {
            const modalRef = this.modal.open(ModalCopyItemsComponent, { backdrop: 'static' });
            modalRef.result.then(result => {
              group.importOptions = result.importOptions;
              resolve(group);
            }).catch(() => reject());
          });
        } else {
          resolve(group);
        }
      });
    });
  }
}
