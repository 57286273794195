import { Injectable } from '@angular/core';
import { DataStatusApiService, IDataArticleItemStatus, IDataItemExtIds, IDataItemStatus } from './data-status-api.service';

@Injectable()
export class DataStatusService {
  public statusCache = new Map<string, IDataItemStatus>();

  constructor(
    private api: DataStatusApiService
  ) { }

  // Fetches article access data, thumbnail, urls and other stuff
  getArticleData(extIds: IDataItemExtIds, skipItemsCheck = true, nocache = false): Promise<IDataItemStatus> {
    return this.api.getArticleData(extIds, skipItemsCheck, nocache); // TODO: cache should be implemented here
  }

  getAltmetricData(doi: string) {
    return this.api.getAltmetricData(doi);
  }

  checkInLibraryStatus(extIds: IDataItemExtIds, nocache = false): Promise<IDataArticleItemStatus[]> {
    return this.api.checkInLibraryStatus(extIds, nocache);
  }

  checkInLibraryStatuses(allExtIds: IDataItemExtIds[]): Promise<IDataArticleItemStatus[][]> {
    return this.api.checkInLibraryStatuses(allExtIds);
  }
}
