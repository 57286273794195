import { Component, Input, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { ContextMenuComponent, ContextMenuService } from '@readcube/ngx-contextmenu';
import { DragAndDropService, DragHelperEvent } from '@readcube/rcp-drag-and-drop';

import { AppShellService } from '../../app-shell.service';
import { SharedService, ListNode } from '../../common';
import { IDataArticle } from '../../search-data';
import { LibraryService } from '../../library';
import { IDataCollection, IDataItem, IDataList } from '../../library-data';
import { ImporterService } from '../../importer';
import { ExporterService } from '../../exporter';
import { BulkService } from '../../bulk';
import { FullTextService } from '../../full-text/services/full-text.service';
import { LibraryNavigationService } from '../services/library-navigation.service';
import { environment } from 'environment';

type ListDroppables = IDataItem[] | IDataArticle[] | ListNode | DataTransfer;

@Component({
  selector: 'library-navigation-list',
  templateUrl: './library-navigation-list.component.html',
  styleUrls: ['./library-navigation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryNavigationListComponent implements OnInit {
  @Input()
  indent = 0;

  @Input()
  level = 0;

  @Input()
  maxDepth: number;

  @Input()
  collection: IDataCollection;

  @Input()
  node: ListNode;

  @ViewChild('contextMenuComponent', { static: true })
  contextMenuComponent: ContextMenuComponent;

  loadingSource = new Subject<boolean>();
  disabled$: Observable<boolean>;
  loading$: Observable<boolean>;

  expanded = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public contextMenu: ContextMenuService,
    public shared: SharedService,
    public library: LibraryService,
    public navigation: LibraryNavigationService,
    public importer: ImporterService,
    public exporter: ExporterService,
    public bulk: BulkService,
    public dragAndDrop: DragAndDropService,
    public fullText: FullTextService,
    public shell: AppShellService
  ) {
    this.loading$ = this.loadingSource.asObservable();
  }

  ngOnInit() {
    const isExpanded = this.navigation.isListNodeExpanded(this.node.list.id);
    this.expanded = isExpanded === undefined ? this.expanded : isExpanded;

    this.disabled$ = this.dragAndDrop.event$.pipe(
      map(event => event.name === 'start' && !this.isDroppable(event)),
      shareReplay({ refCount: true, bufferSize: 1 }),
      startWith(false)
    );
  }

  openContextMenu(event: MouseEvent) {
    this.contextMenu.show.next({
      contextMenu: this.contextMenuComponent,
      anchorElement: event.currentTarget,
      event: event,
      item: null
    });
    event.stopPropagation();
    event.preventDefault();
  }

  onExpandedChange(value: boolean) {
    this.navigation.setListNodeExpanded(this.node.list.id, value);
  }

  onDrop(event: DragHelperEvent<ListDroppables>) {
    const collectionId = this.node.list.collection_id,
      listId = this.node.list.id;
    switch (event.type) {
      case 'articles':
        const articles = <IDataArticle[]>event.data;
        this.loadingSource.next(true);
        this.shared.addToLibrary(articles, collectionId, listId)
          .finally(() => this.loadingSource.next(false));
        break;
      case 'items':
        const items = <IDataItem[]>event.data;
        this.loadingSource.next(true);
        this.library.copyItems(items, { collectionId, listId })
          .finally(() => this.loadingSource.next(false));
        break;
      case 'list':
        const node = <ListNode>event.data;
        this.loadingSource.next(true);
        this.navigation.moveList(node.list, listId)
          .then(() => this.navigation.setListNodeExpanded(listId, true))
          .finally(() => this.loadingSource.next(false));
        break;
      case 'files':
        const data = <DataTransfer>event.data;
        const files = Array.from(data.files);
        this.importer.openImportDialog(collectionId, listId, null, files);
        break;
    }
  }

  isDroppable(event: DragHelperEvent<ListDroppables>): boolean {
    if (!this.canDropItems()) {
      return false;
    }
    switch (event.type) {
      case 'articles':
        return true;
      case 'items':
        // Accepts only items that are not already in the list.
        const items = <IDataItem[]>event.data;
        // Removed recursive check for child item_ids
        //const itemIds = this.node.getDescendantItemIds();
        //return items.some(item => !itemIds.includes(item.id));
        return items.some(item => !item.list_ids.includes(this.node.list.id));
      case 'list':
        // List can only be moved to another list within same collection.
        const node = <ListNode>event.data,
          collectionId = this.node.list.collection_id,
          listId = this.node.list.id,
          limit = environment.maxListsDepth;
        return node.list.collection_id === collectionId &&
          node.list.id !== listId &&
          node.list.parent_id !== listId &&
          node.getHeight() < this.maxDepth &&
          !this.node.isParentOf(node.list.id, limit);
      case 'files':
        return true;
    }
    return false;
  }

  create() {
    const list: Partial<IDataList> = {
      collection_id: this.collection.id,
      parent_id: this.node.list.id
    };
    this.navigation.openEditList(list).then(list => {
      if (list) {
        this.navigation.setListNodeExpanded(this.node.list.id, true);
        this.router.navigate(['/library', list.collection_id, 'list', list.id], {
          relativeTo: this.route
        });
      }
    });
  }

  edit() {
    const list = Object.assign({}, this.node.list);
    this.navigation.openEditList(list, this.node.getHeight());
  }

  delete() {
    this.navigation.openDeleteList(this.node.list).then(deleted => {
      if (this.router.routerState.snapshot.url.includes(deleted.id) && deleted) {
        this.router.navigate(['/library', this.collection.id, 'all'], {
          relativeTo: this.route,
          replaceUrl: true
        });
      }
    });
  }

  exportToBIB() {
    this.exporter.openExportItemsDialog({
      type: 'bib',
      collection: this.collection,
      list: this.node.list
    });
  }

  exportToRIS() {
    this.exporter.openExportItemsDialog({
      type: 'ris',
      collection: this.collection,
      list: this.node.list
    });
  }

  exportToCSV() {
    this.exporter.openExportItemsDialog({
      type: 'csv',
      collection: this.collection,
      list: this.node.list
    });
  }

  exportToXLSX() {
    this.exporter.openExportItemsDialog({
      type: 'xlsx',
      collection: this.collection,
      list: this.node.list
    });
  }

  canDropItems(): boolean {
    return this.shared.user?.licence.active &&
      this.collection.user?.can_create_item &&
      this.collection.status !== 'archived';
  }

  canManageList(): boolean {
    return this.shared.user?.licence.active &&
      this.collection.user?.can_manage_list;
  }

  canCreateSubList(): boolean {
    return this.maxDepth > 0;
  }

  canShareList(): boolean {
    return this.shared.user &&
      !this.shared.user.licence.disable_public_sharing &&
      this.shared.user.licence.active &&
      this.collection.user?.can_publish_list;
  }

  canExportList(): boolean {
    return this.shared.user?.licence.active;
  }

  share() {
    this.navigation.share('list', this.node.list);
  }

  canCreateFilteredList(): boolean {
    return this.shared.user?.licence?.active
      && this.shared?.user?.licence?.filtered_lists;
  }

  createFilteredList() {
    this.fullText.createFilteredList(this.collection.id, this.node.list.id);
  }

  canSetNotifications() {
    return this.shared.user?.licence?.active
      && this.shared?.user?.licence?.collection_email_notifications
      && this.collection.organization;
  }

  setNotifications() {
    this.library.setNotifications(this.collection, this.node.list);
  }

  canCopyItems(): boolean {
    return this.shared.user?.licence?.active && this.collection.user?.can_copy_item;
  }

  copyItems() {
    this.bulk.bulkCopyItems(this.collection.id, this.node.list.id);
  }

  canDeleteItems(): boolean {
    return this.shared.user?.licence?.active && this.collection.user?.can_delete_item;
  }

  deleteItems() {
    this.bulk.bulkDeleteItems(this.collection.id, this.node.list.id);
  }

  canUpdateDetails(): boolean {
    return this.shared.user?.licence?.active && this.collection.user?.can_create_item;
  }

  updateDetails() {
    this.bulk.bulkUpdateDetails(this.collection.id, this.node.list.id);
  }

  canLocatePDFs(): boolean {
    return this.shared.user?.licence?.active;
  }

  locatePDFs() {
    this.bulk.locateAllPDFs(this.collection.id, this.node.list.id);
  }

  canLinkFiles(): boolean {
    return this.shared.user?.licence?.active
      && this.shared.user?.licence?.link_files
      && this.collection.organization;
  }

  linkFiles() {
    this.bulk.bulkLinkFiles(this.collection.id, this.node.list.id);
  }

  canExportPDFs(): boolean {
    return this.shared.user?.licence?.active && this.shared?.user?.licence?.collection_export;
  }

  exportPDFs() {
    this.bulk.bulkExportPDFs(this.collection.id, this.node.list.id);
  }

  listTrackByFn(index: number, list: IDataList) {
    return list.id;
  }

  canLiteratureReview(): boolean {
    return ['admin', 'reviewer'].includes(this.shared.user?.review_role);
  }

  addToLiteratureReview() {
    this.bulk.bulkLiteratureReview(this.collection.id, this.node.list.id);
  }

  linkToLiteratureReview() {
    this.library.linkToLiteratureReview(this.collection.id, this.node.list.id);
  }

  askAI() {
    this.router.navigate(['library', 'ai', 'new'], { queryParams: { collection_id: this.collection.id, list_id: this.node.list.id } });
  }
}
