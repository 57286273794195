import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, concatMap, finalize, firstValueFrom, from, lastValueFrom, map, of, takeUntil, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonDialogProgressComponent } from '../../common';
import { DataDimensionsService } from '../../common-data';
import { DataItemService, DataListService, IDataItemQueryParams, IDataItem, IDataList } from '../../library-data';
import { DataSearchService, IDataArticle } from '../../search-data';

import { ModalFilteredListComponent } from '../modals/modal-filtered-list.component';
import { ModalImportSearchComponent } from '../modals/modal-import-search.component';

export interface IFilteredListGroup {
  confirmed: boolean;
  query: string;
  showResultCount: boolean;
  searchDimensions: boolean;
  resultSublistName: string;
  unsearchableSublistName?: string;
}

export interface IImportSearchGroup {
  confirmed: boolean;
  resultLimit: string;
  searchDimensions: boolean;
  query: string;
  mergeDuplicates: boolean;
}

@Injectable()
export class FullTextService {
  
  constructor(
    protected router: Router,
    protected modal: NgbModal,
    protected dataList: DataListService,
    protected dataItem: DataItemService,
    protected dataDimensions: DataDimensionsService,
    protected dataSearch: DataSearchService
  ) { }

  createFilteredList(collectionId: string, parentListId?: string): Promise<void> {
    return this.promptFilteredList().then(async group => {
      if (!group.confirmed)
        return Promise.resolve();

      const resultListPayload: Partial<IDataList> = {
        collection_id: collectionId,
        parent_id: parentListId,
        name: group.resultSublistName
      };

      const resultList = await this.dataList.create(resultListPayload);

      let totalProcessed = 0;
      let totalToProcess = 0;
      let totalAddedToResultList = 0;
      let totalAddedToUnsearchableList = 0;

      let unsearchableList = null;
      let readcubeSearchMatchedIds = [];

      const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
      const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
      instance.title = 'Create Filtered List';
      instance.stoppable = true;

      const queryParams: IDataItemQueryParams = {
        collection_id: collectionId,
        list_id: parentListId,
        query: group.query
      };

      const stream$ = this.dataItem.queryAll(queryParams).pipe(
        concatMap(result => {
          totalProcessed = totalProcessed + result.rows.length;
          totalToProcess = result.total;
          const matchedItemIds = result.rows.map(item => item.id);
          readcubeSearchMatchedIds = readcubeSearchMatchedIds.concat(matchedItemIds);
          return from(this.dataList.addItems(collectionId, resultList.id, matchedItemIds)).pipe(map(() => matchedItemIds.length));
        }),
        concatMap(addedItemsCount => {
          if (group.showResultCount && addedItemsCount) {
            totalAddedToResultList = totalAddedToResultList + addedItemsCount;
            return from(this.dataList.update({ name: `[${totalAddedToResultList}] ${group.resultSublistName}`, id: resultList.id, collection_id: collectionId }));
          }
          else
            return of(null);
        }),
        tap(() => {
          instance.progress$.next(totalProcessed / totalToProcess * 100);
          instance.message$.next(`${group.searchDimensions ? 'Searching Papers...<br>' : ''}Processed <b>${totalProcessed}</b> out of <b>${totalToProcess}</b> items...`);
        }),
        takeUntil(instance.stop$)
      );

      if (!group.searchDimensions) {
        return lastValueFrom(stream$).then(() => {
          this.router.navigateByUrl(`/library/${collectionId}/list/${resultList.id}`);
          modalRef.close();
          return Promise.resolve();
        });
      }

      return lastValueFrom(stream$).then(async () => {
        totalProcessed = 0;
        totalToProcess = 0;

        if (group.unsearchableSublistName) {
          const unsearchableListPayload: Partial<IDataList> = {
            collection_id: collectionId,
            parent_id: parentListId,
            name: group.unsearchableSublistName
          };
          unsearchableList = await this.dataList.create(unsearchableListPayload);
        }

        const stream$ = this.dataItem.queryAll({ collection_id: collectionId, list_id: parentListId }).pipe(
          concatMap(result => {
            if (totalProcessed === 0) {
              instance.progress$.next(0);
              instance.message$.next('Searching Dimensions Full Text...');
            }

            totalProcessed = totalProcessed + result.rows.length;
            totalToProcess = result.total;
            const itemsToSearchDimensions = result.rows.filter(item => !readcubeSearchMatchedIds.includes(item.id) && !item.primary_file_hash);
            return this.addItemsFromDimensionsSearch(itemsToSearchDimensions || [], group.query, collectionId, resultList.id, unsearchableList?.id);
          }),
          concatMap(response => {
            if (group.showResultCount && response?.results) {
              totalAddedToResultList = totalAddedToResultList + response.results;
              return from(this.dataList.update({ name: `${group.resultSublistName} [${totalAddedToResultList}]`, id: resultList.id, collection_id: collectionId }).then(() => response));
            }
            else
              return of(response);
          }),
          concatMap(response => {
            if (group.showResultCount && response?.unsearchables && group.unsearchableSublistName) {
              totalAddedToUnsearchableList = totalAddedToUnsearchableList + response.unsearchables;
              return from(this.dataList.update({ name: `${group.unsearchableSublistName} [${totalAddedToUnsearchableList}]`, id: unsearchableList.id, collection_id: collectionId }));
            }
            else
              return of(response);
          }),
          tap(() => {
            instance.progress$.next(totalProcessed / totalToProcess * 100);
            instance.message$.next(`${group.searchDimensions ? 'Searching Dimensions Full Text...<br>' : ''}Processed <b>${totalProcessed}</b> out of <b>${totalToProcess}</b> items...`);
          }),
          takeUntil(instance.stop$)
        );
        return lastValueFrom(stream$).then(() => {
          this.router.navigateByUrl(`/library/${collectionId}/list/${resultList.id}`);
          modalRef.close();
          return Promise.resolve();
        });
      });

    });
  };

  private promptFilteredList(): Promise<IFilteredListGroup> {
    return new Promise<IFilteredListGroup>((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalFilteredListComponent, { backdrop: 'static' });
        modalRef.result.then(group => {
          resolve(group);
        });
      });
    });
  }

  private addItemsFromDimensionsSearch(
    items: IDataItem[],
    query: string,
    collectionId: string,
    resultListId: string,
    unsearchableListId: string): Observable<{ results: number, unsearchables?: number; }> {

    const unidentifiedItemIds = items.filter(item => !item?.ext_ids?.doi).map(item => item.id);
    let unsearchableItemIds = [];
    const doiItemMap = {};
    items.forEach(item => {
      if (item?.ext_ids?.doi)
        doiItemMap[item?.ext_ids?.doi] = item.id;
    });

    const allDois = Object.keys(doiItemMap);

    if (!allDois?.length)
      return of(null);

    return this.dataDimensions.search({ term: query, dois: allDois, checkDois: true }).pipe(
      map(articles => {
        const searchableDois = articles.map(article => article.ext_ids?.doi);
        allDois.forEach(doi => {
          if (!searchableDois.includes(doi))
            unsearchableItemIds.push(doiItemMap[doi]);
        });

        return searchableDois;
      }),
      concatMap(searchableDois => {
        if (!searchableDois.length)
          return of([]);
        return this.dataDimensions.search({ term: query, dois: searchableDois });
      }),
      concatMap(articles => {
        const matchedDois: string[] = articles.map(article => article.ext_ids.doi);
        const matchedItemIds = [];
        matchedDois.forEach(doi => {
          matchedItemIds.push(doiItemMap[doi]);
        });

        if (unsearchableListId) {
          const unsearchableIds = unsearchableItemIds.concat(unidentifiedItemIds);
          return this.dataList.addItems(collectionId, unsearchableListId, unsearchableIds)
            .then(() => this.dataList.addItems(collectionId, resultListId, matchedItemIds))
            .then(() => {
              return { results: matchedItemIds.length, unsearchables: unsearchableIds.length };
            });
        }
        else
          return this.dataList.addItems(collectionId, resultListId, matchedItemIds).then(() => {
            return { results: matchedItemIds.length };
          });
      })
    );
  }

  private promptImportSearch() {
    return new Promise<IImportSearchGroup>((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalImportSearchComponent, { backdrop: 'static' });
        modalRef.result.then(group => {
          resolve(group);
        });
      });
    });
  }

  openImportSearch(collectionId: string, listId: string) {
    return this.promptImportSearch().then(group => {
      if (!group.confirmed)
        return Promise.resolve();

      const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
      const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
      instance.title = 'Import from Search';
      instance.stoppable = true;

      const search$: Observable<Partial<IDataArticle>[]> = group.searchDimensions
        ? this.dataDimensions.search({ term: group.query, limit: group.resultLimit })
        : this.dataSearch.query({ query: group.query, size: parseInt(group.resultLimit) });

      const stream$ = search$.pipe(
        concatMap(articles => {
          const items = articles.map(article => {
            return {
              ...article,
              merge: group.mergeDuplicates,
              skip_merge: !group.mergeDuplicates
            };
          });

          return lastValueFrom(this.dataItem.bulkPost(collectionId, items, listId).pipe(
            tap(r => {
              instance.progress$.next(r.percent);
              instance.message$.next(`<b>${r.data.length}</b> out of <b>${items.length}</b> found items are added to your ${listId ? 'list' : 'library'}.`);
            })
          ));
        }),
        finalize(() => instance.done$.next(true))
      );

      return firstValueFrom(stream$).then(() => {
        this.dataItem.reloadRows({ collectionId });
        return Promise.resolve();
      });
    });
  }
}
