import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './common-dialog-confirm.component.html'
})
export class CommonDialogConfirmComponent {
  title = '';
  message = '';
  cancelButtonText = 'Cancel';
  confirmButtonText = 'Confirm';
  showCancel: boolean = true;
  checkboxLabel: string = null;
  displayList: string[] = [];

  checked = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  cancel() {
    this.activeModal.close({ confirmed: false, checked: this.checked });
  }

  confirm() {
    this.activeModal.close({ confirmed: true, checked: this.checked });
  }

  onCheckboxChange(event: any) {
    this.checked = !!event.target?.checked;
  }
}
