import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environment';

export enum FilterGroupType {
  Checkbox = 'checkbox',
  Range = 'range'
}

export type TFilterGroupType = FilterGroupType.Checkbox | FilterGroupType.Range;

export interface IRangeChartItem { 
  name: number | string;
  value: number;
}

export interface IDataFiltersResult {
  label: string;
  name: string;
  type: TFilterGroupType;
  visible: boolean;
  filters: ICheckboxFilter[] | TRangeFilter;
  defaultFilters?: TRangeFilter;
  chartData?: IRangeChartItem[]
}

export type TRangeFilter = [ number, number ];

export interface ICheckboxFilter {
  value?: boolean;
  name: string;
  label: string;
  visible: boolean;
}

@Injectable()
export class DataFiltersApiService {

  constructor(
    private http: HttpClient
  ) { }

  public query(): Observable<IDataFiltersResult[]> {
    // todo replace researcher_id with researcher and split by ; map id to value and name to label
    const endpointUrl = `${environment.baseUrls.services}/articles/search_fulltext?query=curse&refinements[]=year&refinements[]=meshterms&refinements[]=researcher_id&refinements[]=altmetric&refinements[]=citations`;
    return this.http.get<IDataFiltersResult[]>(endpointUrl)
      .pipe(map(body => this.createResult(body)));
  }

  private createResult(response: any): IDataFiltersResult[] {
    const { meshterms, year, researcher_id } = response?.refinements || {};

    const mapKeyToFilter = ({ key }: { key: string }) => ({ 
      label: key,
      name: key,
      visible: true
    });

    let minYear = Math.min();
    let maxYear = Math.max();

    const yearChartData: IRangeChartItem[] = year.sort((a, b) => a.key - b.key).map(({ key, doc_count }: { key: string, doc_count: number }) => {
      const val = Number(key);

      if (val > maxYear) {
        maxYear = val;
      }

      if (val < minYear) {
        minYear = val;
      }

      return {
        name: key,
        value: doc_count
      };
    });

    return [{
      label: 'Year',
      name: 'year',
      visible: true,
      type: FilterGroupType.Range,
      filters: [minYear, maxYear],
      defaultFilters: [minYear, maxYear],
      chartData: yearChartData
    }, {
      label: 'Mesh Terms',
      name: 'meshterms',
      visible: true,
      type: FilterGroupType.Checkbox,
      filters: meshterms.map(mapKeyToFilter)
    }, {
      label: 'Researcher ID',
      name: 'researcher_id',
      visible: true,
      type: FilterGroupType.Checkbox,
      filters: researcher_id.map(mapKeyToFilter)
    }];
  }
}
