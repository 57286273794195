import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'environment';

import { IDataResult } from '../../app-data-result.model';

export interface IDataCitation {
  doi: string;
  title: string;
  journal: string;
  year: number;
  authors: IDataCitationAuthor[];
  mentions?: string[];
}

export interface IDataCitationAuthor {
  name: string;
}

export interface IDataCitationQueryParams {
  doi: string;
  refinements?: string[];
  size?: number;
}

@Injectable()
export class DataCitationApiService {
  private scrollId?: string;

  constructor(
    private http: HttpClient
  ) { }

  public query(params: IDataCitationQueryParams, invalidateSession = false): Observable<IDataResult<IDataCitation>> {
    const endpointUrl = `${environment.baseUrls.services}/articles/metrics`;
    if (invalidateSession) {
      this.scrollId = null;
    }
    const httpOptions = {
      params: this.getHttpParams(params, this.scrollId)
    };
    return this.http.get<IDataResult<IDataCitation>>(endpointUrl, httpOptions).pipe(
      tap(body => this.scrollId = body.scroll_id),
      map(body => this.createResult(body))
    );
  }

  private createResult(data: any): IDataResult<IDataCitation> {
    return {
      refinements: data['refinements'] || {},
      rows: (data['results'] || []).map(d => this.mapCitationData(d)),
      totalHuman: data['total_human'] || 'No citations',
      total: data['total'] || 0
    };
  }

  private mapCitationData(data: any): IDataCitation {
    return {
      doi: data['doi'] || '',
      title: data['title'] || '',
      journal: data['journal'] || '',
      year: data['year'] || null,
      mentions: data['mentions'] || [],
      authors: data['authors'] || []
    };
  }

  private getHttpParams(params: IDataCitationQueryParams, scrollId?: string): HttpParams {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('doi', params.doi);
    httpParams = httpParams.set('size', (params.size || 50).toString());
    if (params.refinements) {
      params.refinements.forEach(val => {
        httpParams = httpParams.append('refinements[]', val);
      });
    }
    if (scrollId) {
      httpParams = httpParams.set('scroll_id', scrollId);
    }
    return httpParams;
  }
}
