import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AppStorageService } from '../../app-storage.service';
import { SharedService } from '../../common';
import { AppShellService } from '../../app-shell.service';
import { AppThemeService } from '../../app-theme.service';

import { environment } from 'environment';
import { BUILD_VERSION, BUILD_TIME } from 'build';

const RELEASE_NOTES_KEY = 'release-notes-read';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  buildVersion = BUILD_VERSION;
  buildTime = BUILD_TIME;

  constructor(
    public router: Router,
    public shared: SharedService,
    public shell: AppShellService,
    public theme: AppThemeService,
    public storage: AppStorageService,
  ) { }

  isSystemTheme(): boolean {
    return !localStorage.getItem('theme');
  }

  isDarkTheme(): boolean {
    return localStorage.getItem('theme') === 'dark';
  }

  isLightTheme(): boolean {
    return localStorage.getItem('theme') === 'light';
  }

  isRouteActive(route: string): boolean {
    return this.router.url.startsWith(route);
  }

  onAccountClick(): void {
    window.open(`${environment.baseUrls.readcube}/account`, '_blank');
  }

  onDashboardClick(): void {
    window.open(`${environment.baseUrls.dashboard}`, '_blank');
  }

  onRecommendClick(): void {
    window.open(`${environment.baseUrls.readcube}/recommend`, '_blank');
  }

  onSupportClick(): void {
    window.open(`${environment.baseUrls.support}`, '_blank');
  }

  onReleaseNotesClick(event: MouseEvent): void {
    this.storage.set(RELEASE_NOTES_KEY, this.buildVersion);
    window.open(`${environment.baseUrls.releaseNotes}`, '_blank');
    event.preventDefault();
  }

  onLogoutClick(): void {
    window.location.href = `${environment.baseUrls.readcube}/authentication/logout?redirect=${encodeURIComponent(environment.baseUrls.papers)}`;
  }

  canOpenDashboard() {
    const user = this.shared.user;
    return user ? !!user.licence.active && !!user.licence.organization_admin : false;
  }
}
