<div class="header" [hidden]="!canShowCustomFields()">
  <ul ngbNav #nav="ngbNav" justify="end" class="nav-tabs">
    <li ngbNavItem="annual">
      <a ngbNavLink>Reference Fields <fa-icon *ngIf="hasFormError()" [icon]="['fal', 'exclamation-circle']" class="text-danger"></fa-icon></a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="referenceFieldsTemplate"></ng-container>
      </ng-template>
    </li>
    <li ngbNavItem="cumulative" *ngIf="canShowCustomFields()">
      <a ngbNavLink>Custom Fields <fa-icon *ngIf="hasFormCustomError()" [icon]="['fal', 'exclamation-circle']" class="text-danger"></fa-icon></a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="referenceCustomFieldsTemplate"></ng-container>
      </ng-template>
    </li>
  </ul>
</div>

<div class="wrapper papers-scrollbar" *ngIf="item && form">
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<div class="footer" *ngIf="canManageItem && form">
  <div class="alert alert-warning text-center" role="alert" *ngIf="itemChanged">Metadata has been changed since editing started.</div>

  <div class="alert alert-danger text-center" *ngIf="!form.valid" role="alert">Please check the required fields.</div>

  <ng-container *ngIf="!(saving$ | async); else savingTemplate">
    <button type="button" (click)="onSubmitClick()" class="btn btn-primary w-100" [disabled]="!form.valid || !form.dirty">Save</button>
  </ng-container>
  <ng-template #savingTemplate>
    <button type="button" class="btn btn-primary w-100" [disabled]="true">Saving...</button>
  </ng-template>
</div>




<ng-template #referenceFieldsTemplate>
  <form (ngSubmit)="onSubmitClick()" (change)="onFormChange()" [formGroup]="form">

    <div class="mt-2" *ngFor="let field of fields" [ngSwitch]="field.input.type" [hidden]="field.input.hidden">

      <ng-container *ngSwitchCase="'text'">
        <div [ngClass]="{ 'form-floating': field.input.label }">
          <ng-container *ngIf="!field.input.array">
            <input type="text"
              [id]="field.id"
              [formControlName]="field.id"
              placeholder="Enter {{field.input.label | lowercase}} here..."
              class="form-control"
              [ngClass]="{'ng-warning': field.input?.warning && !form.controls[field.id]?.value}">
            <label [attr.for]="field.id" *ngIf="field.input.label">
              <ng-container *ngIf="form.controls[field.id]?.value; else labelNoValueTemplate">{{field.input.label}}</ng-container>
              <ng-template #labelNoValueTemplate>Enter {{field.input.label | lowercase}} here...</ng-template>
            </label>
          </ng-container>

          <ng-container *ngIf="field.input.array">
            <ng-container [ngSwitch]="field.readcube">
              <!--
                Custom template for search existing tags: https://readcube.atlassian.net/browse/WEBAPP-1871
                Bugfix note: Field `field.id` is different for each reference type so I changed this to use `readcube` field instead.
              -->
              <ng-container *ngSwitchCase="'user_data.tags'">
                <rcp-token-field-editable
                  class="form-control"
                  [formControlName]="field.id"
                  [search]="searchExistingTags$"
                  [label]="field.input.label"
                  placeholder="Type {{field.input.label | lowercase}} here...">
                </rcp-token-field-editable>
              </ng-container>

              <ng-container *ngSwitchCase="'article.authors'">
                <rcp-token-field-editable
                  class="form-control"
                  [formControlName]="field.id"
                  [canMoveToTop]="true"
                  [canSortAlpha]="true"
                  [label]="field.input.label"
                  placeholder="Add {{field.input.label | lowercase}} here...">
                </rcp-token-field-editable>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <rcp-token-field-editable
                  class="form-control"
                  [formControlName]="field.id"
                  [label]="field.input.label"
                  placeholder="Add {{field.input.label | lowercase}} here..."
                  [ngClass]="{'ng-warning': field.input?.warning && !form.controls[field.id]?.value?.length}">
                </rcp-token-field-editable>
              </ng-container>
            </ng-container>
          </ng-container>

          <small *ngIf="!form.controls[field.id].valid && !form.controls[field.id].disabled"
            class="form-text text-danger">{{field.input.label}} is {{field.input.required ? 'required' : 'invalid'}}</small>
        </div>
      </ng-container>


      <ng-container *ngSwitchCase="'number'">
        <div [ngClass]="{ 'form-floating': field.input.label }">
          <input type="number"
            [id]="field.id"
            [formControlName]="field.id"
            placeholder="Enter {{field.input.label | lowercase}} here..."
            class="form-control"
            [ngClass]="{'ng-warning': field.input?.warning && !form.controls[field.id]?.value}">
          <label [attr.for]="field.id" *ngIf="field.input.label">
            <ng-container *ngIf="form.controls[field.id]?.value; else labelNoValueTemplate">{{field.input.label}}</ng-container>
            <ng-template #labelNoValueTemplate>Enter {{field.input.label | lowercase}} here...</ng-template>
          </label>

          <small *ngIf="!form.controls[field.id].valid && !form.controls[field.id].disabled"
            class="form-text text-danger">{{field.input.label}} is {{field.input.required ? 'required' : 'invalid'}}</small>
        </div>
      </ng-container>


      <ng-container *ngSwitchCase="'date'">
        <common-input-date
          [id]="field.id"
          [formControlName]="field.id"
          placeholder="Enter {{field.input.label | lowercase}} here..."
          [warning]="field.input?.warning"
          [label]="field.input.label"
          [minDate]="field.input.minDate || defaultDatePickerMin"
          [maxDate]="field.input.maxDate || defaultDatePickerMax"
          [isDisabled]="form.controls[field.id].disabled">
        </common-input-date>

        <small *ngIf="!form.controls[field.id].valid && !form.controls[field.id].disabled"
          class="form-text text-danger">{{field.input.label}} is {{field.input.required ? 'required' : 'invalid'}}</small>
      </ng-container>


      <ng-container *ngSwitchCase="'textarea'">
        <div [ngClass]="{ 'form-floating': field.input.label }">
          <textarea
            [id]="field.id"
            [formControlName]="field.id"
            [rows]="5"
            class="form-control"
            placeholder="Enter {{field.input.label | lowercase}} here..."
            [ngClass]="{'ng-warning': field.input?.warning && !form.controls[field.id]?.value}">
          </textarea>

          <label [attr.for]="field.id" *ngIf="field.input.label">
            <ng-container *ngIf="form.controls[field.id]?.value; else labelNoValueTemplate">{{field.input.label}}</ng-container>
            <ng-template #labelNoValueTemplate>Enter {{field.input.label | lowercase}} here...</ng-template>
          </label>
        </div>

        <small *ngIf="!form.controls[field.id].valid && !form.controls[field.id].disabled"
          class="form-text text-muted">{{field.input.label}} is {{field.input.required ? 'required' : 'invalid'}}</small>
      </ng-container>


      <ng-container *ngSwitchCase="'select'">
        <div [ngClass]="{ 'form-floating': field.input.label }">
          <select
            [id]="field.id"
            [formControlName]="field.id"
            [ngSwitch]="field.input.options.groups.length"
            class="form-control form-control-lg"
            [ngClass]="{'ng-warning': field.input?.warning && !form.controls[field.id]?.value}">

            <ng-container *ngSwitchCase="0">
              <option *ngFor="let opt of field.input.options.values | sort:'text'" [value]="opt.id">{{opt.text}}</option>
            </ng-container>

            <ng-container *ngSwitchCase="1">
              <option *ngFor="let opt of field.input.options.values | sort:'text'" [value]="opt.id">{{opt.text}}</option>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <optgroup label="{{group.text}}" *ngFor="let group of field.input.options.groups | sort:'text'">
                <option *ngFor="let opt of filterGroupValues(field.input.options.values, group.id)" [value]="opt.id">{{opt.text}}</option>
              </optgroup>
            </ng-container>
          </select>

          <label [attr.for]="field.id" *ngIf="field.input.label">
            <ng-container *ngIf="form.controls[field.id]?.value; else labelNoValueTemplate">{{field.input.label}}</ng-container>
            <ng-template #labelNoValueTemplate>Enter {{field.input.label | lowercase}} here...</ng-template>
          </label>

          <small *ngIf="!form.controls[field.id].valid && !form.controls[field.id].disabled"
            class="form-text text-danger">{{field.input.label}} is {{field.input.required ? 'required' : 'invalid'}}</small>
        </div>
      </ng-container>

    </div>

  </form>
</ng-template>




<ng-template #referenceCustomFieldsTemplate>
  <div
    class="icon"
    [ngClass]="{'icon--active': customFieldsSearchVisible}"
    (click)="toggleCustomFieldsSearch()">
    <fa-icon [icon]="['fas', 'filter']"></fa-icon>
  </div>
  <div class="input-container form-control mb-2" [hidden]="!customFieldsSearchVisible">
    <fa-icon [icon]="['fas', 'filter']"></fa-icon>
    <input
      #customFieldsSearchInput
      type="text"
      autocomplete="false"
      spellcheck="false"
      [(ngModel)]="customFieldSearchTerm"
      (ngModelChange)="updateCustomFieldsFilter($event)"
      placeholder="Filter fields...">
  </div>

  <form (ngSubmit)="onSubmitClick()" (change)="onFormChange()" [formGroup]="form">

    <div ngbAccordion [closeOthers]="true"
      class="mt-2"
      #acc="ngbAccordion">
      <div ngbAccordionItem="{{section.key}}"
        [disabled]="customFieldSearchTerm && !expandedCustomFieldSections.includes(section.key)"
        [collapsed]="!expandedCustomFieldSections.includes(section.key)"
        *ngFor="let section of customFieldsBySection | keyvalue, let i = index">
        
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton>{{section.key | titlecase}} <fa-icon *ngIf="hasFormCustomError(section.key)" [icon]="['fal', 'exclamation-circle']" class="text-danger"></fa-icon></button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="mt-2" *ngFor="let field of section.value" [ngSwitch]="field.type" [hidden]="isCustomFieldHidden(field)">
                <ng-container *ngSwitchCase="'text'">
                  <div [ngClass]="{ 'form-floating': field.display }">
                    <input type="text"
                      [id]="field.field"
                      [formControlName]="field.field"
                      placeholder="Enter {{field.display | lowercase}} here..."
                      class="form-control">

                    <label [attr.for]="field.field" *ngIf="field.display">
                      <ng-container *ngIf="form.controls[field.field]?.value; else labelNoValueTemplate">{{field.display}}</ng-container>
                      <ng-template #labelNoValueTemplate>Enter {{field.display | lowercase}} here...</ng-template>
                    </label>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'array'">
                  <rcp-token-field-editable
                    [formControlName]="field.field"
                    [label]="field.display"
                    placeholder="Enter {{field.display | lowercase}} here..."
                    class="form-control">
                  </rcp-token-field-editable>
                </ng-container>

                <ng-container *ngSwitchCase="'array-lookup'">
                  <rcp-token-field
                    [formControlName]="field.field"
                    [search]="searchCustomFieldLookups"
                    [editable]="false"
                    (textChange)="customFieldLookups = field.lookups"
                    placeholder="Enter {{field.display | lowercase}} here..."
                    class="form-control">

                    <ng-template #tokenTemplate let-lookup="item">
                      <span>{{lookup}}</span>
                    </ng-template>

                    <ng-template #resultTemplate let-result="result" let-term="term">
                      <span>{{result}}</span>
                    </ng-template>
                  </rcp-token-field>
                </ng-container>

                <ng-container *ngSwitchCase="'textarea'">
                  <div [ngClass]="{ 'form-floating': field.display }">
                    <textarea
                      [id]="field.field"
                      [formControlName]="field.field"
                      placeholder="Enter {{field.display | lowercase}} here..."
                      class="form-control"></textarea>

                    <label [attr.for]="field.field" *ngIf="field.display">
                      <ng-container *ngIf="form.controls[field.field]?.value; else labelNoValueTemplate">{{field.display}}</ng-container>
                      <ng-template #labelNoValueTemplate>Enter {{field.display | lowercase}} here...</ng-template>
                    </label>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'date'">
                  <common-input-date
                    [id]="field.field"
                    [formControlName]="field.field"
                    placeholder="Enter {{field.display | lowercase}} here..."
                    [label]="field.display"
                    [warning]="false">
                  </common-input-date>
                </ng-container>

                <ng-container *ngSwitchCase="'number'">
                  <div [ngClass]="{ 'form-floating': field.display }">
                    <input type="number"
                      [id]="field.field"
                      [formControlName]="field.field"
                      placeholder="Enter {{field.display | lowercase}} here..."
                      class="form-control">

                    <label [attr.for]="field.field" *ngIf="field.display">
                      <ng-container *ngIf="form.controls[field.field]?.value; else labelNoValueTemplate">{{field.display}}</ng-container>
                      <ng-template #labelNoValueTemplate>Enter {{field.display | lowercase}} here...</ng-template>
                    </label>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'bool'">
                  <div class="form-check form-switch form-switch-md ms-2 mt-2 pt-2 pb-2" >
                    <input type="checkbox"
                      [id]="field.field"
                      [formControlName]="field.field"
                      class="form-check-input">

                    <label class="form-check-label" [attr.for]="field.field" *ngIf="field.display">{{field.display}}</label>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="'lookup'">
                  <div [ngClass]="{ 'form-floating': field.display }">
                    <select
                      [id]="field.field"
                      [formControlName]="field.field"
                      [ngSwitch]="field.lookups?.length || 0"
                      class="form-control">

                      <ng-container *ngSwitchCase="0">
                        <option>None</option>
                      </ng-container>

                      <ng-container *ngSwitchDefault>
                        <option [value]="''"></option>
                        <option *ngFor="let opt of field.lookups" [value]="opt">{{opt}}</option>
                      </ng-container>
                    </select>

                    <label [attr.for]="field.field" *ngIf="field.display">
                      <ng-container *ngIf="form.controls[field.field]?.value; else labelNoValueTemplate">{{field.display}}</ng-container>
                      <ng-template #labelNoValueTemplate>Enter {{field.display | lowercase}} here...</ng-template>
                    </label>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

  </form>
</ng-template>
