import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NavigationDrawerSearchComponent } from './components/navigation-drawer-search.component';
import { NavigationDrawerLibraryComponent } from './components/navigation-drawer-library.component';
import { NavigationDrawerReviewComponent } from './components/navigation-drawer-review.component';


const routes: Routes = [
  {
    path: 'search',
    component: NavigationDrawerSearchComponent,
    outlet: 'drawer'
  },
  {
    path: 'library',
    component: NavigationDrawerLibraryComponent,
    outlet: 'drawer'
  },
  {
    path: 'review',
    component: NavigationDrawerReviewComponent,
    outlet: 'drawer'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppNavigationRoutingModule { }
