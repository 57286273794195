import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { map, timeout, retry, catchError } from 'rxjs/operators';

import { BUILD_TIME } from 'build';
import { environment } from 'environment';

export interface IUpdateInfo {
  version: string;
  time: number;
}

@Injectable()
export class AppUpdateService {
  constructor(
    private http: HttpClient
  ) { }

  public available(): Observable<IUpdateInfo | null> {
    if (!environment.baseUrls.updateManifest) {
      return observableOf(null);
    }
    return this.http.get<IUpdateInfo>(environment.baseUrls.updateManifest, {
      params: { 't': Date.now().toString() }
    }).pipe(
      map(build => {
        const a = new Date(build.time * 1000);
        const b = new Date(BUILD_TIME * 1000);
        if (a.getTime() > b.getTime()) {
          return build;
        }
        return null;
      }),
      catchError(() => observableOf(null)),
      timeout(5000),
      retry(3)
    );
  }
}
