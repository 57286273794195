import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ExporterService, IExportItemsParams, IExportType } from '../services/exporter.service';

@Component({
  templateUrl: './exporter-modal-options.component.html'
})
export class ExporterModalOptionsComponent implements OnInit {
  params: IExportItemsParams;

  protected availableExportTypes: IExportType[];

  constructor(
    public route: ActivatedRoute,
    public exporter: ExporterService,
  ) {
    this.params = JSON.parse(this.route.snapshot.queryParams['params']);
  }

  get hasOptions(): boolean {
    return ['csv'].includes(this.params.type);
  }

  ngOnInit() {
    this.exporter.getAvailableExportTypes(this.params).then(types => {
      this.availableExportTypes = types.filter(type => type.visible);
      this.params.type = types.find(t => t.type === this.params.type).type;
    });
  }

  next() {
    this.exporter.openExportItemsDialog(this.params, ['exporter', 'options', this.params.type]);
  }

  cancel() {
    this.exporter.closeExportItemsDialog();
  }

  export() {
    this.exporter.export(this.params);
    this.exporter.closeExportItemsDialog();
  }
}
