import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { of as observableOf } from 'rxjs';

@Directive({
  selector: '[groupInvitedValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GroupInvitedValidator),
      multi: true
    }
  ]
})
export class GroupInvitedValidator implements Validator {
  @Input()
  groupInvitedList: string[] = [];

  validate(control: FormControl) {
    const value = control.value;
    
    if (value instanceof Array) {
      const invalidGroups = value.filter(group => this.groupInvitedList.includes(group.id));
      return invalidGroups.length ? { groupInvited: invalidGroups } : null;
    } else if (value) {
      return this.groupInvitedList.includes(value) ? { groupInvited: true } : null;
    }
    return observableOf(null);
  }
}
