import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../common';

@Component({
  templateUrl: './navigation-drawer-library.component.html',
  styleUrls: ['./navigation-drawer.component.scss']
})
export class NavigationDrawerLibraryComponent  {
  headerItems = [
    {
      name: 'My Papers',
      icon: 'home',
      action: () => {
        this.router.navigate(['/library', this.shared.user?.id]);
      }
    },
    {
      name: 'Reading List',
      icon: 'pin',
      action: () => {
        // todo check what to do with reading list
        this.router.navigate(['/library/search']);
      }
    }
  ];

    // hardcoded for now
    savedFolders = [
      {
        title: 'Central Library',
        info: 'Shared libraries',
        notification: '12 new',
        icon: 'folder-user',
      },
      {
        title: 'Shared Library',
        info: 'Project1',
        icon: 'folder',
      }
    ];
  
    recentPdfs = [
      {
        title: 'Valvular Heart Disease in the Protein Landscape',
        info: 'Project1 ',
        icon: 'history',
      },
      {
        title: 'Valvular Heart Disease with Covid-19 Complications',
        info: 'Central Library',
        icon: 'history',
      }
    ];

  constructor(
    private router: Router,
    private shared: SharedService
  ) { }

}
