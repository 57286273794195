<context-menu #contextMenuComponent>
  <ng-template contextMenuItem (execute)="rename()" [enabled]="canEditTag()">Rename</ng-template>
  <ng-template contextMenuItem (execute)="delete()" [enabled]="canEditTag()">Delete</ng-template>
</context-menu>

<common-navigation-button
  [indent]="indent"
  [routerLink]="['/library', collection.id, 'tag', tag.id]"
  routerLinkActive="active"
  [contextMenu]="contextMenuComponent"

  [rcpDroppable]="isDroppable.bind(this)"
  rcpDropOverClass="blink"
  (rcpDrop)="onDrop($event)"

  [ngClass]="{
    'disabled': disabled$ | async,
    'loading': loading$ | async
  }">
  <ng-container icon>
    <rcp-svg-icon name="tag"></rcp-svg-icon>
  </ng-container>

  <span [innerHTML]="tag.name | highlight:highlight"></span>

  <ng-container addons>
    <common-navigation-button-addon (click)="openContextMenu($event)">
      <fa-icon [icon]="['fas', 'cog']"></fa-icon>
    </common-navigation-button-addon>
  </ng-container>
</common-navigation-button>
