import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControlOptions, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { strtrunc } from '@readcube/rcp-common';
import { CsvInfo } from '@readcube/rcp-csv-items';

import { ITemplateOptionsCsv } from '../../common';
import { IDataUser } from '../../common-data';
import { AppShellService } from '../../app-shell.service';
import { ImporterService } from '../services/importer.service';
import { IBannerError } from './importer-error-banner.component';

type File = any;

@Component({
  selector: 'rcp-importer-options-csv',
  templateUrl: './importer-options-csv.component.html',
  styleUrls: ['./importer-options-csv.component.scss']
})
export class ImporterOptionsCsvComponent implements OnInit {
  @Input()
  user: IDataUser;

  @Input()
  collectionId: string;

  @Input()
  files: File[] = [];

  @Input()
  options: ITemplateOptionsCsv;

  @Output()
  optionsChange = new EventEmitter<ITemplateOptionsCsv>();

  @Input()
  error: IBannerError;

  @Output()
  errorChange = new EventEmitter<IBannerError>();

  csvInfo: CsvInfo;
  formGroup: FormGroup;
  extraMap = [];

  constructor(
    public fb: FormBuilder,
    public importer: ImporterService,
    public shell: AppShellService
  ) {}

  ngOnInit() {
    this.importer.getCSVInfo(this.files[0], this.collectionId).then(r => {
      if (r.success === false) {
        this.setError(r.error);
      } else {
        const csvInfo = r.result;
        this.csvInfo = csvInfo;
        this.formGroup = this.createFormGroup(csvInfo);
        this.updateOptions();
      }
    });
  }

  // This is only called from parrent component when loading template
  loadOptions(options: ITemplateOptionsCsv) {
    if (!this.formGroup) return;

    const csvMappingFormGroup = <FormArray>this.formGroup.controls['csvMapping'];
    options.csvMapping.forEach(opt => {
      for (let ctrl of csvMappingFormGroup.controls) {
        let key = (<FormGroup>ctrl).controls['key'].value;
        if (opt.key === key) ctrl.patchValue(opt);
      }
    });
    this.formGroup.controls['mergeDuplicates'].patchValue(options.mergeDuplicates);
    this.formGroup.controls['resolveMetadata'].patchValue(options.resolveMetadata);
    this.validate(options);
  }

  setError(error: null | IBannerError) {
    this.updateAllMappingValidators();
    this.error = error;
    setTimeout(() => this.errorChange.next(error));
  }

  validate(options: ITemplateOptionsCsv) {
    if (!this.formGroup) return;
    const errors = this.formGroup.controls['csvMapping'].errors || {};
    if (errors.required) {
      this.setError({
        title: 'Missing field mappings',
        message: 'Please verify highlighted fields!'
      });
    } else if (errors.duplicate_keys) {
      this.setError({
        title: 'This table has duplicate headers',
        message: 'Please update duplicate headers or disable some of highlighted columns!'
      });
    } else if (errors.duplicate_fields) {
      this.setError({
        title: 'Duplicate field mappings',
        message: 'Please verify highlighted duplicate fields!'
      });
    } else {
      this.setError(null);
    }
  }

  updateOptions() {
    this.options = this.formGroup.value;
    this.optionsChange.next(this.options);
  }

  updateAllMappingValidators() {
    const formArray = this.formGroup.controls['csvMapping'] as FormArray;
    formArray.controls.forEach(c => c.updateValueAndValidity({ onlySelf: true }));
  }

  dataPreview(i: number): string {
    return strtrunc(this.csvInfo.defaultMap.concat(this.extraMap)[i].dataPreview as string, 50, true);
  }

  createFormGroup(csvInfo: CsvInfo) {
    const csvMappingControls = csvInfo.defaultMap.concat(this.extraMap).map(v => {
      const ctrlOptions = {
        validators: csvMappingFormGroupValidatorFn()
      } as AbstractControlOptions;

      return this.fb.group({
        'key': v.key,
        'enabled': !!v.papersField || !!v.customField,
        'custom': !!v.customField,
        'papersField': this.fb.control({ value: v.papersField || '', disabled: false }),
        'customField': this.fb.control({ value: v.customField || '', disabled: false }),
        'exportHeader': v.key,
      }, ctrlOptions);
    });
    return this.fb.group({
      // Default import options
      'mergeDuplicates': false,
      'resolveMetadata': false,
      // Default export options
      'customizeExport': true,
      'exportHeader': true,
      'customizeHeader': false,
      'exportDelimiter': ',',
      // Common mapping options
      'csvMapping': this.fb.array(csvMappingControls, csvMappingFormArrayValidatorFn())
    });
  }
}

function csvMappingFormGroupValidatorFn() {
  return (formGroup: FormGroup) => {
    // No validation required if mapping is disabled.
    const key = formGroup.controls['key'].value;
    const isEnabled = formGroup.controls['enabled'].value;
    const isCustom = formGroup.controls['custom'].value;
    const papersField = formGroup.controls['papersField'].value;
    const customField = formGroup.controls['customField'].value;

    if (!isEnabled) {
      return null;
    }
    if (!papersField && !isCustom) {
      return { required: true };
    }
    if (!customField && isCustom) {
      return { required: true };
    }

    // Get all selected values from parent formArray and filter those that are disabled.
    const formArray = formGroup.parent as FormArray;
    if (!formArray) {
      return false;
    }

    const { enabledKeys, selectedValues } = formArray.controls
      .filter(ctrl => ctrl != formGroup)
      .reduce((acc, group: FormGroup) => {
        const key = group.controls['key'].value;
        const enabled = group.controls['enabled'].value;
        const custom = group.controls['custom'].value;
        const papersField = group.controls['papersField'].value;
        const customField = group.controls['customField'].value;

        if (enabled) {
          acc.enabledKeys.push(key);
        }
        if (enabled && custom === isCustom) {
          acc.selectedValues.push(custom ? customField : papersField);
        }
        return acc;
      }, {
        enabledKeys: [],
        selectedValues: []
      });

    const errors = {};
    if (enabledKeys.includes(key)) {
      errors['duplicate_key'] = true;
    }
    if (selectedValues.includes(isCustom ? customField : papersField)) {
      errors['duplicate_field'] = true;
    }
    return errors;
  };
}

function csvMappingFormArrayValidatorFn() {
  return (formArray: FormArray) => {
    const { enabledKeys, selectedValues } = formArray.controls
      .map((group: FormGroup) => group.controls)
      .filter(controls => controls['enabled'].value)
      .reduce((acc, controls) => {
        const key = controls['key'].value;
        const customField = controls['customField'].value;
        const papersField = controls['papersField'].value;

        acc.enabledKeys.push(key);
        if (controls['custom'].value) {
          acc.selectedValues.customFields.push(customField);
        } else {
          acc.selectedValues.papersFields.push(papersField);
        }
        return acc;
      }, {
        enabledKeys: [],
        selectedValues: {
          customFields: [],
          papersFields: [],
        },
      });

    if (selectedValues.papersFields.some(v => !v)) {
      return { required: true };
    }
    if (selectedValues.customFields.some(v => !v)) {
      return { required: true };
    }
    if (enabledKeys.length != new Set(enabledKeys).size) {
      return { duplicate_keys: true };
    }
    if (selectedValues.papersFields.length != new Set(selectedValues.papersFields).size) {
      return { duplicate_fields: true };
    }
    if (selectedValues.customFields.length != new Set(selectedValues.customFields).size) {
      return { duplicate_fields: true };
    }
    return null;
  };
}
