import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rcp-importer-file-list',
  templateUrl: './importer-file-list.component.html',
  styleUrls: ['./importer-file-list.component.scss'],
})
export class ImporterFileListComponent {
  @Input()
  files: File[] = [];

  @Output()
  filesChange = new EventEmitter<File[]>();

  removeFile(index: number) {
    this.files.splice(index, 1);
    this.filesChange.next(this.files);
  }

  getIconClass(fileType: string) {
    switch (fileType) {
      case 'application/pdf':
        return 'file-pdf';
      case 'text/plain':
        return 'file-text';
      case 'text/csv':
        return 'file-csv';
      case 'image/bmp':
      case 'image/jpeg':
      case 'image/x-png':
      case 'image/png':
      case 'image/gif':
        return 'file-image';
      default:
        return 'file';
    }
  }
}
