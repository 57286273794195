import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../common';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  @HostListener('mouseleave')
  onMouseLeave() {
    this.navigationService.closeDrawer();
  }

  constructor(
    public router: Router,
    public shared: SharedService,
    public navigationService: NavigationService
  ) {  }

  isRouteActive(route: string): boolean {
    return this.router.url.startsWith(route);
  }
}
