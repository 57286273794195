import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from 'environment';

export interface IDataItemExtIds {
  doi?: string;
  pmid?: string;
  pmcid?: string;
  arxiv?: string;
  patent_id?: string;
}

export type ItemExtIdType = keyof IDataItemExtIds;

export interface IDataItemStatus {
  hosted: boolean;
  copyright?: string;
  altmetric_id?: string;
  crossref_publisher: string;
  meta: IDataItemStatusMeta,
  status_token: string;
  thumbnail: string;
  open_access: boolean;
  free_access: boolean;
  subscription_access: boolean;
  purchase_required: boolean;
  load_in_viewer: boolean;
  dimensions_publication_id: string;
  access_programs: string[];
  publisher: string;
  pdf_urls: IDataItemStatusPdfUrl[];
  getftr_entitled: boolean;
  purchasable_by: 'publisher_token_client' | 'publisher_token_server' | 'readcube_docdel';
  items?: IDataArticleItemStatus[];
}

export interface IDataItemStatusMeta {
  year: string,
  issn: string,
  eissn: string,
  volume: string,
  issue: string,
  member_id: string,
}

export interface IDataItemStatusPdfUrl {
  url: string;
  direct: boolean;
  source: 'publisher' | 'publisher_alternative' | 'preprint' | 'repository';
  auth_method: 'sso' | 'ip';
  label: string;
}

export class IDataArticleItemStatus {
  id: string;
  collection_id: string;
  collection_name: string;
  collection_shared: boolean;
  has_file: boolean;
  notes: string;
}

@Injectable()
export class DataStatusApiService {
  public statusCache = new Map<string, IDataItemStatus>();

  constructor(
    private http: HttpClient
  ) { }

  // Fetches article access data, thumbnail, urls and other stuff
  getArticleData(extIds: IDataItemExtIds, skipItemsCheck = true, nocache = false): Promise<IDataItemStatus> {
    if (!nocache && this.statusCache.has(extIds.doi)) {
      const result = this.statusCache.get(extIds.doi);
      return Promise.resolve(result);
    }

    const statusUrl = `${environment.baseUrls.contentServices}/status`;
    let statusPromise = Promise.resolve(null);
    if (extIds.doi) {
      statusPromise = firstValueFrom(this.http.get(statusUrl, {
        params: new HttpParams({
          fromObject: { 'dois[0]': extIds.doi, 'skip_items_check': (skipItemsCheck ? 1 : '') }
        })
      })).then(result => result[extIds.doi]);
    }

    return statusPromise.then(status => {
      this.statusCache.set(extIds.doi, status);
      return status;
    });
  }

  getAltmetricData(doi: string) {
    const req = this.http.get(`https://api.altmetric.com/v1/doi/${encodeURIComponent(doi)}`, {
      params: new HttpParams({
        fromObject: { 'key': environment.altmetricToken }
      })
    });
    return firstValueFrom(req).catch(() => null);
  }

  checkInLibraryStatus(extIds: IDataItemExtIds, nocache = false): Promise<IDataArticleItemStatus[]> {
    const checkExtIdsUrl = `${environment.baseUrls.sync}/status/check_ext_ids`;
    return firstValueFrom(this.http.post(checkExtIdsUrl, {
      ext_ids: [extIds]
    })).then(result => {
      return Array.isArray(result) ? result[0] : [];
    });
  }

  checkInLibraryStatuses(allExtIds: IDataItemExtIds[]): Promise<IDataArticleItemStatus[][]> {
    const statusUrl = `${environment.baseUrls.sync}/status/check_ext_ids`;
    if (allExtIds.length === 0) {
      return Promise.resolve([]);
    }
    return firstValueFrom(this.http.post<IDataArticleItemStatus[][]>(statusUrl, { ext_ids: allExtIds }));
  }
}
