<form #copyForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">Copy</h4>
  </div>

  <div class="modal-body">

    <ng-container *ngIf="hasDestinationSelect">

      <b class="papers-label">Destination</b>

      <div class="form-floating mt-3">
        <select
          name="collection-id-select"
          class="form-select"
          [(ngModel)]="targetCollectionId"
          (ngModelChange)="collectionIdChange.next(targetCollectionId)"
          required>
          <option [ngValue]="c.id" *ngFor="let c of collections$ | async">{{c.name || 'My Papers'}}</option>
        </select>
        <label for="collection-id-select">Into library</label>
      </div>

      <div class="form-floating mt-3">
        <input type="text"
          name="list-select"
          class="form-control"
          popupClass="papers-scrollbar"
          #listSelectTypeahead="ngbTypeahead"
          [ngbTypeahead]="selectListSearch"
          [(ngModel)]="selectedList"
          [inputFormatter]="selectListFormatter"
          [resultFormatter]="selectListFormatter"
          [editable]="false"
          [disabled]="!targetCollectionId"
          [required]="(fromCollectionId === targetCollectionId) && (lists$ | async)?.length"
          (focus)="listFocus$.next($any($event).target.value)"
          (click)="listClick$.next($any($event).target.value)">
        <label for="list-select" class="papers-label">To list</label>
      </div>

      <br><br>

    </ng-container>

    <ng-container *ngIf="!hasDestinationSelect || (fromCollectionId !== targetCollectionId)">

      <b class="papers-label">Options</b>

      <div class="form-check form-switch mt-3">
        <input
          class="form-check-input"
          type="checkbox"
          id="include-files-checkbox"
          name="include-files-checkbox"
          [(ngModel)]="importOptions.includeFiles">
        <label class="form-check-label" for="include-files-checkbox">Include PDFs</label>
      </div>

      <div class="form-check form-switch mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          id="copy-annotations-checkbox"
          name="copy-annotations-checkbox"
          [(ngModel)]="importOptions.copyUserData">
        <label class="form-check-label" for="copy-annotations-checkbox">Merge Annotations</label>
      </div>

      <div class="form-check form-switch mt-2" *ngIf="importOptions.includeFiles && shared.user?.licence?.copyright_show_status">
        <input
          class="form-check-input"
          type="checkbox"
          id="exclude-without-copyright-checkbox"
          name="exclude-without-copyright-checkbox"
          [(ngModel)]="importOptions.excludeFilesWithoutCopyright">
        <label class="form-check-label" for="exclude-without-copyright-checkbox">Exclude PDFs without Copyright</label>
      </div>

      <div class="form-check form-switch mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          id="merge-duplicates-checkbox"
          name="merge-duplicates-checkbox"
          [(ngModel)]="importOptions.mergeDuplicates"
          (ngModelChange)="onMergeDuplicatesToggle($event)">
        <label class="form-check-label" for="merge-duplicates-checkbox">Merge Duplicates</label>
      </div>

      <ng-container *ngIf="hasDestinationSelect">
        <div class="form-check form-switch mt-2">
          <input
            class="form-check-input"
            type="checkbox"
            id="duplicates-sublist-checkbox"
            name="duplicates-sublist-checkbox"
            [disabled]="!importOptions.mergeDuplicates"
            [(ngModel)]="hasDuplicatesSublist">
          <label class="form-check-label" for="duplicates-sublist-checkbox">Save Duplicates to a Sub List</label>
        </div>

        <div class="form-floating mt-2" *ngIf="hasDuplicatesSublist">
          <input
            name="sublist-name-input"
            type="text"
            class="form-control"
            #sublistName="ngModel"
            [(ngModel)]="duplicatesSublist"
            autocomplete="off"
            maxlength="50"
            emptyValidator
            required>
          <span class="form-text text-muted" *ngIf="sublistName.control.errors?.required && sublistName.touched">Sub list name is required.</span>
          <span class="form-text text-muted" *ngIf="sublistName.control.errors?.empty && sublistName.touched">Sub list name name cant't be empty.</span>
          <label for="library-name-input">Sub List Name</label>
        </div>
      </ng-container>

    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!copyForm.form.valid">Copy</button>
  </div>

</form>
