import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './common-dialog-alert.component.html'
})
export class CommonDialogAlertComponent {
  title = '';
  message = '';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.close();
  }
}
