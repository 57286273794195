import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpEventType, HttpDownloadProgressEvent } from '@angular/common/http';
import { Observable, filter, map } from 'rxjs';
import { IDataItem } from './data-item-api.service';
import { environment } from 'environment';

export interface IAiQueryProgress {
  responses?: IAiQueryResponse[],
  progress?: number;
}
export interface IAiQueryResponse {
  pdf_hash: string;
  answer: string;
  excerpts: IAiQueryExcerpt[];
}

export interface IAiQueryExcerpt {
  page: number;
  id: number;
  text: string;
  last_page: boolean;
  origin: any;
}

@Injectable()
export class DataAiApiService {

  constructor(
    private http: HttpClient
  ) { }

  public query(items: IDataItem[], question: string): Observable<IAiQueryProgress> {
    const pdfHashes = items.map(item => item.primary_file_hash).filter(h => !!h);

    const hashesFragment = pdfHashes.map(hash => `&pdf_hashes[]=${hash}`).join('');
    const endpointUrl = `${environment.baseUrls.api}/assistant/multi_ask.jsonl?question=${encodeURIComponent(question)}${hashesFragment}`;
    const headers = new HttpHeaders().set('Content-Type', 'text/jsonl');

    return this.http.get(endpointUrl, { headers, responseType: 'text', reportProgress: true, observe: 'events' }).pipe(
      filter(event => [HttpEventType.DownloadProgress, HttpEventType.Response].includes(event.type)),
      map((event: HttpEvent<any>) => this.mapEvent(event, pdfHashes.length)));
  }

  public summarize(responses: string[]): Observable<string> {
    const endpointUrl = `${environment.baseUrls.api}/assistant/summarize/`;
    return this.http.post<string>(endpointUrl, { responses }).pipe(map(res => res['summary']));
  }

  public getQuery(question: string): Observable<string> {
    const endpointUrl = `${environment.baseUrls.api}/assistant/generate_query?type=collections&question=${encodeURIComponent(question)}`;
    return this.http.get<string>(endpointUrl).pipe(map(res => res['query']));
  }

  private mapEvent(event: HttpEvent<any>, total: number): IAiQueryProgress {
    if (event.type === HttpEventType.Response && event.body) {
      return { responses: this.parseLines(event.body), progress: 1 };
    } else {
      const part = (<HttpDownloadProgressEvent>event).partialText;
      const results = this.parseLines(part);
      return { responses: [], progress: (results.length / total) };
    }
  }

  private parseLines(text) {
    const lines = text.trim().split('\n');
    const result: any[] = [];

    lines.forEach((line) => {
      try {
        const obj = JSON.parse(line.trim());
        result.push(obj);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        console.log("line", line);
      }
    });
    return result;
  }
}
