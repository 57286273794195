import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgbAccordionDirective, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Subject, Observable, debounceTime, distinctUntilChanged, mergeMap, of, map } from 'rxjs';
import { strtrunc } from '@readcube/rcp-common';
import default_type_schema from '@readcube/rcp-meta/type_schema.json';

import { DataItemService, IDataCollection, IDataCollectionCustomField, IDataCollectionFormField, IDataItem } from '../../library-data';
import { PreviewService, ISelectionMessage } from '../../library-preview';
import { AppShellService } from '../../app-shell.service';
import { LibrarySidepanelService } from '../services/library-sidepanel.service';

const CONFIRM_SAVE_MODAL_TITLE = 'Unsaved changes';
const CONFIRM_SAVE_MODAL_TEXT = 'You have unsaved changes on this reference. Do you want to save your changes?';

function is_empty(obj: any) {
  return obj.constructor === Object && Object.entries(obj).length === 0;
}

function obj_set(obj: object, path: string[], value: any): void {
  const field = path.shift();
  if (path.length === 0) {
    obj[field] = value;
    return;
  }
  if (!obj[field]) {
    obj[field] = {};
  }
  obj_set(obj[field], path, value);
}

function obj_get(obj: object, path: string[]): any {
  const field = path.shift();
  if (path.length === 0) {
    return obj[field];
  }
  return obj_get(obj[field], path);
}

@Component({
  templateUrl: './library-sidepanel-edit.component.html',
  styleUrls: [
    './library-sidepanel.component.scss',
    './library-sidepanel-edit.component.scss'
  ]
})
export class LibrarySidepanelEditComponent implements OnInit, OnDestroy {
  item: IDataItem;
  collection: IDataCollection;
  fields: IDataCollectionFormField[];
  customFields: IDataCollectionCustomField[];
  customFieldsBySection: { [key: string]: IDataCollectionCustomField[]; } = {};

  form: FormGroup;
  selectionSub: Subscription;
  itemSub: Subscription;
  collectionSub: Subscription;

  itemChanged = true;
  saving$ = new Subject<boolean>();

  @ViewChild('acc') accordion: NgbAccordionDirective;
  @ViewChild('customFieldsSearchInput') customFieldsSearchInput: ElementRef;
  customFieldSearchTerm = '';
  expandedCustomFieldSections: string[] = [];
  customFieldsSearchVisible: boolean = false;
  customFieldLookups: string[] = [];

  defaultDatePickerMin = { year: 1900, month: 1, day: 1 };
  defaultDatePickerMax = { year: 2100, month: 1, day: 1 };

  constructor(
    public location: Location,
    public dataItem: DataItemService,
    public sidepanel: LibrarySidepanelService,
    public preview: PreviewService,
    public router: Router,
    public route: ActivatedRoute,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.selectionSub = this.preview.selection$.subscribe(msg => {
      if (msg.type === 'marked' && this.form) {
        this.onResolverTextSelected(msg);
      }
    });

    this.itemSub = this.sidepanel.item$.subscribe(item => {
      this.itemChanged = false;
      const formDirty = this.form?.dirty;
      if (this.collection && this.item) {
        const nextItem = item.id != this.item.id;
        if (nextItem && formDirty) {
          const patch = this.createItemPatch(this.form);
          this.openConfirmSave(this.item.collection_id, this.item.id, patch);
        }
        if (!formDirty || nextItem) {
          const itemTypeKey = this.getItemTypeKey(this.collection);
          this.item = item;
          this.updateForm(item[itemTypeKey], this.collection, true);
        } else if (formDirty) {
          this.itemChanged = true;
        }
      } else {
        this.item = item;
        if (this.collection) {
          const itemTypeKey = this.getItemTypeKey(this.collection);
          this.updateForm(item[itemTypeKey], this.collection, true);
        }
      }
    });

    this.collectionSub = this.sidepanel.collection$.subscribe(collection => {
      this.collection = collection;
      if (this.item) {
        const itemTypeKey = this.getItemTypeKey(this.collection);
        this.updateForm(this.item[itemTypeKey], collection, false);
      }
    });
  }

  get canManageItem(): boolean {
    return this.collection?.user?.can_create_item;
  }

  ngOnDestroy() {
    this.itemSub.unsubscribe();
    this.collectionSub.unsubscribe();
    this.selectionSub.unsubscribe();
  }

  canDeactivate(): boolean {
    if (this.form && this.form.dirty) {
      const patch = this.createItemPatch(this.form);
      this.openConfirmSave(this.item.collection_id, this.item.id, patch);
    }
    return true;
  }

  // Creates form group according to reference fields and fields defined in collection.custom_fields
  createFormGroup(referenceFields: IDataCollectionFormField[], customFields: IDataCollectionCustomField[], reset = true) {
    const controls: { [key: string]: AbstractControl; } = {};

    // Create form controls for referenceFields
    referenceFields.forEach(field => {
      const validators = [];
      if (field.input.required) {
        validators.push(Validators.required);
      }
      if (field.input.pattern) {
        const pattern = field.input.pattern;
        const flags = field.input.pattern_insensitive ? 'i' : undefined;
        validators.push(Validators.pattern(new RegExp(pattern, flags)));
      }
      const fieldId = field.id;
      const currentControl = this.form?.controls[fieldId];
      const formValue = reset ? undefined : currentControl?.value;
      const currentValue = this.getFieldValue(field);
      const formControl = new FormControl({
        value: formValue || currentValue || null,
        disabled: !this.canManageItem || field.input.disabled,
      }, validators);
      if (!reset && currentControl?.dirty) {
        formControl.markAsDirty();
      }
      controls[fieldId] = formControl;
    });

    // Create form controls for collection.custom_fields
    customFields.forEach(field => {
      const fieldId = field.field;
      const currentControl = this.form?.controls[fieldId];
      const formValue = reset ? undefined : currentControl?.value;
      const currentValue = this.getCustomFieldValue(field);
      const formControl = new FormControl({
        value: formValue || currentValue,
        disabled: !this.canManageItem || (field.type === 'lookup' && !field.lookups?.length),
      });
      if (!reset && currentControl?.dirty) {
        formControl.markAsDirty();
      }
      controls[fieldId] = formControl;
    });

    // Combine form controls into form group
    const formGroup = new FormGroup(controls);
    if (!reset && this.form?.dirty) {
      formGroup.markAsDirty();
    }
    return formGroup;
  }

  getFieldValue(field: IDataCollectionFormField): any {
    const defaultValue = field.input.default || (field.input.array ? [] : '');
    let currentValue;
    if (field.readcube) {
      const path = field.readcube.split('.');
      currentValue = obj_get(this.item, path);
    } else if (field.custom_metadata) {
      const key = field.custom_metadata;
      const isArray = field.input.array || false;
      const value = this.item.custom_metadata[key] || '';
      currentValue = isArray && value && !Array.isArray(value) ? value.split(',') : value;
    }
    return currentValue || defaultValue;
  }

  getCustomFieldValue(field: IDataCollectionCustomField): any {
    const value = this.item.custom_metadata[field.field];
    switch (<any>field.type) {
      case 'array':
      case 'array-lookup':
        try {
          const parsed = JSON.parse(value);
          if (parsed && typeof parsed === "object") {
            return parsed;
          } else {
            return [];
          }
        }
        catch (e) {
          return [];
        }
      case 'number':
        return value || 0;
      case 'bool':
        return value === 'true' ? true : (value === 'false') ? false : null;
      default:
        return value || '';
    }
  }

  // Collects changed form fields and maps them to Item according to various rules.
  createItemPatch(form: FormGroup) {
    const patch: Partial<IDataItem> = {};
    for (let key in form.value) {
      const value = form.value[key];
      const control = form.controls[key];

      // Skips unchanged fields.
      if (!control.dirty) {
        continue;
      }

      const field = this.fields.find(f => f.id == key);
      const customField = this.collection.custom_fields.find(f => f.field == key);

      if (!field && customField) {
        if (!patch.custom_metadata) {
          patch.custom_metadata = {};
        }
        // TODO: here custom_fields can be sanitized
        patch.custom_metadata[key] = (value === false) ? 'false' : value;
      }

      // Custom exception to update year field...
      else if (field.custom_metadata === 'date') {
        const isYearOnlyInput = /^[0-9]*$/g.test(value);
        const d = new Date(value);

        const y = isYearOnlyInput ? d.getUTCFullYear().toString() : d.getFullYear().toString();

        if (this.item.article.year !== y) {
          if (!patch.article) {
            patch.article = {};
          }
          patch.article.year = y;
          if (!patch.custom_metadata) {
            patch.custom_metadata = {};
          }
          patch.custom_metadata.date = value.trim();
        }
      }

      // Maps value to custom_metadata acording to type schema
      else if (field.custom_metadata) {
        const key = field.custom_metadata;
        if (!patch.custom_metadata) {
          patch.custom_metadata = {};
        }
        if (Array.isArray(value)) {
          patch.custom_metadata[key] = value.map(v => v.trim()).join(',');
        } else {
          patch.custom_metadata[key] = value.trim();
        }
      }

      // Maps value to Item object acording to path defined in type schema.
      else if (field.readcube) {
        const path = field.readcube.split('.');
        if (Array.isArray(value)) {
          obj_set(patch, path, value.map(v => v.trim()));
        } else {
          obj_set(patch, path, value.trim());
        }
      }
    }
    return patch;
  }

  getMissingRecommendedFields(form: FormGroup) {
    const missingFields = [];
    for (let key in form.value) {
      const value = form.value[key];
      const field = this.fields.find(f => f.id == key);

      if (field?.input?.array && !value?.length && field?.input?.warning) {
        missingFields.push(field.input.label);
        continue;
      }
      if (!value && field?.input?.warning) {
        missingFields.push(field.input.label);
      }
    }
    return missingFields;
  }

  updateForm(itemType: string, collection: IDataCollection, reset: boolean, callback?: (form: FormGroup) => any) {
    itemType = (itemType || this.getItemDefaultType(collection)).replace(/\s/gi, '_');

    // Map custom fields...
    let customFields: IDataCollectionCustomField[] = [];
    if (this.canShowCustomFields()) {
      customFields = collection.custom_fields || [];
    };

    // Map collection fields...
    const schema = collection.custom_type_schema || default_type_schema;
    const fields = schema.types[itemType].fields.map((name: string) => {
      return Object.assign({ id: name }, schema.field_defs[name]);
    });
    this.fields = fields;
    this.customFields = customFields;

    // Map custom fields to sections...
    // If field doesn't have an associated section, use "general" name for a section just for display
    this.customFieldsBySection = customFields.reduce((acc, currentValue) => {
      (acc[currentValue.section || 'general'] = acc[currentValue.section || 'general'] || []).push(currentValue);
      return acc;
    }, {});

    if (Object.keys(this.customFieldsBySection).length === 1) {
      this.expandedCustomFieldSections = [Object.keys(this.customFieldsBySection)[0]];
    }

    this.form = this.createFormGroup(fields, customFields, reset);
    if (callback) {
      callback(this.form);
    }
  }

  canShowCustomFields(): boolean {
    return this.collection?.custom_fields?.length > 0;
  }

  onFormChange() {
    if (!this.collection) return;
    const itemTypeKey = this.getItemTypeKey(this.collection);
    const newItemType = this.form.value['type'];
    if (newItemType !== this.item[itemTypeKey]) {
      this.item[itemTypeKey] = newItemType;
      this.updateForm(newItemType, this.collection, false, form => {
        form.controls['type'].markAsDirty();
      });
    }
  }

  hasFormError(): boolean {
    return this.fields?.some(field => {
      const fieldId = field.id;
      const control = this.form?.controls[fieldId];
      return control.invalid;
    });
  }

  hasFormCustomError(section?: string): boolean {
    return this.customFields?.some(field => {
      const fieldId = field.field;
      const control = this.form?.controls[fieldId];
      return control.invalid && section === field.section;
    });
  }

  getItemTypeKey(collection: IDataCollection): string {
    return (collection.custom_type_schema||default_type_schema).field_defs['type'].readcube;
  }

  getItemDefaultType(collection: IDataCollection): string {
    return (collection.custom_type_schema||default_type_schema).field_defs['type'].input.default;
  }

  onDateChange(fieldName: string, date: NgbDateStruct) {
    const str = [date.year, date.month, date.day].join('-');
    this.form.controls[fieldName].setValue(str);
    this.form.controls[fieldName].markAsDirty();
  }

  filterGroupValues(values: any[], groupId: string): any[] {
    return values.filter(opt => opt.group === groupId)
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  onSubmitClick(): void {
    const fields = this.getMissingRecommendedFields(this.form);
    if (fields?.length) {
      this.sidepanel.promptMisingRecommendedFields(fields).then(confirmed => {
        if (confirmed) {
          this.submit();
        }
      });
    } else {
      this.submit();
    }
  };

  private submit(): Promise<any> {
    this.saving$.next(true);
    const collectionId = this.item.collection_id,
      itemId = this.item.id;
    const patch = this.createItemPatch(this.form);
    if (!patch || is_empty(patch)) {
      return Promise.resolve(this.item);
    }
    return this.updateItem(collectionId, itemId, patch).then(() => {
      this.form.markAsPristine();

      this.router.navigate([{
        outlets: {
          'sidepanel': ['details'],
          'bottompanel': null
        }
      }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge'
      });
    }).then(() => {
      this.sidepanel.setActive('details');
    }).finally(() => {
      this.saving$.next(false);
    });
  }

  onResolverTextSelected(msg: ISelectionMessage) {
    const fieldMap = {
      'Title': { field: '_title' },
      'Author': { field: '_author', concat: true },
      'Journal': { field: '_journal' },
      'Year': { field: '_date' },
      'ISBN': { field: '_isbn' },
      'DOI': { field: '_doi' },
      'PMID': { field: '_pmid' }
    };
    const rule = fieldMap[msg.data.type];
    if (!rule) {
      return;
    }
    let value = msg.data.text.trim();
    for (let key in this.form.controls) {
      if (key.endsWith(rule.field)) {
        const ctrl = this.form.controls[key];
        if (rule.concat) {
          value = ctrl.value.concat([value]);
        }
        ctrl.setValue(value);
        ctrl.markAsDirty();
        return;
      }
    }
  }

  openConfirmSave(collectionId: string, itemId: string, patch: any) {
    if (!this.form.valid) {
      return;
    }
    this.shell.openConfirm<IDataItem>({
      title: CONFIRM_SAVE_MODAL_TITLE,
      message: CONFIRM_SAVE_MODAL_TEXT,
      confirmButtonText: 'Save',
      cancelButtonText: 'Discard',
      progressText: 'Saving...'
    }, () => {
      if (!patch || is_empty(patch)) {
        return Promise.resolve(this.item);
      }
      return this.updateItem(collectionId, itemId, patch);
    });
  }

  updateItem(collectionId, itemId, patch) {
    Object.assign(patch, {
      resolve: true,
      skip_resolve: false,
      merge: false,
      skip_merge: true,
    });
    return this.dataItem.update(collectionId, itemId, patch);
  }

  toggleCustomFieldsSearch() {
    this.customFieldsSearchVisible = !this.customFieldsSearchVisible;
    this.customFieldSearchTerm = '';

    if (this.customFieldsSearchVisible) {
      setTimeout(() => {
        this.customFieldsSearchInput.nativeElement.focus();
      });
    }
  }

  updateCustomFieldsFilter(term: string) {
    if (!term) {
      this.expandedCustomFieldSections = [];
      this.accordion.collapseAll();
      return;
    }

    this.expandedCustomFieldSections = [];
    for (let key in this.customFieldsBySection) {
      for (let i = 0; i < this.customFieldsBySection[key].length; i++) {
        if (this.expandedCustomFieldSections.includes(key))
          continue;
        if (this.customFieldsBySection[key][i].display?.toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
          this.expandedCustomFieldSections.push(key);
        }
      }
    }
  }

  isCustomFieldHidden(field: IDataCollectionCustomField) {
    if (!this.customFieldSearchTerm)
      return false;
    return !field.display?.toLocaleLowerCase().includes(this.customFieldSearchTerm.toLocaleLowerCase());
  }

  searchCustomFieldLookups = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      mergeMap(term => {
        if (!term.length || term.length < 3) {
          return of(this.customFieldLookups);
        }
        term = term.toLowerCase();
        return of(this.customFieldLookups.filter(lookup => lookup.toLowerCase().includes(term)));
      }));
  };

  searchExistingTags$ = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    mergeMap(term => this.dataItem.tags(this.item.collection_id).pipe(
      map(tags => tags.filter(tag => this.item.user_data.tags.indexOf(tag.id) === -1)),
      map(tags => tags.filter(tag => tag.id.toLowerCase().includes(term.toLowerCase()))),
      map(tags => tags.slice(0, 5)),
      map(tags => tags.sort()),
      map(tags => tags.map(tag => strtrunc(tag.id, 50, false)))
    )));
}
