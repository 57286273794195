import { Injectable } from '@angular/core';
import { DataStylesApiService, IDataStyle, IDataStylesInfo } from './data-styles-api.service';

import CitationExportPanel from '@readcube/citation-export-panel';

@Injectable()
export class DataStylesService {
  public lastUsedStyle: string;

  constructor(
    private api: DataStylesApiService
  ) { }

  public async getMergedStyles(): Promise<IDataStyle[]> {
    return this.api.getMergedStyles();
  }

  public async getStyleInfo(): Promise<IDataStylesInfo> {
    return this.api.getStyleInfo();
  }

  public async preloadLastUsedStyle(style?: IDataStyle, userId?: string) {
    if (style) {
      return this.api.getStyle(style).then(xml => this.lastUsedStyle = xml);
    }
    const lastUsedStyleId = CitationExportPanel.GetLastUsedId({ key: userId });
    if (!lastUsedStyleId) {
      return;
    }
    return Promise.all([
      this.getMergedStyles(),
      this.getStyleInfo()
    ]).then(async ([mergedStyles, stylesInfo]) => {
      const styles = mergedStyles.concat(stylesInfo?.custom_styles || []);
      const lastUsedStyle = styles.find(style => style.id === lastUsedStyleId);
      return this.api.getStyle(lastUsedStyle).then(xml => this.lastUsedStyle = xml);
    });
  }
}
