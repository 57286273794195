<ul class="list-group">
  <li class="list-group-item list-group-item-action d-flex justify-content-start align-items-center"
    *ngFor="let f of files; let i = index;">
    <div class="">
      <fa-icon [icon]="['fal', getIconClass(f.type)]" size="2x"></fa-icon>
    </div>

    <small class="ms-3 me-3 w-100">{{f.name}}</small>

    <button type="button" class="btn btn-sm btn-link float-end remove-button"
      (click)="removeFile(i)" *ngIf="files.length > 1">
      <fa-icon [icon]="['fas', 'remove']"></fa-icon>
    </button>
  </li>
</ul>
