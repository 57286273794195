import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface IBannerError {
  title: string;
  message: string | string[];
}

@Component({
  selector: 'rcp-importer-error-banner',
  templateUrl: './importer-error-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImporterErrorBannerComponent {
  @Input()
  error: IBannerError;
}
