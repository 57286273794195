import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { IDataResult } from '../../app-data-result.model';
import { environment } from 'environment';

export interface IDataMention {
  doi: string;
  title: string;
  journal: string;
  year: number;
  authors: IDataMentionAuthor[];
  mentions?: IDataMentionDetail[];
}

export interface IDataMentionAuthor {
  name: string;
}

export interface IDataMentionDetail {
  text: string;
  classification: IMentionClassification
}

export interface IMentionClassification {
  type: string;
  probability: number;
}
export interface IDataMentionQueryParams {
  doi: string;
  refinements?: string[];
  size?: number;
  classify?: boolean;
}

@Injectable()
export class DataMentionApiService {
  private scrollId?: string;

  constructor(
    private http: HttpClient
  ) { }

  public query(params: IDataMentionQueryParams, invalidateSession = false): Observable<IDataResult<IDataMention>> {
    const endpointUrl = `${environment.baseUrls.services}/articles/mentions`;
    if (invalidateSession) {
      this.scrollId = null;
    }
    const httpOptions = {
      params: this.getHttpParams(params, this.scrollId)
    };
    return this.http.get<IDataResult<IDataMention>>(endpointUrl, httpOptions).pipe(
      tap(body => this.scrollId = body.scroll_id),
      map(body => this.createResult(body))
    );
  }

  private createResult(data: any): IDataResult<IDataMention> {
    return {
      refinements: data['refinements'] || {},
      rows: (data['results'] || []).map(d => this.mapMentionData(d)),
      totalHuman: data['total_human'] || 'No mentions',
      total: data['total'] || 0
    };
  }

  private mapMentionData(data: any): IDataMention {
    return {
      doi: data['doi'] || '',
      title: data['title'] || '',
      journal: data['journal'] || '',
      year: data['year'] || null,
      mentions: (data['mentions'] || []).map(d => this.mapMentionDetails(d)),
      authors: data['authors'] || []
    };
  }

  private mapMentionDetails(data: any): IDataMentionDetail {
    const classification: IMentionClassification = data['classification'] || null;

    if (classification?.probability)
      classification.probability = Math.round(classification.probability * 100);

    return {
      text: data['text'] || '',
      classification
    };
  }

  private getHttpParams(params: IDataMentionQueryParams, scrollId?: string): HttpParams {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('doi', params.doi);
    httpParams = httpParams.set('classify', true);
    httpParams = httpParams.set('size', (params.size || 50).toString());
    if (params.refinements) {
      params.refinements.forEach(val => {
        httpParams = httpParams.append('refinements[]', val);
      });
    }
    if (scrollId) {
      httpParams = httpParams.set('scroll_id', scrollId);
    }
    return httpParams;
  }
}
