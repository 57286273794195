import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';
import { ReadCubeDragAndDropModule } from '@readcube/rcp-drag-and-drop';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppCommonModule } from '../common';
import { AppUserMenuModule } from '../user-menu';
import { AppImporterModule } from '../importer';
import { AppLibraryNavigationRoutingModule } from './library-navigation-routing.module';

import { LibraryNavigationCollectionComponent } from './components/library-navigation-collection.component';
import { LibraryNavigationCollectionSharedComponent } from './components/library-navigation-collection-shared.component';
import { LibraryNavigationListComponent } from './components/library-navigation-list.component';
import { LibraryNavigationTagsComponent } from './components/library-navigation-tags.component';
import { LibraryNavigationTagComponent } from './components/library-navigation-tag.component';
import { LibraryNavigationSmartListComponent } from './components/library-navigation-smartlist.component';
import { LibraryNavigationService } from './services/library-navigation.service';
import { LibraryNavigationComponent } from './components/library-navigation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
    ReadCubeDragAndDropModule,
    ReadCubeSvgIconsModule,
    AppImporterModule,
    AppCommonModule,
    AppUserMenuModule,
    AppLibraryNavigationRoutingModule,
  ],
  declarations: [
    LibraryNavigationComponent,
    LibraryNavigationCollectionComponent,
    LibraryNavigationCollectionSharedComponent,
    LibraryNavigationListComponent,
    LibraryNavigationSmartListComponent,
    LibraryNavigationTagsComponent,
    LibraryNavigationTagComponent,
  ],
  exports: [

  ],
  providers: [
    LibraryNavigationService
  ]
})
export class AppLibraryNavigationModule { }
