import { Injectable } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { scan, map, filter, mergeMap, startWith, tap } from 'rxjs/operators';

import {
  IDataCollection,
  IDataCollectionEditable,
  DataCollectionApiService,
  IDataCollectionEditableRequest,
  IDataCollectionLeaveRequest,
  IDataCollectionNotifications,
  IDataCollectionQueryParams
} from './data-collection-api.service';

@Injectable()
export class DataCollectionService {
  protected push$ = new Subject<IDataCollection[]>();

  constructor(
    public api: DataCollectionApiService
  ) { }

  create(collection: Partial<IDataCollection>): Promise<IDataCollection> {
    return this.api.post(collection).then(collection => this.pushDownstream([collection])[0]);
  }

  query(params?: IDataCollectionQueryParams): Observable<IDataCollection[]> {
    const query$ = this.api.query(params);
    const notify$ = this.api.listen().pipe(mergeMap(() => query$));
    return merge(query$, notify$).pipe(
      mergeMap(result => this.push$.pipe(
        startWith(result.rows),
        scan((acc, val) => this.accumulator(acc, val))
      ))
    );
  }

  queryWebonly(): Observable<IDataCollection[]> {
    return this.api.queryWebonly();
  }

  get(collectionId: string): Observable<IDataCollection> {
    const get$ = this.api.get(collectionId);
    const notify$ = this.api.listen(collectionId).pipe(mergeMap(() => get$));
    const push$ = this.push$.pipe(
      map(collections => collections.filter(c => c.id === collectionId)),
      filter(collections => collections.length > 0),
      map(collections => collections[0])
    );
    return merge(get$, notify$).pipe(
      mergeMap(collection => push$.pipe(startWith(collection)))
    );
  }

  getEditable(collectionId): Observable<IDataCollectionEditable> {
    return this.api.getEditable(collectionId);
  }

  update(collectionId: string, body: Partial<IDataCollectionEditableRequest>): Promise<IDataCollection> {
    return this.api.patch(collectionId, body)
      .then(collection => this.pushDownstream([collection])[0]);
  }

  delete(collectionId: string): Promise<IDataCollection> {
    return this.api.delete(collectionId)
      .then(collection => this.pushDownstream([collection])[0]);
  }

  archive(collectionId: string): Promise<IDataCollection> {
    return this.api.archive(collectionId)
      .then(collection => this.pushDownstream([collection])[0]);
  }

  restore(collectionId: string): Promise<IDataCollection> {
    return this.api.restore(collectionId)
      .then(collection => this.pushDownstream([collection])[0]);
  }

  leave(collectionId: string, body: IDataCollectionLeaveRequest): Promise<IDataCollection> {
    return this.api.leave(collectionId, body)
      .then(collection => this.pushDownstream([collection])[0]);
  }

  share(collectionId: string): Observable<IDataCollection> {
    return this.api.share(collectionId).pipe(
      tap(collection => this.pushDownstream([collection])[0])
    );
  }

  stopShare(collectionId: string): Observable<IDataCollection> {
    return this.api.stopShare(collectionId).pipe(
      tap(collection => this.pushDownstream([collection])[0])
    );
  }

  getNotifications(collectionId: string, listId: string): Observable<IDataCollectionNotifications> {
    return this.api.getNotifications(collectionId, listId);
  }

  setNotifications(collectionId: string, body: IDataCollectionNotifications): Observable<IDataCollectionNotifications> {
    return this.api.setNotifications(collectionId, body);
  }

  exportPDFs(collectionId: string, listId: string, template: string): Observable<{ success: boolean; }> {
    return this.api.exportPDFs(collectionId, listId, template);
  }

  pushDownstream(collections: IDataCollection[]): IDataCollection[] {
    this.push$.next(collections);
    return collections;
  }

  protected accumulator(acc: IDataCollection[], val: IDataCollection[]): IDataCollection[] {
    const cmp = (a: IDataCollection, b: IDataCollection) => a.id === b.id;
    return acc.map(a => val.find(b => cmp(a, b)) || a)
      .concat(val.filter(a => !acc.find(b => cmp(a, b))))
      .filter(a => a.status != 'deleted');
  }
}
