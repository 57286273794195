import { ElementRef, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CloseContextMenuEvent } from '@readcube/ngx-contextmenu';
import { ReplaySubject, Subject } from 'rxjs';
import { detect } from 'detect-browser';
import * as clipboard from 'clipboard-polyfill';

import {
  CommonDialogAlertComponent,
  CommonDialogConfirmComponent,
  CommonDialogProgressComponent,
  CommonDialogPromptComponent,
  ModalSubscriptionComponent,
} from './common';

import { AppThemeService } from './app-theme.service';

export interface IConfirmDialogParams {
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  progressText?: string;
  showCancel?: boolean;
  checkboxLabel?: string;
  displayList?: string[];
}

export interface IPromptDialogParams {
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  initialValue?: string;
}

export interface IShowConfirmResponse {
  confirmed: boolean;
  checked: boolean;
}

export interface IShowPromptResponse {
  confirmed: boolean;
  value: string;
}

export interface IShowProgressResponse {
  title: string;
  stoppable: boolean;
  message$: Subject<string>;
  progress$: Subject<number | boolean>;
  done$: Subject<boolean>;
  stop$: Subject<void>;
  close: () => void;
}

export interface IConfirmDialogHelper {
  setPercent(percent: number): void;
}

export interface IShowErrorParams {
  title: string;
  message: string;
}

@Injectable()
export class AppShellService {
  browser = detect();
  online$ = new ReplaySubject<boolean>(1);

  constructor(
    public modal: NgbModal,
    public theme: AppThemeService
  ) {
    this.theme.watchSystemTheme();

    this.online$.next(navigator.onLine);

    window.addEventListener('online', () => {
      this.online$.next(true);
    });
    window.addEventListener('offline', () => {
      this.online$.next(false);
    });
  }

  openURL(url: string): void {
    if (url.startsWith('mailto:')) {
      window.location.href = url;
    } else if (this.browser?.name == 'safari') {
      window.open(url, '_blank', 'popup,noopener');
    } else {
      window.open(url, '_blank');
    }
  }

  copyToClipboard(params: { text: string, html?: string }) {
    const item = new clipboard.ClipboardItem({
      'text/plain': new Blob([params.text||''], { type: 'text/plain' }),
      'text/html': new Blob([params.html||''], { type: 'text/html' }),
    });
    clipboard.write([item]).catch(() => {
      this.showError({
        title: 'Error',
        message: 'Clipboard copy failed.'
      });
    });
  }

  openConfirm<T = any>(params: IConfirmDialogParams, action?: (helper: IConfirmDialogHelper) => any): Promise<T> {
    return this.showConfirm(params).then(response => {
      if (response?.confirmed && typeof action === 'function') {
        const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static' });
        const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
        const result = action.call(this, {
          setPercent: (percent: number) => instance.progress$.next(percent)
        });
        if (result instanceof Promise) {
          return result.finally(() => {
            modalRef.close();
          });
        } else {
          modalRef.close();
        }
      }
      return response?.confirmed;
    });
  }

  showConfirm(params: IConfirmDialogParams): Promise<IShowConfirmResponse> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        toggleModalDisplay(false);
        const modalRef = this.modal.open(CommonDialogConfirmComponent, { backdrop: 'static' });
        const instance = <CommonDialogConfirmComponent>modalRef.componentInstance;
        instance.title = params.title;
        instance.message = params.message;
        if (params.confirmButtonText) {
          instance.confirmButtonText = params.confirmButtonText;
        }
        if (params.cancelButtonText) {
          instance.cancelButtonText = params.cancelButtonText;
        }
        if (params.showCancel !== undefined && params.showCancel !== null) {
          instance.showCancel = params.showCancel;
        }
        if (params.displayList) {
          instance.displayList = params.displayList;
        }
        instance.checkboxLabel = params.checkboxLabel;
        modalRef.result.then(resolve, reject).then(() => {
          toggleModalDisplay(true);
        });
      });
    });
  }

  showPrompt(params: IPromptDialogParams): Promise<IShowPromptResponse> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        toggleModalDisplay(false);
        const modalRef = this.modal.open(CommonDialogPromptComponent, { backdrop: 'static' });
        const instance = <CommonDialogPromptComponent>modalRef.componentInstance;
        instance.title = params.title;
        instance.message = params.message;
        if (params.confirmButtonText) {
          instance.confirmButtonText = params.confirmButtonText;
        }
        if (params.cancelButtonText) {
          instance.cancelButtonText = params.cancelButtonText;
        }
        instance.value = params.initialValue || '';
        modalRef.result.then(resolve, reject).then(() => {
          toggleModalDisplay(true);
        });
      });
    });
  }

  showError(params: IShowErrorParams): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        toggleModalDisplay(false);
        const modalRef = this.modal.open(CommonDialogAlertComponent, { backdrop: 'static' });
        const instance = <CommonDialogAlertComponent>modalRef.componentInstance;
        instance.title = params.title;
        instance.message = params.message;
        modalRef.result.then(resolve, reject).then(() => {
          toggleModalDisplay(true);
        });
      });
    });
  }

  showProgress(): Promise<IShowProgressResponse> {
    const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
    const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
    return Promise.resolve(instance);
  }

  showSubscription() {
    if (this.modal.hasOpenModals()) {
      this.modal.dismissAll();
    }
    this.modal.open(ModalSubscriptionComponent, { backdrop: 'static', keyboard: false });
  }
}






// ...!?...
export function fixBibliographyEntry(html: string): { html: string, text: string } {
  const el = document.createElement('span');
  el.innerHTML = html;
  let div: HTMLDivElement;
  const stack = Array.from(el.getElementsByTagName('div'));
  while (div = stack.pop()) {
    if (div.className === 'csl-entry') {
      div.insertAdjacentHTML('afterend', '<br>')
    } else {
      const span = document.createElement('span');
      span.innerHTML = div.innerHTML;
      span.className = div.className;
      div.parentNode.replaceChild(span, div);
    }
  }
  return { html: el.innerHTML, text: el.innerText };
}

export function shouldRemoveHighlight(element: ElementRef, event: CloseContextMenuEvent) {
  return (event.eventType === 'execute' ||
    (event.eventType === 'cancel' && !(<any>element.nativeElement).contains(event.event?.target)));
}

function toggleModalDisplay(isVisible: boolean) {
  let elements = document.getElementsByClassName('modal-content');
  for (let i = 0; i < elements.length; i++) {
    (<HTMLElement>elements[i]).style.display = isVisible ? 'block' : 'none';
  }
}