import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, ReplaySubject, Subject, debounceTime, distinctUntilChanged, filter, map, merge, mergeMap, shareReplay } from 'rxjs';

import { SharedService } from '../../common/services/shared.service';
import { AppShellService } from '../../app-shell.service';
import { ListTreeService } from '../../common/services/list-tree.service';
import { DataCollectionService, DataListService, IDataCollection, IDataList } from '../../library-data';
import { IBulkItemImportOptions } from '..';

import { environment } from 'environment';

export interface IModalCopyItemsState {
  confirmed: boolean;
  importOptions: IBulkItemImportOptions;
  fromCollectionId?: string;
  targetCollectionId?: string;
  targetListId?: string;
  duplicatesSublist?: string;
}

@Component({
  templateUrl: './modal-copy-items.component.html'
})
export class ModalCopyItemsComponent {

  confirmed: boolean;
  hasDestinationSelect: boolean;

  fromCollectionId: string;
  targetCollectionId: string;
  importOptions: IBulkItemImportOptions = {
    includeFiles: true,
    copyUserData: true,
    excludeFilesWithoutCopyright: true,
    mergeDuplicates: true
  };

  hasDuplicatesSublist: boolean = false;
  duplicatesSublist: string = null;

  collections: IDataCollection[];
  selectedList?: IDataList;

  collections$: Observable<IDataCollection[]>;
  lists$: Observable<IDataList[]>;


  // List select input --->>
  @ViewChild('listSelectTypeahead', { static: false })
  listSelectTypeahead: NgbTypeahead;
  listFocus$ = new Subject<string>();
	listClick$ = new Subject<string>();
  selectListFormatter = (list: IDataList) => list.name;
  selectListSearch: OperatorFunction<string, readonly IDataList[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.listClick$.pipe(filter(() => !this.listSelectTypeahead.isPopupOpen()));
		const inputFocus$ = this.listFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      mergeMap(term => this.lists$.pipe(
        map(lists => lists.filter(v => !term || v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )),
    );
  };
  // <<--- List select input


  collectionIdChange: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(
    public activeModal: NgbActiveModal,
    public dataCollection: DataCollectionService,
    public dataList: DataListService,
    public listNode: ListTreeService,
    public shared: SharedService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    if (!this.hasDestinationSelect)
      return;

    this.collections$ = this.dataCollection.query().pipe(
      map(arr => arr.filter(c => c.status === 'active' && c.user?.can_create_item))
    );
    this.lists$ = this.collectionIdChange.pipe(
      mergeMap(collection_id => this.dataList.query({ collection_id })),
      map(lists => this.listNode.fromLists(lists)),
      map(root => this.listNode.flatten(root, environment.maxListsDepth)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  onMergeDuplicatesToggle(mergeDuplicates: boolean) {
    if (!mergeDuplicates)
      this.hasDuplicatesSublist = false;
  }

  submit() {
    const state: IModalCopyItemsState = {
      confirmed: true,
      fromCollectionId: this.fromCollectionId,
      importOptions: this.importOptions,
      targetCollectionId: this.targetCollectionId,
      targetListId: this.selectedList?.id,
      duplicatesSublist: this.importOptions.mergeDuplicates ? this.duplicatesSublist : null
    };
    this.activeModal.close(state);
  }

  cancel() {
    this.activeModal.close({ confirmed: false });
  }
}
