import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AppShellService } from '../../app-shell.service';
import { DataSmartListService, DataItemService, IDataCollection, IDataSmartList } from '../../library-data';

@Component({
  templateUrl: './modal-edit-smartlist.component.html'
})
export class ModalEditSmartListComponent {
  @Input()
  collection: IDataCollection;

  @Input()
  list: Partial<IDataSmartList>;

  disabled$: Observable<boolean>;
  error$: Observable<string>;
  disabledSubject = new Subject<boolean>();
  errorSubject = new Subject<string>();

  protected original: IDataSmartList;

  constructor(
    public dataSmartList: DataSmartListService,
    public activeModal: NgbActiveModal,
    public shell: AppShellService,
    public router: Router,
    public dataItem: DataItemService
  ) {
    this.disabled$ = this.disabledSubject.pipe(startWith(false));
    this.error$ = this.errorSubject.asObservable();
  }

  ngOnInit() {
    this.original = Object.assign(<IDataSmartList>{}, this.list);
  }

  submit() {
    this.errorSubject.next('');
    this.disabledSubject.next(true);
    let promise: Promise<IDataSmartList>;

    if (!this.list.id) {
      promise = this.dataSmartList.create(this.list);
    } else {
      const patch = <Partial<IDataSmartList>>{
        id: this.list.id,
        collection_id: this.list.collection_id,
        sortField: this.list.sortField,
        sortOrder: this.list.sortOrder
      };
      if (this.original.name !== this.list.name) {
        patch.name = this.list.name;
      }
      if (this.original.query !== this.list.query) {
        patch.query = this.list.query;
      }

      promise = this.dataSmartList.update(patch);
    }
    promise.then(list => {
      this.activeModal.close(list);

      const extras = {
        queryParams: { query: list.query }
      };

      if (list.sortField && list.sortOrder) {
        extras.queryParams['sort'] = list.sortField;
        extras.queryParams['order'] = list.sortOrder;
      }

      this.router.navigate(['/library', list.collection_id, 'smartlist', list.id], extras);
    }).catch(response => {
      this.errorSubject.next(response?.error?.error);
      this.disabledSubject.next(false);
    });
  }

  close() {
    this.activeModal.close();
  }
}
