import { ReplaySubject } from 'rxjs';

export type ThemeName = 'dark' | 'light';

export class AppThemeService {
  theme$ = new ReplaySubject<ThemeName>(1);
  systemTheme = this.getSystemTheme();

  getSystemTheme(): ThemeName {
    if ('matchMedia' in window) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
      }
    }
    return 'light';
  }

  getPreferedTheme(): ThemeName {
    return localStorage.getItem('theme') as ThemeName || this.systemTheme;
  }

  setPreferedTheme(name: ThemeName | null) {
    if (name) {
      localStorage.setItem('theme', name);
    } else {
      localStorage.removeItem('theme');
    }
    this.loadPreferedTheme();
  }

  watchSystemTheme() {
    if ('matchMedia' in window) {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handler = (event: MediaQueryListEvent) => {
        this.systemTheme = event.matches ? 'dark' : 'light';
        this.loadPreferedTheme();
      };
      if ('addEventListener' in mediaQuery) {
        mediaQuery.addEventListener('change', handler);
      } else if ('addListener' in mediaQuery) {
        (<any>mediaQuery).addListener(handler);
      }
    }
    this.loadPreferedTheme();
  }

  protected loadPreferedTheme() {
    let theme = this.getPreferedTheme();
    if ('loadTheme' in window && theme) {
      (<any>window).loadTheme(theme);
    }
    this.theme$.next(theme);
  }
}