<ng-container *ngIf="formGroup; else loadingTemplate">

  <form [formGroup]="formGroup" (change)="updateOptions()">
    <div class="row mt-2">

      <div class="col-sm-5"><strong>CSV Column</strong></div>
      <div class="col-sm-6"><strong>Field in Papers</strong></div>
      <div class="col-sm-1"></div>
      <!-- <div class="col-sm-4"><strong>Sample value</strong></div> -->
    </div>

    <div class="papers-scrollbar field-mappings-form" formArrayName="csvMapping">
      <div class="row" *ngFor="let formGroupControl of formGroup.controls['csvMapping'].controls; let i = index;" [formGroupName]="i">
        <ng-container *ngIf="formGroup.controls['csvMapping'].controls[i] as subGroup">

          <div class="col-sm-5">
            <span class="form-check form-switch mt-1 mb-2">
              <input class="form-check-input" type="checkbox" id="mapping-enabled-{{i}}"
                formControlName="enabled" (change)="updateAllMappingValidators()">
              <label for="mapping-enabled-{{i}}">{{subGroup.controls['key'].value}}</label>
            </span>
            <div class="form-text text-danger" *ngIf="formGroupControl.errors?.duplicate_key">Duplicate column name.</div>
          </div>

          <div class="col-sm-6">
            <div class="form-group" *ngIf="subGroup.controls['enabled'].value">

              <ng-container *ngIf="!subGroup.controls['custom'].value">
                <rcp-typeahead
                  class="form-control"
                  formControlName="papersField"
                  (selectChange)="updateOptions()"
                  [options]="csvInfo.papersFields"
                  [editable]="false"
                  [limit]="10">
                </rcp-typeahead>
              </ng-container>

              <ng-container *ngIf="subGroup.controls['custom'].value">
                <rcp-typeahead
                  class="form-control"
                  formControlName="customField"
                  (selectChange)="updateOptions()"
                  [options]="csvInfo.customFields"
                  [editable]="false"
                  [limit]="10">
                </rcp-typeahead>
              </ng-container>

              <!--
              <select class="form-select mb-2" formControlName="papersField" *ngIf="!subGroup.controls['custom'].value">
                <option [value]="f" *ngFor="let f of csvInfo.papersFields">{{f}}</option>
              </select>

              <select class="form-select mb-2" formControlName="customField" *ngIf="subGroup.controls['custom'].value">
                <option [value]="f" *ngFor="let f of csvInfo.customFields">{{f}}</option>
              </select>
              -->

              <div class="form-check form-switch mb-2" *ngIf="csvInfo.customFields?.length">
                <input type="checkbox" class="form-check-input" formControlName="custom">
                <label class="form-check-label">Custom field</label>
              </div>
            </div>

            <div class="form-text text-danger" *ngIf="formGroupControl.errors?.required">Please select field.</div>
            <div class="form-text text-danger" *ngIf="formGroupControl.errors?.duplicate_field">Already selected in another field.</div>
          </div>

          <div class="col-sm-1">
            <ng-container *ngIf="subGroup.controls['enabled'].value && dataPreview(i) as preview">
              <button type="button" class="btn btn-sm btn-link pe-0 ps-0 ms-1 mt-1" ngbTooltip="Sample value: {{preview}}">
                <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
              </button>
            </ng-container>
          </div>
          <!-- <div class="col-sm-4">
            <ng-container class="form-group" *ngIf="subGroup.controls['enabled'].value && dataPreview(i) as preview">
              <span class="card-body fst-italic">{{preview}}</span>
            </ng-container>
          </div> -->

        </ng-container>
      </div>
    </div>

    <hr>

    <div class="form-check form-switch mt-2">
      <input class="form-check-input"
        type="checkbox"
        id="merge-duplicates-checkbox"
        formControlName="mergeDuplicates">
      <label class="form-check-label" for="merge-duplicates-checkbox">Merge Duplicates</label>
      &nbsp;<a href rcpPreventDefault (click)="importer.openDuplicateMergingInfo()">Learn more</a>
    </div>

    <div class="form-check form-switch mt-2">
      <input class="form-check-input"
        type="checkbox"
        id="resolve-metadata-checkbox"
        formControlName="resolveMetadata">
      <label class="form-check-label" for="resolve-metadata-checkbox">Resolve Metadata</label>
    </div>

  </form>

</ng-container>



<ng-template #loadingTemplate>
  <common-loader-circles class="loader"></common-loader-circles>
</ng-template>
