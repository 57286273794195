import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  DataReviewApiService,
  IDataReviewAddToProject,
  IDataReviewLinkToProject,
  IReviewProject,
  IReviewProjectLink
} from './data-review-api.service';

@Injectable()
export class DataReviewService {
  constructor(
    public api: DataReviewApiService
  ) { }

  getProjects(): Observable<IReviewProject[]> {
    return this.api.getProjects();
  }

  addToProject(body: IDataReviewAddToProject): Observable<{ success: boolean; }> {
    return this.api.addToProject(body);
  }

  getProjectLink(collectionId: string, listId?: string): Observable<IReviewProjectLink> {
    return this.api.getProjectLink(collectionId, listId);
  }

  linkToProject(body: IDataReviewLinkToProject): Observable<IReviewProjectLink> {
    return this.api.linkToProject(body);
  }
}
