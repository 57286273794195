import { Injectable } from "@angular/core";

import { QueryOperator } from "src/app/query-builder";
import { SearchNavigationFiltersService } from "./search-navigation-filters.service";
import { BracketType } from "../components/search-navigation.component";
import { IFormValueChange, TCheckboxFilterArray, TFormGroupControl, TRangeFilterControl } from "../components/search-navigation-filters.component";
import { FilterGroupType, ICheckboxFilter, IDataFiltersResult } from "./data-filters-api.service";

@Injectable()
export class SearchNavigationQueryService {
  constructor(
    protected navigationFilters: SearchNavigationFiltersService
  ) {}

  splitContent(content: string, changedFilterName: string) {
    // split content by white space, remove leftover whitespaces and then check if the next element is an operator or a multi-word filter
    // if it is a multi-word filter, treat it as one element
    const operators = Object.values(QueryOperator);
    const splitContent = this.splitQueryPart(content).reduce((acc, val) => {
      // if current value is not an operator, and previous wasn't, it's a multi word filter
      if (!operators.includes(val as QueryOperator) && acc.length && !operators.includes(acc[acc.length - 1])) {
        acc[acc.length - 1] = `${acc[acc.length - 1]} ${val}`;
      } else {
        acc.push(val);
      }

      return acc;
    }, []);

    let matchingContentIndex = -1;
    splitContent.forEach((content, i) => {
      if (content === changedFilterName || (content === changedFilterName && i > 0 && splitContent[i - 1] === QueryOperator.Or)) {
        matchingContentIndex = i;
        return;
      }
    });

    // if first element is matching, remove operator behind him, otherwise remove operator before
    const operatorIndex = matchingContentIndex === 0 ? matchingContentIndex + 1 : matchingContentIndex - 1;
    const operator = splitContent[operatorIndex];

    return { splitContent, matchingContentIndex, operatorIndex, operator };
  }

  splitQueryPart(queryPart: string) {
    return (queryPart || '').trim().split(' ').filter((el) => el);
  }

  removeGroupString(trimmedBeginning: string, trimmedQuery: string, queryPart: string) {
    const splitTrimmed = trimmedQuery.split(queryPart);
    let groupString: string;

    // if there were values before it, remove the operator and the group
    if (trimmedBeginning) {
      groupString = ` ${QueryOperator.And} ${queryPart}`;
    } else if (!splitTrimmed[1]?.length) { // else if it's the last part of the query so just remove it
      groupString = queryPart;
    } else { // else it was the first value, remove the operator after it as well
      const operator = this.splitQueryPart(splitTrimmed[1])[0];

      groupString = `${queryPart} ${operator} `;
    }

    return trimmedQuery.replace(groupString, '');
  }

  getGroupContent(groupName: string, query: string) {
    if (!query) {
      return null;
    }

    const queryProperty = `${groupName}:`;
    let splitQuery = query.split(queryProperty);
    const [_, ...matchingQueries] = splitQuery;

    // if matchingQueries doesn't exist there's nothing for this group in query so skip this iteration
    if (!matchingQueries.length) {
      return null; 
    }

    let groupOperator;
    let matchingIndex = 0;
    const groupContent = matchingQueries.find((_, i) => {
      const contentBeforeMatchingQuery = this.splitQueryPart(splitQuery[i]);
      groupOperator = contentBeforeMatchingQuery[contentBeforeMatchingQuery.length - 1];

      if (groupOperator === QueryOperator.And || !contentBeforeMatchingQuery.length) {
        matchingIndex = i;

        return true;
      }

      return false;
    });

    // if there are matchingQueries but no groupContent it means the operator is wrong so we don't count it as a filter
    if (!groupContent && !groupOperator) {
      return null;
    }

    if (!groupContent && groupOperator) {
      return {
        openingBracketsIndex: -1,
        closingBracketsIndex: -1,
        content: null,
        groupOperator,
        matchingIndex: -1,
        bracketType: BracketType.None
      }
    }

    let bracketType = BracketType.None;
    const openingRoundBracketsIndex = groupContent.indexOf('('); // can be 0, 1 or -1 realistically
    const closingRoundBracketsIndex = groupContent.indexOf(')', openingRoundBracketsIndex);
    const openingSquareBracketsIndex = groupContent.indexOf('[');
    const closingSquareBracketsIndex = groupContent.indexOf(']', openingSquareBracketsIndex);

    let openingBracketsIndex = -1;
    let closingBracketsIndex = -1;

    if (
      (openingRoundBracketsIndex >= 0 && (openingRoundBracketsIndex < openingSquareBracketsIndex || openingSquareBracketsIndex === -1)) &&
      (closingRoundBracketsIndex >= 0 && (closingRoundBracketsIndex < closingSquareBracketsIndex || closingSquareBracketsIndex === -1))
    ) {
      bracketType = BracketType.Round;
      openingBracketsIndex = openingRoundBracketsIndex;
      closingBracketsIndex = closingRoundBracketsIndex;
    } else if (openingSquareBracketsIndex >= 0 && closingSquareBracketsIndex >= 0) {
      bracketType = BracketType.Square;
      openingBracketsIndex = openingSquareBracketsIndex;
      closingBracketsIndex = closingSquareBracketsIndex;
    }
    // there may not be any parenthesis
    const content = openingBracketsIndex !== -1 ?
      groupContent.substring(openingBracketsIndex + 1, closingBracketsIndex) : this.splitQueryPart(groupContent)[0];

    return {
      openingBracketsIndex,
      closingBracketsIndex,
      content,
      groupOperator,
      matchingIndex,
      bracketType
    };
  }

  getRangeContentData(rangeContent: string = '', [ defaultLow, defaultHigh ]) {
    const [ lowString, operator, highString ] = this.splitQueryPart(rangeContent);
    const contentLow = Number(lowString);
    const low = contentLow < defaultLow ? defaultLow : contentLow;
    const contentHigh = Number(highString);
    const high = contentHigh > defaultHigh ? defaultHigh : contentHigh;

    return {
      contentLow,
      contentHigh,
      low,
      high,
      operator
    };
  }

  mapQueryToFormData(query: string, values: IDataFiltersResult[], controls: TFormGroupControl[]) {
    values.map((group, i: number) => {
      const groupContent = this.getGroupContent(group.name, query);
      const groupControl = controls[i];

      // if groupContent doesn't exist there's no group in the query with the appropriate operator
      // if it's range revert to default values
      if (!groupContent) {
        if (group.type === FilterGroupType.Range) {
          (groupControl.controls.filters as TRangeFilterControl).patchValue(group.defaultFilters);
        }

        return; 
      }

      const { content, groupOperator, bracketType } = groupContent || {};

      // if operator before the group is not AND, uncheck all filters in that group
      if (groupOperator && groupOperator !== QueryOperator.And) {

        if (group.type === FilterGroupType.Range) {
          (groupControl.controls.filters as TRangeFilterControl).patchValue(group.defaultFilters);

          return;
        }

        const filterControl = (groupControl.controls.filters as TCheckboxFilterArray).controls;

        group.filters.map((filter, j: number) => {
          const { value } = filterControl[j].controls;
          
          if (filter.value) {
            value.patchValue(false);
          }
        });

        return;
      }

      // check for range groups
      if (group.type === FilterGroupType.Range) {
        // support for now only bracketType.Square
        if (bracketType !== BracketType.Square) {
          return;
        }

        const { contentLow, contentHigh, low, high, operator } = this.getRangeContentData(content, group.defaultFilters);

        // if the operator is wrong, return the full range as we're not filtering by range anymore 
        if (operator !== QueryOperator.To) {
          (groupControl.controls.filters as TRangeFilterControl).patchValue(group.defaultFilters);
        }

        // if one of the values changed and it is a number, if the operator is correct, update the values
        if (group.visible && !isNaN(contentLow) && !isNaN(contentHigh) && (contentLow !== group.filters[0] || contentHigh !== group.filters[1]) && operator === QueryOperator.To) {
          (groupControl.controls.filters as TRangeFilterControl).patchValue([low, high]);
        }

        return;
      }

      // check for checkbox groups
      // instead of splitting contentInsideParenthesis we check if it includes filter name in specific ways
      // reduces the chance of bugs if user decides to mess with the query and for example change operators
      group.filters.map(({ name, value }: Partial<ICheckboxFilter>, j: number) => {
        const { value: valueControl } = (groupControl.controls.filters as TCheckboxFilterArray).controls[j].controls;

        let { matchingContentIndex, operator } = this.splitContent(content, name);
        
        const contentExists = (content.trim() === name || content.startsWith(`${name} `))|| (operator === QueryOperator.Or && matchingContentIndex > 0);

        if (contentExists) {
          valueControl.patchValue(true);

          return;
        }

        // detect operator change and uncheck the filter
        if (operator && content.includes(`${operator} ${name}`) && value) {
          valueControl.patchValue(false);

          return;
        }

        // detect value change from filter to non-filter
        if (value && !contentExists) {
          valueControl.patchValue(false);

          return;
        }
      });
    });
  }

  mapFormDataToQuery({ oldValue, newValue }: IFormValueChange, query: string) {
    newValue.map((group: IDataFiltersResult, i) => {
      let activeFilterCount = 0;
      let changedFilter = null;

      const { closingBracketsIndex, openingBracketsIndex, content, matchingIndex, groupOperator } = this.getGroupContent(group.name, query) || {};
      const queryProperty = `${group.name}:`;
      const splitQuery = query.split(queryProperty);
      const [ beginning, ...rest ] = splitQuery;
      const trimmedBeginning = beginning.trim();
      const newBeginning = `${trimmedBeginning}${groupOperator ? ' ' : ''}`; // handle case of multiple whitespaces before the match
      let matchingQuery = rest[matchingIndex];
      // trim the start of the  matching query because user could use whitespaces i.e. species: (animal) or species:(animal)
      const trimmed = matchingQuery?.trimStart();
      const trimmedQuery = [ newBeginning, ...rest.map((el, i) => i === matchingIndex ? trimmed : el) ].join(queryProperty);

      if (group.type === FilterGroupType.Range) {
        const { contentLow, contentHigh } = this.getRangeContentData(content, group.defaultFilters);
        const [ defaultLow, defaultHigh ] = group.defaultFilters;

        // if there's nothing rendered or no diff, or the operator is wrong, do nothing
        if (!group.filters || (contentLow === group.filters[0] && contentHigh === group.filters[1])) {
          return;
        }

        // if it uses default values, remove it from the query as searching for full range is same as searching without it...
        if (group.filters[0] === defaultLow && group.filters[1] === defaultHigh) {
          // if we decide to support alternate syntax, this needs to change
          const queryPart = `${group.name}:[${contentLow} TO ${contentHigh}]`;
          query = this.removeGroupString(trimmedBeginning, trimmedQuery, queryPart);
          return;
        }

        // bracketIndexes can be undefined if there's no query, and in that case  we need to add them as well
        const newContent = `${openingBracketsIndex >= 0 ? '' : '['}${group.filters[0]} TO ${group.filters[1]}${closingBracketsIndex >= 0 ? '' : ']'}`;
        // range was not in the query up until now, so append it
        if (!content) {
          query = query.concat(`${trimmedQuery.length ? ` ${QueryOperator.And} `: ''}${group.name}: ${newContent}`);

          return;
        }

        // else just update the value
        const beforeNewContent = `${matchingQuery.substring(0, openingBracketsIndex + 1)}`;
        // if there were no parenthesis, it was a single element and take its length instead as starting index
        const afterNewContent = `${matchingQuery.substring(closingBracketsIndex === -1 ? matchingQuery.length: closingBracketsIndex)}`;
        rest[matchingIndex] = `${beforeNewContent}${newContent}${afterNewContent}`;
        query = [ newBeginning, ...rest ].join(queryProperty);

        return;
      }
      
      group.filters.map((filter, j) => {
        if (filter.value) {
          activeFilterCount++;
        }

        const oldFilter = (oldValue[i].filters as ICheckboxFilter[]).find(({ name }) => name === filter.name);

        if (oldFilter && filter.value !== oldFilter.value) {
          changedFilter = filter;
        }
      });

      if (!changedFilter) {
        return;
      }

      const { splitContent, matchingContentIndex, operatorIndex, operator } = this.splitContent(content, changedFilter.name);

      // last active filter of a group was unchecked
      if (!activeFilterCount) {
        if (groupOperator !== QueryOperator.And && trimmedBeginning) {
          return;
        }

        const otherContent = splitContent.length > 1;

        // cover scenario of a single value without parenthesis being changed into a non filter value
        if (splitContent.length === 1 && splitContent[0] !== changedFilter.name) {
          return;
        }

        // even though this is last filter value, there might be other content in the group that isn't classified as a filter
        if (otherContent) {
          const newContent = splitContent.filter((_, i) => i !== matchingContentIndex && i !== operatorIndex).join(' ');
          const beforeNewContent = `${matchingQuery.substring(0, openingBracketsIndex + 1)}`;
          const afterNewContent = `${matchingQuery.substring(closingBracketsIndex)}`;
    
          rest[matchingIndex] = `${beforeNewContent}${newContent}${afterNewContent}`;
          query = [ newBeginning, ...rest ].join(queryProperty);

          return;
        }

        // assuming that there will be no whitespace when theres no parenthesis
        const queryPart =  trimmed?.startsWith('(') ? `${group.name}:(${changedFilter.name})` : `${group.name}:${changedFilter.name}`;

        query = this.removeGroupString(trimmedBeginning, trimmedQuery, queryPart);
        return;
      }

      // cover edge case where loading from the url changes filters, then we don't have to update the url
      // but avoid early returning if group exists but isn't a filter (has operator different than AND)
      // keep in mind there can be multiple whitespaces between operator and content
      if (changedFilter.value && (content?.trim() === changedFilter.name || content?.trim()?.startsWith(`${changedFilter.name} `) || (operator === QueryOperator.Or && matchingContentIndex > 0))) {
        return;
      }
      
      // first filter of a inactive group was checked
      if (activeFilterCount === 1 && changedFilter.value && !content) {
        query = query.concat(`${trimmedQuery.length ? ` ${QueryOperator.And} `: ''}${group.name}: (${changedFilter.name})`);
        return;
      }
      
      // existing filter was updated
      let newContent: string;

      // filter updated because of operator change and no need to touch the query
      if (groupOperator !== QueryOperator.And && trimmedBeginning) {
        return;
      }

      if (changedFilter.value) { // new filter added to the existing group group
        newContent = `${content} OR ${changedFilter.name}`;
        // if it was a single value without parenthesis, wrap the content into parenthesis
        if (openingBracketsIndex === -1 && closingBracketsIndex === -1) {
          newContent = `(${newContent})`;
        }
      } else { // filter removed from the group but at least 1 more still remains
        // filter got changed into non-filter value, url changed accordingly and is now calling this again, so nothing needs to be changed
        if (matchingContentIndex === -1) {
          return;
        }

        // check if operator changed, in that case no need to remove anything, as it is no longer considered a filter
        if (operator !== QueryOperator.Or && matchingContentIndex !== 0) {
          return;
        }

        newContent = splitContent.filter((_, i) => i !== matchingContentIndex && i !== operatorIndex).join(' ');
      }

      const beforeNewContent = `${matchingQuery.substring(0, openingBracketsIndex + 1)}`;
      // if there were no parenthesis, it was a single element and take its length instead as starting index
      const afterNewContent = `${matchingQuery.substring(closingBracketsIndex === -1 ? matchingQuery.length: closingBracketsIndex)}`;

      rest[matchingIndex] = `${beforeNewContent}${newContent}${afterNewContent}`;
      query = [ newBeginning, ...rest ].join(queryProperty);
    });

    return query;
  }
}