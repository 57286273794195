<div class="wrapper papers-scrollbar">

  <library-sidepanel-header
    [journal]="item?.article.journal"
    [year]="item?.article.year"
    [title]="item?.article.title"
    [chapter]="item?.article.chapter">
  </library-sidepanel-header>

  <div [hidden]="sharingHidden">
    <!-- Email -->
    <a href class="share-button email" #btnShareByEmail>
      <span class="icon">
        <fa-icon [icon]="['fas', 'envelope-square']" size="3x"></fa-icon>
      </span>
      <span class="text">Share by Email</span>
    </a>

    <!-- Facebook -->
    <a href class="share-button facebook" #btnShareOnFacebook>
      <span class="icon">
        <fa-icon [icon]="['fab', 'facebook-square']" size="3x"></fa-icon>
      </span>
      <span class="text">Share on Facebook</span>
    </a>

    <!-- Twitter -->
    <a href class="share-button twitter" #btnShareOnTwitter>
      <span class="icon">
        <fa-icon [icon]="['fab', 'twitter-square']" size="3x"></fa-icon>
      </span>
      <span class="text">Share on Twitter</span>
    </a>

    <!-- LinkedIn -->
    <a href class="share-button linkedin" #btnShareOnLinkedIn>
      <span class="icon">
        <fa-icon [icon]="['fab', 'linkedin']" size="3x"></fa-icon>
      </span>
      <span class="text">Share on LinkedIn</span>
    </a>
  </div>

</div>