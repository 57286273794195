<library-menu #itemsMenu
  [collectionId]="collectionId"
  [collection]="collection$ | async"
  [listId]="listId"
  [items]="highlighted?.length ? highlighted : selected"
  (onImportFiles)="onImportFiles($event)"
  (onItemCreated)="onItemCreated($event)"
  (onDeleted)="onDeleted($event)"
  (onEditDetails)="onEditDetails($event)"
  (onOpenResolver)="onOpenResolver($event)"
  (onMenuClose)="onMenuClose($event)">
</library-menu>

<common-sidepanel-layout [sidepanelOpen]="sidepanelOpen$ | async" (sidepanelClosed)="closeSidePanel()">

  <as-split direction="vertical" (dragStart)="onResizeStart($event)" (dragEnd)="onResizeEnd($event)" content>
    <as-split-area [size]="viewSizes[0]">
      <div class="wrapper">
        <library-header
          #libraryHeader
          class="header"
          [collection]="collection$ | async"
          [listId]="listId"
          [sort]="sort$ | async"
          [order]="order$ | async"
          [query]="query$ | async"
          [searchPlaceholder]="searchPlaceholder$ | async"
          [(viewName)]="viewName"
          (viewNameChange)="onViewNameChange($event)"
          (onItemCreated)="onItemCreated($event)">
        </library-header>

        <common-dropzone class="content"
          text="Drop files to import..."
          [rcpDroppable]="isDroppableFn"
          rcpDropOverClass="active"
          (rcpDrop)="onDrop($event)"
          [disabled]="!canManageItem">

          <div #selectionContainer 
            [rcpDraggable]="selected.length > 0 && canCopyItem"
            rcpDragType="items"
            rcpDragEffect="move"
            [rcpDragTheme]="theme.theme$ | async"
            [rcpDragData]="selected">

            <ng-container [ngSwitch]="viewName">
              <library-results-list
                *ngSwitchCase="'list'"
                [items]="items$ | async"
                [collection]="collection$ | async"
                [disabled]="!canManageItem"
                [selected]="selected"
                [highlighted]="highlighted"
                [loading]="loading$ | async"
                [finished]="finished$ | async"
                (open)="onItemDblClick($event)"
                (loadNext)="onLoadNext($event)"
                (selectedChange)="selectItems($event)"
                (highlightedChange)="highlightItems($event)"
                (hasFocusChange)="listHasFocus = $event"
                (contextmenu)="itemsMenu.open($event)">
              </library-results-list>

              <library-results-table
                *ngSwitchCase="'table'"
                [items]="items$ | async"
                [collection]="collection$ | async"
                [sort]="sort$ | async"
                [order]="order$ | async"
                [disabled]="!canManageItem"
                [selected]="selected"
                [highlighted]="highlighted"
                [loading]="loading$ | async"
                [finished]="finished$ | async"
                (open)="onItemDblClick($event)"
                (loadNext)="onLoadNext($event)"
                (selectedChange)="selectItems($event)"
                (highlightedChange)="highlightItems($event)"
                (hasFocusChange)="listHasFocus = $event"
                (contextmenu)="itemsMenu.open($event)">
              </library-results-table>
            </ng-container>
          </div>
        </common-dropzone>
      </div>
    </as-split-area>

    <as-split-area [size]="viewSizes[1]" [visible]="previewOpen$ | async">
      <router-outlet name="bottompanel"></router-outlet>
    </as-split-area>
  </as-split>

  <ng-container footer>
    <div class="footer-content">
      <span class="text">{{statusText$ | async | capitalize}}</span>
      <span class="buttons" *ngIf="selected.length === 1">

        <!-- toggle preview button -->
        <ng-container *ngIf="previewOpen$ | async; else closedPreviewTemplate">
          <button class="btn btn-link" (click)="closePreview()" ngbTooltip="Close preview" container="body">
            <fa-icon [icon]="['fas', 'eye-slash']"></fa-icon>
          </button>
        </ng-container>
        <ng-template #closedPreviewTemplate>
          <button class="btn btn-link" (click)="openPreview(selected[0])" *ngIf="preview.canShow(selected[0])" ngbTooltip="Open preview" container="body">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
          </button>
        </ng-template>

        <!-- toggle sidepanel button -->
        <ng-container *ngIf="sidepanelOpen$ | async; else closedSidepanelTemplate">
          <button class="btn btn-link" (click)="closeSidePanel()" ngbTooltip="Close sidepanel" container="body">
            <fa-icon [icon]="['fas', 'arrow-to-right']"></fa-icon>
          </button>
        </ng-container>
        <ng-template #closedSidepanelTemplate>
          <button class="btn btn-link" (click)="openSidePanel()" ngbTooltip="Open sidepanel" container="body">
            <fa-icon [icon]="['fas', 'arrow-to-left']"></fa-icon>
          </button>
        </ng-template>

      </span>
    </div>
  </ng-container>

  <ng-container sidepanel>
    <div class="wrapper sidepanel">
      <div class="navigation papers-border-bottom">
        <ul>
          <li ngbTooltip="Details" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('details')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'details' }">
              <rcp-svg-icon name="info" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Edit" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('edit')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'edit' }">
              <rcp-svg-icon name="cite" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Share" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('share')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'share' }">
              <rcp-svg-icon name="share" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Notes" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('notes')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'notes' }">
              <rcp-svg-icon name="comment" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Metrics" openDelay="1000" placement="bottom" *ngIf="shared.user?.licence.active">
            <a href (click)="openSidePanel('metrics')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'metrics' }">
              <rcp-svg-icon name="metrics" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Metrics" openDelay="1000" placement="bottom" *ngIf="!shared.user?.licence.active">
            <a href (click)="openSubscriptionModal()" rcpPreventDefault class="disabled">
              <rcp-svg-icon name="metrics" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Files" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('files')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'files' }">
              <rcp-svg-icon name="files" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Export" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('export')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'export' }">
              <rcp-svg-icon name="export" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>

          <li class="right" ngbTooltip="Options" openDelay="1000" placement="bottom">
            <a href (click)="itemsMenu.open($event, true)" rcpPreventDefault>
              <rcp-svg-icon name="gear" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
        </ul>
      </div>

      <common-dropzone class="content"
        text="Drop files to attach them as a supplement..."
        [rcpDroppable]="isDroppableFn"
        rcpDropOverClass="active"
        (rcpDrop)="onDropAttachment($event)"
        [disabled]="!canManageFile">

        <router-outlet name="sidepanel"></router-outlet>
      </common-dropzone>
    </div>
  </ng-container>

</common-sidepanel-layout>
