import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppShellService } from '../../app-shell.service';
import { ImporterService } from '../services/importer.service';
import { IBannerError } from './importer-error-banner.component';
import { IDataUser } from '../../common-data';
export interface IImporterOptionsBib {
  mergeDuplicates: boolean
}

@Component({
  selector: 'rcp-importer-options-bib',
  templateUrl: './importer-options-bib.component.html'
})
export class ImporterOptionsBibComponent implements OnInit {
  @Input()
  user: IDataUser;

  @Input()
  collectionId: string;

  @Input()
  files: File[] = [];

  @Input()
  options: IImporterOptionsBib;

  @Output()
  optionsChange = new EventEmitter<IImporterOptionsBib>();

  @Input()
  error: IBannerError;

  @Output()
  errorChange = new EventEmitter<IBannerError>();

  constructor(
    public importer: ImporterService,
    public shell: AppShellService
  ) {}

  ngOnInit() {
    this.optionsChange.subscribe(this.validate.bind(this));
    this.validate(this.options);
  }

  setError(error: null | IBannerError) {
    this.error = error;
    this.errorChange.next(error);
  }

  validate(options: IImporterOptionsBib) {
    this.setError(null);
    // TODO: validate?
  }
}
