<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>

<div class="modal-body">
  <p [innerHtml]="message"></p>

  <div class="col-12">
    <form (ngSubmit)="confirm()">
      <input class="form-control w-100" name="text-input" type="text" autofocus [(ngModel)]="value">
    </form>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-lg btn-secondary" (click)="cancel()">{{cancelButtonText}}</button>
  <button type="button" class="btn btn-lg btn-primary" (click)="confirm()" [disabled]="!value">{{confirmButtonText}}</button>
</div>
