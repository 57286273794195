import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, finalize } from 'rxjs';

import { DataCollectionService, IDataCollection, IDataList } from '../../library-data';

@Component({
  templateUrl: './modal-notifications.component.html'
})
export class ModalNotificationsComponent implements OnInit {

  collection: IDataCollection;
  list: IDataList;
  frequency: 'daily' | 'weekly' | 'monthly' | null;

  loading$ = new BehaviorSubject<boolean>(false);

  frequencies = [
    { name: 'Disabled', value: null },
    { name: 'Daily', value: 'daily' },
    { name: 'Weekly', value: 'weekly' },
    { name: 'Monthly', value: 'monthly' }
  ];

  constructor(
    public activeModal: NgbActiveModal,
    public dataCollection: DataCollectionService
  ) { }

  ngOnInit() {
    this.dataCollection.getNotifications(this.collection.id, this.list?.id).subscribe(notifications => {
      this.frequency = notifications.frequency;
    });
  }

  submit() {
    this.loading$.next(true);
    this.dataCollection.setNotifications(this.collection.id, { list_id: this.list?.id, frequency: this.frequency }).pipe(
      finalize(() => this.loading$.next(false))
    ).subscribe(() => this.activeModal.close());
  }

  cancel() {
    this.activeModal.close();
  }
}
