import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IImportType } from '../services/importer.service';
import { IDataUser } from '../../common-data';

@Component({
  templateUrl: './importer-source-select.component.html',
  selector: 'rcp-importer-source-select',
})
export class ImporterSourceSelectComponent {
  @Input()
  user: IDataUser;

  @Input()
  importType: IImportType;

  @Input()
  selectedFiles: File[] = [];

  @Input()
  availableImportTypes: IImportType[] = [];

  @Output()
  importTypeChange = new EventEmitter<IImportType>();

  @Output()
  selectedFilesChange = new EventEmitter<File[]>();

  onTypeChange(event: HTMLInputEvent) {
    this.selectedFiles = [];
    this.importTypeChange.next(this.importType);
    this.selectedFilesChange.next(this.selectedFiles);
  }

  onFileSelect(event: HTMLInputEvent) {
    this.selectedFiles = Array.from(event.target.files);
    this.selectedFilesChange.next(this.selectedFiles);
  }
}
