import {
  Component,
  Inject,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  Renderer2, 
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'common-sidepanel-layout',
  templateUrl: './common-sidepanel-layout.component.html',
  styleUrls: ['./common-sidepanel-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('sidePanelOpenClose', [
      state('open', style({
        'width': '{{width}}px',
        'right': '0px'
      }), {
        params: { width: 450 }
      }),
      state('closed', style({
        'width': '{{width}}px',
        'right': '-{{width}}px'
      }), {
        params: { width: 450 }
      }),
      state('mobile-open', style({
        'right': '0px',
        'width': '100%'
      })),
      state('mobile-closed', style({
        'right': '-450px'
      })),
      transition('* => *', animate('400ms ease-out'))
    ]),
    trigger('contentExpandContract', [
      state('expanded', style({
        'right': '0px'
      })),
      state('contracted', style({
        'right': '{{width}}px'
      }), {
        params: { width: 450 }
      }),
      state('mobile-expanded', style({
        'right': '0px',
        'left': '0px'
      })),
      state('mobile-contracted', style({
        'left': '-100%'
      })),
      transition('* => *', animate('400ms ease-out'))
    ])
  ]
})
export class CommonSidepanelLayoutComponent implements OnDestroy {
  @Input()
  sidepanelOpen = false;

  @Input()
  sidepanelWidth = 450;

  @Input()
  sidepanelMinWidth = 310;

  @Input()
  sidepanelMaxWidth = 600;

  @Output()
  sidepanelWidthChange = new EventEmitter<number>();

  @Output()
  sidepanelClosed = new EventEmitter<void>();

  protected dragUnlisten = () => undefined;
  protected stopUnlisten = () => undefined;

  constructor(
    public renderer: Renderer2,
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT)
    public document: Document
  ) { }

  ngOnDestroy() {
    this.stopDrag();
  }

  stopDrag() {
    this.dragUnlisten();
    this.stopUnlisten();
    this.document.body.classList.remove('resizing');
  }

  startDrag(event: MouseEvent) {
    this.document.body.classList.add('resizing');
    this.dragUnlisten = this.renderer.listen(window, 'mousemove', event => {
      this.sidepanelWidth -= event.movementX;
      if (this.sidepanelWidth < this.sidepanelMinWidth) {
        this.sidepanelWidth = this.sidepanelMinWidth;
      } else if (this.sidepanelWidth > this.sidepanelMaxWidth) {
        this.sidepanelWidth = this.sidepanelMaxWidth;
      }
      this.changeDetectorRef.detectChanges();
    });
    this.stopUnlisten = this.renderer.listen(window, 'mouseup', () => {
      this.stopDrag();
    });
  }

  closeSidepanel() {
    this.sidepanelClosed.next();
  }

  get sidePanelState(): string {
    if (this.isMobile) {
      return this.sidepanelOpen ? 'mobile-open' : 'mobile-closed';
    }
    return this.sidepanelOpen ? 'open' : 'closed';
  }

  get contentState(): string {
    if (this.isMobile) {
      return this.sidepanelOpen ? 'mobile-contracted' : 'mobile-expanded';
    }
    return this.sidepanelOpen ? 'contracted' : 'expanded';
  }

  get isMobile(): boolean {
    return window.innerWidth < 825;
  }
}
