<div class="modal-body" [style.width.px]="modalWidth">
  <ng-container *ngIf="hasTemplatesEnabled">
    <rcp-common-templates-menu
      [collectionId]="params.collection.id"
      [type]="params.type"
      [(options)]="params.options"
      (optionsChange)="loadOptions($event)">
    </rcp-common-templates-menu>
  </ng-container>  


  <ng-container *ngIf="params">
    <rcp-exporter-options-csv #optionsRef 
      [collection]="params.collection"
      [list]="params.list"
      [items]="params.items" 
      [(options)]="params.options">
    </rcp-exporter-options-csv>
  </ng-container>
</div>

<div class="modal-footer mt-4">
  <button type="button" class="btn btn-lg btn-secondary me-1" (click)="back()">
    <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon> Back
  </button>

  <button type="button" class="btn btn-lg btn-primary" [disabled]="!params.options" (click)="export()">
    <fa-icon [icon]="['fas', 'file-import']"></fa-icon> Export
  </button>
</div>
