<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <filter x="-38.2%" y="-38.2%" width="176.5%" height="176.5%" filterUnits="objectBoundingBox" id="filter-1">
      <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.26805933 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>
  </defs>
  <g filter="url(#filter-1)">
    <path d="M5.47563527,5.24663849 C5.67186228,5.04665451 5.81614685,4.8584343 5.90848897,4.68197784 L8.22281342,0.382322311 C8.31900313,0.20586586 8.33824107,0.0901888529 8.28052724,0.0352912903 C8.226661,-0.0235275269 8.11123335,-0.00392125448 7.93424428,0.0941101074 L3.72690633,2.45862656 C3.54991726,2.56057917 3.36715681,2.70762622 3.17862498,2.89976768 C2.98239797,3.09975166 2.8381134,3.28797188 2.74577128,3.46442833 L0.425675456,7.7523201 C0.325638156,7.92877655 0.30447642,8.04641418 0.362190247,8.105233 C0.416056485,8.16013056 0.531484138,8.13856366 0.708473206,8.0405323 L4.92735392,5.68777962 C5.10434299,5.585827 5.28710344,5.43877996 5.47563527,5.24663849 L5.47563527,5.24663849 Z" id="Shape" fill="#9823C6"></path>
    <path d="M15.2432531,6.85719005 C15.2124724,6.62975729 15.1259016,6.4434977 14.9835409,6.29841129 L10.8339167,2.06933833 C10.691556,1.92425192 10.5376524,1.86347247 10.3722061,1.887 C10.2029122,1.91052753 10.0701704,2.01248014 9.97398071,2.19285785 L7.65388489,6.4866315 C7.55769518,6.66308795 7.52691447,6.86699318 7.56154277,7.0983472 C7.59232347,7.32577996 7.67697042,7.51203955 7.8154836,7.65712596 L11.9651077,11.880317 C12.1036209,12.0254034 12.2575244,12.0842223 12.4268183,12.0567735 C12.5961122,12.033246 12.728854,11.933254 12.8250437,11.7567975 L15.1451396,7.46890575 C15.2413293,7.28852804 15.2740338,7.08462281 15.2432531,6.85719005 L15.2432531,6.85719005 Z" id="Shape" fill="#FFC34E"></path>
    <path d="M10.3787206,14.11619 C10.4056537,13.9436549 10.3479399,13.7868047 10.2055791,13.6456395 L6.06172638,9.41656656 C5.91936561,9.2754014 5.73660516,9.1891338 5.51344503,9.15776376 C5.28643731,9.12247247 5.08636271,9.15384251 4.91322123,9.25187387 L0.700111898,11.6163903 C0.526970418,11.7144217 0.428856913,11.849705 0.405771383,12.0222402 C0.378838264,12.1908541 0.43655209,12.3477043 0.578912862,12.4927907 L4.72853699,16.7218636 C4.87089776,16.8669501 5.05365821,16.9551783 5.27681834,16.9865483 C5.49997847,17.0179184 5.70005307,16.9845877 5.87704213,16.8865563 L10.0843801,14.5220399 C10.2575216,14.4240085 10.3556351,14.2887252 10.3787206,14.11619 L10.3787206,14.11619 Z" id="Shape" fill="#FF3E3C"></path>
    <path d="M16.9011587,4.97607193 C16.9242442,4.80353673 16.8665304,4.64472593 16.7280172,4.49963951 L12.578393,0.276448441 C12.4398799,0.131362025 12.2590432,0.0431337992 12.0358831,0.0117637634 C11.8088754,-0.0196062724 11.6088008,0.0137243907 11.4356593,0.111755753 L7.22254995,2.47039032 C7.04556088,2.56842168 6.94552358,2.70370496 6.92243805,2.87624016 C6.89935252,3.04877536 6.95899014,3.20758616 7.10135091,3.35267258 L11.250975,7.58174553 C11.3933358,7.72683194 11.5760963,7.81506017 11.7992564,7.84643021 C12.0224165,7.87780024 12.2205673,7.84446958 12.3937088,7.74643822 L16.6010468,5.38192177 C16.7780358,5.28389041 16.8780731,5.14860713 16.9011587,4.97607193 L16.9011587,4.97607193 Z" id="Shape" fill="#26C4FF"></path>
    <path d="M16.9471939,8.87529129 C16.8933277,8.81647247 16.7779,8.83607875 16.6009109,8.93411011 L12.3878016,11.2868628 C12.2108125,11.3888154 12.0261283,11.5358625 11.8337489,11.7280039 C11.6413695,11.9279879 11.4970849,12.1162081 11.4008952,12.2926646 L9.09234212,16.5923201 C8.99615241,16.7687766 8.97499068,16.8864142 9.02885691,16.945233 C9.08657074,17.0040518 9.20199839,16.9824849 9.37513987,16.8805323 L13.5824778,14.5160159 C13.7556193,14.4179845 13.9403035,14.2709374 14.1365306,14.0748747 C14.3250624,13.878812 14.469347,13.6905918 14.5693843,13.5102141 L16.8894801,9.22232231 C16.9856698,9.04586586 17.0049077,8.93018885 16.9471939,8.87529129 L16.9471939,8.87529129 Z" id="Shape" fill="#FE0FA6"></path>
    <path d="M9.6784729,10.5119057 C9.77466261,10.3354493 9.80736711,10.1315441 9.77658641,9.90019005 C9.7458057,9.67275729 9.65923496,9.4864977 9.51687419,9.34141129 L5.36725006,5.11233833 C5.22488929,4.96725192 5.07098575,4.9084331 4.90553945,4.93588188 C4.73624556,4.95940941 4.60350376,5.0594014 4.50731405,5.23585785 L2.18721822,9.5296315 C2.09102851,9.71000921 2.0602478,9.91391444 2.0948761,10.1413472 C2.12565681,10.36878 2.21030375,10.5550395 2.34881694,10.700126 L6.49844106,14.923317 C6.63695424,15.0684034 6.79085778,15.1291829 6.96015167,15.1056554 C7.12944556,15.0821278 7.26218736,14.9801752 7.35837708,14.7997975 L9.6784729,10.5119057 L9.6784729,10.5119057 Z" id="Shape" fill="#98D843"></path>
  </g>
</svg>
<span><ng-content></ng-content></span>