import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

@Injectable()
export class DialogService {
  constructor(
    protected router: Router
  ) { }

  openDialog<T>(dialog: string[], queryParams: Params = {}) {
    this.router.navigate([{
      outlets: { dialog }
    }], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  closeDialog() {
    this.router.navigate([{
      outlets: { dialog: null }
    }], {
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
