import { Injectable } from '@angular/core';

import { AppStorageService } from '../../app-storage.service';

@Injectable()
export class ListExplorerService {
  protected expandedDirectories: any;

  constructor(
    public storage: AppStorageService,
  ) {
    this.expandedDirectories = this.storage.get('list-explorer-expanded-directories', {});
  }

  isNodeExpanded(listId: string): boolean {
    return this.expandedDirectories[listId];
  }

  setNodeExpanded(listId: string, expanded: boolean) {
    this.expandedDirectories[listId] = expanded;
    this.storage.set('list-explorer-expanded-directories', this.expandedDirectories);
  }
}
