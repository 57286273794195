<ul *ngIf="shared.user">
  <li class="dropdown-login">
    <div class="row mx-2">
      <div class="col-lg-12">
        <div class="mt-1"><strong>{{shared.user.name}}</strong></div>
        <div>{{shared.user.email}}</div>
        <div class="mt-1">
          <span class="text-muted">Version: {{buildVersion}}</span>
          <span class="ms-1" style="padding: 4px" ngbTooltip="Released on {{(buildTime*1000) | date:'long'}}" container="body">
            <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
          </span>
        </div>
      </div>
    </div>
  </li>
  <li class="dropdown-divider"></li>
  <li>
    <button type="button" class="dropdown-item" [ngClass]="{'checked': isSystemTheme()}" (click)="theme.setPreferedTheme(null)"><fa-icon [icon]="['fas', 'adjust']" [fixedWidth]="true"></fa-icon> System Theme</button>
  </li>
  <li>
    <button type="button" class="dropdown-item" [ngClass]="{'checked': isLightTheme()}" (click)="theme.setPreferedTheme('light')"><fa-icon [icon]="['fas', 'lightbulb-on']" [fixedWidth]="true"></fa-icon> Light Theme</button>
  </li>
  <li>
    <button type="button" class="dropdown-item" [ngClass]="{'checked': isDarkTheme()}" (click)="theme.setPreferedTheme('dark')"><fa-icon [icon]="['fas', 'lightbulb']" [fixedWidth]="true"></fa-icon> Dark Theme</button>
  </li>
  <li class="dropdown-divider"></li>
  <li>
    <button type="button" class="dropdown-item" (click)="onAccountClick()"><fa-icon [icon]="['fas', 'user']" [fixedWidth]="true"></fa-icon> My Account</button>
  </li>
  <li *ngIf="canOpenDashboard()">
    <button type="button" class="dropdown-item" (click)="onDashboardClick()"><fa-icon [icon]="['fas', 'users']" [fixedWidth]="true"></fa-icon> Manage Organization</button>
  </li>
  <li class="dropdown-divider"></li>
  <li>
    <button type="button" class="dropdown-item" (click)="onReleaseNotesClick($event)"><fa-icon [icon]="['fas', 'sticky-note']" [fixedWidth]="true"></fa-icon> Release Notes</button>
  </li>
  <li>
    <button type="button" class="dropdown-item" (click)="onSupportClick()"><fa-icon [icon]="['fas', 'life-ring']" [fixedWidth]="true"></fa-icon> Support</button>
  </li>
  <li class="dropdown-divider"></li>
  <li>
    <button type="button" class="dropdown-item" (click)="onLogoutClick()"><fa-icon [icon]="['fas', 'sign-out-alt']" [fixedWidth]="true"></fa-icon> Sign out</button>
  </li>
</ul>
