
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';
import { ReadCubeDataViewModule } from '@readcube/rcp-data-view';
import { ReadCubeKatexModule } from '@readcube/rcp-katex';

import { AppCommonModule } from '../common';
import { SearchResultsListComponent } from './components/search-results-list.component';
import { SearchResultsTableComponent } from './components/search-results-table.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
    ReadCubeDataViewModule,
    ReadCubeKatexModule,
    AppCommonModule,
  ],
  declarations: [
    SearchResultsListComponent,
    SearchResultsTableComponent,
  ],
  exports: [
    SearchResultsListComponent,
    SearchResultsTableComponent,
  ]
})
export class AppSearchResultsModule { }
