<context-menu #contextMenuComponent>
  <ng-template contextMenuItem (execute)="edit()" [enabled]="canManageSmartlist()">Edit</ng-template>
  <ng-template contextMenuItem (execute)="delete()" [enabled]="canManageSmartlist()">Delete</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="share()" [enabled]="canShareList()">Share as Public List</ng-template>
</context-menu>

<common-navigation-button
  [routerLink]="['/library', list.collection_id, 'smartlist', list.id]"
  [queryParams]="{ query: list.query, sort: list.sortField, order: list.sortOrder }"
  routerLinkActive="active"
  [contextMenu]="contextMenuComponent"

  [indent]="indent"

  [ngClass]="{
    'disabled': disabled$ | async
  }">
  <ng-container icon>
    <rcp-svg-icon name="list-smart"></rcp-svg-icon>
  </ng-container>

  {{list.name}}

  <ng-container addons>
    <common-navigation-button-addon (click)="openContextMenu($event)">
      <fa-icon [icon]="['fas', 'cog']"></fa-icon>
    </common-navigation-button-addon>
  </ng-container>
</common-navigation-button>
