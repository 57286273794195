import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { asBib } from '@readcube/rcp-citation-export';
import { gen as citationGen } from '@readcube/citation-gen';
import { NATURE_STYLE, READCUBE_CUSTOM_STYLE } from 'strings';

import { ReaderService, SharedService } from '../../common';
import { IDataArticle } from '../../search-data';
import { DataItemService, IDataCollectionCustomField, IDataCollectionTypeSchema, IDataItem } from '../../library-data';
import { DataStylesApiService, IDataArticleItemStatus } from '../../common-data';

import { AppStorageService } from '../../app-storage.service';
import { AppShellService, fixBibliographyEntry } from '../../app-shell.service';

@Injectable()
export class SearchService {
  queryTypes = [
    { key: 'publications', display: 'Publications' },
    { key: 'patents', display: 'Patents' },
    { key: 'conference_proceedings', display: 'Conference Proceedings' },
    { key: 'clinical_trials', display: 'Clinical Trials' }
  ];

  sortOptions = [
    { key: 'relevance', display: 'Relevance' },
    { key: 'citations', display: 'Citation Count' },
    { key: 'last_modified', display: 'Last Modified' }
  ];

  orderOptions = [
    { key: 'asc', display: 'Ascending' },
    { key: 'desc', display: 'Descending' },
  ];

  constructor(
    protected dataItem: DataItemService,
    protected shared: SharedService,
    protected reader: ReaderService,
    protected stylesApi: DataStylesApiService,
    protected shell: AppShellService,
    protected storage: AppStorageService
  ) { }

  getViewName(): string {
    return this.storage.get('search-readcube-view', 'list');
  }

  setViewName(view: string) {
    this.storage.set('search-readcube-view', view);
  }

  getSorting(): string {
    return this.storage.get('search-readcube-sort', 'relevance');
  }

  setSorting(sort: string) {
    this.storage.set('search-readcube-sort', sort);
  }

  getOrdering(): string {
    return this.storage.get('search-readcube-order', 'desc');
  }

  setOrdering(order: string) {
    this.storage.set('search-readcube-sort-order', order);
  }

  canOpenCopyright(article: IDataArticle): boolean {
    const copyrightLicence = this.shared.user?.licence?.copyright_licence;
    if (copyrightLicence == 'acl_advisor' && (!!article.isbn || !!article.issn || !!article.eisbn || !!article.eissn))
      return true;
    return copyrightLicence == 'acl_enterprise' && (!!article.ext_ids?.doi || !!article.ext_ids?.pmid);
  }

  canOrderReprint(article: IDataArticle): boolean {
    return this.shared.user?.organization_id
      && (!!article.ext_ids?.doi || !!article.ext_ids?.pmid)
      && article.item_statuses?.some(s => s.has_file);
  }

  openCopyright(articles: IDataArticle[]) {
    const article = articles[0];
    if (this.shared.user?.licence?.copyright_licence == 'acl_advisor') {
      const id = article.isbn || article.eisbn || article.eissn || article.issn;
      this.shell.openURL(`https://rightfind.copyright.com/rs-ui-web/search#journal/${encodeURIComponent(id)}`);
    } else if (this.shared.user?.licence?.copyright_licence == 'acl_enterprise' && article.ext_ids.doi) {
      this.shell.openURL(`https://www.rightfind.com/vlib/order/OpenURLReceive.aspx?clientid=${this.shared.user?.licence?.copyright_acl_id}&action=rights&doi=${encodeURIComponent(article.ext_ids?.doi)}`);
    } else if (this.shared.user?.licence?.copyright_licence == 'acl_enterprise' && article.ext_ids.pmid) {
      this.shell.openURL(`https://www.rightfind.com/vlib/order/OpenURLReceive.aspx?clientid=${this.shared.user?.licence?.copyright_acl_id}&action=rights&pmid=${encodeURIComponent(article.ext_ids?.pmid)}`);
    }
  }

  openOrderReprint(articles: IDataArticle[]) {
    const id = articles[0].ext_ids.doi || articles[0].ext_ids.pmid;
    const field317 = encodeURIComponent(id);
    const field4 = encodeURIComponent(this.shared.user.email);
    const field320 = encodeURIComponent(this.shared.user.name);
    const field3 = encodeURIComponent(this.shared.user.organization_id);
    this.shell.openURL(`https://readcube.wufoo.com/forms/?formname=zalpmf804x4ngz&field317=${field317}&field4=${field4}&field320=${field320}&field3=${field3}`);
  }

  copyArticleSummaryToClipboard(articles: IDataArticle[]) {
    const result = citationGen({ items: this.mapArticlesToItem(articles), style: READCUBE_CUSTOM_STYLE });
    this.shell.copyToClipboard(fixBibliographyEntry(result.bibliographyEntry));
  }

  copyArticleReferenceToClipboard(articles: IDataArticle[], mapping?: IDataCollectionTypeSchema, customFields?: IDataCollectionCustomField[]) {
    const result = citationGen({ items: this.mapArticlesToItem(articles), style: this.stylesApi.lastUsedStyle || NATURE_STYLE, mapping, customFields });
    this.shell.copyToClipboard(fixBibliographyEntry(result.bibliographyEntry));
  }

  copyArticleBibTeXToClipboard(articles: IDataArticle[]) {
    this.shell.copyToClipboard({
      text: this.mapArticlesToItem(articles).map(item => asBib({ item })).join('\n\r')
    });
  }

  copyAuthorsToClipbooard(articles: IDataArticle[]) {
    this.shell.copyToClipboard({
      text: articles.map(article => article.authors.map(author => author.name).join(', ')).flat(1).join('\n\r')
    });
  }

  canViewArticlePDF(article: IDataArticle) {
    const status = this.findArticleItemStatus(article);
    const isFree = article.status_data?.open_access || article.status_data?.free_access;
    const hasDirectUrl = !!this.reader.getDirectUrl(article);
    return status?.has_file || (isFree && hasDirectUrl);
  }

  viewArticlePDF(status: IDataArticleItemStatus) {
    firstValueFrom(this.dataItem.get(status.collection_id, status.id)).then(item => {
      this.reader.viewItemPDF({ item });
    });
  }

  // TODO: reafctor
  findArticleItemStatus(article: IDataArticle): IDataArticleItemStatus {
    if (!article?.item_statuses.length)return null;

    const personalCollectionStatus = article.item_statuses.find(status => !status.collection_shared);
    if (personalCollectionStatus?.has_file) return personalCollectionStatus;

    const hasFileStatus = article.item_statuses.find(status => status.has_file);
    if (hasFileStatus) return hasFileStatus;
    if (personalCollectionStatus) return personalCollectionStatus;

    return article.item_statuses[0];
  }

  protected mapArticlesToItem(articles: IDataArticle[]): Partial<IDataItem>[] {
    return articles.map(article => {
      return {
        article: { ...article, authors: article.authors.map(a => a.name) },
        ext_ids: article.ext_ids
      };
    });
  }
}
