import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';

import { AppCommonModule } from '../common';

import { ExporterService } from './services/exporter.service';
import { ExporterModalOptionsComponent } from './components/exporter-modal-options.component';
import { ExporterModalOptionsCsvComponent } from './components/exporter-modal-options-csv.component';
import { ExporterDestinationSelectComponent } from './components/exporter-destination-select.component';
import { ExporterSourceSelectComponent } from './components/exporter-source-select.component';
import { ExporterOptionsCsvComponent } from './components/exporter-options-csv.component';
import { AppExporterRoutingModule } from './exporter-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    AppCommonModule,
    AppExporterRoutingModule
  ],
  declarations: [
    ExporterModalOptionsComponent,
    ExporterModalOptionsCsvComponent,
    ExporterSourceSelectComponent,
    ExporterDestinationSelectComponent,
    ExporterOptionsCsvComponent,
  ],
  providers: [
    ExporterService
  ]
})
export class AppExporterModule {
  static forRoot(): ModuleWithProviders<AppExporterModule> {
    return {
      ngModule: AppExporterModule,
      providers: [
        ExporterService
      ]
    };
  }
}
