import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, Subject, defer, EMPTY } from 'rxjs';
import { tap, switchMap, mergeMap, startWith, map, filter } from 'rxjs/operators';

import { DataStatusService } from '../../common-data';
import { IDataArticle } from '../../search-data';
import { IDataResultRefinements } from '../../app-data-result.model';
import { DataRecommendApiService, IDataRecommendQueryParams } from './data-recommend-api.service';

@Injectable()
export class DataRecommendService {
  public rows: IDataArticle[] = [];
  public total = 0;
  public totalHuman = '0';
  public loaded = 0;
  public refinements: IDataResultRefinements;

  public sortOptions = [
    { key: 'relevance', value: 'Relevance' },
    { key: 'citations', value: 'Citation Count' },
    { key: 'altmetric', value: 'Altmetric Score' },
    { key: 'year', value: 'Year of Publication' },
  ];

  public orderOptions = [
    { key: 'asc', value: 'Ascending' },
    { key: 'desc', value: 'Descending' },
  ];

  protected reload$ = new Subject<string>();
  protected update$ = new Subject<IDataArticle[]>();
  protected concat$ = new Subject<IDataArticle[]>();
  protected endOfScroll = false;

  constructor(
    public api: DataRecommendApiService,
    private statusService: DataStatusService
  ) { }

  isDone(): boolean {
    return this.endOfScroll || this.loaded >= this.total;
  }

  query(params: IDataRecommendQueryParams): Observable<IDataArticle[]> {
    this.endOfScroll = false;
    return this.api.query(params, true).pipe(
      tap(result => this.rows = result.rows),
      tap(result => this.total = result.total),
      tap(result => this.totalHuman = result.totalHuman),
      tap(result => this.loaded = result.rows.length),
      tap(result => this.refinements = result.refinements),
      switchMap(result => merge(
        this.update$.pipe(map(articles => this.update(articles))),
        this.concat$.pipe(map(articles => this.concat(articles)))
      ).pipe(
        startWith(result.rows)
      ))
    );
  }

  queryNext(params: IDataRecommendQueryParams): Promise<IDataArticle[]> {
    return firstValueFrom(this.api.query(params)).then(result => {
      this.loaded += result.rows.length;
      this.endOfScroll = !result.rows || result.rows.length == 0;
      return this.pushDownstream(result.rows, 'concat');
    });
  }

  get(articleId: string): Observable<IDataArticle> {
    // New article is taken from list of old articles, because there is no enpoint to get it.
    // Only status_data and item_statuses are fetched and updated.
    const article = this.rows.find(r => r.id === articleId);
    if (!article) {
      return EMPTY;
    }
    const get$ = defer(() => Promise.all([
      this.statusService.getArticleData(article.ext_ids),
      this.statusService.checkInLibraryStatus(article.ext_ids)
    ]).then(result => {
      article.status_data = result[0];
      article.item_statuses = result[1];
      this.pushDownstream([article], 'update');
      return article;
    }));
    const reload$ = this.reload$.pipe(
      filter(id => articleId === id),
      mergeMap(() => get$)
    );
    return merge(get$, reload$);
  }

  pushDownstream(articles: IDataArticle[], mode: 'concat' | 'update'): IDataArticle[] {
    articles = Array.isArray(articles) ? articles : [articles];
    switch (mode) {
      case 'concat':
        this.concat$.next(articles);
        break;
      default:
        this.update$.next(articles);
    }
    return articles;
  }

  reloadItem(articleId: string) {
    this.reload$.next(articleId);
  }

  protected update(articles: IDataArticle[]): IDataArticle[] {
    articles.forEach(article => {
      const i = this.rows.findIndex(r => r.id === article.id);
      if (i > -1) {
        this.rows.splice(i, 1, article);
      }
    });
    return this.rows.slice();
  }

  protected concat(articles: IDataArticle[]): IDataArticle[] {
    this.rows.push(...articles);
    return this.rows.slice();
  }
}
