import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeTokenFieldModule } from '@readcube/rcp-token-field';

import { AppBulkRoutingModule } from './bulk-routing.module';
import { BulkService } from './services/bulk.service';
import { BulkIdentifiersService } from './services/bulk-identifiers.service';
import { BulkModalIdentifiersComponent } from './components/bulk-modal-identifiers.component';
import { ModalBulkLocatePDFComponent } from './modals/modal-bulk-locate-pdf.component';
import { ModalBulkExportPDFComponent } from './modals/modal-bulk-export-pdf.component';
import { ModalCopyItemsComponent } from '../bulk/modals/modal-copy-items.component';
import { ModalImportErrorComponent } from '../bulk/modals/modal-import-error.component';
import { AppCommonModule } from '../common';
import { AppLibraryModule } from '../library';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeTokenFieldModule,
    AppLibraryModule,
    AppCommonModule,
    AppBulkRoutingModule
  ],
  declarations: [
    BulkModalIdentifiersComponent,
    ModalBulkLocatePDFComponent,
    ModalBulkExportPDFComponent,
    ModalCopyItemsComponent,
    ModalImportErrorComponent
  ],
  exports: [
    ModalBulkLocatePDFComponent,
    ModalBulkExportPDFComponent,
    ModalCopyItemsComponent,
    ModalImportErrorComponent
  ],
  providers: [
    BulkService,
    BulkIdentifiersService
  ]
})
export class AppBulkModule { }
