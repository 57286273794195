import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ExtensionService } from '../services/extension.service';

import { environment } from 'environment';

@Component({
  templateUrl: './modal-extension-not-available.component.html'
})
export class ModalExtensionNotAvailableComponent {
  error: string;

  constructor(
    public activeModal: NgbActiveModal,
    public extension: ExtensionService
  ) { }

  close() {
    this.activeModal.close();
  }
}
