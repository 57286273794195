import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataCollectionApiService } from './services/data-collection-api.service';
import { DataItemApiService } from './services/data-item-api.service';
import { DataListApiService } from './services/data-list-api.service';
import { DataSmartListApiService } from './services/data-smartlist-api.service';
import { DataAiApiService } from './services/data-ai-api.service';

import { DataCollectionService } from './services/data-collection.service';
import { DataListService } from './services/data-list.service';
import { DataSmartListService } from './services/data-smartlist.service';
import { DataItemService } from './services/data-item.service';
import { DataAiService } from './services/data-ai.service';


@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DataCollectionApiService,
    DataItemApiService,
    DataListApiService,
    DataSmartListApiService,
    DataAiApiService
  ]
})
export class AppLibraryDataModule {
  static forRoot(): ModuleWithProviders<AppLibraryDataModule> {
    return {
      ngModule: AppLibraryDataModule,
      providers: [
        DataCollectionService,
        DataListService,
        DataSmartListService,
        DataItemService,
        DataAiService
      ]
    };
  }
}
