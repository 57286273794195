import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'library-sidepanel-header',
  templateUrl: './library-sidepanel-header.component.html',
  styleUrls: ['./library-sidepanel-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibrarySidepanelHeaderComponent {
  @Input()
  journal: string;

  @Input()
  title: string;

  @Input()
  chapter?: string;

  @Input()
  year?: string;

  get journalText(): string {
    return [this.journal, this.year].filter(s => !!s).join(' ');
  }

  get titleText(): string {
    return [this.title, this.chapter].filter(s => !!s).join(' - ') || 'Untitled';
  }
}
