import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'common-navigation-button-addon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./common-navigation-button-addon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonNavigationButtonAddonComponent {

}
