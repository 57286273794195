<search-menu
  #articlesMenu
  [articles]="highlighted?.length ? highlighted : selected"
  [collections]="collections$ | async"
  (onMenuClose)="onMenuClose($event)">
</search-menu>

<common-sidepanel-layout [sidepanelOpen]="sidePanelOpen$ | async" (sidepanelClosed)="closeSidePanel()">
  <ng-container content>
    <div class="wrapper">

      <search-query-header
        #searchHeader
        class="header"
        [(viewName)]="viewName"
        [fields]="fields"
        [fieldInputCapabilities]="fieldInputCapabilities"
        [fieldDefaultInputCapabilities]="fieldDefaultInputCapabilities"
        [fieldInputOptions]="fieldInputOptions"
        [sort]="sort$ | async"
        [order]="order$ | async"
        [query]="query$ | async"
        [queryType]="queryType$ | async"
        [user]="user$ | async"
        searchPlaceholder="Enter search terms..."
        (viewNameChange)="onViewNameChange($event)">
      </search-query-header>

      <div #selectionContainer
        class="content"
        [rcpDraggable]="selected.length > 0"
        rcpDragType="articles"
        rcpDragEffect="move"
        [rcpDragData]="selected">

        <ng-container [ngSwitch]="viewName">
          <search-results-list *ngSwitchCase="'list'"
            [multiselect]="true"
            [items]="articles$ | async"
            [selected]="selected"
            [highlighted]="highlighted"
            (loadNext)="onLoadNext($event)"
            [loading]="loading$ | async"
            [finished]="finished$ | async"
            (selectedChange)="selectItems($event)"
            (highlightedChange)="highlightItems($event)"
            (contextmenu)="articlesMenu.open($event)">
          </search-results-list>

          <search-results-table
            *ngSwitchCase="'table'"
            [multiselect]="true"
            [items]="articles$ | async"
            [sort]="sort$ | async"
            [order]="order$ | async"
            [selected]="selected"
            [highlighted]="highlighted"
            (loadNext)="onLoadNext($event)"
            [loading]="loading$ | async"
            [finished]="finished$ | async"
            (selectedChange)="selectItems($event)"
            (highlightedChange)="highlightItems($event)"
            (contextmenu)="articlesMenu.open($event)">
          </search-results-table>
        </ng-container>
      </div>

    </div>
  </ng-container>

  <!-- <ng-container footer>{{statusText$ | async | capitalize}}</ng-container> -->

  <ng-container sidepanel>
    <div class="wrapper sidepanel">
      <div class="navigation papers-border-bottom">
        <ul>
          <li ngbTooltip="Details" openDelay="1000" placement="bottom">
            <a href (click)="openSidePanel('details')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'details' }">
              <rcp-svg-icon name="info" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Metrics" openDelay="1000" placement="bottom" *ngIf="shared.user?.licence.active">
            <a href (click)="openSidePanel('metrics')" rcpPreventDefault [ngClass]="{ 'active': sidepanel.getActive() === 'metrics' }">
              <rcp-svg-icon name="metrics" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
          <li ngbTooltip="Metrics" openDelay="1000" placement="bottom" *ngIf="!shared.user?.licence.active">
            <a href (click)="openSubscriptionModal($event)" rcpPreventDefault class="disabled">
              <rcp-svg-icon name="metrics" width="25" height="25"></rcp-svg-icon>
            </a>
          </li>
        </ul>
      </div>

      <div class="content">
        <router-outlet name="sidepanel"></router-outlet>
      </div>
    </div>
  </ng-container>
</common-sidepanel-layout>
