import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './navigation-drawer-search.component.html',
  styleUrls: ['./navigation-drawer.component.scss']
})
export class NavigationDrawerSearchComponent {
  headerItems = [
    {
      name: 'New Search',
      icon: 'home',
      action: () => {
        this.router.navigate(['/search']);
      }
    },
    {
      name: 'Recommendations',
      icon: 'diamond',
      action: () => {
        // todo check what to do with recommendations
        this.router.navigate(['/search']);
      }
    }
  ];

  // hardcoded for now
  savedItems = [
    {
      title: 'European journal of medicine',
      info: '1 filter',
      icon: 'search',
    },
    {
      title: 'Hear disease in the elderly',
      info: '6 filters',
      icon: 'search',
    }
  ];

  recentItems = [
    {
      title: 'European journal of medicine',
      info: '1 filter',
      icon: 'history',
    },
    {
      title: 'Hear disease in the elderly',
      info: '4 filters',
      icon: 'history',
    }
  ];

  constructor(
    public router: Router
  ) { }

}
