import { LibraryCollectionComponent } from './library-collection.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'library-collection-smartlist',
  templateUrl: './library-collection.component.html',
  styleUrls: ['./library-collection.component.scss']
})
export class LibraryCollectionSmartListComponent extends LibraryCollectionComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}
