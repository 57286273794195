import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';

import { NavigationDrawerSearchComponent } from './components/navigation-drawer-search.component';
import { NavigationDrawerLibraryComponent } from './components/navigation-drawer-library.component';
import { NavigationDrawerReviewComponent } from './components/navigation-drawer-review.component';
import { NavigationComponent } from './components/navigation.component';
import { NavigationService } from './services/navigation.service';
import { NavigationButtonDirective } from './directives/navigation-button.directive';
import { NavigationDrawerComponent } from './components/navigation-drawer.component';

import { AppCommonModule } from '../common';
import { AppNavigationRoutingModule } from './navigation-routing.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeSvgIconsModule,
    AppCommonModule,
    AppNavigationRoutingModule
  ],
  declarations: [
    NavigationDrawerSearchComponent,
    NavigationDrawerLibraryComponent,
    NavigationDrawerReviewComponent,
    NavigationComponent,
    NavigationButtonDirective,
    NavigationDrawerComponent
  ],
  exports: [
    NavigationComponent
  ],
  providers: [
    NavigationService
  ]
})
export class AppNavigationModule { }
