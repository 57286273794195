<ng-template #fileTemplate let-item="item" let-file="file" let-canViewPDF="canViewPDF"
  let-canDownloadFile="canDownloadFile" let-canSetPrimary="canSetPrimary"
  let-downloading="downloading" let-canDelete="canDelete" let-thumbURL="thumbURL">
  <div class="file">
    <div class="thumbnail" [hidden]="hideThumb" *ngIf="thumbURL && !shared.user?.licence?.hide_thumbnails; else noThumbTemplate">
      <a href (click)="onThumbClick($event, file, canViewPDF, canDownloadFile)" [class.disabled]="!canViewPDF">
        <img [src]="thumbURL" (error)="hideThumb = true">
      </a>
    </div>
    <ng-template #noThumbTemplate>
      <div class="thumbnail">
        <span class="text-center text-muted" style="opacity: .2;">
          <fa-icon [icon]="['fal', 'file']" size="9x"></fa-icon>
        </span>
      </div>
    </ng-template>

    <div class="body">
      <div class="d-inline-block" *ngIf="canViewPDF">
        <button class="btn btn-primary" (click)="open($event, file)">View PDF</button>
      </div>
      <div class="d-inline-block" *ngIf="!canViewPDF">
        <button class="btn btn-primary" (click)="download($event, file)">Download</button>
      </div>
      <div ngbDropdown class="d-inline-block ms-1">
        <button class="btn btn-secondary" ngbDropdownToggle *ngIf="canDownloadFile || canSetPrimary || canDelete">More</button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <button class="dropdown-item" (click)="download($event, file)" *ngIf="canDownloadFile" [disabled]="!canDownloadFileForCollection">Download from Cloud</button>
          <div class="dropdown-divider" *ngIf="canDownloadFile"></div>
          <button class="dropdown-item" (click)="setPrimary(file)" *ngIf="canSetPrimary" [disabled]="!canManageFile">Make Primary</button>
          <button class="dropdown-item" (click)="delete(file)" *ngIf="canDelete" [disabled]="!canManageFile">Delete File</button>
        </div>
      </div>
      <div class="text-info mt-1">{{file.name || 'Untitled'}}</div>
      <div class="text-info" *ngIf="file.size">{{file.size | bytes}}</div>
      <div class="text-info" *ngIf="file.pages">{{file.pages}} Page{{file.pages > 1 ? 's' : ''}}</div>
    </div>
  </div>
</ng-template>

<ng-template #emptyTemplate>
  <div class="empty-text">Drag and drop PDF files here</div>
</ng-template>




<div class="wrapper papers-scrollbar" *ngIf="item">
  <library-sidepanel-header
    [journal]="item.article.journal"
    [year]="item.article.year"
    [title]="item.article.title"
    [chapter]="item.article.chapter">
  </library-sidepanel-header>

  <ul class="files" *ngIf="item.files.length; else emptyTemplate">
    <li *ngFor="let file of item.files; let i = index">
      <ng-template [ngTemplateOutlet]="fileTemplate" [ngTemplateOutletContext]="{
        item: item,
        file: file,
        canViewPDF: canViewPDF[file.sha256],
        canDownloadFile: canDownloadFile[file.sha256],
        canSetPrimary: canSetPrimary[file.sha256],
        canDelete: canDelete[file.sha256],
        thumbURL: thumbURL[file.sha256]
      }"></ng-template>
    </li>
  </ul>
</div>

<div class="footer" *ngIf="item && canManageFile">
  <label class="form-control btn btn-secondary pointer">
    <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon> Add files
    <input type="file" id="files" name="files[]" class="form-control"
      (change)="onFileSelect($event)" accept="*" multiple hidden>
  </label>
</div>
