import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { of as observableOf } from 'rxjs';

@Directive({
  selector: '[emailInvitedValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailInvitedValidator),
      multi: true
    }
  ]
})
export class EmailInvitedValidator implements Validator {
  @Input()
  emailInvitedList: string[] = [];

  validate(control: FormControl) {
    const value = control.value;
    
    if (value instanceof Array) {
      const invalidEmails = value.filter(email => this.emailInvitedList.includes(email));
      return invalidEmails.length ? { emailInvited: invalidEmails } : null;
    } else if (value) {
      return this.emailInvitedList.includes(value) ? { emailInvited: true } : null;
    }
    return observableOf(null);
  }
}
