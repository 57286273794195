<nav class="navigation">
  <ul class="module-list">
    <li class="module">
      <common-icon-readcube width="28px" height="28px"></common-icon-readcube>
    </li>
    <li
      class="module"
      routerLink="/search"
      [ngClass]="{ 'active': isRouteActive('/search') }"
      navigationButton
      drawerLink="search">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48221 0C4.56302 0 3.81787 0.745148 3.81787 1.66433V18.6993C3.81787 19.6185 4.56302 20.3636 5.48221 20.3636H18.7969C19.7161 20.3636 20.4612 19.6185 20.4612 18.6993V1.66434C20.4612 0.745148 19.7161 0 18.7969 0H5.48221ZM6.31439 3.32869H17.9647V4.99302H6.31439V3.32869ZM17.9647 6.65735H6.31439V8.32168H17.9647V6.65735ZM6.31439 9.986H12.1396V11.6503H6.31439V9.986Z" fill="url(#paint0_linear_286_6862)"/>
        <g style="mix-blend-mode:overlay" opacity="0.6">
          <circle cx="19.2132" cy="17.5" r="4.57692" fill="black"/>
        </g>
        <path d="M19.2125 11.4546C18.0668 11.4526 16.9427 11.7665 15.9639 12.3619C14.985 12.9573 14.1892 13.811 13.6641 14.8293C13.1389 15.8475 12.9046 16.9909 12.987 18.1336C13.0694 19.2764 13.4653 20.3743 14.1312 21.3066L8.81055 26.627L10.2815 28.0979L15.6018 22.7776C16.4021 23.3464 17.3253 23.7183 18.2965 23.863C19.2676 24.0077 20.2591 23.9211 21.1905 23.6103C22.1218 23.2995 22.9667 22.7732 23.6562 22.0743C24.3458 21.3754 24.8607 20.5235 25.159 19.5881C25.4573 18.6527 25.5306 17.66 25.3729 16.6909C25.2152 15.7218 24.831 14.8036 24.2515 14.011C23.672 13.2184 22.9137 12.5738 22.0381 12.1296C21.1625 11.6854 20.1944 11.4541 19.2125 11.4546ZM19.2125 21.8567C18.3896 21.8567 17.5851 21.6127 16.9009 21.1555C16.2167 20.6983 15.6834 20.0484 15.3685 19.2881C15.0535 18.5278 14.9711 17.6912 15.1317 16.8841C15.2922 16.077 15.6885 15.3356 16.2704 14.7537C16.8523 14.1718 17.5937 13.7755 18.4008 13.615C19.2079 13.4544 20.0445 13.5368 20.8048 13.8517C21.5651 14.1667 22.2149 14.7 22.6721 15.3842C23.1293 16.0685 23.3733 16.8729 23.3733 17.6959C23.3721 18.799 22.9333 19.8566 22.1533 20.6366C21.3732 21.4167 20.3156 21.8555 19.2125 21.8567Z" fill="#3493CF"/>
        <defs>
          <linearGradient id="paint0_linear_286_6862" x1="3.81787" y1="0" x2="15.6376" y2="17.8999" gradientUnits="userSpaceOnUse">
            <stop stop-color="#26C4FF"/>
            <stop offset="1" stop-color="#9924C7"/>
          </linearGradient>
        </defs>
      </svg>
      Search
    </li>

    <li
      class="module"
      [routerLink]="['/library', shared.user?.id]"
      [ngClass]="{ 'active': isRouteActive('/library') }"
      navigationButton
      drawerLink="library">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.574 0C9.65482 0 8.90967 0.745148 8.90967 1.66433V18.6993C8.90967 19.6185 9.65481 20.3636 10.574 20.3636H23.8887C24.8079 20.3636 25.553 19.6185 25.553 18.6993V1.66434C25.553 0.745148 24.8079 0 23.8887 0H10.574ZM11.406 3.32867H23.0564V4.993H11.406V3.32867ZM23.0564 6.65736H11.406V8.32169H23.0564V6.65736ZM11.406 9.98602H17.2312V11.6504H11.406V9.98602Z" fill="url(#paint0_linear_286_6874)"/>
        <path d="M8.08056 14H4.53368C4.06631 14 3.61809 14.191 3.28762 14.531C2.95714 14.871 2.77148 15.3321 2.77148 15.8129V26.1871C2.77148 26.6679 2.95714 27.129 3.28762 27.469C3.61809 27.809 4.06631 28 4.53368 28H18.9249C19.3923 28 19.8405 27.809 20.171 27.469C20.5015 27.129 20.6871 26.6679 20.6871 26.1871V17.913C20.6871 17.4322 20.5015 16.965 20.171 16.625C19.8405 16.285 19.3923 16.1 18.9249 16.1H11.8715L9.33172 14.5348C9.16778 14.3651 8.97283 14.2305 8.7581 14.1388C8.54336 14.047 8.31309 13.9998 8.08056 14Z" fill="#FFC24F"/>
        <path d="M8.33472 14H4.40328C3.97063 14 3.5557 14.1375 3.24977 14.3824C2.94384 14.6272 2.77197 14.9592 2.77197 15.3055V16.1H11.872L9.49295 14.3851C9.34119 14.2629 9.16071 14.166 8.96193 14.0999C8.76314 14.0338 8.54998 13.9999 8.33472 14Z" fill="#FFC24F"/>
        <path d="M8.33472 14H4.40328C3.97063 14 3.5557 14.1375 3.24977 14.3824C2.94384 14.6272 2.77197 14.9592 2.77197 15.3055V16.1H11.872L9.49295 14.3851C9.34119 14.2629 9.16071 14.166 8.96193 14.0999C8.76314 14.0338 8.54998 13.9999 8.33472 14Z" fill="#F18800"/>
        <path d="M8.33475 18.2001L2.77197 18.2001C2.77197 18.2001 2.77205 17.6107 2.77205 16.8946V16.1001H11.872L9.49296 17.815C9.34121 17.9371 9.16074 18.0341 8.96195 18.1002C8.76317 18.1663 8.55001 18.2002 8.33475 18.2001Z" fill="#FFC24F"/>
        <path d="M8.33475 18.2001L2.77197 18.2001C2.77197 18.2001 2.77205 17.6107 2.77205 16.8946V16.1001H11.872L9.49296 17.815C9.34121 17.9371 9.16074 18.0341 8.96195 18.1002C8.76317 18.1663 8.55001 18.2002 8.33475 18.2001Z" fill="#F18800"/>
        <defs>
          <linearGradient id="paint0_linear_286_6874" x1="25.617" y1="2.07458e-07" x2="8.98467" y2="20.3643" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFC24F"/>
            <stop offset="1" stop-color="#FF3D3D"/>
          </linearGradient>
        </defs>
      </svg>
      Library
    </li>

    <li
      class="module"
      routerLink="/review"
      [ngClass]="{ 'active': isRouteActive('/review') }"
      navigationButton
      drawerLink="review">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.6667 1.6969C1.72949 1.6969 0.969727 2.45666 0.969727 3.39387V20.7629C0.969727 21.7001 1.72949 22.4598 2.6667 22.4598H16.2425C17.1797 22.4598 17.9394 21.7001 17.9394 20.7629V3.39387C17.9394 2.45666 17.1797 1.6969 16.2425 1.6969H2.6667ZM3.51507 6.78781H15.3939V8.48478H3.51507V6.78781ZM15.3939 10.1817H3.51507V11.8787H15.3939V10.1817ZM3.51507 13.5757H9.45446V15.2726H3.51507V13.5757Z" fill="url(#paint0_linear_286_6888)"/>
        <circle cx="16.243" cy="21.2121" r="6.78788" fill="#99D942"/>
        <circle cx="16.243" cy="21.2121" r="6.78788" fill="#99D942"/>
        <path d="M15.2719 23.7575L12.8486 21.3541L13.6195 20.5894L15.2719 22.2282L18.8632 18.6666L19.6365 19.429L15.2719 23.7575Z" fill="#008D00"/>
        <path d="M5.2124 0.848485C5.2124 0.379879 5.59228 0 6.06089 0H12.8488C13.3174 0 13.6973 0.37988 13.6973 0.848485V4.24242H5.2124V0.848485Z" fill="#008D00"/>
        <defs>
          <linearGradient id="paint0_linear_286_6888" x1="0.969727" y1="1.6969" x2="14.9071" y2="24.488" gradientUnits="userSpaceOnUse">
            <stop stop-color="#26C4FF"/>
            <stop offset="1" stop-color="#87C742"/>
          </linearGradient>
        </defs>
      </svg>
      Review
    </li>

    <li class="module">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_341_15634)">
          <path d="M6.5 4V6.5H4V4H6.5ZM11.75 4V6.5H9.25V4H11.75ZM17 4V6.5H14.5V4H17ZM6.5 9.25V11.75H4V9.25H6.5ZM11.75 9.25V11.75H9.25V9.25H11.75ZM17 9.25V11.75H14.5V9.25H17ZM6.5 14.5V17H4V14.5H6.5ZM11.75 14.5V17H9.25V14.5H11.75ZM17 14.5V17H14.5V14.5H17Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_341_15634">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      All Apps
    </li>
  </ul>
</nav>

<navigation-drawer></navigation-drawer>