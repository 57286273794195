<context-menu #contextMenuComponent>
  <ng-template contextMenuItem (execute)="create()" [enabled]="canManageList() && canCreateSubList()">Create List</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem (execute)="edit()" [enabled]="canManageList()">Edit</ng-template>
  <ng-template contextMenuItem (execute)="delete()" [enabled]="canManageList()">Delete</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [subMenu]="exportOptionsSubMenu" [enabled]="canExportList()">Export to...</ng-template>
  <ng-template contextMenuItem (execute)="share()" [enabled]="canShareList()">Share as Public List</ng-template>
  <ng-template contextMenuItem (execute)="createFilteredList()" [enabled]="canManageList()" *ngIf="canCreateFilteredList()">Create Filtered List</ng-template>
  <ng-template contextMenuItem (execute)="setNotifications()" *ngIf="canSetNotifications()">Email Notifications</ng-template>
  <ng-template contextMenuItem (execute)="copyItems()" [enabled]="canCopyItems()">Copy to...</ng-template>
  <ng-template contextMenuItem (execute)="locatePDFs()" [enabled]="canLocatePDFs()">Locate PDFs</ng-template>
  <ng-template contextMenuItem (execute)="askAI()" [enabled]="true">Ask AI</ng-template>
  <ng-template contextMenuItem (execute)="updateDetails()" [enabled]="canUpdateDetails()">Update Details</ng-template>
  <ng-template contextMenuItem (execute)="deleteItems()" [enabled]="canDeleteItems()">Remove from List</ng-template>
  <ng-template contextMenuItem (execute)="linkFiles()" [enabled]="collection.user?.can_create_item" *ngIf="canLinkFiles()">Link Existing Files</ng-template>
  <ng-template contextMenuItem (execute)="exportPDFs()" [enabled]="canExportPDFs()">Export PDFs</ng-template>
  <ng-template contextMenuItem [subMenu]="literatureReviewSubMenu" *ngIf="canLiteratureReview()">Literature Review</ng-template>
</context-menu>

<context-menu #exportOptionsSubMenu>
  <ng-template contextMenuItem (execute)="exportToBIB()">BibTeX (.bib)</ng-template>
  <ng-template contextMenuItem (execute)="exportToRIS()">RefMan (.ris)</ng-template>
  <ng-template contextMenuItem (execute)="exportToCSV()">Comma-separated Values (.csv)</ng-template>
  <ng-template contextMenuItem (execute)="exportToXLSX()">Microsoft Excel (.xlsx)</ng-template>
</context-menu>

<context-menu #literatureReviewSubMenu>
  <ng-template contextMenuItem (execute)="addToLiteratureReview()">Add to Existing Project</ng-template>
  <ng-template contextMenuItem (execute)="shared.openLiteratureReview()">Go to Projects</ng-template>
  <ng-template contextMenuItem (execute)="linkToLiteratureReview()" [enabled]="collection.user?.can_create_item">Link to Project</ng-template>
</context-menu>

<common-navigation-button
  [routerLink]="['/library', node.list.collection_id, 'list', node.list.id]"
  routerLinkActive="active"
  [contextMenu]="contextMenuComponent"
  (click)="$event.stopPropagation()"

  [indent]="indent"
  [expandable]="node.children.length > 0"
  [(expanded)]="expanded"
  (expandedChange)="onExpandedChange($event)"

  [rcpDraggable]="canManageList()"
  rcpDragEffect="move"
  rcpDragType="list"
  [rcpDragData]="node"

  [rcpDroppable]="isDroppable.bind(this)"
  rcpDropOverClass="blink"
  (rcpDrop)="onDrop($event)"

  [ngClass]="{
    'disabled': disabled$ | async,
    'loading': loading$ | async
  }">
  <ng-container icon>
    <rcp-svg-icon name="folder"></rcp-svg-icon>
  </ng-container>
  <!--
  <i class="fa" [ngClass]="{
    'fa-folder': node.children.length && !expanded,
    'fa-folder-open': node.children.length && expanded,
    'fa-list': !node.children.length
  }"></i>
  -->
  {{node.list.name}}

  <ng-container addons>
    <common-navigation-button-addon *ngIf="loading$ | async">
      <fa-icon [icon]="['fas', 'spinner-third']" [spin]="true"></fa-icon>
    </common-navigation-button-addon>

    <common-navigation-button-addon *ngIf="!(loading$ | async)" (click)="openContextMenu($event)">
      <fa-icon [icon]="['fas', 'cog']"></fa-icon>
    </common-navigation-button-addon>
  </ng-container>

  <ng-template #expandedTemplate>
    <library-navigation-list
      *ngFor="let node of node.children; trackBy: listTrackByFn"
      [indent]="(indent||0)+1"
      [level]="level+1"
      [maxDepth]="maxDepth-1"
      [collection]="collection"
      [node]="node">
    </library-navigation-list>
  </ng-template>

</common-navigation-button>
