import { ErrorHandler, Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { DataUserService } from './common-data';
import { BUILD_TIME } from 'build';
import { TrackJS } from 'trackjs';
import { environment } from 'environment';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  buildTime = new Date(BUILD_TIME * 1000).toUTCString();

  constructor(
    protected dataUser: DataUserService
  ) {
    super();
  }

  handleError(error: any) {
    super.handleError(error);

    if (environment.trackJsEnabled) {
      TrackJS.addMetadata('Build Time', this.buildTime);
      this.dataUser.get().pipe(first()).subscribe({
        next: user => {
          TrackJS.addMetadata('User Role', user.role);
          TrackJS.addMetadata('User Verified', user.verified ? 'true' : 'false');
          TrackJS.addMetadata('User License', JSON.stringify(user.licence.active));
          TrackJS.track(error.originalError || error);
        },
        error: () => {
          TrackJS.track(error.originalError || error);
        }
      });
    }
  }
}
