import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LibrarySearchComponent } from './components/library-search.component';
import { PreviewComponent } from '../library-preview';

import {
  LibrarySidepanelDetailsComponent,
  LibrarySidepanelEditComponent,
  LibrarySidepanelExportComponent,
  LibrarySidepanelFilesComponent,
  LibrarySidepanelMetricsComponent,
  LibrarySidepanelNotesComponent,
  LibrarySidepanelResolveComponent,
  LibrarySidepanelShareComponent
} from '../library-sidepanel';

import { LibraryComponent } from './components/library.component';
import { LibraryCollectionComponent } from './components/library-collection.component';
import { LibraryCollectionRecentComponent } from './components/library-collection-recent.component';
import { LibraryCollectionFlaggedComponent } from './components/library-collection-flagged.component';
import { LibraryCollectionTaggedComponent } from './components/library-collection-tagged.component';
import { LibraryCollectionListComponent } from './components/library-collection-list.component';
import { LibraryCollectionSmartListComponent } from './components/library-collection-smartlist.component';
import { LibraryAiLandingComponent } from '../library-ai';

const sidepanelRoutes: Routes = [
  {
    path: 'details',
    component: LibrarySidepanelDetailsComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'share',
    component: LibrarySidepanelShareComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'notes',
    component: LibrarySidepanelNotesComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'metrics',
    component: LibrarySidepanelMetricsComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'files',
    component: LibrarySidepanelFilesComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'edit',
    component: LibrarySidepanelEditComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'resolve',
    component: LibrarySidepanelResolveComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'export',
    component: LibrarySidepanelExportComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'preview',
    component: PreviewComponent,
    outlet: 'bottompanel'
  },
  {
    path: 'resolve',
    component: PreviewComponent,
    outlet: 'bottompanel'
  }
];

const routes: Routes = [
  {
    path: '',
    component: LibraryComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'search'
      },
      {
        path: '',
        loadChildren: () => import('../library-navigation/library-navigation.module').then(m => m.AppLibraryNavigationModule),
        outlet: 'menu'
      },
      {
        path: 'search',
        component: LibrarySearchComponent,
        children: sidepanelRoutes
      },
      {
        path: 'ai/new',
        component: LibraryAiLandingComponent,
      },
      {
        path: ':collection_id',
        redirectTo: ':collection_id/all'
      },
      {
        path: ':collection_id/all',
        component: LibraryCollectionComponent,
        children: sidepanelRoutes
      },
      {
        path: ':collection_id/recent',
        component: LibraryCollectionRecentComponent,
        children: sidepanelRoutes
      },
      {
        path: ':collection_id/flagged',
        component: LibraryCollectionFlaggedComponent,
        children: sidepanelRoutes
      },
      {
        path: ':collection_id/tag/:tags',
        component: LibraryCollectionTaggedComponent,
        children: sidepanelRoutes
      },
      {
        path: ':collection_id/list/:list_id',
        component: LibraryCollectionListComponent,
        children: sidepanelRoutes
      },
      {
        path: ':collection_id/smartlist/:smartlist_id',
        component: LibraryCollectionSmartListComponent,
        children: sidepanelRoutes
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppLibraryRoutingModule { }
