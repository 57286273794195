import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { of as observableOf } from 'rxjs';

function validate(fieldName) {
  var re = /^[a-zA-Z0-9-_]+$/;
  return re.test(String(fieldName));
}

@Directive({
  selector: '[fieldNameValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FieldNameValidator),
      multi: true
    }
  ]
})
export class FieldNameValidator implements Validator {
  validate(control: FormControl) {
    const value = control.value;
    if (value) {
      return !validate(value) ? { fieldName: true } : null;
    }
    return observableOf(null);
  }
}
