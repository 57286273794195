import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';

import { IDataArticle } from '../../search-data';
import { environment } from 'environment';

export interface IDataDimensionsSearchParams {
  term: string;
  dois?: string[];
  limit?: string;
  checkDois?: boolean;
}

export interface IDataDimensionsResponse {
  publications: any[];
}

@Injectable()
export class DataDimensionsApiService {

  constructor(
    private http: HttpClient
  ) { }

  public search(params: IDataDimensionsSearchParams): Observable<Partial<IDataArticle>[]> {
    let query = '';
    if (!params.dois) {
      query = `search publications for ${JSON.stringify(params.term)} return publications[doi+pmid+pmcid] limit ${params.limit}`;
      const url = `${environment.baseUrls.contentServices}/search?query=${encodeURIComponent(query)}`;
      return this.http.get<IDataDimensionsResponse>(url).pipe(
        map(result => result.publications
          .filter(publication => !!publication.doi || !!publication.pmid || !!publication.pmcid)
          .map(publication => this.mapPublicationToArticle(publication))),
        catchError(err => of([]))
      );
    }


    let doiString = '';
    params.dois.forEach((doi, index) => {
      doiString = doiString + `"${doi}"` + (index < params.dois.length - 1 ? ',' : '');
    });

    if(params.checkDois){
      query = `search publications in full_data_exact for "a" where doi in [${doiString}] return publications[doi] limit ${environment.bulkRequestSize}`;
      const url = `${environment.baseUrls.contentServices}/search?query=${encodeURIComponent(query)}`;
      return this.http.get<IDataDimensionsResponse>(url).pipe(
        map(result => result.publications
          .filter(publication => !!publication.doi || !!publication.pmid || !!publication.pmcid)
          .map(publication => this.mapPublicationToArticle(publication))),
        catchError(err => of([]))
      );
    }

    query = `search publications for ${JSON.stringify(params.term)} where doi in [${doiString}] return publications[doi] limit ${environment.bulkRequestSize}`;
    const url = `${environment.baseUrls.contentServices}/search?query=${encodeURIComponent(query)}`;
    return this.http.get<IDataDimensionsResponse>(url).pipe(
      map(result => result.publications
        .filter(publication => !!publication.doi || !!publication.pmid || !!publication.pmcid)
        .map(publication => this.mapPublicationToArticle(publication))),
      catchError(err => of([]))
    );
  }

  private mapPublicationToArticle(publication: any): Partial<IDataArticle> {
    return {
      ext_ids: {
        doi: publication.doi,
        pmid: publication.pmid,
        pmcid: publication.pmcid
      }
    };
  }
}
