import { Component, Input, Output, ViewChild, TemplateRef, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { LoadNextEvent } from '@readcube/rcp-data-view';
import { ReplaySubject } from 'rxjs';

import { DataItemService, IDataCollection, IDataItem, IDataItemFile } from '../../library-data';
import { ExtensionService, ExtensionStatus } from '../../extension';
import { AppShellService } from '../../app-shell.service';

@Component({
  selector: 'library-results-list',
  templateUrl: './library-results-list.component.html',
  styleUrls: ['./library-results-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryResultsListComponent {
  @Input()
  items: IDataItem[];

  @Input()
  collection: IDataCollection;

  @Input()
  selected: IDataItem[];

  @Input()
  highlighted: IDataItem[];

  @Input()
  hasFocus = false;

  @Input()
  loading = false;

  @Input()
  finished = false;

  @Input()
  disabled = false;

  @Input()
  multiselect = true;

  @Output()
  open = new EventEmitter<IDataItem>();

  @Output()
  selectedChange = new EventEmitter<IDataItem[]>();

  @Output()
  highlightedChange = new EventEmitter<IDataItem[]>();

  @Output()
  hasFocusChange = new EventEmitter<boolean>();

  @Output()
  loadNext = new EventEmitter<LoadNextEvent>();

  @ViewChild('listRowTemplate', { static: true })
  listRowTemplate: TemplateRef<any>;

  constructor(
    public dataItem: DataItemService,
    public extension: ExtensionService,
    public shell: AppShellService
  ) { }

  getExtensionStatus(itemId: string): ReplaySubject<ExtensionStatus> {
    return this.extension.downloads.get(itemId)?.status$;
  }

  onLoadNext(event: LoadNextEvent) {
    if (this.finished || this.loading) {
      return;
    }
    this.loadNext.emit(event);
  }

  toggleFlag(item: IDataItem) {
    this.dataItem.update(item.collection_id, item.id, {
      user_data: { star: !item.user_data.star }
    });
  }

  setRating(item: IDataItem, rating: number) {
    this.dataItem.update(item.collection_id, item.id, {
      user_data: { rating: rating }
    });
  }

  setColorLabel(item: IDataItem, color: string) {
    this.dataItem.update(item.collection_id, item.id, {
      user_data: { color: color }
    });
  }

  getTitle(item: IDataItem) {
    return item.article.title.replace(/\${2,}/g, '$') || 'Untitled';
  }

  onHighlightItems(items: IDataItem[]) {
    this.highlightedChange.emit(items);
  }

  getArticleFile(item: IDataItem): IDataItemFile {
    return item.files[0];
  }

  isWaitingResolution(item: IDataItem): boolean {
    const a = this.getArticleFile(item);
    return a ? a.localWaitingResolution : false;
  }
}
