import { Injectable } from '@angular/core';
import { Observable, catchError, concatMap, finalize, firstValueFrom, from, lastValueFrom, map, of, takeUntil, tap, throwError, timeout } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { chunkify } from '@readcube/rcp-common';

import { CommonDialogProgressComponent, SharedService } from '../../common';
import { LibraryService } from '../../library';
import { DataItemService, DataListService, IDataItem, IDataList } from '../../library-data';
import { DataReviewService } from '../../review-data';

import { ImporterService } from '../../importer';
import { ExtensionService } from '../../extension';
import { AppShellService } from '../../app-shell.service';

import { IModalCopyItemsState, ModalCopyItemsComponent } from '../modals/modal-copy-items.component';
import { IModalBulkLocatePDFState, ModalBulkLocatePDFComponent } from '../modals/modal-bulk-locate-pdf.component';
import { IModalReviewState, ModalReviewComponent } from '../../common/modals/modal-review.component';
import { ModalBulkExportPDFComponent } from '../modals/modal-bulk-export-pdf.component';

import { environment } from 'environment';

export interface IBulkItemsCopyGroup {
  items: IDataItem[];
  collectionId: string;
  importOptions: IBulkItemImportOptions;
}

export interface IBulkItemImportOptions {
  copyUserData: boolean;
  mergeDuplicates: boolean;
  excludeFilesWithoutCopyright?: boolean;
  includeFiles?: boolean;
}

@Injectable()
export class BulkService {

  constructor(
    protected dataItem: DataItemService,
    protected dataList: DataListService,
    protected dataReview: DataReviewService,
    protected library: LibraryService,
    protected extension: ExtensionService,
    protected importer: ImporterService,
    protected modal: NgbModal,
    protected shared: SharedService,
    protected shell: AppShellService
  ) { }

  async bulkCopyItems(fromCollectionId: string, fromListId?: string): Promise<void> {
    return this.promptBulkCopyItems(fromCollectionId).then(async state => {
      if (!state.confirmed)
        return Promise.resolve();

      let totalProcessed = 0;
      let totalToProcess = 0;
      let totalMergedItemIds = [];
      let createdSublistId = null;
      let message = '';

      const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
      const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
      instance.title = 'Copy';
      instance.stoppable = true;

      const stream$ = this.dataItem.queryAll({ collection_id: fromCollectionId, list_id: fromListId }).pipe(
        concatMap(result => {
          totalProcessed = totalProcessed + result.rows.length;
          totalToProcess = result.total;

          const copyGroup: IBulkItemsCopyGroup = {
            items: result.rows,
            importOptions: state.importOptions,
            collectionId: state.fromCollectionId
          };

          return lastValueFrom(this.library.createCopiedItems(copyGroup, state.targetCollectionId, state.targetListId ? [state.targetListId] : []).pipe(
            // next line to avoid console error "Uncaught promise"
            tap(() => { })
          ));
        }),
        map(items => items.filter(item => item.merged).map(item => item.id)),
        concatMap(mergedIds => {
          totalMergedItemIds = totalMergedItemIds.concat(mergedIds);
          if (mergedIds.length && state.duplicatesSublist && !createdSublistId) {
            const listPayload: Partial<IDataList> = {
              collection_id: state.targetCollectionId,
              parent_id: state.targetListId,
              name: state.duplicatesSublist
            };

            return this.dataList.create(listPayload)
              .then(list => this.dataList.addItems(state.targetCollectionId, list.id, mergedIds)
                .then(list => createdSublistId = list?.id));
          } else if (createdSublistId) {
            return this.dataList.addItems(state.targetCollectionId, createdSublistId, mergedIds);
          } else {
            return of(null);
          }
        }),
        tap(list => {
          instance.progress$.next(totalToProcess ? (totalProcessed / totalToProcess * 100) : 100);

          message = `Processed <b>${totalProcessed}</b> out of <b>${totalToProcess}</b> items...`;
          if (state.importOptions.mergeDuplicates)
            message = message + `<br><b>${totalMergedItemIds.length}</b> duplicates merged.`;
          if (createdSublistId && totalMergedItemIds.length) {
            message = message + `<br>Duplicates will be saved to <b>${state.duplicatesSublist}</b> sub list.`;
            const uniqueMergedItems = new Set(totalMergedItemIds);
            if (uniqueMergedItems.size !== totalMergedItemIds.length)
              message = message + `<br><b>${uniqueMergedItems.size}</b> unique merge${(uniqueMergedItems.size > 1) ? 's' : ''} occured.`;
          }
          instance.message$.next(message);
        }),
        takeUntil(instance.stop$),
        finalize(() => instance.done$.next(true))
      );

      return lastValueFrom(stream$).then(() => Promise.resolve());
    });
  }

  async bulkUpdateDetails(collectionId: string, listId?: string): Promise<void> {
    return this.shell.openConfirm({
      title: 'Update Details',
      message: `Are you sure you want to update metadata for all the items in the selected ${listId ? 'list' : 'library'}?`
    }, async () => {
      let totalProcessed = 0;
      let totalToProcess = 0;

      const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
      const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
      instance.title = 'Update Details';
      instance.stoppable = true;

      const stream$ = this.dataItem.queryAll({ collection_id: collectionId, list_id: listId }).pipe(
        concatMap(result => {
          totalProcessed = totalProcessed + result.rows.length;
          totalToProcess = result.total;

          return lastValueFrom(this.dataItem.bulkResolve(collectionId, result.rows).pipe(
            // next line to avoid console error "Uncaught promise"
            tap(() => { })
          ));
        }),
        tap(() => {
          instance.progress$.next(totalProcessed / totalToProcess * 100);
          instance.message$.next(`Processed <b>${totalProcessed}</b> out of <b>${totalToProcess}</b> items...`);
        }),
        takeUntil(instance.stop$)
      );

      return lastValueFrom(stream$).then(() => modalRef.close());
    });
  }

  async bulkDeleteItems(collectionId: string, listId?: string): Promise<void> {
    return this.shell.openConfirm({
      title: 'Remove All Items from List',
      message: `Are you sure you want to remove all of the items from the selected ${listId ? 'list' : 'library'}?`
    }, async () => {
      let totalProcessed = 0;
      let totalToProcess = 0;

      const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
      const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
      instance.title = 'Delete';
      instance.stoppable = true;

      const stream$ = this.dataItem.queryAll({ collection_id: collectionId, list_id: listId }).pipe(
        concatMap(result => {
          totalProcessed = totalProcessed + result.rows.length;
          totalToProcess = result.total;
          if (!listId)
            return lastValueFrom(this.dataItem.bulkDelete(collectionId, result.rows.map(item => item.id)).pipe(
              // next line to avoid console error "Uncaught promise"
              tap(() => { })
            ));
          else
            return this.library.removeFromList(collectionId, listId, result.rows);
        }),
        tap(() => {
          instance.progress$.next(totalToProcess ? (totalProcessed / totalToProcess * 100) : 100);
          instance.message$.next(`Processed <b>${totalProcessed}</b> out of <b>${totalToProcess}</b> items...`);
        }),
        takeUntil(instance.stop$)
      );

      return lastValueFrom(stream$).then(() => {
        this.dataItem.reloadRows({ collectionId });
        setTimeout(() => modalRef.close(), totalToProcess && listId ? environment.delayBeforeItemsReload : 0);
      });
    });
  }

  async bulkExportPDFs(collectionId: string, listId?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalBulkExportPDFComponent, { backdrop: 'static' });
        const instance = <ModalBulkExportPDFComponent>modalRef.componentInstance;
        instance.collectionId = collectionId;
        instance.listId = listId;
        modalRef.result.then(resolve).catch(resolve);
      });
    });
  }

  async bulkLinkFiles(collectionId: string, listId?: string): Promise<void> {
    return this.shell.openConfirm({
      title: 'Link Existing Files',
      message: `Are you sure you want to link existing files for all the items in the selected ${listId ? 'list' : 'library'}?`
    }, async () => {
      let totalProcessed = 0;
      let totalToProcess = 0;

      const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
      const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
      instance.title = 'Link Existing Files';
      instance.stoppable = true;

      const stream$ = this.dataItem.queryAll({ collection_id: collectionId, list_id: listId }).pipe(
        concatMap(result => {
          totalProcessed = totalProcessed + result.rows.length;
          totalToProcess = result.total;
          const items = result.rows.filter(item => !item.primary_file_hash && this.library.isIdentified(item));

          return lastValueFrom(this.dataItem.bulkLinkFiles(collectionId, items).pipe(
            // next line to avoid console error "Uncaught promise"
            tap(() => { })
          ));
        }),
        tap(() => {
          instance.progress$.next(totalProcessed / totalToProcess * 100);
          instance.message$.next(`Processed <b>${totalProcessed}</b> out of <b>${totalToProcess}</b> items...`);
        }),
        takeUntil(instance.stop$)
      );

      return lastValueFrom(stream$).then(() => modalRef.close());
    });
  }

  async locateAllPDFs(collectionId: string, listId?: string, items: IDataItem[] = []): Promise<any> {
    if (!this.extension.isInstalled()) {
      this.extension.openInstallExtensionDialog();
      return;
    }

    const params = await this.promptBulkLocatePDF();
    if (!params.confirmed) return;

    const modal = await this.shell.showProgress();

    let failedListId: string;
    if (params.faliedListEnabled) {
      const lists = await firstValueFrom(this.dataList.query({ collection_id: collectionId }));
      failedListId = lists.find(list => list.name == params.failedListName)?.id;
    }

    let successCount = 0;
    let failedCount = 0;
    const locateFileForItems$ = (items: IDataItem[], total: number) => {

      return from(items).pipe(

        // Initialize synchronous extension locate PDF.
        concatMap(async item => {

          const locateProgress$ = this.extension
            .downloadPDFWithProgress({ doi: item.ext_ids.doi })
            .pipe(
              timeout({
                each: environment.bulkExtensionTimeout,
                with: () => throwError(() => {
                  return {
                    status: 'failed',
                    error: 'timed_out',
                    doi: item.ext_ids.doi
                  };
                })
              }),
              catchError(err => of(err))
            );

          // Add downloaded file buffer to importer.
          return lastValueFrom(locateProgress$).then(event => {
            switch (event.status) {
              case 'download_completed':
                successCount++;
                break;
              default:
                failedCount++;
                return event;
            }
            const fileName = this.extension.getFileNameFor(item);
            const fileBlobs = this.extension.getDownloadedFile(event, fileName);

            // Import will be queued and runs independently from this stream.
            this.importer.addFile(fileBlobs, item.collection_id, null, item.id);
            this.importer.start();
            return event;
          });
        }),

        // Add failed items to sublist.
        concatMap(event => {
          const failedItemIds = items
            .filter(i => i.ext_ids.doi == event.doi && event.status == 'failed')
            .map(i => i.id);

          if (failedItemIds.length && params.failedListName && !failedListId) {
            return this.dataList.create({
              collection_id: collectionId,
              parent_id: listId,
              name: params.failedListName
            }).then(list => {
              failedListId = list.id;
              return this.dataList.addItems(list.collection_id, list.id, failedItemIds)
                .then(() => event);
            });
          } else if (failedListId) {
            return this.dataList.addItems(collectionId, failedListId, failedItemIds)
              .then(() => event);
          } else {
            return of(event);
          }
        }),

        takeUntil(modal.stop$),

        tap(() => {
          const totalCount = total;
          const finishedCount = successCount + failedCount;
          const percentDone = finishedCount / totalCount * 100;

          modal.stoppable = true;
          modal.progress$.next(percentDone);
          modal.message$.next((() => {
            if (!failedCount && successCount) {
              return `Successfuly located <b class="text-success">${successCount}</b> out of <b>${totalCount}</b> files${percentDone == 100 ? '.' : '...'}`;
            } else if (failedCount && !successCount) {
              return `Failed to locate <b class="text-danger">${failedCount}</b> out of <b>${totalCount}</b> files${percentDone == 100 ? '.' : '...'}`;
            } else if (failedCount && successCount) {
              return `Successfuly located <b class="text-success">${successCount}</b> and failed to locate <b class="text-danger">${failedCount}</b> out of <b>${totalCount}</b> files${percentDone == 100 ? '.' : '...'}`;
            }
            return 'Please wait...';
          })());
        })
      );
    };

    let stream$: Observable<any>;
    if (items?.length) {
      if (params.skipItemsWithFile) {
        items = items.filter(item => !item.primary_file_hash);
      }
      stream$ = locateFileForItems$(items, items.length);
    } else {
      const query = params.skipItemsWithFile ? '_exists_:doi AND NOT(_exists_:files)' : '_exists_:doi';
      stream$ = this.dataItem.queryAll({ collection_id: collectionId, list_id: listId, query }).pipe(
        concatMap(result => locateFileForItems$(result.rows, result.total)),
        takeUntil(modal.stop$),
      );
    }
    return lastValueFrom(stream$).then(() => modal.done$.next(true));
  }

  async bulkLiteratureReview(collectionId: string, listId: string, items?: IDataItem[]): Promise<void> {
    return this.promptLiteratureReview().then(async state => {
      if (!state.confirmed)
        return Promise.resolve();

      let totalProcessed = 0;
      let totalToProcess = 0;

      const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
      const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
      instance.title = 'Add to Literature Review Project';
      instance.stoppable = true;

      const items$ = items?.length
        ? from(chunkify(items, environment.bulkRequestSize)).pipe(map(i => {
          return { total: items.length, rows: i };
        }))
        : this.dataItem.queryAll({ collection_id: collectionId, list_id: listId });

      const stream$ = items$.pipe(
        concatMap(result => {
          totalProcessed = totalProcessed + result.rows.length;
          totalToProcess = result.total;

          const ids = result.rows.map(item => item.id);
          if (!ids?.length)
            return of({});

          return this.dataReview.addToProject({
            project_id: state.projectId,
            collection_id: collectionId,
            ids,
            source: state.source,
            labels: state.labels
          });
        }),
        tap(() => {
          instance.progress$.next(totalProcessed / totalToProcess * 100);
          instance.message$.next(`Processed <b>${totalProcessed}</b> out of <b>${totalToProcess}</b> items...`);
        }),
        takeUntil(instance.stop$)
      );

      return lastValueFrom(stream$).then(() => modalRef.close());
    });
  }

  protected async promptLiteratureReview<T = IModalReviewState>(): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalReviewComponent, { backdrop: 'static' });
        modalRef.result.then(resolve);
      });
    });
  }

  protected async promptBulkCopyItems<T = IModalCopyItemsState>(fromCollectionId: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalCopyItemsComponent, { backdrop: 'static' });
        const instance = <ModalCopyItemsComponent>modalRef.componentInstance;
        instance.fromCollectionId = fromCollectionId;
        instance.hasDestinationSelect = true;
        modalRef.result.then(resolve);
      });
    });
  }

  protected async promptBulkLocatePDF<T = IModalBulkLocatePDFState>(): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalBulkLocatePDFComponent, { backdrop: 'static', keyboard: false });
        const instance = <ModalBulkLocatePDFComponent>modalRef.componentInstance;
        modalRef.result.then(resolve);
      });
    });
  }

  protected saveBlobParts(fileName: string, entries: BlobPart[], type: string = 'text/plain;charset=utf-8') {
    const blob = new Blob(entries, { type });
    saveAs(blob, fileName);
  }
}
