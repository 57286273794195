import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataCollectionService, DataListService } from '../../library-data';
import { EXPORT_TYPE_ID, IExportType } from '../services/exporter.service';

@Component({
  templateUrl: './exporter-destination-select.component.html',
  selector: 'rcp-exporter-destination-select',
})
export class ExporterDestinationSelectComponent {
  @Input()
  exportType: EXPORT_TYPE_ID;

  @Output()
  exportTypeChange = new EventEmitter<EXPORT_TYPE_ID>();

  @Input()
  availableExportTypes: IExportType[] = [];

  constructor(
    public dataCollection: DataCollectionService,
    public dataList: DataListService,
  ) { }

  onTypeChange(event: HTMLInputEvent) {
    this.exportTypeChange.next(this.exportType);
  }
}
