<ng-template #colorLabelHeadTemplate let-column="column">
  <div class="head-icon align-center">
    <fa-icon [icon]="['fal', 'circle']"></fa-icon>
  </div>
</ng-template>

<ng-template #flagHeadTemplate let-column="column">
  <div class="head-icon align-center">
    <fa-icon [icon]="['fal', 'flag']"></fa-icon>
  </div>
</ng-template>

<ng-template #fileHeadTemplate let-column="column">
  <div class="head-icon align-center">
    <fa-icon [icon]="['fal', 'paperclip']"></fa-icon>
  </div>
</ng-template>

<ng-template #colorLabelCellTemplate let-item="item">
  <div class="cell-icon align-center">
    <common-color-picker
      class="icon"
      [showEmpty]="false"
      [disabled]="disabled"
      [color]="item.user_data.color"
      (colorChange)="setColorLabel(item, $event)"
      (mousedown)="$event.stopPropagation()">
    </common-color-picker>
  </div>
</ng-template>

<ng-template #flagCellTemplate let-item="item">
  <div class="cell-icon align-center">
    <common-toggle-flag
      class="icon"
      [showEmpty]="false"
      [disabled]="disabled"
      [value]="item.user_data.star"
      (valueChange)="toggleFlag(item)"
      (mousedown)="$event.stopPropagation()">
    </common-toggle-flag>
  </div>
</ng-template>

<ng-template #fileCellTemplate let-item="item">
  <div class="cell-icon align-center" *ngIf="!item.primary_file_hash">
    <ng-container [ngSwitch]="getExtensionStatus(item.ext_ids?.doi) | async">
      <fa-icon *ngSwitchCase="'started'" [icon]="['fas', 'sync']" [spin]="true" ngbTooltip="Searching for file..." container="body" ></fa-icon>
      <fa-icon *ngSwitchCase="'downloading'" [icon]="['fas', 'download']" ngbTooltip="Downloading file..." container="body"></fa-icon>
      <span class="pointer" (click)="extension.openDownloadFailedDialog(item.ext_ids?.doi, item)" ngbTooltip="Failed to locate PDF" container="body">
        <fa-icon *ngSwitchCase="'failed'" [icon]="['fas', 'exclamation-triangle']" class="text-danger"></fa-icon>
      </span>
    </ng-container>
  </div>

  <div class="cell-icon align-center">
    <fa-icon [icon]="['fal', 'paperclip']" *ngIf="item.primary_file_hash"></fa-icon>
  </div>
</ng-template>

<ng-template #authorsCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}">{{(item.import_data?.source === 'janssen_endnote') ? ((item.article.authors | formatCommaFirstAuthor)) : (item.article.authors | formatFirstAuthor)}}</span>
</ng-template>

<ng-template #lastAuthorCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}">{{(item.import_data?.source === 'janssen_endnote') ? ((item.article.authors | formatCommaLastAuthor)) : (item.article.authors | formatLastAuthor)}}</span>
</ng-template>

<ng-template #titleCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}">
    <ng-container *ngIf="item.article.title else titleNoneTemplate">
      <rcp-katex-paragraph [paragraph]="item.article.title" [forceInline]="true"></rcp-katex-paragraph>
    </ng-container>
    <ng-template #titleNoneTemplate><i>Untitled</i></ng-template>
  </span>
</ng-template>

<ng-template #libraryCellTemplate let-item="item">
  <a href (click)="shared.viewInLibrary(item.collection_id, item.id); $event.preventDefault()">{{collectionNames?.get(item.collection_id)}}</a>
</ng-template>

<ng-template #typeCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}">{{getItemType(item)}}</span>
</ng-template>

<ng-template #journalCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}">{{item.article.journal || ''}}</span>
</ng-template>

<ng-template #yearCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}">{{item.article.year || ''}}</span>
</ng-template>

<ng-template #ratingCellTemplate let-item="item">
  <div class="cell-icon">
    <common-stars-rating
      [disabled]="disabled"
      [rating]="item.user_data.rating"
      (ratingChange)="setRating(item, $event)"
      (mousedown)="$event.stopPropagation()"
      (click)="$event.stopPropagation()">
    </common-stars-rating>
  </div>
</ng-template>

<ng-template #notesCellTemplate let-item="item">
  <span>{{item.user_data.notes}}</span>
</ng-template>

<ng-template #createdDateCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}" *ngIf="item.user_data.created">
    {{item.user_data.created | date:'medium'}}
  </span>
</ng-template>

<ng-template #modifiedDateCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}" *ngIf="item.user_data.modified">
    {{item.user_data.modified | date:'medium'}}
  </span>
</ng-template>

<ng-template #createdByCellTemplate let-item="item">
  <span *ngIf="item.user_name">{{item.user_name}}</span>
</ng-template>

<ng-template #lastReadDateCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}" *ngIf="item.user_data.last_read">
    {{item.user_data.last_read | date:'medium'}}
  </span>
</ng-template>

<ng-template #patentIdCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}" *ngIf="item.ext_ids?.patent_id">
    {{item.ext_ids.patent_id}}
  </span>
</ng-template>

<ng-template #patentPublicationDateCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}" *ngIf="item.item_type === 'patent' && item.custom_metadata?.publication_date">
    {{item.custom_metadata.publication_date | date:'mediumDate'}}
  </span>
</ng-template>

<ng-template #patentFilingDateCellTemplate let-item="item">
  <span [ngClass]="{'fw-bold': item.user_data.unread}" *ngIf="item.item_type === 'patent' && item.custom_metadata?.filing_date">
    {{item.custom_metadata.filing_date | date:'mediumDate'}}
  </span>
</ng-template>

<ng-template #copyrightStatusCellTemplate let-item="item">
  <div class="cell-icon align-center">
    <ng-container [ngSwitch]="item?.user_data?.copyright_status">
      <fa-icon *ngSwitchCase="'covered'" [icon]="['fas', 'circle']" style="color: #00d127"></fa-icon>
      <fa-icon *ngSwitchCase="'not_covered'" [icon]="['fas', 'circle']" style="color: #fe3018"></fa-icon>
    </ng-container>
  </div>
</ng-template>

<ng-template #customFieldCellTemplate let-item="item" let-column="column">
  {{getCustomFieldValue(item, column)}}
</ng-template>

<data-view-table
  [items]="items"
  [itemSize]="32"
  [columns]="columns"
  [multiselect]="multiselect"
  (columnsChange)="onColumsChange($event)"
  (open)="open.next($event)"
  [(selected)]="selected"
  [highlighted]="highlighted"
  [(hasFocus)]="hasFocus"
  (selectedChange)="selectedChange.emit($event)"
  (highlightedChange)="onHighlightItems($event)"
  (hasFocusChange)="hasFocusChange.emit($event)"
  (headClick)="onTableHeadClick($event)"
  (loadNext)="onLoadNext($event)">

  <common-loader-circles class="loader" [ngClass]="{'visible': loading}"></common-loader-circles>
</data-view-table>
