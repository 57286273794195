import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { of as observableOf } from 'rxjs';

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

@Directive({
  selector: '[emailValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailValidator),
      multi: true
    }
  ]
})
export class EmailValidator implements Validator {
  validate(control: FormControl) {
    const value = control.value;
    
    if (value instanceof Array) {
      const invalidEmails = value.filter(email => !validateEmail(email));
      return invalidEmails.length ? { email: invalidEmails } : null;
    } else if (value) {
      return !validateEmail(value) ? { email: true } : null;
    }
    return observableOf(null);
  }
}
