import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'common-loader-circles',
  templateUrl: './common-loader-circles.component.html',
  styleUrls: ['./common-loader-circles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonLoaderCirclesComponent {

}
