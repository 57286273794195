import {
  Component,
  Input,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'common-dropzone',
  templateUrl: './common-dropzone.component.html',
  styleUrls: ['./common-dropzone.component.scss']
})
export class CommonDropzoneComponent {
  @Input()
  text?: string;

  @HostBinding('class.active')
  active = false;
}
