import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concatMap, finalize, tap } from 'rxjs/operators';

import { IBulkItemImportOptions } from './bulk.service';
import { SharedService, DialogService, CommonDialogProgressComponent } from '../../common';
import { DataItemService, DataListService, IDataItem } from '../../library-data';
import { ModalImportErrorComponent } from '../modals/modal-import-error.component';

@Injectable()
export class BulkIdentifiersService {
  constructor(
    protected dataItem: DataItemService,
    protected dataList: DataListService,
    protected shared: SharedService,
    protected modal: NgbModal,
    protected dialog: DialogService,
  ) { }

  validateDOI(value: string) {
    const re = /^(doi:)?10.\d{4,9}\/[-._;()/:A-Z0-9<>+\[\]]+(?:(?<=-)#)?$/i;
    return re.test(value);
  }
  
  validatePMID(value: string) {
    const re = /^(pmid:)?\d{2,8}$/i;
    return re.test(value);
  }
  
  validateArxiv(value: string) {
    const re = /(^(arxiv:)?\d{4}\.\d{4,6}v?\d*$)|(^[-a-z]+\/\d{7}v?\d*$)/i;
    return re.test(value);
  }
  
  validateUrl(value: string) {
    return value.split('readcube.com/library/')[1];
  }

  openBulkImportIdentifiers(collectionId: string, listId?:string) {
    this.dialog.openDialog(['identifiers'], { collectionId, listId });
  }

  async importFromIdentifiers(ids: string[], collectionId: string, listId: string = null, availableCollections: string[], params: IBulkItemImportOptions): Promise<void> {
    const { items, invalidIds } = this.parseIdentifiers(ids, availableCollections);
    let processingMessage = '';
    let duplicatesCount = 0;
    let duplicatesMessage = '';

    const modalRef = this.modal.open(CommonDialogProgressComponent, { backdrop: 'static', keyboard: false });
    const instance = <CommonDialogProgressComponent>modalRef.componentInstance;
    instance.title = 'Import from Identifiers';
    instance.stoppable = true;

    const itemsToCreate: Partial<IDataItem>[] = [];
    const itemsToCopy: Partial<IDataItem>[] = [];
    const itemsToAddToList: Partial<IDataItem>[] = [];

    items.forEach(item => {
      if (!item.collection_id)
        itemsToCreate.push(item);
      else if (item.collection_id !== collectionId)
        itemsToCopy.push(item);
      else if (listId)
        itemsToAddToList.push(item);
    });

    const stream$ = of(itemsToCreate).pipe(
      concatMap(items => {
        const itemsPayload = items.map(item => {
          return {
            ...item,
            merge: params.mergeDuplicates,
            skip_merge: !params.mergeDuplicates,
            resolve: true,
            skip_resolve: false
          };
        });
        return lastValueFrom(this.dataItem.bulkPost(collectionId, itemsPayload, listId).pipe(
          tap(r => {
            instance.message$.next(`Processed <b>${r.data.length}</b> out of <b>${items.length}</b> identifiers containing DOI, PMID or arXiv.`);
            instance.progress$.next(r.percent);
          })
        )).then(r => {
          duplicatesCount = duplicatesCount + r.data.filter(item => item.merged).length;
          duplicatesMessage =  duplicatesCount ? `<br><b>${duplicatesCount}</b> duplicate${duplicatesCount > 1 ? 's' : ''} merged.` : '';
          processingMessage = processingMessage + `Processed <b>${r.data.length}</b> out of <b>${items.length}</b> identifiers containing DOI, PMID or arXiv.`;
          instance.message$.next(processingMessage + duplicatesMessage);
        });
      }),
      concatMap(() => {
        if (!itemsToCopy.length)
          return of(null);

        const ids = itemsToCopy.map(item => `${item.collection_id}:${item.id}`);

        const copyFiles: 'all' | 'copyright_covered' = params.includeFiles
          ? (params.excludeFilesWithoutCopyright && this.shared.user?.licence?.copyright_show_status ? 'copyright_covered' : 'all')
          : null;
        const importOptions = {
          copy_user_data: params.copyUserData,
          copy_files: copyFiles,
          merge: params.mergeDuplicates,
        };

        return lastValueFrom(this.dataItem.bulkCopy(ids, collectionId, listId ? [listId] : null, importOptions).pipe(
          tap(r => {
            instance.message$.next(`Processed <b>${r.data.length}</b> out of <b>${itemsToCopy.length}</b> identifiers containing Papers URL from other libraries.`);
            instance.progress$.next(r.percent);
          })
        )).then(r => {
          duplicatesCount = duplicatesCount + r.data.filter(item => item.merged).length;
          duplicatesMessage = duplicatesCount ? `<br><b>${duplicatesCount}</b> duplicate${duplicatesCount > 1 ? 's' : ''} merged.` : '';
          processingMessage = processingMessage + `<br>Processed <b>${r.data.length}</b> out of <b>${itemsToCopy.length}</b> identifiers containing Papers URL from other libraries.`;
          instance.message$.next(processingMessage + duplicatesMessage);
        });
      }),
      concatMap(() => {
        if (!itemsToAddToList.length)
          return of(null);

        return this.dataList.addItems(collectionId, listId, itemsToAddToList.map(item => item.id)).then(() => {
          processingMessage = processingMessage + `<br>Processed <b>${itemsToAddToList.length}</b> out of <b>${itemsToAddToList.length}</b> identifiers containing Papers URL from this library.`;
          instance.message$.next(processingMessage + duplicatesMessage);
          instance.progress$.next(100);
        });
      }),
      finalize(() => instance.done$.next(true))
    );

    return firstValueFrom(stream$).then(() => {
      this.dataItem.reloadRows({ collectionId: collectionId });
      if (invalidIds?.length) {
        modalRef.close();
        const errorModalRef = this.modal.open(ModalImportErrorComponent, { backdrop: true });
        const modalComponent = <ModalImportErrorComponent>errorModalRef.componentInstance;
        modalComponent.identifierError = {
          message: processingMessage + duplicatesMessage,
          invalidIds: invalidIds
        };
        return errorModalRef.result.catch(err => { });
      }
      return Promise.resolve();
    });
  }

  protected parseIdentifiers(ids: string[], availableCollections: string[]): { items: Partial<IDataItem>[], invalidIds: string[]; } {
    const items: Partial<IDataItem>[] = [];
    const invalidIds: string[] = [];

    ids.forEach(id => {
      if (this.validateDOI(id)) {
        items.push({ ext_ids: { doi: id } });
        return;
      }
      if (this.validatePMID(id)) {
        items.push({ ext_ids: { pmid: id } });
        return;
      }
      if (this.validateArxiv(id)) {
        items.push({ ext_ids: { arxiv: id } });
        return;
      }
      if (this.validateUrl(id)) {
        const item: Partial<IDataItem> = {
          collection_id: this.validateUrl(id).split(':')[0],
          id: this.validateUrl(id).split(':')[1]
        };
        if (item.id && item.collection_id && availableCollections.includes(item.collection_id)) {
          items.push(item);
          return;
        }
        else {
          invalidIds.push(id);
          return;
        }
      }

      invalidIds.push(id);
    });

    return { items, invalidIds };
  }
}
