import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppShellService } from '../../app-shell.service';
import { ExtensionService } from '../services/extension.service';
import { SharedService } from '../../common';
import { IDataItem } from '../../library-data';
import { environment } from 'environment';

@Component({
  templateUrl: './modal-extension-download-failed.component.html'
})
export class ModalExtensionDownloadFailedComponent {
  doi: string;
  item?: IDataItem;

  constructor(
    public activeModal: NgbActiveModal,
    public extension: ExtensionService,
    public shared: SharedService,
    public shell: AppShellService
  ) { }

  get isUsingProxy(): boolean {
    return !!this.shared.user.proxy_url;
  }

  viewArticlePage() {
    this.shell.openURL(`${environment.baseUrls.readcube}/articles/${this.doi}`);
  }

  openProxySettings() {
    this.shell.openURL(environment.baseUrls.readcube + '/account');
  }

  loginWithProxy() {
    const url = this.getProxyURL(`${environment.baseUrls.readcube}/articles/${this.doi}`);
    this.shell.openURL(url);
  }

  getProxyURL(url: string): string {
    return (this.shared.user.proxy_url || '').replace('%@', url);
  }

  close() {
    this.activeModal.close();
  }
}
