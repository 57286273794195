import { Component } from '@angular/core';

@Component({
  templateUrl: './navigation-drawer-review.component.html',
  styleUrls: ['./navigation-drawer.component.scss']
})
export class NavigationDrawerReviewComponent {
  headerItems = [
    {
      name: 'Projects',
      icon: 'home',
    }
  ];

  // hardcoded for now
  savedProjects = [
    {
      title: 'Abdominal Wall Reinforcement Rev B SOTA',
      icon: 'project',
    }
  ];

  dueSoonList = [
    {
      title: 'Ability Stent Rev A SOTA',
      notification: '1 day left ',
      icon: 'project',
    },
    {
      title: 'Absorbable staples CER Memory Foam With Granular Expansion',
      notification: '3 days left ',
      icon: 'project',
    }
  ];

  constructor(
  ) { }

}
