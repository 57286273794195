import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';

import { UserMenuComponent } from './components/user-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule
  ],
  declarations: [
    UserMenuComponent
  ],
  exports: [
    UserMenuComponent
  ],
  providers: [
  ]
})
export class AppUserMenuModule {
  static forRoot(options: any): ModuleWithProviders<AppUserMenuModule> {
    return {
      ngModule: AppUserMenuModule,
      providers: [

      ]
    };
  }
}
