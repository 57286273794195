import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularSplitModule } from 'angular-split';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeDragAndDropModule } from '@readcube/rcp-drag-and-drop';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';

import { AppCommonModule } from '../common';
import { AppQueryMenuModule } from '../query-builder';
import { AppImporterModule } from '../importer';
import { AppLibraryMenuModule } from '../library-menu';
import { AppLibraryResultsModule } from '../library-results';
import { AppLibraryPreviewModule } from '../library-preview';
import { AppLibraryNavigationModule } from '../library-navigation';
import { AppLibrarySidepanelModule } from '../library-sidepanel';
import { AppReviewDataModule } from '../review-data';
import { AppLibraryAiModule } from '../library-ai';

import { AppLibraryRoutingModule } from './library-routing.module';

import { LibraryComponent } from './components/library.component';
import { LibraryCollectionComponent } from './components/library-collection.component';
import { LibraryCollectionRecentComponent } from './components/library-collection-recent.component';
import { LibraryCollectionFlaggedComponent } from './components/library-collection-flagged.component';
import { LibraryCollectionTaggedComponent } from './components/library-collection-tagged.component';
import { LibraryCollectionListComponent } from './components/library-collection-list.component';
import { LibraryCollectionSmartListComponent } from './components/library-collection-smartlist.component';
import { LibrarySearchComponent } from './components/library-search.component';
import { LibraryHeaderComponent } from './components/library-header.component';

import { LibraryService } from './services/library.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    AngularSplitModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
    ReadCubeDragAndDropModule,
    ReadCubeSvgIconsModule,
    AppCommonModule,
    AppQueryMenuModule,
    AppImporterModule,
    AppLibraryMenuModule,
    AppLibraryRoutingModule,
    AppLibraryResultsModule,
    AppLibraryPreviewModule,
    AppLibraryNavigationModule,
    AppLibrarySidepanelModule,
    AppLibraryAiModule
  ],
  declarations: [
    LibraryComponent,
    LibraryHeaderComponent,
    LibrarySearchComponent,
    LibraryCollectionComponent,
    LibraryCollectionRecentComponent,
    LibraryCollectionFlaggedComponent,
    LibraryCollectionTaggedComponent,
    LibraryCollectionListComponent,
    LibraryCollectionSmartListComponent,
  ],
  providers: [
    LibraryService
  ]
})
export class AppLibraryModule { }
