import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  HostBinding,
  ChangeDetectionStrategy
} from '@angular/core';

import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'common-color-picker',
  styleUrls: ['./common-color-picker.component.scss'],
  templateUrl: './common-color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonColorPickerComponent {
  @Input()
  color: string;

  @Input()
  showEmpty = true;

  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Output()
  colorChange = new EventEmitter<string>();

  @ViewChild('p', { static: true })
  popover: NgbPopover;

  constructor(
    public elementRef: ElementRef
  ) { }

  showBrushes(event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    if (this.popover.isOpen()) {
      this.popover.close();
    } else {
      this.popover.open();
    }
  }

  setColor(color: string) {
    this.color = color;
    this.colorChange.emit(color);
    this.popover.close();
  }
}
