import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge, mergeMap, of, shareReplay, tap } from 'rxjs';
import { IReviewProject, DataReviewService } from '../../review-data';

export interface IModalReviewState {
  confirmed: boolean;
  projectId: number;
  source: string;
  labels: string[];
}

@Component({
  templateUrl: './modal-review-link.component.html'
})
export class ModalReviewLinkComponent {

  collectionId: string;
  listId: string;
  selectedProject: IReviewProject;
  projects$: Observable<IReviewProject[]>;

  @ViewChild('projectSelectTypeahead', { static: false })
  projectSelectTypeahead: NgbTypeahead;
  projectFocus$ = new Subject<string>();
  projectClick$ = new Subject<string>();
  selectProjectFormatter = (project: any) => project.name;
  selectProjectSearch: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.projectClick$.pipe(filter(() => !this.projectSelectTypeahead.isPopupOpen()));
    const inputFocus$ = this.projectFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      mergeMap(term => this.projects$.pipe(
        map(projects => projects.filter(v => !term || v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )),
    );
  };

  sources = ['embase', 'ovid', 'pubmed', 'other'];
  labels = [];
  labelsText = '';
  selectedSource: string = null;
  searchLabels$: (text$: Observable<string>) => Observable<string[]>;

  constructor(
    public activeModal: NgbActiveModal,
    public dataReview: DataReviewService,
  ) { }

  ngOnInit() {
    this.projects$ = this.dataReview.getProjects().pipe(
      tap(projects => {
        this.dataReview.getProjectLink(this.collectionId, this.listId).subscribe(response => {
          if (response?.review_project_id) {
            this.selectedSource = response.source;
            this.labels = response.labels;
            this.selectedProject = projects.find(project => project.id === response.review_project_id);
          }
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.searchLabels$ = (text$: Observable<string>) => text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),

      mergeMap(term => {
        if (!term.length || term.length < 2) {
          return of(this.selectedProject?.labels || []);
        }
        term = term.toLowerCase();
        return of(this.selectedProject?.labels?.filter(l => l.toLocaleLowerCase().includes(term)) || []);
      }));
  }

  submit() {
    this.dataReview.linkToProject({
      collection_id: this.collectionId,
      list_id: this.listId,
      review_project_id: this.selectedProject?.id || null,
      source: this.selectedSource,
      labels: this.labels
    }).subscribe(() => {
      this.activeModal.close();
    });
  }

  cancel() {
    this.activeModal.close();
  }
}
