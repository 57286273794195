<form #notificationsForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">Email Notifications</h4>
  </div>
  
  <div class="modal-body">

    Configure email notifications whenever new content is added to <b>{{list ? list.name : (collection.name || 'My Papers')}}</b>:

    <div class="form-floating mt-3">
      <select 
        name="notification-select" 
        class="form-select"
        [(ngModel)]="frequency">
        <option [ngValue]="f.value" *ngFor="let f of frequencies">{{f.name}}</option>
      </select>
      <label for="notification-select">Settings</label>
    </div>

  </div>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!notificationsForm.form.valid || (loading$ | async)">Save</button>
  </div>

</form>