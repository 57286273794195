import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IDataCollectionCustomField, IDataCollectionField } from '../../library-data';
import { QueryFormData, QueryFormValue, QueryFormValueType, QueryOperator } from '../services/query-builder.service';
import { FieldInputCapabilities, FieldInputOptions } from './query-field-value.component';

@Component({
  selector: 'query-builder',
  templateUrl: './query-builder.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QueryMenuComponent),
      multi: true
    }
  ]
})
export class QueryMenuComponent implements ControlValueAccessor {
  readonly QueryOperator = QueryOperator;

  form: FormGroup;
  value: QueryFormData = {
    words: [],
    fields: [],
  };
  onChange: (str: QueryFormData) => any;
  onTouched: () => any;

  @Input()
  fields: IDataCollectionField[] = [];

  @Input()
  customFields: IDataCollectionCustomField[] = [];

  @Input()
  fieldInputCapabilities: FieldInputCapabilities = {};

  @Input()
  fieldDefaultInputCapabilities: { [key: string]: QueryFormValueType } = {};

  @Input()
  fieldInputOptions: FieldInputOptions = {};

  @ViewChildren('wordValue')
  wordValues: QueryList<{ focus: () => void }>;

  @ViewChildren('fieldValue')
  fieldValues: QueryList<{ focus: () => void }>;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: QueryFormData) {
    this.value = value;
    this.form = this.createFormGroup(value);
  }

  createFormGroup(data: QueryFormData): FormGroup {
    const words = data?.words.map(field => new FormGroup({
      'operator': new FormControl(field.operator),
      'value': new FormControl(field.value),
    }));
    const fields = data?.fields.map(field => new FormGroup({
      'operator': new FormControl(field.operator),
      'field': new FormControl(field.field),
      'value': new FormControl(field.value),
    }));
    const form = new FormGroup({
      'words': new FormArray(words || []),
      'fields': new FormArray(fields || []),
    });
    form.valueChanges.subscribe((value: QueryFormData) => {
      this.value = value;
      this.onChange(value);
      this.onTouched();
    });
    return form;
  }

  addWords(index = 0, operator = QueryOperator.And) {
    const formArray = this.form.controls['words'] as FormArray;
    const formGroup = new FormGroup({
      'operator': new FormControl(operator),
      'value': new FormControl(this.getDefaultWordsValue())
    });
    formArray.insert(index, formGroup, { emitEvent: true });
  }

  addField(index = 0, operator = QueryOperator.And) {
    const formArray = this.form.controls['fields'] as FormArray;
    const field = this.getDefaultField();
    const fieldValue = this.getDefaultFieldValue(field);
    const formGroup = new FormGroup({
      'operator': new FormControl(operator),
      'field': new FormControl(field),
      'value': new FormControl(fieldValue)
    });
    formArray.insert(index, formGroup, { emitEvent: true });
  }

  removeWords(index: number) {
    const formArray = this.form.controls['words'] as FormArray;
    formArray.removeAt(index, { emitEvent: true });
    this.wordValues.get(index-1)?.focus();
  }

  removeField(index: number) {
    const formArray = this.form.controls['fields'] as FormArray;
    formArray.removeAt(index, { emitEvent: true });
    this.fieldValues.get(index-1)?.focus();
  }

  findSelectedField(key: string): IDataCollectionField | IDataCollectionCustomField {
    return this.fields.find(f => f.field === key) || this.customFields.find(f => f.field === key);
  }

  updateFormGroupValue(field: IDataCollectionField | IDataCollectionCustomField, index: number) {
    const formArray = this.form.controls['fields'] as FormArray;
    formArray.at(index).get('value').patchValue({
      type: this.getDefaultFieldInputType(field),
      input_text: this.getDefaultFieldInputText(field),
      input_bool: true,
      input_range: [null, null],
    });
  }

  protected getDefaultField(): IDataCollectionField | null {
    if (this.fields.length) {
      return this.fields[0];
    }
    return null;
  }

  protected getDefaultWordsValue(): QueryFormValue {
    return {
      type: 'is',
      input_text: ''
    };
  }

  protected getDefaultFieldValue(field: IDataCollectionField): QueryFormValue {
    return {
      type: this.getDefaultFieldInputType(field),
      input_text: this.getDefaultFieldInputText(field),
      input_bool: true,
      input_range: [null, null],
    };
  }

  protected getDefaultFieldInputType(field: IDataCollectionField | IDataCollectionCustomField): QueryFormValueType {
    return this.fieldDefaultInputCapabilities[field.key] || 'is';
  }

  protected getDefaultFieldInputText(field: IDataCollectionField | IDataCollectionCustomField): string {
    if (field.field === 'type') return 'article';
    const options = this.fieldInputOptions[field.key];
    return options ? options[0].value : '';
  }
}
