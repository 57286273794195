import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataAiApiService, IAiQueryProgress } from './data-ai-api.service';
import { IDataItem } from './data-item-api.service';

@Injectable()
export class DataAiService {

  constructor(
    protected api: DataAiApiService
  ) { }

  query(items: IDataItem[], question: string): Observable<IAiQueryProgress> {
    return this.api.query(items, question);
  }

  summarize(responses: string[]): Observable<string> {
    return this.api.summarize(responses);
  }

  getQuery(question: string): Observable<string> {
    return this.api.getQuery(question);
  }
}
