import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ElementRef, ViewChild, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDropdown, NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge, of, switchMap, zip } from 'rxjs';
import default_type_schema from '@readcube/rcp-meta/type_schema.json';

import { SharedService, ModalEditSmartListComponent, HistoryService } from '../../common';
import { DataItemService, DEFAULT_SEARCHABLE_FIELDS, DEFAULT_SORTABLE_FIELDS,
  IDataCollection, IDataCollectionCustomField, IDataCollectionField, IDataItem, IDataSmartList } from '../../library-data';

import { QueryBuilderService, QueryFormData, QueryFormValueType, FieldInputCapabilities, FieldInputOptions } from '../../query-builder';
import { BulkIdentifiersService } from '../../bulk';
import { ImporterService } from '../../importer';
import { AppShellService } from '../../app-shell.service';
import { LibraryService } from '../services/library.service';
import { FullTextService } from '../../full-text/services/full-text.service';

@Component({
  selector: 'library-header',
  styleUrls: ['./library-header.component.scss'],
  templateUrl: './library-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryHeaderComponent implements OnChanges {
  @Input()
  viewName: string;

  @Input()
  collection: IDataCollection;

  @Input()
  listId: string;

  @Input()
  searchPlaceholder: string;

  @Input()
  sort: string;

  @Input()
  order: string;

  @Input()
  query: string = '';

  @Output()
  onItemCreated = new EventEmitter<IDataItem>();

  @Output()
  viewNameChange = new EventEmitter<string>();

  @ViewChild('filterToggleButton', { static: false })
  filterToggleButton: ElementRef<any>;

  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef<any>;

  @ViewChild('searchTypeahead', { static: false })
  searchTypeahead: NgbTypeahead;

  @ViewChild('filterDropdown', { static: false })
  filterDropdown: NgbDropdown;

  @ViewChild('filterDropdownMenu', { static: false, read: ElementRef })
  filterDropdownMenuRef: ElementRef<HTMLElement>;

  @HostListener('window:keydown', ['$event'])
  handleWindowKeyDown(event: KeyboardEvent) {
    if (event.key == 'f' && (event.ctrlKey || event.metaKey)) {
      this.focusOnSearchInput();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onDocumentClick(event: PointerEvent) {
    // Workaround to fix autoClose when clicking inside nested dropdown menu.
    if (!this.filterDropdown?.isOpen()) return;
    const targetElement = event.target as HTMLElement;
    const isClickInsideFilterDropdownMenu = this.filterDropdownMenuRef.nativeElement.contains(targetElement);
    const isClickInsideAnotherDropdownMenu = Array.from(document.body.querySelectorAll('.dropdown-menu'))
      .filter(element => this.filterDropdownMenuRef.nativeElement != element)
      .some(element => element.contains(targetElement));

    if (!isClickInsideFilterDropdownMenu && !isClickInsideAnotherDropdownMenu) {
      this.filterDropdown.close();
      event.stopPropagation();
    }
  }

  sortOptions: { key: string, display: string }[];
  orderOptions: { key: string, display: string }[];

  fields: IDataCollectionField[] = [];
  customFields: IDataCollectionCustomField[] = [];
  fieldInputCapabilities: FieldInputCapabilities = {};
  fieldDefaultInputCapabilities: { [key: string]: QueryFormValueType } = {};
  fieldInputOptions: FieldInputOptions = {};

  queryFormData: QueryFormData = null;
  searchClick$ = new Subject<string>();

  searchTypeaheadFilter: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged(), map(text => text?.toLowerCase()));
    const clicksWithClosedPopup$ = this.searchClick$.pipe(filter(() => !this.searchTypeahead.isPopupOpen()));

    return merge(debouncedText$, clicksWithClosedPopup$).pipe(switchMap(term => {
      let historyKey = this.collection?.id || 'organization';
      let history$ = of(this.history.getSearchHistory(historyKey)
        .filter(s => s.toLowerCase().includes(term))
        .slice(0, 5)
        .map(value => {
          return { type: 'history', value };
        }));

      if (term == '' || !this.collection) {
        return history$;
      }

      let tags$ = this.dataItem.tags(this.collection.id).pipe(map(tags => {
        return tags
          .filter(t => t.name.toLowerCase().includes(term))
          .slice(0, 5)
          .map(t => {
            return { type: 'tag', value: t.name };
          })
      }));
      
      return zip(tags$, history$).pipe(
        map(([tagPicks, historyPicks]) => tagPicks.concat(historyPicks))
      );
    }));
  };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modal: NgbModal,
    public library: LibraryService,
    public shared: SharedService,
    public importer: ImporterService,
    public bulkIdentifiers: BulkIdentifiersService,
    public fulltext: FullTextService,
    public dataItem: DataItemService,
    public queryBuilder: QueryBuilderService,
    public history: HistoryService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.queryFormData = this.queryBuilder.parseQueryFragment(fragment);
    });

    if (this.queryBuilder.parseQueryFragment(this.route.snapshot.fragment)) {
      setTimeout(() => this.filterDropdown?.open(), 500);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const collection: IDataCollection = changes['collection']?.currentValue;
    const schema = collection?.custom_type_schema || default_type_schema;

    this.sortOptions = collection ? collection.sortable_fields : DEFAULT_SORTABLE_FIELDS;
    this.orderOptions = [
      { key: 'asc', display: 'Ascending' },
      { key: 'desc', display: 'Descending' },
    ];

    this.fields = collection ? collection.searchable_fields : DEFAULT_SEARCHABLE_FIELDS;
    this.fieldInputCapabilities = {
      'color': ['is', 'is_not', 'exists'],
      'tag': ['is', 'is_not', 'exists'],
      'unread': ['boolean'],
      'favorite': ['boolean'],
      'files': ['exists'],
      'rating': ['is', 'is_more', 'is_less', 'exists'],
      'year': ['is', 'is_not', 'exists', 'range'],
      'added': ['date', 'date_after', 'date_before'],
      'last_opened': ['date', 'date_after', 'date_before'],
      'notes': ['is', 'is_not', 'exists'],
    };
    this.fieldDefaultInputCapabilities = {
      'color': 'is',
      'tag': 'is',
      'unread': 'boolean',
      'favorite': 'boolean',
      'files': 'exists',
      'rating': 'is',
      'year': 'is',
      'added': 'date',
      'last_opened': 'date',
      'notes': 'exists',
    };
    this.fieldInputOptions = {
      'type': schema.field_defs.type.input.options.values.map(value => {
        return { value: value.id, display: value.text };
      }),
      'color': [
        { value: '#fe3018', display: 'Red' },
        { value: '#fe8e23', display: 'Orange' },
        { value: '#fed12f', display: 'Yellow' },
        { value: '#00d127', display: 'Green' },
        { value: '#1ea4fc', display: 'Blue' },
        { value: '#fd8afc', display: 'Pink' },
        { value: '#bfbfbf', display: 'Gray' },
      ],
      'rating': [
        { value: '1', display: '1' },
        { value: '2', display: '2' },
        { value: '3', display: '3' },
        { value: '4', display: '4' },
        { value: '5', display: '5' },
      ]
    };

    this.customFields = collection ? collection.custom_fields : [];
    this.customFields.filter(field => ['array-lookup', 'lookup'].includes(field.type)).forEach(field => {
      this.fieldInputOptions[field.key] = field.lookups.map(value => ({ value, display: value }));
    });
    this.customFields.filter(field => ['date'].includes(field.type)).forEach(field => {
      this.fieldInputCapabilities[field.key] = ['date', 'date_after', 'date_before'];
      this.fieldDefaultInputCapabilities[field.key] = 'date';
    });
    this.customFields.filter(field => ['bool'].includes(field.type)).forEach(field => {
      this.fieldInputCapabilities[field.key] = ['boolean'];
      this.fieldDefaultInputCapabilities[field.key] = 'boolean';
    });
  }

  focusOnSearchInput() {
    if (this.searchInput) {
      const event = new Event('blur');
      document.dispatchEvent(event);
      this.searchInput.nativeElement.focus();
    }
  }

  changeView(viewName: string) {
    this.viewName = viewName;
    this.viewNameChange.next(viewName);
  }

  createNewReference() {
    this.library.createReference(this.collection.id, this.listId).then(item => {
      this.onItemCreated.next(item);
    });
  }

  openImportDialog() {
    this.importer.openImportDialog(this.collection?.id, this.listId);
  }

  openImportIdentifiers() {
    this.bulkIdentifiers.openBulkImportIdentifiers(this.collection.id, this.listId);
  }

  openImportSearch() {
    this.fulltext.openImportSearch(this.collection?.id, this.listId);
  }

  onSortChange(value: string) {
    this.router.navigate([], {
      queryParams: { sort: value, size: null },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onOrderChange(value: string) {
    this.router.navigate([], {
      queryParams: { order: value, size: null },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onSearchSaveClick(event: MouseEvent) {
    const modalRef = this.modal.open(ModalEditSmartListComponent);
    const modalComponent = <ModalEditSmartListComponent>modalRef.componentInstance;
    modalComponent.collection = this.collection;
    modalComponent.list = <IDataSmartList>{
      collection_id: this.collection.id,
      query: this.query,
      sortField: this.sort,
      sortOrder: this.order
    };
  }

  onSearchClearClick(event: MouseEvent) {
    this.query = '';
    const closeSidepanel = false; //!event.shiftKey
    this.search(closeSidepanel);
  }

  onSearchEnterKey(event: KeyboardEvent) {
    const closeSidepanel = false; // !event.shiftKey
    this.search(closeSidepanel);
  }

  onSearchSelectItem(event: NgbTypeaheadSelectItemEvent) {
    if (event.item.type === 'tag') {
      this.query = `tag:"${event.item.value}"`;
    } else {
      this.query = event.item.value;
    }
    this.search();
    event.preventDefault();
  }

  onSearchTabKey(event: KeyboardEvent) {
    this.filterDropdown.open();
    this.filterToggleButton.nativeElement.focus();
    event.preventDefault();
  }

  updateQuery(data: QueryFormData) {
    this.query = this.queryBuilder.serialize(data);
    this.queryFormData = data;
  }

  search(closeSidepanel = false) {
    const queryParams = { query: this.query.trim(), reload: Date.now().toString() };
    const queryParamsHandling = 'merge';
    const fragment = this.queryBuilder.toQueryFragment(this.queryFormData);

    this.router.navigate(closeSidepanel ? [{
      outlets: { 'sidepanel': null }
    }] : [], {
      queryParams,
      queryParamsHandling,
      fragment,
    }).then(() => {
      this.focusOnSearchInput();
    });
  }

  /// TODO: remove when WEBAPP-1820?
  // onSearchKeyClick(event: MouseEvent, key: string) {
  //   let q = (this.query || '').trim().concat(' ').concat(key).trim();
  //   let i = q.indexOf('(CARET)');
  //   q = q.replace('(CARET)', '');
  //   setTimeout(() => {
  //     setCaretPosition(this.searchInput.nativeElement, i);
  //   }, 100);
  //   this.query = q;
  //   this.filterDropdown.close();
  // }
}


// function setCaretPosition(elem: any, pos: number) {
//   if (elem.setSelectionRange) {
//     elem.focus();
//     elem.setSelectionRange(pos, pos);
//   } else if (elem.createTextRange) {
//     var range = elem.createTextRange();
//     range.collapse(true);
//     range.moveEnd('character', pos);
//     range.moveStart('character', pos);
//     range.select();
//   }
// }