<div class="btn-toolbar" style="justify-content: flex-end" *ngIf="templates">

  <div class="btn-group" ngbDropdown placement="bottom-end" *ngIf="templates.length > 0">
    <button type="button" class="btn btn-secondary" ngbDropdownToggle>
      <fa-icon [icon]="['fas', 'table']"></fa-icon> {{loadedTemplate?.name || 'Choose Template'}}
    </button>

    <div class="dropdown-menu" ngbDropdownMenu container="body">
      <!--<h6 class="dropdown-header">My Templates</h6>-->
      <button class="dropdown-item" *ngFor="let t of templates"
        [ngClass]="{'active': loadedTemplate && loadedTemplate.id == t.id}"
        (click)="loadTemplate(t.id)">{{t.name}}</button>
    </div>
  </div>

  <div class="btn-group ms-1" ngbDropdown placement="bottom-end">
    <button type="button" class="btn btn-secondary" ngbDropdownToggle>
      <fa-icon [icon]="['fas', 'gear']"></fa-icon>
    </button>

    <div class="dropdown-menu" ngbDropdownMenu container="body">
      <button class="dropdown-item" (click)="saveTemplate()">Save Template</button>

      <button class="dropdown-item" (click)="updateTemplate()" [disabled]="!loadedTemplate">Update Template</button>
      <button class="dropdown-item" (click)="deleteCurrentTemplate()" [disabled]="!loadedTemplate">Delete Template</button>

      <li><hr class="dropdown-divider"></li>

      <button class="dropdown-item" (click)="file.click($event)">Import Templates</button>
      <input type="file" #file (change)="importTemplatesFromFile($event)" hidden>

      <button class="dropdown-item" (click)="exportTemplatesToFile($event)">Export Templates</button>
    </div>
  </div>

</div>
