<context-menu #contextMenuComponent>
  <ng-template contextMenuItem (execute)="createList()" [enabled]="canManageList()">Create List</ng-template>
  <ng-template contextMenuItem (execute)="createSmartList()" [enabled]="canManageSmartlist()">Create Smart List</ng-template>
  <ng-template contextMenuItem divider="true"></ng-template>
  <ng-template contextMenuItem [subMenu]="exportOptionsSubMenu" [enabled]="canExportLibrary()">Export to...</ng-template>
  <ng-template contextMenuItem (execute)="share()" [enabled]="canShareCollection()">Share as Public List</ng-template>
  <ng-template contextMenuItem (execute)="createFilteredList()" [enabled]="canManageList()" *ngIf="canCreateFilteredList()">Create Filtered List</ng-template>
  <ng-template contextMenuItem (execute)="setNotifications()" *ngIf="canSetNotifications()">Email Notifications</ng-template>
  <ng-template contextMenuItem (execute)="copyItems()" [enabled]="canCopyItems()">Copy to...</ng-template>
  <ng-template contextMenuItem (execute)="locatePDFs()" [enabled]="canLocatePDFs()">Locate PDFs</ng-template>
  <ng-template contextMenuItem (execute)="updateDetails()" [enabled]="canUpdateDetails()">Update Details</ng-template>
  <ng-template contextMenuItem (execute)="deleteItems()" [enabled]="canDeleteItems()">Delete From Library</ng-template>
  <ng-template contextMenuItem (execute)="linkFiles()" [enabled]="collection.user?.can_create_item" *ngIf="canLinkFiles()">Link Existing Files</ng-template>
  <ng-template contextMenuItem (execute)="exportPDFs()" [enabled]="canExportPDFs()">Export PDFs</ng-template>
  <ng-template contextMenuItem [subMenu]="literatureReviewSubMenu" *ngIf="canLiteratureReview()">Literature Review</ng-template>
</context-menu>

<context-menu #exportOptionsSubMenu>
  <ng-template contextMenuItem (execute)="exportToBIB()">BibTeX (.bib)</ng-template>
  <ng-template contextMenuItem (execute)="exportToRIS()">RefMan (.ris)</ng-template>
  <ng-template contextMenuItem (execute)="exportToCSV()">Comma-separated Values (.csv)</ng-template>
  <ng-template contextMenuItem (execute)="exportToXLSX()">Microsoft Excel (.xlsx)</ng-template>
</context-menu>

<context-menu #literatureReviewSubMenu>
  <ng-template contextMenuItem (execute)="addToLiteratureReview()">Add to Existing Project</ng-template>
  <ng-template contextMenuItem (execute)="shared.openLiteratureReview()">Go to Projects</ng-template>
  <ng-template contextMenuItem (execute)="linkToLiteratureReview()" [enabled]="collection.user?.can_create_item">Link to Project</ng-template>
</context-menu>

<common-navigation-button
  [routerLink]="['/library', collection.id, 'all']"
  [contextMenu]="contextMenuComponent"

  [indent]="indent"
  [expandable]="true"
  [(expanded)]="expanded"
  (expandedChange)="onExpandedChange($event)"

  [rcpDroppable]="isDroppable.bind(this)"
  rcpDropOverClass="blink"
  (rcpDrop)="onDrop($event)"

  [ngClass]="{
    'active': active$ | async,
    'disabled': disabled$ | async,
    'visible': (loading$ | async) || (active$ | async)
  }">
  <ng-container icon>
    <rcp-svg-icon name="my-library"></rcp-svg-icon>
  </ng-container> My Papers

  <ng-container addons>
    <common-navigation-button-addon *ngIf="loading$ | async">
      <fa-icon [icon]="['fas', 'spinner-third']" [spin]="true"></fa-icon>
    </common-navigation-button-addon>

    <common-navigation-button-addon *ngIf="!(loading$ | async)" (click)="openContextMenu($event)">
      <fa-icon [icon]="['fas', 'cog']"></fa-icon>
    </common-navigation-button-addon>
  </ng-container>

  <ng-template #expandedTemplate>
    <common-navigation-button
      [disabled]="dragActive$ | async"
      [indent]="indent+1"
      [routerLink]="['/library', collection.id, 'recent']"
      routerLinkActive="active">
      <ng-container icon>
        <rcp-svg-icon name="history"></rcp-svg-icon>
      </ng-container> Recently Read
    </common-navigation-button>

    <common-navigation-button
      [disabled]="dragActive$ | async"
      [indent]="indent+1"
      [routerLink]="['/library', collection.id, 'flagged']"
      routerLinkActive="active">
      <ng-container icon>
        <rcp-svg-icon name="flag"></rcp-svg-icon>
      </ng-container> Flagged
    </common-navigation-button>

    <library-navigation-tags [collection]="collection" [indent]="indent"></library-navigation-tags>

    <library-navigation-smartlist
      *ngFor="let list of smartLists$ | async"
      [indent]="indent+1"
      [collection]="collection"
      [list]="list">
    </library-navigation-smartlist>

    <library-navigation-list
      *ngFor="let node of lists$ | async"
      [indent]="indent+1"
      [maxDepth]="maxListsDepth"
      [collection]="collection"
      [node]="node">
    </library-navigation-list>

    <common-loader-circles class="loader" *ngIf="loadingLists$ | async"></common-loader-circles>
  </ng-template>
</common-navigation-button>
