<div class="wrapper papers-scrollbar">

  <library-sidepanel-header
    [journal]="item?.article.journal"
    [year]="item?.article.year"
    [title]="item?.article.title"
    [chapter]="item?.article.chapter">
  </library-sidepanel-header>

  <form #notesForm="ngForm" (ngSubmit)="save()" *ngIf="item">
    <textarea #noteInput id="note" name="note" class="papers-scrollbar"
      [(ngModel)]="item.user_data.notes"
      (ngModelChange)="noteChange$.next($event)"
      placeholder="Write your note here..."
      [disabled]="!canAnnotateItem">
    </textarea>
  </form>

  <div class="saving mb-4" [ngClass]="{'hidden': !saving}">Saving...</div>

  <h5 *ngIf="annotations.length > 0">Annotations</h5>

  <div class="mt-2" #annotationsWidget></div>

  <common-loader-circles *ngIf="loading"></common-loader-circles>

</div>
