import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, SharedService } from '../../common';
import { DataUserService } from '../../common-data';
import { DataCollectionService } from '../../library-data';
import { IBannerError } from './importer-error-banner.component';
import { IImportType, ImporterService, IMPORT_TYPE_ID } from '../services/importer.service';
import { getFileExt } from '@readcube/rcp-common';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './importer-modal-options.component.html',
  styleUrls: ['./importer-modal-options.component.scss']
})
export class ImporterModalOptionsComponent implements OnInit {
  @ViewChild('optionsRef', { static: false })
  optionsRef: any;

  constructor(
    public dataCollection: DataCollectionService,
    public importer: ImporterService,
    public route: ActivatedRoute,
    public dialog: DialogService,
    public shared: SharedService,
    public user: DataUserService
  ) { }

  availableImportTypes: IImportType[];
  selectedImportType: IImportType;
  selectedImportTypeId: IMPORT_TYPE_ID;
  currentPage = 1;
  selectedFiles: File[] = [];
  collectionId = null;
  listId = null;
  options: any = {};
  error?: IBannerError = null;
  showError = false;
  get modalWidth(): number {
    return this.selectedImportType?.type === 'csv' ? 800 : 650;
  }

  get hasTemplatesEnabled(): boolean {
    return ['csv'].includes(this.selectedImportType?.type);
  }

  ngOnInit() {
    this.availableImportTypes = this.importer.getAvailableImportTypes();
    this.collectionId = this.route.snapshot.queryParamMap.get('collectionId');
    this.listId = this.route.snapshot.queryParamMap.get('listId');
    this.selectedImportTypeId = this.route.snapshot.queryParamMap.get('importType') as IMPORT_TYPE_ID || this.availableImportTypes[0].type;
    this.selectedImportType = this.availableImportTypes.find(v => v.type = this.selectedImportTypeId);

    const selectedFiles = this.importer.getSelectedFiles();

    if (!selectedFiles?.length) return;

    const allFilesOfSameType = selectedFiles
      .map(file => getFileExt(file.name))
      .every((val, _, arr) => val == arr[0]);

    if (!allFilesOfSameType) return;

    switch (getFileExt(selectedFiles[0].name)) {
      case 'pdf':
        this.selectedImportTypeId = 'pdf';
        break;
      case 'bib':
      case 'bibtex':
        this.selectedImportTypeId = 'bib';
        break;
      case 'nbib':
        this.selectedImportTypeId = 'nbib';
        break;
      case 'ris':
        this.selectedImportTypeId = 'ris';
        break;
      case 'csv':
        this.selectedImportTypeId = 'csv';
        break;
      default:
        if (this.shared.user.licence.all_file_types) {
          this.selectedImportTypeId = 'media';
        }
    }
    this.selectedImportType = this.availableImportTypes.find(v => v.type = this.selectedImportTypeId);
    this.selectedFiles = selectedFiles;
    this.currentPage = 2;
  }

  loadOptions(options: any) {
    if (this.optionsRef.loadOptions) {
      this.optionsRef.loadOptions(options);
    }
    this.options = options;
  }

  back() {
    this.setPage(this.currentPage - 1);
  }

  next() {
    this.setPage(this.currentPage + 1);
  }

  setPage(page: number) {
    this.error = null;
    this.currentPage = page;
    if (page == 1) {
      this.selectedFiles = [];
    }
  }

  import() {
    if (this.error) {
      this.showError = true;
      return;
    }
    this.showError = false;
    this.importer.add(this.selectedFiles, this.collectionId, this.listId, this.options);
    this.importer.start();
    this.close();
  }

  canNext() {
    return !!this.selectedImportType && !!this.collectionId && (!this.selectedImportType.accept || this.selectedFiles.length > 0);
  }

  close() {
    this.dialog.closeDialog();
  }
}
