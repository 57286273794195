import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, firstValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CustomEncoder } from '../../app-http-param-encoder';
import { AppWebSocketService } from '../../app-websocket.service';
import { IDataResult } from '../../app-data-result.model';
import { environment } from 'environment';

export const DEFAULT_SORTABLE_FIELDS: IDataCollectionField[] = [
  { key: 'color', field: 'color', display: 'Color Label' },
  { key: 'flag', field: 'flag', display: 'Flag' },
  { key: 'file', field: 'file', display: 'Files' },
  { key: 'year', field: 'year', display: 'Year' },
  { key: 'last_read', field: 'last_read', display: 'Recently Read' },
  { key: 'created', field: 'created', display: 'Date Added' },
  { key: 'type', field: 'type', display: 'Type' },
  { key: 'title', field: 'title', display: 'Title' },
  { key: 'first_author', field: 'first_author', display: 'First Author' },
  { key: 'last_author', field: 'last_author', display: 'Last Author' },
  { key: 'journal', field: 'journal', display: 'Journal' },
  { key: 'notes', field: 'notes', display: 'Notes' },
  { key: 'rating', field: 'rating', display: 'Rating' }
];

export const DEFAULT_SEARCHABLE_FIELDS: IDataCollectionField[] = [
  { key: 'title', field: 'title', display: 'Title' },
  { key: 'abstract', field: 'abstract', display: 'Abstract' },
  { key: 'journal', field: 'journal', display: 'Journal' },
  { key: 'author', field: 'author', display: 'Author' },
  { key: 'color', field: 'color', display: 'Color' },
  { key: 'type', field: 'type', display: 'Type' },
  { key: 'doi', field: 'doi', display: 'DOI' },
  { key: 'pmid', field: 'pmid', display: 'PMID' },
  { key: 'pmcid', field: 'pmcid', display: 'PMCID' },
  { key: 'citekey', field: 'citekey', display: 'Citekey' },
  { key: 'files', field: 'files', display: 'Files' },
  { key: 'year', field: 'year', display: 'Year' },
  { key: 'issn', field: 'issn', display: 'ISSN' },
  { key: 'isbn', field: 'isbn', display: 'ISBN' },
  { key: 'volume', field: 'volume', display: 'Volume' },
  { key: 'tag', field: 'tag', display: 'Tag' },
  { key: 'unread', field: 'unread', display: 'Unread' },
  { key: 'favorite', field: 'favorite', display: 'Favorite' },
  { key: 'rating', field: 'rating', display: 'Rating' },
  { key: 'note', field: 'note', display: 'Notes' },
  { key: 'first_author', field: 'first_author', display: 'First Author' },
  { key: 'last_author', field: 'last_author', display: 'Last Author' },
  { key: 'added', field: 'added', display: 'Date Added' },
  { key: 'last_opened', field: 'last_opened', display: 'Date Opened' },
  { key: 'highlighted_text', field: 'highlighted_text', display: 'Highlighted Text' },
];

export type CollectionFieldInputType =  'text' | 'textarea' | 'array' | 'number' | 'date' | 'bool' | 'lookup' | 'array-lookup';

interface IDataCollectionBase {
  id: string;
  name: string;
  shared: boolean;
  status: 'active' | 'archived' | 'deleted';
  public_id: string;
  last_reset_at: number;
  tip: number;
  organization: boolean;
  sortable_fields: IDataCollectionField[];
  searchable_fields: IDataCollectionField[];
  custom_fields?: IDataCollectionCustomField[];
  custom_type_schema?: IDataCollectionTypeSchema;
  order_options: IDataCollectionOrderOption[];
  web_only?: boolean;
}

export interface IDataCollectionOrderOption {
  key: 'asc' | 'desc',
  display: string,
}

export interface IDataCollectionField {
  key: string;
  display: string;
  field: string;
}

export interface IDataCollectionCustomField {
  key: string;
  display: string;
  field: string;
  type: CollectionFieldInputType;
  show_in_details: boolean;
  show_in_table: boolean;
  lookups?: string[];
  section?: string;
}

export interface IDataCollection extends IDataCollectionBase {
  user: IDataCollectionUserSettings;
}

export interface IDataCollectionUserSettings {
  can_manage_collection: boolean;
  can_create_item: boolean;
  can_delete_item: boolean;
  can_copy_item: boolean;
  can_tag_item: boolean;
  can_annotate_item: boolean;
  can_manage_file: boolean;
  can_download_file: boolean;
  can_print_file: boolean;
  can_manage_list: boolean;
  can_manage_smartlist: boolean;
  can_publish_list: boolean;
  role: 'owner' | 'admin' | 'member' | 'viewer';
}

export interface IDataCollectionEditable extends IDataCollectionBase {
  role_settings: IDataCollectionRoleSettings;
  members: IDataCollectionMember[];
}

export interface IDataCollectionRoleSettings {
  member_annotate_item: boolean;
  member_copy_item: boolean;
  member_download_file: boolean;
  member_create_item: boolean;
  member_delete_item: boolean;
  member_print_file: boolean;
  member_manage_list: boolean;
  member_manage_smartlist: boolean;
  member_publish_list: boolean;
  member_tag_item: boolean;
  viewer_annotate_item: boolean;
  viewer_copy_item: boolean;
  viewer_download_file: boolean;
  viewer_create_item: boolean;
  viewer_delete_item: boolean;
  viewer_print_file: boolean;
  viewer_manage_list: boolean;
  viewer_manage_smartlist: boolean;
  viewer_publish_list: boolean;
  viewer_tag_item: boolean;
}

export interface IDataCollectionMember {
  id: string;
  name: string;
  type: 'user' | 'group';
  role: 'owner' | 'admin' | 'member' | 'viewer';
  status?: string;
  email?: string;
}

export interface IDataCollectionTypeSchema {
  field_defs: {
    [key: string]: IDataCollectionFormField
  },
  types: {
    [key: string]: IDataCollectionType
  }
}

export interface IDataCollectionFormField {
  id: string;
  input: IDataCollectionFormInput,
  custom_metadata: string,
  readcube: string,
  papers: string,
  endnote: string,
  csl: string,
  csv: string,
  bib: string,
  ris: string,
}

export interface IDataCollectionFormInput {
  label: string | null,
  type: 'select' | 'text' | 'textarea' | 'date',
  required: boolean,
  disabled: boolean,
  hidden: boolean;
  array: boolean,
  pattern: string | null,
  pattern_insensitive?: boolean,
  options?: {
    groups: [{ id: string, text: string }],
    values: [{ id: string, text: string, group: string }],
  },
  minDate?: { year: number, month: number, day: number };
  maxDate?: { year: number, month: number, day: number };
  default?: string,
  warning?: boolean,
}

export interface IDataCollectionType {
  category: string,
  csl_type: string,
  bib_type: string,
  ris_type: string,
  csv_type: string,
  end_type: string,
  fields: string[],
  is_default: boolean,
}

export interface IDataCollectionQueryParams {
  created_since?: string;
  modified_since?: string;
}

export interface IDataCollectionEditableRequest {
  name: string;
  role_settings: IDataCollectionRoleSettings;
  invite_users: { [key: string]: 'owner' | 'admin' | 'member' | 'viewer'; };
  add_users: { [key: string]: 'owner' | 'admin' | 'member' | 'viewer'; };
  remove_users: string[];
  add_groups: { [key: string]: 'owner' | 'admin' | 'member' | 'viewer'; };
  remove_groups: string[];
}
export interface IDataCollectionLeaveRequest {
  new_owner_id?: string;
  new_admin_id?: string;
}

export interface IDataCollectionEmail {
  id?: string;
  email: string;
  can_join?: boolean;
  status?: 'unregistered' | 'invite_pending' | 'registered';
  error?: 'not_in_organization' | 'email_invalid';
}

export interface IDataCollectionNotifications {
  frequency: 'monthly' | 'weekly' | 'daily' | null;
  collection_id?: string;
  list_id?: string;
}

@Injectable()
export class DataCollectionApiService {

  constructor(
    private http: HttpClient,
    private ws: AppWebSocketService
  ) { }

  public listen(collectionId?: string): Observable<any> {
    return this.ws.listen(`/users/${collectionId}`).pipe(
      filter(data => data.collections_changed)
    );
  }

  public query(params?: IDataCollectionQueryParams): Observable<IDataResult<IDataCollection>> {
    return this.http.get<IDataResult<IDataCollection>>(`${environment.baseUrls.services}/collections`).pipe(
      map(body => this.createResult(body))
    );
  }

  public queryWebonly(): Observable<IDataCollection[]> {
    // not implemented
    return EMPTY;
  }

  public get(collectionId: string): Observable<IDataCollection> {
    return this.http.get<any>(`${environment.baseUrls.services}/collections/${collectionId}`).pipe(
      map(body => this.mapCollectionData(body.collection))
    );
  }

  public getEditable(collectionId: string): Observable<IDataCollectionEditable> {
    const endpointUrl = `${environment.baseUrls.services}/collections/${collectionId}/members`;
    return this.http.get<any>(endpointUrl).pipe(
      map(body => this.mapCollectionEditableData(body.collection))
    );
  }

  public post(body: Partial<IDataCollectionEditableRequest>): Promise<IDataCollection> {
    const endpointUrl = `${environment.baseUrls.services}/collections`;
    return firstValueFrom(this.http.post<any>(endpointUrl, body).pipe(
      map(body => this.mapCollectionData(body.collection))
    ));
  }

  public patch(collectionId: string, body: Partial<IDataCollectionEditableRequest>): Promise<IDataCollection> {
    const endpointUrl = `${environment.baseUrls.services}/collections/${collectionId}`;
    return firstValueFrom(this.http.patch<any>(endpointUrl, body).pipe(
      map(body => this.mapCollectionData(body.collection))
    ));
  }

  public delete(collectionId: string): Promise<IDataCollection> {
    const endpointUrl = `${environment.baseUrls.services}/collections/${collectionId}`;
    return firstValueFrom(this.http.delete<any>(endpointUrl).pipe(
      map(body => this.mapCollectionData(body.collection))
    ));
  }

  public archive(collectionId: string): Promise<IDataCollection> {
    const endpointUrl = `${environment.baseUrls.services}/collections/${collectionId}/archive`;
    return firstValueFrom(this.http.post<any>(endpointUrl, null).pipe(
      map(body => this.mapCollectionData(body.collection))
    ));
  }

  public restore(collectionId: string): Promise<IDataCollection> {
    const endpointUrl = `${environment.baseUrls.services}/collections/${collectionId}/restore`;
    return firstValueFrom(this.http.post<any>(endpointUrl, null).pipe(
      map(body => this.mapCollectionData(body.collection))
    ));
  }

  public leave(collectionId: string, body: IDataCollectionLeaveRequest): Promise<IDataCollection> {
    const endpointUrl = `${environment.baseUrls.services}/collections/${collectionId}/leave`;
    return firstValueFrom(this.http.post<any>(endpointUrl, body).pipe(
      map(body => this.mapCollectionData(body.collection))
    ));
  }

  public checkEmails(emails: string[]): Promise<IDataCollectionEmail[]> {
    let params = new HttpParams({ encoder: new CustomEncoder() });
    emails.forEach(email => {
      params = params.append('emails[]', email);
    });
    const endpointUrl = `${environment.baseUrls.services}/collections/check_emails`;
    return firstValueFrom(this.http.get<any>(endpointUrl, { params: params }));
  }

  public share(collectionId: string): Observable<IDataCollection> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/enable_public?type=collection`;
    return this.http.post<any>(endpointUrl, {}).pipe(
      map(body => this.mapCollectionData(body.object))
    );
  }

  public stopShare(collectionId: string): Observable<IDataCollection> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/disable_public?type=collection`;
    return this.http.post<any>(endpointUrl, {}).pipe(
      map(body => this.mapCollectionData(body.object))
    );
  }

  public getNotifications(collectionId: string, listId: string): Observable<IDataCollectionNotifications> {
    let endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/email_notifications`;
    if (listId) endpointUrl = endpointUrl + `?list_id=${listId}`;
    return this.http.get<IDataCollectionNotifications>(endpointUrl);
  }

  public setNotifications(collectionId: string, body: IDataCollectionNotifications): Observable<IDataCollectionNotifications> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/email_notifications`;
    return this.http.post<IDataCollectionNotifications>(endpointUrl, body);
  }

  public exportPDFs(collectionId: string, listId: string, template: string): Observable<{ success: boolean; }> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/exports/generate`;
    return this.http.post<any>(endpointUrl, { list_id: listId, template }).pipe(
      map((response: any) => response.result)
    );
  }

  private createResult(data: any): IDataResult<IDataCollection> {
    return {
      refinements: data['refinements'] || {},
      rows: (data['collections'] || []).map(d => this.mapCollectionData(d)),
      totalHuman: data['total_human'] || 'No collections',
      total: data['total'] || 0
    };
  }

  private mapCollectionData(data: any): IDataCollection {
    return {
      id: data['id'] || data['collection_id'],
      name: data['name'] || '',
      shared: data['shared'] || false,
      status: data['status'],
      public_id: data['public_id'] || null,
      last_reset_at: data['last_reset_at'] || null,
      organization: data['organization'] || false,
      tip: data['tip'] || null,
      user: data['user'] || {},
      sortable_fields: DEFAULT_SORTABLE_FIELDS,
      searchable_fields: DEFAULT_SEARCHABLE_FIELDS,
      custom_fields: (data['custom_fields'] || []).map(field => {
        return Object.assign(field, { key: 'custom_metadata.'+field.field });
      }),
      custom_type_schema: data['custom_type_schema'] || null,
      order_options: [
        { key: 'asc', display: 'Ascending' },
        { key: 'desc', display: 'Descending' }
      ],
      web_only: data['web_only'] || false,
    };
  }

  private mapCollectionEditableData(data: any): IDataCollectionEditable {
    return {
      id: data['id'] || data['collection_id'],
      name: data['name'] || '',
      shared: data['shared'] || false,
      status: data['status'],
      public_id: data['public_id'] || null,
      last_reset_at: data['last_reset_at'] || null,
      organization: data['organization'] || false,
      tip: data['tip'] || null,
      role_settings: data['role_settings'] || {},
      members: data['members'] || [],
      sortable_fields: DEFAULT_SORTABLE_FIELDS,
      searchable_fields: DEFAULT_SEARCHABLE_FIELDS,
      custom_fields: (data['custom_fields'] || []).map(field => {
        return Object.assign(field, { key: 'custom_metadata.'+field.field });
      }),
      custom_type_schema: data['custom_type_schema'] || null,
      order_options: [
        { key: 'asc', display: 'Ascending' },
        { key: 'desc', display: 'Descending' }
      ],
      web_only: data['web_only'] || false,
    };
  }
}
