<form #searchForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">Import from Search</h4>
  </div>

  <div class="modal-body">

    <b class="papers-label">Search</b>

    <div class="row">
      <div class="col-sm-9">
        <div class="form-floating">
          <input 
            name="query-input" 
            type="text" 
            class="form-control"
            #queryInput="ngModel"
            [(ngModel)]="query"
            autocomplete="off"
            maxlength="200"
            ngbAutofocus
            emptyValidator
            required>
          <span class="form-text text-muted" *ngIf="queryInput.control.errors?.required && queryInput.touched">Query is required.</span>
          <span class="form-text text-muted" *ngIf="queryInput.control.errors?.empty && queryInput.touched">Query cant't be empty.</span>
          <label for="query-input">Search Query</label>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="form-floating">
          <select 
            name="result-limit-select" 
            class="form-select"
            [(ngModel)]="resultLimit"
            required>
            <option *ngFor="let limit of resultLimits" value="{{limit}}">{{limit}}</option>
          </select>
          <label for="result-limit-select">Results</label>
        </div>
      </div>
    </div>

    <br><br>

    <b class="papers-label">Options</b>
  
    <div class="form-check form-switch mt-2">
      <input class="form-check-input"
        type="checkbox"
        id="search-dimensions-checkbox"
        name="search-dimensions-input"
        [(ngModel)]="searchDimensions">
      <label class="form-check-label" for="search-dimensions-checkbox">Search Dimensions Full Text</label>
    </div>

    <div class="form-check form-switch mt-2">
      <input class="form-check-input"
        type="checkbox"
        id="merge-duplicates-checkbox"
        name="merge-duplicates-checkbox"
        [(ngModel)]="mergeDuplicates">
      <label class="form-check-label" for="merge-duplicates-checkbox">Merge Duplicates</label>
    </div>

    <br>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!searchForm.form.valid">Search</button>
  </div>

</form>