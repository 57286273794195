import { Component, AfterContentInit, OnChanges, Input, Output, ViewChild, TemplateRef, EventEmitter, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { LoadNextEvent, ITableColumn } from '@readcube/rcp-data-view';

import { SharedService } from '../../common';
import { IDataArticle } from '../../search-data';
import { AppStorageService } from '../../app-storage.service';

@Component({
  selector: 'search-results-table',
  templateUrl: './search-results-table.component.html',
  styleUrls: ['./search-results-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultsTableComponent implements AfterContentInit, OnChanges {
  @Input()
  items: IDataArticle[];

  @Input()
  selected: IDataArticle[];

  @Input()
  highlighted: IDataArticle[];

  @Input()
  loading = false;

  @Input()
  finished = false;

  @Input()
  sort: string;

  @Input()
  order: string;

  @Input()
  multiselect = true;

  @Output()
  open = new EventEmitter<IDataArticle>();

  @Output()
  selectedChange = new EventEmitter<IDataArticle[]>();

  @Output()
  highlightedChange = new EventEmitter<IDataArticle[]>();

  @Output()
  loadNext = new EventEmitter<LoadNextEvent>();

  @ViewChild('inLibraryHeadTemplate', { static: true })
  inLibraryHeadTemplate: TemplateRef<any>;

  @ViewChild('inLibraryCellTemplate', { static: true })
  inLibraryCellTemplate: TemplateRef<any>;

  @ViewChild('libraryCellTemplate', { static: true })
  libraryCellTemplate: TemplateRef<any>;

  @ViewChild('authorsCellTemplate', { static: true })
  authorsCellTemplate: TemplateRef<any>;

  @ViewChild('lastAuthorCellTemplate', { static: true })
  lastAuthorCellTemplate: TemplateRef<any>;

  @ViewChild('titleCellTemplate', { static: true })
  titleCellTemplate: TemplateRef<any>;

  @ViewChild('journalCellTemplate', { static: true })
  journalCellTemplate: TemplateRef<any>;

  @ViewChild('yearCellTemplate', { static: true })
  yearCellTemplate: TemplateRef<any>;

  columns: ITableColumn[];
  storagePrefix = '';

  constructor(
    public router: Router,
    public shared: SharedService,
    protected storage: AppStorageService
  ) { }

  ngAfterContentInit() {
    this.columns = this.getTableColumns();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sort || changes.order) {
      this.columns = this.getTableColumns();
    }
  }

  onColumsChange(columns: ITableColumn[]) {
    columns.forEach(column => {
      const index = column.index >= 0 ? column.index : column.defaultIndex;
      const width = column.width >= column.minWidth ? column.width : column.defaultWidth;
      const hidden = column.hidden || false;
      this.setColumnIndex(column.id, index);
      this.setColumnWidth(column.id, width);
      this.setColumnHidden(column.id, hidden);
    });
  }

  onTableHeadClick(column: ITableColumn) {
    const paramMap: {[key: string]: string} = {
      'year': 'year'
    };
    if (!paramMap[column.id]) {
      return;
    }
    this.router.navigate([], {
      queryParams: {
        'sort': paramMap[column.id],
        'order': column.sorted ? column.sorted === 'asc' ? 'desc' : 'asc' : column.sorted
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onSelectItems(items: IDataArticle[]) {
    this.selectedChange.emit(items);
  }

  onLoadNext(event: LoadNextEvent) {
    if (this.finished || this.loading) {
      return;
    }
    this.loadNext.emit(event);
  }

  getTableColumns() {
    return [
      {
        id: 'inLibrary',
        title: 'In Library',
        index: this.getColumnIndex('inLibrary', 1),
        width: this.getColumnWidth('inLibrary', 40),
        hidden: this.getColumnHidden('inLibrary', false),
        defaultIndex: 1,
        defaultWidth: 40,
        defaultHidden: false,
        minWidth: 40,
        templateRef: this.inLibraryCellTemplate,
        headTemplateRef: this.inLibraryHeadTemplate
      },
      {
        id: 'title',
        title: 'Title',
        index: this.getColumnIndex('title', 2),
        width: this.getColumnWidth('title', 300),
        hidden: this.getColumnHidden('title', false),
        sorted: this.sort === 'title' ? this.order : undefined,
        defaultIndex: 2,
        defaultWidth: 300,
        defaultHidden: false,
        minWidth: 50,
        templateRef: this.titleCellTemplate
      },
      {
        id: 'library',
        title: 'Library',
        index: this.getColumnIndex('library', 3),
        width: this.getColumnWidth('library', 150),
        hidden: this.getColumnHidden('library', true),
        defaultIndex: 3,
        defaultWidth: 150,
        defaultHidden: true,
        minWidth: 150,
        templateRef: this.libraryCellTemplate,
      },
      {
        id: 'authors',
        title: 'Authors',
        index: this.getColumnIndex('authors', 4),
        width: this.getColumnWidth('authors', 150),
        hidden: this.getColumnHidden('authors', false),
        sorted: this.sort === 'first_author' ? this.order : undefined,
        defaultIndex: 4,
        defaultWidth: 150,
        defaultHidden: false,
        minWidth: 50,
        templateRef: this.authorsCellTemplate
      },
      {
        id: 'lastAuthor',
        title: 'Last Author',
        index: this.getColumnIndex('lastAuthor', 5),
        width: this.getColumnWidth('lastAuthor', 150),
        hidden: this.getColumnHidden('lastAuthor', false),
        sorted: this.sort === 'last_author' ? this.order : undefined,
        defaultIndex: 5,
        defaultWidth: 150,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.lastAuthorCellTemplate
      },
      {
        id: 'journal',
        title: 'Journal',
        index: this.getColumnIndex('journal', 6),
        width: this.getColumnWidth('journal', 200),
        hidden: this.getColumnHidden('journal', false),
        sorted: this.sort === 'journal' ? this.order : undefined,
        defaultIndex: 6,
        defaultWidth: 200,
        defaultHidden: false,
        minWidth: 50,
        templateRef: this.journalCellTemplate
      },
      {
        id: 'year',
        title: 'Year',
        index: this.getColumnIndex('year', 7),
        width: this.getColumnWidth('year', 70),
        hidden: this.getColumnHidden('year', false),
        sorted: this.sort === 'year' ? this.order : undefined,
        defaultIndex: 7,
        defaultWidth: 70,
        defaultHidden: false,
        minWidth: 40,
        templateRef: this.yearCellTemplate
      }
    ];
  }

  getColumnIndex(columnId: string, initial: number): number {
    return this.storage.get(`${this.storagePrefix}grid-articles-column-${columnId}-index`, initial);
  }

  getColumnWidth(columnId: string, initial: number): number {
    return this.storage.get(`${this.storagePrefix}grid-articles-column-${columnId}-width`, initial);
  }

  getColumnHidden(columnId: string, initial: boolean): boolean {
    return this.storage.get(`${this.storagePrefix}grid-articles-column-${columnId}-hidden`, initial);
  }

  setColumnIndex(columnId: string, index: number) {
    this.storage.set(`${this.storagePrefix}grid-articles-column-${columnId}-index`, index);
  }

  setColumnWidth(columnId: string, width: number) {
    this.storage.set(`${this.storagePrefix}grid-articles-column-${columnId}-width`, width);
  }

  setColumnHidden(columnId: string, hidden: boolean) {
    this.storage.set(`${this.storagePrefix}grid-articles-column-${columnId}-hidden`, hidden);
  }

  onHighlightItems(items: IDataArticle[]) {
    this.highlightedChange.emit(items);
  }

  getHighlightedTitle(item: IDataArticle): string {
    if (item.highlight.title?.length) {
      return item.highlight.title[0];
    }
    return item.title;
  }
}
