import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from 'environment';
import { map, mergeMap, Observable, startWith } from 'rxjs';
import { ListTreeService } from '../../common';
import { DataCollectionService, DataListService, IDataCollection, IDataList } from '../../library-data';

@Component({
  templateUrl: './importer-destination-select.component.html',
  selector: 'rcp-importer-destination-select',
})
export class ImporterDestinationSelectComponent implements OnInit, OnChanges {
  @Input()
  collectionId: string;

  @Input()
  listId: string;

  @Input()
  showLists: boolean;

  @Output()
  collectionIdChange = new EventEmitter<string>();

  @Output()
  listIdChange = new EventEmitter<string>();

  collections$: Observable<IDataCollection[]>;
  lists$: Observable<IDataList[]>;

  constructor(
    public dataCollection: DataCollectionService,
    public dataList: DataListService,
    public listTree: ListTreeService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('collectionId' in changes) {
      const value = changes['collectionId'].currentValue;
      this.collectionIdChange.next(value);
    }
  }

  ngOnInit() {
    this.collections$ = this.dataCollection.query().pipe(
      map(arr => arr.filter(c => c.status === 'active'))
    );
    this.lists$ = this.collectionIdChange.pipe(
      startWith(this.collectionId),
      mergeMap(collection_id => this.dataList.query({ collection_id })),
      map(lists => this.listTree.fromLists(lists)),
      map(root => this.listTree.flatten(root, environment.maxListsDepth)),
    );
  }
}
