import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataReviewApiService } from './services/data-review-api.service';
import { DataReviewService } from './services/data-review.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    DataReviewApiService
  ]
})
export class AppReviewDataModule {
  static forRoot(): ModuleWithProviders<AppReviewDataModule> {
    return {
      ngModule: AppReviewDataModule,
      providers: [
        DataReviewService
      ]
    };
  }
}
