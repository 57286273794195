import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'environment';

import { AppWebSocketService } from '../../app-websocket.service';

export interface IDataUser {
  id: string;
  name: string;
  email: string;
  verified: boolean;
  proxy_url: string | null;
  programs: string[];
  role: 'developer' | 'manager' | 'labs' | 'tester' | 'preview' | 'banned';
  licence: IDataUserLicense;
  link_resolver_name: string;
  link_resolver_url: string;
  organization_id: number;
  organization_name: string;
  organization_access_proxy_url: string;
  review_role: 'admin' | 'reviewer' | null;
}

export interface IDataUserLicense {
  active: boolean;
  used_trial: boolean;
  collection_roles_enabled: boolean;
  collection_count_limit: number | null;
  collection_users_limit: number;
  patent_support: boolean;
  all_file_types: boolean;
  organization_search: boolean;
  disable_updates: boolean;
  disable_public_sharing: boolean;
  organization_admin: boolean;
  enterprise_access: boolean;
  custom_fields: boolean;
  disable_item_sharing: boolean;
  copyright_acl_id: string;
  copyright_licence: 'acl_advisor' | 'acl_enterprise' | 'cla' | 'ca' | 'other';
  copyright_show_status: boolean;
  fulltext_search_via_dimensions: boolean;
  collection_email_notifications: boolean;
  import_from_search: boolean;
  collection_export: boolean;
  link_files: boolean;
  filtered_lists: boolean;
}

export interface IDataUserActivationResponse {
  success: boolean;
  error: string;
}

@Injectable()
export class DataUserApiService {

  constructor(
    private http: HttpClient,
    private ws: AppWebSocketService
  ) { }

  public listen(userId: string): Observable<any> {
    return this.ws.listen(`/users/${userId}`).pipe(
      filter(data => data.user_changed)
    );
  }

  public get(): Observable<IDataUser> {
    return this.http.get<IDataUser>(`${environment.baseUrls.services}/users/info_webapp`).pipe(
      map(user => {
        // Quickly enable license option to debug...
        // user.licence.custom_fields = true;
        return user;
      })
    );
  }

  public activate(): Promise<IDataUserActivationResponse> {
    const startTrialUrl = `${environment.baseUrls.services}/user_subscriptions/start_trial`;
    return firstValueFrom(this.http.post<IDataUserActivationResponse>(startTrialUrl, null));
  }
}
