import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { of as observableOf } from 'rxjs';

function validateEmpty(str) {
  return !!str.trim();
}

@Directive({
  selector: '[emptyValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmptyValidator),
      multi: true
    }
  ]
})
export class EmptyValidator implements Validator {
  validate(control: FormControl) {
    const value = control.value;

    if (value)
      return !validateEmpty(value) ? { empty: true } : null;

    return observableOf(null);
  }
}
