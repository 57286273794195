<div class="modal-header">
  <h4 class="modal-title">Download Unsuccessful</h4>
  <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <p>Unfortunately, full-text access could not be automatically verified. If you believe you have full-text access, please retry via the article page.</p>
  <p>If you are part of an university with an EZProxy, you can also retry by logging in with your proxy first.</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" *ngIf="isUsingProxy" (click)="loginWithProxy()">Try via EZProxy Login</button>
  <button type="button" class="btn btn-secondary" (click)="openProxySettings()">Review EZProxy Settings</button>
  <button type="button" class="btn btn-primary" (click)="viewArticlePage()">View Article Page</button>
</div>
