import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SearchQueryComponent } from './components/search-query.component';
import { SearchRecommendComponent } from './components/search-recommend.component';
import { SearchLandingComponent } from './components/search-landing.component';
import { SearchSidepanelDetailsComponent, SearchSidepanelMetricsComponent } from '../search-sidepanel';
import { SearchNavigationComponent } from '../search-navigation/components/search-navigation.component';
import { SearchComponent } from './components/search.component';
import { ModalCustomizeFiltersComponent } from '../search-navigation/modals/modal-customize-filters.component';

const sidepanelRoutes: Routes = [
  {
    path: 'details',
    component: SearchSidepanelDetailsComponent,
    outlet: 'sidepanel'
  },
  {
    path: 'metrics',
    component: SearchSidepanelMetricsComponent,
    outlet: 'sidepanel'
  }
];

const routes: Routes = [
  {
    path: '',
    component: SearchLandingComponent,
  },
  {
    path: 'customize-filters',
    component: ModalCustomizeFiltersComponent,
    outlet: 'dialog'
  },
  {
    path: '',
    component: SearchComponent,
    children: [
      {
        path: '',
        component: SearchNavigationComponent,
        outlet: 'menu'
      },
      {
        path: 'query',
        component: SearchQueryComponent,
        children: sidepanelRoutes
      },
      {
        path: 'recommend/:collection_id',
        component: SearchRecommendComponent,
        children: sidepanelRoutes
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppSearchRoutingModule { }
