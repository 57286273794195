import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.AppSearchModule)
  },
  {
    path: 'library',
    loadChildren: () => import('./library/library.module').then(m => m.AppLibraryModule)
  },
  {
    path: 'review',
    loadChildren: () => import('./review/review.module').then(m => m.AppReviewModule)
  },
  {
    path: '**',
    redirectTo: 'search'
  },
  // {
  //   path: 'library/:collection_id/item/:item_id',
  //   pathMatch: 'full',
  //   component: HomeComponent,
  // },
  // {
  //   path: 'library/:collection_id/item/:item_id/:sidepanel',
  //   pathMatch: 'full',
  //   component: HomeComponent,
  // },
  // {
  //   path: 'library/:collection_id/list/:list_id/item/:item_id',
  //   pathMatch: 'full',
  //   component: HomeComponent,
  // },
  // {
  //   path: 'library/:collection_id/list/:list_id/item/:item_id/:sidepanel',
  //   pathMatch: 'full',
  //   component: HomeComponent,
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
