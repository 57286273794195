<div class="search-navigation">
  <div class="module-info">
    <div class="module-info__name">Search</div>
    <div class="module-info__powered-by papers-label papers-label">Powered by <common-icon-dimensions height="16" width="80"></common-icon-dimensions></div>
  </div>

  <common-navigation-button
    [routerLink]="['/search', 'query']"
    [ngClass]="{ 'active': isRouteActive('/search/query') }">
    <ng-container icon>
      <rcp-svg-icon name="search"></rcp-svg-icon>
    </ng-container>
    Search
  </common-navigation-button>

  <common-navigation-button
    [routerLink]="['/search', 'recommend', shared.user?.id]"
    [ngClass]="{ 'active': isRouteActive('/search/recommend') }">
    <ng-container icon>
      <rcp-svg-icon name="diamond"></rcp-svg-icon>
    </ng-container>
    Recommended
  </common-navigation-button>

  <div class="papers-border-top search-navigation__results">
    {{results | number}} {{results !== 1 ? 'results' : 'result'}}
  </div>
  <search-navigation-filters [initialValues]="initialValues" class="search-navigation-filters papers-scrollbar" (valueChange)="onFormChange($event)"></search-navigation-filters>
  <!-- <button class="customize-filters" (click)="openCustomizeFiltersModal()">Customize Filters</button> -->
</div>
