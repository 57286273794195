import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReadCubeCommonModule } from '@readcube/rcp-common';

import { AppCommonModule } from '../common';
import { MetricsArticleComponent } from './components/metrics-article.component';
import { AppMetricsDataModule } from '../metrics-data/metrics-data.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    NgxChartsModule,
    ReadCubeCommonModule,
    AppCommonModule,
    AppMetricsDataModule.forRoot(),
  ],
  declarations: [
    MetricsArticleComponent
  ],
  exports: [
    MetricsArticleComponent
  ]
})
export class AppMetricsModule { }
