<ng-container [ngSwitch]="status$ | async">

  <ng-container *ngSwitchCase="'new'">
    <div class="modal-header">
      <h4 class="modal-title">Start your Papers trial!</h4>
      <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
    </div>

    <div class="modal-body">
      <div *ngIf="error$ | async" class="alert alert-danger">{{error$ | async}}</div>
      <p>To continue using across all of your devices, upgrade below and you’ll be on your way!</p>
      <p>If you want to think about it, you’ll continue to be able to access the content you’ve imported so far, but will no longer be able to add new material and have limited access to search and reading features.</p>
      <p>Would you like to start your trial today?</p>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-lg btn-secondary" (click)="close()">Not right now</button>
      <button type="button" class="btn btn-lg btn-primary" (click)="startTrial()" ngbAutofocus [disabled]="activating$ | async">
        {{(activating$ | async) ? 'Activating...' : 'Start free 30 day trial'}}
      </button>
    </div>
  </ng-container>



  <ng-container *ngSwitchCase="'expired'">
    <div class="modal-header">
      <h4 class="modal-title">Your Papers trial has expired!</h4>
      <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
    </div>

    <div class="modal-body">
      <div *ngIf="error$ | async" class="alert alert-danger">{{error$ | async}}</div>
      <p>To continue with all the powerful features Papers has to offer, upgrade today and you'll be on your way to a research life more organized.</p>
      <p>If you need some time to think about it, please note that you'll be able to read/download contents within your existing personal library. <a href (click)="features(); $event.preventDefault()">Other features</a> such as importing, search, shared collections, sync, advanced metrics, and SmartCite will no longer be available.</p>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-lg btn-secondary" (click)="close()">Not right now</button>
      <button type="button" class="btn btn-lg btn-primary" (click)="upgrade()" ngbAutofocus>Upgrade</button>
    </div>
  </ng-container>



  <ng-container *ngSwitchCase="'active'">
    <div class="modal-header">
      <h4 class="modal-title">Your account is active!</h4>
      <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
    </div>

    <div class="modal-body">
      <div class="text-center text-success">
        <fa-icon [icon]="['fas', 'check']" size="5x"></fa-icon>
      </div>
      <h5 class="text-center">Thanks for activating!</h5>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-link me-auto" (click)="shell.openURL('mailto:support@papersapp.com')">Questions?</button>
      <button type="button" class="btn btn-lg btn-secondary" (click)="close()" ngbAutofocus>Continue</button>
    </div>
  </ng-container>
</ng-container>
