<ng-template #popoverTemplate>
  <ul>
    <li (click)="setColor('#fe3018')" style="color: #fe3018">
      <fa-icon [icon]="['fas', 'circle']"></fa-icon>
    </li>
    <li (click)="setColor('#fe8e23')" style="color: #fe8e23">
      <fa-icon [icon]="['fas', 'circle']"></fa-icon>
    </li>
    <li (click)="setColor('#fed12f')" style="color: #fed12f">
      <fa-icon [icon]="['fas', 'circle']"></fa-icon>
    </li>
    <li (click)="setColor('#00d127')" style="color: #00d127">
      <fa-icon [icon]="['fas', 'circle']"></fa-icon>
    </li>
  </ul>

  <ul>
    <li (click)="setColor('#1ea4fc')" style="color: #1ea4fc">
      <fa-icon [icon]="['fas', 'circle']"></fa-icon>
    </li>
    <li (click)="setColor('#fd8afc')" style="color: #fd8afc">
      <fa-icon [icon]="['fas', 'circle']"></fa-icon>
    </li>
    <li (click)="setColor('#bfbfbf')" style="color: #bfbfbf">
      <fa-icon [icon]="['fas', 'circle']"></fa-icon>
    </li>
    <li (click)="setColor(null)">
      <fa-icon [icon]="['far', 'circle']"></fa-icon>
    </li>
  </ul>
</ng-template>

<div class="brush" #p="ngbPopover"
  (click)="showBrushes($event)"
  [ngStyle]="{'color': color || 'inherit'}"
  [ngClass]="{'hidden': !showEmpty && !color}"
  [ngbPopover]="popoverTemplate"
  triggers="manual"
  container="body">

  <fa-icon [icon]="[color ? 'fas' : 'far', 'circle']"></fa-icon>
</div>
