<div class="brand papers-border-bottom papers-button" ngbDropdown [autoClose]="true" container="body">
  <div class="module-info no-caret" ngbDropdownToggle>
    <div class="module-name">Library <div class="powered-by">Powered by <common-icon-readcube width="12px" height="12px">ReadCube</common-icon-readcube></div></div>
  </div>

  <div class="dropdown-menu dropdown-menu-left" style="min-width: 250px" ngbDropdownMenu>
    <app-user-menu></app-user-menu>
  </div>
</div>

<div class="menu papers-scrollbar papers-border-right" (scroll)="closeConetxMenus()">
  <common-navigation-button
    [disabled]="false"
    [routerLink]="['/library', 'search']"
    [ngClass]="{ 'active': isRouteActive('/library/search') }">
    <ng-container icon>
      <rcp-svg-icon name="search"></rcp-svg-icon>
    </ng-container>
    Search
  </common-navigation-button>

  <div class="ms-2 mt-4 mb-2 d-flex justify-content-between papers-label"> My Library
    <span style="padding-right: 8px;">
      <a href rcpPreventDefault >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
      </a>
    </span>
  </div>

  <library-navigation-collection
    *ngFor="let collection of privateCollections$ | async; trackBy: collectionTrackByFn;"
    [collection]="collection"
    [expanded]="navigation.isCollectionExpanded(collection.id) ?? true">
  </library-navigation-collection>

  <div class="ms-2 mt-4 mb-2 d-flex justify-content-between papers-label"> Shared Libraries
    <span style="padding-right: 8px" *ngIf="canCreateCollection()" (click)="createCollection()" ngbTooltip="Create shared library">
      <a href rcpPreventDefault >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
      </a>
    </span>
  </div>

  <library-navigation-collection-shared
    *ngFor="let collection of sharedCollections$ | async; trackBy: collectionTrackByFn;"
    [collection]="collection"
    [expanded]="navigation.isCollectionExpanded(collection.id) ?? false"
    [activated]="licenseActive$ | async">
  </library-navigation-collection-shared>

  <common-navigation-button
    *ngIf="(archivedCollections$ | async)?.length > 0"
    [expandable]="true"
    [(expanded)]="archivedExpanded"
    (click)="archivedExpanded = !archivedExpanded">
    <ng-container icon>
      <rcp-svg-icon name="list"></rcp-svg-icon>
    </ng-container>
    Archive

    <ng-template #expandedTemplate>
      <library-navigation-collection-shared
        *ngFor="let collection of archivedCollections$ | async; trackBy: collectionTrackByFn;"
        [collection]="collection"
        [expanded]="false"
        [activated]="false"
        [indent]="1">
      </library-navigation-collection-shared>
    </ng-template>
  </common-navigation-button>
</div>

<ng-container *ngIf="importer.totalCnt$ | async as totalCnt">
  <div class="imports papers-border-right">
    <importer-progress></importer-progress>
  </div>
</ng-container>

<div class="footer papers-border-top" *ngIf="!isRouteActive('/library/ai')">
  <span *ngIf="statusText$ | async as text">
    <fa-icon [icon]="['fas', 'spinner-third']" [spin]="true"></fa-icon> {{text | capitalize}}
  </span>
</div>
