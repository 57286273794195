
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataRecommendApiService } from './services/data-recommend-api.service';
import { DataSearchApiService } from './services/data-search-api.service';
import { DataSearchService } from './services/data-search.service';
import { DataRecommendService } from './services/data-recommend.service';
import { AppCommonDataModule } from '../common-data';

@NgModule({
  imports: [
    CommonModule,
    AppCommonDataModule.forRoot()
  ],
  providers: [
    DataRecommendApiService,
    DataSearchApiService,
  ]
})
export class AppSearchDataModule {
  static forRoot(): ModuleWithProviders<AppSearchDataModule> {
    return {
      ngModule: AppSearchDataModule,
      providers: [
        DataSearchService,
        DataRecommendService
      ]
    };
  }
}
