<ng-container *ngIf="tags$ | async as tags">
  <common-navigation-button
    *ngIf="searchTerm || tags.total > 0"
    [disabled]="dragActive$ | async"
    [indent]="indent+1"
    [expandable]="true"
    [(expanded)]="expanded"
    (click)="setExpanded(!expanded, true)">
    <ng-container icon>
      <rcp-svg-icon name="tag"></rcp-svg-icon>
    </ng-container> Tags

    <ng-template #expandedTemplate>
      <common-navigation-button
        *ngIf="searchTerm || tags.total > 5"
        [indent]="indent+2">
        <ng-container icon>
          <rcp-svg-icon name="search"></rcp-svg-icon>
        </ng-container>
        <input #searchInput type="text"
          autocomplete="false" spellcheck="false"
          [(ngModel)]="searchTerm"
          (ngModelChange)="updateFilter($event)"
          placeholder="Type to filter tags...">
      </common-navigation-button>

      <library-navigation-tag
        *ngFor="let tag of tags.items; trackBy: trackTagById"
        [indent]="indent+2"
        [collection]="collection"
        [highlight]="searchTerm"
        [tag]="tag">
      </library-navigation-tag>

      <common-navigation-button
        *ngIf="tags.total > tags.items.length"
        [indent]="indent+2"
        (click)="showMoreTags.next(pageSize)">
        <ng-container icon>
          <rcp-svg-icon name="arrow-down"></rcp-svg-icon>
        </ng-container>
        <span class="btn-link">Show more...</span>
      </common-navigation-button>
    </ng-template>
  </common-navigation-button>
</ng-container>
