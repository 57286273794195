<form>
  <div class="modal-header">
    <h4 class="modal-title">{{ (public$ | async) ? 'Public List Sharing': 'Share as a Public List' }} </h4>
    <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body">
    <div [hidden]="!(error$ | async)" class="alert alert-danger">{{error$ | async}}</div>

    <div *ngIf="!(public$ | async), else publicBodyTemplate">
      By enabling public lists feature on <strong>{{ name }}</strong>, you are sharing a list of references publicly. Note, your private notes,
      annotations and files will not be shared.
      <br>
      <br>
      <button
        type="button"
        class="btn btn-lg btn-primary"
        *ngIf="!(public$ | async) && !(loading$ | async)"
        (click)="onShareClick()">Create Public List
      </button>
      <fa-icon
        *ngIf="loading$ | async"
        [icon]="['fas', 'spinner-third']"
        [spin]="true"
        size="2x">
      </fa-icon>
    </div>

    <ng-template #publicBodyTemplate>
      <strong>{{ name }}</strong> has been shared as a public list.
      <br>
      <br>
      <i>Note, your private notes, annotations and files will not be shared.</i>
      <br>
      <br>
      Use the URL below to share this list:
      <br>
      <br>
      <div class="input-group link">
        <span class="input-group-text" (click)="shell.openURL(url)">
          <fa-icon [icon]="['fal', 'link']"></fa-icon>
        </span>
        <input type="text" class="form-control" [value]="url" readonly>
      </div>
    </ng-template>

  </div>

  <div class="modal-footer" *ngIf="public$ | async" >
    <button
      type="button"
      class="btn btn-lg btn-secondary"
      ngbAutofocus
      (click)="onStopClick()"
      [disabled]="loading$ | async">
      Stop Sharing
    </button>

    <button type="button" class="btn btn-lg btn-primary" (click)="copyUrl()">Copy URL</button>
  </div>
</form>
