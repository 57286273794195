<div class="modal-header">
    <h4 class="modal-title">PDF Downloading Not Available</h4>
    <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body">
    <p>PDF downloading within this web library app requires the <a href="{{extension.webstoreURL}}" target="_blank">Papers Web Importer</a> browser extension.</p>
    <p>The extension is not yet available for your current browser, although we are working on this.</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="close()">Close</button>
  </div>
