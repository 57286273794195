import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'query-label-dropdown',
  templateUrl: './query-label-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QueryLabelDropdownComponent),
      multi: true
    }
  ]
})
export class QueryLabelDropdownComponent implements ControlValueAccessor {
  value: string;
  onChange: (value: string) => any;
  onTouched: () => any;

  @Input()
  options: {[key: string]: string};

  markTouched() {
    this.onChange(this.value);
    this.onTouched();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }
}
