import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'common-icon-dimensions',
  templateUrl: './common-icon-dimensions.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CommonIconDimensionsComponent {
  @Input()
  width: number = 136;

  @Input()
  height: number = 24;

  get viewBox() {
    return `0 0 ${this.width} ${this.height}`;
  }
}
