import { Injectable } from '@angular/core';

export interface QueryField {
  key: string;
  display: string;
}

export interface QueryFormOption {
  operator: QueryOperator;
  value: QueryFormValue;
  field?: QueryField;
}

export interface QueryFormValue {
  type: QueryFormValueType;
  input_text?: string;
  input_bool?: boolean;
  input_range?: [null | number, null | number],
}

export type QueryFormValueType = 
  'is' |
  'is_not' |
  'is_after' |
  'is_before' |
  'is_more' |
  'is_less' |
  'date' |
  'date_after' |
  'date_before' |
  'range' |
  'boolean' |
  'exists';

export interface QueryFormData {
  words: QueryFormOption[];
  fields: QueryFormOption[];
}

export enum QueryOperator {
  Not = 'NOT',
  And = 'AND',
  Or = 'OR',
  To = 'TO',
  Undefined = '',
}

export abstract class Query {
  abstract isComplete(): boolean;
  abstract getOperator(): QueryOperator;
  abstract toString(): string;

  escapeValue(value: string): string {
    return value.includes(' ') ? `"${value}"` : value;
  }
}

export class QueryWords extends Query {
  constructor(
    private option: QueryFormOption,
  ) {
    super();
  }

  isComplete(): boolean {
    return !!this.option.value.input_text;
  }

  getOperator() {
    return this.option.operator;
  }

  toString(): string {
    const words = this.escapeValue(this.option.value.input_text);
    switch (this.option.value.type) {
      case 'is':
        return words;
      case 'is_not':
        return `NOT(${words})`;
    }
    return '*';
  }
}

export class QueryField extends Query {
  constructor(
    private option: QueryFormOption,
  ) {
    super();
  }

  isComplete(): boolean {
    if (!this.option.field) {
      return false;
    }
    const value =  this.option.value;
    switch (value.type) {
      case 'is':
      case 'is_not':
      case 'is_more':
      case 'is_less':
      case 'is_after':
      case 'is_before':
      case 'date':
      case 'date_after':
      case 'date_before':
        return !!value.input_text;
      case 'range':
        return !!value.input_range[0] || !!value.input_range[1];
      case 'boolean':
      case 'exists':
        return value.input_bool === true || value.input_bool === false;
    }
  }

  getOperator() {
    return this.option.operator;
  }

  toString(): string {
    if (!this.isComplete()) {
      return '';
    }
    const key = this.option.field.key;
    const value =  this.option.value;
    switch (value.type) {
      case 'is':
        return `${key}:${this.escapeValue(value.input_text)}`;
      case 'is_not':
        return `NOT(${key}:${this.escapeValue(value.input_text)})`;
      case 'is_after':
        return `${key}:>=${value.input_text}`;
      case 'is_more':
        return `${key}:>${value.input_text}`;
      case 'is_before':
        return `${key}:<=${value.input_text}`;
      case 'is_less':
        return `${key}:<${value.input_text}`;
      case 'date':
        return `${key}:${value.input_text}`;
      case 'date_after':
        return `${key}:>=${value.input_text}`;
      case 'date_before':
        return `${key}:<=${value.input_text}`;
      case 'range':
        return `${key}:[${value.input_range[0]||'*'} TO ${value.input_range[1]||'*'}]`;
      case 'boolean':
        return value.input_bool ? `${key}:true` : `NOT(${key}:true)`;
      case 'exists':
        const exists_query = `_exists_:${key}`;
        return value.input_bool ? exists_query : `NOT(${exists_query})`;
    }
  }
}

export class QueryComplex extends Query {
  value: Query[] = [];

  addQuery(query: Query) {
    this.value.push(query);
  }

  removeQuery(index: number) {
    this.value.splice(index, 1);
  }

  isComplete(): boolean {
    return this.value.every(part => part.isComplete());
  }

  getOperator() {
    return QueryOperator.And;
  }

  toString(): string {
    return this.value.reduce((prev, curr) => {
      if (!curr.isComplete()) {
        return prev;
      }
      const q = curr.toString();
      if (prev.length == 0) {
        return prev.concat(q);
      }
      switch (curr.getOperator()) {
        case QueryOperator.And:
          return prev.concat(` AND ${q}`);
        case QueryOperator.Or:
          return prev.concat(` OR ${q}`);
        default:
          return prev.concat(` ${q}`);
      }
    }, [] as string[]).join('').trim();
  }
}

@Injectable()
export class QueryBuilderService {
  serialize(formFields: QueryFormData): string {
    const query = new QueryComplex();
    for (let f of formFields.words) {
      query.addQuery(new QueryWords(f));
    }
    for (let f of formFields.fields) {
      query.addQuery(new QueryField(f));
    }
    return query.toString();
  }

  parseQueryFragment(fragment: string): QueryFormData | null {
    if (!fragment) return null;
    try {
      return JSON.parse(atob(fragment));
    } catch (ex) {
      console.warn('failed to parse query fragment');
    }
    return null;
  }

  toQueryFragment(data: QueryFormData) {
    return btoa(JSON.stringify(data));
  }
}
