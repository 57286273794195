import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, filter, finalize, mergeMap, of, shareReplay, switchMap, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import Viewer from '@readcube/viewer-frame';
import { DataUserService, IDataUser } from '../../common-data';
import { DataCollectionService, DataItemService, DataAiService, IDataCollection, IDataItem, IAiQueryResponse, DataListService, IDataList } from '../../library-data';

@Component({
  templateUrl: './library-ai-landing.component.html',
  styleUrls: ['./library-ai-landing.component.scss']
})
export class LibraryAiLandingComponent implements OnDestroy {
  @ViewChild('pdfContainer', { static: true })
  pdfContainerRef: ElementRef<HTMLDivElement>;

  date: Date;
  collection: IDataCollection;
  list: IDataList;
  total: number;
  user$: Observable<IDataUser>;

  formShown: boolean = true;
  question: string;

  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>('');

  aiResponses: IAiQueryResponse[] = [];
  summary: { plainText: string; superscriptTags: string[]; }[] = [];

  hashItemMap: { [key: string]: IDataItem; } = {};
  items: IDataItem[] = [];
  itemsShown: boolean = false;

  viewerShown: boolean = false;
  viewerInstance: any;

  progress: number = 0;
  queryProgress: number;
  summarizeProgress: number;

  constructor(
    public route: ActivatedRoute,
    public dataCollection: DataCollectionService,
    public dataList: DataListService,
    public dataItem: DataItemService,
    public dataAi: DataAiService,
    public dataUser: DataUserService
  ) { }

  ngOnInit() {
    this.date = new Date();

    this.user$ = this.dataUser.get().pipe(
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.route.queryParams.pipe(
      tap(() => this.resetQuery()),
      switchMap(params => combineLatest([
        this.dataCollection.get(params['collection_id']),
        params['list_id'] ? this.dataList.get(params['collection_id'], params['list_id']) : of(null),
        this.dataItem.query({ collection_id: params['collection_id'], list_id: params['list_id'] })
      ]))
    ).subscribe(result => {
      this.collection = result[0];
      this.list = result[1];
      this.total = this.dataItem.total;
    });
  }

  ngOnDestroy(): void {
    this.viewerInstance?.destroy();
  }

  submit() {
    this.loading$.next(true);
    this.setRandomProgress();
    this.formShown = false;

    this.dataAi.getQuery(this.question).pipe(
      filter(query => {
        if (!query) {
          this.error$.next('Something went wrong. Please try a new query.');
          this.loading$.next(false);
        }
        return !!query;
      }),
      tap(() => this.progress = this.queryProgress),
      switchMap(query => this.dataItem.query({
        collection_id: this.collection.id,
        list_id: this.list?.id,
        query: `${query} AND file_type:pdf`,
        size: 10
      })),
      filter(items => {
        if (!items?.length) {
          this.error$.next(`${this.list ? 'List' : 'Library'} doesn't have any references relevant for this query.`);
          this.loading$.next(false);
        }
        return !!items?.length;
      }),
      tap(items => {
        items.forEach(item => {
          this.hashItemMap[item.primary_file_hash] = { ...item };
        });
      }),
      switchMap(items => this.dataAi.query(items, this.question)),
      tap(result => {
        this.progress = this.queryProgress + (100 - this.queryProgress - this.summarizeProgress) * result.progress;
      }),
      filter(result => !!result.responses?.length),
      tap(result => {
        this.aiResponses = result.responses;
        this.aiResponses.forEach(response => {
          this.items.push(this.hashItemMap[response.pdf_hash]);
        });
      }),
      mergeMap(res => this.dataAi.summarize(res.responses.map(r => r.answer))),
      finalize(() => {
        this.loading$.next(false);
      })
    ).subscribe(summaryText => {
      this.progress = 100;
      this.summary = this.parseSummary(summaryText as string);
    }, err => this.error$.next('Something went wrong. Please try a new query.'));
  }

  parseSummary(text: string): { plainText: string; superscriptTags: string[]; }[] {
    const regex = /<sup>(\d+)<\/sup>|([^<]+)/g;
    const matches = text.matchAll(regex);

    const parsedText = [];
    for (const match of matches) {
      if (match[1]) {
        parsedText.push({
          text: match[1],
          isSuperscript: true
        });
      } else {
        parsedText.push({
          text: match[0],
          isSuperscript: false
        });
      }
    }
    return parsedText;
  }

  async openItemPDF(order: number) {
    this.viewerInstance?.destroy();

    this.viewerShown = true;
    const item = this.items[order - 1];
    const url = await this.dataItem.getViewerURL(item.collection_id, item.id);
    this.viewerInstance = await Viewer.create({
      container: this.pdfContainerRef.nativeElement,
      pdf: url
    });
    const excerpt = this.aiResponses[order - 1]?.excerpts[0];
    excerpt && await this.viewerInstance.excerpt.render(excerpt);
  }

  setRandomProgress() {
    // sync progress approx. is in range from 5% to 15%
    this.queryProgress = Math.floor(Math.random() * 10 + 5);
    // summarize progress approx. is in range from 15% to 25%
    this.summarizeProgress = Math.floor(Math.random() * 10 + 15);
  }

  getResponse(i: number) {
    return this.aiResponses[i].answer.replace(/<sup>\d+<\/sup>/g, '').slice(0, 200) + '...';
  }

  resetQuery() {
    this.formShown = true;
    this.question = null;
    this.aiResponses = [];
    this.summary = [];
    this.items = [];
    this.hashItemMap = {};
    this.itemsShown = false;
    this.viewerShown = false;
    this.progress = 0;
    this.error$.next('');
  }
}