import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  templateUrl: './common-dialog-progress.component.html'
})
export class CommonDialogProgressComponent {
  title = 'Please wait...';
  message$ = new Subject<string>();
  progress$ = new Subject<number | boolean>();
  stop$ = new Subject<void>();
  done$ = new BehaviorSubject<boolean>(false);
  stoppable = false;

  constructor(
    protected activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.close();
  }

  stop() {
    this.stop$.next();
    this.activeModal.close();
  }
}
