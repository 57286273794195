import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AppShellService } from '../../app-shell.service';
import { DataUserService } from '../../common-data';

import { environment } from 'environment';

@Component({
  templateUrl: './modal-subscription.component.html'
})
export class ModalSubscriptionComponent implements OnInit {
  error$ = new ReplaySubject<string>(1);
  activating$ = new ReplaySubject<boolean>(1);
  status$: Observable<string>;

  subscribeURL = environment.baseUrls.papers + '/pricing?utm_source=app&utm_medium=banner&utm_campaign=upgrade';
  featuresURL = environment.baseUrls.papers + '/features';

  constructor(
    public activeModal: NgbActiveModal,
    public user: DataUserService,
    public shell: AppShellService,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.status$ = this.user.get(false).pipe(
      map(user => {
        if (!user.licence.active) {
          return user.licence.used_trial ? 'expired' : 'new';
        } else {
          return 'active';
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  startTrial() {
    this.error$.next(null);
    this.activating$.next(true);
    this.user.activate().then(response => {
      this.activating$.next(false);
      if (response.success) {
        this.activeModal.close();

        if (this.route.snapshot.queryParams['action']) {
          window.location.reload();
        }
      } else {
        this.error$.next(response.error);
      }
    });
  }

  upgrade() {
    this.shell.openURL(this.subscribeURL);
  }

  features() {
    this.shell.openURL(this.featuresURL);
  }

  close() {
    this.user.reload();
    this.activeModal.close();
  }
}
