import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environment';
import { firstValueFrom } from 'rxjs';

export interface IDataStylesInfo {
  custom_styles: IDataStyle[];
  default_style_id: string;
  favorite_style_ids: string[];
}

export interface IDataStyle {
  id: string;
  title: string;
  href: string;
}

@Injectable()
export class DataStylesApiService {
  public lastUsedStyle: string;

  constructor(
    private http: HttpClient
  ) { }

  public async getMergedStyles(): Promise<IDataStyle[]> {
    return firstValueFrom(this.http.get<IDataStyle[]>(`${environment.baseUrls.smartciteAssets}/merged-styles.json`));
  }

  public async getStyleInfo(): Promise<IDataStylesInfo> {
    return firstValueFrom(this.http.get<IDataStylesInfo>(`${environment.baseUrls.services}/citation_styles/info`));
  }

  public async getStyle(style?: IDataStyle): Promise<any> {
    return firstValueFrom(this.http.get(style.href, { responseType: 'text' }));
  }
}
