import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';;
import { map, mergeMap, first, distinctUntilChanged, skip } from 'rxjs/operators';

import { AppShellService } from '../../app-shell.service';
import { ReaderService, SharedService } from '../../common';
import { IDataItemExtIds } from '../../common-data';
import { LibraryService } from '../../library';
import { DataItemService, DataListService, IDataItem, IDataList, IDataCollection, IDataCollectionCustomField } from '../../library-data';
import { ExtensionService, ExtensionDownload } from '../../extension';
import { ImporterService, IImportJob } from '../../importer';
import { PreviewService } from '../../library-preview';
import { LibrarySidepanelService } from '../services/library-sidepanel.service';

import { environment } from 'environment';

@Component({
  templateUrl: './library-sidepanel-details.component.html',
  styleUrls: [
    './library-sidepanel.component.scss',
    './library-sidepanel-details.component.scss'
  ]
})
export class LibrarySidepanelDetailsComponent implements OnInit, OnDestroy {
  item: IDataItem;
  collection: IDataCollection;
  authorsCollapsed = true;
  authorsUsePipe = true;
  authorsLimit = 5;

  import: IImportJob;
  download: ExtensionDownload;

  canViewPDF: boolean;
  canGetPDF: boolean;
  canLocatePDF: boolean;
  canViewPubmed: boolean;
  canDownloadFile: boolean;
  hasLinkResolverURL: boolean;
  thumbURL: string;
  lists$: Observable<IDataList[]>;

  protected itemSub: Subscription;
  protected collectionSub: Subscription;
  protected importerRunSub: Subscription;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public dataItem: DataItemService,
    public dataList: DataListService,
    public library: LibraryService,
    public reader: ReaderService,
    public sidepanel: LibrarySidepanelService,
    public shared: SharedService,
    public extension: ExtensionService,
    public importer: ImporterService,
    public preview: PreviewService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.itemSub = this.sidepanel.item$.subscribe(item => this.onItemChange(item));

    this.collectionSub = this.sidepanel.collection$.subscribe(collection => {
      this.collection = collection;
    });

    this.importerRunSub = this.importer.doneCnt$.pipe(
      distinctUntilChanged(),
      skip(1),
    ).subscribe(() => this.reloadItem());

    this.download = this.extension.getDownload(this.item?.ext_ids.doi);

    this.lists$ = this.sidepanel.item$.pipe(
      mergeMap(item => {
        return this.dataList.getAllByCollectionId(item.collection_id).pipe(
          map(lists => lists.filter(list => item.list_ids.includes(list.id))),
          map(lists => lists.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }))
        );
      })
    );

    this.sidepanel.setActive('details');
  }

  ngOnDestroy() {
    this.itemSub.unsubscribe();
    this.collectionSub.unsubscribe();
    this.importerRunSub.unsubscribe();
  }

  onAuthorsToggleClick(event: Event) {
    this.authorsCollapsed = !this.authorsCollapsed;
    event.preventDefault();
  }

  onAuthorClick(event: MouseEvent, author: string) {
    this.router.navigate(['search', 'query'], {
      queryParams: {
        query: ['author', '"'+author+'"'].join(':')
      },
      relativeTo: this.route.root
    });
    event.preventDefault();
  }

  onThumbClick(event: MouseEvent) {
    if (this.canViewPDF) {
      this.viewPDF(event);
    } else if (this.canGetPDF) {
      this.getPDF(event);
    } else if (this.canLocatePDF) {
      this.locatePDF(event);
    }
  }

  onItemChange(item: IDataItem) {
    this.download = this.extension.getDownload(item.ext_ids.doi);
    this.import = this.importer.getImport(item.id);

    this.canGetPDF = !item.primary_file_hash && this.reader.canGetPDF(item);
    this.canViewPDF = this.library.canViewItemPDF(item);
    this.canLocatePDF = !item.primary_file_hash && this.library.canLocateItemPDF(item);

    this.canDownloadFile = this.library.canDownloadFile(item);
    this.hasLinkResolverURL = this.library.hasLinkResolverURL(item, this.shared.user);
    this.thumbURL = this.library.getThumbnailURL(item);
    this.item = item;
  }

  viewPDF(event: MouseEvent) {
    this.library.viewItemPDF(this.item);
  }

  openResolver(item: IDataItem) {
    this.sidepanel.open(this.route.parent, [item], 'resolve').then(() => {
      this.preview.showResolve(this.route.parent, item);
    });
  }

  downloadFromCloud(): void {
    const collectionId = this.item.collection_id, itemId = this.item.id;
    this.dataItem.downloadFromCloud(collectionId, itemId)
      .then(item => this.onItemChange(item));
  }

  getPDF(event: MouseEvent) {
    this.reader.getPDF(this.item);
  }

  locatePDF(_: MouseEvent) {
    if (!this.extension.isInstalled()) {
      this.extension.openInstallExtensionDialog();
      return;
    }
    const item = this.item;
    const collectionId = this.item.collection_id;
    const itemId = this.item.id;
    const doi = this.item.ext_ids.doi;
    const env = environment.name;
    const download = this.extension.downloadPDF({ doi, env });
    const fileName = this.extension.getFileNameFor(this.item);

    download.success$.subscribe(event => {
      const file = this.extension.getDownloadedFile(event, event.fileName || fileName);
      const job = this.importer.addFile(file, collectionId, null, itemId);
      job.id = itemId;
      this.importer.start();
    });
    download.failed$.subscribe(event => {
      if (event.error == 'failed' || event.error == 'not_found') {
        this.extension.openDownloadFailedDialog(doi, item);
      }
    });
  }

  reloadItem() {
    this.dataItem.get(this.item.collection_id, this.item.id)
      .pipe(first()).subscribe(item => {
        this.dataItem.pushDownstream([item], 'update');
      });
  }

  viewPubmed() {
    this.shell.openURL(`https://pubmed.ncbi.nlm.nih.gov/${this.item.ext_ids.pmid}`);
  }

  setRating(rating: number) {
    this.dataItem.update(this.item.collection_id, this.item.id, {
      user_data: { rating: rating }
    });
  }

  setColorLabel(color: string) {
    this.dataItem.update(this.item.collection_id, this.item.id, {
      user_data: { color: color }
    });
  }

  setFlag(value: boolean) {
    this.dataItem.update(this.item.collection_id, this.item.id, {
      user_data: { star: value }
    });
  }

  openEditTags() {
    this.sidepanel.openEditTags(this.item);
  }

  openEditLists() {
    this.sidepanel.openEditLists(this.item, this.collection);
  }

  openWebByExtId(extId: string, type: keyof IDataItemExtIds) {
    this.shared.openPublisherById(extId, type);
  }

  openMetrics() {
    if (this.item.ext_ids.doi) {
      this.shell.openURL(`https://badge.dimensions.ai/details/doi/${this.item.ext_ids.doi}`);
    }
  }

  openWebByLinkResolverURL() {
    if (!this.hasLinkResolverURL) {
      return;
    }

    const q = this.shared.user?.link_resolver_url.indexOf('?') > -1;
    let url = `${this.shared.user?.link_resolver_url}${q ? '&' : '?'}rft_id=info:doi/${this.item.ext_ids.doi}`;

    url = url + `&id=DOI:${this.item.ext_ids.doi}`;

    if (this.item.article?.title)
      url = url + `&atitle=${this.item.article.title}`;

    if (this.item.article?.journal)
      url = url + `&title=${this.item.article.journal}`;

    if (this.item.article?.authors?.length && this.item.article.authors[this.item.article.authors.length - 1])
      url = url + `&aulast=${this.item.article.authors[this.item.article.authors.length - 1]}`;

    if (this.item.article?.year)
      url = url + `&date=${this.item.article.year}`;

    if (this.item.article?.pagination)
      url = url + `&pages=${this.item.article.pagination}`;

    if (this.item.article?.issue)
      url = url + `&issue=${this.item.article.issue}`;

    if (this.item.article?.volume)
      url = url + `&volume=${this.item.article.volume}`;

    if (this.item.article?.issn)
      url = url + `&issn=${this.item.article.issn}`;

    this.shell.openURL(url);
  }

  hasMetrics(): boolean {
    return !!this.item.metrics_data && (
      !!this.item.metrics_data.times_cited ||
      !!this.item.metrics_data.field_citation_ratio);
  }

  hasCustomFields() {
    let fields = this.collection?.custom_fields || [];
    return fields.some(f => f.show_in_details && this.item.custom_metadata?.hasOwnProperty(f.field));
  }

  getCustomFieldValue(field: IDataCollectionCustomField) {
    const value = this.item.custom_metadata[field.field];

    if (!value)
      return value;

    if (field.type === 'bool') {
      return value === 'true' ? 'yes' : (value === 'false') ? 'no' : null;
    }

    if (field.type === 'array' || field.type === 'array-lookup') {
      try {
        const parsed = JSON.parse(value);
        if (parsed && typeof parsed === "object" && parsed.join) {
          return parsed.join(', ');
        }
      }
      catch (e) { }
    }

    return value;
  }

  openCustomFieldSearch(event: MouseEvent, field: IDataCollectionCustomField) {
    let value = this.item.custom_metadata[field.field];
    if (field.type === 'bool') {
      value = value ? 'true' : 'false';
    }
    else if (field.type === 'array' || field.type === 'array-lookup') {
      try {
        value = JSON.parse(value);
      }
      catch (e) {
      }
    }
    else if (value.includes(' ')) {
      value = '"' + value + '"';
    }

    const query = Array.isArray(value) ? value.map(v => [field.key, v].join(':')).join(' AND ') : [field.key, value].join(':');

    this.router.navigate(['/library', this.collection.id, 'all'], {
      queryParams: { query },
      relativeTo: this.route.root
    });
    event.preventDefault();
  }

  getListPath(listId: string): Observable<IDataList[]> {
    return this.dataList
      .getAllByCollectionId(this.collection.id)
      .pipe(map(lists => {
        let list = lists.find(l => l.id == listId);
        let parent: IDataList;
        let count = 0;
        const path: IDataList[] = [list];
        while (count < environment.maxListsDepth) {
          parent = lists.find(l => l.id === list.parent_id);
          if (!parent) break;
          path.push(parent);
          list = parent;
          count++;
        }
        return path.reverse();
      }));
  }

  isDownloading(): boolean {
    const file = this.item.files.find(f => f.sha256 === this.item.primary_file_hash);
    return file ? file.downloadInProgress : false;
  }
}
