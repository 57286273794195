import { Component, ElementRef, forwardRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'common-input-date',
  templateUrl: './common-input-date.component.html',
  styleUrls: ['./common-input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CommonInputDateComponent,
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommonInputDateComponent),
      multi: true
    }
  ]
})
export class CommonInputDateComponent implements ControlValueAccessor {
  value: string;
  onChange: (str: string) => any;
  onTouched: () => any;

  @Input()
  id: string;

  @Input()
  placeholder: string;

  @Input()
  warning = false;

  @Input()
  isDisabled = false;

  @Input()
  label = '';

  @Input()
  minDate = null;

  @Input()
  maxDate = null;

  constructor(
    public elementRef: ElementRef,
  ) { }

  validate({ value }: FormControl) {
    // TODO: date format can be validated?
    return false;
  }

  change(date: NgbDateStruct) {
    const value = [date.year, date.month, date.day].join('-');
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  markTouched() {
    this.onChange(this.value);
    this.onTouched();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(date: string) {
    this.value = date;
  }
}
