import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { DataCollectionService, DataItemService, IDataCollection, IDataCollectionCustomField } from '../../library-data';
import Papa from 'papaparse';

export function downloadCSV(str: string, filename = 'download.csv') {
  const data = new Blob([str], {type: 'text/csv;charset=utf-8;'});
  const url = window.URL.createObjectURL(data);
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
@Component({
  templateUrl: './modal-edit-collection-fields.component.html',
  styleUrls: ['./modal-edit-collection-fields.component.scss'],
})
export class ModalEditCollectionFieldsComponent implements OnInit {
  @Input()
  collectionId: string;

  collection: IDataCollection;
  fieldTypes = [
    { label: 'Text (Single-line)', value: 'text' },
    { label: 'Text (Multi-line)', value: 'textarea' },
    { label: 'Text (Array)', value: 'array' },
    { label: 'Number', value: 'number' },
    { label: 'Checkbox', value: 'bool' },
    { label: 'Date', value: 'date' },
    { label: 'Lookup', value: 'lookup' },
    { label: 'Lookup (Array)', value: 'array-lookup' }
  ];

  get sections() {
    return [...new Set(this.collection.custom_fields.filter(f => f.section).map(f => f.section))]
  };

  disabled$ = new ReplaySubject<boolean>(1);
  error$ = new ReplaySubject<string>(1);

  @ViewChild('searchInput') searchInput: ElementRef;
  searchTerm = '';

  constructor(
    public activeModal: NgbActiveModal,
    public dataCollection: DataCollectionService,
    public dataItem: DataItemService
  ) { }

  ngOnInit() {
    this.dataCollection.get(this.collectionId).subscribe(collection => {
      this.collection = collection;
    });
  }

  removeField(index: number) {
    this.collection.custom_fields.splice(index, 1);
  }

  addField() {
    this.collection.custom_fields.push({
      key: '',
      display: '',
      field: '',
      type: 'text',
      show_in_details: false,
      show_in_table: true,
    });
  }

  exportToCSV() {
    let data = Papa.unparse(this.collection.custom_fields, {
      header: true,
      delimiter: ',',
      columns: ['type', 'display', 'field', 'lookups', 'section', 'show_in_details', 'show_in_table']
    });
    downloadCSV(data, 'custom_fields.csv');
  }

  importFromCSV(event: HTMLInputEvent) {
    Papa.parse(event.target.files[0], {
      header: true,
      dynamicTyping: true,
      complete: res => {
        res.data.forEach((field: any) => {
          if (field.lookups && typeof field.lookups === 'string') {
            field.lookups = field.lookups.split(',');
          }
        });
        this.collection.custom_fields = res.data as IDataCollectionCustomField[];
      }
    });
  }

  onTypeChange(type: string, field: IDataCollectionCustomField) {
    if (!field.lookups && (type === 'lookup' || type === 'array-lookup')) {
      field.lookups = [];
    }
  }

  submit() {
    const body = <any>{
      id: this.collection.id,
      custom_fields: this.collection.custom_fields
    };
    this.error$.next('');
    this.disabled$.next(true);
    this.dataCollection.update(this.collectionId, body).then(() => {
      this.activeModal.close();
    }).catch(response => {
      this.error$.next(response?.error?.error);
      this.disabled$.next(false);
    });
  }

  close() {
    this.activeModal.close();
  }

  onSearchTermChange(event: HTMLInputEvent) {
    this.searchTerm = event.target.value;
  }

  clearSearch() {
    this.searchTerm = '';
    this.searchInput.nativeElement.value = '';
  }

  isCustomFieldHidden(field: IDataCollectionCustomField) {
    if (!this.searchTerm)
      return false;
    return !field.display?.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase());
  }
}
