import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ExtensionService } from '../services/extension.service';

import { environment } from 'environment';

@Component({
  templateUrl: './modal-extension-install.component.html'
})
export class ModalExtensionInstallComponent {
  error: string;
  onInstalled: Function;

  constructor(
    public activeModal: NgbActiveModal,
    public extension: ExtensionService
  ) { }

  get subscriptionInfoURL() {
    return `${environment.baseUrls.readcube}/pro`;
  }

  install() {
    window.open(this.extension.webstoreURL, '_blank');
  }

  close() {
    this.activeModal.close();
  }
}
