import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, finalize } from 'rxjs';
import { DataCollectionService } from '../../library-data';

export interface IModalBulkExportPDFState {
  confirmed: boolean;
  tokens?: ('journal' | 'journal_abbrev' | 'year' | 'first_author' | 'last_author' | 'pmid')[];
  separator?: '-' | '_' | '.' | ',' | ' ';
}

@Component({
  templateUrl: './modal-bulk-export-pdf.component.html'
})
export class ModalBulkExportPDFComponent {

  collectionId: string;
  listId: string;

  tokens = ['journal_abbrev', 'year'];
  separator = '-';

  availableFileNames: string[];

  fileNameTokens = {
    'journal': 'Journal',
    'year': 'Year',
    'first_author': 'First Author',
    'last_author': 'Last Author',
    'pmid': 'PMID',
    'journal_abbrev': 'Journal Abbreviation'
  };

  fileSeparators = [
    {
      name: 'Hyphen',
      value: '-'
    },
    {
      name: 'Underscore',
      value: '_'
    },
    {
      name: 'Dot',
      value: '.'
    },
    {
      name: 'Comma',
      value: ','
    },
    {
      name: 'Space',
      value: ' '
    }
  ];

  submitted = false;
  disabled$ = new ReplaySubject<boolean>(1);

  constructor(
    public activeModal: NgbActiveModal,
    public dataCollection: DataCollectionService
  ) {
    this.availableFileNames = Object.keys(this.fileNameTokens)
      .filter((v: any) => !this.tokens.includes(v));
  }

  submit() {
    this.disabled$.next(true);
    const template = this.tokens.map(token => `{${token}}`).join(this.separator);
    this.dataCollection.exportPDFs(this.collectionId, this.listId, template).pipe(
      finalize(() => this.disabled$.next(false))
    ).subscribe(result => {
      if (result.success)
        this.submitted = true;
    });
  }

  close() {
    this.activeModal.close();
  }
}
