import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { AppShellService } from '../../app-shell.service';
import { IImportJob } from '../services/importer.service';

@Component({
  selector: 'importer-progress-item',
  templateUrl: './importer-progress-item.component.html',
  styleUrls: ['./importer-progress-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImporterProgressItemComponent implements OnInit {
  @Input()
  job: IImportJob;

  @Output()
  remove = new EventEmitter<MouseEvent>();

  error$: Observable<string>;
  percent$: Observable<number>;
  processing$: Observable<boolean>;
  progress$: Observable<string>;
  type$: Observable<string>;

  constructor(
    public modal: NgbModal,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.percent$ = this.job.progress$.pipe(map(percent => percent));
    this.error$ = this.job.errors$.pipe(map(errors => errors.length ? errors[0] : ''));
    this.processing$ = this.job.status$.pipe(map(status => status === 'pending'));
    this.progress$ = this.job.status$.pipe(mergeMap(status => {
      if (status === 'running') {
        return this.job.progress$.pipe(map(percent => percent + '%'));
      }
      return of({
        'queued': 'Queued',
        'paused': 'Paused',
        'pending': 'Processing...',
        'completed': 'Finished',
        'failed': 'Failed'
      }[status]);
    }));
    this.type$ = this.job.status$.pipe(map(status => {
      return {
        'queued': 'secondary',
        'paused': 'secondary',
        'running': 'primary',
        'pending': 'primary',
        'completed': 'success',
        'failed': 'danger'
      }[status];
    }));
  }

  onRemove(event: MouseEvent) {
    this.remove.emit(event);
    event.preventDefault();
  }
}
