import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, merge as observableMerge, of } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';

import { ContextMenuService, ContextMenuComponent } from '@readcube/ngx-contextmenu';
import { ITokenFieldError } from '@readcube/rcp-token-field';

import { AppShellService } from '../../app-shell.service';
import { DataUserService, DataOrganizationService, IDataOrganizationGroup } from '../../common-data';
import { DataCollectionService, IDataCollectionEditableRequest, IDataCollectionEmail,
  IDataCollection, IDataCollectionEditable, IDataCollectionMember } from '../../library-data';
import { SharedService } from '../services/shared.service';

const INVALID_EMAIL_ERROR = 'This is not valid email format.';
const OUTSIDE_ORGANIZATION_ERROR = `Your enterprise security policies only allow you to invite members within your organization.
If the email you are attempting to add is a member of your organization, please contact your administrator as they may not yet be added to the account.`;
const EMAIL_SHARED_ERROR = 'User with this email already has access to the library.';
const GROUP_SHARED_ERROR = 'This group already has access to the library.';

interface SharingGroup {
  displayName: string;
  memberId: string;
  viewerId: string;
}

@Component({
  templateUrl: './modal-edit-collection.component.html',
  styleUrls: ['./modal-edit-collection.component.scss']
})
export class ModalEditCollectionComponent {
  collectionId: string;
  collectionEditable: IDataCollectionEditable;
  isRenameMode: boolean;
  isRolesMenuOpen: boolean = false;
  invitePeople: boolean = true;

  emails: string[] = [];
  emailsInfo = new Map<string, IDataCollectionEmail>();
  invitationRole: 'owner' | 'admin' | 'member' | 'viewer' = 'member';
  emailText = '';

  updatedUsers: { [key: string]: 'owner' | 'admin' | 'member' | 'viewer'; } = {};
  addedUsers: { [key: string]: 'owner' | 'admin' | 'member' | 'viewer'; } = {};
  invitedUsers: { [key: string]: 'owner' | 'admin' | 'member' | 'viewer'; } = {};
  removedUsers: string[] = [];

  availableGroups: IDataOrganizationGroup[] = [];
  groups: IDataOrganizationGroup[] = [];

  updatedGroups: { [key: string]: 'owner' | 'admin' | 'member' | 'viewer'; } = {};
  addedGroups: { [key: string]: 'owner' | 'admin' | 'member' | 'viewer'; } = {};
  removedGroups: string[] = [];

  disabled$: Observable<boolean>;
  saving$ = new Subject<boolean>();
  error$ = new Subject<string>();
  emailType$: (text$: Observable<string>) => Observable<string[]>;

  canManageRoles: boolean = false;
  memberRoles = {
    'owner': 'Owner',
    'admin': 'Admin',
    'member': 'Member',
    'viewer': 'Viewer'
  };

  memberTypes = {
    user: 'user',
    group: 'group'
  };

  sharingExpanded: boolean = false;
  sharingGroups: SharingGroup[] = [
    {
      'displayName': 'Export PDFs:',
      'memberId': 'member_download_file',
      'viewerId': 'viewer_download_file'
    },
    {
      'displayName': 'Print PDFs:',
      'memberId': 'member_print_file',
      'viewerId': 'viewer_print_file'
    },
    {
      'displayName': 'Create & Manage Annotations:',
      'memberId': 'member_annotate_item',
      'viewerId': 'viewer_annotate_item'
    },
    {
      'displayName': 'Create & Update Items:',
      'memberId': 'member_create_item',
      'viewerId': 'viewer_create_item'
    },
    {
      'displayName': 'Delete Items:',
      'memberId': 'member_delete_item',
      'viewerId': 'viewer_delete_item'
    },
    {
      'displayName': 'Copy to another library:',
      'memberId': 'member_copy_item',
      'viewerId': 'viewer_copy_item'
    },
    {
      'displayName': 'Manage Lists:',
      'memberId': 'member_manage_list',
      'viewerId': 'viewer_manage_list'
    },
    {
      'displayName': 'Manage Smartlists:',
      'memberId': 'member_manage_smartlist',
      'viewerId': 'viewer_manage_smartlist'
    },
    {
      'displayName': 'Manage Tags:',
      'memberId': 'member_tag_item',
      'viewerId': 'viewer_tag_item'
    },
    {
      'displayName': 'Share as public list:',
      'memberId': 'member_publish_list',
      'viewerId': 'viewer_publish_list'
    }
  ];

  @ViewChild('emailInput', { static: true })
  emailInput: ElementRef<HTMLInputElement>;

  @ViewChild('rolesContextMenu', { static: true })
  rolesContextMenu: ContextMenuComponent;

  emailInvitedList: string[] = [];
  groupInvitedList: number[] = [];

  searchGroups$: (text$: Observable<string>) => Observable<IDataOrganizationGroup[]>;

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public router: Router,
    public modal: NgbModal,
    public activeModal: NgbActiveModal,
    public contextMenu: ContextMenuService,
    public dataCollection: DataCollectionService,
    public dataUser: DataUserService,
    public dataOrganization: DataOrganizationService,
    public shared: SharedService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.canManageRoles = this.shared.user.licence.collection_roles_enabled;

    if (this.collectionId) {
      this.loading$.next(true);
      this.dataCollection.getEditable(this.collectionId).subscribe(collection => {
        this.loading$.next(false);
        this.collectionEditable = collection;
        this.canManageRoles = this.shared.user.licence.collection_roles_enabled && !!collection.organization;

        this.emailInvitedList = this.collectionEditable.members.filter(member => member.type === this.memberTypes.user).map(user => user.email);
        this.groupInvitedList = this.collectionEditable.members.filter(member => member.type === this.memberTypes.group).map(group => <any>group.id);
        this.updateEmailsInfo(this.emailInvitedList, true);
      });
    }

    if (!this.isRenameMode && this.shared.user.organization_id)
      this.dataOrganization.query().subscribe(organization => {
        this.availableGroups = organization.groups.filter(group => !group.include_all);
      });

    this.disabled$ = this.saving$.pipe(startWith(false));

    this.searchGroups$ = (text$: Observable<string>) => text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),

      mergeMap(term => {
        if (!term.length || term.length < 3) {
          return of(this.availableGroups);
        }
        term = term.toLowerCase();
        return of(this.availableGroups.filter(group => group.name.toLocaleLowerCase().includes(term)));
      }));
  }

  updateEmailsInfo(emails: string[], alreadyInvited: boolean = false) {
    this.emailText = '';
    this.dataCollection.api.checkEmails(emails).then(emailsInfo => {
      this.invitedUsers = {};
      this.addedUsers = {};

      emailsInfo.forEach(info => {
        this.emailsInfo.set(info.email, info);
        if (alreadyInvited)
          return;

        if (info.status === 'unregistered')
          this.invitedUsers[info.email] = this.invitationRole;

        if (info.id)
          this.addedUsers[info.id] = this.invitationRole;
      });
    });
  }

  openRolesMenu(event: MouseEvent, user: IDataCollectionMember) {
    const roles = Object.keys(this.memberRoles);
    if (!this.isRolesMenuOpen) {
      this.contextMenu.show.next({
        contextMenu: this.rolesContextMenu,
        anchorElement: event.currentTarget,
        activeMenuItemIndex: roles.indexOf(user.role),
        event: event,
        item: user
      });
      event.stopPropagation();
    }
  }

  setExistingMemberRole(member: IDataCollectionMember, role: 'owner' | 'admin' | 'member' | 'viewer') {
    member.role = role;

    member.type === this.memberTypes.user
      ? this.updatedUsers[member.id] = role
      : this.updatedGroups[member.id] = role;
  }

  onRemoveMemberClick(event: MouseEvent, removedMember: IDataCollectionMember) {
    this.collectionEditable.members = this.collectionEditable.members.filter(member => member.id !== removedMember.id);

    removedMember.type === this.memberTypes.user
      ? this.removedUsers.push(removedMember.id)
      : this.removedGroups.push(removedMember.id);

    event.preventDefault();
  }

  isPending(email: string): boolean {
    const info = this.emailsInfo.get(email);
    return info ? info.status === 'invite_pending' : false;
  }

  isRegistered(email: string): boolean {
    const info = this.emailsInfo.get(email);
    return info ? info.status === 'registered' : false;
  }

  getTitle(): string {
    if (!this.collectionId) {
      return 'Create Shared Library';
    }
    if (this.isRenameMode) {
      return 'Rename Shared Library';
    }
    return 'Share with People and Groups';
  }

  submit() {
    let promise: Promise<IDataCollection>;
    const body: Partial<IDataCollectionEditableRequest> = {};

    if (this.isRenameMode || !this.collectionId) {
      body.name = this.collectionEditable.name;
    }

    if (!this.isRenameMode) {
      body.invite_users = this.invitedUsers;
      body.add_users = {
        ...this.addedUsers,
        ...this.updatedUsers
      };
      body.remove_users = this.removedUsers;

      body.add_groups = {
        ...this.addedGroups,
        ...this.updatedGroups
      };
      body.remove_groups = this.removedGroups;

      body.role_settings = this.collectionEditable.role_settings;
    }

    if (this.collectionId) {
      promise = this.dataCollection.update(this.collectionId, body);
    } else {
      promise = this.dataCollection.create(body);
    }
    this.error$.next('');
    this.saving$.next(true);
    promise.then(collection => {
      this.activeModal.close();
      this.router.navigate(['/library', collection.id, 'all']);
    }).catch(response => {
      this.error$.next(response?.error?.error);
      this.saving$.next(false);
    });
  }

  getEmailErrors(errors: any): ITokenFieldError[] {
    const tfErrors = [];

    if (errors === null) {
      return tfErrors;
    }
    if ('email' in errors) {
      tfErrors.push({
        message: INVALID_EMAIL_ERROR,
        entries: errors.email.map(email => this.emails.indexOf(email))
      });
    }
    if ('emailNotInEnterprise' in errors) {
      tfErrors.push({
        message: OUTSIDE_ORGANIZATION_ERROR,
        entries: errors.emailNotInEnterprise.map(email => this.emails.indexOf(email))
      });
    }
    if ('emailInvited' in errors) {
      tfErrors.push({
        message: EMAIL_SHARED_ERROR,
        entries: errors.emailInvited.map(email => this.emails.indexOf(email))
      });
    }

    return tfErrors;
  }

  getGroupErrors(errors: any): ITokenFieldError[] {
    const tfErrors = [];

    if (errors === null) {
      return tfErrors;
    }
    if ('groupInvited' in errors) {
      tfErrors.push({
        message: GROUP_SHARED_ERROR,
        entries: errors.groupInvited.map(group => this.groups.findIndex(g => g.id === group.id))
      });
    }

    return tfErrors;
  }

  closeContextMenus() {
    this.contextMenu.closeAllContextMenus({
      eventType: 'cancel'
    });
  }

  close() {
    this.activeModal.close();
  }

  onSharingExpandedToggle() {
    this.sharingExpanded = !this.sharingExpanded;
  }

  onChangeInvitationRole(role: 'owner' | 'admin' | 'member' | 'viewer') {
    this.invitationRole = role;
    for (const key in this.addedUsers) {
      this.addedUsers[key] = role;
    }
    for (const key in this.invitedUsers) {
      this.invitedUsers[key] = role;
    }
    for (const key in this.addedGroups) {
      this.addedGroups[key] = role;
    }
  }

  canChangeMemberRole(member: IDataCollectionMember) {
    const hasAdminUsers = this.collectionEditable.members.filter(member => member.role === 'admin').length > 1;

    if (member.role === 'owner') {
      return false;
    }

    if (member.role === 'admin' && !hasAdminUsers) {
      return false;
    }

    return true;
  }

  canDeleteMember(member: IDataCollectionMember) {
    return this.canChangeMemberRole(member);
  }

  onGroupChange(groups: IDataOrganizationGroup[]) {
    this.groups = this.groups.filter(group => !!group?.id);

    this.addedGroups = {};
    groups.forEach(group => {
      if (group.id)
        this.addedGroups[group.id] = this.invitationRole;
    });
  }
}
