<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>

<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-4">

        <ng-container *ngIf="progress$ | async as progress; else infiniteLoaderTemplate">
          <ngb-progressbar type="primary" height="3em" [showValue]="true" [value]="progress"></ngb-progressbar>
        </ng-container>

        <ng-template #infiniteLoaderTemplate>
          <common-loader-circles class="loader" [ngClass]="{'visible': loading}"></common-loader-circles>
        </ng-template>

      </div>
      <div class="col-12 p-2 text-center" *ngIf="message$ | async as message" [innerHTML]="message"></div>
    </div>
  </div>
</div>

<div class="modal-footer" *ngIf="stoppable">
  <button *ngIf="!(done$ | async)" type="button" class="btn btn-lg btn-primary" (click)="stop()" ngbAutofocus>Stop</button>
  <button *ngIf="done$ | async" type="button" class="btn btn-lg btn-primary" (click)="close()" ngbAutofocus>Close</button>
</div>
