<div class="button" #button
  [ngClass]="{ 'expandable': expandable }"
  [ngStyle]="{ 'padding-left': (indent*15)+'px' }">

  <span class="caret papers-icon-color" #caret>
    <rcp-svg-icon [name]="expanded ? 'arrow-down' : 'arrow-right'"></rcp-svg-icon>
  </span>

  <span class="icon papers-icon-color">
    <ng-content select="[icon]"></ng-content>
  </span>

  <span class="content">
    <ng-content></ng-content>
  </span>

  <span class="addons papers-icon-color">
    <ng-content select="[addons]"></ng-content>
  </span>
</div>

<div *ngIf="expanded">
  <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
</div>
