import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, takeWhile } from 'rxjs/operators';
import { getFileExt, readTextFromFile } from '@readcube/rcp-common';
import { CsvInfo, getCsvInfoFromString, Result } from '@readcube/rcp-csv-items';

import { AppShellService } from '../../app-shell.service';
import { SharedService, ITemplateOptionsCsv, DialogService } from '../../common';
import { DataItemService, IDataItemsPendingUpload, DataCollectionService, DataListService } from '../../library-data';
import { ImportMetaJob } from './import-meta-job';
import { ImportFileJob } from './import-file-job';
import { ImportUnsupportedJob } from './import-unsupported-job';
import { IImporterOptionsBib } from '../components/importer-options-bib.component';
import { IImporterOptionsNbib } from '../components/importer-options-nbib.component';
import { IImporterOptionsRis } from '../components/importer-options-ris.component';

export type STATUS_FLAG = 'queued' | 'paused' | 'running' | 'pending' | 'completed' | 'failed';
export type IMPORT_TYPE_ID = 'pdf' | 'bib' | 'ris' | 'nbib' | 'csv' | 'media';

export interface IImportType {
  type: IMPORT_TYPE_ID,
  name: string,
  description: string,
  visible: boolean,
  multiple: boolean,
  accept: string,
}

export interface IImportJob {
  id: string;
  name: string;
  detail: string;
  inProgress$: ReplaySubject<boolean>;
  completed$: Subject<boolean>;
  status$: Observable<STATUS_FLAG>;
  errors$: Observable<string[]>;
  parserError$: Observable<ParserError>;
  progress$: Observable<number>;
  snapshot: IImportJobSnapshot;
  run(): void;
  stop(): void;
}

export interface IImportJobSnapshot {
  status: STATUS_FLAG;
  errors: string[];
  parserError?: ParserError;
  progress: number;
}

export interface ParserErrorRecord {
  line: number;
  message: string;
  ctx: string;
}

export interface ParserError {
  successCount: number;
  errors: ParserErrorRecord[];
  warnings: ParserErrorRecord[];
}

export interface IdentifierError {
  message: string;
  invalidIds: string[];
}

export interface ImportAPI {
  getPending: (collectionId: string) => Observable<Map<string, IDataItemsPendingUpload>>;
  dataItem: DataItemService;
  dataCollection: DataCollectionService;
}

@Injectable()
export class ImporterService {
  queue$ = new BehaviorSubject<IImportJob[]>([]);
  start$ = new BehaviorSubject<IImportJob[]>([]);
  done$ = new Subject<IImportJob[]>();
  run$ = new BehaviorSubject<IImportJob>(null);
  totalCnt$: Observable<number>;
  completedCnt$: Observable<number>;
  failedCnt$: Observable<number>;
  doneCnt$: Observable<number>;
  percent$: Observable<number>;

  protected queue: IImportJob[] = [];
  protected MAX_CONCURENT_JOBS = 1;
  protected pendingStreams = new Map<string, Observable<Map<string, IDataItemsPendingUpload>>>();
  protected selectedFiles: File[];

  constructor(
    protected dataItem: DataItemService,
    protected dataCollection: DataCollectionService,
    protected dataList: DataListService,
    protected shared: SharedService,
    protected modal: NgbModal,
    protected dialog: DialogService,
    protected shell: AppShellService
  ) {
    this.totalCnt$ = this.queue$.pipe(
      map(queue => queue.length)
    );
    this.completedCnt$ = this.queue$.pipe(
      map(queue => queue.filter(task => task.snapshot.status === 'completed').length)
    );
    this.failedCnt$ = this.queue$.pipe(
      map(queue => queue.filter(task => task.snapshot.status === 'failed').length)
    );
    this.doneCnt$ = this.queue$.pipe(
      map(queue => queue.filter(task => task.snapshot.status != 'queued').length)
    );
    this.percent$ = combineLatest([this.doneCnt$, this.totalCnt$]).pipe(
      map(([done, total]) => Math.floor(done / total * 100))
    );
  }

  openDuplicateMergingInfo() {
    this.shell.openURL('https://support.papersapp.com/support/solutions/articles/30000045430-duplicate-merging-during-import');
  }

  getAvailableImportTypes(): IImportType[] {
    return [
      {
        type: 'pdf',
        name: 'Portable Document Format (.pdf)',
        description: '',
        visible: true,
        multiple: true,
        accept: '.pdf',
      },
      {
        type: 'bib',
        name: 'Bibliography file (.bib)',
        description: '',
        visible: true,
        multiple: true,
        accept: '.bib',
      },
      {
        type: 'ris',
        name: 'Research Information Systems (.ris)',
        description: '',
        visible: true,
        multiple: true,
        accept: '.ris',
      },
      {
        type: 'nbib',
        name: 'PubMed bibliography file (.nbib)',
        description: '',
        visible: true,
        multiple: true,
        accept: '.nbib',
      },
      {
        type: 'csv',
        name: 'Comma-separated values (.csv)',
        description: '',
        visible: true,
        multiple: false,
        accept: '.csv',
      },
      {
        type: 'media',
        name: 'Other media files...',
        description: 'Your pictures, music, audios, videos...',
        visible: this.shared.user?.licence.all_file_types,
        multiple: true,
        accept: '*',
      }
    ];
  }

  openImportDialog(collectionId?: string, listId?: string, importType: IMPORT_TYPE_ID = 'pdf', selectedFiles: File[] = []) {
    this.selectedFiles = selectedFiles;

    this.dialog.openDialog(['importer', 'options'], {
      collectionId,
      listId,
      importType
    });
  }

  public getSelectedFiles(): File[] {
    return this.selectedFiles;
  }

  public getImport(id: string): IImportJob {
    return this.queue.find(q => q.id === id);
  }

  public add(files: File[], collectionId: string, listId?: string, options: any = {}): ImporterService {
    files.forEach(file => {
      switch (getFileExt(file.name)) {
        case 'pdf':
          this.addFile(file, collectionId, listId, null);
          break;
        case 'bib':
        case 'bibtex':
          this.addBIB(file, collectionId, listId, options);
          break;
        case 'nbib':
          this.addNBIB(file, collectionId, listId, options);
          break;
        case 'ris':
          this.addRIS(file, collectionId, listId, options);
          break;
        case 'csv':
          this.addCSV(file, collectionId, listId, { ...options, allowCopyrightStatus: this.shared.user?.licence?.copyright_show_status });
          break;
        default:
          if (this.shared.user.licence.all_file_types) {
            this.addFile(file, collectionId, listId, null);
          } else {
            this.addUnsupported(file, collectionId, listId);
          }
      }
    });
    return this;
  }

  public remove(id: string) {
    const i = this.queue.findIndex(o => o.id === id);
    this.queue[i].stop();
    this.queue.splice(i, 1);
    this.queue$.next(this.queue);
    if (this.queue.length) {
      this.continue();
    }
    return Promise.resolve();
  }

  public clear() {
    this.queue = this.queue.filter(job => !['completed', 'failed'].includes(job.snapshot.status));
    this.queue$.next(this.queue);
  }

  public start() {
    this.continue();
    this.start$.next(this.queue);
  }

  public addToItem(files: File[], collectionId: string, itemId: string): IImportJob[] {
    const allFileTypes = this.shared.user.licence.all_file_types;
    return files.map(file => {
      const ext = getFileExt(file.name);
      if (ext === 'pdf' || (allFileTypes && ext)) {
        return this.addFile(file, collectionId, null, itemId);
      } else {
        return this.addUnsupported(file, collectionId, null, itemId);
      }
    });
  }

  public addUnsupported(file: File, collectionId: string, listId?: string, itemId?: string): IImportJob {
    const job = new ImportUnsupportedJob(file, collectionId, listId, itemId);
    this.queue.push(job);
    return job;
  }

  public addFile(file: File, collectionId: string, listId?: string, itemId?: string): IImportJob {
    const job = new ImportFileJob(file, collectionId, itemId, listId, this.getAPI());
    this.queue.push(job);
    return job;
  }

  public addBIB(file: File, collectionId: string, listId?: string, options?: IImporterOptionsBib): IImportJob {
    const job = new ImportMetaJob(file, collectionId, listId, 'bib', this.getAPI(), options);
    this.queue.push(job);
    return job;
  }

  public addNBIB(file: File, collectionId: string, listId?: string, options?: IImporterOptionsNbib): IImportJob {
    const job = new ImportMetaJob(file, collectionId, listId, 'nbib', this.getAPI(), options);
    this.queue.push(job);
    return job;
  }

  public addRIS(file: File, collectionId: string, listId?: string, options?: IImporterOptionsRis): IImportJob {
    const job = new ImportMetaJob(file, collectionId, listId, 'ris', this.getAPI(), options);
    this.queue.push(job);
    return job;
  }

  public addCSV(file: File, collectionId: string, listId?: string, options?: ITemplateOptionsCsv): IImportJob {
    const csvMapping = options.csvMapping
      .filter(c => c.enabled)
      .map(c => {
        if (c.custom) {
          return { key: c.key, customField: c.customField };
        } else {
          return { key: c.key, papersField: c.papersField };
        }
      });
    const job = new ImportMetaJob(file, collectionId, listId, 'csv', this.getAPI(), { ...options, csvMapping });
    this.queue.push(job);
    return job;
  }

  protected continue() {
    const running = this.queue.filter(job => job.snapshot.status === 'running');
    if (running.length >= this.MAX_CONCURENT_JOBS) {
      return;
    }
    const queued = this.queue.filter(job => ['queued'].includes(job.snapshot.status)).reverse();
    if (queued.length === 0) {
      this.done$.next(this.queue);
    } else {
      const n = this.MAX_CONCURENT_JOBS - running.length;
      queued.slice(0, n).forEach(job => {
        job.status$.pipe(
          takeWhile(status => !['completed', 'failed'].includes(status))
        ).subscribe({
          complete: () => this.continue()
        });
        this.run$.next(job);
        job.run();
      });
    }
    this.queue$.next(this.queue);
  }

  public getCSVInfo(file: File, collectionId: string): Promise<Result<CsvInfo, any>> {
    const collection$ = this.dataCollection.get(collectionId);
    return firstValueFrom(collection$).then(collection => {
      return readTextFromFile(file).then(csvString => {
        return getCsvInfoFromString({
          csvString,
          customFields: collection.custom_fields,
          customSchema: collection.custom_type_schema,
          allowCopyrightStatus: this.shared?.user?.licence?.copyright_show_status
        });
      });
    });
  }

  protected getAPI(): ImportAPI {
    return {
      getPending: (collectionId: string) => {
        let pending$ = this.pendingStreams.get(collectionId);
        if (!pending$) {
          pending$ = this.dataItem.uploadsPending(collectionId);
          this.pendingStreams.set(collectionId, pending$);
        }
        return pending$;
      },
      dataCollection: this.dataCollection,
      dataItem: this.dataItem,
    };
  }
}
