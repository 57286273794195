import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';

import { AppLibraryMenuComponent } from './components/library-menu.component';

@NgModule({
  imports: [
    CommonModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
  ],
  declarations: [
    AppLibraryMenuComponent,
  ],
  exports: [
    AppLibraryMenuComponent,
  ]
})
export class AppLibraryMenuModule { }
