import {
  faTag as falTag,
  faHistory as falHistory,
  faBox as falBox,
  faLock as falLock,
  faUnlock as falUnlock,
  faDotCircle as falDotCircle,
  faCalendar as falCalendar,
  faArchive as falArchive,
  faFile as falFile,
  faFolder as falFolder,
  faFolderPlus as falFolderPlus,
  faCircle as falCircle,
  faFlag as falFlag,
  faPlusCircle as falPlusCircle,
  faPaperclip as falPaperclip,
  faSearch as falSearch,
  faList as falList,
  faCaretCircleDown as falCaretCircleDown,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faTable as falTable,
  faSortAlphaUp as falSortAlphaUp,
  faExternalLink as falExternalLink,
  faDownload as falDownload,
  faSquare as falSquare,
  faCheckSquare as falCheckSquare,
  faLink as falLink,
  faCopy as falCopy,
  faFilePdf as falFilePdf,
  faFileCsv as falFileCsv,
  faExclamationCircle as falExclamationCircle
} from '@fortawesome/pro-light-svg-icons';

import {
  faTable as fasTable,
  faArrowToRight as fasArrowToRight,
  faArrowToLeft as fasArrowToLeft,
  faTrash as fasTrash,
  faBan as fasBan,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faExclamationTriangle as fasExclamationTriangle,
  faClipboard as fasClipboard,
  faArrowCircleRight as fasArrowCircleRight,
  faCaretUp as fasCaretUp,
  faCaretRight as fasCaretRight,
  faEnvelopeSquare as fasEnvelopeSquare,
  faArchive as fasArchive,
  faCog as fasCog,
  faCheck as fasCheck,
  faSpinnerThird as fasSpinnerThird,
  faSync as fasSync,
  faChevronLeft as fasChevronLeft,
  faChevronCircleDown as fasChevronCircleDown,
  faSignOutAlt as fasSignOutAlt,
  faSearch as fasSearch,
  faPlusCircle as fasPlusCircle,
  faPlus as fasPlus,
  faUser as fasUser,
  faInfoCircle as fasInfoCircle,
  faStar as fasStar,
  faStickyNote as fasStickyNote,
  faLightbulb as fasLightbulb,
  faLightbulbOn as fasLightbulbOn,
  faAdjust as fasAdjust,
  faUsers as fasUsers,
  faFilter as fasFilter,
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faFileImport as fasFileImport,
  faCircle as fasCircle,
  faFlag as fasFlag,
  faRemove as fasRemove,
  faLifeRing as fasLifeRing,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faStar as farStar,
  faCircle as farCircle,
  faFlag as farFlag,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faFacebookSquare as fabFacebookSquare,
  faTwitterSquare as fabTwitterSquare,
  faLinkedin as fabLinkedin
} from '@fortawesome/free-brands-svg-icons';

export const APP_ICONS = [
  farStar,
  farFlag,
  farCircle,

  fasArrowToRight,
  fasArrowToLeft,
  fasTrash,
  fasBan,
  fasEye,
  fasEyeSlash,
  fasExclamationTriangle,
  fasCircle,
  fasFlag,
  fasClipboard,
  fasArrowCircleRight,
  fasCaretUp,
  fasCaretRight,
  fasChevronLeft,
  fasChevronCircleDown,
  fasEnvelopeSquare,
  fasArchive,
  fasCog,
  fasCheck,
  fasSpinnerThird,
  fasSync,
  fasSignOutAlt,
  fasSearch,
  fasPlusCircle,
  fasPlus,
  fasUser,
  fasInfoCircle,
  fasStar,
  fasStickyNote,
  fasLightbulb,
  fasLightbulbOn,
  fasAdjust,
  fasUsers,
  fasFilter,
  fasTable,
  fasRemove,
  fasFileImport,
  fasLifeRing,

  falTag,
  falList,
  falHistory,
  falBox,
  falLock,
  falUnlock,
  falDotCircle,
  falCalendar,
  falTable,
  falArchive,
  falSearch,
  falTimes,
  falTimesCircle,
  falPlusCircle,
  falPaperclip,
  falFile,
  falFolder,
  falFolderPlus,
  falCircle,
  falCaretCircleDown,
  falFlag,
  falSortAlphaUp,
  falExternalLink,
  falDownload,
  falSquare,
  falCheckSquare,
  falLink,
  falCopy,
  falChevronRight,
  falChevronLeft,
  falFilePdf,
  falFileCsv,
  falExclamationCircle,

  fabFacebookSquare,
  fabTwitterSquare,
  fabLinkedin
];
