<div class="wrapper papers-scrollbar">

  <library-sidepanel-header
    [journal]="item?.article.journal"
    [year]="item?.article.year"
    [title]="item?.article.title"
    [chapter]="item?.article.chapter">
  </library-sidepanel-header>

  <div #styleWidget></div>

  <p>&nbsp;</p>

  <div class="mt-2 text-center">
    <button type="button" (click)="exporter.exportRIS({ collection, items: [item] })" class="btn btn-secondary w-100">Export to RefMan (.ris)</button>
  </div>

  <div class="mt-2 text-center">
    <button type="button" (click)="exporter.exportBIB({ collection, items: [item] })" class="btn btn-secondary w-100">Export to BibTeX (.bib)</button>
  </div>

  <div class="mt-2 text-center">
    <button type="button" (click)="exporter.exportCSV({ collection, items: [item] })" class="btn btn-secondary w-100">Comma-separated Values (.csv)</button>
  </div>

  <div class="mt-2 text-center">
    <button type="button" (click)="exporter.exportXLSX({ collection, items: [item] })" class="btn btn-secondary w-100">Microsoft Excel (.xlsx)</button>
  </div>

  <div class="mt-2 text-center">
    <button type="button" (click)="exporter.exportJSON({ collection, items: [item] })" class="btn btn-secondary w-100">Export to JSON (.json)</button>
  </div>
</div>
