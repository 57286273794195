<div class="modal-header">
  <h4 class="modal-title">Manage Lists</h4>
  <button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
    <span aria-hidden="true"><fa-icon [icon]="['fal', 'times']" size="sm"></fa-icon></span>
  </button>
</div>

<div class="modal-body papers-scrollbar">
  <div class="library-btn">
    <ng-container *ngIf="!collection.shared">
      <rcp-svg-icon name="my-library"></rcp-svg-icon><span>My Library</span>
    </ng-container>
    <ng-container *ngIf="collection.shared">
      <rcp-svg-icon name="folder-user"></rcp-svg-icon><span>{{collection.name}}</span>
    </ng-container>
  </div>

  <list-explorer-list
    *ngFor="let node of lists$ | async"
    [maxDepth]="maxListsDepth"
    [collection]="collection"
    [node]="node"
    [item]="item"
    [hasItem]="item.list_ids.includes(node.list.id)"
    (addToList)="addToList($event)"
    (removeFromList)="removeFromList($event)">
  </list-explorer-list>

  <common-loader-circles class="loader" *ngIf="loadingLists$ | async"></common-loader-circles>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-link me-auto text-decoration-none" (click)="openCreateList()"><fa-icon [icon]="['fas', 'plus']"></fa-icon> Create List</button>
  <button type="button" class="btn btn-lg btn-secondary" (click)="close()">Close</button>
</div>
