import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { tap, mergeMap, startWith, scan } from 'rxjs/operators';

import { DataMentionApiService, IDataMentionQueryParams, IDataMention } from './data-mention-api.service';

@Injectable()
export class DataMentionService {
  public total = 0;
  public totalHuman = '0';
  public loaded = 0;

  protected push$ = new Subject<IDataMention[]>();

  constructor(
    public api: DataMentionApiService
  ) { }

  query(params: IDataMentionQueryParams): Observable<IDataMention[]> {
    const push$ = this.push$.asObservable();
    return this.api.query(params, true).pipe(
      tap(result => this.total = result.total),
      tap(result => this.totalHuman = result.totalHuman),
      tap(result => this.loaded = result.rows.length),
      mergeMap(result => push$.pipe(
        startWith(result.rows),
        scan((acc, val) => this.accumulator(acc, val))
      ))
    );
  }

  next(params: IDataMentionQueryParams): Promise<IDataMention[]> {
    return firstValueFrom(this.api.query(params)).then(result => {
      this.loaded += result.rows.length;
      return this.pushDownstream(result.rows);
    });
  }

  protected pushDownstream(result: IDataMention[]): IDataMention[] {
    this.push$.next(result);
    return result;
  }

  protected accumulator(acc: IDataMention[], val: IDataMention[]): IDataMention[] {
    return acc.concat(val);
  }
}
