<div class="modal-header">
  <h4 class="modal-title">Customize Filters</h4>
  <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <search-navigation-filters [initialValues]="initialValues" configureView="true" class="modal-customize-filters papers-scrollbar" (valueChange)="onFormChange($event)"></search-navigation-filters>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary  btn-left" (click)="formComponent.clearValues('visible')">Clear All</button>
  <button type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
