import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environment';

export interface IDataOrganization {
  name: string;
  groups: IDataOrganizationGroup[];
}

export interface IDataOrganizationGroup {
  id: number;
  name: string;
  include_all: boolean;
}

@Injectable()
export class DataOrganizationApiService {

  constructor(
    private http: HttpClient
  ) { }

  public query(): Observable<IDataOrganization> {
    const endpointUrl = `${environment.baseUrls.services}/organization`;

    return this.http.get<IDataOrganization>(endpointUrl).pipe(
      map(response => response["organization"] || {})
    );
  }
}
