<ul class="nav nav-pills papers-border-top" [ngClass]="{'papers-border-bottom': !minimized}">
  <li class="nav-item">
    <button type="button" class="btn btn-sm btn-link" (click)="minimized = !minimized">
      <fa-icon [icon]="['fas', minimized ? 'chevron-circle-up' : 'chevron-circle-down']"></fa-icon>
    </button>
  </li>

  <li class="nav-item" (click)="minimized = !minimized">
    <span class="navbar-text">Import status</span>
  </li>

  <span class="nav nav-pills ms-auto" *ngIf="importer.totalCnt$ | async as totalCnt">
    <ng-container *ngIf="importer.completedCnt$ | async as completedCnt">
      <li class="nav-item">
        <button type="button" class="btn btn-sm btn-link pe-0 ps-0 ms-1" (click)="minimized = false"
          ngbTooltip="Successfully imported items from {{completedCnt}} out of {{totalCnt}} files.">
          <span class="badge rounded-pill bg-success">{{completedCnt}}</span>
        </button>
      </li>
    </ng-container>

    <ng-container *ngIf="importer.failedCnt$ | async as failedCnt">
      <li class="nav-item">
        <button type="button" class="btn btn-sm btn-link pe-0 ps-0 ms-1" (click)="minimized = false"
          ngbTooltip="Failed to import {{failedCnt}} out of {{totalCnt}} files.">
          <span class="badge rounded-pill bg-danger">{{failedCnt}}</span>
        </button>
      </li>
    </ng-container>

    <li class="nav-item" ngbDropdown [autoClose]="true" container="body" #optDrop="ngbDropdown">
      <button type="button" class="btn btn-sm btn-link me-1" ngbDropdownAnchor
        (click)="optDrop.open()"><fa-icon [icon]="['fas', 'cog']"></fa-icon>
      </button>

      <div ngbDropdownMenu>
        <button ngbDropdownItem (click)="importer.clear()">Clear completed</button>
      </div>
    </li>
  </span>
</ul>


<div class="content" *ngIf="!minimized"  [@inOutAnimation]>
  <ul class="list papers-scrollbar">
    <li *ngFor="let job of importer.queue$ | async | reverse; trackBy: trackByFn">
      <div class="inner">
        <importer-progress-item [job]="job" (remove)="importer.remove(job.id)"></importer-progress-item>
      </div>
    </li>
  </ul>
</div>
