import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { debounceTime, pairwise, startWith } from 'rxjs';

import { SearchNavigationFiltersService } from '../services/search-navigation-filters.service';
import { Options } from 'ngx-slider-v2';
import { FilterGroupType, ICheckboxFilter, IDataFiltersResult, IRangeChartItem, TRangeFilter } from '../services/data-filters-api.service';

export interface ICheckboxFilterGroup {
  value: FormControl;
  name: FormControl;
  label: FormControl;
  visible: FormControl;
}

export type TCheckboxFilterArray  = FormArray<FormGroup<ICheckboxFilterGroup>>;

export type TRangeFilterControl = FormControl<TRangeFilter>;

export interface IFormGroup {
  label: FormControl;
  name: FormControl;
  type: FormControl;
  visible?: FormControl;
  defaultFilters?: TRangeFilterControl;
  chartData?: FormControl<IRangeChartItem[]>;
  filters: TCheckboxFilterArray | TRangeFilterControl;
}

export type TFormGroupControl = FormGroup<IFormGroup>;

export type TForm = FormArray<TFormGroupControl>;

export interface IFormValueChange {
  oldValue: IDataFiltersResult[],
  newValue: IDataFiltersResult[]
}

@Component({
  selector: 'search-navigation-filters',
  templateUrl: './search-navigation-filters.component.html',
  styleUrls: ['./search-navigation-filters.component.scss']
})
export class SearchNavigationFiltersComponent {
  chartView = [225, 88];

  @ViewChild('accordion', { static: false })
  public accordion;

  @Input()
  configureView: boolean = false;

  @Input()
  initialValues: IDataFiltersResult[];

  @Output()
  valueChange = new EventEmitter<IFormValueChange>();

  constructor(
    public router: Router,
    public navigationFilters: SearchNavigationFiltersService
  ) { }

  customColors = (value: string) => {
    const year = this.form.value.find((group) => group.name === 'year');
    const [ low, high ] = year.filters as TRangeFilter;

    return Number(value) >= low && Number(value) <= high ? '#37738d' : '#cbdae0';
  }

  sliderOptions([ lowDefault, highDefault ]) :Options {
    return {
      floor: lowDefault,
      ceil: highDefault,
      step: 1,
    };
  };

  form: TForm;
  chartData: IRangeChartItem[]; // used so we can repaint the chart optimally

  expandedSections = ['group-0', 'group-1', 'group-2'];

  initializeChart() {
    const year = this.form.value.find((group) => group.name === 'year');

    this.chartData = [ ...year.chartData ];
  }

  ngOnChanges(changes) {
    const valuesChanged = changes?.initialValues?.currentValue && changes?.initialValues?.currentValue !== changes?.initialValues?.previousValue;
    // initial state of behavior subject is null, which means changes?.initialValues?.firstChange isn't what we need
    const firstChange  = !changes?.initialValues?.previousValue && valuesChanged;
  
    if (firstChange) {
      this.generateFiltersForm(this.initialValues);
      this.initializeChart();
    }

    if (!firstChange && valuesChanged) {
      this.updateForm(changes?.initialValues?.currentValue);
    }

    if (valuesChanged) {
      this.expandSections();
    }
  }

  createFilterGroup = (item: ICheckboxFilter) => new FormGroup({
    label: new FormControl(item.label),
    name: new FormControl(item.name),
    visible: new FormControl(item.visible),
    value: new FormControl(false)
  });

  expandSections() {
    setTimeout(() => this.expandedSections.forEach((section) => this.accordion.expand(section)), 0);
  }

  generateFiltersForm(filters: IDataFiltersResult[] = []) { 
    const filtersArray = filters.map((item) => {
      const filters = item.type === FilterGroupType.Checkbox ? new FormArray((item.filters as ICheckboxFilter[]).map(this.createFilterGroup)) : new FormControl(item.filters) as TRangeFilterControl;
      const group: IFormGroup = {
        label: new FormControl(item.label),
        name: new FormControl(item.name),
        type: new FormControl(item.type),
        visible: new FormControl(item.visible),
        filters
      };

      if (item.type === FilterGroupType.Range) {
        group.defaultFilters = new FormControl(item.filters as TRangeFilter);
        group.chartData = new FormControl(item.chartData);
      }

      return new FormGroup(group);
    });

    this.form = new FormArray(filtersArray);

    this.form.valueChanges.pipe(
      debounceTime(100),
      startWith<string, any>(this.form.value),
      pairwise()
    ).subscribe(([ oldValue, newValue ]: [ IDataFiltersResult[], IDataFiltersResult[]]) => {
      // go through all ranges and detect changes, repaint each chart if there was a change
      oldValue.forEach((oldGroup, i) => {
        const newGroupFilters = newValue[i].filters;
        if (oldGroup.type === FilterGroupType.Range && (oldGroup.filters?.[0] !== newGroupFilters?.[0] || oldGroup.filters?.[1] !== newGroupFilters?.[1])) {
          this.initializeChart();
        }
      })

      this.valueChange.next({ oldValue, newValue });
    });

    this.expandSections();
  }

  updateForm(newFilters: IDataFiltersResult[]) {
    // add filters that exist in newFilters but are missing in controls
    newFilters.map((newGroup, i) => {
      const controlGroup: FormGroup = this.form.controls[i];

      // it's possible that initial form will be null
      if (!controlGroup) {
        return;
      }

      // check if group visibility changed
      if (newGroup.visible !== controlGroup.controls.visible.value) {
        controlGroup.controls.visible.patchValue(newGroup.visible);
      }

      // range doesn't have a nested form array, so just set the form control with latest value
      if (newGroup.type === FilterGroupType.Range) {
        controlGroup.controls.filters.patchValue(newGroup.filters);

        return;
      }

      (newGroup.filters as ICheckboxFilter[]).map((filter, j) => {
        const controlExists= !!controlGroup.value.filters.find((filterValue) => filterValue.name === filter.name);

        if (controlExists) {
          return;
        }

        (controlGroup.controls.filters as FormArray).insert(j, this.createFilterGroup(filter));
      });
    })

    // remove ones that exist in old filters but aren't present in newFilters
    this.form?.controls?.map((groupControl, i) => {
      // no need to do remove anything for ranges
      if (groupControl.value.type === FilterGroupType.Range) {
        return;
      }

      const indexesToRemove = [];

      (groupControl.controls.filters as TCheckboxFilterArray).controls.map((filter, j) => {
        const filterExists = (newFilters[i].filters as ICheckboxFilter[]).find(({ name }) => name === filter.controls.name.value);

        if (!filterExists) {
          indexesToRemove.push(j);
        }
      });

      indexesToRemove.reverse().forEach((indexToRemove) => (groupControl.controls.filters as FormArray).removeAt(indexToRemove));
    });

    this.expandSections();
  }

  clearValues(property: string = 'value') {
    this.form.controls.map((groupControl) => {
      if (groupControl.value.type === FilterGroupType.Range) {
        if (this.configureView) {
          groupControl.controls.visible.patchValue(false);
        } else {
          (groupControl.controls.filters as TRangeFilterControl).patchValue(groupControl.controls.defaultFilters.value);
        }

        return;
      }

      (groupControl.controls.filters as TCheckboxFilterArray).controls.map((filter) => {
        filter.controls[property].patchValue(false);  
      });
    });
  }
}
