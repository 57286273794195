<div class="papers-scrollbar" style="max-height: 500px; overflow-y: auto;">
  <rcp-importer-file-list [(files)]="files"></rcp-importer-file-list>
</div>

<hr>

<div class="form-check form-switch mt-2">
  <input class="form-check-input" type="checkbox" id="merge-duplicates-checkbox"
    [(ngModel)]="options.mergeDuplicates"
    (ngModelChange)="optionsChange.next(options)">
  <label class="form-check-label" for="merge-duplicates-checkbox">Merge Duplicates</label>
  &nbsp;<a href rcpPreventDefault (click)="importer.openDuplicateMergingInfo()">Learn more</a>
</div>
