<div class="row">
  <div class="col-sm-6">
    <select type="text" class="form-control" [(ngModel)]="value.type"
      (ngModelChange)="onChange(value)" (change)="focus()">
      <option ngValue="is">Include these words</option>
      <option ngValue="is_not">Exclude these words</option>
    </select>
  </div>

  <div class="col-sm-6">
    <input type="text" class="form-control"
      [(ngModel)]="value.input_text" (ngModelChange)="onChange(value)"
      placeholder="Enter text..." autocomplete=off #focusInput>
  </div>
</div>
