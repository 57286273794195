import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { mergeMap, map, startWith } from 'rxjs/operators';

import { IDataItem } from '../../library-data';
import { LibraryCollectionComponent } from './library-collection.component';

@Component({
  selector: 'library-collection-tagged',
  templateUrl: './library-collection.component.html',
  styleUrls: ['./library-collection.component.scss'],
})
export class LibraryCollectionTaggedComponent extends LibraryCollectionComponent implements OnInit {

  loadFilterPredicate = (params: Params) => ((item: IDataItem) => {
    return (params.tags||[]).some((tag: string) => item.user_data.tags.includes(tag));
  });

  ngOnInit() {
    super.ngOnInit();

    this.searchPlaceholder$ = this.collection$.pipe(
      mergeMap(collection => {
        return this.route.params.pipe(
          map(params => [
            `Search tagged with ${params.tags}`,
            collection.name ? ` within ${collection.name}` : '',
            '...'
          ].join(''))
        );
      }),
      startWith('')
    );
  }
}
