import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable, filter, map } from "rxjs";

import { NavigationService } from "../services/navigation.service";

@Component({
  selector: 'navigation-drawer',
  templateUrl: './navigation-drawer.component.html',
  styleUrls: ['./navigation-drawer.component.scss'],
})
export class NavigationDrawerComponent implements OnInit {
  drawerOpen$: Observable<boolean>;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.drawerOpen$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !!this.route.children.find(child => child.outlet === 'drawer'))
    );

    this.navigationService.drawerOpen$.subscribe((drawerLink) => {
      this.router.navigate([{
        outlets: {
          drawer: drawerLink ? [drawerLink] : null
        }
      }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    })
  }
}
