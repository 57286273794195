import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAuthor'
})
export class FormatAuthorPipe implements PipeTransform {
  transform(value: string | { name: string; }): string {
    if (!value) return '';
    let s = typeof value == 'string' ? value : value.name;

    // https://readcube.atlassian.net/jira/software/c/projects/WEBAPP/issues/WEBAPP-1534
    if (s[s.length - 1] === '~') return s.slice(0, -1);

    let i = s.lastIndexOf(' ');
    if (i === -1) return s;
    let r = [s.slice(i), s.slice(0, i)].join(', ');
    if (r[r.length - 1] === '.') r.slice(0, -1);
    return r;
  }
}
