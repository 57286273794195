import { IDataList } from '../../library-data';

export class ListNode {
  constructor(
    public list: IDataList,
    public children: ListNode[] = [],
    public parent?: ListNode
  ) {}

  public getHeight(height: number = 0): number {
    const arr = this.children
      .filter(child => !child.list.deleted)
      .map(child => child.getHeight(height + 1));
    return arr.length ? Math.max(...arr) : height;
  }

  public findNode(listId: string): ListNode | undefined {
    if (this.list && this.list.id === listId) {
      return this;
    }
    for (let i = 0; i < this.children.length; i++) {
      const node = this.children[i].findNode(listId);
      if (node) {
        return node;
      }
    }
    return undefined;
  }

  public isParentOf(listId: string, limit = 10, node?: ListNode): boolean {
    node = node || this.parent;
    if (!node || limit === 0) {
      return false;
    } else if (node.list.id === listId) {
      return true;
    }
    return this.isParentOf(listId, limit-1, node.parent);
  }
}

export class ListTreeService {
  fromLists(lists: IDataList[], parent?: ListNode): ListNode {
    let tmp: ListNode[];
    let children: ListNode[];
    tmp = (lists || []).filter(child => {
      return parent ? child.parent_id === parent.list.id : !child.parent_id;
    }).map(list => {
      return new ListNode(list, [], parent);
    }).sort((a, b) => {
      return a.list.name.toLowerCase()
        .localeCompare(b.list.name.toLowerCase());
    });
    if (!parent) {
      children = tmp;
    } else {
      parent.children = tmp;
    }
    tmp.forEach(child => this.fromLists(lists, child));
    return new ListNode(null, children, null);
  }

  flatten(node: ListNode, limit?: number, lists: IDataList[] = []): IDataList[] {
    if (node.list) {
      lists.push(node.list);
    }
    const children = node.children.sort((a, b) => {
      return a.list.name.toLowerCase()
        .localeCompare(b.list.name.toLowerCase());
    });
    if (limit === 0) {
      return lists;
    }
    children.forEach(c => this.flatten(c, limit - 1, lists));
    return lists;
  }
}
