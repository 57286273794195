<form #reviewForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">Add to Literature Review Project</h4>
  </div>

  <div class="modal-body">

    <div class="form-floating mt-3">
      <input type="text"
        name="project-select"
        class="form-control"
        popupClass="papers-scrollbar"
        #projectSelectTypeahead="ngbTypeahead"
        [ngbTypeahead]="selectProjectSearch"
        [(ngModel)]="selectedProject"
        [inputFormatter]="selectProjectFormatter"
        [resultFormatter]="selectProjectFormatter"
        [editable]="false"
        [required]="true"
        (focus)="projectFocus$.next($any($event).target.value)"
        (click)="projectClick$.next($any($event).target.value)">
      <label for="project-select" class="papers-label">Select Project</label>
    </div>

    <div class="form-floating mt-3">
      <select
        name="source-select"
        class="form-select"
        [(ngModel)]="selectedSource">
        <option [ngValue] = null></option>
        <option [ngValue]="source" *ngFor="let source of sources">{{source | titlecase}}</option>
      </select>
      <label for="source-select" class="papers-label">Source</label>
    </div>

    <div class="form-floating mt-3">
      <rcp-token-field
        #labelsInput
        name="tags-input"
        class="form-control"
        [(text)]="labelsText"
        [search]="searchLabels$"
        [focusFirst]="false"
        [maxLength]="50"
        [(ngModel)]="labels"
        placeholder="Enter your labels here...">

        <ng-template #tokenTemplate let-tag="item">
          <span>{{tag}}</span>
        </ng-template>

        <ng-template #resultTemplate let-result="result" let-term="term">
          <span>{{result}}</span>
        </ng-template>
      </rcp-token-field>
    </div>

    <br><br>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!reviewForm.form.valid">Add to Project</button>
  </div>

</form>
