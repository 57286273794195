import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SearchNavigationFiltersService } from '../services/search-navigation-filters.service';
import { first } from 'rxjs';
import { SearchNavigationFiltersComponent } from '../components/search-navigation-filters.component';
import { DialogService } from 'src/app/common/services/dialog.service';
import { IDataFiltersResult } from '../services/data-filters-api.service';

@Component({
  templateUrl: './modal-customize-filters.component.html',
  styleUrls: ['./modal-customize-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalCustomizeFiltersComponent implements OnInit {
  @Input()
  initialValues: IDataFiltersResult[];

  values: IDataFiltersResult[];

  @ViewChild(SearchNavigationFiltersComponent)
  formComponent: SearchNavigationFiltersComponent;

  constructor(
    public dialog: DialogService,
    public navigationFilters: SearchNavigationFiltersService
  ) {}

  ngOnInit() {
    this.navigationFilters.getFilters({}).pipe(first()).subscribe(filters => {
      this.initialValues = filters;
    });
  }

  onFormChange({ newValue }) {
    this.values = newValue;
  }

  save() {
    this.navigationFilters.customizeFilters(this.values || this.initialValues);
    this.dialog.closeDialog();
  }

  close() {
    this.dialog.closeDialog();
  }
}
