import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeTokenFieldModule } from '@readcube/rcp-token-field';
import { ReadCubeKatexModule } from '@readcube/rcp-katex';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';

import { AppCommonModule } from '../common';
import { AppListExplorerModule } from '../list-explorer';
import { AppMetricsModule } from '../metrics';

import { LibrarySidepanelDetailsComponent } from './components/library-sidepanel-details.component';
import { LibrarySidepanelShareComponent } from './components/library-sidepanel-share.component';
import { LibrarySidepanelNotesComponent } from './components/library-sidepanel-notes.component';
import { LibrarySidepanelFilesComponent } from './components/library-sidepanel-files.component';
import { LibrarySidepanelEditComponent } from './components/library-sidepanel-edit.component';
import { LibrarySidepanelMetricsComponent } from './components/library-sidepanel-metrics.component';
import { LibrarySidepanelResolveComponent } from './components/library-sidepanel-resolve.component';
import { LibrarySidepanelExportComponent } from './components/library-sidepanel-export.component';
import { LibrarySidepanelHeaderComponent } from './components/library-sidepanel-header.component';

import { ModalEditTagsComponent } from './modals/modal-edit-tags.component';
import { ModalListsEditorComponent } from './modals/modal-edit-lists.component';
import { LibrarySidepanelService } from './services/library-sidepanel.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeTokenFieldModule,
    ReadCubeSvgIconsModule,
    ReadCubeKatexModule,
    AppCommonModule,
    AppListExplorerModule,
    AppMetricsModule,
  ],
  declarations: [
    LibrarySidepanelHeaderComponent,
    LibrarySidepanelDetailsComponent,
    LibrarySidepanelShareComponent,
    LibrarySidepanelNotesComponent,
    LibrarySidepanelFilesComponent,
    LibrarySidepanelEditComponent,
    LibrarySidepanelMetricsComponent,
    LibrarySidepanelResolveComponent,
    LibrarySidepanelExportComponent,
    ModalEditTagsComponent,
    ModalListsEditorComponent,
  ],
  providers: [
    LibrarySidepanelService
  ]
})
export class AppLibrarySidepanelModule { }
