import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged, shareReplay, startWith } from 'rxjs/operators';
import { ContextMenuService } from '@readcube/ngx-contextmenu';

import { AppShellService } from '../../app-shell.service';
import { SharedService } from '../../common';
import { DataUserService, IDataUser } from '../../common-data';
import { LibraryService } from '../../library';
import { DataCollectionService, IDataCollection } from '../../library-data';
import { LibraryNavigationService } from '../services/library-navigation.service';
import { ImporterService } from '../../importer';

@Component({
  templateUrl: './library-navigation.component.html',
  styleUrls: ['./library-navigation.component.scss']
})
export class LibraryNavigationComponent implements OnInit {
  user$: Observable<IDataUser>;
  collections$: Observable<IDataCollection[]>;
  sharedCollections$: Observable<IDataCollection[]>;
  privateCollections$: Observable<IDataCollection[]>;
  archivedCollections$: Observable<IDataCollection[]>;
  licenseActive$: Observable<boolean>;
  searchQuery$: Observable<string>;
  searchDisabled$: Observable<boolean>;
  statusText$: Observable<string>;
  archivedExpanded = false;

  constructor(
    public router: Router,
    public shared: SharedService,
    public contextMenu: ContextMenuService,
    public dataUser: DataUserService,
    public dataCollection: DataCollectionService,
    public navigation: LibraryNavigationService,
    public library: LibraryService,
    public importer: ImporterService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.statusText$ = this.importer.percent$.pipe(
      map(p => p < 100 ? p : null),
      startWith(null),
      map(result => {
        const messages = [];
        if (result !== null) {
          messages.push('importing files' + ' ' + result + '%');
        }
        if (!messages.length) {
          return null;
        }
        return messages.join(' and ') + '...';
      })
    );

    this.user$ = this.dataUser.get().pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
      distinctUntilChanged()
    );

    this.licenseActive$ = this.user$.pipe(
      map(user => user.licence.active)
    );

    this.collections$ = this.dataCollection.query().pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.privateCollections$ = this.collections$.pipe(
      map(collections => {
        return collections.filter(collection => {
          return !collection.shared && collection.status === 'active';
        });
      }),
      map(collections => collections.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }))
    );

    this.sharedCollections$ = this.collections$.pipe(
      map(collections => {
        return collections.filter(collection => {
          return collection.shared && collection.user?.role && collection.status === 'active';
        });
      }),
      map(collections => collections.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }))
    );

    this.archivedCollections$ = this.collections$.pipe(
      map(collections => {
        return collections.filter(collection => {
          return collection.status === 'archived';
        });
      }),
      map(collections => collections.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }))
    );
  }

  isRouteActive(route: string): boolean {
    return this.router.url.startsWith(route);
  }

  collectionTrackByFn(index: number, collection: IDataCollection) {
    return collection.id;
  }

  createCollection() {
    return this.library.createCollection();
  }

  canCreateCollection() {
    return this.shared.user?.licence.active;
  }

  closeConetxMenus() {
    this.contextMenu.closeAllContextMenus({ eventType: 'cancel' });
  }
}
