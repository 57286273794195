import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environment';

export interface IDataMetricsQueryParams {
  doi: string;
}

export class IDataMetricsResult {
  rcr: number;
  citations: number;
}

@Injectable()
export class DataMetricsApiService {

  constructor(
    private http: HttpClient
  ) { }

  public query(params: IDataMetricsQueryParams): Observable<IDataMetricsResult> {
    const endpointUrl = `${environment.baseUrls.services}/articles/metrics`;
    const httpParams = new HttpParams().set('doi', params.doi);
    return this.http.get<IDataMetricsResult>(endpointUrl, { params: httpParams })
      .pipe(map(body => this.createResult(body)));
  }

  private createResult(response: any): IDataMetricsResult {
    return {
      rcr: response['rcr'] || 0,
      citations: response['count'] || 0
    };
  }
}
