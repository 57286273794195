<div class="modal-header">
  <h4 class="modal-title">Enable PDF downloads</h4>
  <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
  <p>In order to attempt automatic PDF downloads of Search results and Recommendations, please install the <a [href]="extension.webstoreURL" target="_blank">Papers Web Importer</a> browser extension.</p>
  <p>The extension will also allow you to directly import references from elsewhere on the web, including PubMed, Google Scholar and many journal websites.</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-lg btn-secondary" (click)="close()">Not now</button>
  <button type="button" class="btn btn-lg btn-primary" (click)="install()">Install</button>
</div>
