import { Component, ChangeDetectionStrategy } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ImporterService, IImportJob } from '../services/importer.service';

@Component({
  selector: 'importer-progress',
  templateUrl: './importer-progress.component.html',
  styleUrls: ['./importer-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('250ms ease-out', style({ height: '*', opacity: 1 })),
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('250ms ease-in', style({ height: 0, opacity: 0 }))
          ]
        )
      ],
    ),
  ]
})
export class ImporterProgressComponent {
  minimized = true;

  constructor(
    public importer: ImporterService
  ) { }

  ngOnInit() {
    this.importer.start$.subscribe(() => {
      this.minimized = false;
    });
  }

  trackByFn(index: number, item: IImportJob): string {
    return item.id;
  }
}
