import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, fromEvent as observableFromEvent } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ReaderService, SharedService } from '../../common';
import { IDataItem } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { LibrarySidepanelService } from '../services/library-sidepanel.service';

@Component({
  templateUrl: './library-sidepanel-share.component.html',
  styleUrls: [
    './library-sidepanel.component.scss',
    './library-sidepanel-share.component.scss'
  ]
})
export class LibrarySidepanelShareComponent implements OnInit, OnDestroy {
  item: IDataItem;
  itemSub: Subscription;
  sharingHidden: boolean;

  @ViewChild('btnShareByEmail', { static: true })
  btnShareByEmail: ElementRef;

  @ViewChild('btnShareOnFacebook', { static: true })
  btnShareOnFacebook: ElementRef;

  @ViewChild('btnShareOnTwitter', { static: true })
  btnShareOnTwitter: ElementRef;

  @ViewChild('btnShareOnLinkedIn', { static: true })
  btnShareOnLinkedIn: ElementRef;

  protected btnShareByEmailClickSub: Subscription;
  protected btnShareOnFacebookClickSub: Subscription;
  protected btnShareOnTwitterClickSub: Subscription;
  protected btnShareOnLinkedInClickSub: Subscription;

  constructor(
    public shared: SharedService,
    public sidepanel: LibrarySidepanelService,
    public reader: ReaderService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.itemSub = this.sidepanel.item$.subscribe(item => {
      this.item = item;
      this.sharingHidden = this.shared.user?.licence.disable_item_sharing || (!item?.ext_ids?.doi && !item?.ext_ids?.pmid);
    });

    this.btnShareOnFacebookClickSub = observableFromEvent<MouseEvent>(this.btnShareOnFacebook.nativeElement, 'click').pipe(
      tap(event => event.preventDefault()),
      map(() => this.item)
    ).subscribe(item => this.shareOnFacebook(item));

    this.btnShareOnTwitterClickSub = observableFromEvent<MouseEvent>(this.btnShareOnTwitter.nativeElement, 'click').pipe(
      tap(event => event.preventDefault()),
      map(() => this.item)
    ).subscribe(item => this.shareOnTwitter(item));

    this.btnShareOnLinkedInClickSub = observableFromEvent<MouseEvent>(this.btnShareOnLinkedIn.nativeElement, 'click').pipe(
      tap(event => event.preventDefault()),
      map(() => this.item)
    ).subscribe(item => this.shareOnLinkedIn(item));

    this.btnShareByEmailClickSub = observableFromEvent<MouseEvent>(this.btnShareByEmail.nativeElement, 'click').pipe(
      tap(event => event.preventDefault()),
      map(() => this.item)
    ).subscribe(item => this.shareByEmail(item));
  }

  ngOnDestroy() {
    this.itemSub.unsubscribe();
    this.btnShareOnFacebookClickSub.unsubscribe();
    this.btnShareOnTwitterClickSub.unsubscribe();
    this.btnShareOnLinkedInClickSub.unsubscribe();
  }

  shareByEmail(item: IDataItem): void {
    const subject = 'Here’s a paper I thought you’d find interesting';
    const body = this.sidepanel.getShareByEmailBody(item);
    this.shell.openURL(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
  }

  shareOnFacebook(item: IDataItem): void {
    const articleURL = this.reader.getDirectUrl(item);
    this.shell.openURL(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(articleURL)}`);
  }

  shareOnTwitter(item: IDataItem): void {
    const articleURL = this.reader.getDirectUrl(item);
    this.shell.openURL(`https://twitter.com/share?text=${item.article.title}&url=${encodeURIComponent(articleURL)}`);
  }

  shareOnLinkedIn(item: IDataItem): void {
    const articleURL = this.reader.getDirectUrl(item);
    this.shell.openURL(`https://www.linkedin.com/shareArticle?mini=true` +
      `&url=${encodeURIComponent(articleURL)}&title=${encodeURIComponent(item.article.title)}` +
      `&summary=${encodeURIComponent(item.article.abstract)}`);
  }
}
