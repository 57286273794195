<div class="wrapper papers-scrollbar" *ngIf="article">

  <search-sidepanel-header
    [journal]="article.journal"
    [year]="article.year"
    [title]="article.title"
    [chapter]="article.chapter">
  </search-sidepanel-header>

  <ng-container *ngIf="article.ext_ids.doi; else metricsNotAvailableTemplate">
    <metrics-article [doi]="article.ext_ids.doi" [chartType]="chartType"></metrics-article>
  </ng-container>

  <ng-template #metricsNotAvailableTemplate>
    <div class="empty-text">Article metrics unavailable at this time</div>
  </ng-template>

</div>
