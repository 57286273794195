export { AppLibrarySidepanelModule } from './library-sidepanel.module';

export * from './components/library-sidepanel-details.component';
export * from './components/library-sidepanel-share.component';
export * from './components/library-sidepanel-notes.component';
export * from './components/library-sidepanel-files.component';
export * from './components/library-sidepanel-edit.component';
export * from './components/library-sidepanel-metrics.component';
export * from './components/library-sidepanel-resolve.component';
export * from './components/library-sidepanel-export.component';

export * from './services/library-sidepanel.service';
