import { Injectable } from '@angular/core';

import { ExtensionService, IExtensionEvent } from '../../extension';
import { IDataItem, IDataItemAnnotation, IDataItemFile } from '../../library-data';
import { IDataArticle } from '../../search-data';
import { AppShellService } from '../../app-shell.service';

import openInPapers from '@readcube/open-in-papers';
import { environment } from 'environment';

export interface IViewItemPDFParams {
  item: Partial<IDataItem>;
  file?: IDataItemFile;
  annotation?: IDataItemAnnotation;
}

@Injectable()
export class ReaderService {
  constructor(
    protected extension: ExtensionService,
    protected shell: AppShellService
  ) { }

  getDirectUrl(itemOrArticle: IDataItem | IDataArticle, proxyURL?: string): string {
    const articleURL = itemOrArticle.status_data?.pdf_urls?.find(url => url.direct)?.url;
    if (!proxyURL) {
      return articleURL;
    }
    return proxyURL.indexOf('%@') !== -1 ?
      proxyURL.replace('%@', articleURL) :
      [proxyURL, articleURL].join('');
  }

  canGetPDF(articleOrItem: IDataArticle | IDataItem) {
    return articleOrItem.status_data?.subscription_access || !!articleOrItem.status_data?.purchasable_by;
  }

  getPDF(articleOrItem: IDataArticle | IDataItem) {
    const query = new URLSearchParams()
    const importTo = this.getImportTo(articleOrItem);
    let url = `${environment.baseUrls.readcube}/articles/${articleOrItem.ext_ids.doi}`;
    if (importTo)
      query.set('import_to', importTo);
    if (articleOrItem?.status_data?.crossref_publisher)
      query.set('publisher', articleOrItem.status_data.crossref_publisher);
    if (query.size)
      url = url + '?' + query.toString();
    this.shell.openURL(url);
  }

  canViewPublicPDF(itemOrArticle: IDataItem | IDataArticle) {
    const isFree = itemOrArticle.status_data?.open_access || itemOrArticle.status_data?.free_access;
    const hasDirectUrl = !!this.getDirectUrl(itemOrArticle);
    return isFree || hasDirectUrl;
  }

  viewPublicPDF(itemOrArticle: IDataItem | IDataArticle) {
    const doi = itemOrArticle.ext_ids.doi;
    const env = environment.name;
    const dl = this.extension.downloadPDF({ env, doi });

    openInPapers({
      doi,
      env,
      pdfDownload: (progress: (event: IExtensionEvent) => void) => {
        dl.progress$.subscribe(event => progress && progress(event));
        return new Promise<ArrayBuffer>((resolve, reject) => {
          dl.success$.subscribe(event => resolve(event.arrayBuffer));
          dl.failed$.subscribe(() => reject({
            redirect: this.getDirectUrl(itemOrArticle)
          }));
        });
      },
      addToLibrary: true,
      tab: true
    });
  }

  viewItemPDF(params: IViewItemPDFParams) {
    this.shell.openURL(this.getReadURL(params.item, params.file, params.annotation));
  }

  getReadURL(item: Partial<IDataItem>, file?: IDataItemFile, annotation?: IDataItemAnnotation): string {
    const annot = (url: string, annotation?: IDataItemAnnotation) => {
      return annotation ? url.concat(`#annotation:${annotation.id}`) : url;
    };
    const fileIndex = file ? item.files
      .filter(f => f.type === 'supplement')
      .findIndex(f => f.sha256 === file.sha256) : -1;
    if (fileIndex >= 0) {
      if (!item.drm || (item.access_data?.method === 'docdel')) {
        const url = `${environment.baseUrls.readcube}/library/supplement` +
          `?library_id=${item.collection_id}:${item.id}&index=${fileIndex}`;
        return annot(url, annotation);
      } else {
        return `${environment.baseUrls.readcube}/articles/supplement` +
          `?doi=${item.ext_ids.doi}&index=${fileIndex}`;
      }
    }
    if (item.primary_file_hash && (!item.drm || item.access_data?.method === 'docdel')) {
      const url = `${environment.baseUrls.readcube}/library/${item.collection_id}:${item.id}`;
      return annot(url, annotation);
    } else if (item.ext_ids.doi) {
      return `${environment.baseUrls.readcube}/articles/${item.ext_ids.doi}`;
    }
    return '';
  }

  private getImportTo(articleOrItem: Partial<IDataItem> | IDataArticle): string {
    if ('collection_id' in articleOrItem) {
      return [articleOrItem.collection_id, articleOrItem.id].join(':');
    } else {
      return null
    }
  }
}
