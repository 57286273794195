import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { last, delay } from 'rxjs/operators';

import { DataUserService, IDataUser, IDataItemExtIds } from '../../common-data';
import { DataItemService, IDataItem, IDataItemArticle } from '../../library-data';
import { DataRecommendService, DataSearchService, IDataArticle } from '../../search-data';
import { AppShellService } from '../../app-shell.service';

import { environment } from 'environment';

@Injectable()
export class SharedService {
  user: IDataUser;

  constructor(
    protected router: Router,
    protected dataUser: DataUserService,
    protected dataItem: DataItemService,
    protected dataSearch: DataSearchService,
    protected dataRecommend: DataRecommendService,
    protected shell: AppShellService
  ) {
    this.dataUser.get().subscribe(user => this.user = user);
  }

  async addToLibrary(articles: IDataArticle[], collectionId = this.user.id, listId?: string): Promise<void> {
    const items = articles.map(a => {
      return {
        ext_ids: a.ext_ids,
        article: <IDataItemArticle>{
          title: a.title,
          abstract: a.abstract,
          authors: Array.isArray(a.authors) ? a.authors.map(a => a.name) : [],
          eissn: a.eissn,
          issn: a.issn,
          issue: a.issue,
          journal: a.journal,
          journal_abbrev: a.journal_abbrev,
          pagination: a.pagination,
          volume: a.volume,
          year: a.year,
          isbn: a.isbn,
          eisbn: a.eisbn,
          chapter: a.chapter
        },
        custom_metadata: a.year ? {
          date: a.year
        } : undefined,
        resolve_files: false,
        resolve: true,
        merge: true
      };
    });

    const source$ = this.dataItem.bulkPost(collectionId, items, listId).pipe(last(), delay(2000));

    const findArticleToItem = (article: IDataArticle, item: IDataItem) => {
      for (let i in article.ext_ids) for (let j in item.ext_ids)
        if (article.ext_ids[i] && item.ext_ids[j] && article.ext_ids[i].toLowerCase() == item.ext_ids[j].toLowerCase()) {
          return true;
        }
      return false;
    };

    return lastValueFrom(source$).then(result => {
      const updatedArticles = result.data.map(item => articles.find(article => findArticleToItem(article, item)));

      updatedArticles.filter(article => !!article).forEach(article => {
        this.dataSearch.reloadItem(article.id);
        this.dataRecommend.reloadItem(article.id);
      });
    });
  }

  isInLibrary(collectionId: string, article: IDataArticle): boolean {
    if (!article?.item_statuses?.length) {
      return false;
    } else if (collectionId) {
      return article.item_statuses.map(s => s.collection_id).includes(collectionId);
    }
    return true;
  }

  viewInLibrary(collectionId: string, itemId: string) {
    this.router.navigate([
      'library', collectionId, 'all',
      {
        outlets: { 'sidepanel': 'details' }
      }
    ], {
      queryParams: {
        'item_id': itemId,
        'collection_id': collectionId
      }
    })
  }

  openPublisherById(extId: string, type: keyof IDataItemExtIds) {
    const urls = {
      'doi': 'https://dx.doi.org/' + extId,
      'pmid': 'https://www.ncbi.nlm.nih.gov/pubmed/' + extId,
      'pmcid': 'https://www.ncbi.nlm.nih.gov/pmc/articles/' + extId,
      'arxiv': 'https://arxiv.org/abs/' + extId,
      'patent_id': 'https://app.dimensions.ai/details/patent/' + extId,
    };
    this.shell.openURL(urls[type]);
  }

  openPubmed(pmid: string) {
    this.shell.openURL(`https://pubmed.ncbi.nlm.nih.gov/${pmid}`);
  }

  openDimensionsBadgeUrl(doi: string) {
    this.shell.openURL(`https://badge.dimensions.ai/details/doi/${doi}`);
  }

  openAltmetricBadgeUrl(altmetric_id: string, publisher: string) {
    if (this.user.organization_id) {
      this.shell.openURL(`https://papersenterprise.altmetric.com/details/${altmetric_id}`);
    } else {
      const p = publisher === 'ats' ? 'atsjournals' : (publisher || 'www');
      this.shell.openURL(`https://${p}.altmetric.com/details/${altmetric_id}`);
    }
  }

  openLiteratureReview() {
    this.shell.openURL(environment.baseUrls.review);
  }

  openSessionExpired() {
    return this.shell.showConfirm({
      title: 'Session Expired',
      message: 'Your session has timed out due to inactivity. If you were editing an item you will get a chance to save your changes. The page will reload after hitting OK.',
      confirmButtonText: 'OK',
      showCancel: false
    });
  }
}
