import { Injectable } from "@angular/core";
import { ReplaySubject, Subject, delay, mergeMap, of, takeUntil } from "rxjs";

@Injectable()
export class NavigationService {
  drawerOpen$: ReplaySubject<string | null> = new ReplaySubject(1);

  private openDrawer$: Subject<string> = new Subject();
  private closeDrawer$: Subject<void> = new Subject();
  private drawerOpen = false;

  openDrawer(drawerRouterLink: string) {
    this.openDrawer$.next(drawerRouterLink);
  }

  closeDrawer() {
    this.closeDrawer$.next();
  }

  constructor() {
    this.openDrawer$.pipe(
      mergeMap((e) => of(e).pipe(
        delay(this.drawerOpen ? 0 : 1000),
        takeUntil(this.closeDrawer$)
      ))
    ).subscribe((linkName) => {
      this.drawerOpen$.next(linkName);
      this.drawerOpen = true;
    });

    this.closeDrawer$.pipe(delay(100)).subscribe(() => {
      this.drawerOpen$.next(null);
      this.drawerOpen = false;
    });
  }
}