import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  DataMetricsApiService,
  IDataMetricsQueryParams,
  IDataMetricsResult
} from './data-metrics-api.service';

@Injectable()
export class DataMetricsService {
  constructor(
    public api: DataMetricsApiService
  ) { }

  query(params: IDataMetricsQueryParams): Observable<IDataMetricsResult> {
    return this.api.query(params);
  }
}
