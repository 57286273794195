<ng-template #listRowTemplate let-item="item">
  <div class="result">
    <div class="title pointer">
      <rcp-katex-paragraph [paragraph]="getHighlightedTitle(item)" [forceInline]="true"></rcp-katex-paragraph>
    </div>

    <div class="metadata">
      <span class="text authors">{{item?.authors | formatFirstAuthor}}</span>    
      <span class="text" *ngIf="item.year">
        <span>{{item.year}}<ng-container *ngIf="item.year && item.journal">,&nbsp;</ng-container></span>
        <span *ngIf="item.journal">{{item.journal}}</span>
      </span>
      <span class="text" *ngIf="item.journal && !item.year">{{item.journal}}</span>
    </div>

    <div class="highlights">
      <div class="text" *ngFor="let highlight of getHighlightedChunks(item)">...<span [innerHTML]="highlight"></span>...</div>
    </div>

    <div class="footer">
      <span class="block">Mentions <span class="badge papers-badge">0</span></span>
      <span class="block">Citations <span class="badge papers-badge">0</span></span>
      <span class="block">Altmetric <span class="badge papers-badge">0</span></span>
      <span class="block">
        <fa-icon [icon]="['fal', item.status_data?.open_access ? 'unlock' : 'lock']"></fa-icon> PDF
      </span>
      <span class="block" *ngIf="!!item.item_statuses?.length && addingToLibraryId != item.id">
        <fa-icon  [icon]="['fal', 'folder']"></fa-icon> In Library
      </span>
      <span class="block pointer" *ngIf="!item.item_statuses?.length && addingToLibraryId != item.id" (click)="onAddToLibrary(item)">
        <fa-icon [icon]="['fal', 'folder-plus']"></fa-icon> Add to Library
      </span>

      <span class="block pointer" *ngIf="addingToLibraryId == item.id">
        <fa-icon  [icon]="['fas', 'spinner-third']" [spin]="true"></fa-icon> Adding to Library...
      </span>
    </div>
  </div>
</ng-template>

<data-view-list
  [items]="items"
  [itemSize]="150" 
  [multiselect]="multiselect"
  [templateRef]="listRowTemplate"
  (open)="open.next($event)"
  [(selected)]="selected"
  [highlighted]="highlighted"
  (selectedChange)="onSelectItems($event)"
  (highlightedChange)="onHighlightItems($event)"
  (loadNext)="onLoadNext($event)">

  <common-loader-circles class="loader" [ngClass]="{'visible': loading}"></common-loader-circles>
</data-view-list>