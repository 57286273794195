import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCommaLastAuthor'
})
export class FormatCommaLastAuthorPipe implements PipeTransform {
  transform(value: string[] | { name: string; }[]): string {
    if (!value || value.length === 0) return '';
    let last = value[value.length - 1];
    let s = typeof last == 'string' ? last : last.name;
    return s;
  }
}