import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'environment';

export interface IDataReviewAddToProject {
  project_id: number;
  collection_id: string;
  ids: string[];
  source: string;
  labels: string[];
}

export interface IReviewProject {
  id: number;
  name: string;
  labels: string[];
}

export interface IDataReviewLinkToProject {
  review_project_id: number;
  collection_id: string;
  list_id: string;
  source: string;
  labels: string[];
}

export interface IReviewProjectLink {
  review_project_id: number;
  source: string;
  labels: string[];
}

@Injectable()
export class DataReviewApiService {

  constructor(
    private http: HttpClient
  ) { }

  getProjects(): Observable<IReviewProject[]> {
    return this.http.get<any>(`${environment.baseUrls.sync}/review/projects`).pipe(map(res => res['projects']));
  }

  addToProject(body: IDataReviewAddToProject): Observable<{ success: boolean; }> {
    return this.http.post<any>(`${environment.baseUrls.sync}/review/import_items`, body);
  }

  getProjectLink(collectionId: string, listId?: string): Observable<IReviewProjectLink> {
    let url = `${environment.baseUrls.sync}/review/collection_settings?collection_id=${collectionId}`;
    if (listId)
      url = url + `&list_id=${listId}`;
    return this.http.get<any>(url).pipe(
      map(response => response['forwarder'])
    );
  }

  linkToProject(body: IDataReviewLinkToProject): Observable<IReviewProjectLink> {
    return this.http.post<any>(`${environment.baseUrls.sync}/review/update_collection_settings`, body);
  }
}