import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IDataArticle } from '../../search-data';
import { AppShellService } from '../../app-shell.service';
import { SearchSidepanelService } from '../services/search-sidepanel.service';

@Component({
  selector: 'search-sidepanel-metrics',
  templateUrl: './search-sidepanel-metrics.component.html',
  styleUrls: [
    './search-sidepanel.component.scss',
    './search-sidepanel-metrics.component.scss'
  ],
})
export class SearchSidepanelMetricsComponent implements OnInit, OnDestroy {
  article: IDataArticle;
  articleSub: Subscription;
  chartType = 'cumulative';

  constructor(
    public sidepanel: SearchSidepanelService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.articleSub = this.sidepanel.article$.subscribe(article => {
      this.article = article;
    });
  }

  ngOnDestroy() {
    this.articleSub.unsubscribe();
  }
}
