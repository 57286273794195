import { Component, Input, Output, ViewChild, TemplateRef, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LoadNextEvent } from '@readcube/rcp-data-view';

import { SharedService } from '../../common';
import { IDataArticle, IDataArticleCollectionStatus } from '../../search-data';
import { IDataCollection } from '../../library-data';

@Component({
  selector: 'search-results-list',
  templateUrl: './search-results-list.component.html',
  styleUrls: ['./search-results-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultsListComponent {
  @Input()
  items: IDataArticleCollectionStatus[];

  @Input()
  collection: IDataCollection;

  @Input()
  selected: IDataArticle[];

  @Input()
  highlighted: IDataArticle[];

  @Input()
  loading = false;

  @Input()
  finished = false;

  @Input()
  multiselect = true;

  @Output()
  open = new EventEmitter<IDataArticle>();

  @Output()
  selectedChange = new EventEmitter<IDataArticle[]>();

  @Output()
  highlightedChange = new EventEmitter<IDataArticle[]>();

  @Output()
  loadNext = new EventEmitter<LoadNextEvent>();

  @ViewChild('listRowTemplate', { static: true })
  listRowTemplate: TemplateRef<any>;

  addingToLibraryId: string | null;

  constructor(
    public shared: SharedService,
    public changeDetectorRef: ChangeDetectorRef
    ) { }

  async onAddToLibrary(article: IDataArticle) {
    const collectionId = this.shared.user.id;
    this.addingToLibraryId = article.id;
    await this.shared.addToLibrary([article], collectionId);
    this.addingToLibraryId = null;
    this.changeDetectorRef.detectChanges();
  }

  onSelectItems(items: IDataArticle[]) {
    this.selectedChange.emit(items);
  }

  onHighlightItems(items: IDataArticle[]) {
    this.highlightedChange.emit(items);
  }

  onLoadNext(event: LoadNextEvent) {
    if (this.finished || this.loading) {
      return;
    }
    this.loadNext.emit(event);
  }

  getHighlightedTitle(item: IDataArticle): string {
    if (item.highlight.title?.length) {
      return item.highlight.title[0];
    }
    return item.title;
  }

  getHighlightedChunks(item: IDataArticle): string[] {
    const chunks = item.highlight.full_text || item.highlight.abstract || [];
    return chunks.slice(0, 2)
  }
}
