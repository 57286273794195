import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'common-stars-rating',
  templateUrl: './common-stars-rating.component.html',
  styleUrls: ['./common-stars-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonStarsRatingComponent {
  @Input()
  rating: number;

  @Output()
  ratingChange: EventEmitter<number>;

  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Input()
  showEmpty = false;

  stars: number[] = [1, 2, 3, 4, 5];

  constructor() {
    this.ratingChange = new EventEmitter<number>();
  }

  onRateClick(rating: number, event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    if (this.rating != rating) {
      this.rating = rating;
      this.ratingChange.emit(rating);
    } else {
      this.rating = null;
      this.ratingChange.emit(null);
    }
    event.stopPropagation();
  }
}
