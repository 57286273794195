<form #listForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">{{list.id ? 'Edit List' : 'Create List'}}</h4>
    <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body">
    <div [hidden]="!(error$ | async)" class="alert alert-danger">{{error$ | async}}</div>

    <div class="row">
      <div class="fcol-sm-12">
        <div class="form-floating">
          <input #name="ngModel" name="list-name-input" type="text" class="form-control"
            [(ngModel)]="list.name" required maxlength="50" autocomplete="off" ngbAutofocus>
          <span class="form-text text-muted" [hidden]="name.valid || name.pristine">List name is required.</span>
          <label for="list-name-input">List name</label>
        </div>
      </div>
    </div>
    <!--
    <div class="row mt-4">
      <div class="fcol-sm-12">
        <div class="form-floating " *ngIf="!list.id">
          <select name="collection-id-select" class="form-select" [(ngModel)]="list.collection_id" (ngModelChange)="onCollectionChange()" required>
            <option [value]="collection.id" *ngFor="let collection of collections$ | async">{{collection.name || 'My Papers'}}</option>
          </select>
          <label for="collection-id-select">In library</label>
        </div>
      </div>
    </div>
    -->
    <div class="row mt-4">
      <div class="fcol-sm-12">
        <div class="form-floating">
          <select name="parent-name-select" class="form-select" [(ngModel)]="list.parent_id">
            <option value="">(none)</option>
            <option [value]="list.id" *ngFor="let list of lists$ | async">{{list.name}}</option>
          </select>
          <label for="parent-name-select" class="font-weight-bold">Nest under</label>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="close()" [disabled]="disabled$ | async">Close</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!listForm.form.valid || (disabled$ | async)">{{list.id ? 'Save' : 'Create'}}</button>
  </div>

</form>
