<div *ngFor="let item of headerItems" class="drawer_header-item">
  <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
  <div (click)="item.action()">{{item.name}}</div>
</div>

<div class="drawer_section">
  <div class="drawer_section-title">Saved Projects</div>
  <div *ngFor="let item of savedProjects" class="drawer_item-container">
    <div class="drawer_item-icon">
      <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
    </div>
    <div class="drawer_item-content">
      <div class="drawer_item-title">{{item.title}}</div>
    </div>
  </div>
</div>

<div class="drawer_section">
  <div class="drawer_section-title">Due Soon</div>
  <div *ngFor="let item of dueSoonList" class="drawer_item-container">
    <div class="drawer_item-icon">
      <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
    </div>
    <div class="drawer_item-content">
      <div class="drawer_item-title">{{item.title}}</div>
      <div class="drawer_item-info">
        <rcp-svg-icon name="notification" width="6" height="6"></rcp-svg-icon>
        <div>{{item.notification}}</div>
      </div>
    </div>
  </div>
</div>