<ng-template #loadingTemplate>
  <common-loader-circles></common-loader-circles>
</ng-template>

<div class="wrapper papers-scrollbar" *ngIf="article">

  <search-sidepanel-header
    [journal]="article.journal"
    [year]="article.year"
    [title]="article.title"
    [chapter]="article.chapter">
  </search-sidepanel-header>

  <div class="authors" *ngIf="authorNames() as authors">
    <ul>
      <li *ngFor="let author of authorsCollapsed ? authors.slice(0, limit) : authors">
        <a href class="text-decoration-none" (click)="onAuthorClick($event, author)">{{author}}</a>
      </li>
    </ul>
  
    <div class="toggle-button" *ngIf="limit < authors.length">
      <a href="#" (click)="onAuthorsToggleClick($event)">
        {{authorsCollapsed ? 'more' : 'less'}}
        <fa-icon [icon]="['fas', authorsCollapsed ? 'caret-down' : 'caret-up']"></fa-icon>
      </a>
    </div>
  </div>

  <div class="abstract" *ngIf="article.abstract">
    <rcp-katex-paragraph [paragraph]="article.abstract"></rcp-katex-paragraph>
  </div>
  
  <div class="content">
    <div class="left">
      <div class="mb-2" *ngIf="article.item_statuses?.length">
        <div class="papers-label">Libraries</div>
        <div>
          <span *ngFor="let status of article.item_statuses" class="badge papers-badge">
            <a (click)="shared.viewInLibrary(status.collection_id, status.id)" class="collection-link">
              {{status.collection_name || 'My Papers'}} <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon>
            </a>
          </span>
        </div>
      </div>

      <div class="mb-2" *ngIf="article.counts.citations || article.counts.altmetric">
        <div class="papers-label">Metrics</div>
        <div class="badge-container">
          <span class="badge papers-badge" *ngIf="article.counts.citations">
            <a href (click)="shared.openDimensionsBadgeUrl(article.ext_ids.doi)" rcpPreventDefault>{{article.counts.citations | number | replace:'.':','}} Citations <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></a>
          </span>
          <span class="badge papers-badge" *ngIf="article.counts.altmetric">
            <a href (click)="shared.openAltmetricBadgeUrl(article.status_data.altmetric_id, article.status_data.publisher)" rcpPreventDefault>{{article.counts.altmetric | number | replace:'.':','}} Altmetric <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></a>
          </span>
        </div>
      </div>

      <div class="mb-2">
        <div class="papers-label">Identifiers</div>
        <div>
          <span *ngFor="let id of article.ext_ids | keyvalue" class="badge papers-badge">
            <a href (click)="openWebByExtId(id.value, id.key)" rcpPreventDefault *ngIf="id.value">{{(id.key === 'patent_id') ? 'patent' : id.key}}: {{id.value}} <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon></a>
          </span>
        </div>
      </div>
    </div>

    <div class="right mb-4">
      <div class="mb-2" *ngIf="article.thumb_url && !shared.user?.licence?.hide_thumbnails">
        <a href (click)="onThumbClick($event)" rcpPreventDefault>
          <img class="thumb papers-border" [src]="article.thumb_url" [hidden]="!thumb" (load)="thumb = true" (error)="thumb = false">
        </a>
      </div>

      <ng-container *ngIf="!(sidepanel.loading$ | async); else loadingTemplate">

        <div class="mb-2" *ngIf="canViewPDF, else getPDFTemplate">
          <button type="button" class="btn btn-primary papers-file-button" [disabled]="download && (download.inProgress$ | async)" (click)="viewPDF($event)">
            <div class="icon" *ngIf="!download || !(download.inProgress$ | async)">
              <rcp-svg-icon name="pdf-file" width="38" height="38"></rcp-svg-icon>
            </div>
            <div class="text" *ngIf="!download || !(download.inProgress$ | async); else progressTemplate">View PDF</div>

            <ng-template #progressTemplate>
              <div class="text">
                <ng-container [ngSwitch]="download.status$ | async">
                  <ng-container *ngSwitchCase="'downloading'">Downloading... {{download.percent$ | async}}&#37;</ng-container>
                  <ng-container *ngSwitchDefault>Searching...</ng-container>
                </ng-container>
              </div>
            </ng-template>
          </button>
        </div>

        <ng-template #getPDFTemplate>
          <div class="mb-2" *ngIf="canGetPDF, else locatePDFTemplate">
            <button type="button" class="btn btn-primary papers-file-button" (click)="getPDF($event)">
              <div class="icon">
                <rcp-svg-icon name="pdf-file" width="38" height="38"></rcp-svg-icon>
              </div>
              <div class="text">Get PDF</div>
            </button>
          </div>
        </ng-template>

        <ng-template #locatePDFTemplate>
          <div class="mb-2" *ngIf="canLocatePDF, else viewPubmedTemplate">
            <button type="button" class="btn btn-secondary papers-locate-button" (click)="locatePDF($event)"
              [disabled]="(importing$ | async) || (download?.inProgress$ | async) || (import?.inProgress$ | async)">
              <div class="icon">
                <rcp-svg-icon name="search-small" width="30" height="30"></rcp-svg-icon>
              </div>
              <div class="text">
                <div class="big">Locate PDF</div>
                <div class="small">
                  <ng-container *ngIf="!download || !(download.inProgress$ | async); else downloadTemplate">
                    <ng-container *ngIf="!import || !(import.inProgress$ | async); else importTemplate">
                      <ng-container *ngIf="!(importing$ | async)">Attempt download</ng-container>
                      <ng-container *ngIf="importing$ | async">Importing...</ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <ng-template #downloadTemplate>
                <ng-container [ngSwitch]="download.status$ | async">
                  <span *ngSwitchCase="'downloading'">Downloading... {{download.percent$ | async}}&#37;</span>
                  <span *ngSwitchDefault>Searching...</span>
                </ng-container>
              </ng-template>
              <ng-template #importTemplate>
                <ng-container [ngSwitch]="import.status$ | async">
                  <span *ngSwitchCase="'queued'">Waiting...</span>
                  <span *ngSwitchCase="'pending'">Processing...</span>
                  <span *ngSwitchCase="'running'">Uploading... <ng-container *ngIf="import.progress$ | async as progress">{{progress}}&#37;</ng-container></span>
                  <span *ngSwitchDefault>Uploading...</span>
                </ng-container>
              </ng-template>
            </button>
          </div>
        </ng-template>

        <ng-template #viewPubmedTemplate>
          <div class="mb-2" *ngIf="canViewPubmed">
            <button type="button" class="btn btn-primary papers-file-button" (click)="shared.openPubmed(article.ext_ids.pmid)">
              <div class="icon">
                <rcp-svg-icon name="pdf-file" width="38" height="38"></rcp-svg-icon>
              </div>
              <div class="text text--indent">View on PubMed</div>
            </button>
          </div>
        </ng-template>

        <ng-container *ngIf="canAddToLibrary()">
          <ng-template #importingTemplate>
            <fa-icon [icon]="['fas', 'spinner-third']" [spin]="true"></fa-icon> Importing...
          </ng-template>

          <div class="mb-2" ngbDropdown *ngIf="collections?.length">
            <ng-container *ngIf="collections.length > 1">
              <button ngbDropdownToggle type="button" class="btn btn-primary w-100"
                [disabled]="importing$ | async"
                [ngClass]="{'btn-secondary': canViewPDF || canGetPDF || (canViewPubmed && !canLocatePDF), 'no-caret': importing$ | async}">
                <ng-container *ngIf="!(importing$ | async); else importingTemplate">{{ getImportLabel(collections) }}</ng-container>
              </button>
              <div class="dropdown-menu papers-scrollbar" ngbDropdownMenu>
                <ng-container *ngFor="let collection of collections | sort:'name'">
                  <button type="button" class="dropdown-item" [ngClass]="{'checked': shared.isInLibrary(collection.id, article)}"
                    (click)="addToLibrary(collection.id)">{{collection.name || 'My Papers'}}</button>
                  <div class="dropdown-divider" *ngIf="!collection.shared"></div>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="collections.length === 1">
              <button type="button" class="btn btn-primary w-100" *ngIf="!shared.isInLibrary(collections[0].id, article)" (click)="importItemToLibrary()"
                [disabled]="importing$ | async"
                [ngClass]="{'btn-secondary': canViewPDF || canGetPDF || (canViewPubmed && !canLocatePDF)}">
                <ng-container *ngIf="!(importing$ | async); else importingTemplate">Add to Library</ng-container>
              </button>

              <button type="button" class="btn btn-primary w-100" *ngIf="shared.isInLibrary(collections[0].id, article)"
                (click)="viewInLibrary(collections[0].id)" [ngClass]="{'btn-secondary': canViewPDF || canGetPDF || (canViewPubmed && !canLocatePDF)}">View in Library
              </button>
            </ng-container>
          </div>
        </ng-container>

      </ng-container>

    </div>
  </div>

</div>
