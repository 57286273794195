import { Component, OnInit, OnDestroy, ViewChild, AfterContentInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';

import {
  EMPTY,
  Observable,
  ReplaySubject,
  merge as observableMerge,
  of as observableOf,
  asyncScheduler,
} from 'rxjs';

import {
  tap,
  map,
  switchMap,
  distinctUntilChanged,
  startWith,
  filter,
  catchError,
  shareReplay,
  mergeMap,
  throttleTime
} from 'rxjs/operators';

import { LoadNextEvent } from '@readcube/rcp-data-view';
import { CloseContextMenuEvent } from '@readcube/ngx-contextmenu';

import { SharedService, ModalSubscriptionComponent, HistoryService, ReaderService } from '../../common';
import { DataUserService, IDataUser }  from '../../common-data';
import { DataCollectionService, DataItemService, IDataCollection, IDataItem }  from '../../library-data';

import { AppShellService, shouldRemoveHighlight } from '../../app-shell.service';
import { LibraryHeaderComponent } from './library-header.component';
import { LibraryService } from '../services/library.service';
import { LibrarySidepanelService } from '../../library-sidepanel';
import { ImporterService } from '../../importer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './library-search.component.html',
  styleUrls: ['./library-search.component.scss']
})
export class LibrarySearchComponent implements OnInit, OnDestroy, AfterContentInit {
  viewName: string;

  selected: IDataItem[] = [];
  highlighted: IDataItem[] = [];

  loading$ = new ReplaySubject<boolean>(1);
  finished$ = new ReplaySubject<boolean>(1);
  itemQuickPush$ = new ReplaySubject<IDataItem>(1);
  selectionChange$ = new ReplaySubject<IDataItem[]>(1);

  sidePanelOpen$: Observable<boolean>;
  user$: Observable<IDataUser>;
  item$: Observable<IDataItem>;
  items$: Observable<IDataItem[]>;
  query$: Observable<string>;
  sort$: Observable<string>;
  order$: Observable<string>;
  collection$: Observable<IDataCollection>;
  statusText$: Observable<string>;

  canCopyItem: boolean = false;
  canManageItem: boolean = true;
  canManageFile: boolean = true;

  @ViewChild('libraryHeader', { static: true })
  libraryHeader: LibraryHeaderComponent;

  @ViewChild('selectionContainer', { static: true })
  selectionContainer: ElementRef;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public importer: ImporterService,
    public library: LibraryService,
    public sidepanel: LibrarySidepanelService,
    public history: HistoryService,
    public reader: ReaderService,
    public shared: SharedService,
    public modal: NgbModal,
    public dataUser: DataUserService,
    public dataCollection: DataCollectionService,
    public dataItem: DataItemService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.viewName = this.library.getViewName();

    this.user$ = this.dataUser.get().pipe(
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.sidePanelOpen$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !!this.route.children.find(child => child.outlet === 'sidepanel')),
      startWith(!!this.route.children.find(child => child.outlet === 'sidepanel')),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    // Filter streams...
    this.query$ = this.route.queryParams.pipe(
      map(params => params?.query),
      distinctUntilChanged(),
      map(value => value || ''),
      tap(value => this.history.pushSearchHistory('readcube', value)),
      tap(value => this.history.lastSearchQuery$.next(value))
    );

    this.sort$ = this.route.queryParams.pipe(
      map(params => params?.sort),
      map(value => value || this.library.getSorting()),
      tap(value => this.library.setSorting(value))
    );

    this.order$ = this.route.queryParams.pipe(
      map(params => params?.order),
      map(value => value || this.library.getOrdering()),
      tap(value => this.library.setOrdering(value))
    );

    // Content streams...
    this.item$ = this.sidepanel.item$ = this.route.queryParams.pipe(
      filter(params => 'collection_id' in params),
      filter(params => 'item_id' in params),
      distinctUntilChanged((x, y) => x['item_id'] === y['item_id']),
      tap(() => this.sidepanel.loading$.next(true)),
      mergeMap(params => this.importer.done$.pipe(
        // https://readcube.atlassian.net/browse/ELECTRON-1630
        map(() => params),
        throttleTime(3000, asyncScheduler, { leading: false, trailing: true }),
        startWith(params),
      )),
      switchMap(params => observableMerge(
        this.dataItem.get(params['collection_id'], params['item_id']).pipe(
          tap(item => {
            // populates selected list on first load
            if (!this.selected.length) {
              this.selected = [item];
            }
            this.sidepanel.loading$.next(false);
          }),
          catchError(err => {
            this.updateSelection([]);
            return EMPTY;
          })
        ),
        this.itemQuickPush$
      )),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.items$ = this.getItemsParams$().pipe(
      tap(() => this.loading$.next(true)),
      switchMap(params => this.dataItem.searchOrg(params)),
      tap(() => this.loading$.next(false)),
      tap(() => this.finished$.next(this.dataItem.isDone())),
      catchError(() => observableOf([])),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.statusText$ = this.items$.pipe(
      map(() => {
        const s = (n: number) => (n > 1) ? 's' : '';
        const total = this.dataItem.total;
        if (total === 0) {
          return 'No articles found.';
        }
        return `Found ${total} item${s(total)}.`;
      })
    );
  }

  ngOnDestroy() {

  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.libraryHeader.focusOnSearchInput();
    }, 200);
  }

  onViewNameChange(viewName: string) {
    this.library.setViewName(viewName);
  }

  onLoadNext(event: LoadNextEvent) {
    this.loading$.next(true);
    this.dataItem.searchOrgNext({}).catch(response => {
      if (response?.error === 'scroll_id_expired') {
        this.shared.openSessionExpired().then(() => {
          const url = this.router.url;
          this.router.navigateByUrl('.', { skipLocationChange: true })
            .then(() => this.router.navigateByUrl(url));
        });
      }
    });
  }

  onItemDblClick(item: IDataItem) {
    if (item.primary_file_hash && item.primary_file_type === 'pdf') {
      this.reader.viewItemPDF({ item });
    }
  }

  onDeleted(items: IDataItem[]) {
    this.deselectItems(items);
  }

  onEditDetails(event: any) {
    this.selected = [event.item];
    this.sidepanel.open(this.route, [event.item], 'edit');
  }

  onOpenResolver(event: any) {
    this.selected = [event.item];
    this.sidepanel.open(this.route, [event.item], 'resolve');
  }

  selectItems(items: IDataItem[]) {
    this.sidepanel.open(this.route, items, 'details').then(navigated => {
      if (navigated)
        this.itemQuickPush$.next(items[0]);
      this.updateSelection(items);
    });
  }

  highlightItems(items: IDataItem[]) {
    this.highlighted = items.slice(0);
  }

  onMenuClose(event: CloseContextMenuEvent) {
    if (shouldRemoveHighlight(this.selectionContainer, event))
      this.highlightItems([]);
  }

  openSidePanel(panel: string) {
    this.sidepanel.open(this.route, this.selected, panel);
  }

  closeSidePanel() {
    this.sidepanel.close(this.route);
  }

  openSubscriptionModal() {
    this.modal.open(ModalSubscriptionComponent);
  }


  deselectItems(items: IDataItem[]) {
    let selectionChanged = false;
    items.map(i => i.id).forEach(itemId => {
      let index = this.selected.findIndex(i => i.id === itemId);
      if (index > -1) {
        this.selected.splice(index, 1);
        selectionChanged = true;
      }
    });
    if (selectionChanged || !this.selected.length) {
      this.sidepanel.close(this.route);
      this.updateSelection(this.selected);
    }
  }

  protected updateSelection(selected: IDataItem[]) {
    this.highlighted = [];
    this.selected = selected.slice(0);
    this.selectionChange$.next(selected);
  }

  protected getItemsParams$() {
    const relevantParams = ['tags', 'query', 'sort', 'order'];
    return this.route.queryParams.pipe(
      distinctUntilChanged((x, y) => relevantParams.every(p => x[p] === y[p])),
      map(params => {
        return Object.assign({}, params, {
          'query': this.createQuery(params),
          'tags': params.tags ? (params.tags || '').split(',') : undefined,
          'sort': params.sort || this.library.getSorting(),
          'order': params.order || this.library.getOrdering(),
          'collection_id': null
        });
      })
    );
  }

  protected createQuery(params: Params): string {
    if (params.key) {
      return [params.key, '"' + params.query + '"'].join(':');
    }
    return params.query || '';
  }
}
