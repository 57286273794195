import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { tap, mergeMap, startWith, scan } from 'rxjs/operators';

import { DataCitationApiService, IDataCitation, IDataCitationQueryParams } from './data-citation-api.service';
import { IDataResultRefinements } from '../../app-data-result.model';

@Injectable()
export class DataCitationService {
  public total = 0;
  public loaded = 0;
  public refinements: IDataResultRefinements;

  protected push$ = new Subject<IDataCitation[]>();

  constructor(
    public api: DataCitationApiService
  ) { }

  query(params: IDataCitationQueryParams): Observable<IDataCitation[]> {
    const push$ = this.push$.asObservable();
    return this.api.query(params, true).pipe(
      tap(result => this.total = result.total),
      tap(result => this.loaded = result.rows.length),
      tap(result => this.refinements = result.refinements),
      mergeMap(result => push$.pipe(
        startWith(result.rows),
        scan((acc, val) => this.accumulator(acc, val))
      ))
    );
  }

  next(params: IDataCitationQueryParams): Promise<IDataCitation[]> {
    return firstValueFrom(this.api.query(params)).then(result => {
      this.loaded += result.rows.length;
      return this.pushDownstream(result.rows);
    });
  }

  protected pushDownstream(result: IDataCitation[]): IDataCitation[] {
    this.push$.next(result);
    return result;
  }

  protected accumulator(acc: IDataCitation[], val: IDataCitation[]): IDataCitation[] {
    return acc.concat(val);
  }
}
