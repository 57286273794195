<div class="modal-header">
  <h4 class="modal-title">Leave Shared Library</h4>
</div>

<div class="modal-body" *ngIf="collectionEditable">
  <ng-container *ngIf="canLeave; else assigneesTemplate">
    <p>Are you sure you want to leave library &quot;<i>{{collectionEditable?.name}}</i>&quot;?</p>
    <p>You will need to be invited if you wish to regain access.</p>
  </ng-container>

  <ng-template #assigneesTemplate>
    <ng-container *ngIf="assigneesList.length; else cantLeaveTemplate">
      <div class="row mt-2">
        <div class="col-sm-12">
          <label for="parent-name-select" class="fw-bold">Please select new library {{ isOrganization ? 'admin' : 'owner' }}</label>
          <select name="parent-name-select" class="form-control" [(ngModel)]="newAssigneeId">
            <option [value]="user.id" *ngFor="let user of assigneesList">{{user.name}} &lt;{{user.email}}&gt;</option>
          </select>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #cantLeaveTemplate>
    <p>Unfortunately, currently you are not able to leave &quot;<i>{{collectionEditable?.name}}</i>&quot; as you are the only remaining member.</p>
    <p>To leave library, first invite at least one other member.</p>
  </ng-template>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-lg btn-secondary" (click)="cancel()" ngbAutofocus>{{!canLeave && !assigneesList.length ? 'Close' : 'Cancel'}}</button>
  <button [hidden]="!canLeave && !assigneesList.length" type="button" class="btn btn-lg btn-primary" (click)="leave()" [disabled]="!canLeave && !newAssigneeId">Leave</button>
</div>
