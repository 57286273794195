import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeTypeaheadModule } from '@readcube/rcp-typeahead';

import { AppCommonModule } from '../common';
import { AppImporterRoutingModule } from './importer-routing.module';

import { ImporterProgressComponent } from './components/importer-progress.component';
import { ImporterProgressItemComponent } from './components/importer-progress-item.component';
import { ImporterFileListComponent } from './components/importer-file-list.component';
import { ImporterSourceSelectComponent } from './components/importer-source-select.component';
import { ImporterDestinationSelectComponent } from './components/importer-destination-select.component';
import { ImporterErrorBannerComponent } from './components/importer-error-banner.component';
import { ImporterOptionsPdfComponent } from './components/importer-options-pdf.component';
import { ImporterOptionsBibComponent } from './components/importer-options-bib.component';
import { ImporterOptionsRisComponent } from './components/importer-options-ris.component';
import { ImporterOptionsNbibComponent } from './components/importer-options-nbib.component';
import { ImporterOptionsCsvComponent } from './components/importer-options-csv.component';
import { ImporterOptionsMediaComponent } from './components/importer-options-media.component';

import { ImporterModalOptionsComponent } from './components/importer-modal-options.component';
import { ImporterService } from './services/importer.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeTypeaheadModule,
    AppCommonModule,
    AppImporterRoutingModule
  ],
  declarations: [
    ImporterModalOptionsComponent,
    ImporterProgressComponent,
    ImporterProgressItemComponent,
    ImporterFileListComponent,
    ImporterSourceSelectComponent,
    ImporterDestinationSelectComponent,
    ImporterErrorBannerComponent,
    ImporterOptionsPdfComponent,
    ImporterOptionsBibComponent,
    ImporterOptionsRisComponent,
    ImporterOptionsNbibComponent,
    ImporterOptionsCsvComponent,
    ImporterOptionsMediaComponent,
  ],
  exports: [
    ImporterProgressComponent
  ]
})
export class AppImporterModule {
  static forRoot(): ModuleWithProviders<AppImporterModule> {
    return {
      ngModule: AppImporterModule,
      providers: [
        ImporterService
      ]
    };
  }
}
