import { Injectable } from '@angular/core';
import { Observable, Subject, merge, throwError, asyncScheduler, firstValueFrom, interval } from 'rxjs';
import { tap, mergeMap, filter, map, startWith, delay, switchMap, throttleTime, shareReplay, exhaustMap, takeWhile } from 'rxjs/operators';

import { IDataItemExtIds } from '../../common-data';
import { IDataResultRefinements, IDataResult } from '../../app-data-result.model';
import { environment } from 'environment';

import {
  IDataItem,
  IDataItemAnnotation,
  IDataItemTag,
  IDataItemNotification,
  DataItemApiService,
  IDataBulkProgressEvent,
  IDataItemAddFileParams,
  IDataItemAddFileResponse,
  IDataItemDownloadParams,
  IDataItemImportOptions,
  IDataItemQueryParams,
  IDataItemRemoveFileParams,
  IDataItemSetPrimaryParams,
  IDataItemUploadFileParams,
  IDataItemUploadFileResponse,
  IDataItemsPendingUpload,
} from './data-item-api.service';

export type TUpdatePredicate = (item: IDataItem) => (row: IDataItem) => boolean;

interface IReloadRowsParams {
  collectionId: string;
  extraRowCount?: number;
}

interface IReloadTagsParams {
  collectionId: string;
}

@Injectable()
export class DataItemService {
  public rows: IDataItem[];
  public total = 0;
  public loaded = 0;
  public refinements: IDataResultRefinements;

  public defaultUpdatePredicate: TUpdatePredicate = ((item: IDataItem) => (row: IDataItem) => {
    return row.collection_id === item.collection_id && row.id === item.id;
  });

  protected orderIsDirty = false;
  protected scrollId?: string;

  protected tags$ = new Subject<IReloadTagsParams>();
  protected reload$ = new Subject<IReloadRowsParams>();
  protected create$ = new Subject<IDataItem[]>();
  protected update$ = new Subject<IDataItem[]>();
  protected concat$ = new Subject<IDataItem[]>();
  protected tagsCache$: { [key: string]: Observable<IDataItemTag[]>; } = {};

  constructor(
    public api: DataItemApiService
  ) { }

  isDone(): boolean {
    return this.loaded >= this.total;
  }

  create(item: Partial<IDataItem>): Promise<IDataItem> {
    return this.api.post(item.collection_id, item).then(item => {
      //this.reloadRows({ collectionId: item.collection_id });
      this.pushDownstream(item, 'create');
      return item;
    });
  }

  query(params: IDataItemQueryParams, updatePredicate?: TUpdatePredicate): Observable<IDataItem[]> {
    updatePredicate = updatePredicate || this.defaultUpdatePredicate;
    const notify$ = this.api.listen(params.collection_id);
    const query$ = this.api.query(params, environment.scrollBatchSizeForItems);
    const reload$ = this.reload$.pipe(
      filter(reload => reload.collectionId === params.collection_id),
      map(reload => {
        const size = this.loaded + (reload.extraRowCount || 0);
        return Object.assign({}, params, { size: Math.max(size, 50) });
      }),
      throttleTime(environment.throttleItemsReload, asyncScheduler, { leading: true, trailing: true }),
      delay(environment.delayBeforeItemsReload),
      mergeMap(params => this.api.query(params, environment.scrollBatchSizeForItems))
    );
    return merge(query$, reload$).pipe(
      tap(result => {
        this.rows = result.rows;
        this.total = result.total;
        this.loaded = result.rows.length;
        this.refinements = result.refinements;
        this.orderIsDirty = false;
        this.scrollId = result.scroll_id;
      }),
      switchMap(result => merge(
        this.create$.pipe(map(items => this.createItems(items))),
        this.update$.pipe(map(items => this.updateItems(items, this.defaultUpdatePredicate))),
        this.concat$.pipe(map(items => this.concatItems(items))),
        notify$.pipe(mergeMap(data => this.handleNotification(params.collection_id, data, this.defaultUpdatePredicate)))
      ).pipe(
        startWith(result.rows)
      ))
    );
  }

  queryAll(params: IDataItemQueryParams): Observable<IDataResult<IDataItem>> {
    let scrollId: string;
    let itemsTotal: number;
    return interval(environment.bulkRequestInterval).pipe(
      exhaustMap(() => this.api.query(params, environment.bulkRequestSize, scrollId)),
      takeWhile(result => result.rows.length > 0),
      tap(result => scrollId = result.scroll_id),
      tap(result => {
        if (!itemsTotal)
          itemsTotal = result.total;
        result.total = itemsTotal;
      })
    );
  }

  queryNext(params: IDataItemQueryParams): Promise<IDataItem[]> {
    return firstValueFrom(this.api.query(params, environment.scrollBatchSizeForItems, this.scrollId)).then(result => {
      this.loaded += result.rows.length;
      this.scrollId = result.scroll_id;
      return this.pushDownstream(result.rows, 'concat');
    });
  }

  searchOrg(params: IDataItemQueryParams, updatePredicate?: TUpdatePredicate): Observable<IDataItem[]> {
    updatePredicate = updatePredicate || this.defaultUpdatePredicate;
    return this.api.query(params, environment.scrollBatchSizeForItems).pipe(
      tap(result => {
        this.rows = result.rows;
        this.total = result.total;
        this.loaded = result.rows.length;
        this.refinements = result.refinements;
        this.orderIsDirty = false;
        this.scrollId = result.scroll_id;
      }),
      switchMap(result => merge(
        this.update$.pipe(map(items => this.updateItems(items, this.defaultUpdatePredicate))),
        this.concat$.pipe(map(items => this.concatItems(items)))
      ).pipe(
        startWith(result.rows)
      ))
    );
  }

  searchOrgNext(params: IDataItemQueryParams): Promise<IDataItem[]> {
    return firstValueFrom(this.api.query(params, environment.scrollBatchSizeForItems, this.scrollId)).then(result => {
      this.loaded += result.rows.length;
      this.scrollId = result.scroll_id;
      return this.pushDownstream(result.rows, 'concat');
    });
  }

  get(collectionId: string, itemId: string): Observable<IDataItem> {
    const get$ = this.api.get(collectionId, itemId);
    const notify$ = this.api.listen(collectionId, itemId);
    const update$ = notify$.pipe(map(d => d[0]), filter(d => ['updated', 'annotated'].includes(d.action)), mergeMap(() => get$));
    const delete$ = notify$.pipe(map(d => d[0]), filter(d => d.action === 'deleted'), mergeMap(() => throwError(() => new Error('deleted'))));
    const reload$ = this.reload$.pipe(
      filter(reload => reload.collectionId === collectionId),
      throttleTime(environment.throttleItemsReload, asyncScheduler, { leading: true, trailing: true }),
      delay(environment.delayBeforeItemsReload),
      mergeMap(() => get$)
    );
    return merge(get$, update$, delete$, reload$).pipe(
      mergeMap(item => this.update$.pipe(
        map(items => items.filter(i => i.collection_id === collectionId && i.id === itemId)),
        filter(items => items.length === 1),
        map(items => items[0]),
        startWith(item)
      ))
    );
  }

  update(collectionId: string, itemId: string, item: Partial<IDataItem>): Promise<IDataItem> {
    return this.api.patch(collectionId, itemId, item)
      .then(item => this.pushDownstream(item)[0]);
  }

  delete(collectionId: string, itemId: string): Promise<IDataItem> {
    return this.api.delete(collectionId, itemId)
      .then(item => this.pushDownstream(item)[0]);
  }

  annotations(collectionId: string, itemId: string): Promise<IDataItemAnnotation[]> {
    return this.api.annotations(collectionId, itemId);
  }

  deleteAnnotation(collectionId: string, itemId: string, annotationId: string): Promise<IDataItem> {
    return this.api.deleteAnnotation(collectionId, itemId, annotationId)
      .then(item => this.pushDownstream(item)[0]);
  }

  patchAnnotation(collectionId: string, itemId: string, annotationId: string, annotation: Partial<IDataItemAnnotation>): Promise<IDataItem> {
    return this.api.patchAnnotation(collectionId, itemId, annotationId, annotation)
      .then(item => this.pushDownstream(item)[0]);
  }

  tags(collectionId: string): Observable<IDataItemTag[]> {
    const reload$ = this.tags$.pipe(
      filter(reload => reload.collectionId === collectionId),
      throttleTime(environment.throttleItemsReload, asyncScheduler, { leading: true, trailing: true }),
      delay(environment.delayBeforeItemsReload),
      mergeMap(reload => this.api.tags(reload.collectionId))
    );
    if (!this.tagsCache$[collectionId]) {
      this.tagsCache$[collectionId] = merge(this.api.tags(collectionId), reload$).pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }
    return this.tagsCache$[collectionId];
  }

  removeTag(collectionId: string, name: string): Promise<any> {
    return this.api.removeTag(collectionId, name).then(() => {
      this.tags$.next({ collectionId });
      this.reloadRows({ collectionId });
    });
  }

  renameTag(collectionId: string, name: string, newName: string): Promise<any> {
    return this.api.renameTag(collectionId, name, newName).then(() => {
      this.tags$.next({ collectionId });
      this.reloadRows({ collectionId });
    });
  }

  merge(collectionId: string, sourceItemId: string, targetItemId: string): Promise<IDataItem[]> {
    return this.api.merge(collectionId, sourceItemId, targetItemId).then(items => {
      return this.pushDownstream(items);
    });
  }

  bulkPost(
    collectionId: string,
    items: Partial<IDataItem>[],
    listId: string = null,
    stop$?: Observable<boolean>,
    includeIds?: boolean):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    return this.api.bulkPost(collectionId, items, listId, stop$, includeIds).pipe(
      tap(result => this.pushDownstream(result.data, 'create'))
    );
  }

  bulkDelete(
    collectionId: string,
    itemIds: string[],
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    return this.api.bulkDelete(collectionId, itemIds, stop$).pipe(
      tap(result => this.pushDownstream(result.data))
    );
  }

  bulkUpdate(
    collectionId: string,
    items: Partial<IDataItem>[],
    action = 'update',
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    return this.api.bulkPatch(collectionId, items, action, stop$).pipe(
      tap(result => this.pushDownstream(result.data))
    );
  }

  bulkCopy(
    ids: string[],
    collectionId: string,
    listIds: string[],
    options: IDataItemImportOptions,
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    return this.api.bulkCopy(ids, collectionId, listIds, options, stop$);
  }

  bulkResolve(
    collectionId: string,
    items: IDataItem[],
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    return this.api.bulkResolve(collectionId, items, stop$).pipe(
      tap(result => this.pushDownstream(result.data))
    );
  }

  bulkClearMetadata(
    collectionId: string,
    items: IDataItem[],
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    return this.api.bulkClearMetadata(collectionId, items, stop$).pipe(
      tap(result => this.pushDownstream(result.data))
    );
  }

  bulkLinkFiles(
    collectionId: string,
    items: IDataItem[],
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    return this.api.bulkLinkFiles(collectionId, items, stop$).pipe(
      tap(result => this.pushDownstream(result.data))
    );
  }

  uploadFile(collectionId: string, params: IDataItemUploadFileParams): Promise<IDataItemUploadFileResponse> {
    return this.api.uploadFile(collectionId, params);
  }

  addFile(collectionId: string, itemId: string, params: IDataItemAddFileParams): Promise<IDataItemAddFileResponse> {
    return this.api.addFile(collectionId, itemId, params);
  }

  removeFile(collectionId: string, itemId: string, params: IDataItemRemoveFileParams) {
    return this.api.removeFile(collectionId, itemId, params)
      .then(item => this.pushDownstream(item)[0]);
  }

  setPrimary(collectionId: string, itemId: string, params: IDataItemSetPrimaryParams) {
    return this.api.setPrimary(collectionId, itemId, params)
      .then(item => this.pushDownstream(item)[0]);
  }

  uploadsPending(collectionId: string): Observable<Map<string, IDataItemsPendingUpload>> {
    return this.api.uploadsPending(collectionId);
  }

  downloadFileURL(collectionId: string, itemId: string, params: IDataItemDownloadParams): Promise<string> {
    return this.api.downloadFileURL(collectionId, itemId, params);
  }

  getViewerURL(collectionId: string, itemId: string): Promise<string> {
    return this.api.getViewerURL(collectionId, itemId);
  }

  add(collectionId: string, extIds: IDataItemExtIds, merge = true, resolve = true, resolveFiles = true): Promise<IDataItem> {
    return this.api.add(collectionId, extIds, merge, resolve, resolveFiles);
  }

  updateDetails(collectionId: string, itemId: string, extIds: IDataItemExtIds): Promise<IDataItem> {
    return this.api.updateDetails(collectionId, itemId, extIds)
      .then(item => this.pushDownstream(item)[0]);
  }

  downloadFromCloud(collectionId: string, itemId: string, hash?: string): Promise<IDataItem> {
    return this.api.downloadFromCloud(collectionId, itemId, hash);
  }

  reloadRows(params: IReloadRowsParams) {
    this.reload$.next(params);
  }

  reloadTags(params: IReloadTagsParams) {
    this.tags$.next(params);
  }

  pushDownstream(items: IDataItem | IDataItem[], mode?: 'concat' | 'create' | 'update'): IDataItem[] {
    items = Array.isArray(items) ? items : [items];
    switch (mode) {
      case 'concat':
        this.concat$.next(items);
        break;
      case 'create':
        this.create$.next(items);
        break;
      default:
        this.update$.next(items);
    }
    return items;
  }

  protected updateItems(items: IDataItem[], updatePredicate: TUpdatePredicate): IDataItem[] {
    items.forEach(item => {
      const replaceItem = !item.deleted ? item : undefined;
      this.updateItem(updatePredicate(item), replaceItem);
    });
    return this.rows.slice();
  }

  protected createItems(items: IDataItem[]): IDataItem[] {
    this.orderIsDirty = true;
    this.rows.splice(0, 0, ...items);
    return this.rows.slice();
  }

  protected concatItems(items: IDataItem[]): IDataItem[] {
    this.rows.splice(this.rows.length, 0, ...items);
    return this.rows.slice();
  }

  protected handleNotification(collectionId: string, data: IDataItemNotification[], updatePredicate: TUpdatePredicate): Promise<IDataItem[]> {
    const created = data.filter(d => d.type == 'item' && d.action === 'created');
    const updated = data.filter(d => d.type == 'item' && d.action === 'updated')
      .filter(d => this.rows.some(item => item.id === d.id));
    const annotated = data.filter(d => d.type == 'item' && d.action === 'annotated')
      .filter(d => this.rows.some(item => item.id === d.id));
    const deleted = data.filter(d => d.type == 'item' && d.action === 'deleted')
      .filter(d => this.rows.some(item => item.id === d.id));
    return Promise.resolve().then(async () => {
      // If even one of notifications is 'created' then reload rows and skip everything else.
      if (created.length > 0) {
        this.reloadRows({ collectionId });
      } else {
        // For 'deleted' notification remove items.
        if (deleted.length > 0) {
          deleted.forEach(d => this.updateItem(row => {
            return row.collection_id === collectionId && row.id === d.id;
          }));
        }
        // For 'updated' or 'annotated' fetch and replace changed items.
        const changed = [].concat(annotated, updated);
        if (changed.length > 0) {
          return firstValueFrom(this.api.getItems(collectionId, changed.map(d => d.id)))
            .then(result => result.rows.forEach(item => {
              this.updateItem(updatePredicate(item), item);
            }));
        }
      }
    })
      .then(() => {
        // Additionaly reload tags.
        if (created.length > 0 || updated.length > 0 || deleted.length > 0) {
          this.reloadTags({ collectionId });
        }
      })
      .then(() => this.rows.slice());
  }

  protected updateItem(findPredicate: (row: IDataItem) => boolean, replaceItem?: IDataItem): void {
    if (this.orderIsDirty) {
      this.reloadRows({ collectionId: replaceItem?.collection_id });
    }
    const i = this.rows.findIndex(findPredicate);
    if (!replaceItem) {
      if (i > -1) {
        this.rows.splice(i, 1); // Remove from list
        this.total--;
      }
    } else {
      if (i > -1) {
        this.rows.splice(i, 1, replaceItem); // Replace in list
      } else {
        //this.rows.splice(0, 0, replaceItem); // Add to list
      }
    }
  }
}
