import { Component, OnInit, OnDestroy, Inject, ViewChild, AfterContentInit, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { CloseContextMenuEvent } from '@readcube/ngx-contextmenu';
import { DragHelperEvent } from '@readcube/rcp-drag-and-drop';
import { LoadNextEvent } from '@readcube/rcp-data-view';
import { EMPTY, Observable, ReplaySubject, Subscription, combineLatest, merge, of } from 'rxjs';
import { tap, map, first, switchMap, distinctUntilChanged, startWith, filter, catchError, shareReplay, mergeWith, skip } from 'rxjs/operators';

import { SharedService, HistoryService, ModalSubscriptionComponent } from '../../common';
import { DataUserService, IDataUser } from '../../common-data';
import { DataCollectionService, DataListService, DataItemService, IDataItem, IDataCollection, IDataList } from '../../library-data';
import { AppShellService, shouldRemoveHighlight } from '../../app-shell.service';
import { LibraryService } from '../services/library.service';
import { PreviewService } from '../../library-preview';
import { LibrarySidepanelService } from '../../library-sidepanel';
import { ImporterService, IMPORT_TYPE_ID } from '../../importer';
import { LibraryHeaderComponent } from './library-header.component';

import { environment } from 'environment';
import { AppThemeService } from 'src/app/app-theme.service';

@Component({
  selector: 'library-collection',
  templateUrl: './library-collection.component.html',
  styleUrls: ['./library-collection.component.scss']
})
export class LibraryCollectionComponent implements OnInit, OnDestroy, AfterContentInit {
  viewName: string;
  viewSizes: number[];
  viewFilters = {};

  loading$ = new ReplaySubject<boolean>(1);
  finished$ = new ReplaySubject<boolean>(1);
  itemQuickPush$ = new ReplaySubject<IDataItem>(1);
  selectionChange$ = new ReplaySubject<IDataItem[]>(1);

  sidepanelOpen$: Observable<boolean>;
  previewOpen$: Observable<boolean>;
  user$: Observable<IDataUser>;
  collection$: Observable<IDataCollection>;
  list$: Observable<IDataList>;
  items$: Observable<IDataItem[]>;
  query$: Observable<string>;
  sort$: Observable<string>;
  order$: Observable<string>;
  searchPlaceholder$: Observable<string>;
  statusText$: Observable<string>;
  importReloadSub: Subscription;

  canCopyItem = false;
  canManageItem = true;
  canManageFile = true;
  listHasFocus = false;

  isDroppableFn: () => boolean;
  selected: IDataItem[] = [];
  highlighted: IDataItem[] = [];
  loaded: IDataItem[] = [];
  loadFilterPredicate = (params: Params) => (item: IDataItem) => true;

  get collectionId(): string {
    return this.route.snapshot.paramMap.get('collection_id');
  }

  get listId(): string {
    return this.route.snapshot.paramMap.get('list_id');
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.listHasFocus && event.key == 'a' && (event.ctrlKey || event.metaKey)) {
      this.selectItems(this.loaded);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @ViewChild('libraryHeader', { static: true })
  libraryHeader: LibraryHeaderComponent;

  @ViewChild('selectionContainer', { static: true })
  selectionContainer: ElementRef;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modal: NgbModal,
    public library: LibraryService,
    public preview: PreviewService,
    public sidepanel: LibrarySidepanelService,
    public shared: SharedService,
    public history: HistoryService,
    public importer: ImporterService,
    public dataUser: DataUserService,
    public dataCollection: DataCollectionService,
    public dataItem: DataItemService,
    public dataList: DataListService,
    public shell: AppShellService,
    public theme: AppThemeService,
    @Inject(DOCUMENT)
    public document: Document
  ) {
    this.isDroppableFn = this.isDroppable.bind(this);
  }

  ngOnInit() {
    this.viewName = this.library.getViewName();
    this.viewSizes = this.library.getViewSizes();
    this.viewFilters['sort'] = this.library.getSorting();
    this.viewFilters['order'] = this.library.getOrdering();

    this.sidepanelOpen$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !!this.route.children.find(child => child.outlet === 'sidepanel')),
      startWith(!!this.route.children.find(child => child.outlet === 'sidepanel')),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.previewOpen$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !!this.route.children.find(child => child.outlet === 'bottompanel')),
      startWith(!!this.route.children.find(child => child.outlet === 'bottompanel')),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.user$ = this.dataUser.get().pipe(
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.collection$ = this.sidepanel.collection$ = this.route.params.pipe(
      map(params => params['collection_id']),
      filter(collectionId => !!collectionId),
      distinctUntilChanged(),
      switchMap(collectionId => this.dataCollection.get(collectionId)),
      tap(collection => {
        this.canCopyItem = !!collection.user?.can_copy_item;
        this.canManageItem = !!collection.user?.can_create_item;
        this.canManageFile = !!collection.user?.can_manage_file;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.list$ = this.route.params.pipe(
      filter(params => 'collection_id' in params),
      filter(params => 'list_id' in params),
      map(params => ({
        collectionId: params['collection_id'],
        listId: params['list_id']
      })),
      distinctUntilChanged(),
      switchMap(p => this.dataList.get(p.collectionId, p.listId)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.sidepanel.item$ = this.route.queryParams.pipe(
      filter(params => 'collection_id' in params),
      filter(params => 'item_id' in params),
      distinctUntilChanged((x, y) => x['item_id'] === y['item_id']),
      tap(() => this.sidepanel.loading$.next(true)),
      switchMap(params => this.dataItem.get(params['collection_id'], params['item_id']).pipe(
        tap((item) => {
          // populates selected list on first load
          if (!this.selected.length) {
            this.selected = [item];
          }
          // just to refresh data for next replay
          this.itemQuickPush$.next(item);
          this.sidepanel.loading$.next(false);
        }),
        catchError(err => {
          this.sidepanel.loading$.next(false);
          this.updateSelection([]);
          return EMPTY;
        })
      )),
      mergeWith(this.itemQuickPush$),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    // Filter streams...
    this.query$ = this.route.queryParams.pipe(
      map(params => params['query']),
      map(value => value || ''),
      distinctUntilChanged(),
      tap(value => this.history.pushSearchHistory(this.collectionId, value))
    );

    this.sort$ = this.route.queryParams.pipe(
      map(params => params['sort']),
      map(value => value || this.library.getSorting()),
      tap(value => this.library.setSorting(value))
    );

    this.order$ = this.route.queryParams.pipe(
      map(params => params['order']),
      map(value => value || this.library.getOrdering()),
      tap(value => this.library.setOrdering(value))
    );

    this.searchPlaceholder$ = this.collection$.pipe(
      map(collection => collection.name || 'my papers'),
      map(name => ['Search within ', name.toLowerCase(), '...'].join('')),
      startWith('')
    );

    // Content streams...
    this.items$ = this.getItemsParams$().pipe(
      tap(() => this.loading$.next(true)),
      switchMap(params => this.dataItem.query(params).pipe(
        catchError(() => {
          this.loading$.next(false);
          return of([]);
        }),
        map(items => items.filter(this.loadFilterPredicate(params)))
      )),
      tap(() => this.loading$.next(false)),
      tap(() => this.finished$.next(this.dataItem.isDone())),
      tap(items => this.loaded = items),
      tap(items => this.selectQueryParamItems(items)),
      catchError(() => of([])),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.statusText$ = merge(this.items$, this.selectionChange$).pipe(
      map(() => {
        const s = (n: number) => (n > 1 || n === 0) ? 's' : '';
        const c = this.selected.length;
        if (c > 0) {
          return `${c} of ${this.dataItem.total} selected`;
        }
        return `${this.dataItem.total} item${s(this.dataItem.total)}`;
      }),
      startWith('Loading...')
    );

    this.importReloadSub = this.importer.doneCnt$
      .pipe(distinctUntilChanged(), skip(1))
      .subscribe(() => this.updateState());
  }

  ngOnDestroy() {
    this.importReloadSub.unsubscribe();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.libraryHeader.focusOnSearchInput();
    }, 200);
  }

  onViewNameChange(viewName: string) {
    this.library.setViewName(viewName);
  }

  onResizeStart(event: { gutterNum: number, sizes: number[]; }) {
    this.document.body.classList.add('resizing');
  }

  onResizeEnd(event: { gutterNum: number, sizes: number[]; }) {
    this.viewSizes = event.sizes;
    this.library.setViewSizes(event.sizes);
    this.document.body.classList.remove('resizing');
  }

  onLoadNext(event: LoadNextEvent) {
    this.loading$.next(true);
    this.dataItem.queryNext({
      collection_id: this.collectionId,
      list_id: this.listId
    }).catch(response => {
      if (response?.error === 'scroll_id_expired') {
        this.dataItem.reloadRows({
          collectionId: this.collectionId,
          extraRowCount: environment.scrollBatchSizeForItems
        });
      }
    });
  }

  onDrop(event: DragHelperEvent<DataTransfer>) {
    if (!event.data || !event.data.files) {
      return;
    }
    const files = Array.from(event.data.files);
    this.importer.openImportDialog(this.collectionId, this.listId, null, files);
  }

  onDropAttachment(event: DragHelperEvent<DataTransfer>) {
    if (!event.data || !event.data.files || !this.selected.length) {
      return;
    }
    const item = this.selected[0];
    const files = Array.from(event.data.files);
    this.importer.addToItem(files, item.collection_id, item.id);
    this.importer.start();
  }

  onItemDblClick(item: IDataItem) {
    // Get the full item first for status_data field used in canViewPDF function.
    this.dataItem.get(item.collection_id, item.id).pipe(first()).subscribe(item => {
      if (this.library.canViewItemPDF(item)) {
        this.library.viewItemPDF(item);
      }
    });
  }

  onImportFiles(importType: IMPORT_TYPE_ID) {
    this.importer.openImportDialog(this.collectionId, this.listId, importType);
  }

  onItemCreated(item: IDataItem) {
    const items = [item];
    this.selectItems(items, 'edit');
  }

  onDeleted(items: IDataItem[]) {
    this.dataItem.reloadTags({ collectionId: this.collectionId });
    this.deselectItems(items || []);
  }

  onEditDetails(event: any) {
    this.selected = [event.item];
    this.sidepanel.open(this.route, [event.item], 'edit');
  }

  onOpenResolver(event: any) {
    this.selected = [event.item];
    this.sidepanel.open(this.route, [event.item], 'resolve').then(() => {
      if (!!event.item.primary_file_hash) {
        this.preview.showResolve(this.route, event.item);
      }
    });
  }

  isDroppable(event: DragHelperEvent<DataTransfer>): boolean {
    return event.type === 'files';
  }

  hasPreview(item: IDataItem): boolean {
    return this.library.canViewItemPDF(item);
  }

  selectItems(items: IDataItem[], panel?: string) {
    const isFirstSelect = !this.route.snapshot.queryParamMap.has('item_id');
    if (items.length === 1) {
      const item = items[0];
      if (isFirstSelect) {
        this.sidepanel.setActive(panel || 'details');
      }
      panel = panel || this.sidepanel.getActive();
      if (panel === 'resolve') {
        panel = 'details';
      }
      this.sidepanel.open(this.route, items, panel).then(navigated => {
        if (navigated) {
          this.itemQuickPush$.next(item);
        }
      });
    } else {
      this.sidepanel.close(this.route, items).then(() => {
        this.preview.close(this.route);
      });
    }
    this.updateSelection(items);
  }

  highlightItems(items: IDataItem[]) {
    this.highlighted = items.slice(0);
  }

  onMenuClose(event: CloseContextMenuEvent) {
    if (shouldRemoveHighlight(this.selectionContainer, event))
      this.highlightItems([]);
  }

  selectQueryParamItems(loaded: IDataItem[]) {
    const params = this.route.snapshot.queryParamMap;
    const itemIds = (params.get('item_id') || '').split(',');
    const items = loaded.filter(item => itemIds.indexOf(item.id) !== -1);
    if (items.length)
      this.updateSelection(items);
  }

  deselectItems(items: IDataItem[]) {
    let selectionChanged = false;
    items.map(i => i.id).forEach(itemId => {
      let index = this.selected.findIndex(i => i.id === itemId);
      if (index > -1) {
        this.selected.splice(index, 1);
        selectionChanged = true;
      }
    });
    if (selectionChanged || !this.selected.length) {
      this.sidepanel.close(this.route).then(() => {
        this.preview.close(this.route);
      });
      this.updateSelection(this.selected);
    }
  }

  updateState() {
    this.dataItem.reloadRows({ collectionId: this.collectionId });
    this.dataItem.reloadTags({ collectionId: this.collectionId });
    this.dataList.reloadRows({ collectionId: this.collectionId });
  }

  openPreview(item: IDataItem) {
    this.preview.show(this.route, item);
  }

  closePreview() {
    this.preview.close(this.route);
  }

  openSidePanel(panel: string) {
    this.sidepanel.open(this.route, this.selected, panel);
  }

  closeSidePanel() {
    this.sidepanel.close(this.route);
  }

  openSubscriptionModal() {
    this.modal.open(ModalSubscriptionComponent);
  }

  protected updateSelection(selected: IDataItem[]) {
    this.highlighted = [];
    this.selected = selected.slice(0);
    this.selectionChange$.next(selected);
  }

  protected getItemsParams$() {
    const relevantParams = ['collection_id', 'list_id', 'tags', 'query', 'sort', 'order', 'reload'];
    return combineLatest([
      Promise.resolve(this.viewFilters),
      this.route.params,
      this.route.queryParams
    ]).pipe(
      map(result => Object.assign({}, ...result)),
      distinctUntilChanged((x, y) => relevantParams.every(p => x[p] === y[p])),
      map(params => {
        return Object.assign({}, params, {
          'tags': params.tags ? [params.tags] : undefined,
          'query': this.createQuery(params),
          'sort': params.sort || this.library.getSorting(),
          'order': params.order || this.library.getOrdering()
        });
      })
    );
  }

  protected createQuery(params: Params): string {
    if (params.key) {
      return [params.key, '"' + params.query + '"'].join(':');
    }
    return params.query;
  }
}
