import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, EventType } from '@angular/router';
import { Observable, Subscription, combineLatest, ReplaySubject } from 'rxjs';
import { filter, map, startWith, shareReplay, scan } from 'rxjs/operators';
import { DragAndDropService } from '@readcube/rcp-drag-and-drop';

import { DataItemService, IDataCollection, IDataItemTag } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { LibraryNavigationService } from '../services/library-navigation.service';

@Component({
  selector: 'library-navigation-tags',
  templateUrl: './library-navigation-tags.component.html',
  styleUrls: ['./library-navigation-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryNavigationTagsComponent implements OnInit, OnDestroy {
  @Input()
  indent = 0;

  @Input()
  collection: IDataCollection;

  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef;

  filter$ = new ReplaySubject<string>(1);
  dragActive$: Observable<boolean>;
  tags$: Observable<{ items: IDataItemTag[], total: number }>;

  searchTerm = '';
  expanded = false;
  pageSize = 100;
  expandedSub: Subscription;
  showMoreTags = new ReplaySubject<number>(1);

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public changeDetectroRef: ChangeDetectorRef,
    public dataItem: DataItemService,
    public navigation: LibraryNavigationService,
    public dragAndDrop: DragAndDropService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.tags$ = combineLatest([
      this.dataItem.tags(this.collection.id),
      this.showMoreTags.pipe(startWith(10), scan((acc, curr) => acc + curr)),
      this.filter$.pipe(startWith(''))
    ]).pipe(
      map(([ tags, limit, term ]) => {
        const items = tags.filter(value => {
          return value.name.toLocaleLowerCase().includes(term.toLocaleLowerCase());
        });
        return {
          items: items.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          }).slice(0, limit),
          total: items.length
        };
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.dragActive$ = this.dragAndDrop.event$.pipe(
      map(event => event.name === 'start'),
      shareReplay({ refCount: true, bufferSize: 1 }),
      startWith(false)
    );

    this.expanded = decodeURIComponent(this.router.routerState?.snapshot?.url).includes(`/library/${this.collection.id}/tag/`);
    this.expandedSub = this.router.events.pipe(
      filter(event => event.type == EventType.NavigationEnd),
      filter(event => (<any>event).url.includes(`/library/${this.collection.id}/tag/`))
    ).subscribe(() => {
      this.setExpanded(true);
      this.changeDetectroRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this.expandedSub.unsubscribe();
  }

  updateFilter(value: string) {
    this.filter$.next(value);
  }

  setExpanded(value = true, focusSearchInput = false) {
    this.expanded = value;
    if (value && focusSearchInput) {
      setTimeout(() => {
        this.searchInput?.nativeElement.blur();
        this.searchInput?.nativeElement.focus();
      }, 10);
    }
  }

  trackTagById(index: number, tag: IDataItemTag) {
    return tag.id;
  }
}
