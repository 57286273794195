import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ExporterModalOptionsComponent } from './components/exporter-modal-options.component';
import { ExporterModalOptionsCsvComponent } from './components/exporter-modal-options-csv.component';

const routes: Routes = [
  {
    path: 'exporter/options',
    component: ExporterModalOptionsComponent,
    outlet: 'dialog'
  },
  {
    path: 'exporter/options/csv',
    component: ExporterModalOptionsCsvComponent,
    outlet: 'dialog'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppExporterRoutingModule {}
