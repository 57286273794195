<ng-container *ngIf="formGroup; else loadingTemplate">

  <form [formGroup]="formGroup" (change)="updateOptions()">
    <div class="form-check form-switch mt-2">
      <input class="form-check-input" type="checkbox" id="customize-checkbox" formControlName="customizeExport">
      <label class="form-check-label" for="customize-checkbox">Customize Export</label>
    </div>

    <div class="form-check form-switch mt-2" *ngIf="formGroup.controls['customizeExport'].value">
      <input class="form-check-input" type="checkbox" id="default-header-names-checkbox" formControlName="exportHeader">
      <label class="form-check-label" for="default-header-names-checkbox">Export Header</label>
    </div>

    <div class="form-check form-switch mt-2" *ngIf="formGroup.controls['customizeExport'].value && formGroup.controls['exportHeader'].value">
      <input class="form-check-input" type="checkbox" id="default-header-names-checkbox" formControlName="customizeHeader">
      <label class="form-check-label" for="default-header-names-checkbox">Customize Header Names</label>
    </div>

    <ng-container *ngIf="formGroup.controls['customizeExport'].value">
      <hr>

      <div class="row">
        <div class="col-sm-5">
          <span class="form-check form-switch mt-1 mb-2">
            <input class="form-check-input" type="checkbox" id="toggle-all-checkbox"
              [(ngModel)]="exportAllColumnsToggle"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="toggleSelectColumns($event)">

            <label class="form-check-label" for="toggle-all-checkbox">Select All</label>
          </span>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-sm-5"><strong>Field</strong></div>
        <div class="col-sm-5" *ngIf="formGroup.controls['exportHeader'].value && formGroup.controls['customizeHeader'].value"><strong>Header Name</strong></div>
      </div>

      <div class="papers-scrollbar field-mappings-form" formArrayName="csvMapping">
        <div class="row" *ngFor="let formGroupControl of formGroup.controls['csvMapping'].controls; let i = index;" [formGroupName]="i">
          <ng-container *ngIf="formGroup.controls['csvMapping'].controls[i] as subGroup">

            <div class="col-sm-5">
              <span class="form-check form-switch mt-1 mb-2">
                <input class="form-check-input" type="checkbox" id="enabled-checkbox-{{i}}" formControlName="enabled">
                <label class="form-check-label" for="enabled-checkbox-{{i}}" [ngSwitch]="true">
                  <span *ngSwitchCase="subGroup.controls['papersField'].value && !subGroup.controls['customField'].value">{{subGroup.controls['papersField'].value}}</span>
                  <span *ngSwitchCase="!subGroup.controls['papersField'].value && subGroup.controls['customField'].value">{{subGroup.controls['customField'].value}} <i>(Custom Field)</i></span>
                  <span *ngSwitchDefault>{{subGroup.controls['key'].value}}</span>
                </label>
              </span>
            </div>

            <div class="col-sm-5" *ngIf="formGroup.controls['exportHeader'].value && formGroup.controls['customizeHeader'].value">
              <div class="form-group" *ngIf="subGroup.controls['enabled'].value">
                <input class="form-control" type="text" id="mapping-enabled-{{i}}" formControlName="exportHeader">
              </div>
            </div>

          </ng-container>
        </div>
      </div>
    </ng-container>

    <hr>

    <div class="mt-2 row">
      <span class="col-sm-3">
        <label for="export-delimiter-text">Delimiter</label>
        <select id="export-delimiter-text" class="form-select" formControlName="exportDelimiter">
          <option [ngValue]="','">Comma</option>
          <option [ngValue]="';'">Semicolon</option>
          <option [ngValue]="'\t'">Tab</option>
        </select>
      </span>
    </div>
  </form>

</ng-container>



<ng-template #loadingTemplate>
  <div class="text-center mt-2 mb-2">Loading export info...</div>
  <ng-container *ngIf="loadingProgress$ | async as percent; else loadingCirclesTemplate">
    <ngb-progressbar type="primary" height="14px" [animated]="true" [value]="percent">{{percent | number: '1.0-0'}}&percnt;</ngb-progressbar>
  </ng-container>
</ng-template>

<ng-template #loadingCirclesTemplate>
  <common-loader-circles class="loader"></common-loader-circles>
</ng-template>
