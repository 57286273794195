<div class="form-floating">
  <select name="collection-id-select" class="form-select"
    [(ngModel)]="collectionId"
    (ngModelChange)="collectionIdChange.next(collectionId)"
    required>
    <option [ngValue]="c.id" *ngFor="let c of collections$ | async">{{c.name || 'My Papers'}}</option>
  </select>
  <label for="collection-id-select">Into library</label>
</div>


<div class="form-floating mt-3" *ngIf="showLists">
  <select name="parent-name-select" class="form-select"
    [(ngModel)]="listId"
    (ngModelChange)="listIdChange.next(listId)">
    <option [ngValue]="null">(none)</option>
    <option [ngValue]="l.id" *ngFor="let l of lists$ | async">{{l.name}}</option>
  </select>
  <label for="parent-name-select">To list</label>
</div>
