import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';

import { DataStatusService, IDataItemExtIds } from '../../common-data';
import { IDataResult } from '../../app-data-result.model';
import { IDataArticle } from './data-search-api.service';

import { environment } from 'environment';
import * as uuid from 'uuid';

export interface IDataRecommendQueryParams {
  collection_id?: string;
  list_id?: string;
  query?: string;
  from_date?: string;
  sort_by?: string;
  order?: 'asc' | 'desc';
  size?: number;
}

@Injectable()
export class DataRecommendApiService {
  public scrollId?: string;

  constructor(
    private http: HttpClient,
    private statusService: DataStatusService
  ) { }

  public query<T = IDataArticle>(params: IDataRecommendQueryParams, invalidateSession = false): Observable<IDataResult<T>> {
    const endpointUrl = params.collection_id && params.list_id ?
      `${environment.baseUrls.sync}/collections/${params.collection_id}/lists/${params.list_id}/recommendations` :
      `${environment.baseUrls.sync}/items/recommendations`;
    if (invalidateSession) {
      this.scrollId = null;
    }
    const httpOptions = {
      headers: this.getHttpHeaders(this.scrollId),
      params: this.getHttpParams(params, this.scrollId)
    };
    return this.http.get<any>(endpointUrl, httpOptions).pipe(
      catchError(result => throwError(() => result.error)),
      tap(body => this.scrollId = body.scroll_id),
      switchMap(body => {
        const extIds = body.results.map(result => this.getExtIds(result));
        return this.statusService.checkInLibraryStatuses(extIds)
          .then(checkExtIdsData =>  this.createResult<T>(body, checkExtIdsData));
      })
    );
  }

  private createResult<T>(data: any, checkExtIdsData: any[]): IDataResult<T> {
    return {
      refinements: data['refinements'] || {},
      rows: data['results'].map((data: any, index: number) => {
        return this.mapResultData(data, checkExtIdsData[index]);
      }),
      totalHuman: data['total_human'] || 'No results',
      total: data['total'] || 0
    };
  }

  private mapResultData<T>(data: any, itemStatuses: any[]): T {
    return Object.assign({
      id: uuid.v4(),
      ext_ids: this.getExtIds(data),
      authors: data.inventors ? data.inventors.map(i => ({ name: i })) : [],
      highlight: data.highlight || {},
      item_statuses: itemStatuses || [],
      counts: {},
    }, data);
  }

  private getExtIds(data: any): IDataItemExtIds {
    const e = {
      doi: data['doi'],
      pmid: data['pmid'],
      pmcid: data['pmcid'],
      arxiv: data['arxiv'],
      patent_id: data['patent_id']
    };
    return e.doi || e.pmid || e.pmcid || e.arxiv || e.patent_id ? e : {};
  }

  private getHttpHeaders(scrollId?: string) {
    if (environment.debugScrollSession && scrollId) {
      return new HttpHeaders()
        .set('X-Raise-Error', 'scroll_id_expired')
        .set('X-Raise-Error-Status', '404');
    }
    return null;
  }

  private getHttpParams(params: IDataRecommendQueryParams, scrollId?: string): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      httpParams = httpParams.append(key, params[key]);
    });
    if (scrollId) {
      httpParams = httpParams.set('scroll_id', scrollId);
    }
    return httpParams;
  }
}
