<ng-template #citationTemplate let-citation="citation">
  <div class="citation">
    <div class="title">
      <a href (click)="onTitleClick($event, citation.doi)">{{citation.title}}</a>
    </div>
    <div class="authors">{{getAuthorsText(citation)}}</div>
    <div *ngIf="citation.journal">
      <a href (click)="onJournalClick($event, citation.journal)" class="journal">{{citation.journal}}</a>
      <span>&nbsp;<span class="year" *ngIf="citation.year">{{citation.year}}</span></span>
    </div>
  </div>
</ng-template>

<ng-template #mentionTemplate let-mention="mention">
  <div>
    <blockquote>
      <p>...{{mention.mentions[0]?.text ? mention.mentions[0].text : mention.mentions[0]}}...</p>
    </blockquote>
    <span class="authors">&mdash;&nbsp;{{getAuthorsText(mention)}}&nbsp;</span>
    <span class="title"><a href (click)="onTitleClick($event, mention.doi)">{{mention.title}}</a>&nbsp;</span>

    <div class="mention_box">
      <div *ngIf="mention.journal">
        <a href (click)="onJournalClick($event, mention.journal)" class="journal">{{mention.journal}}</a>
        <span>&nbsp;<span class="year" *ngIf="mention.year">{{mention.year}}</span></span>
      </div>
      <!--temporary hidden from ui-->
      <!-- <ng-container *ngIf="mention.mentions[0]?.classification as classification">
        <div
          *ngIf="classification.type"
          class="mention_badge"
          [ngClass]="{'mention_badge--blue': classification.type === 'mentioning',
                      'mention_badge--red': classification.type === 'contradicting',
                      'mention_badge--green': classification.type === 'supporting'}">
            {{classification.type | capitalize}}
          </div>
        <div *ngIf="classification.probability">
          Confidence: <span>{{classification.probability}}</span>&#37;
        </div>
      </ng-container> -->
    </div>

  </div>
</ng-template>


<ng-container *ngIf="!(loading$ | async); else loadingTemplate">

  <div class="row cards">
    <div class="card">
      <div class="card-body">
        <div class="card-title papers-label papers-border-bottom">Citation count</div>
        <h5 class="card-text">{{metrics.citations | number | replace:'.':','}}</h5>
      </div>
    </div>
    <div class="card" *ngIf="altmetric">
      <div class="card-body">
        <div class="card-title papers-label papers-border-bottom">Altmetric score</div>
        <h5 class="card-text">
          <a href (click)="shared.openAltmetricBadgeUrl(altmetric.altmetric_id, altmetric.publisher)" rcpPreventDefault class="text-decoration-none">
            {{altmetric.score | number: '1.0-0'}}
          </a>
        </h5>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="annualData.length > 1 || cumulativeData > 1">
    <div class="col-md-12">

      <ul ngbNav #nav="ngbNav" justify="end" [(activeId)]="chartType" class="nav-tabs">
        <li ngbNavItem="annual">
          <a ngbNavLink>Annual</a>
          <ng-template ngbNavContent>
            <div #chartContainerRef>
              <ngx-charts-bar-vertical
                [view]="chartView"
                [scheme]="chartColorScheme"
                [results]="annualData"
                [animations]="true"
                [legend]="false"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [xAxis]="true"
                [yAxis]="true"
                [xAxisLabel]="Year"
                [yAxisLabel]="Citations"
                [gradient]="true">
                <ng-template #tooltipTemplate let-model="model">
                  {{model.value}} citations in {{model.name}}
                </ng-template>
              </ngx-charts-bar-vertical>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem="cumulative">
          <a ngbNavLink>Cumulative</a>
          <ng-template ngbNavContent>
            <div #chartContainerRef>
              <ngx-charts-area-chart
                [view]="chartView"
                [scheme]="chartColorScheme"
                [results]="cumulativeData"
                [animations]="true"
                [legend]="false"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [xAxis]="true"
                [yAxis]="true"
                [xAxisLabel]="Year"
                [yAxisLabel]="Citations"
                [gradient]="true"
                [timeline]="false">
                <ng-template #seriesTooltipTemplate let-model="model">
                  {{model[0].value}} citations by {{model[0].name}}
                </ng-template>
              </ngx-charts-area-chart>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>

    </div>
  </div>

  <div class="row" *ngIf="citationsTotal > 0">
    <div class="col-md-12 mt-4">

      <ul ngbNav #nav="ngbNav" activeId="citations" class="nav-tabs">
        <li ngbNavItem="citations">
          <a ngbNavLink>Citations</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-md-12">
                <ul class="citation-list">
                  <li *ngFor="let c of citations" class="papers-border-bottom">
                    <ng-template [ngTemplateOutlet]="citationTemplate" [ngTemplateOutletContext]="{ citation: c }"></ng-template>
                  </li>
                </ul>
              </div>
            </div>
            <ng-container *ngIf="!(loadingCitations$ | async); else loadingTemplate">
              <div class="row mt-3" *ngIf="hasMoreCitations">
                <div class="col-md-12 text-center">
                  <button class="btn btn-secondary" (click)="loadMoreCitations()">Load more...</button>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </li>

        <li ngbNavItem="mentions" [disabled]="mentions.length === 0">
          <a ngbNavLink>Mentions</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-md-12">
                <!--temporary hidden from ui-->
                <!-- <div *ngIf="dataMention.total">
                  Found {{dataMention.totalHuman}} article{{dataMention.total > 1 ? 's' : ''}}.
                </div> -->
                <ul class="citation-list">
                  <li *ngFor="let m of mentions" class="papers-border-bottom">
                    <ng-template [ngTemplateOutlet]="mentionTemplate" [ngTemplateOutletContext]="{ mention: m }"></ng-template>
                  </li>
                </ul>
              </div>
            </div>
            <ng-container *ngIf="!(loadingMentions$ | async); else loadingTemplate">
              <div class="row mt-3" *ngIf="hasMoreMentions">
                <div class="col-md-12 text-center">
                  <button class="btn btn-secondary" (click)="loadMoreMentions()">Load more...</button>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>

    </div>
  </div>

</ng-container>

<ng-template #loadingTemplate>
  <br/>
  <br/>
  <common-loader-circles></common-loader-circles>
</ng-template>
