<div class="modal-header">
  <h4 class="modal-title">{{identifierError ? 'Import Errors' : 'Import Warnings &#38; Errors'}}</h4>
  <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body papers-scrollbar" *ngIf="!identifierError">
  <strong>{{name}}</strong> Import Results:
  <br>
  <br>
  <div class="mb-2" *ngIf="error?.errors?.length">
    <div class="mb-2" *ngIf="error.successCount !== undefined">
      <strong>{{error.successCount}}</strong> {{error.successCount === 1 ? 'item' : 'items'}} imported successfully. {{error.successCount > 0 ? 'Some items' : 'Items'}} could not be imported. See error list for details.
    </div>
    <strong class="text-danger">
      Errors:
    </strong>
    <div *ngFor="let item of error.errors">
      Line {{item.line}}, {{item.message}}
    </div>
  </div>
  <div #warnings class="warnings" *ngIf="error?.warnings?.length">
    <strong class="text-warning">
      Warnings:
    </strong>
    <div *ngFor="let item of error.warnings">
      {{item.line}}, {{item.message}}
    </div>
  </div>
</div>

<div class="modal-body papers-scrollbar" *ngIf="identifierError">
  <div [innerHTML]="identifierError.message"></div>
  <br>
  <div class="box">
    <div>Papers was not able to process the following identifiers:</div>
    <div class="button">
      <fa-icon 
        [icon]="['fal', 'copy']"
        ngbTooltip="Copy to Clipboard"
        (click)="copyErrors()">
      </fa-icon>
    </div>
  </div>
  <div class="mb-2">
    <div *ngFor="let id of identifierError.invalidIds">
      {{id}}
    </div>
  </div>
</div>  

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-lg btn-secondary"
    ngbAutofocus
    (click)="close()">
    Close
  </button>
</div>
