
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';
import { ReadCubeDataViewModule } from '@readcube/rcp-data-view';
import { ReadCubeKatexModule } from '@readcube/rcp-katex';

import { LibraryResultsListComponent } from './components/library-results-list.component';
import { LibraryResultsTableComponent } from './components/library-results-table.component';
import { AppCommonModule } from '../common';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
    ReadCubeDataViewModule,
    ReadCubeKatexModule,
    AppCommonModule,
  ],
  declarations: [
    LibraryResultsListComponent,
    LibraryResultsTableComponent,
  ],
  exports: [
    LibraryResultsListComponent,
    LibraryResultsTableComponent,
  ]
})
export class AppLibraryResultsModule { }
