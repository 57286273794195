import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './common-dialog-prompt.component.html'
})
export class CommonDialogPromptComponent {
  title = '';
  message = '';
  value = '';
  cancelButtonText = 'Cancel';
  confirmButtonText = 'OK';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  cancel() {
    this.activeModal.close({ confirmed: false, value: this.value });
  }

  confirm() {
    this.activeModal.close({ confirmed: true, value: this.value });
  }
}
