import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber, ReplaySubject, firstValueFrom, merge, of, interval, throwError, range, from } from 'rxjs';
import { catchError, map, startWith, switchMap, mergeMap, filter, concatMap, scan, takeLast } from 'rxjs/operators';
import { chunkify } from '@readcube/rcp-common';

import { DataStatusService, IDataItemExtIds, IDataItemStatus } from '../../common-data';
import { AppWebSocketService } from '../../app-websocket.service';
import { IDataResult } from '../../app-data-result.model';

import { environment } from 'environment';
import { BUILD_VERSION } from 'build';

export interface IDataItem {
  id: string;
  item_type: string;
  custom_type: string;
  collection_id: string;
  list_ids: string[];
  user_id: string;
  user_name: string;
  user_email: string;
  deleted: boolean;
  drm: boolean;
  ext_ids: IDataItemExtIds;
  primary_file_hash: string;
  primary_file_type: string;
  files: IDataItemFile[];
  article: Partial<IDataItemArticle>;
  custom_metadata: { [key: string]: string; };
  user_data: Partial<IDataItemUser>;
  status_data?: IDataItemStatus; // Joined
  metrics_data?: IDataItemMetrics; // Joined
  import_data?: IDataItemImport;
  access_data?: IDataItemAccess;
  merged?: boolean;
}

export interface IDataArticleReadParams {
  sponsored?: boolean;
}

export interface IDataItemFile {
  access_method: 'personal_library' | string;
  created: string;
  type: 'article' | 'supplement';
  file_type: string;
  name: string;
  pages: number;
  pdf_text_url: string;
  sha256: string;
  size: number;
  // Desktop fields...
  localPath?: string;
  localWaitingResolution?: boolean;
  needsUpload?: boolean;
  needsConfirm?: boolean;
  uploadInProgress?: boolean;
  downloadInProgress?: boolean;
  uploadFailed?: boolean;
}

export interface IDataItemMetrics {
  times_cited: number | null;
  relative_citation_ratio: number | null;
  field_citation_ratio: number | null;
  recent_citations: number | null;
}

export interface IDataItemArticle {
  title: string;
  abstract: string;
  authors: string[];
  journal: string;
  journal_abbrev: string;
  year: string;
  month: string;
  issn: string;
  isbn: string;
  eissn: string;
  eisbn: string;
  issue: string;
  volume: string;
  chapter: string;
  pagination: string;
}

export interface IDataItemUser {
  has_annotations: boolean;
  notes: string | null;
  notes_with_tags: string | null;
  citekey: string;
  color: string;
  rating: number;
  star: boolean;
  tags: string[];
  voted_up_count: number;
  voted_down_count: number;
  view_count: number;
  created: string;
  modified: string;
  last_read: string;
  unread: boolean;
  copyright_status: 'covered' | 'not_covered' | null;
}

export interface IDataItemImport {
  imported_by: string;
  original_id: string;
  original_type: string;
  source: string;
}

export interface IDataItemAccess {
  method: string;
}

export interface IDataItemTag {
  id: string;
  name: string;
}

export interface IDataItemAnnotation {
  id: string;
  type: 'highlight' | 'underline' | 'strikethrough' | 'freehand' | 'note';
  name: string;
  text: string;
  created: string;
  has_note: boolean;
  item_id: string;
  modified: string;
  note: string;
  page_end: number;
  page_start: number;
  preceeding_text: string;
  sha256: string;
  color_id: number;
}

export interface IDataItemParams {
  created_since?: string;
  modified_since?: string;
  recently_read?: boolean;
  star?: boolean;
  tags?: string[];
  sort?: string;
  order?: 'asc' | 'desc';
  size?: number;
}

export interface IDataItemQueryParams extends IDataItemParams {
  collection_id?: string;
  list_id?: string;
  query?: string;
}

export interface IDataItemImportOptions {
  copy_files: "all" | "copyright_covered" | null;
  copy_user_data: boolean;
  merge: boolean;
}

export interface IDataBulkProgressEvent<T> {
  percent: number;
  data: T;
}

export interface IDataItemUploadFileParams {
  file_name: string;
  file_size?: number;
  list_id?: string;
  hash?: string;
  pages?: number;
  file_text?: string;
  source_url?: string;
  resolve?: boolean;
  skip_resolve?: boolean;
  merge?: boolean;
  skip_merge?: boolean;
}

export interface IDataItemUploadFileResponse {
  status: 'ok' | 'error';
  item?: IDataItem;
  upload_id?: string;
  upload_url?: string;
  code?: string | 'file_info_missing' | 'pdf_invalid';
}

export interface IDataItemAddFileParams extends IDataItemUploadFileParams {
  primary: boolean;
}

export interface IDataItemAddFileResponse extends IDataItemUploadFileResponse {
  code?: 'file_info_missing' | 'pdf_invalid' | 'not_found';
}

export interface IDataItemRemoveFileParams {
  hash: string;
}

export interface IDataItemSetPrimaryParams {
  hash: string;
}

export interface IDataItemsPendingUpload {
  status: 'uploading' | 'completed' | 'failed';
  file_name?: string;
  hash?: string;
  error?: string;
}

export interface IDataDownloadFromCloudParams {
  files: { collectionId: string, itemId: string, hash: string; }[];
}

export interface IDataItemDownloadParams {
  hash: string;
  file_name?: string;
}

export interface IDataItemNotification {
  action: 'created' | 'updated' | 'deleted' | 'annotated';
  doi: string;
  id: string;
  type: 'item';
}

const encodeQueryData = (data: { [key: string]: string; }) => {
  const ret = [];
  for (const k in data) {
    ret.push(encodeURIComponent(k) + '=' + encodeURIComponent(data[k]));
  }
  return ret.join('&');
};

@Injectable()
export class DataItemApiService {

  constructor(
    private http: HttpClient,
    private ws: AppWebSocketService,
    private statusService: DataStatusService
  ) { }

  public listen(collectionId: string, itemId?: string): Observable<IDataItemNotification[]> {
    return this.ws.listen(`/collections/${collectionId}/changes`).pipe(
      map(response => response.changes.filter(c => {
        return c.type === 'item' && (c.id === itemId || !itemId);
      })),
      filter(c => Array.isArray(c) && c.length > 0)
    );
  }

  public query(params: IDataItemQueryParams, batchSize: number, scrollId?: string): Observable<IDataResult<IDataItem>> {
    let endpointUrl: string;
    if (params.collection_id) {
      endpointUrl = params.list_id ?
        `${environment.baseUrls.sync}/collections/${params.collection_id}/lists/${params.list_id}/items` :
        `${environment.baseUrls.sync}/collections/${params.collection_id}/items`;
    } else {
      endpointUrl = `${environment.baseUrls.sync}/organizations/search`;
    }
    const groupItems = (acc: any[]) => {
      const items = acc.map(r => r.items).reduce((prev, next) => prev.concat(next), []);
      return Object.assign({}, acc[acc.length - 1], { items });
    };
    const numRequests = params.size ? Math.ceil(params.size / batchSize) : 1;
    return range(0, numRequests).pipe(
      concatMap(() => {
        const httpOptions = {
          headers: this.getHttpHeaders(scrollId),
          params: this.getHttpParams(params, batchSize, scrollId)
        };
        return this.http.get<IDataResult<IDataItem>>(endpointUrl, httpOptions);
      }),
      catchError(result => throwError(() => result.error)),
      scan((acc, curr) => acc.concat(curr), []),
      takeLast(1),
      map(acc => groupItems(acc)),
      map(body => this.createResult(body)),
    );
  }

  public get(collectionId: string, itemId: string): Observable<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}`;
    let getPromise = firstValueFrom(this.http.get<any>(endpointUrl)).then(data => {
      if (!data.item.primary_file_hash && data.item.ext_ids) {
        let statusPromise = this.statusService.getArticleData(data.item.ext_ids, false);
        return statusPromise.then(status => this.mapItemData(data.item));
      }
      return this.mapItemData(data.item);
    });
    return from(getPromise);
  }

  public getItems(collectionId: string, itemIds: string[]): Observable<IDataResult<IDataItem>> {
    let httpParams = new HttpParams();
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items`;
    itemIds.forEach(itemId => httpParams = httpParams.append('ids[]', itemId));
    return this.http.get<any>(endpointUrl, { params: httpParams }).pipe(
      map(body => this.createResult(body))
    );
  }

  public post(collectionId: string, item: Partial<IDataItem>): Promise<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items`;
    return firstValueFrom(this.http.post<any>(endpointUrl, { item }).pipe(
      map(data => this.mapItemData(data.item))
    ));
  }

  public delete(collectionId: string, itemId: string): Promise<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}`;
    return firstValueFrom(this.http.delete<any>(endpointUrl).pipe(
      map(data => this.mapItemData(data.item))
    ));
  }

  public patch(collectionId: string, itemId: string, item: Partial<IDataItem>): Promise<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}`;
    return firstValueFrom(this.http.patch<any>(endpointUrl, { item }).pipe(
      map(data => this.mapItemData(data.item)),
      catchError((response) => {
        if (response?.error?.error === 'inactive_subscription') {
          return this.get(collectionId, itemId);
        }
        return null;
      })
    ));
  }

  public annotations(collectionId: string, itemId: string): Promise<IDataItemAnnotation[]> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}/annotations`;
    return firstValueFrom(this.http.get<IDataItemAnnotation>(endpointUrl).pipe(
      map(body => body['annotations'])
    ));
  }

  public deleteAnnotation(collectionId: string, itemId: string, annotationId: string): Promise<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}/annotations/${annotationId}`;
    return firstValueFrom(this.http.delete<void>(endpointUrl).pipe(
      mergeMap(() => this.get(collectionId, itemId))
    ));
  }

  public patchAnnotation(collectionId: string, itemId: string, annotationId: string, annotation: Partial<IDataItemAnnotation>): Promise<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}/annotations/${annotationId}`;
    return firstValueFrom(this.http.patch<void>(endpointUrl, annotation).pipe(
      mergeMap(() => this.get(collectionId, itemId))
    ));
  }

  public tags(collectionId: string): Observable<IDataItemTag[]> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/tags`;
    return this.http.get<IDataResult<string>>(endpointUrl).pipe(map(body => {
      return (body['tags'] || []).map(tag => ({ id: tag, name: tag }));
    }));
  }

  public renameTag(collectionId: string, tagId: string, name: string): Promise<any> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/rename_tag`;
    return firstValueFrom(this.http.post(endpointUrl, { tag: tagId, rename_to: name }));
  }

  public removeTag(collectionId: string, tagId: string): Promise<any> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/remove_tag`;
    return firstValueFrom(this.http.post(endpointUrl, { tag: tagId }));
  }

  public uploadFile(collectionId: string, params: IDataItemUploadFileParams): Promise<IDataItemUploadFileResponse> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/upload_file`;
    return firstValueFrom(this.http.post<IDataItemUploadFileResponse>(endpointUrl, params));
  }

  public addFile(collectionId: string, itemId: string, params: IDataItemAddFileParams): Promise<IDataItemAddFileResponse> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}/add_file`;
    return firstValueFrom(this.http.post<IDataItemAddFileResponse>(endpointUrl, params));
  }

  public removeFile(collectionId: string, itemId: string, params: IDataItemRemoveFileParams): Promise<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}/remove_supplement`;
    return firstValueFrom(this.http.post<any>(endpointUrl, params).pipe(
      map(data => this.mapItemData(data.item))
    ));
  }

  public setPrimary(collectionId: string, itemId: string, params: IDataItemSetPrimaryParams): Promise<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}/set_primary`;
    return firstValueFrom(this.http.post<any>(endpointUrl, params).pipe(
      map(data => this.mapItemData(data.item))
    ));
  }

  public uploadsPending(collectionId: string): Observable<Map<string, IDataItemsPendingUpload>> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/uploads_pending`;
    const get$ = this.http.get<any>(endpointUrl);
    const poll$ = interval(3000).pipe(mergeMap(() => get$));
    return merge(get$, poll$);
  }

  public downloadFileURL(collectionId: string, itemId: string, params: IDataItemDownloadParams): Promise<string> {
    const queryString = encodeQueryData({
      'hash': params.hash,
      'file_name': params.file_name,
      'client': environment.clientName,
      'client_version': BUILD_VERSION
    });
    const downloadUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}/download?${queryString}`;
    return Promise.resolve(downloadUrl);
  }

  public getViewerURL(collectionId: string, itemId: string): Promise<string> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/reader/personal_data/${itemId}`;
    return firstValueFrom(this.http.get<any>(endpointUrl).pipe(map(res => res['viewer_pdf'])));
  }

  public add(collectionId: string, extIds: IDataItemExtIds, merge: boolean, resolve: boolean, resolveFiles: boolean): Promise<IDataItem> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items`;
    return firstValueFrom(this.http.post<any>(endpointUrl, {
      'ext_ids': extIds,
      'merge': merge,
      'resolve': resolve,
      'resolve_files': resolveFiles,
    }).pipe(
      map(data => this.mapItemData(data.item))
    ));
  }

  public merge(collectionId: string, sourceItemId: string, targetItemId: string): Promise<IDataItem[]> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${sourceItemId}/merge`;
    return firstValueFrom(this.http.post<any>(endpointUrl, { 'into': targetItemId }).pipe(
      map(data => [
        this.mapItemData(data.item),
        this.mapItemData(data.deleted_item)
      ])
    ));
  }

  public updateDetails(collectionId: string, itemId: string, extIds: IDataItemExtIds) {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/items/${itemId}/resolve`;
    return firstValueFrom(this.http.post<any>(endpointUrl, { 'ext_ids': extIds }).pipe(
      map(data => this.mapItemData(data.item))
    ));
  }

  public downloadFromCloud(collectionId: string, itemId: string, hash?: string): Promise<IDataItem> {
    return Promise.reject('not implemnted');
  }

  public bulkPost(
    collectionId: string,
    items: Partial<IDataItem>[],
    listId?: string,
    stop$?: Observable<boolean>,
    includeIds?: boolean):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    const url = `${environment.baseUrls.sync}/collections/${collectionId}/bulk`;
    const jobs = chunkify(items, environment.bulkRequestSize).map(partition => () => {
      const data = partition.map(item => {
        const payload: any = {
          'type': 'item',
          'action': 'create',
          'data': item
        };
        if (includeIds && item.id)
          payload.id = item.id;
        return payload;
      });
      return firstValueFrom(this.http.post<any>(url, { data }).pipe(
        map(body => body.result.filter(r => r.saved && !!r.object).map(r => {
          return this.mapItemData(r.object, r.merged);
        })),
        switchMap(items => {
          if (!listId) {
            return of(items);
          }
          const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/lists/${listId}/add_items`;
          return this.http.post<any>(endpointUrl, { item_ids: items.map(i => i.id) }).pipe(map(() => items));
        })
      ));
    });
    return this.runSequential<IDataItem[]>(jobs, stop$);
  }

  public bulkPatch(
    collectionId: string,
    items: Partial<IDataItem>[],
    action: string,
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    const url = `${environment.baseUrls.sync}/collections/${collectionId}/bulk`;
    const jobs = chunkify(items, environment.bulkRequestSize).map(partition => () => {
      const data = partition.map(item => {
        const itemId = item.id;
        delete item.id;
        const isEmpty = Object.keys(item).length === 0;
        return {
          'type': 'item',
          'action': action,
          'id': itemId,
          'data': !isEmpty ? item : {},
        };
      });
      return firstValueFrom(this.http.post<any>(url, { data }).pipe(
        map(body => body.result.filter(r => r.saved && !!r.object).map(r => {
          return this.mapItemData(r.object);
        }))
      ));
    });
    return this.runSequential<IDataItem[]>(jobs, stop$);
  }

  public bulkDelete(
    collectionId: string,
    itemIds: string[],
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    const url = `${environment.baseUrls.sync}/collections/${collectionId}/bulk`;
    const jobs = chunkify(itemIds, environment.bulkRequestSize).map(partition => () => {
      const data = partition.map(itemId => {
        return {
          'type': 'item',
          'action': 'destroy',
          'id': itemId,
          'data': {}
        };
      });
      return firstValueFrom(this.http.post<any>(url, { data }).pipe(
        map(body => body.result.filter(r => r.saved && !!r.object).map(r => {
          return this.mapItemData(r.object);
        }))
      ));
    });
    return this.runSequential<IDataItem[]>(jobs, stop$);
  }

  public bulkCopy(
    ids: string[],
    collectionId: string,
    listIds: string[],
    options: IDataItemImportOptions,
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    const url = `${environment.baseUrls.sync}/collections/${collectionId}/bulk`;
    const jobs = chunkify(ids, environment.bulkRequestSize).map(partition => () => {
      const data = partition.map(id => {
        return {
          'type': 'item',
          'action': 'create_copy',
          'data': {
            from_collection_id: id.split(':')[0],
            item_id: id.split(':')[1],
            copy_user_data: options.copy_user_data,
            copy_files: options.copy_files,
            resolve: false,
            skip_resolve: true,
            merge: options.merge,
            skip_merge: !options.merge
          }
        };
      });
      return firstValueFrom(this.http.post<any>(url, { data }).pipe(
        map(body => body.result.filter(r => r.saved && !!r.object).map(r => {
          return this.mapItemData(r.object, r.merged);
        })),
        switchMap(items => {
          if (!listIds || !listIds.length) {
            return of(items);
          }
          const listId = listIds[0];
          const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/lists/${listId}/add_items`;
          return this.http.post<any>(endpointUrl, { item_ids: items.map(i => i.id) }).pipe(map(() => items));
        })
      ));
    });
    return this.runSequential<IDataItem[]>(jobs, stop$);
  }

  public bulkResolve(collectionId: string, items: IDataItem[], stop$?: Observable<boolean>) {
    const url = `${environment.baseUrls.sync}/collections/${collectionId}/bulk`;
    const resolved = items.filter(item => !!Object.keys(item).length);
    const jobs = chunkify(resolved, environment.bulkRequestSize).map(partition => () => {
      const data = partition.map(item => {
        return {
          'type': 'item',
          'action': 'resolve',
          'id': item.id,
          'data': { ext_ids: item.ext_ids }
        };
      });
      return firstValueFrom(this.http.post<any>(url, { data }).pipe(
        map(body => body.result.filter(r => r.saved && !!r.object).map(r => {
          return this.mapItemData(r.object);
        }))
      ));
    });
    return this.runSequential<IDataItem[]>(jobs, stop$);
  }

  public bulkClearMetadata(
    collectionId: string,
    items: IDataItem[],
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    const url = `${environment.baseUrls.sync}/collections/${collectionId}/bulk`;
    const jobs = chunkify(items, environment.bulkRequestSize).map(partition => () => {
      const data = partition.map(item => {
        return {
          'type': 'item',
          'action': 'clear_metadata',
          'id': item.id,
          'data': {}
        };
      });
      return firstValueFrom(this.http.post<any>(url, { data }).pipe(
        map(body => body.result.filter(r => r.saved && !!r.object).map(r => {
          return this.mapItemData(r.object);
        }))
      ));
    });
    return this.runSequential<IDataItem[]>(jobs, stop$);
  }

  public bulkLinkFiles(
    collectionId: string,
    items: IDataItem[],
    stop$?: Observable<boolean>):
    Observable<IDataBulkProgressEvent<IDataItem[]>> {
    const url = `${environment.baseUrls.sync}/collections/${collectionId}/bulk`;
    const jobs = chunkify(items, environment.bulkRequestSize).map(partition => () => {
      const data = partition.map(item => {
        return {
          'type': 'item',
          'action': 'link_files',
          'id': item.id,
          'data': { ext_ids: item.ext_ids }
        };
      });
      return firstValueFrom(this.http.post<any>(url, { data }).pipe(
        map(body => body.result.filter(r => r.saved && !!r.object).map(r => {
          return this.mapItemData(r.object);
        }))
      ));
    });
    return this.runSequential<IDataItem[]>(jobs, stop$);
  }

  protected createResult(data: any): IDataResult<IDataItem> {
    return {
      refinements: data['refinements'] || {},
      rows: (data['items'] || []).map(data => this.mapItemData(data)),
      scroll_id: data['scroll_id'],
      totalHuman: data['total_human'] || 'No references',
      total: data['total'] || 0
    };
  }

  protected mapItemData(data: any, merged?: boolean): IDataItem {
    const extIds = data['ext_ids'] || {};
    const userData = data['user_data'] || {};
    const tags = userData['tags'] || [];

    tags.sort((a: string, b: string) => {
      const alc = a.toLocaleLowerCase();
      const blc = b.toLocaleLowerCase();
      if (alc < blc) return -1;
      if (alc > blc) return 1;
      return 0;
    });

    const mapped: IDataItem = Object.assign({}, data, {
      files: data['files'] || [],
      list_ids: data['list_ids'] || [],
      status_data: this.statusService.statusCache.get(extIds.doi),
      user_data: Object.assign({ tags }, userData),
      import_data: data['import_data'] || {},
      article: Object.assign({ authors: [] }, data['article']),
      metrics_data: {
        times_cited: null,
        relative_citation_ratio: null,
        field_citation_ratio: null,
        recent_citations: null
      }
    });

    if (merged)
      mapped.merged = merged;

    return mapped;
  }

  protected runSequential<T>(jobs: (() => Promise<T>)[], stop$?: Observable<boolean>): Observable<IDataBulkProgressEvent<T>> {
    if (!stop$) {
      const s = new ReplaySubject<boolean>(1);
      stop$ = s.pipe(startWith(false));
    }
    return new Observable((subscriber: Subscriber<IDataBulkProgressEvent<T>>) => {
      const reducer = (previous, action, index) => {
        return firstValueFrom(stop$.pipe(mergeMap(stopped => {
          if (stopped) {
            subscriber.complete();
            return Promise.reject(previous);
          }
          return previous.then(result => action().then(r => {
            const percent = Math.floor((index / jobs.length) * 100);
            subscriber.next({ percent: percent, data: result });
            return Array.prototype.concat.call(result, r);
          }));
        })));
      };
      return jobs.reduce(reducer, Promise.resolve([]))
        .then(results => [].concat(...results))
        .then(results => {
          subscriber.next({ percent: 100, data: results });
          subscriber.complete();
        });
    });
  }

  private getHttpHeaders(scrollId?: string) {
    if (environment.debugScrollSession && scrollId) {
      return new HttpHeaders()
        .set('X-Raise-Error', 'scroll_id_expired')
        .set('X-Raise-Error-Status', '404');
    }
    return null;
  }

  protected getHttpParams(params: IDataItemQueryParams, batchSize: number, scrollId?: string): HttpParams {
    let httpParams = new HttpParams();
    if (params.query) {
      httpParams = httpParams.append('query', params.query);
    }
    if (params.created_since) {
      httpParams = httpParams.set('created_since', params.created_since);
    }
    if (params.recently_read) {
      httpParams = httpParams.set('recently_read', 'true');
    }
    if (params.star) {
      httpParams = httpParams.set('star', 'true');
    }
    if (params.tags) {
      params.tags.forEach(tag => httpParams = httpParams.append('tags[]', tag));
    }
    if (scrollId) {
      httpParams = httpParams.set('scroll_id', scrollId);
    }
    if (!!params.sort && !!params.order) {
      httpParams = httpParams.append('sort[]', [params.sort, params.order].join(','));
    } else if (!!params.sort || !!params.order) {
      httpParams = httpParams.append('sort[]', [params.sort, params.order].join(''));
    }
    httpParams = httpParams.append('sort[]', ['created', 'asc'].join(','));
    httpParams = httpParams.set('size', batchSize);
    return httpParams;
  }
}
