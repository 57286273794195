<ng-template #listRowTemplate let-item="item" let-selected="selected">
  <div class="item-row" [ngClass]="{'selected': selected}">
    <div class="head">
      <span class="icon">
        <common-color-picker
          [showEmpty]="false"
          [disabled]="disabled"
          [color]="item.user_data.color"
          (colorChange)="setColorLabel(item, $event)"
          (mousedown)="$event.stopPropagation()">
        </common-color-picker>
      </span>

      <span class="icon">
        <common-toggle-flag
          class="icon"
          [showEmpty]="false"
          [disabled]="disabled"
          [value]="item.user_data.star"
          (valueChange)="toggleFlag(item)"
          (mousedown)="$event.stopPropagation()">
        </common-toggle-flag>
      </span>

      <span class="title" [ngClass]="{'font-weight-bold': item.user_data.unread}">
        <ng-container *ngIf="item.article.title else titleNoneTemplate">
          <rcp-katex-paragraph [paragraph]="item.article.title" [forceInline]="true"></rcp-katex-paragraph>
        </ng-container>
        <ng-template #titleNoneTemplate><i>Untitled</i></ng-template>
      </span>
    </div>

    <div class="info">
      <span class="icon">
        <ng-container *ngIf="!item.primary_file_hash">
          <ng-container [ngSwitch]="getExtensionStatus(item.ext_ids?.doi) | async">
            <fa-icon *ngSwitchCase="'started'" [icon]="['fas', 'sync']" [spin]="true" ngbTooltip="Searching for file..." container="body" ></fa-icon>
            <fa-icon *ngSwitchCase="'downloading'" [icon]="['fas', 'download']" ngbTooltip="Downloading file..." container="body"></fa-icon>
            <span class="pointer" (click)="extension.openDownloadFailedDialog(item.ext_ids?.doi, item)" ngbTooltip="Failed to locate PDF" container="body">
              <fa-icon *ngSwitchCase="'failed'" [icon]="['fas', 'exclamation-triangle']" class="text-danger"></fa-icon>
            </span>
          </ng-container>
        </ng-container>
      </span>

      <ng-container *ngIf="item.article?.authors?.length > 0">
        <span class="authors">{{(item.import_data?.source === 'janssen_endnote') ? ((item.article.authors | formatCommaFirstAuthor)) : (item.article.authors | formatFirstAuthor)}}</span>
        <span *ngIf="item.article.journal || item.article.year">&nbsp;</span>
      </ng-container>
      <span class="journal">{{item.article.journal || ''}} {{item.article.year || ''}}</span>

      <span class="stars">
        <common-stars-rating
          [disabled]="disabled"
          [rating]="item.user_data.rating"
          (ratingChange)="setRating(item, $event)"
          (mousedown)="$event.stopPropagation()">
        </common-stars-rating>
      </span>
    </div>
  </div>
</ng-template>


<data-view-list
  [items]="items"
  [itemSize]="50"
  [multiselect]="multiselect"
  [templateRef]="listRowTemplate"
  (open)="open.next($event)"
  [(selected)]="selected"
  [highlighted]="highlighted"
  [(hasFocus)]="hasFocus"
  (selectedChange)="selectedChange.emit($event)"
  (highlightedChange)="onHighlightItems($event)"
  (hasFocusChange)="hasFocusChange.emit($event)"
  (loadNext)="onLoadNext($event)">

  <common-loader-circles class="loader" [ngClass]="{'visible': loading}"></common-loader-circles>
</data-view-list>
