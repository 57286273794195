<div class="input-group datepicker-group">
  <input type="text"
    ngbDatepicker #d="ngbDatepicker"
    placement="bottom-left"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (dateSelect)="change($event)"
    autocomplete="false">

  <div [ngClass]="{ 'form-floating': !!label }">
    <input type="text"
      [id]="id"
      [(ngModel)]="value"
      (input)="markTouched()"
      [placeholder]="placeholder"
      [disabled]="isDisabled"
      autocomplete="off"
      class="form-control"
      [ngClass]="{'ng-warning': warning && !value}">

    <label [attr.for]="id" *ngIf="label">
      <ng-container *ngIf="value; else labelNoValueTemplate">{{label}}</ng-container>
      <ng-template #labelNoValueTemplate>Enter {{label | lowercase}} here...</ng-template>
    </label>
  </div>

  <button class="btn btn-secondary" (click)="d.toggle()" type="button">
    <fa-icon [icon]="['fal', 'calendar']"></fa-icon>
  </button>
</div>
