import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReaderService, SharedService } from '../../common';
import { IDataItemExtIds } from '../../common-data';
import { DataCollectionService, IDataCollection } from '../../library-data';
import { DataSearchService, DataRecommendService, IDataArticle } from '../../search-data';
import { AppShellService } from '../../app-shell.service';
import { SearchSidepanelService } from '../services/search-sidepanel.service';
import { ExtensionService, ExtensionDownload } from '../../extension';
import { ImporterService } from '../../importer';
import { SearchService } from '../../search';

import { environment } from 'environment';

@Component({
  templateUrl: './search-sidepanel-details.component.html',
  styleUrls: [
    './search-sidepanel.component.scss',
    './search-sidepanel-details.component.scss'
  ]
})
export class SearchSidepanelDetailsComponent implements OnInit, OnDestroy {
  article: IDataArticle;
  articleSub: Subscription;
  importing$ = new ReplaySubject<boolean>();
  collections: IDataCollection[];
  authorsCollapsed = true;
  authorsUsePipe = true;
  authorsLimit = 5;

  canViewPDF: boolean = false;
  canGetPDF: boolean = false;
  canLocatePDF: boolean = false;
  canViewPubmed: boolean = false;

  download: ExtensionDownload;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public search: SearchService,
    public shared: SharedService,
    public reader: ReaderService,
    public dataCollection: DataCollectionService,
    public dataSearch: DataSearchService,
    public dataRecommend: DataRecommendService,
    public sidepanel: SearchSidepanelService,
    public extension: ExtensionService,
    public importer: ImporterService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.dataCollection.query().pipe(
      map(arr => arr.filter(c => c.status === 'active'))
    ).subscribe(collections => {
      this.collections = collections;
    });

    this.articleSub = this.sidepanel.article$.subscribe(article => {
      this.importing$.next(false);

      const status = this.search.findArticleItemStatus(article);
      this.download = this.extension.getDownload(article.ext_ids.doi);
      this.canGetPDF = this.reader.canGetPDF(article);
      this.canViewPDF = this.search.canViewArticlePDF(article);
      this.canLocatePDF = !status?.has_file && !!article.ext_ids.doi;
      this.canViewPubmed = !!article.ext_ids.pmid;
      this.article = article;
    });
  }

  ngOnDestroy() {
    this.articleSub.unsubscribe();
  }

  onAuthorsToggleClick(event: Event) {
    this.authorsCollapsed = !this.authorsCollapsed;
    event.preventDefault();
  }

  onAuthorClick(event: MouseEvent, author: string) {
    this.router.navigate(['search', 'query'], {
      queryParams: {
        query: ['author', '"'+author+'"'].join(':')
      },
      relativeTo: this.route.root
    });
    event.preventDefault();
  }

  onThumbClick(event: MouseEvent) {
    if (this.canViewPDF) {
      this.viewPDF(event);
    } else if (this.canGetPDF) {
      this.getPDF(event);
    } else if (this.canLocatePDF) {
      this.locatePDF(event);
    }
  }

  viewPDF(event: MouseEvent) {
    const article = this.article;
    const status = this.search.findArticleItemStatus(article);
    if (status?.has_file) {
      this.search.viewArticlePDF(status);
    } else if (this.extension.isInstalled()) {
      this.reader.viewPublicPDF(article);
    } else {
      this.extension.openInstallExtensionDialog();
    }
  }

  getPDF(event: MouseEvent) {
    this.reader.getPDF(this.article);
  }

  locatePDF(_: MouseEvent) {
    if (!this.extension.isInstalled()) {
      this.extension.openInstallExtensionDialog();
      return;
    }
    const doi = this.article.ext_ids.doi;
    const id = this.article.id;
    const env = environment.name;
    const collectionId = this.shared.user.id;
    const fileName = this.extension.getFileNameFor(this.article);

    this.download = this.extension.downloadPDF({ doi, env });

    this.download.success$.subscribe(event => {
      const file = this.extension.getDownloadedFile(event, event.fileName || fileName);
      this.importer.addFile(file, collectionId);
      this.importer.start();
    });
    this.download.failed$.subscribe(event => {
      if (event.error == 'failed' || event.error == 'not_found') {
        this.extension.openDownloadFailedDialog(doi);
      }
    });
  }

  reloadArticle(articleId: string) {
    this.dataSearch.reloadItem(articleId);
    this.dataRecommend.reloadItem(articleId);
  }

  addToLibrary(collectionId: string) {
    if (this.shared.isInLibrary(collectionId, this.article)) {
      this.viewInLibrary(collectionId);
    } else {
      this.importItemToLibrary(collectionId);
    }
  }

  async importItemToLibrary(collectionId?: string): Promise<any> {
    this.importing$.next(true);
    await this.shared.addToLibrary([this.article], collectionId);
    this.importing$.next(false);
  }

  viewInLibrary(collectionId: string) {
    const status = this.article.item_statuses.find(s => s.collection_id === collectionId);
    this.shared.viewInLibrary(status.collection_id, status.id);
  }

  canAddToLibrary() {
    return this.article.ext_ids.doi ||
      this.article.ext_ids.pmid ||
      this.article.ext_ids.pmcid ||
      this.article.ext_ids.arxiv ||
      this.article.ext_ids.patent_id;
  }

  openWebByExtId(extId: string, type: keyof IDataItemExtIds) {
    this.shared.openPublisherById(extId, type);
  }

  authorNames(): string[] {
    return this.article.authors.map(a => a.name);
  }

  getImportLabel(collections: IDataCollection[]): string {
    const importedInAll = collections?.every(collection => this.shared.isInLibrary(collection.id, this.article));
    return importedInAll ? 'View in Library' : 'Add to Library';
  }
}
