import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, debounceTime } from 'rxjs';
import { NgForm } from '@angular/forms';

import { AppShellService } from '../../app-shell.service';
import { DialogService, SharedService } from '../../common';
import { DataCollectionService } from '../../library-data';

import { BulkIdentifiersService } from '../services/bulk-identifiers.service';
import { IBulkItemImportOptions } from '../services/bulk.service';

@Component({
  templateUrl: './bulk-modal-identifiers.component.html',
  styleUrls: ['./bulk-modal-identifiers.component.scss']
})
export class BulkModalIdentifiersComponent implements OnInit, OnDestroy {

  @ViewChild('identifiersForm', { static: true }) identifiersForm: NgForm;

  confirmed: boolean;
  importOptions: IBulkItemImportOptions = {
    includeFiles: true,
    copyUserData: true,
    excludeFilesWithoutCopyright: true,
    mergeDuplicates: true
  };
  identifiers = '';
  showCopyOptions: boolean = false;

  identifiersSubscription: Subscription;
  availableCollections: string[] = [];

  constructor(
    public dataCollection: DataCollectionService,
    public importerIdentifiers: BulkIdentifiersService,
    public dialog: DialogService,
    public shared: SharedService,
    public shell: AppShellService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.identifiersSubscription = this.identifiersForm.form.valueChanges.pipe(
      debounceTime(300),
    ).subscribe(state => {
      const identifiers = state['identifiers-input'].split(' ') as string[];
      this.showCopyOptions = !!identifiers.find(id => !!this.importerIdentifiers.validateUrl(id));
    });

    this.dataCollection.query().subscribe(collections => {
      collections.forEach(collection => {
        if (collection.user?.can_copy_item)
          this.availableCollections.push(collection.id);
      });
    });
  }

  ngOnDestroy(): void {
    this.identifiersSubscription?.unsubscribe();
  }

  submit() {
    const ids = this.identifiers.trim().replace(/[\r\n]/g, '').split(' ');
    const collectionId = this.route.snapshot.queryParamMap.get('collectionId');
    const listId = this.route.snapshot.queryParamMap.get('listId');
    this.importerIdentifiers.importFromIdentifiers(ids, collectionId, listId, this.availableCollections, this.importOptions);
    this.dialog.closeDialog();
  }

  cancel() {
    this.dialog.closeDialog();
  }
}
