import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoadNextEvent } from '@readcube/rcp-data-view';
import { CloseContextMenuEvent } from '@readcube/ngx-contextmenu';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, combineLatest, ReplaySubject, asyncScheduler } from 'rxjs';
import { tap, map, mergeMap, mergeWith, switchMap, distinctUntilChanged, startWith, filter, shareReplay, throttleTime } from 'rxjs/operators';

import { SharedService, ModalSubscriptionComponent } from '../../common';
import { DataUserService, IDataUser } from '../../common-data';
import { DataListService, DataCollectionService, IDataList, IDataCollection } from '../../library-data';
import { DataRecommendService, IDataArticle } from '../../search-data';
import { SearchService } from '../services/search.service';
import { SearchSidepanelService } from '../../search-sidepanel';
import { ImporterService } from '../../importer';
import { AppShellService, shouldRemoveHighlight } from '../../app-shell.service';

@Component({
  templateUrl: './search-recommend.component.html',
  styleUrls: ['./search-recommend.component.scss']
})
export class SearchRecommendComponent implements OnInit {
  viewName: string;

  selected: IDataArticle[] = [];
  highlighted: IDataArticle[] = [];

  loading$ = new ReplaySubject<boolean>(1);
  finished$ = new ReplaySubject<boolean>(1);
  itemQuickPush$ = new ReplaySubject<IDataArticle>(1);

  user$: Observable<IDataUser>;
  sidePanelOpen$: Observable<boolean>;
  articles$: Observable<IDataArticle[]>;
  collections$: Observable<IDataCollection[]>;
  lists$: Observable<IDataList[]>;
  sort$: Observable<string>;
  order$: Observable<string>;
  statusText$: Observable<string>;

  @ViewChild('selectionContainer', { static: true })
  selectionContainer: ElementRef;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public search: SearchService,
    public importer: ImporterService,
    public shared: SharedService,
    public modal: NgbModal,
    public dataUser: DataUserService,
    public dataRecommend: DataRecommendService,
    public dataCollection: DataCollectionService,
    public dataList: DataListService,
    public sidepanel: SearchSidepanelService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.viewName = this.search.getViewName();

    this.sidepanel.close(this.route);

    this.user$ = this.dataUser.get().pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.sidePanelOpen$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !!this.route.children.find(child => child.outlet === 'sidepanel')),
      shareReplay({ refCount: true, bufferSize: 1 }),
      startWith(false)
    );

    // Filter streams...
    this.sort$ = this.route.queryParams.pipe(
      map(params => params?.sort),
      distinctUntilChanged(),
      map(value => value || this.search.getSorting()),
      tap(value => this.search.setSorting(value))
    );

    this.order$ = this.route.queryParams.pipe(
      map(params => params?.order),
      distinctUntilChanged(),
      map(value => value || this.search.getOrdering()),
      tap(value => this.search.setOrdering(value))
    );

    this.collections$ = this.dataCollection.query().pipe(
      map(arr => arr.filter(c => c.status === 'active')),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.lists$ = this.route.params.pipe(
      map(params => params?.collection_id),
      distinctUntilChanged(),
      mergeMap(collectionId => {
        return this.dataList.query({ collection_id: collectionId });
      })
    );

    // Content streams...
    this.articles$ = this.getItemsParams$().pipe(
      tap(() => this.loading$.next(true)),
      switchMap(params => this.dataRecommend.query(params)),
      tap(() => this.loading$.next(false)),
      tap(() => this.finished$.next(this.dataRecommend.isDone())),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.sidepanel.article$ = this.route.queryParams.pipe(
      filter(params => 'article_id' in params),
      map(params => params['article_id']),
      distinctUntilChanged((x, y) => x === y),
      tap(() => this.sidepanel.loading$.next(true)),
      mergeMap(params => this.importer.done$.pipe(
        // https://readcube.atlassian.net/browse/ELECTRON-1630
        map(() => params),
        throttleTime(3000, asyncScheduler, { leading: false, trailing: true }),
        startWith(params),
      )),
      switchMap(articleId => this.dataRecommend.get(articleId).pipe(
        tap(() => this.sidepanel.loading$.next(false)),
      )),
      mergeWith(this.itemQuickPush$),
      filter(article => article != null),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.statusText$ = this.articles$.pipe(
      map(() => {
        const s = (n: number) => (n > 1) ? 's' : '';
        const total = this.dataRecommend.total,
          totalHuman = this.dataRecommend.totalHuman;
        if (total === 0) {
          return 'No articles found.';
        }
        return `Found ${totalHuman} article${s(total)}.`;
      })
    );
  }

  onViewNameChange(viewName: string) {
    this.search.setViewName(viewName);
  }

  onLoadNext(event: LoadNextEvent) {
    if (this.dataRecommend.total <= this.dataRecommend.loaded) {
      return;
    }
    this.loading$.next(true);
    this.dataRecommend.queryNext({
      'collection_id': this.route.snapshot.paramMap.get('collection_id'),
      'list_id': this.route.snapshot.queryParamMap.get('list_id')
    }).catch(response => {
      if (response?.error === 'scroll_id_expired') {
        this.shared.openSessionExpired().then(() => {
          const url = this.router.url;
          this.router.navigateByUrl('.', { skipLocationChange: true })
            .then(() => this.router.navigateByUrl(url));
        });
      }
    });
  }

  selectItems(articles: IDataArticle[]) {
    const params = this.route.snapshot.queryParamMap;
    const isFirstSelect = !params.has('articles_ids');
    if (articles.length === 1) {
      if (isFirstSelect) {
        this.sidepanel.setActive('details');
      }
      const panel = this.sidepanel.getActive();
      this.sidepanel.open(this.route, articles, panel).then(opened => {
        if (opened) {
          this.itemQuickPush$.next(articles[0]);
        }
      });
    } else {
      this.sidepanel.close(this.route);
    }

    this.selected = articles.slice(0);
    this.highlighted = [];
  }

  highlightItems(articles: IDataArticle[]) {
    this.highlighted = articles.slice(0);
  }

  onMenuClose(event: CloseContextMenuEvent) {
    if (shouldRemoveHighlight(this.selectionContainer, event))
      this.highlightItems([]);
  }

  openSidePanel(path: string) {
    this.sidepanel.navigate(this.route, path);
  }

  closeSidePanel() {
    this.selected = [];
    this.sidepanel.close(this.route);
  }

  openSubscriptionModal() {
    this.modal.open(ModalSubscriptionComponent);
  }

  protected getItemsParams$() {
    const relevantParams = ['collection_id', 'list_id', 'sort', 'order', 'from_date', 'oa'];
    return combineLatest([this.route.params, this.route.queryParams]).pipe(
      map(result => Object.assign({}, ...result)),
      map(params => {
        const p = {
          'collection_id': this.route.snapshot.paramMap.get('collection_id'),
          'sort_by': params.sort || this.search.getSorting(),
          'order': params.order || this.search.getOrdering()
        };
        if (params.oa || params.oa === false) {
          p['query'] = ['open_access', params.oa].join(':');
        }
        if (params.from_date) {
          p['from_date'] = new Date(params.from_date * 1000).toISOString();
        }
        return Object.assign(params, p);
      }),
      distinctUntilChanged((x, y) => relevantParams.every(p => x[p] === y[p]))
    );
  }
}
