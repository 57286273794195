import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';
import { ReadCubeDragAndDropModule } from '@readcube/rcp-drag-and-drop';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';

import { AppCommonModule } from '../common';
import { AppQueryMenuModule } from '../query-builder';
import { AppUserMenuModule } from '../user-menu';
import { AppSearchMenuModule } from '../search-menu';
import { AppSearchSidepanelModule } from '../search-sidepanel';
import { AppSearchDataModule } from '../search-data';

import { SearchService } from './services/search.service';
import { SearchComponent } from './components/search.component';
import { SearchLandingComponent } from './components/search-landing.component';
import { SearchQueryHeaderComponent } from './components/search-query-header.component';
import { SearchQueryComponent } from './components/search-query.component';

import { AppSearchRoutingModule } from './search-routing.module';
import { AppSearchResultsModule } from '../search-results';
import { SearchRecommendHeaderComponent } from './components/search-recommend-header.component';
import { SearchRecommendComponent } from './components/search-recommend.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
    ReadCubeDragAndDropModule,
    ReadCubeSvgIconsModule,
    AppSearchRoutingModule,
    AppCommonModule,
    AppUserMenuModule,
    AppQueryMenuModule,
    AppSearchDataModule,
    AppSearchMenuModule,
    AppSearchResultsModule,
    AppSearchSidepanelModule,
  ],
  declarations: [
    SearchComponent,
    SearchQueryComponent,
    SearchQueryHeaderComponent,
    SearchRecommendComponent,
    SearchRecommendHeaderComponent,
    SearchLandingComponent
  ],
  providers: [
    SearchService
  ]
})
export class AppSearchModule { }
