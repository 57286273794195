import { Component, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AppShellService } from './app-shell.service';
import { AppStorageService } from './app-storage.service';
import { AppUpdateService, IUpdateInfo } from './app-update.service';
import { DataStylesService } from './common-data';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  latest: IUpdateInfo;

  dialogOpen$: Observable<boolean>;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public shell: AppShellService,
    public update: AppUpdateService,
    public storage: AppStorageService,
    public styles: DataStylesService,
    @Inject(DOCUMENT)
    public document: Document,
  ) {
    this.styles.preloadLastUsedStyle();
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (!!this.latest || document.hidden) return;
    this.update.available().pipe(filter(update => !!update)).subscribe(update => {
      this.shell.showConfirm({
        title: `Update available`,
        message: `There is a new version available. Reload the application to update now.`,
        confirmButtonText: 'Reload',
        cancelButtonText: 'Not now'
      }).then(result => {
        if (result?.confirmed) {
          window.location.reload();
        }
      });
      this.latest = update;
    });
  }

  ngOnInit() {
    this.dialogOpen$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !!this.route.children.find(child => child.outlet === 'dialog'))
    );
  }
}
