import { Component, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { LibraryCollectionComponent } from './library-collection.component';

import { IDataItem } from '../../library-data';

@Component({
  selector: 'library-collection-flagged',
  templateUrl: './library-collection.component.html',
  styleUrls: ['./library-collection.component.scss']
})
export class LibraryCollectionFlaggedComponent extends LibraryCollectionComponent implements OnInit {

  loadFilterPredicate = () => ((item: IDataItem) => item.user_data.star);

  ngOnInit() {
    super.ngOnInit();

    this.viewFilters['star'] = true;

    this.searchPlaceholder$ = this.collection$.pipe(
      map(collection => collection.name ? collection.name.concat('\'s ') : ''),
      map(name => ['Search within ', name.toLowerCase(), 'flagged...'].join('')),
      startWith('')
    );
  }
}
