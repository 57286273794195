<form #bulkExportPDFForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">Export PDFs</h4>
  </div>

  <div class="modal-body">

    <ng-container *ngIf="!submitted, else submittedTemplate">
      <b class="papers-label">File naming convention</b>

      <div class="row mt-4">
        <div class="col-sm-12">
          <rcp-token-field-picker class="form-control w-100" #fileNameModel="ngModel"
            name="file_name_token_field" id="file_name_token_field"
            [required]="true"
            [items]="availableFileNames"
            [(ngModel)]="tokens">
            <ng-template #tokenTemplate let-item="item">{{fileNameTokens[item]}}</ng-template>
          </rcp-token-field-picker>
          <ng-container *ngIf="fileNameModel.invalid">
            <div class="form-text text-danger" *ngIf="fileNameModel.errors.required">Please select at least one token.</div>
          </ng-container>
        </div>
      </div>
  
      <div class="row mt-4">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="card-subtitle mb-2 text-muted">Select file name separator:</div>
              <select name="file_separator_select" class="form-control" style="width: 150px"
                [(ngModel)]="separator">
                <option [value]="separator.value" *ngFor="let separator of fileSeparators">{{separator.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #submittedTemplate>
      PDFs are being exported and a link will be sent to the user when complete.
    </ng-template>

    <br>

  </div>

  <div class="modal-footer">
    <button 
      type="button" 
      class="btn btn-lg" 
      [ngClass]="{'btn-primary': submitted, 'btn-secondary': !submitted}" 
      (click)="close()">
      {{submitted ? 'Close' : 'Cancel'}}
    </button>
    <button 
      *ngIf="!submitted" 
      type="submit" 
      class="btn btn-lg btn-primary" 
      [disabled]="!bulkExportPDFForm.form.valid || (disabled$ | async)">
      Export
    </button>
  </div>

</form>
