<form #identifiersForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">Import from Identifiers</h4>
  </div>

  <div class="modal-body">

    <b class="papers-label">Identifiers</b>

    <div class="form-floating mt-3">
      <textarea 
        name="identifiers-input" 
        class="papers-scrollbar"
        #identifiersInput="ngModel"
        [(ngModel)]="identifiers"
        placeholder="Enter or paste identifiers (DOIs, PMIDs, ArXiv, ReadCube URL)..."
        emptyValidator
        required>
      </textarea>
      <span class="form-text text-muted" *ngIf="identifiersInput.control.errors?.required && identifiersInput.touched">Identifiers are required.</span>
      <span class="form-text text-muted" *ngIf="identifiersInput.control.errors?.empty && identifiersInput.touched">Identifiers cant't be empty.</span>
    </div>

    <br><br>

    <b class="papers-label">Options</b>

    <div class="form-check form-switch mt-3">
      <input
        class="form-check-input"
        type="checkbox"
        id="merge-duplicates-checkbox"
        name="merge-duplicates-checkbox"
        [(ngModel)]="importOptions.mergeDuplicates">
      <label class="form-check-label" for="merge-duplicates-checkbox">Merge Duplicates</label>
    </div>

    <ng-container *ngIf="showCopyOptions">
      <div class="form-check form-switch mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          id="include-files-checkbox"
          name="include-files-checkbox"
          [(ngModel)]="importOptions.includeFiles">
        <label class="form-check-label" for="include-files-checkbox">Include PDFs</label>
      </div>

      <div class="form-check form-switch mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          id="copy-annotations-checkbox"
          name="copy-annotations-checkbox"
          [(ngModel)]="importOptions.copyUserData">
        <label class="form-check-label" for="copy-annotations-checkbox">Merge Annotations</label>
      </div>

      <div class="form-check form-switch mt-2" *ngIf="importOptions.includeFiles && shared.user?.licence?.copyright_show_status">
        <input
          class="form-check-input"
          type="checkbox"
          id="exclude-without-copyright-checkbox"
          name="exclude-without-copyright-checkbox"
          [(ngModel)]="importOptions.excludeFilesWithoutCopyright">
        <label class="form-check-label" for="exclude-without-copyright-checkbox">Exclude PDFs without Copyright</label>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary me-1" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!identifiersForm.form.valid">Import</button>
  </div>

</form>
