import { Component, OnInit, ElementRef, OnDestroy, ViewChild, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, from, asyncScheduler, NEVER } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import * as ViewerPreviewWeb from '@readcube/viewer-preview-web';

import { DataItemService, IDataItem } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { PreviewService, ISelectionMessage } from '../services/preview.service';

import { environment } from 'environment';

@Component({
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewComponent implements OnInit, OnDestroy {
  viewer: any;
  paramsSub: Subscription;
  error$ = new Subject<string>();
  loading$ = new BehaviorSubject<boolean>(true);
  available$ = new BehaviorSubject<boolean>(true);

  @ViewChild('container', { static: true })
  containerRef: ElementRef<HTMLDivElement>;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected zone: NgZone,
    protected dataItem: DataItemService,
    protected preview: PreviewService,
    protected shell: AppShellService
  ) { }

  ngOnInit() {
    const updateFilter = (x: Params, y: Params) => {
      return x['item_id'] === y['item_id'] && x['types'] === y['types'];
    };

    this.paramsSub = combineLatest([this.route.params, this.route.queryParams]).pipe(
      map(result => Object.assign({}, ...result)),
      distinctUntilChanged(updateFilter),
      tap(() => this.loading$.next(true)),
      tap(() => this.available$.next(true)),
      throttleTime(1000, asyncScheduler, { leading: true, trailing: true }),
      switchMap(({ collection_id, item_id }) => {
        return this.dataItem.get(collection_id, item_id).pipe(
          distinctUntilChanged((a: IDataItem, b: IDataItem) => a.primary_file_hash === b.primary_file_hash)
        );
      }),
      tap(() => this.loading$.next(false)),
      switchMap(item => {
        const canShow = this.preview.canShow(item);
        this.available$.next(canShow);
        if (canShow) {
          return this.changeItem(item);
        }
        return NEVER;
      }),
      catchError(response => {
        this.loading$.next(false);
        this.error$.next(
          (!response.error?.error || response.error?.error === 'not_found')
            ? 'Preview is currently not available.'
            : response.error.error);
        return response;
      }),
      mergeMap(() => this.route.parent.queryParams.pipe(
        map(params => params?.query),
        distinctUntilChanged(),
        debounceTime(100),
        tap(query => this.viewer.find(query ?? ''))
      ))
    ).subscribe();
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    if (this.viewer) {
      this.viewer.destroy();
    }
  }

  protected changeItem(item: IDataItem): Observable<IDataItem> {
    const types = this.route.snapshot.paramMap.get('types')?.split(',');
    return from(this.initViewer()).pipe(
      switchMap((viewer) => this.renderPreview(viewer, types, item)),
    );
  }

  protected renderPreview(viewer: any, types: string[], item: IDataItem): Promise<any> {
    return viewer.render({ collectionId: item.collection_id, itemId: item.id, types })
      .then(viewer => [item, viewer]);
  }

  protected showNotAvailable() {
    this.router.navigate([{
      outlets: {
        'bottompanel': ['preview-not-available', {
          'collection_id': this.route.snapshot.paramMap.get('collection_id'),
          'item_id': this.route.snapshot.paramMap.get('item_id')
        }]
      }
    }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  protected initViewer(): Promise<any> {
    if (this.viewer) return Promise.resolve(this.viewer);

    const container = this.containerRef.nativeElement;
    const origin = environment.baseUrls.readcube;
    const events: any = {};

    events.mark = (e, type, text) => {
      if (type === 'Copy') {
        this.shell.copyToClipboard({ text });
      }
      const data = <ISelectionMessage>{ type, text };
      this.preview.selection$.next({ type: 'marked', data });
    };
    events.title = (e, title) => {
      this.preview.selection$.next({ type: 'title-found', data: title });
    };
    events.unavailable = () => {
      this.available$.next(false);
    };

    this.zone.runOutsideAngular(() => {
      this.viewer = ViewerPreviewWeb({ container, origin, events });
    });

    return Promise.resolve(this.viewer);
  }
}
