import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'common-toggle-flag',
  templateUrl: './common-toggle-flag.component.html',
  styleUrls: ['./common-toggle-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonToggleFlagComponent {
  @Input()
  value: boolean;

  @Input()
  showEmpty = true;

  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Output()
  valueChange = new EventEmitter<boolean>();

  toggleValue(event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
