import { Component, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { of as observableOf, combineLatest, ReplaySubject } from 'rxjs';
import { first, tap, switchMap } from 'rxjs/operators';

import { SharedService } from '../../common';
import { DataMetricsService, DataCitationService, DataMentionService, IDataCitation, IDataMention } from '../../metrics-data';
import { DataSearchService } from '../../search-data';

@Component({
  selector: 'metrics-article',
  templateUrl: './metrics-article.component.html',
  styleUrls: ['./metrics-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricsArticleComponent implements OnChanges {
  @Input()
  doi: string;

  @Input()
  chartType = 'cumulative';

  @ViewChild('chartContainerRef', { static: false })
  chartContainerRef: ElementRef;

  chartView = [0, 0];

  chartColorScheme = {
    domain: ['#1e8bff']
  };

  metrics: {
    citations: number
  };
  annualData = [];
  cumulativeData = [];

  loadBatchSize = 50;

  citations: IDataCitation[] = [];
  citationsTotal = 0;
  citationsLoaded = 0;
  hasMoreCitations = true;

  mentions: IDataMention[] = [];
  mentionsTotal = 0;
  mentionsLoaded = 0;
  hasMoreMentions = true;
  resizeObserver: ResizeObserver;

  altmetric: any;

  loading$ = new ReplaySubject<boolean>();
  loadingCitations$ = new ReplaySubject<boolean>();
  loadingMentions$ = new ReplaySubject<boolean>();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public elementRef: ElementRef,
    public changeDetectionRef: ChangeDetectorRef,
    public shared: SharedService,
    public dataSearch: DataSearchService,
    public dataMetrics: DataMetricsService,
    public dataCitation: DataCitationService,
    public dataMention: DataMentionService
  ) { }

  ngOnInit() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.chartView = [this.chartContainerRef?.nativeElement.clientWidth, 200];
      this.changeDetectionRef.detectChanges();
    });
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.elementRef.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.doi) {
      return;
    }
    const doi = changes.doi.currentValue;
    observableOf(doi).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(doi => combineLatest([
        this.dataMetrics.query({
          'doi': doi
        }),
        this.dataCitation.query({
          'doi': doi,
          'refinements': ['year'],
          'size': this.loadBatchSize
        }),
        this.dataMention.query({
          'doi': doi,
          'size': this.loadBatchSize
        }),
        this.dataSearch.getAltmetricData(doi),
      ])),
      first()
    ).subscribe(results => {
      const [ metrics, citations, mentions, altmetric ] = results;
      const refinements = this.dataCitation.refinements;
      // Map charts data
      this.metrics = metrics;
      this.annualData = (refinements.year||[])
        .sort((x, y) => x.key - y.key)
        .map(r => {
          return { name: r.key, value: r.doc_count };
        });
      this.cumulativeData = [
        {
          name: 'Citations',
          series: (refinements.year||[])
            .sort((x, y) => x.key - y.key)
            .map((d, i, arr) => {
              d.doc_count = i ? arr[i-1].doc_count + d.doc_count : d.doc_count;
              return d;
            })
            .map(r => {
            return { name: r.key, value: r.doc_count };
          })
        }
      ];
      // Citations
      this.citations = citations;
      this.citationsTotal = this.dataCitation.total;
      this.citationsLoaded = this.dataCitation.loaded;
      this.hasMoreCitations = citations.length >= this.loadBatchSize &&
        this.dataCitation.total > this.dataCitation.loaded;
      // Mentions
      this.mentions = mentions;
      this.mentionsTotal = this.dataMention.total;
      this.mentionsLoaded = this.dataMention.loaded;
      this.hasMoreMentions = mentions.length >= this.loadBatchSize &&
        this.dataMention.total > this.dataMention.loaded;

      // Altmetric
      this.altmetric = altmetric;

      this.loading$.next(false);
    });
  }

  onChartContainerResize(element: Element) {
    this.chartView = [element.clientWidth, 200];
  }

  onTitleClick(event: MouseEvent, doi: string) {
    this.router.navigate(['search', 'query'], {
      queryParams: { query: ['doi', doi].join(':') },
      relativeTo: this.route.root
    });
    event.preventDefault();
  }

  onJournalClick(event: MouseEvent, journal: string) {
    this.router.navigate(['search', 'query'], {
      queryParams: { query: ['journal', '"'+journal+'"'].join(':') },
      relativeTo: this.route.root
    });
    event.preventDefault();
  }

  loadMoreCitations() {
    this.loadingCitations$.next(true);
    this.dataCitation.query({
      'doi': this.doi,
      'size': 50
    }).pipe(first()).subscribe(citations => {
      this.citations = this.citations.concat(citations);
      this.citationsLoaded += citations.length;
      this.hasMoreCitations = !!citations.length && this.dataCitation.total > this.citationsLoaded;
      this.loadingCitations$.next(false);
    });
  }

  loadMoreMentions() {
    this.loadingMentions$.next(true);
    this.dataMention.query({
      'doi': this.doi,
      'size': 50
    }).pipe(first()).subscribe(mentions => {
      this.mentions = this.mentions.concat(mentions);
      this.mentionsLoaded += mentions.length;
      this.hasMoreMentions = !!mentions.length && this.dataMention.total > this.mentionsLoaded;
      this.loadingMentions$.next(false);
    });
  }

  getAuthorsText(citation: IDataCitation): string {
    const authors = citation.authors || [];
    if (authors.length === 1) {
      return authors[0].name;
    } else if (authors.length > 1) {
      return authors[0].name + ' et al.';
    }
    return '';
  }
}
