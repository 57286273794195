<context-menu #selectedContextMenu (close)="onMenuClose.emit($event)">
  <ng-template contextMenuItem [visible]="canViewPDF" (execute)="viewPDF()">View PDF</ng-template>
  <ng-template contextMenuItem *ngIf="canViewPDF" divider="true" ></ng-template>
  <ng-template contextMenuItem [visible]="canCopy" [subMenu]="copySubMenu">Copy...</ng-template>
  <ng-template contextMenuItem divider="true" ></ng-template>
  <ng-template contextMenuItem [visible]="canViewInLibrary" [subMenu]="viewSubMenu">View in Library</ng-template>
  <ng-template contextMenuItem [visible]="canAddToLibrary" [subMenu]="addSubMenu">Add to Library</ng-template>
  <ng-template contextMenuItem [visible]="canExport" [subMenu]="exportSubMenu">Export to...</ng-template>
  <ng-template contextMenuItem divider="true" *ngIf="canOpenCopyright || canOrderReprint"></ng-template>
  <ng-template contextMenuItem (execute)="search.openCopyright($event.item)" [visible]="canOpenCopyright">Open Copyright Status</ng-template>
  <ng-template contextMenuItem (execute)="search.openOrderReprint($event.item)" [visible]="canOrderReprint">Order Reprints</ng-template>
</context-menu>

<context-menu #copySubMenu>
  <ng-template contextMenuItem (execute)="search.copyAuthorsToClipbooard($event.item)">Authors</ng-template>
  <ng-template contextMenuItem (execute)="search.copyArticleBibTeXToClipboard($event.item)">BibTeX</ng-template>
  <ng-template contextMenuItem (execute)="search.copyArticleSummaryToClipboard($event.item)">Formatted Summary</ng-template>
  <ng-template contextMenuItem (execute)="search.copyArticleReferenceToClipboard($event.item)">Formatted Reference</ng-template>
  <ng-container *ngIf="articles.length === 1">
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].title })" [visible]="articles[0].title">Title</ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].abstract })" [visible]="articles[0].abstract">Abstract</ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].journal })" [visible]="articles[0].journal">Journal</ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].patent_id })" [visible]="articles[0].patent_id">Patent ID</ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].doi })" [visible]="articles[0].doi">DOI</ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].pmid })" [visible]="articles[0].pmid">PMID</ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].pmcid })" [visible]="articles[0].pmcid">PMCID</ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].arxiv })" [visible]="articles[0].arxiv">arXiv Identifier</ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].isbn })" [visible]="articles[0].isbn">ISBN</ng-template>
    <ng-template contextMenuItem (execute)="shell.copyToClipboard({ text: $event.item[0].issn })" [visible]="articles[0].issn">ISSN</ng-template>
  </ng-container>
</context-menu>

<context-menu #viewSubMenu>
  <ng-container *ngIf="articles[0]">
    <ng-template
      *ngFor="let status of articles[0].item_statuses"
      contextMenuItem
      (execute)="shared.viewInLibrary(status.collection_id, status.id)"
      [visible]="articles[0].title">
      {{status.collection_name || 'My Papers'}}
    </ng-template>
  </ng-container>
</context-menu>

<context-menu #addSubMenu>
    <ng-template
      *ngFor="let collection of collections"
      contextMenuItem
      (execute)="addToLibrary(collection.id)">
      <div class="papers-context-checkbox-item" [ngClass]="{'checked': shared.isInLibrary(collection.id, articles[0])}"> {{collection.name || 'My Papers'}}</div>
    </ng-template>
</context-menu>

<context-menu #exportSubMenu>
  <ng-template contextMenuItem (execute)="exportBIB($event.item)">BibTeX (.bib)</ng-template>
  <ng-template contextMenuItem (execute)="exportRIS($event.item)">RefMan (.ris)</ng-template>
  <ng-template contextMenuItem (execute)="exportJSON($event.item)">JavaScript Object Notation (.json)</ng-template>
</context-menu>
