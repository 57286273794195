import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { ContextMenuComponent, ContextMenuService } from '@readcube/ngx-contextmenu';
import { DragAndDropService } from '@readcube/rcp-drag-and-drop';

import { SharedService } from '../../common';
import { IDataCollection, IDataSmartList } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { LibraryNavigationService } from '../services/library-navigation.service';

@Component({
  selector: 'library-navigation-smartlist',
  templateUrl: './library-navigation-smartlist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryNavigationSmartListComponent implements OnInit {
  @Input()
  indent = 0;

  @Input()
  collection: IDataCollection;

  @Input()
  list: IDataSmartList;

  @ViewChild('contextMenuComponent', { static: true })
  contextMenuComponent: ContextMenuComponent;

  disabled$: Observable<boolean>;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modal: NgbModal,
    public contextMenu: ContextMenuService,
    public navigation: LibraryNavigationService,
    public dragAndDrop: DragAndDropService,
    public shared: SharedService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.disabled$ = this.dragAndDrop.event$.pipe(
      map(event => event.name === 'start'),
      shareReplay({ refCount: true, bufferSize: 1 }),
      startWith(false)
    );
  }

  openContextMenu(event: MouseEvent) {
    this.contextMenu.show.next({
      contextMenu: this.contextMenuComponent,
      anchorElement: event.currentTarget,
      event: event,
      item: null
    });
    event.stopPropagation();
    event.preventDefault();
  }

  edit() {
    const list = Object.assign({}, this.list);
    this.navigation.openEditSmartList(this.collection, list);
  }

  delete() {
    this.navigation.openDeleteSmartList(this.list)
      .then(deleted => deleted ? this.goAwayTo(this.collection.id) : null);
  }

  share() {
    this.navigation.share('smartlist', this.list);
  }

  canShareList(): boolean {
    return this.shared.user &&
      !this.shared.user.licence.disable_public_sharing &&
      this.shared.user.licence.active &&
      this.collection.user?.can_publish_list;
  }

  canManageSmartlist(): boolean {
    return this.shared.user?.licence.active &&
      this.collection.user?.can_manage_smartlist;
  }

  private goAwayTo(collectionId: string) {
    this.router.navigate(['/library', collectionId, 'all'], {
      relativeTo: this.route,
      replaceUrl: true
    });
  }
}
