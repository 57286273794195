import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppShellService } from '../../app-shell.service';
import { IdentifierError, ParserError } from '../../importer/services/importer.service';

@Component({
  templateUrl: './modal-import-error.component.html',
  styleUrls: ['./modal-import-error.component.scss']
})
export class ModalImportErrorComponent implements AfterViewInit {
  @Input()
  error: ParserError;

  @Input()
  name: string;

  @Input()
  scrollToWarnings: boolean = false;

  @Input()
  identifierError: IdentifierError = null;

  @ViewChild('warnings', { static: false })
  warningsElement: ElementRef<any>;

  constructor(
    public activeModal: NgbActiveModal,
    public shell: AppShellService
  ) { }

  ngAfterViewInit() {
    if (this.scrollToWarnings) {
      this.warningsElement.nativeElement.scrollIntoView();
    }
  }

  close() {
    this.activeModal.close();
  }

  copyErrors() {
    this.shell.copyToClipboard({
      text: this.identifierError.invalidIds.join('\n')
    });
  }
}
