import { Observable, Subscription, of, NEVER, ReplaySubject, Subject } from 'rxjs';
import { startWith, scan, takeWhile, map, catchError, finalize } from 'rxjs/operators';
import { getFileHash, XHRFileUploader } from '@readcube/rcp-common';
import { IDataItemUploadFileResponse, IDataItemAddFileResponse, IDataItemsPendingUpload } from '../../library-data';
import { IImportJob, IImportJobSnapshot, ImportAPI, STATUS_FLAG } from './importer.service';
import * as uuid from 'uuid';

async function checkMicrosoftAIP(file: File) {
  const arrBuffer = await file.arrayBuffer();

  return new Promise<void>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const text = new TextDecoder('ascii').decode(arrBuffer);

      if (text.substring(0, 16384).indexOf('MicrosoftIRMServices Protected PDF') !== -1) {
        reject(new Error('Encrypted PDF (Microsoft AIP)'));
      } else {
        resolve();
      }
    };
    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };
    reader.readAsArrayBuffer(file);
  });
}

export class ImportFileJob implements IImportJob {
  public id: string;
  public name: string;
  public detail: string;
  public inProgress$ = new ReplaySubject<boolean>(1);
  public completed$ = new Subject<boolean>();
  public status$: Observable<STATUS_FLAG>;
  public progress$: Observable<number>;
  public errors$: Observable<string[]>;
  public parserError$ = NEVER;
  public snapshot: IImportJobSnapshot;

  protected statusSource = new ReplaySubject<STATUS_FLAG>(1);
  protected progressSource = new ReplaySubject<number>(1);
  protected errorsSource = new ReplaySubject<string[]>(1);
  protected uploader = new XHRFileUploader();
  protected sub: Subscription;

  constructor(
    public file: File,
    public collectionId: string,
    public itemId: string = null,
    public listId: string = null,
    protected api: ImportAPI,
  ) {
    this.id = uuid.v4();
    this.name = file.name;
    this.snapshot = { errors: [], progress: 0, status: 'queued' };
    this.status$ = this.statusSource.pipe(
      startWith(<STATUS_FLAG>'queued')
    );
    this.progress$ = this.progressSource.asObservable();
    this.errors$ = this.errorsSource.pipe(
      scan((acc, val) => acc.concat(val))
    );
  }

  run(): Promise<void> {
    if (this.file.size === 0) {
      this.setProgress(100);
      this.setError('File is empty or could not be accessed.');
      this.setStatus('failed');
      return Promise.resolve();
    }
    return checkMicrosoftAIP(this.file)
      .catch(err => {
        this.setError(err.message || 'File upload failed.');
        this.setStatus('failed');
        throw err;
      })
      .then(() => getFileHash(this.file, 'SHA-256'))
      .then(hash => this.createUpload(hash))
      .catch(err => {
        if (err.error?.error === 'pdf_invalid') {
          this.setError('File appears to be damaged.');
        } else if (err.error?.error === 'inactive_subscription') {
          this.setError('Your subscription is inactive.');
        } else {
          this.setError(`Failed to upload file. (${err.error?.error ? err.error.error : ''})`);
        }
        this.setStatus('failed');
        throw err;
      })
      .then(response => {
        this.setStatus('running');
        this.setProgress(0);
        if (response.item) {
          this.setProgress(100);
          this.setStatus('completed');
        } else if (response.upload_id) {
          let mimeType = this.file.type;
          if (this.file.type != 'application/pdf') {
            mimeType = 'application/octet-stream';
          }
          this.sub = this.uploader.upload(this.file, response.upload_url, mimeType).pipe(
            map(event => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              this.setProgress(percent);
            }),
            catchError(err => {
              this.setError(err.message || 'File upload failed.');
              this.setStatus('failed');
              return of(err);
            }),
            finalize(() => {
              if (this.snapshot.status != 'failed') {
                this.waitPending(response.upload_id).subscribe();
              }
            })
          ).subscribe();
        }
      })
      .catch(() => null);
  }

  stop() {
    this.uploader.abort();
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (['running', 'pending', 'failed'].includes(this.snapshot.status)) {
      this.stopUpload();
    }
    this.setStatus('queued');
  }

  protected setStatus(status: STATUS_FLAG) {
    const finished = ['completed', 'failed'].includes(status);
    this.snapshot.status = status;
    this.statusSource.next(status);
    this.inProgress$.next(!finished);
    if (status == 'completed') {
      this.completed$.next(true);
    }
    if (finished) {
      this.statusSource.complete();
      this.progressSource.complete();
      this.errorsSource.complete();
      this.inProgress$.complete();
      this.completed$.complete();

      // Clear reference to file binary so GC can do it's thing.
      this.file = null;
    }
  }

  protected setProgress(progress: number) {
    this.progressSource.next(progress);
    this.snapshot.progress = progress;
  }

  protected setError(err: string) {
    this.errorsSource.next([err]);
    this.snapshot.errors.push(err);
  }

  protected createUpload(hash: string): Promise<IDataItemUploadFileResponse | IDataItemAddFileResponse> {
    if (this.itemId) {
      return this.api.dataItem.addFile(this.collectionId, this.itemId, {
        file_name: this.file.name,
        file_size: this.file.size,
        primary: false,
        hash: hash,
        resolve: false,
        skip_resolve: true,
        merge: false,
        skip_merge: true
      });
    }
    return this.api.dataItem.uploadFile(this.collectionId, {
      file_name: this.file.name,
      file_size: this.file.size,
      list_id: this.listId,
      hash: hash,
      resolve: true,
      skip_resolve: false,
      merge: true,
      skip_merge: false
    });
  }

  protected stopUpload(): Promise<void> {
    // Nothing to do on server for now
    return Promise.resolve();
  }

  protected waitPending(uploadId: string): Observable<IDataItemsPendingUpload> {
    return this.api.getPending(this.collectionId).pipe(
      map(pending => pending[uploadId]),
      takeWhile(pending => {
        if (!pending) {
          return false;
        }
        switch (pending.status) {
          case 'completed':
            // Commit uploaded file to services
            this.createUpload(pending.hash).then(() => {
              this.setStatus('completed');
            }).catch(() => {
              this.setError('Failed to add uploaded file.');
              this.setStatus('failed');
            });
            return false;
          case 'failed':
            this.setError(pending.error);
            this.setStatus('failed');
            return false;
          case 'uploading':
            this.setStatus('pending');
            return true;
        }
        return false;
      }),
      catchError(err => {
        this.setError(err);
        this.setStatus('failed');
        return of(err);
      }),
    );
  }
}
