import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, of as observableOf } from 'rxjs';
import { mergeMap, distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { strtrunc } from '@readcube/rcp-common';
import { TokenFieldComponent } from '@readcube/rcp-token-field';

import { DataItemService, IDataItem } from '../../library-data';

@Component({
  templateUrl: './modal-edit-tags.component.html'
})
export class ModalEditTagsComponent implements OnInit, AfterViewInit {
  @Input()
  item: IDataItem;

  search$: (text$: Observable<string>) => Observable<string[]>;
  disabled$= new ReplaySubject<boolean>(1);
  error$ = new ReplaySubject<string>(1);

  loading = false;
  saving = false;
  text = '';
  form: FormGroup;

  @ViewChild('tagsInput', { static: true })
  tagsInput: TokenFieldComponent;

  constructor(
    public activeModal: NgbActiveModal,
    public dataItem: DataItemService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      'tags': new FormControl({
        value: this.item.user_data.tags.slice(0) || [],
        disabled: false
      }, Validators.nullValidator)
    });

    this.search$ = (text$: Observable<string>) => text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      mergeMap(term => {
        if (!term.length) {
          return observableOf([]);
        }
        term = term.toLowerCase();
        const enteredTags = this.form.controls['tags'].value;
        return this.dataItem.tags(this.item.collection_id).pipe(
          map(tags => tags.filter(tag => enteredTags.indexOf(tag.id) === -1)),
          map(tags => tags.filter(tag => {
            if (term.length === 1) {
              return tag.id.toLowerCase().startsWith(term);
            }
            return tag.id.toLowerCase().includes(term);
          })),
          map(tags => tags.slice(0, 5)),
          map(tags => tags.sort((a: any, b: any) => {
            a = a.id.toLowerCase();
            b = b.id.toLowerCase();
            if (a < b) {
              return -1;
            } else if (a > b) {
              return 1;
            } else {
              return 0;
            }
          })),
          map(tags => tags.map(tag => strtrunc(tag.id, 50, false)))
        );
      }));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.tagsInput.focus();
    });
  }

  removeAllTags() {
    const tagsCtrl = this.form.controls['tags'];
    tagsCtrl.setValue([]);
    tagsCtrl.markAsDirty();
  }

  submit() {
    if (this.loading) {
      return;
    }
    const tags = this.form.controls['tags'].value || [];
    this.saving = true;
    this.error$.next('');
    this.disabled$.next(true);
    this.dataItem.update(this.item.collection_id, this.item.id, {
      user_data: { tags }
    }).then(item => {
      this.dataItem.reloadTags({ collectionId: item.collection_id });
    }).then(() => {
      this.activeModal.close();
    }).catch(response => {
      this.error$.next(response?.error?.error);
    }).finally(() => {
      this.saving = false;
      this.disabled$.next(false);
    });
  }

  close() {
    this.activeModal.close();
  }
}
