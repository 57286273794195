import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { NEVER, Observable, throwError, timer } from 'rxjs';
import { catchError, switchMap, tap, retry } from 'rxjs/operators';

import { AppSessionService } from './app-session.service';
import { AppShellService } from './app-shell.service';

import { environment } from 'environment';
import { BUILD_VERSION } from 'build';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    protected shell: AppShellService,
    protected session: AppSessionService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url;
    const clientId = this.session.clientId || '';
    if (url.includes('sync.readcube.com') ||
      url.includes('services.readcube.com') ||
      url.includes('content-services.readcube.com') ||
      url.includes('sync-staging.readcube.com') ||
      url.includes('services-staging.readcube.com') ||
      url.includes('content-services-staging.readcube.com') ||
      url.includes('api.readcube.com')) {
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          //'X-Readcube-Client': environment.clientName,
          //'X-Readcube-Client-Id': clientId
        },
        setParams: {
          'client': environment.clientName,
          'client_id': clientId,
          'client_version': BUILD_VERSION
        },
        withCredentials: true
      });
    }
    return next.handle(request).pipe(catchError((response: HttpErrorResponse) => {
      switch (response.status) {
        case 400:
        case 500:
          switch (response.error.code) {
            case 'invalid_query':
              this.shell.showError({
                title: 'Query Error',
                message: 'Search query could not be parsed. Please check your syntax.'
              });
              break;
            case 'scroll_id_expired':
              window.location.reload();
              break;
            default:
              !request.url.includes(environment.baseUrls.contentServices) && this.shell.showError({
                title: 'Error',
                message: response.error?.message || 'An error has ocurred. Please try again later.'
              });
          }
          return throwError(() => response);
        case 401: // Unauthorized
          window.location.href = `${environment.baseUrls.readcube}/authentication?` +
            `client=${environment.clientName}&redirect=${encodeURIComponent(window.location.href)}`;
          return throwError(() => response);
        case 403: // Forbidden
          if (response.error?.error === 'inactive_subscription') {
            this.shell.showSubscription();
          }
          if (response.error?.error === 'collection_not_accessible') {
            window.location.href = environment.baseUrls.webapp;
          }
          return throwError(() => response);
        case 404: // Not Found
          return throwError(() => response);
        case 429: // Too Many Requests
          return this.getRateLimitResetTimer(response).pipe(
            tap(() => console.log('Retrying request...')),
            switchMap(() => next.handle(request.clone())),
            retry({
              delay: () => this.getRateLimitResetTimer(response),
              count: environment.maxRequestRetryAttempts,
            })
          );
        case 503:
          const loadingTextElement = document.getElementById('loading_text');
          const maintenanceText = `Papers is currently under maintenance.`;
          return this.getRetryAfterTimer(response).pipe(
            tap(() => console.log('Retrying request...')),
            switchMap(() => next.handle(request.clone())),
            retry({
              delay: (error, retryCount) => {
                if (loadingTextElement) {
                  loadingTextElement.innerHTML = `${maintenanceText}<br>Retrying (${retryCount}/${environment.maxRequestRetryAttempts})...`;
                }
                return this.getRateLimitResetTimer(response);
              },
              count: environment.maxRequestRetryAttempts,
            }),
            catchError(() => {
              if (loadingTextElement) {
                loadingTextElement.innerHTML = `${maintenanceText}<br>Please try again later...`;
              }
              return NEVER;
            })
          );
        default:
          return throwError(() => response);
      }
    }));
  }

  protected getRateLimitResetTimer(response: HttpErrorResponse): Observable<number> {
    const resetTime = parseInt(response.headers.get('RateLimit-Reset'), 10);
    const nowTime = Date.now() / 1000;
    let dueTime = resetTime - nowTime;
    dueTime = dueTime < 2 ? 2 : Math.ceil(dueTime);
    return timer(dueTime * 1000);
  }

  protected getRetryAfterTimer(response: HttpErrorResponse): Observable<number> {
    let retryTime = parseInt(response.headers.get('Retry-After'), 10);
    retryTime = retryTime < 2 ? 2 : retryTime;
    return timer(retryTime * 1000);
  }
}
