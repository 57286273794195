<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>

<div class="modal-body">
  <p [innerHTML]="message"></p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-lg btn-primary" (click)="close()" ngbAutofocus>OK</button>
</div>
