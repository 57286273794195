import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from '../services/shared.service';
import { DataCollectionService, IDataCollectionEditable, IDataCollectionMember } from '../../library-data';

@Component({
  templateUrl: './modal-leave-library.component.html'
})
export class ModalLeaveLibraryComponent implements OnInit {
  collectionId: string;
  collectionEditable: IDataCollectionEditable;
  newAssigneeId: string;

  canLeave: boolean = true;
  assigneesList: IDataCollectionMember[] = [];

  isOrganization: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    public dataCollection: DataCollectionService,
    public shared: SharedService
  ) { }

  ngOnInit() {
    this.dataCollection.getEditable(this.collectionId).subscribe(collection => {
      this.collectionEditable = collection;
      this.isOrganization = !!collection.organization;

      if (this.isOrganization) {
        const userIsAdmin = collection.members.find(member => member.id === this.shared.user.id)?.role === 'admin';

        const hasMultipleAdmins = collection.members.filter(member => member.role === 'admin').length > 1;

        this.canLeave = !userIsAdmin || hasMultipleAdmins;

        if (!this.canLeave)
          this.assigneesList = collection.members.filter(member =>
            member.role !== 'admin' &&
            member.status !== 'invite_pending' &&
            member.id !== this.shared.user.id);
      } else {
        this.canLeave = collection.members.find(member => member.id === this.shared.user.id)?.role !== 'owner';

        if (!this.canLeave)
          this.assigneesList = collection.members.filter(member =>
            member.role !== 'owner' &&
            member.status !== 'invite_pending' &&
            member.id !== this.shared.user.id
          );
      }
    });
  }

  cancel() {
    this.activeModal.close(this.collectionEditable);
  }

  leave() {
    const body = this.isOrganization ? { new_admin_id: this.newAssigneeId } : { new_owner_id: this.newAssigneeId };
    this.dataCollection.leave(this.collectionEditable.id, body)
      .then(collection => this.activeModal.close(collection));
  }
}
