import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge, mergeMap, of, shareReplay, tap } from 'rxjs';
import { DataReviewService, IReviewProject } from '../../review-data';

export interface IModalReviewState {
  confirmed: boolean;
  projectId: number;
  source: string;
  labels: string[];
}

@Component({
  templateUrl: './modal-review.component.html'
})
export class ModalReviewComponent {

  confirmed: boolean;
  selectedProject: IReviewProject;
  projects$: Observable<IReviewProject[]>;

  @ViewChild('projectSelectTypeahead', { static: false })
  projectSelectTypeahead: NgbTypeahead;
  projectFocus$ = new Subject<string>();
  projectClick$ = new Subject<string>();
  selectProjectFormatter = (project: any) => project.name;
  selectProjectSearch: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.projectClick$.pipe(filter(() => !this.projectSelectTypeahead.isPopupOpen()));
    const inputFocus$ = this.projectFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      mergeMap(term => this.projects$.pipe(
        map(projects => projects.filter(v => !term || v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )),
    );
  };

  sources = ['embase', 'ovid', 'pubmed', 'other'];
  labels = [];
  labelsText = '';
  selectedSource: string = null;
  searchLabels$: (text$: Observable<string>) => Observable<string[]>;

  constructor(
    public activeModal: NgbActiveModal,
    public dataReview: DataReviewService,
  ) { }

  ngOnInit() {
    this.projects$ = this.dataReview.getProjects().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.searchLabels$ = (text$: Observable<string>) => text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),

      mergeMap(term => {
        if (!term.length || term.length < 2) {
          return of(this.selectedProject?.labels || []);
        }
        term = term.toLowerCase();
        return of(this.selectedProject?.labels?.filter(l => l.toLocaleLowerCase().includes(term)) || []);
      }));
  }



  submit() {
    const state: IModalReviewState = {
      confirmed: true,
      projectId: this.selectedProject?.id,
      source: this.selectedSource,
      labels: this.labels
    };
    this.activeModal.close(state);
  }

  cancel() {
    this.activeModal.close({ confirmed: false });
  }
}
