import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'common-icon-readcube',
  templateUrl: './common-icon-readcube.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonIconReadCubeComponent {
  @Input()
  width = '21px';

  @Input()
  height = '21px';
}
