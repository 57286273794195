import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IDataItem } from '../../library-data';

export interface ISelectionMessage {
  type: 'marked' | 'initialized' | 'title-found' | 'preview-unavailable';
  data?: any;
}

@Injectable()
export class PreviewService {
  selection$ = new Subject<ISelectionMessage>();

  constructor(
    public router: Router
  ) { }

  canShow(item: IDataItem): boolean {
    return !!item.primary_file_hash && item.primary_file_type === 'pdf';
  }

  show(route: ActivatedRoute, item: IDataItem) {
    return this.router.navigate([{
      outlets: {
          'bottompanel': ['preview', {
          'collection_id': item.collection_id,
          'item_id': item.id
        }]
      }
    }], {
      relativeTo: route,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  showResolve(route: ActivatedRoute, item: IDataItem, type = 'journal_article') {
    const contextMenuFields = {
      'journal_article': ['Copy', '_separator_', 'Title', 'Authors', 'Journal', 'Year', 'DOI', 'ISBN', 'PMID'],
      'patent': ['Copy', '_separator_', 'Patent ID']
    };
    return this.router.navigate([{
      outlets: {
        'bottompanel': ['resolve', {
          'collection_id': item.collection_id,
          'item_id': item.id,
          'types': contextMenuFields[type]
        }]
      }
    }], {
      relativeTo: route,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  close(route: ActivatedRoute) {
    return this.router.navigate([{
      outlets: { 'bottompanel': null }
    }], {
      relativeTo: route,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
