import { Component, Input, Output, EventEmitter, OnInit, NgZone, ViewEncapsulation } from '@angular/core';

import { AppShellService } from '../../app-shell.service';
import { AppStorageService } from '../../app-storage.service';

import { saveAs } from 'file-saver';
import * as uuid from 'uuid';

function readTextFromFile(file: File, encoding?: string): Promise<string> {
  return new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result.toString());
    });
    reader.readAsText(file, encoding);
  });
}

interface ITemplate {
  id: string,
  name: string,
  options: ITemplateOptionsCsv,
}

export interface ITemplateOptionsCsv {
  // Import options
  mergeDuplicates: boolean,
  resolveMetadata: boolean,
  // Export options
  customizeExport: boolean,
  exportHeader: boolean,
  customizeHeader: boolean,
  exportDelimiter: string,
  // Common mapping options
  csvMapping?: ITemplateOptionsMappingCsv[],
}

export interface ITemplateOptionsMappingCsv {
  key: string,
  enabled: boolean,
  custom: boolean,
  papersField: string,
  customField: string,
  exportHeader: string,
}

@Component({
  selector: 'rcp-common-templates-menu',
  templateUrl: './common-templates-menu.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CommonTemplatesMenuComponent implements OnInit {
  @Input()
  type: string;

  @Input()
  collectionId: string;

  @Input()
  options: any;

  @Output()
  optionsChange = new EventEmitter<any>();

  templates: ITemplate[];
  loadedTemplate: ITemplate;
  storageKey = 'import_templates_v1';

  constructor(
    protected zone: NgZone,
    public shell: AppShellService,
    public storage: AppStorageService,
  ) { }

  ngOnInit() {
    this.getImportTemplates();
  }

  async getImportTemplates(): Promise<ITemplate[]> {
    const templates = this.storage.get(this.storageKey, [])
      //.filter(template => template.collection_id === this.collectionId)
      .filter(template => template.type === this.type);
    this.templates = templates;
    return templates;
  }

  async storeImportTemplates(templates: ITemplate[]) {
    this.storage.set(this.storageKey, templates);
  }

  async createTemplate(id: string | null, name: string) {
    const templates = await this.getImportTemplates();
    let templateIndex = templates.findIndex(v => v.id === id);
    if (templateIndex == -1) {
      const template =  {
        id: uuid.v4(),
        collection_id: this.collectionId,
        type: this.type,
        options: this.options,
        default: true,
        name,
      };
      templates.push(template);
      this.loadedTemplate = template;
    } else {
      const template = templates[templateIndex];
      template.options = this.options;
      templates[templateIndex] = template;
      this.loadedTemplate = template;
    }
    this.storage.set(this.storageKey, templates);
  }

  async deleteTemplate(id: string) {
    const templates = await this.getImportTemplates();
    const templateIndex = templates.findIndex(v => v.id === id);
    if (templateIndex != -1) {
      templates.splice(templateIndex, 1);
      this.storage.set(this.storageKey, templates);
    }
    this.loadedTemplate = null;
  }

  async loadTemplate(id: string) {
    const templates = await this.getImportTemplates();
    const template = templates.find(v => v.id === id);
    if (template) {
      this.loadedTemplate = template;
      this.options = template.options;
      this.optionsChange.emit(template.options);
    }
  }

  updateTemplate() {
    this.shell.showConfirm({
      title: 'Update template',
      message: `Are you sure you want to update ${this.loadedTemplate.name} template?`,
    }).then(result => {
      if (result.confirmed) {
        this.createTemplate(this.loadedTemplate.id, this.loadedTemplate.name);
      }
    });
  }

  saveTemplate() {
    this.shell.showPrompt({
      title: 'Template name',
      message: 'Please enter template name:',
    }).then(result => {
      if (result.confirmed && result.value) {
        this.createTemplate(null, result.value);
      }
    });
  }

  exportTemplatesToFile() {
    const name = 'Papers Templates.json';
    const type = 'text/plain;charset=utf-8';
    const blob = new Blob([JSON.stringify(this.templates)], { type });
    saveAs(blob, name);
  }

  importTemplatesFromFile(event: HTMLInputEvent) {
    const files = Array.from(event.target.files);
    if (!files.length) return;

    readTextFromFile(files[0]).then(text => {
      try {
        const templates: any[] = JSON.parse(text);
        if (templates.some(t => !t.name || !t.type || !t.id || !t.options)) {
          throw 'missing fields';
        }
        this.storeImportTemplates(templates);
        this.getImportTemplates();
      } catch (ex) {
        this.shell.showError({
          title: 'Invalid file',
          message: 'Seleted file does not contain valid templates.',
        });
      }
    });
  }

  deleteCurrentTemplate() {
    this.shell.showConfirm({
      title: 'Delete template',
      message: `Are you sure you want to delete "${this.loadedTemplate.name}" template?`,
    }).then(result => {
      if (result.confirmed) {
        this.deleteTemplate(this.loadedTemplate.id);
      }
    });
  }
}
