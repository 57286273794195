<form [formGroup]="form" class="filters-form"  *ngIf="form">
  <div ngbAccordion #accordion="ngbAccordion">
    <ng-container *ngFor="let group of form.controls;let i = index;" [formGroupName]="i">
      <ng-container *ngIf="group.get('visible').value || configureView">

        <div ngbAccordionItem="group-{{i}}" *ngIf="group.get('type').value === 'range'">
          <h2 ngbAccordionHeader><button class="papers-label" ngbAccordionButton>Year</button></h2>
          <div ngbAccordionCollapse>

            <div ngbAccordionBody *ngIf="!configureView">
              <ng-container>
                <div class="filters-form__chart">
                  <ngx-charts-bar-vertical [view]="chartView" barPadding="1" [results]="chartData" [customColors]="customColors" tooltipDisabled="true"></ngx-charts-bar-vertical>
                </div>
                <div class="filters-form__slider">
                  <ngx-slider formControlName="filters" [options]="sliderOptions(group.get('defaultFilters').value)"></ngx-slider>
                </div>
              </ng-container>
            </div>
    
            <div ngbAccordionBody *ngIf="configureView" class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" formControlName="visible" type="checkbox">
                Slider
              </label>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="group-{{i}}" *ngIf="group.get('type').value === 'checkbox'">
          <h2 ngbAccordionHeader><button class="papers-label" ngbAccordionButton>{{ group.get('label').value }}</button></h2>
          <div ngbAccordionCollapse formGroupName="filters">
            <div class="form-check" *ngFor="let filter of group.get('filters').controls;let j = index;" [formGroupName]="j" ngbAccordionBody>
              <label class="form-check-label">
                <input class="form-check-input" [formControlName]="configureView ? 'visible' : 'value'" type="checkbox">
                {{ filter.get('label').value }}
              </label>
            </div>
          </div>
        </div>

      </ng-container>
    </ng-container>
  </div>

</form>
