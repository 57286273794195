import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { DataFiltersApiService, FilterGroupType, ICheckboxFilter, IDataFiltersResult, TRangeFilter } from './data-filters-api.service';

interface IGetFiltersParams {
  visible?: boolean;
}

@Injectable()
export class SearchNavigationFiltersService {
  data: BehaviorSubject<IDataFiltersResult[]>;

  constructor(
    protected dataFilters: DataFiltersApiService
  ) {
    this.data = new BehaviorSubject(null);

    dataFilters.query().subscribe((newFilters) => {
      const mappedFilters = this.mapFilters(newFilters);

      this.data.next(mappedFilters);

      try {
        localStorage.setItem('filters', JSON.stringify(mappedFilters));
      } catch {
        console.error('Failed to save filters to local storage');
      }
    });
  }

  mapFilters(newFilters: IDataFiltersResult[]) {
    const oldFilters = JSON.parse(localStorage.getItem('filters')) || [];

    // instead of elaborate checks whether something needs updating, just map the visibilities of old ones over to the new ones and update
    const oldFiltersMap = oldFilters.reduce((groupAcc, group) => {
      groupAcc[group.name] = {
        ...group,
        filters: group.type === FilterGroupType.Checkbox ? group.filters.reduce((filterAcc, val: ICheckboxFilter) => {
          filterAcc[val.name] = val;

          return filterAcc;
        }, {}) : group.filters,
      };

      return groupAcc;
    }, {});

    return newFilters.map((group) => {
      const oldFilterGroup = oldFiltersMap[group.name];

      if (!oldFilterGroup) {
        return group;
      }

      return {
        ...group,
        visible: oldFilterGroup.visible,
        filters: group.type === FilterGroupType.Range ? group.filters : (group.filters as ICheckboxFilter[]).map((filter) => {
          const oldFilter = oldFilterGroup.filters[filter.name];

          if (!oldFilter) {
            return filter;
          }

          return {
            ...filter,
            visible: oldFilter.visible
          };
        })
      };
    });
  }

  getGroupVisibleFilters({ type, visible, filters }: IDataFiltersResult, params: IGetFiltersParams) {
    if (type === FilterGroupType.Range) {
      return visible ? filters : null;
    }

    return (filters as ICheckboxFilter[]).filter(({ visible }) => visible === params.visible);
  }

  getFilters(params: IGetFiltersParams) {
    // it'll probably live in a data service, but for now we mock data here

    if (params?.visible === true || params?.visible === false) {
      return this.data.pipe(map((data) => {
        if (!data) {
          return null;
        }

        return data.map((group) => ({
            ...group,
            filters: this.getGroupVisibleFilters(group, params),
          }));
        }
      ));
    }
    
    return this.data;
  }

  getGroupVisibility(group: IDataFiltersResult) {
    return group.type === FilterGroupType.Range ? group.visible : !!(group.filters as ICheckboxFilter[]).find(({ visible }) => visible);
  }

  customizeFilters(form: IDataFiltersResult[]) {
    // determine group visible for checkboxes if no filters are active for each group as that cannot be done through the form

    const mappedForm = form.map((group) => ({
      ...group,
      visible: this.getGroupVisibility(group)
    }));

    localStorage.setItem('filters', JSON.stringify(mappedForm));
    this.data.next(mappedForm);
  }
}