import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { AppStorageService } from '../../app-storage.service';

@Injectable()
export class HistoryService {
  lastSearchQuery$ = new ReplaySubject<string>(1);

  constructor(
    protected storage: AppStorageService
  ) { }

  getSearchHistory(key: string): string[] {
    const storageKey = ['search_history', key].join('_');
    return this.storage.get(storageKey) || [];
  }

  pushSearchHistory(key: string, term: string): string[] {
    const storageKey = ['search_history', key].join('_');
    let s = this.getSearchHistory(key);
    if (!term) return s;
    s = s.filter(v => v !== term);
    s.splice(0, 0, term);
    this.storage.set(storageKey, s);
    return s;
  }
}
