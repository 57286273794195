import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { ReaderService, SharedService } from '../../common';
import { DataUserService } from '../../common-data';
import { DataItemService, IDataItem, IDataItemFile } from '../../library-data';
import { ImporterService } from '../../importer';
import { LibraryService } from '../../library';
import { AppShellService } from '../../app-shell.service';

import { LibrarySidepanelService } from '../services/library-sidepanel.service';

@Component({
  templateUrl: './library-sidepanel-files.component.html',
  styleUrls: [
    './library-sidepanel.component.scss',
    './library-sidepanel-files.component.scss'
  ],
})
export class LibrarySidepanelFilesComponent implements OnInit, OnDestroy {
  item: IDataItem;

  canViewPDF = new Map<string, boolean>();
  canDownloadFile = new Map<string, boolean>();
  canSetPrimary = new Map<string, boolean>();
  canDelete = new Map<string, boolean>();
  thumbURL = new Map<string, string>();

  canDownloadFileForCollection: boolean = false;
  canManageFile: boolean = false;

  protected itemSub: Subscription;
  protected collectionSub: Subscription;

  constructor(
    public modal: NgbModal,
    public shared: SharedService,
    public sidepanel: LibrarySidepanelService,
    public reader: ReaderService,
    public importer: ImporterService,
    public library: LibraryService,
    public dataItem: DataItemService,
    public dataUser: DataUserService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.itemSub = this.sidepanel.item$.subscribe(item => {
      item.files.forEach(file => {
        this.canViewPDF[file.sha256] = this.library.canViewItemPDF(item, file);
        this.canDownloadFile[file.sha256] = this.library.canDownloadFile(item, file);
        this.canSetPrimary[file.sha256] = this.library.canSetPrimary(item, file);
        this.canDelete[file.sha256] = this.library.canDeleteFile(item, file);
        this.thumbURL[file.sha256] = this.library.getThumbnailURL(item, file);
      });
      this.item = item;
    });
    this.collectionSub = this.sidepanel.collection$.subscribe(collection => {
      this.canDownloadFileForCollection = !!collection.user?.can_download_file;
      this.canManageFile = !!collection.user?.can_manage_file;
    });
  }

  ngOnDestroy() {
    this.canViewPDF.clear();
    this.canDownloadFile.clear();
    this.canDelete.clear();
    this.thumbURL.clear();

    this.itemSub.unsubscribe();
    this.collectionSub.unsubscribe();
  }

  onThumbClick(event: MouseEvent, file: IDataItemFile, canViewPDF: boolean, canDownload: boolean) {
    if (canViewPDF) {
      this.open(event, file);
    } else if (canDownload) {
      this.download(event, file);
    }
    event.preventDefault();
  }

  onFileSelect(event: HTMLInputEvent) {
    const files = Array.from(event.target.files);
    const collectionId = this.item.collection_id,
      itemId = this.item.id;
    this.importer.addToItem(files, collectionId, itemId);
    this.importer.start();
  }

  setPrimary(file: IDataItemFile) {
    const collectionId = this.item.collection_id, itemId = this.item.id;
    this.dataItem.setPrimary(collectionId, itemId, { hash: file.sha256 });
  }

  delete(file: IDataItemFile) {
    this.sidepanel.removeFile(this.item, file);
  }

  open(event: MouseEvent, file: IDataItemFile) {
    this.reader.viewItemPDF({ item: this.item, file: file });
  }

  download(event: MouseEvent, file: IDataItemFile) {
    const item = this.item;
    const params = { hash: file.sha256, file_name: file.name };
    this.dataItem.downloadFileURL(item.collection_id, item.id, params).then(url => window.open(url));
  }
}
