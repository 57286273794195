import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLastAuthor'
})
export class FormatLastAuthorPipe implements PipeTransform {
  transform(value: string[] | { name: string }[]): string {
    if (!value || value.length === 0) return '';
    let last = value[value.length-1];
    let s = typeof last == 'string' ? last : last.name;

    // https://readcube.atlassian.net/jira/software/c/projects/WEBAPP/issues/WEBAPP-1534
    if (s[s.length - 1] === '~') return s.slice(0, -1);

    let i = s.lastIndexOf(' ');
    if (i === -1) return s;
    return [s.slice(i), s.slice(0, i)].join(', ');
  }
}
