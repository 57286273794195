<form #collectionFieldsForm="ngForm">

  <div class="modal-header">
    <h4 class="modal-title" *ngIf="!collection?.name">Custom Fields</h4>
    <h4 class="modal-title" *ngIf="collection?.name">Custom Fields in {{collection.name}}</h4>
    <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body">
    <div *ngIf="error$ | async as error" class="alert alert-danger">{{error}}</div>

    <div class="alert alert-secondary">
      Use custom fields to store additional information associated with references in your library.
    </div>

    <div *ngIf="collection; else loadingTemplate">

      <div class="input-container form-control mb-2" *ngIf="collection.custom_fields?.length">
        <fa-icon [icon]="['fas', 'filter']"></fa-icon>
        <input
          #searchInput
          type="text"
          autocomplete="false"
          spellcheck="false"
          (input)="onSearchTermChange($event)"
          placeholder="Filter fields...">
        <fa-icon
          class="pointer"
          [icon]="['fal', 'times-circle']"
          (click)="clearSearch()">
        </fa-icon>
      </div>

      <div class="card mb-2" *ngFor="let f of collection.custom_fields; let i = index" [hidden]="isCustomFieldHidden(f)">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="input-group">
                <span class="input-group-text">Field name:</span>
                <input
                  id="field_name_{{i}}"
                  name="field_name_{{i}}"
                  #name="ngModel"
                  type="text"
                  class="form-control"
                  [(ngModel)]="f.display"
                  placeholder="Enter field name..."
                  maxlength="200"
                  required
                  emptyValidator>
              </div>
              <span class="form-text text-muted" *ngIf="name.control.errors?.required && name.touched">Name is required.</span>
              <span class="form-text text-muted" *ngIf="name.control.errors?.empty && name.touched">Name cant't be empty.</span>
            </div>

            <div class="col-md-4">
              <div class="input-group">
                <span class="input-group-text">CSL name:</span>
                <input
                    id="csl_name_{{i}}"
                    name="csl_name_{{i}}"
                    #cslName="ngModel"
                    type="text"
                    class="form-control"
                    [(ngModel)]="f.field"
                    placeholder="Enter CSL name..."
                    maxlength="200"
                    required
                    fieldNameValidator>
              </div>
              <span class="form-text text-muted" *ngIf="cslName.control.errors?.required && cslName.touched">CSL name is required.</span>
              <span class="form-text text-muted" *ngIf="cslName.control.errors?.fieldName && cslName.touched">CSL name can't have spaces or special characters other than &#8208; and &#95;.</span>
            </div>

            <div class="col-md-4">
              <div class="input-group">
                <span class="input-group-text">Section:</span>
                <rcp-typeahead
                  id="section_{{i}}"
                  name="section_{{i}}"
                  class="form-control"
                  [(ngModel)]="f.section"
                  [ngModelOptions]="{standalone: true}"
                  [options]="sections"
                  [editable]="true">
                </rcp-typeahead>
                <span class="input-group-text pointer" (click)="f.section = null" *ngIf="f.section">
                  <fa-icon [icon]="['fal', 'times-circle']"></fa-icon>
                </span>
              </div>
            </div>

            <div class="col-md-12 mt-2">
              <div class="row">
                <div class="col-md-8">
                  <div class="input-group">
                    <span class="input-group-text">Type:</span>
                    <select
                      id="type_{{i}}"
                      name="type_{{i}}"
                      class="form-control"
                      [(ngModel)]="f.type"
                      (ngModelChange)="onTypeChange($event, f)">
                      <option [value]="opt.value" *ngFor="let opt of fieldTypes">{{opt.label}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="(f.type == 'lookup' || f.type == 'array-lookup') && f.lookups">
                <div class="col-md-8">
                  <rcp-token-field-editable
                    name="lookups-{{i}}"
                    class="form-control"
                    [(ngModel)]="f.lookups"
                    [maxVisibleElements]="4"
                    [canSortAlpha]="true"
                    label="Lookup options"
                    placeholder="Enter lookup options here...">
                  </rcp-token-field-editable>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-2">
              <div class="row">
                <div class="col-md-8">
                  <div class="form-check form-switch">
                    <input
                      type="checkbox"
                      id="show_details_{{i}}"
                      class="form-check-input"
                      name="show_details_{{i}}"
                      [(ngModel)]="f.show_in_details">
                    <label class="form-check-label" for="show_details_{{i}}">Show in details panel</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="form-check form-switch">
                    <input
                      type="checkbox"
                      id="show_table_{{i}}"
                      name="show_table_{{i}}"
                      class="form-check-input"
                      [(ngModel)]="f.show_in_table">
                    <label class="form-check-label" for="show_table_{{i}}">Show in library table view</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <button type="button" class="btn btn-sm btn-link float-end" ngbTooltip="Remove field" (click)="removeField(i)" [disabled]="disabled$ | async">
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="addField()" [disabled]="disabled$ | async">
      <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add Field
    </button>

    <div class="btn-group me-auto" ngbDropdown placement="bottom-left">
      <button type="button" class="btn btn-secondary" ngbDropdownToggle>
        <fa-icon [icon]="['fal', 'hdd']"></fa-icon> Backup
      </button>

      <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
        <input #importFileElement type="file" id="files" name="files[]" class="dropdown-item"
          (change)="importFromCSV($event)" accept=".csv" hidden>

        <button type="button" class="dropdown-item" (click)="importFileElement.click()">Import from CSV</button>
        <button type="button" class="dropdown-item" (click)="exportToCSV()">Export to CSV</button>
      </div>
    </div>

    <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="disabled$ | async">Close</button>
    <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="!collectionFieldsForm.form.valid || (disabled$ | async)">Save</button>
  </div>

</form>


<ng-template #loadingTemplate>
  <common-loader-circles class="loader" [ngClass]="{'visible': loading}"></common-loader-circles>
</ng-template>
