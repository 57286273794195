import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, firstValueFrom, map, of, reduce, takeUntil } from 'rxjs';
import { CsvInfo, ItemToTablesConverter, getCsvInfoFromString } from '@readcube/rcp-csv-items';

import { AppShellService } from '../../app-shell.service';
import { SharedService, ITemplateOptionsCsv } from '../../common';
import { IDataUser } from '../../common-data';
import { DataItemService, IDataCollection, IDataItem, IDataList } from '../../library-data';
import { ExporterService } from '../services/exporter.service';

import { environment } from 'environment';

@Component({
  selector: 'rcp-exporter-options-csv',
  templateUrl: './exporter-options-csv.component.html',
  styleUrls: ['./exporter-options-csv.component.scss']
})
export class ExporterOptionsCsvComponent implements OnInit, OnDestroy {
  @Input()
  user: IDataUser;

  @Input()
  collection: IDataCollection;

  @Input()
  list: IDataList;

  @Input()
  items: Partial<IDataItem>[];

  @Input()
  options: ITemplateOptionsCsv;

  @Output()
  optionsChange = new EventEmitter<ITemplateOptionsCsv>();

  formGroup: FormGroup;
  loadingProgress$ = new Subject<number | null>();
  stopExport$ = new Subject<void>();
  exportAllColumnsToggle = true;
  extraMap = [
    {
      key: 'created (Read-Only)',
      csvColumn: 'created (Read-Only)',
      papersField: 'Created (Read-Only)',
      customField: '',
      dataPreview: ''
    },
    {
      key: 'updated (Read-Only)',
      csvColumn: 'updated (Read-Only)',
      papersField: 'Updated (Read-Only)',
      customField: '',
      dataPreview: ''
    },
    {
      key: 'file (Read-Only)',
      csvColumn: 'file (Read-Only)',
      papersField: 'File (Read-Only)',
      customField: '',
      dataPreview: ''
    },
    {
      key: 'Library URL',
      csvColumn: 'Library URL',
      papersField: 'Library URL',
      customField: '',
      dataPreview: ''
    },
    {
      key: 'PDF URL',
      csvColumn: 'PDF URL',
      papersField: 'PDF URL',
      customField: '',
      dataPreview: ''
    },
  ];

  constructor(
    public fb: FormBuilder,
    public exporter: ExporterService,
    public dataItem: DataItemService,
    public shared: SharedService,
    public shell: AppShellService
  ) { }

  async ngOnInit() {
    await this.loadExportInfo();
    await this.updateOptions();
  }

  ngOnDestroy() {
    this.stopExport$.next();
  }

  async updateOptions() {
    this.options = this.formGroup.value;
    this.optionsChange.next(this.options);
  }

  async loadExportInfo() {
    const customFields = this.collection.custom_fields;
    const customSchema = this.collection.custom_type_schema;
    const baseAppUrl = `https:${environment.baseUrls.webapp}`;
    const baseWebUrl = `https:${environment.baseUrls.readcube}`;
    const allowCopyrightStatus = this.shared.user?.licence?.copyright_show_status;

    const converter = new ItemToTablesConverter({
      customFields,
      customSchema,
      baseAppUrl,
      baseWebUrl,
      allowCopyrightStatus
    });

    let stream$: Observable<ItemToTablesConverter>;
    if (this.items) {
      stream$ = of(this.items).pipe(
        map(items => {
          this.loadingProgress$.next(100);
          converter.addItems(items);
          return converter;
        })
      );
    } else {
      stream$ = this.dataItem.queryAll({
        collection_id: this.collection.id,
        list_id: this.list?.id
      }).pipe(
        reduce((converter, result) => {
          const percent = converter.rows.length / result.total * 100;
          this.loadingProgress$.next(percent);
          converter.addItems(result.rows);
          return converter;
        }, converter),
        takeUntil(this.stopExport$)
      );
    }

    return firstValueFrom(stream$).then(converter => {
      const csvString = converter.getCsv();
      return getCsvInfoFromString({
        csvString,
        customFields,
        customSchema,
        allowCopyrightStatus
      });
    }).then(result => {
      if (result.success) {
        this.formGroup = this.createFormGroup(result.result);
      }
    });
  }

  createFormGroup(csvInfo: CsvInfo) {
    const csvMappingControls = csvInfo.defaultMap.concat(this.extraMap).map(v => {
      return this.fb.group({
        'key': v.key,
        'enabled': this.exportAllColumnsToggle,
        'custom': !!v.customField,
        'papersField': this.fb.control({ value: v.papersField || '', disabled: false }),
        'customField': this.fb.control({ value: v.customField || '', disabled: false }),
        'exportHeader': v.key,
      });
    });
    return this.fb.group({
      'mergeDuplicates': false,
      'resolveMetadata': false,
      'customizeExport': false,
      'exportHeader': true,
      'customizeHeader': false,
      'exportDelimiter': ',',
      'csvMapping': this.fb.array(csvMappingControls),
    });
  }

  loadOptions(options: ITemplateOptionsCsv) {
    this.formGroup.controls['customizeExport'].patchValue(options.customizeExport);
    this.formGroup.controls['exportHeader'].patchValue(options.exportHeader);
    this.formGroup.controls['customizeHeader'].patchValue(options.customizeHeader);
    this.formGroup.controls['exportDelimiter'].patchValue(options.exportDelimiter);

    const csvMapping = <FormArray>this.formGroup.controls['csvMapping'];
    for (let opt of options.csvMapping) {
      for (let ctrl of csvMapping.controls) {
        let columnValue = (<FormGroup>ctrl).controls['key'].value;
        if (opt.key === columnValue) ctrl.patchValue(opt);
      }
    }
  }

  toggleSelectColumns(value: boolean) {
    const csvMapping = <FormArray>this.formGroup.controls['csvMapping'];
    for (let ctrl of csvMapping.controls) {
      const columnEnabledCtrl = (<FormGroup>ctrl).controls['enabled'];
      columnEnabledCtrl.patchValue(value);
    }
  }
}
