<div class="content"
  [@contentExpandContract]="{value: contentState, params: { width: sidepanelWidth }}">
  <ng-content select="[content]"></ng-content>
  <div class="footer papers-border-top">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>

<div class="sidepanel"
  [ngClass]="{'closed': !sidepanelOpen, 'open': sidepanelOpen, 'papers-border-left': sidepanelOpen}"
  [@sidePanelOpenClose]="{value: sidePanelState, params: { width: sidepanelWidth }}">
  <div class="resize-handle" (mousedown)="startDrag($event)" *ngIf="!isMobile"></div>
  <div class="close-handle" (click)="closeSidepanel()" *ngIf="!isMobile && false">
    <i class="arrow"></i>
  </div>
  <ng-content select="[sidepanel]"></ng-content>
</div>
