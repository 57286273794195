import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';

import { DataStatusService, IDataItemExtIds, IDataArticleItemStatus, IDataItemStatus } from '../../common-data'
import { IDataResult } from '../../app-data-result.model';
import { environment } from 'environment';
import * as uuid from 'uuid';

export interface IDataArticle {
  id: string;
  patent_id: string;
  abstract: string;
  authors: IDataArticleAuthor[];
  counts: IDataArticleCounts;
  highlight: IDataArticleHighlight;
  //doi: string;
  issn: string;
  isbn: string;
  eissn: string;
  eisbn: string;
  figures?: any;
  first_page_preview: boolean;
  issue: string;
  journal: string;
  journal_abbrev: string;
  month: string;
  multipass: boolean;
  oa: boolean;
  oa_journal: boolean;
  pagination: string;
  pdf_hash: string;
  pdf_url: string;
  //pmcid: string;
  //pmid: string;
  publisher: string;
  tags?: any;
  thumb_url: string;
  title: string;
  chapter: string;
  url: string;
  volume: string;
  year: string;
  ext_ids: IDataItemExtIds; // Joined
  item_statuses: IDataArticleItemStatus[]; // Joined
  status_data?: IDataItemStatus // Joined
}

export class IDataArticleHighlight {
  title: string[];
  abstract: string[];
  full_text: string[];
}

export class IDataArticleAuthor {
  countries: string[];
  email: string;
  institutions: string[];
  name: string;
  short_name: string;
}

export class IDataArticleCounts {
  affiliations?: number;
  altmetric?: number;
  citations?: number;
  inline_references?: number;
  inline_references_in_methods?: number;
  methods?: number;
  rcr?: number;
  references?: number;
}

export class IDataArticleCollectionStatus {
  id: string;
  name: string;
  shared: boolean;
  item_id: string;
  has_file: boolean;
}

export interface IDataSearchQueryParams {
  type?: string;
  query?: string;
  sort_by?: string;
  order?: 'asc' | 'desc';
  size?: number;
}

@Injectable()
export class DataSearchApiService {
  public scrollId?: string;

  constructor(
    private http: HttpClient,
    private statusService: DataStatusService
  ) { }

  public query<T = IDataArticle>(params: IDataSearchQueryParams, invalidateSession = false): Observable<IDataResult<T>> {
    const endpointUrl = `${environment.baseUrls.services}/articles/search_fulltext`;
    if (invalidateSession) {
      this.scrollId = null;
    }
    const httpOptions = {
      headers: this.getHttpHeaders(this.scrollId),
      params: this.createHttpParams(params, this.scrollId)
    };
    return this.http.get<any>(endpointUrl, httpOptions).pipe(
      catchError(result => throwError(() => result.error)),
      tap(body => this.scrollId = body.scroll_id),
      switchMap(body => {
        const extIds = body.results.map(result => this.getExtIds(result));
        return this.statusService.checkInLibraryStatuses(extIds)
          .then(checkExtIdsData =>  this.createResult<T>(body, checkExtIdsData));
      })
    );
  }

  private createResult<T>(data: any, checkExtIdsData: any[]): IDataResult<T> {
    return {
      refinements: data['refinements'] || {},
      rows: data['results'].map((data: any, index: number) => {
        return this.mapResultData(data, checkExtIdsData[index]);
      }),
      totalHuman: data['total_human'] || 'No results',
      total: data['total'] || 0
    };
  }

  private mapResultData<T>(data: any, itemStatuses: any[]): T {
    return Object.assign({
      id: uuid.v4(),
      ext_ids: this.getExtIds(data),
      authors: data.inventors ? data.inventors.map(i => ({ name: i })) : [],
      highlight: data.highlight || {},
      item_statuses: itemStatuses || [],
      counts: {},
    }, data);
  }

  private getExtIds(data: any): IDataItemExtIds {
    const e = {
      doi: data['doi'],
      pmid: data['pmid'],
      pmcid: data['pmcid'],
      arxiv: data['arxiv'],
      patent_id: data['patent_id']
    };
    return e.doi || e.pmid || e.pmcid || e.arxiv || e.patent_id ? e : {};
  }

  private getHttpHeaders(scrollId?: string) {
    if (environment.debugScrollSession && scrollId) {
      return new HttpHeaders()
        .set('X-Raise-Error', 'scroll_id_expired')
        .set('X-Raise-Error-Status', '404');
    }
    return null;
  }

  private createHttpParams(params: IDataSearchQueryParams, scrollId?: string): HttpParams {
    let httpParams = new HttpParams();
    if (params.query) {
      httpParams = httpParams.append('query', params.query);
      httpParams = httpParams.append('refinements[]', 'year');
      httpParams = httpParams.append('refinements[]', 'meshterms');
      httpParams = httpParams.append('refinements[]', 'researcher');
    }
    if (params.type) {
      httpParams = httpParams.append('type', params.type);
    }
    if (params.sort_by) {
      httpParams = httpParams.append('sort_by', params.sort_by);
    }
    if (params.order) {
      httpParams = httpParams.append('order', params.order);
    }
    if (params.size) {
      httpParams = httpParams.append('size', params.size.toString());
    }
    if (scrollId) {
      httpParams = httpParams.set('scroll_id', scrollId);
    }
    return httpParams;
  }
}
