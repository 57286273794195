import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFirstAuthor'
})
export class FormatFirstAuthorPipe implements PipeTransform {
  transform(value: string[] | { name: string; }[]): string {
    if (!value || value.length === 0) {
      return '';
    }
    let first = value[0];
    let s = typeof first == 'string' ? first : first.name;

    // https://readcube.atlassian.net/jira/software/c/projects/WEBAPP/issues/WEBAPP-1534
    if (s[s.length - 1] === '~') {
      return s.slice(0, -1).concat(value.length > 1 ? ' et al.' : '');
    }

    return s.split(' ').pop().concat(value.length > 1 ? ' et al.' : '');
  }
}
