import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { map, mergeMap, Observable, startWith, tap } from 'rxjs';
import { environment } from 'environment';

import { ListTreeService } from '../../common';
import { DataCollectionService, DataListService, IDataCollection, IDataList } from '../../library-data';

@Component({
  templateUrl: './exporter-source-select.component.html',
  selector: 'rcp-exporter-source-select',
})
export class ExporterSourceSelectComponent implements OnInit {
  @Input()
  collection: IDataCollection;

  @Input()
  list: IDataList;

  @Output()
  collectionChange = new EventEmitter<IDataCollection>();

  @Output()
  listChange = new EventEmitter<IDataList>();

  collections$: Observable<IDataCollection[]>;
  lists$: Observable<IDataList[]>;

  constructor(
    public dataCollection: DataCollectionService,
    public dataList: DataListService,
    public listTree: ListTreeService,
  ) { }

  ngOnInit() {
    this.collections$ = this.dataCollection.query().pipe(
      map(arr => arr.filter(c => c.status === 'active')),
      tap(collections => this.collection = collections.find(c => c.id == this.collection.id))
    );
    this.lists$ = this.collectionChange.pipe(
      startWith(this.collection),
      mergeMap(collection => this.dataList.query({ collection_id: collection.id })),
      tap(lists => this.list = lists.find(l => l.id == this.list.id)),
      map(lists => this.listTree.fromLists(lists)),
      map(root => this.listTree.flatten(root, environment.maxListsDepth)),
    );
  }
}
