import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { AppCommonModule } from '../common';
import { LibraryAiLandingComponent } from './components/library-ai-landing.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeSvgIconsModule,
    AppCommonModule,
  ],
  declarations: [
    LibraryAiLandingComponent
  ],
  exports: [
    LibraryAiLandingComponent
  ]
})
export class AppLibraryAiModule { }
