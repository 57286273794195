import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, Inject } from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from '@readcube/ngx-contextmenu';
import { Router, ActivatedRoute } from '@angular/router';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { DragAndDropService, DragHelperEvent } from '@readcube/rcp-drag-and-drop';

import { DataItemService } from '../../library-data';
import { IDataItem, IDataCollection, IDataItemTag } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { LibraryNavigationService } from '../services/library-navigation.service';

@Component({
  selector: 'library-navigation-tag',
  templateUrl: './library-navigation-tag.component.html',
  styleUrls: ['./library-navigation-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryNavigationTagComponent implements OnInit {
  @Input()
  indent = 0;

  @Input()
  collection: IDataCollection;

  @Input()
  tag: IDataItemTag;

  @Input()
  highlight: string;

  @ViewChild('contextMenuComponent', { static: true })
  contextMenuComponent: ContextMenuComponent;

  loadingSource = new Subject<boolean>();
  disabled$: Observable<boolean>;
  loading$: Observable<boolean>;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public contextMenu: ContextMenuService,
    public dataItem: DataItemService,
    public navigation: LibraryNavigationService,
    public dragAndDrop: DragAndDropService,
    private shell: AppShellService
  ) {
    this.loading$ = this.loadingSource.asObservable();
  }

  ngOnInit() {
    this.disabled$ = this.dragAndDrop.event$.pipe(
      map(event => event.name === 'start' && !this.isDroppable(event)),
      shareReplay({ refCount: true, bufferSize: 1 }),
      startWith(false)
    );
  }

  openContextMenu(event: MouseEvent) {
    this.contextMenu.show.next({
      contextMenu: this.contextMenuComponent,
      anchorElement: event.currentTarget,
      event: event,
      item: null
    });
    event.stopPropagation();
    event.preventDefault();
  }

  rename() {
    this.shell.showPrompt({
      title: 'Rename tag...',
      message: 'Type the new name of the tag',
      confirmButtonText: 'Rename',
      cancelButtonText: 'Cancel',
      initialValue: this.tag.name,
    }).then(result => {
      if (!result.confirmed) return;
      const newName = result.value;
      this.navigation.renameTag(this.collection.id, this.tag.id, newName).then(() => {
        this.tag.id = newName;
        this.tag.name = newName;
        setTimeout(() => {
          this.router.navigate(['/library', this.collection.id, 'tag', newName], {
            relativeTo: this.route,
            replaceUrl: true
          });
        }, 2000);
      });
    });
  }

  delete() {
    const collectionId = this.collection.id;
    const tagId = this.tag.id;
    this.navigation.openRemoveTag(collectionId, tagId).then(removed => {
      if (removed && this.router.routerState.snapshot.url.includes(tagId)) {
        this.router.navigate(['/library', collectionId, 'all'], {
          relativeTo: this.route,
          replaceUrl: true
        });
      }
    });
  }

  onDrop(event: DragHelperEvent<IDataItem[]>) {
    const collectionId = this.collection.id,
      newTags = [this.tag.id];
    switch (event.type) {
      case 'items':
        const items = <IDataItem[]>(event.data || []);
        const itemPartials = items.map(item => ({
          id: item.id,
          user_data: { tags: item.user_data.tags.concat(newTags) }
        }));
        this.loadingSource.next(true);
        lastValueFrom(this.dataItem.bulkUpdate(collectionId, itemPartials))
          .then(() => this.loadingSource.next(false))
          .catch(() => this.loadingSource.next(false));
        break;
    }
  }

  isDroppable(event: DragHelperEvent<IDataItem[]>): boolean {
    switch (event.type) {
      case 'items':
        const items = <IDataItem[]>event.data;
        const isFromThisCollection = items.map(item => item.collection_id)
          .indexOf(this.collection.id) !== -1;
        return isFromThisCollection;
    }
    return false;
  }

  canEditTag(): boolean {
    return !!this.collection.user?.can_tag_item;
  }
}
