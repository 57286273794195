import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SharedService, ListNode } from '../../common';
import { IDataList, IDataCollection, IDataItem } from '../../library-data';
import { ListExplorerService } from '../services/list-explorer.service';

@Component({
  selector: 'list-explorer-list',
  templateUrl: './list-explorer-list.component.html',
  styleUrls: ['./list-explorer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListExplorerListComponent implements OnInit {
  @Input()
  indent = 0;

  @Input()
  level = 0;

  @Input()
  maxDepth: number;

  @Input()
  collection: IDataCollection;

  @Input()
  node: ListNode;

  @Input()
  item: IDataItem;

  @Input()
  hasItem = false;

  @Output()
  addToList = new EventEmitter<string>();

  @Output()
  removeFromList = new EventEmitter<string>();

  expanded = false;
  hasItemInChildrenShown: boolean = false;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public shared: SharedService,
    public listExplorer: ListExplorerService
  ) { }

  ngOnInit() {
    const isExpanded = this.listExplorer.isNodeExpanded(this.node.list.id);
    this.expanded = isExpanded === undefined ? this.expanded : isExpanded;
    this.hasItemInChildrenShown = this.hasItem ? false : this.hasItemInChildren(this.node);
  }

  private hasItemInChildren(node: ListNode): boolean {
    if (!node.children?.length) {
      return false;
    }

    for (let i = 0; i < node.children.length; i++) {
      if (this.item.list_ids.includes(node.list.id)) {
        return true;
      }
    }

    for (let i = 0; i < node.children.length; i++) {
      const hasItem = this.hasItemInChildren(node.children[i]);
      if (hasItem) {
        return true;
      }
    }
    return false;
  }

  onMouseClick(event: MouseEvent) {
    this.loading$.next(true);
    this.hasItem
      ? this.removeFromList.emit(this.node.list.id)
      : this.addToList.emit(this.node.list.id);
    event.stopPropagation();
  }

  onExpandedChange(value: boolean) {
    this.listExplorer.setNodeExpanded(this.node.list.id, value);
  }

  listTrackByFn(index: number, list: IDataList) {
    return list.id;
  }
}
