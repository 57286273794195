import { Component, Input, EventEmitter, Output, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from '../../common';
import { IDataList, IDataCollection } from '../../library-data';
import { AppShellService } from '../../app-shell.service';

function dateShifted(years: number, months: number, days: number) {
  const d = new Date();
  return new Date(d.getFullYear() + years, d.getMonth() + months, d.getDate() + days);
}

@Component({
  selector: 'search-recommend-header',
  styleUrls: ['./search-recommend-header.component.scss'],
  templateUrl: './search-recommend-header.component.html'
})
export class SearchRecommendHeaderComponent {
  @Input()
  viewName: string;

  @Input()
  sort: string;

  @Input()
  order: string;

  @Input()
  sortOptions: { key: string, value: string }[];

  @Input()
  orderOptions: { key: string, value: string }[];

  @Input()
  collections: IDataCollection[];

  @Input()
  lists: IDataList[];

  @Output()
  viewNameChange = new EventEmitter<string>();

  fromDateOptions = [{
    date: dateShifted(0, 0, -7).getTime() / 1000,
    label: 'Published last week'
  }, {
    date: dateShifted(0, 0, -14).getTime() / 1000,
    label: 'Published in last 2 weeks'
  }, {
    date: dateShifted(0, -1, 0).getTime() / 1000,
    label: 'Published last month'
  }, {
    date: dateShifted(0, -3, 0).getTime() / 1000,
    label: 'Published in last 3 months'
  }, {
    date: dateShifted(-1, 0, 0).getTime() / 1000,
    label: 'Published last year'
  }];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modal: NgbModal,
    public shared: SharedService,
    public shell: AppShellService
  ) { }

  changeView(viewName: string) {
    this.viewName = viewName;
    this.viewNameChange.next(viewName);
  }

  onSortChange(value: string) {
    this.router.navigate([], {
      queryParams: { sort: value, size: null },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onOrderChange(value: string) {
    this.router.navigate([], {
      queryParams: { order: value, size: null },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
