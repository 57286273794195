import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input,
  OnChanges, SimpleChanges, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { QueryField, QueryFormValue, QueryFormValueType } from '../services/query-builder.service';

export interface FieldInputOption {
  value: string;
  display: string;
}

export type FieldInputOptions = {[key: string]: FieldInputOption[]};

export type FieldInputCapabilities = { [key: string]: QueryFormValueType[] };

export const DEFAULT_FIELD_VALUE_INPUT_CAPABILITIES: QueryFormValueType[] = [
  'is',
  'is_not'
];

const TYPE_SELECT_LABELS = new Map<QueryFormValueType, string>([
  ['is', 'Is'],
  ['is_not', 'Is not'],
  ['is_after', 'Is after'],
  ['is_more', 'Is more than'],
  ['is_before', 'Is before'],
  ['is_less', 'Is less than'],
  ['range', 'In range'],
  ['boolean', 'Boolean'],
  ['exists', 'Exists'],
  ['date', 'Is'],
  ['date_after', 'Is after'],
  ['date_before', 'Is before'],
]);

@Component({
  selector: 'query-field-value',
  templateUrl: './query-field-value.component.html',
  styleUrls: ['./query-field-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QueryFieldValueComponent),
      multi: true
    }
  ]
})
export class QueryFieldValueComponent implements AfterViewInit, OnChanges, ControlValueAccessor {
  @Input()
  field: QueryField;

  @Input()
  fieldInputOptions: FieldInputOptions = {};

  @Input()
  fieldInputCapabilities: FieldInputCapabilities = {};

  @ViewChild('focusInput')
  focusInput: ElementRef;

  value: QueryFormValue;
  onChange: (value: QueryFormValue) => any;
  onTouched: () => any;

  ngAfterViewInit() {
    this.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('field' in changes) {
      this.focus();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: QueryFormValue) {
    this.value = value;
  }

  getCapabilities(): QueryFormValueType[] {
    return this.fieldInputCapabilities[this.field.key] || DEFAULT_FIELD_VALUE_INPUT_CAPABILITIES || [];
  }

  getCapabilityLabel(key: QueryFormValueType): string {
    return TYPE_SELECT_LABELS.get(key);
  }

  getFieldOptions() {
    return this.fieldInputOptions[this.field.key];
  }

  selectDate(date: NgbDateStruct) {
    if (date && date.year && date.month && date.day) {
      this.value.input_text = [date.year, date.month, date.day].join('-');
    }
    this.onChange(this.value);
    this.onTouched();
  }

  focus() {
    setTimeout(() => this.focusInput?.nativeElement.focus());
  }
}
