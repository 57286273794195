import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SharedService } from '../../common';
import { SearchNavigationFiltersService } from '../services/search-navigation-filters.service';
import { IFormValueChange, SearchNavigationFiltersComponent } from './search-navigation-filters.component';
import { DialogService } from 'src/app/common/services/dialog.service';
import { SearchNavigationQueryService } from '../services/search-navigation-query.service';
import { IDataFiltersResult } from '../services/data-filters-api.service';
import { DataSearchService } from 'src/app/search-data';

export enum BracketType {
  Round = 'round',
  Square = 'square',
  None = 'none'
}

@Component({
  selector: 'search-navigation',
  templateUrl: './search-navigation.component.html',
  styleUrls: ['./search-navigation.component.scss']
})
export class SearchNavigationComponent implements OnInit {
  results = 142235748; // TODO: this shouldn't be hardcoded
  initialValues: IDataFiltersResult[];

  @ViewChild(SearchNavigationFiltersComponent)
  formComponent: SearchNavigationFiltersComponent;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public shared: SharedService,
    public navigationFilters: SearchNavigationFiltersService,
    public navigationQuery: SearchNavigationQueryService,
    public dataSearch: DataSearchService,
    public dialog: DialogService
  ) { }

  ngOnInit() {
    this.dataSearch.filters$.subscribe((filters) => {
      // first filters will always be null, as they are dynamically pulled from the server and this is a BehaviorSubject
      if (!filters) {
        return;
      }

      setTimeout(() => this.mapParamsToFormData(), 0);
      this.initialValues = filters;
    });

    this.route.queryParams.subscribe(() => {
      if (!this.formComponent?.form) {
        return;
      }

      // afterViewInit needs to trigger for this
      setTimeout(() => this.mapParamsToFormData(), 0);
    });
  }

  isRouteActive(route: string): boolean {
    return this.router.url.startsWith(route);
  }

  openCustomizeFiltersModal() {
    this.dialog.openDialog(['customize-filters'], {});
  }

  mapParamsToFormData() {
    const query = this.route.snapshot.queryParamMap.get('query');

    if (!query && this.formComponent?.form) {
      this.formComponent.clearValues();

      return;
    }

    this.navigationQuery.mapQueryToFormData(query, this.formComponent.form.value as IDataFiltersResult[], this.formComponent.form.controls);
  }

  mapFormDataToParams({ oldValue, newValue }: IFormValueChange) {
    let query = this.route.snapshot.queryParamMap.get('query') || '';

    query = this.navigationQuery.mapFormDataToQuery({ oldValue, newValue }, query);

    return {
      ...this.route.snapshot.queryParams,
      query,
    };
  }

  onFormChange(valueChanges: IFormValueChange) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.mapFormDataToParams(valueChanges),
        replaceUrl: true,
      }
    );
  }
}
