<div class="landing-container">
  <div class="landing-wrapper">
    <div class="title-wrapper">
      <h1 class="title">Search</h1>
      <span class="papers-label powered-by-text">Powered by</span>
      <common-icon-dimensions class="powered-by-company"></common-icon-dimensions>
    </div>

    <p class="description">
      <span class="papers-label">Full text search of the world&acute;s largest linked research database.</span>
      <a target="_" href="https://support.papersapp.com/support/solutions/articles/30000023243-what-is-the-readcube-papers-browser-extension-">Learn more</a>
    </p>

    <div class="search-wrapper">
      <fa-icon [icon]="['fas', 'search']"></fa-icon>
      <input
        #searchInput
        type="text"
        name="query"
        [ngModel]="query"
        (ngModelChange)="onChange($event)"
        (keydown.enter)="onSearchEnterKey($event)"
        class="form-control papers-label search"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        placeholder="Search record name or metadata">
    </div>

    <div class="radio-container">
      <label class="radio-wrapper" *ngFor="let queryType of searchService.queryTypes">
        <input type="radio" name="queryType" [value]="queryType.key" [(ngModel)]="selectedQueryType">
        <span class="text papers-label">{{queryType.display}}</span>
        <span class="checkmark"></span>
      </label>
    </div>

    <button
      #searchBtn
      type="button"
      class="btn btn-primary search-btn"
      routerLink="/search/query"
      [disabled]="isBtnDisabled"
      [queryParams]="{ type: selectedQueryType, query }">
        Search
    </button>
  </div>

</div>
