import { NEVER, Observable, ReplaySubject, Subject } from 'rxjs';
import { startWith, scan } from 'rxjs/operators';
import { IImportJob, IImportJobSnapshot, STATUS_FLAG } from './importer.service';
import * as uuid from 'uuid';

export class ImportUnsupportedJob implements IImportJob {
  public id: string;
  public name: string;
  public detail: string;
  public inProgress$ = new ReplaySubject<boolean>(1);
  public completed$ = new Subject<boolean>();
  public status$: Observable<STATUS_FLAG>;
  public progress$: Observable<number>;
  public errors$: Observable<string[]>;
  public parserError$ = NEVER;
  public snapshot: IImportJobSnapshot;

  protected statusSource = new ReplaySubject<STATUS_FLAG>(1);
  protected progressSource = new ReplaySubject<number>(1);
  protected errorsSource = new ReplaySubject<string[]>(1);

  constructor(
    public file: File,
    public collectionId: string,
    public listId?: string,
    public itemId?: string,
  ) {
    this.id = uuid.v4();
    this.name = file.name;
    this.snapshot = { errors: [], progress: 0, status: 'queued' };
    this.status$ = this.statusSource.pipe(
      startWith(<STATUS_FLAG>'queued')
    );
    this.progress$ = this.progressSource.asObservable();
    this.errors$ = this.errorsSource.pipe(
      scan((acc, val) => acc.concat(val))
    );
  }

  run(): Promise<void> {
    this.setProgress(100);
    this.setError('Invalid file format.');
    this.setStatus('failed');
    return Promise.resolve();
  }

  stop() {
  }

  protected setStatus(status: STATUS_FLAG) {
    this.snapshot.status = status;
    this.statusSource.next(status);
    this.inProgress$.next(false);
    this.statusSource.complete();
    this.progressSource.complete();
    this.errorsSource.complete();
    this.inProgress$.complete();
    this.completed$.complete();
  }

  protected setProgress(progress: number) {
    this.progressSource.next(progress);
    this.snapshot.progress = progress;
  }

  protected setError(err: string) {
    this.errorsSource.next([err]);
    this.snapshot.errors.push(err);
  }
}
