import { Injectable } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { map, mergeMap, startWith, scan, filter, tap } from 'rxjs/operators';

import { DataSmartListApiService, IDataSmartListQueryParams, IDataSmartList } from './data-smartlist-api.service';

const CMP_LIST = (a: IDataSmartList, b: IDataSmartList) => a.collection_id === b.collection_id && a.id === b.id;

@Injectable()
export class DataSmartListService {
  protected update$ = new Subject<IDataSmartList[]>();
  protected concat$ = new Subject<IDataSmartList[]>();

  constructor(
    public api: DataSmartListApiService
  ) { }

  create(list: Partial<IDataSmartList>): Promise<IDataSmartList> {
    return this.api.post(list).then(list => this.pushDownstream(list).pop());
  }

  query(params: IDataSmartListQueryParams): Observable<IDataSmartList[]> {
    let update = false;
    const notify$ = this.api.listen(params.collection_id);
    const query$ = this.api.query(params);
    const reload$ = notify$.pipe(mergeMap(() => query$));
    return merge(query$, reload$).pipe(
      mergeMap(result => merge(
        this.update$.pipe(tap(() => update = true)),
        this.concat$.pipe(tap(() => update = false))
      ).pipe(
        startWith(result.rows),
        map(lists => lists.filter(l => l.collection_id === params.collection_id)),
        scan((acc, val) => this.accumulator(acc, val, update))
      ))
    );
  }

  get(collectionId: string, listId: string): Observable<IDataSmartList> {
    const get$ = this.api.get(collectionId, listId);
    const notify$ = this.api.listen(collectionId, listId);
    const reload$ = notify$.pipe(filter(data => data.pop().action === 'updated'), mergeMap(() => get$));
    const update$ = this.update$.pipe(
      map(lists => lists.filter(i => i.collection_id === collectionId && i.id === listId)),
      filter(lists => lists.length > 0),
      map(lists => lists[0])
    );
    return merge(get$, reload$).pipe(
      mergeMap(item => update$.pipe(
        startWith(item)
      ))
    );
  }

  update(list: Partial<IDataSmartList>): Promise<IDataSmartList> {
    return this.api.patch(list)
      .then(list => this.pushDownstream(list, true).pop());
  }

  delete(collectionId: string, listId: string): Promise<IDataSmartList> {
    return this.api.delete(collectionId, listId)
      .then(list => this.pushDownstream(list, true).pop());
  }

  share(collectionId: string, listId: string): Observable<IDataSmartList> {
    return this.api.share(collectionId, listId).pipe(
      tap(list => this.pushDownstream(list, true))
    );
  }

  stopShare(collectionId: string, listId: string): Observable<IDataSmartList> {
    return this.api.stopShare(collectionId, listId).pipe(
      tap(list => this.pushDownstream(list, true))
    );
  }

  pushDownstream(lists: IDataSmartList | IDataSmartList[], update: boolean = false): IDataSmartList[] {
    lists = Array.isArray(lists) ? lists : [lists];
    if (update) {
      this.update$.next(lists);
    } else {
      this.concat$.next(lists);
    }
    return lists;
  }

  protected accumulator(acc: IDataSmartList[], val: IDataSmartList[], update = false): IDataSmartList[] {
    if (update) {
      return acc.map(a => val.find(b => CMP_LIST(a, b)) || a).filter(a => !a.deleted);
    }
    return acc.concat(acc.length ? val.filter(a => !acc.find(b => CMP_LIST(a, b))) : val);
  }
}
