import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';
import { ReadCubeDragAndDropModule } from '@readcube/rcp-drag-and-drop';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';

import { firstValueFrom } from 'rxjs';
import { TrackJS, TrackJSErrorPayload } from 'trackjs';
import * as uuid from 'uuid';

import { AppBulkModule } from './bulk';
import { AppCommonModule } from './common';
import { AppCommonDataModule, DataUserService } from './common-data';
import { AppLibraryDataModule } from './library-data';
import { AppSearchModule } from './search';
import { AppImporterModule } from './importer';
import { AppExporterModule } from './exporter';
import { AppExtensionModule } from './extension';
import { AppFullTextModule } from './full-text';
import { AppNavigationModule } from './navigation';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AppShellService } from './app-shell.service';
import { AppThemeService } from './app-theme.service';
import { AppStorageService } from './app-storage.service';
import { AppWebSocketService } from './app-websocket.service';
import { AppSessionService } from './app-session.service';
import { AppUpdateService } from './app-update.service';
import { AppHttpInterceptor } from './app-http-interceptor.service';
import { AppErrorHandler } from './app-error-handler.service';

import { environment } from 'environment';
import { BUILD_VERSION } from 'build';
import { APP_ICONS } from './app-icons';

import { DataUserApiService } from './common-data';
import { AppReviewDataModule } from './review-data';
import { AppSearchDataModule } from './search-data';
import { AppSearchNavigationModule } from './search-navigation';

export function sessionFactory(session: AppSessionService, dataUser: DataUserApiService): () => Promise<void> {
  const clientId = uuid.v4();
  return () => firstValueFrom(dataUser.get()).then(user => {
    // Create session object
    session.created = Date.now();
    session.clientId = clientId;
    session.userId = user.id;
    // Configure TrackJS
    if (environment.trackJsEnabled) {
      TrackJS.install({
        token: environment.trackJsToken,
        application: environment.trackJsApp,
        enabled: environment.trackJsEnabled,
        version: BUILD_VERSION,
        sessionId: clientId,
        userId: user.id,
        onError: onError
      });
    }
  });
}

function onError(payload: TrackJSErrorPayload): boolean {
  // Error originated in external resource.
  if (payload.message === 'Script Error.') {
    return false;
  }
  // Unauthorized users are redirected to login page.
  if (payload.message.includes('401 Unauthorized')) {
    return false;
  }
  // Users that perform write actions without permissions.
  if (payload.message.includes('403 Forbidden')) {
    return false;
  }
  // Ignore failed requests from this domain.
  if (payload.message.includes('metrics-api.dimensions.ai')) {
    return false;
  }
  // This is known HTTP error code that occours when user attempts to upload
  // file that is recognized by hash. Error has alredy been handled but this
  // prevents it from being tracked.
  if (payload.message.includes('422 Unprocessable Entity')) {
    return false;
  }
  if (payload.message.includes('422 ???')) {
    return false;
  }
  return true;
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,

    ReadCubeCommonModule,
    ReadCubeSvgIconsModule,
    ReadCubeDragAndDropModule.forRoot(),
    ReadCubeContextMenuModule.forRoot({ useBootstrap4: true }),

    AppRoutingModule,
    AppBulkModule,
    AppExtensionModule,
    AppSearchModule,
    AppNavigationModule,
    AppSearchNavigationModule.forRoot(),
    AppCommonModule.forRoot(),
    AppCommonDataModule.forRoot(),
    AppSearchDataModule.forRoot(),
    AppLibraryDataModule.forRoot(),
    AppReviewDataModule.forRoot(),
    AppImporterModule.forRoot(),
    AppExporterModule.forRoot(),
    AppFullTextModule.forRoot()
  ],
  providers: [
    AppShellService,
    AppThemeService,
    AppStorageService,
    AppSessionService,
    AppWebSocketService,
    AppUpdateService,
    {
      provide: APP_INITIALIZER,
      useFactory: sessionFactory,
      deps: [AppSessionService, DataUserService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      deps: [AppShellService, AppSessionService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    faConfig: FaConfig,
    faIconLibrary: FaIconLibrary
  ) {
    faConfig.defaultPrefix = 'fal';
    faIconLibrary.addIcons(...APP_ICONS);
  }
}
