import { Component, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { LibraryCollectionComponent } from './library-collection.component';

@Component({
  selector: 'library-collection-recent',
  templateUrl: './library-collection.component.html',
  styleUrls: ['./library-collection.component.scss'],
})
export class LibraryCollectionRecentComponent extends LibraryCollectionComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();

    this.viewFilters['recently_read'] = true;

    this.searchPlaceholder$ = this.collection$.pipe(
      map(collection => collection.name ? collection.name.concat('\'s ') : ''),
      map(name => ['Search within ', name.toLowerCase(), 'recently read...'].join('')),
      startWith('')
    );
  }
}
