<div class="header papers-border-bottom">
  <div class="btn-toolbar">
    <!-- Add reference menu -->
    <div class="btn-group me-1" ngbDropdown container="body" placement="bottom-left" *ngIf="collection?.user?.can_create_item">
      <button type="button" class="btn btn-secondary" ngbDropdownToggle>
        <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon> Add
      </button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button type="button" class="dropdown-item" (click)="openImportDialog()">Import Files...</button>
        <button type="button" class="dropdown-item" (click)="openImportIdentifiers()">From List of Identifiers</button>
        <button type="button" class="dropdown-item" (click)="createNewReference()">Create Manual Entry...</button>
        <button *ngIf="collection?.organization && shared.user?.licence?.import_from_search" type="button" class="dropdown-item" (click)="openImportSearch()">
          Import from Search...
        </button>
      </div>
    </div>

    <!-- Search box -->
    <div class="btn-group me-1 btn-group-sm search-box papers-border">
      <div class="input-group">
        <ng-template #searchResultTemplate let-r="result" let-t="term">
          <fa-icon [icon]="['fal', 'history']" *ngIf="r.type === 'history'"></fa-icon>
          <fa-icon [icon]="['fal', 'tag']" *ngIf="r.type === 'tag'"></fa-icon>
          &nbsp;<ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
        </ng-template>

        <div class="input-group-prepend search-query-button" ngbDropdown #filterDropdown="ngbDropdown" [autoClose]="false" placement="bottom-left" container="body">
          <button type="button" class="btn btn-outline-secondary" ngbDropdownToggle #filterToggleButton>
            <fa-icon [icon]="['fal', 'search']"></fa-icon>
          </button>

          <!-- TODO: remove when WEBAPP-1820 -->
          <!-- <div class="dropdown-menu" ngbDropdownMenu #filterDropdownMenu>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, 'author:&quot;(CARET)&quot;')">Author</button>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, 'title:&quot;(CARET)&quot;')">Title</button>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, 'journal:&quot;(CARET)&quot;')">Journal</button>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, 'year:(CARET)')">Year</button>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, 'notes:&quot;(CARET)&quot;')">Notes</button>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, 'citekey:&quot;(CARET)&quot;')">Citekey</button>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, 'tag:&quot;(CARET)&quot;')">Tag</button>
            <div class="dropdown-divider"></div>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, ' AND (CARET)')">AND</button>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, ' OR (CARET)')">OR</button>
            <button type="button" class="dropdown-item" (click)="onSearchKeyClick($event, ' NOT((CARET))')">NOT</button>
            <div class="dropdown-divider"></div>
            <button type="button" class="dropdown-item"
              (click)="shell.openURL('https://support.papersapp.com/support/solutions/articles/30000037532-advanced-searching-in-library')">
              Advanced Search Tips&nbsp;&nbsp;<fa-icon [icon]="['fal', 'external-link']"></fa-icon>
            </button>
          </div> -->
          <div class="dropdown-menu papers-scrollbar search-query-menu p-4" ngbDropdownMenu #filterDropdownMenu>
            <div class="modal-header">
              <button type="button" class="btn btn-close float-end" (click)="filterDropdown.close()"></button>
            </div>

            <div class="modal-body">
              <query-builder 
                [(ngModel)]="queryFormData"
                (ngModelChange)="updateQuery($event)"
                [fields]="fields"
                [customFields]="customFields"
                [fieldInputCapabilities]="fieldInputCapabilities"
                [fieldDefaultInputCapabilities]="fieldDefaultInputCapabilities"
                [fieldInputOptions]="fieldInputOptions">
              </query-builder>
            </div>

            <div class="modal-footer mt-4">
              <button type="button" class="btn btn-link me-auto float-start" (click)="shell.openURL('https://support.papersapp.com/support/solutions/articles/30000037532-advanced-searching-in-library')">
                <fa-icon [icon]="['fas', 'info-circle']"></fa-icon> Advanced Searching
              </button>
              <button type="button" class="btn btn-primary float-end" (click)="filterDropdown.close(); search()" *ngIf="query">Search</button>
            </div>
          </div>
        </div>

        <input
          #searchInput
          #searchTypeahead="ngbTypeahead"
          type="text"
          name="query"
          class="form-control"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          [(ngModel)]="query"
          [ngbTypeahead]="searchTypeaheadFilter"
          [resultTemplate]="searchResultTemplate"
          [focusFirst]="false"
          (keydown.enter)="onSearchEnterKey($event)"
          (keydown.tab)="onSearchTabKey($event)"
          (selectItem)="onSearchSelectItem($event)"
          (click)="searchClick$.next($any($event).target.value)"
          (blur)="searchTypeahead.dismissPopup()"
          [placeholder]="searchPlaceholder">
          <div *ngIf="query" ngbDropdown placement="bottom-right" container="body">
            <button *ngIf="collection?.user?.can_manage_list" type="button" class="btn btn-outline-secondary no-caret" ngbDropdownToggle>
              <fa-icon [icon]="['fal', 'caret-circle-down']"></fa-icon>
            </button>
            <button type="button" class="btn btn-outline-secondary" (click)="onSearchClearClick($event)">
              <fa-icon [icon]="['fal', 'times-circle']"></fa-icon>
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button type="button" class="dropdown-item" (click)="onSearchSaveClick($event)" [disabled]="!collection?.user?.can_manage_smartlist">Create SmartList</button>
            </div>
          </div>
      </div>
    </div>

    <!-- Sorting options menu -->
    <div class="btn-group me-1" ngbDropdown container="body" placement="bottom-right">
      <button type="button" class="btn btn-secondary" ngbDropdownToggle>
        <fa-icon [icon]="['fal', order === 'asc' ? 'sort-alpha-down' : 'sort-alpha-up']"></fa-icon>
      </button>

      <div class="dropdown-menu dropdown-menu-left papers-scrollbar" ngbDropdownMenu>
        <button *ngFor="let f of sortOptions"
          type="button" class="dropdown-item"
          [ngClass]="{ 'checked': sort == f.key }"
          (click)="onSortChange(f.key)">{{f.display}}</button>

        <ng-container *ngIf="customFields?.length">
          <div class="dropdown-divider"></div>

          <button *ngFor="let f of customFields"
            type="button" class="dropdown-item"
            [ngClass]="{ 'checked': sort == f.key }"
            (click)="onSortChange(f.key)">{{f.display}}</button>
        </ng-container>

        <div class="dropdown-divider"></div>

        <button type="button" class="dropdown-item"
          *ngFor="let opt of orderOptions"
          [ngClass]="{ 'checked': order == opt.key }"
          (click)="onOrderChange(opt.key)">{{opt.display}}</button>
      </div>
    </div>

    <div class="btn-group">
      <button
        type="button"
        class="btn"
        (click)="changeView('list')"
        [ngClass]="{
          'btn-primary': viewName == 'list',
          'btn-secondary': viewName === 'table'
        }">
        <fa-icon [icon]="['fal', 'table']"></fa-icon>
      </button>
      <button
        type="button"
        class="btn"
        (click)="changeView('table')"
        [ngClass]="{
          'btn-primary': viewName == 'table',
          'btn-secondary': viewName === 'list'
        }">
        <fa-icon [icon]="['fal', 'list']"></fa-icon>
      </button>
    </div>
  </div>
</div>
