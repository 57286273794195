import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from '../../common';

@Component({
  templateUrl: './modal-filtered-list.component.html'
})
export class ModalFilteredListComponent {

  query: string;
  searchDimensions: boolean = false;
  showResultCount: boolean = false;
  resultSublistName: string;
  unsearchableSublistName: string;

  constructor(
    public activeModal: NgbActiveModal,
    public shared: SharedService
  ) {
    this.searchDimensions = this.shared.user?.licence?.fulltext_search_via_dimensions;
  }

  submit() {
    this.activeModal.close({
      confirmed: true,
      showResultCount: this.showResultCount,
      searchDimensions: this.searchDimensions,
      resultSublistName: this.resultSublistName,
      query: this.query,
      unsearchableSublistName: this.unsearchableSublistName
    });
  }

  cancel() {
    this.activeModal.close({ confirmed: false });
  }
}
