<rcp-svg-definitions></rcp-svg-definitions>

<div class="dialog papers-scrollbar" [ngClass]="{ 'open': dialogOpen$ | async}">
  <div class="inner">
    <router-outlet name="dialog"></router-outlet>
  </div>
</div>

<div class="navigation">
  <navigation></navigation>
</div>

<div class="content">
  <router-outlet></router-outlet>
</div>
