import { Directive, Input, HostListener } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Directive({
  selector: '[navigationButton]'
})
export class NavigationButtonDirective {
  @Input()
  drawerLink: string;

  constructor(
    public navigationService: NavigationService
  ) { }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.navigationService.openDrawer(this.drawerLink);
  }

  @HostListener('click')
  onMouseClick() {
    this.navigationService.closeDrawer();
  }
}
