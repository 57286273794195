import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ListNode, ListTreeService, SharedService } from '../../common';
import { LibraryService } from '../../library';
import { DataCollectionService, DataListService, IDataCollection, IDataItem } from '../../library-data';
import { LibrarySidepanelService } from '../services/library-sidepanel.service';
import { environment } from 'environment';

@Component({
  templateUrl: './modal-edit-lists.component.html',
  styleUrls: ['./modal-edit-lists.component.scss']
})
export class ModalListsEditorComponent implements OnInit {
  @Input()
  collection: IDataCollection;

  @Input()
  item: IDataItem;

  protected lists$: Observable<ListNode[]>;
  protected collection$: Observable<IDataCollection>;
  protected loadingLists$ = new ReplaySubject<boolean>(1);
  protected isPrivateCollection: boolean;
  protected maxListsDepth: number = environment.maxListsDepth;

  constructor(
    public dataCollection: DataCollectionService,
    public dataList: DataListService,
    public activeModal: NgbActiveModal,
    public listTree: ListTreeService,
    public library: LibraryService,
    public sidepanel: LibrarySidepanelService,
    public shared: SharedService,
    public elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.loadingLists$.next(true);

    this.lists$ = this.dataList.getAllByCollectionId(this.collection.id).pipe(
      tap(() => this.loadingLists$.next(false)),
      map(lists => this.listTree.fromLists(lists)),
      map(root => root.children)
    );

    this.collection$ = this.dataCollection.get(this.item.collection_id).pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.isPrivateCollection = !this.collection.shared && (this.collection.status === 'active');
  }

  openCreateList() {
    this.toggleModalDisplay(false);

    this.sidepanel.openEditList({
      collection_id: this.item.collection_id
    }).then(list => {
      this.library.copyItems([this.item], { collectionId: this.item.collection_id, listId: list.id })
      this.toggleModalDisplay(true);
    }).catch(() => {
      this.toggleModalDisplay(true);
    });
  }

  addToList(listId: string) {
    this.library.copyItems([this.item], { collectionId: this.collection.id, listId });
  }

  removeFromList(listId: string) {
    this.library.removeFromList(this.collection.id, listId, [this.item]);
  }

  close() {
    this.activeModal.close();
  }

  private toggleModalDisplay(isVisible: boolean) {
    (<HTMLElement>this.elementRef.nativeElement).style.display = isVisible ? 'block' : 'none';
  }
}
