<div class="form-floating">
  <select name="collection-select" class="form-select"
    [(ngModel)]="collection"
    (ngModelChange)="collectionChange.next(collection)"
    required>
    <option [ngValue]="c" *ngFor="let c of collections$ | async">{{c.name || 'My Papers'}}</option>
  </select>
  <label for="collection-select">From library</label>
</div>


<div class="form-floating mt-3" *ngIf="list">
  <select name="list-select" class="form-select"
    [(ngModel)]="list"
    (ngModelChange)="listChange.next(list)">
    <option [ngValue]="null">(none)</option>
    <option [ngValue]="l" *ngFor="let l of lists$ | async">{{l.name}}</option>
  </select>
  <label for="list-select">From list</label>
</div>
