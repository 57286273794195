import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataCitationApiService } from './services/data-citation-api.service';
import { DataMentionApiService } from './services/data-mention-api.service';
import { DataMetricsApiService } from './services/data-metrics-api.service';

import { DataMetricsService } from './services/data-metrics.service';
import { DataCitationService } from './services/data-citation.service';
import { DataMentionService } from './services/data-mention.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DataCitationApiService,
    DataMentionApiService,
    DataMetricsApiService,
  ]
})
export class AppMetricsDataModule {
  static forRoot(): ModuleWithProviders<AppMetricsDataModule> {
    return {
      ngModule: AppMetricsDataModule,
      providers: [
        DataMetricsService,
        DataCitationService,
        DataMentionService,
      ]
    };
  }
}
