<ng-container *ngIf="results$ | async as results; else formTemplate">
  <ng-container *ngIf="resultCount > 0; else formTemplate">
    <div class="wrapper papers-scrollbar">
      <h5 class="header">Possible matches...</h5>
      <h6>Please pick one of the results from below that matches your reference!</h6>

      <div class="list-group-item list-group-item-action flex-column align-items-start"
        *ngFor="let result of results" (click)="confirmMatch(result)">
        <div class="d-flex w-100 justify-content-between">
          <span class="title">{{result.title}}</span>
        </div>
        <div class="d-flex w-100 justify-content-between" *ngIf="result.patent_id">
          <span class="sub-title">Patent ID: {{result.patent_id}}</span>
        </div>
        <div class="d-flex w-100 justify-content-between" *ngIf="result.doi">
          <span class="sub-title">DOI: {{result.doi}}</span>
        </div>
        <div class="d-flex w-100 justify-content-between" *ngIf="result.pmid">
          <span class="sub-title">PMID: {{result.pmid}}</span>
        </div>
        <div class="authors">{{getAuthorNames(result.authors)}}</div>
        <div class="abstract text-muted" *ngIf="result.abstract">{{result.abstract | slice:0:500}}{{result.abstract.length > 500 ? '...' : ''}}</div>
        <div class="journal text-muted" *ngIf="result.journal || result.year">{{result.journal}} {{result.year}}</div>
      </div>
    </div>

    <div class="footer">
      <button type="button" (click)="clearResults()" class="btn btn-primary w-100">
        <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon> Review metadata
      </button>
    </div>
  </ng-container>
</ng-container>



<ng-template #formTemplate>
  <div class="wrapper papers-scrollbar" *ngIf="form">
    <h5 class="header">Review metadata...</h5>

    <div class="mt-2" *ngIf="canUsePatent()">
      <label for="title_input">Type:</label>
      <select name="type-select" class="form-control" [(ngModel)]="formType" (ngModelChange)="onFormTypeChange($event)">
        <option [value]="f.key" *ngFor="let f of formTypes">{{f.value}}</option>
      </select>
    </div>

    <ng-container [ngSwitch]="formType">
      <form (ngSubmit)="submit()" [formGroup]="form" *ngSwitchCase="'journal_article'">
        <p>Use your cursor to select data from the PDF preview pane. After selecting text, you will have option to save that data to one of the following fields.</p>
        <p><strong>Tip:</strong> Try matching first with just a portion of the title and one last name of an author - that should be all you need for a match!</p>

        <div class="row mt-2">
          <div class="col-md-12">
            <label for="title_input">Title:</label>
            <input type="text"
              class="form-control"
              id="title_input"
              placeholder="Enter article title..."
              formControlName="title">
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <label for="authors_input">Authors:</label>
            <textarea type="text"
              class="form-control"
              id="authors_input"
              placeholder="Enter article authors..."
              formControlName="authors">
            </textarea>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-8">
            <label for="journal_input">Journal:</label>
            <input type="text"
              class="form-control"
              id="journal_input"
              placeholder="Enter journal..."
              formControlName="journal">
          </div>

          <div class="col-md-4">
            <label for="year_input">Year:</label>
            <input type="text"
              class="form-control"
              id="year_input"
              placeholder="Enter year..."
              formControlName="year">
            <small class="form-text text-muted" *ngIf="!form.controls['year'].valid">Invalid year format.</small>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-8">
            <label for="doi_input">Digital Object Identifier:</label>
            <input type="text"
              class="form-control"
              id="doi_input"
              placeholder="Enter DOI..."
              formControlName="doi">
            <small class="form-text text-muted" *ngIf="!form.controls['doi'].valid">Invalid DOI format.</small>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-8">
            <label for="isbn_input">International Standard Book Number:</label>
            <input type="text"
              class="form-control"
              id="isbn_input"
              placeholder="Enter ISBN..."
              formControlName="isbn">
            <small class="form-text text-muted" *ngIf="!form.controls['isbn'].valid">Invalid ISBN format.</small>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-8">
            <label for="pmid_input">PubMed:</label>
            <input type="text"
              class="form-control"
              id="pmid_input"
              placeholder="Enter PMID..."
              formControlName="pmid">
            <small class="form-text text-muted" *ngIf="!form.controls['pmid'].valid">Invalid PMID format.</small>
          </div>
        </div>
      </form>

      <form (ngSubmit)="submit()" [formGroup]="form" *ngSwitchCase="'patent'">
        <div class="row mt-2">
          <div class="col-md-12">
            <label for="patent_id_input">Patent ID:</label>
            <input type="text"
              class="form-control"
              id="patent_id_input"
              placeholder="Enter patent id..."
              formControlName="patent_id">
          </div>
        </div>
      </form>
    </ng-container>
  </div>

  <div class="footer" *ngIf="form">
    <ng-container *ngIf="!(loading$ | async); else searchingTemplate">
      <div class="alert alert-danger text-center" role="alert" *ngIf="form.invalid">
        Please update the invalid fields and try again.
      </div>

      <div class="alert alert-warning text-center" role="alert" *ngIf="resultCount === 0">
        <strong>No matches were found.</strong><br>Please edit the metadata and try again.
      </div>

      <button type="button" (click)="submit()" class="btn btn-primary w-100" [disabled]="form.invalid">Find match</button>
    </ng-container>
  </div>
</ng-template>



<ng-template #searchingTemplate>
  <button type="button" class="btn btn-primary w-100" disabled>Searching...</button>
</ng-template>
