import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AppShellService } from '../../app-shell.service';
import { DataCollectionService, DataListService, DataSmartListService,
  IDataCollection, IDataList, IDataSmartList } from '../../library-data';

import { environment } from 'environment';

const ENTITY_TYPE = {
  COLLECTION: 'collection',
  SMARTLIST: 'smartlist',
  LIST: 'list'
};

const ERROR_MESSAGE = `Something went wrong. If you continue to experience problems please contact support.`;

@Component({
  templateUrl: './modal-share.component.html',
  styleUrls: ['./modal-share.component.scss']
})
export class ModalShareComponent {
  @Input()
  entity: IDataCollection | IDataSmartList | IDataList;

  @Input()
  type: 'collection' | 'smartlist' | 'list';

  name: string;
  url: string;

  error$: Observable<string>;
  errorSubject = new Subject<string>();

  loading$: Subject<boolean> = new Subject();
  public$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public activeModal: NgbActiveModal,
    public dataList: DataListService,
    public dataCollection: DataCollectionService,
    public dataSmartlist: DataSmartListService,
    public shell: AppShellService
  ) {
    this.error$ = this.errorSubject.asObservable();
  }

  ngOnInit() {
    this.name = (this.type === ENTITY_TYPE.COLLECTION && !(<IDataCollection>this.entity).shared) ? 'My Papers' : this.entity.name;

    if (!!this.entity?.public_id) {
      this.public$.next(true);
      this.url = `${environment.baseUrls.publicLists}/${this.entity.public_id}`;
    }
  }

  onShareClick() {
    this.loading$.next(true);
    this.errorSubject.next('');

    switch (this.type) {
      case ENTITY_TYPE.COLLECTION:
        this.shareCollection();
        break;
      case ENTITY_TYPE.SMARTLIST:
        this.shareSmartlist();
        break;
      case ENTITY_TYPE.LIST:
        this.shareList();
        break;
    }
  }

  shareCollection() {
    this.dataCollection.share((<IDataCollection>this.entity).id).subscribe(collection => {
      this.url = `${environment.baseUrls.publicLists}/${collection.public_id}`;
      this.loading$.next(false);
      this.public$.next(true);
    }, res => {
      this.loading$.next(false);
      this.handleError(res);
    });
  }

  shareSmartlist() {
    this.dataSmartlist.share((<IDataSmartList>this.entity).collection_id, (<IDataSmartList>this.entity).id).subscribe(smartlist => {
      this.url = `${environment.baseUrls.publicLists}/${smartlist.public_id}`;
      this.loading$.next(false);
      this.public$.next(true);
    }, res => {
      this.loading$.next(false);
      this.handleError(res);
    });
  }

  shareList() {
    this.dataList.share((<IDataList>this.entity).collection_id, (<IDataList>this.entity).id).subscribe(list => {
      this.url = `${environment.baseUrls.publicLists}/${list.public_id}`;
      this.loading$.next(false);
      this.public$.next(true);
    }, res => {
      this.loading$.next(false);
      this.handleError(res);
    });
  }

  onStopClick() {
    this.loading$.next(true);
    this.errorSubject.next('');

    switch (this.type) {
      case ENTITY_TYPE.COLLECTION:
        this.stopShareCollection();
        break;
      case ENTITY_TYPE.SMARTLIST:
        this.stopShareSmartlist();
        break;
      case ENTITY_TYPE.LIST:
        this.stopShareList();
        break;
    }
  }

  stopShareCollection() {
    this.dataCollection.stopShare((<IDataCollection>this.entity).id).subscribe(data => {
      this.loading$.next(false);
      this.close();
    }, res => {
      this.loading$.next(false);
      this.handleError(res);
    });
  }

  stopShareSmartlist() {
    this.dataSmartlist.stopShare((<IDataSmartList>this.entity).collection_id, (<IDataSmartList>this.entity).id).subscribe(data => {
      this.loading$.next(false);
      this.close();
    }, res => {
      this.loading$.next(false);
      this.handleError(res);
    });
  }

  stopShareList() {
    this.dataList.stopShare((<IDataList>this.entity).collection_id, (<IDataList>this.entity).id).subscribe(data => {
      this.loading$.next(false);
      this.close();
    }, res => {
      this.loading$.next(false);
      this.handleError(res);
    });
  }

  private handleError(res: any) {
    if (res.status === 404) {
      this.errorSubject.next(ERROR_MESSAGE);
    } else {
      this.errorSubject.next(res.error?.error);
    }
  }

  copyUrl() {
    this.shell.copyToClipboard({ text: this.url });
  }

  close() {
    this.activeModal.close();
  }
}
