import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import AnnotationPanel from '@readcube/annotation-panel';

import { SharedService, ReaderService } from '../../common';
import { DataItemService, IDataItem, IDataItemAnnotation, IDataItemFile } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { LibrarySidepanelService } from '../services/library-sidepanel.service';

@Component({
  templateUrl: './library-sidepanel-notes.component.html',
  styleUrls: [
    './library-sidepanel.component.scss',
    './library-sidepanel-notes.component.scss'
  ]
})
export class LibrarySidepanelNotesComponent implements OnInit, OnDestroy {
  item: IDataItem;
  canAnnotateItem = false;
  saving = false;
  loading = false;
  annotations: IDataItemAnnotation[] = [];
  itemSub: Subscription;
  collectionSub: Subscription;

  @ViewChild('notesForm', { static: false })
  formRef: NgForm;

  @ViewChild('noteInput', { static: false })
  noteInputRef: ElementRef<HTMLTextAreaElement>;

  @ViewChild('annotationsWidget', { static: true })
  annotationsWidget: ElementRef;

  noteChange$ = new Subject<string>();

  constructor(
    public router: Router,
    public dataItem: DataItemService,
    public sidepanel: LibrarySidepanelService,
    public reader: ReaderService,
    public shared: SharedService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    AnnotationPanel.init({
      container: this.annotationsWidget.nativeElement,
      historyKey: { key: this.shared.user.id }
    });
    AnnotationPanel.evtout.on('click-annotation', (e, annotation) => {
      this.readAnnotation(this.item, annotation);
    });
    AnnotationPanel.evtout.on('share-link', (e, annotation) => {
      this.shareAnnotation(this.item, annotation);
    });
    AnnotationPanel.evtout.on('delete-annotation', (e, annotation) => {
      this.deleteAnnotation(this.item, annotation);
    });
    AnnotationPanel.evtout.on('copy-text', (e, text) => {
      this.shell.copyToClipboard({ text });
    });

    this.itemSub = this.sidepanel.item$.subscribe(item => {
      if (this.formRef && this.formRef.dirty) {
        this.save();
      }
      if (!this.item || this.item.id !== item.id) {
        this.loadAnnotations(item);
        this.item = item;
      }
      setTimeout(() => this.resizeNote());
    });
    this.collectionSub = this.sidepanel.collection$.subscribe(collection => {
      this.canAnnotateItem = !!collection.user?.can_annotate_item;
      AnnotationPanel.setReadonly(!this.canAnnotateItem);
      AnnotationPanel.setShareable(collection.shared !== false);
    });
    this.noteChange$.pipe(
      tap(() => this.resizeNote()),
      debounceTime(1000)
    ).subscribe(() => this.save());
  }

  ngOnDestroy() {
    this.itemSub.unsubscribe();
    this.collectionSub.unsubscribe();
    AnnotationPanel.destroy();
  }

  loadAnnotations(item: IDataItem) {
    this.loading = true;
    AnnotationPanel.update({
      annotations: [],
      fulltext: null
    });
    this.dataItem.annotations(item.collection_id, item.id).then(annotations => {
      annotations = annotations.filter(a => a.sha256 === item.primary_file_hash);
      this.loading = false;
      this.annotations = annotations;
      AnnotationPanel.update({ annotations });
    });
  }

  readAnnotation(item: IDataItem, annotation: IDataItemAnnotation) {
    // TODO: to read annotations on supplement file needs to be provided
    const file: IDataItemFile = null;
    this.reader.viewItemPDF({ item, file, annotation });
  }

  shareAnnotation(item: IDataItem, annotation: IDataItemAnnotation) {
    // TODO: to read annotations on supplement file needs to be provided
    const file: IDataItemFile = null;
    const url = this.reader.getReadURL(item, file, annotation);
    this.shell.copyToClipboard({ text: url.replace(/^\/\//, 'https://') });
  }

  deleteAnnotation(item: IDataItem, annotation: IDataItemAnnotation) {
    this.dataItem.deleteAnnotation(this.item.collection_id, this.item.id, annotation.id).then(() => {
      const i = this.annotations.findIndex(a => a.id === annotation.id);
      this.annotations.splice(i, 1);
      AnnotationPanel.update({
        annotations: this.annotations,
        fulltext: null
      });
    });
  }

  resizeNote() {
    const el = this.noteInputRef.nativeElement;
    el.style.height = 'auto';
    el.style.height = el.scrollHeight + 5 + 'px';
  }

  save() {
    const notes = this.item.user_data.notes;
    this.saving = true;
    this.setFormPristine();
    this.sidepanel.setNotes(this.item, notes).finally(() => {
      this.saving = false;
      this.resizeNote();
    });
  }

  setFormPristine() {
    for (const name in this.formRef.controls) {
      if (this.formRef.controls[name]) {
        this.formRef.controls[name].markAsPristine();
      }
    }
  }
}
