import { Component,AfterContentInit, OnInit, OnDestroy, OnChanges, Input, Output, ViewChild, TemplateRef, EventEmitter, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { LoadNextEvent, ITableColumn } from '@readcube/rcp-data-view';
import { ReplaySubject, Subscription, tap } from 'rxjs';

import { AppShellService } from '../../app-shell.service';
import { AppStorageService } from '../../app-storage.service';
import { SharedService } from '../../common';
import { DataItemService, DataCollectionService, IDataCollection, IDataItem, IDataItemFile } from '../../library-data';
import { ExtensionService, ExtensionStatus } from '../../extension';

import default_type_schema from '@readcube/rcp-meta/type_schema.json';

@Component({
  selector: 'library-results-table',
  templateUrl: './library-results-table.component.html',
  styleUrls: ['./library-results-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryResultsTableComponent implements OnInit, OnDestroy, AfterContentInit, OnChanges {
  @Input()
  items: IDataItem[];

  @Input()
  collection: IDataCollection;

  @Input()
  organization = false;

  @Input()
  selected: IDataItem[];

  @Input()
  highlighted: IDataItem[];

  @Input()
  hasFocus = false;

  @Input()
  loading = false;

  @Input()
  finished = false;

  @Input()
  sort: string;

  @Input()
  order: string;

  @Input()
  disabled = false;

  @Input()
  multiselect = true;

  @Output()
  open = new EventEmitter<IDataItem>();

  @Output()
  selectedChange = new EventEmitter<IDataItem[]>();

  @Output()
  highlightedChange = new EventEmitter<IDataItem[]>();

  @Output()
  hasFocusChange = new EventEmitter<boolean>();

  @Output()
  loadNext = new EventEmitter<LoadNextEvent>();

  @ViewChild('colorLabelHeadTemplate', { static: true })
  colorLabelHeadTemplate: TemplateRef<any>;

  @ViewChild('flagHeadTemplate', { static: true })
  flagHeadTemplate: TemplateRef<any>;

  @ViewChild('fileHeadTemplate', { static: true })
  fileHeadTemplate: TemplateRef<any>;

  @ViewChild('colorLabelCellTemplate', { static: true })
  colorLabelCellTemplate: TemplateRef<any>;

  @ViewChild('flagCellTemplate', { static: true })
  flagCellTemplate: TemplateRef<any>;

  @ViewChild('fileCellTemplate', { static: true })
  fileCellTemplate: TemplateRef<any>;

  @ViewChild('authorsCellTemplate', { static: true })
  authorsCellTemplate: TemplateRef<any>;

  @ViewChild('lastAuthorCellTemplate', { static: true })
  lastAuthorCellTemplate: TemplateRef<any>;

  @ViewChild('typeCellTemplate', { static: true })
  typeCellTemplate: TemplateRef<any>;

  @ViewChild('titleCellTemplate', { static: true })
  titleCellTemplate: TemplateRef<any>;

  @ViewChild('libraryCellTemplate', { static: true })
  libraryCellTemplate: TemplateRef<any>;

  @ViewChild('journalCellTemplate', { static: true })
  journalCellTemplate: TemplateRef<any>;

  @ViewChild('yearCellTemplate', { static: true })
  yearCellTemplate: TemplateRef<any>;

  @ViewChild('ratingCellTemplate', { static: true })
  ratingCellTemplate: TemplateRef<any>;

  @ViewChild('notesCellTemplate', { static: true })
  notesCellTemplate: TemplateRef<any>;

  @ViewChild('createdDateCellTemplate', { static: true })
  createdDateCellTemplate: TemplateRef<any>;

  @ViewChild('modifiedDateCellTemplate', { static: true })
  modifiedDateCellTemplate: TemplateRef<any>;

  @ViewChild('createdByCellTemplate', { static: true })
  createdByCellTemplate: TemplateRef<any>;

  @ViewChild('lastReadDateCellTemplate', { static: true })
  lastReadDateCellTemplate: TemplateRef<any>;

  @ViewChild('patentIdCellTemplate', { static: true })
  patentIdCellTemplate: TemplateRef<any>;

  @ViewChild('patentPublicationDateCellTemplate', { static: true })
  patentPublicationDateCellTemplate: TemplateRef<any>;

  @ViewChild('patentFilingDateCellTemplate', { static: true })
  patentFilingDateCellTemplate: TemplateRef<any>;

  @ViewChild('copyrightStatusCellTemplate', { static: true })
  copyrightStatusCellTemplate: TemplateRef<any>;

  @ViewChild('customFieldCellTemplate', { static: true })
  customFieldCellTemplate: TemplateRef<any>;

  columns: ITableColumn[];
  collectionNames: Map<string, string>;
  collectionSub: Subscription;
  get storagePrefix() {
    return this.organization ? 'org-' : '';
  }

  constructor(
    public router: Router,
    public dataItem: DataItemService,
    public dataCollection: DataCollectionService,
    public extension: ExtensionService,
    public shared: SharedService,
    public shell: AppShellService,
    public storage: AppStorageService,
  ) { }

  ngOnInit() {
    if (this.organization) {
      this.collectionSub = this.dataCollection.query().pipe(tap(collections => {
        this.collectionNames = new Map(collections.map(c => [c.id, c.name || 'My Library']));
      })).subscribe();
    }
  }

  ngOnDestroy() {
    if (this.organization) {
      this.collectionSub.unsubscribe();
    }
  }

  ngAfterContentInit() {
    this.columns = this.getTableColumns();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sort || changes.order || changes.collection) {
      this.columns = this.getTableColumns();
    }
  }

  getExtensionStatus(itemId: string): ReplaySubject<ExtensionStatus> {
    return this.extension.downloads.get(itemId)?.status$;
  }

  onColumsChange(columns: ITableColumn[]) {
    columns.forEach(column => {
      const index = column.index >= 0 ? column.index : column.defaultIndex;
      const width = column.width >= column.minWidth ? column.width : column.defaultWidth;
      const hidden = column.hidden || false;
      this.setColumnIndex(column.id, index);
      this.setColumnWidth(column.id, width);
      this.setColumnHidden(column.id, hidden);
    });
  }

  onTableHeadClick(column: ITableColumn) {
    const paramMap: { [key: string]: string; } = {
      'colorLabel': 'color',
      'flag': 'flag',
      'file': 'file',
      'authors': 'first_author',
      'lastAuthor': 'last_author',
      'type': 'type',
      'title': 'title',
      'journal': 'journal',
      'year': 'year',
      'createdDate': 'created',
      'modifiedDate': 'modified',
      'lastReadDate': 'last_read',
      'notes': 'notes',
      'rating': 'rating',
      'copyrightStatus': 'copyright_status'
    };
    // Enable sorting by custom_fields.
    const customFields = this.collection?.custom_fields || [];
    for (let f of customFields) {
      paramMap[`customField_${f.field}`] = f.key;
    }
    if (!paramMap[column.id]) {
      return;
    }
    this.router.navigate([], {
      queryParams: {
        'sort': paramMap[column.id],
        'order': column.sorted ? column.sorted === 'asc' ? 'desc' : 'asc' : column.sorted
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onLoadNext(event: LoadNextEvent) {
    if (this.finished || this.loading) {
      return;
    }
    this.loadNext.emit(event);
  }

  getTableColumns() {
    return this.getDefaultTableColumns().filter(c => !c.disabled).concat(this.getCustomTableColumns());
  }

  getDefaultTableColumns(): ITableColumn[] {
    return [
      {
        id: 'colorLabel',
        title: 'Color Label',
        index: this.getColumnIndex('colorLabel', 0),
        width: this.getColumnWidth('colorLabel', 40),
        hidden: this.getColumnHidden('colorLabel', false),
        sorted: this.sort === 'color' ? this.order : undefined,
        defaultIndex: 0,
        defaultWidth: 40,
        defaultHidden: false,
        minWidth: 40,
        templateRef: this.colorLabelCellTemplate,
        headTemplateRef: this.colorLabelHeadTemplate
      },
      {
        id: 'flag',
        title: 'Flag',
        index: this.getColumnIndex('flag', 1),
        width: this.getColumnWidth('flag', 40),
        hidden: this.getColumnHidden('flag', true),
        sorted: this.sort === 'flag' ? this.order : undefined,
        defaultIndex: 1,
        defaultWidth: 40,
        defaultHidden: true,
        minWidth: 40,
        templateRef: this.flagCellTemplate,
        headTemplateRef: this.flagHeadTemplate
      },
      {
        id: 'file',
        title: 'File',
        index: this.getColumnIndex('file', 2),
        width: this.getColumnWidth('file', 40),
        hidden: this.getColumnHidden('file', false),
        sorted: this.sort === 'file' ? this.order : undefined,
        defaultIndex: 2,
        defaultWidth: 40,
        defaultHidden: false,
        minWidth: 40,
        templateRef: this.fileCellTemplate,
        headTemplateRef: this.fileHeadTemplate
      },
      {
        id: 'type',
        title: 'Type',
        index: this.getColumnIndex('type', 3),
        width: this.getColumnWidth('type', 150),
        hidden: this.getColumnHidden('type', true),
        sorted: this.sort === 'type' ? this.order : undefined,
        defaultIndex: 3,
        defaultWidth: 150,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.typeCellTemplate
      },
      {
        id: 'authors',
        title: 'Authors',
        index: this.getColumnIndex('authors', 4),
        width: this.getColumnWidth('authors', 150),
        hidden: this.getColumnHidden('authors', false),
        sorted: this.sort === 'first_author' ? this.order : undefined,
        defaultIndex: 4,
        defaultWidth: 150,
        defaultHidden: false,
        minWidth: 50,
        templateRef: this.authorsCellTemplate
      },
      {
        id: 'lastAuthor',
        title: 'Last Author',
        index: this.getColumnIndex('lastAuthor', 5),
        width: this.getColumnWidth('lastAuthor', 150),
        hidden: this.getColumnHidden('lastAuthor', false),
        sorted: this.sort === 'last_author' ? this.order : undefined,
        defaultIndex: 5,
        defaultWidth: 150,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.lastAuthorCellTemplate
      },
      {
        id: 'title',
        title: 'Title',
        index: this.getColumnIndex('title', 6),
        width: this.getColumnWidth('title', 300),
        hidden: this.getColumnHidden('title', false),
        sorted: this.sort === 'title' ? this.order : undefined,
        defaultIndex: 6,
        defaultWidth: 300,
        defaultHidden: false,
        minWidth: 50,
        templateRef: this.titleCellTemplate
      },
      {
        id: 'library',
        title: 'Library',
        index: this.getColumnIndex('library', 7),
        width: this.getColumnWidth('library', 150),
        hidden: this.getColumnHidden('library', false),
        sorted: undefined,
        defaultIndex: 7,
        defaultWidth: 150,
        defaultHidden: false,
        minWidth: 150,
        disabled: !this.organization,
        templateRef: this.libraryCellTemplate
      },
      {
        id: 'journal',
        title: 'Journal',
        index: this.getColumnIndex('journal', 8),
        width: this.getColumnWidth('journal', 200),
        hidden: this.getColumnHidden('journal', false),
        sorted: this.sort === 'journal' ? this.order : undefined,
        defaultIndex: 8,
        defaultWidth: 200,
        defaultHidden: false,
        minWidth: 50,
        templateRef: this.journalCellTemplate
      },
      {
        id: 'year',
        title: 'Year',
        index: this.getColumnIndex('year', 9),
        width: this.getColumnWidth('year', 70),
        hidden: this.getColumnHidden('year', false),
        sorted: this.sort === 'year' ? this.order : undefined,
        defaultIndex: 9,
        defaultWidth: 70,
        defaultHidden: false,
        minWidth: 40,
        templateRef: this.yearCellTemplate
      },
      {
        id: 'notes',
        title: 'Notes',
        index: this.getColumnIndex('notes', 10),
        width: this.getColumnWidth('notes', 200),
        hidden: this.getColumnHidden('notes', false),
        sorted: this.sort === 'notes' ? this.order : undefined,
        defaultIndex: 10,
        defaultWidth: 200,
        defaultHidden: true,
        minWidth: 100,
        templateRef: this.notesCellTemplate
      },
      {
        id: 'rating',
        title: 'Rating',
        index: this.getColumnIndex('rating', 11),
        width: this.getColumnWidth('rating', 100),
        hidden: this.getColumnHidden('rating', false),
        sorted: this.sort === 'rating' ? this.order : undefined,
        defaultIndex: 11,
        defaultWidth: 100,
        defaultHidden: false,
        minWidth: 60,
        templateRef: this.ratingCellTemplate
      },
      {
        id: 'createdDate',
        title: 'Date Added',
        index: this.getColumnIndex('createdDate', 12),
        width: this.getColumnWidth('createdDate', 170),
        hidden: this.getColumnHidden('createdDate', true),
        sorted: this.sort === 'created' ? this.order : undefined,
        defaultIndex: 12,
        defaultWidth: 170,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.createdDateCellTemplate
      },
      {
        id: 'modifiedDate',
        title: 'Date Modified',
        index: this.getColumnIndex('modifiedDate', 13),
        width: this.getColumnWidth('modifiedDate', 170),
        hidden: this.getColumnHidden('modifiedDate', true),
        sorted: this.sort === 'modified' ? this.order : undefined,
        defaultIndex: 13,
        defaultWidth: 170,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.modifiedDateCellTemplate
      },
      {
        id: 'createdBy',
        title: 'Created By',
        index: this.getColumnIndex('createdBy', 14),
        width: this.getColumnWidth('createdBy', 170),
        hidden: this.getColumnHidden('createdBy', true),
        //sorted: this.sort === 'created_by' ? this.order : undefined,
        defaultIndex: 14,
        defaultWidth: 170,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.createdByCellTemplate
      },
      {
        id: 'lastReadDate',
        title: 'Last Read',
        index: this.getColumnIndex('lastReadDate', 15),
        width: this.getColumnWidth('lastReadDate', 170),
        hidden: this.getColumnHidden('lastReadDate', true),
        sorted: this.sort === 'last_read' ? this.order : undefined,
        defaultIndex: 15,
        defaultWidth: 170,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.lastReadDateCellTemplate
      },
      {
        id: 'patentId',
        title: 'Patent ID',
        index: this.getColumnIndex('patentId', 16),
        width: this.getColumnWidth('patentId', 170),
        hidden: this.getColumnHidden('patentId', true),
        defaultIndex: 16,
        defaultWidth: 170,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.patentIdCellTemplate
      },
      {
        id: 'patentPublicationDate',
        title: 'Patent Publication Date',
        index: this.getColumnIndex('patentPublicationDate', 17),
        width: this.getColumnWidth('patentPublicationDate', 170),
        hidden: this.getColumnHidden('patentPublicationDate', true),
        defaultIndex: 17,
        defaultWidth: 170,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.patentPublicationDateCellTemplate
      },
      {
        id: 'patentFilingDate',
        title: 'Patent Filing Date',
        index: this.getColumnIndex('patentFilingDate', 18),
        width: this.getColumnWidth('patentFilingDate', 170),
        hidden: this.getColumnHidden('patentFilingDate', true),
        defaultIndex: 18,
        defaultWidth: 170,
        defaultHidden: true,
        minWidth: 50,
        templateRef: this.patentFilingDateCellTemplate
      },
      {
        id: 'copyrightStatus',
        title: 'Copyright Status',
        index: this.getColumnIndex('copyrightStatus', 19),
        width: this.getColumnWidth('copyrightStatus', 125),
        hidden: this.getColumnHidden('copyrightStatus', true),
        sorted: this.sort === 'copyright_status' ? this.order : undefined,
        defaultIndex: 19,
        defaultWidth: 125,
        defaultHidden: true,
        minWidth: 50,
        disabled: !this.shared.user?.licence?.copyright_show_status,
        templateRef: this.copyrightStatusCellTemplate
      }
    ];
  }

  getCustomTableColumns(): ITableColumn[] {
    const fields = this.collection?.custom_fields || [];
    const defaultColumnsCount = this.getDefaultTableColumns().length;
    return fields.filter(f => f.show_in_table).map((f, i) => {
      const columnId = `customField_${f.field}`;
      return {
        id: columnId,
        title: f.display,
        index: this.getColumnIndex(columnId, defaultColumnsCount + i),
        width: this.getColumnWidth(columnId, 200),
        hidden: this.getColumnHidden(columnId, false),
        sorted: this.sort === f.key ? this.order : undefined,
        defaultIndex: defaultColumnsCount + i,
        defaultWidth: 200,
        defaultHidden: false,
        minWidth: 50,
        templateRef: this.customFieldCellTemplate,
        customField: f,
      };
    });
  }

  toggleFlag(item: IDataItem) {
    this.dataItem.update(item.collection_id, item.id, {
      user_data: { star: !item.user_data.star }
    });
  }

  setRating(item: IDataItem, rating: number) {
    this.dataItem.update(item.collection_id, item.id, {
      user_data: { rating: rating }
    });
  }

  setColorLabel(item: IDataItem, color: string) {
    this.dataItem.update(item.collection_id, item.id, {
      user_data: { color: color }
    });
  }

  getColumnIndex(columnId: string, initial: number): number {
    return this.storage.get(`${this.storagePrefix}grid-items-column-${columnId}-index`, initial);
  }

  getColumnWidth(columnId: string, initial: number): number {
    return this.storage.get(`${this.storagePrefix}grid-items-column-${columnId}-width`, initial);
  }

  getColumnHidden(columnId: string, initial: boolean): boolean {
    return this.storage.get(`${this.storagePrefix}grid-items-column-${columnId}-hidden`, initial);
  }

  setColumnIndex(columnId: string, index: number) {
    this.storage.set(`${this.storagePrefix}grid-items-column-${columnId}-index`, index);
  }

  setColumnWidth(columnId: string, width: number) {
    this.storage.set(`${this.storagePrefix}grid-items-column-${columnId}-width`, width);
  }

  setColumnHidden(columnId: string, hidden: boolean) {
    this.storage.set(`${this.storagePrefix}grid-items-column-${columnId}-hidden`, hidden);
  }

  getItemType(item: IDataItem): string {
    const schema = this.collection?.custom_type_schema || default_type_schema;
    if (!schema)
      return '';
    const itemType = item[schema.field_defs['type'].readcube];
    return schema.types[itemType]?.csv_type || itemType;
  }

  onHighlightItems(items: IDataItem[]) {
    this.highlightedChange.emit(items);
  }

  getArticleFile(item: IDataItem): IDataItemFile {
    return item.files[0];
  }

  getCustomFieldValue(item: IDataItem, column: ITableColumn): string {
    if (!column.customField.field) return '';

    const value = item.custom_metadata[column.customField.field];

    if (!value) return '';

    if (column.customField?.type === 'bool') {
      return value === 'true' ? 'yes' : (value === 'false') ? 'no' : '';
    }

    if (column.customField?.type === 'array' || column.customField?.type === 'array-lookup') {
      try {
        const parsed = JSON.parse(value);
        if (parsed && typeof parsed === "object" && parsed.join) {
          return parsed.join(', ');
        }
      }
      catch (e) { }
    }

    return value;
  }
}
