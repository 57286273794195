import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { AppCommonModule } from '../common';
import { FullTextService } from './services/full-text.service';
import { ModalFilteredListComponent } from './modals/modal-filtered-list.component';
import { ModalImportSearchComponent } from './modals/modal-import-search.component';
import { AppSearchDataModule } from '../search-data';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReadCubeCommonModule,
    AppCommonModule,
    AppSearchDataModule,
  ],
  declarations: [
    ModalFilteredListComponent,
    ModalImportSearchComponent
  ],
  exports: [
    ModalFilteredListComponent,
    ModalImportSearchComponent
  ]
})
export class AppFullTextModule {
  static forRoot(): ModuleWithProviders<AppFullTextModule> {
    return {
      ngModule: AppFullTextModule,
      providers: [
        FullTextService
      ]
    };
  }
}
