<div *ngFor="let item of headerItems" class="drawer_header-item">
  <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
  <div (click)="item.action()">{{item.name}}</div>
</div>

<div class="drawer_section">
  <div class="drawer_section-title">Saved</div>
  <div *ngFor="let item of savedItems" class="drawer_item-container">
    <div class="drawer_item-icon">
      <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
    </div>
    <div class="drawer_item-content">
      <div class="drawer_item-title">{{item.title}}</div>
      <div class="drawer_item-info">
        <div>{{item.info}}</div>
      </div>
    </div>
  </div>
</div>

<div class="drawer_section">
  <div class="drawer_section-title">Recent</div>
  <div *ngFor="let item of recentItems" class="drawer_item-container">
    <div class="drawer_item-icon">
      <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
    </div>
    <div class="drawer_item-content">
      <div class="drawer_item-title">{{item.title}}</div>
      <div class="drawer_item-info">
        <div>{{item.info}}</div>
      </div>
    </div>
  </div>
</div>