<form #searchForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">Create Filtered List</h4>
  </div>

  <div class="modal-body">

    <b class="papers-label">Search</b>

    <div class="form-floating">
      <input 
        name="query-input" 
        type="text" 
        class="form-control"
        #queryInput="ngModel"
        [(ngModel)]="query"
        autocomplete="off"
        maxlength="200"
        ngbAutofocus
        emptyValidator
        required>
      <span class="form-text text-muted" *ngIf="queryInput.control.errors?.required && queryInput.touched">Query is required.</span>
      <span class="form-text text-muted" *ngIf="queryInput.control.errors?.empty && queryInput.touched">Query cant't be empty.</span>
      <label for="query-input">Query</label>
    </div>

    <br>

    <b class="papers-label">Save results to</b>
  
    <div class="form-floating">
      <input 
        name="result-sublist-name-input" 
        type="text" 
        class="form-control"
        #resultSublistNameInput="ngModel"
        [(ngModel)]="resultSublistName"
        autocomplete="off"
        maxlength="200"
        emptyValidator
        required>
      <span class="form-text text-muted" *ngIf="resultSublistNameInput.control.errors?.required && resultSublistNameInput.touched">Sub List Name is required.</span>
      <span class="form-text text-muted" *ngIf="resultSublistNameInput.control.errors?.empty && resultSublistNameInput.touched">Sub List Name cant't be empty.</span>
      <label for="result-sublist-name-input">Sub List Name</label>
    </div>

    <ng-container *ngIf="searchDimensions">
      <br>

      <b class="papers-label">[Optional] Save Records Without Full Text To</b>
    
      <div class="form-floating">
        <input 
          name="unsearchable-sublist-name-input" 
          type="text" 
          class="form-control"
          #unsearchableSublistNameInput="ngModel"
          [(ngModel)]="unsearchableSublistName"
          autocomplete="off"
          maxlength="200">
      </div>
    </ng-container>

    <br><br>

    <b class="papers-label">Options</b>

    <div class="form-check form-switch mt-2" *ngIf="shared.user?.licence?.fulltext_search_via_dimensions">
      <input class="form-check-input"
        type="checkbox"
        id="search-dimensions-checkbox"
        name="search-dimensions-input"
        [(ngModel)]="searchDimensions">
      <label class="form-check-label" for="search-dimensions-checkbox">Search Full Text via Dimensions</label>
    </div>
  
    <div class="form-check form-switch mt-2">
      <input class="form-check-input"
        type="checkbox"
        id="show-result-count-checkbox"
        name="show-results-count-input"
        [(ngModel)]="showResultCount">
      <label class="form-check-label" for="show-result-count-checkbox">Show Result Count in Sub List Name</label>
    </div>

    <br>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!searchForm.form.valid">Search</button>
  </div>

</form>