import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';

import { ModalExtensionInstallComponent } from './modals/modal-extension-install.component';
import { ModalExtensionNotAvailableComponent } from './modals/modal-extension-not-available.component';
import { ModalExtensionDownloadFailedComponent } from './modals/modal-extension-download-failed.component';

import { ExtensionService } from './services/extension.service';

@NgModule({
  imports: [
    CommonModule,
    ReadCubeCommonModule,
    ReadCubeSvgIconsModule
  ],
  declarations: [
    ModalExtensionInstallComponent,
    ModalExtensionNotAvailableComponent,
    ModalExtensionDownloadFailedComponent
  ],
  exports: [
    ModalExtensionInstallComponent,
    ModalExtensionNotAvailableComponent,
    ModalExtensionDownloadFailedComponent
  ],
  providers: [
    ExtensionService
  ]
})
export class AppExtensionModule { }
