import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';

import { AppWebSocketService } from '../../app-websocket.service';
import { IDataResult } from '../../app-data-result.model';
import { environment } from 'environment';

export interface IDataSmartList {
  id: string;
  collection_id: string;
  name: string;
  query: string;
  deleted: boolean;
  created: string;
  modified: string;
  incomplete: boolean;
  sortField: string;
  sortOrder: string;
  public_id: string;
}

export interface IDataSmartListQueryParams {
  collection_id: string;
}

export interface IDataSmartListNotification {
  action: 'created' | 'updated' | 'deleted';
  id: string;
  type: 'smartlist';
}

@Injectable()
export class DataSmartListApiService {

  constructor(
    private http: HttpClient,
    private ws: AppWebSocketService
  ) { }

  public listen(collectionId: string, listId?: string): Observable<IDataSmartListNotification[]> {
    return this.ws.listen(`/collections/${collectionId}/changes`).pipe(
      map(response => response.changes.find(c => {
        return c.type === 'smartlist' && (c.id === listId || !listId);
      })),
      filter(c => Array.isArray(c) && c.length > 0)
    );
  }

  public query(params?: IDataSmartListQueryParams): Observable<IDataResult<IDataSmartList>> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${params.collection_id}/smartlists`;
    return this.http.get<IDataResult<IDataSmartList>>(endpointUrl).pipe(
      map(body => this.createResult(body))
    );
  }

  public get(collectionId: string, listId: string): Observable<IDataSmartList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/smartlists/${listId}`;
    return this.http.get<any>(endpointUrl).pipe(
      map(body => this.mapToSmartlist(body.smartlist))
    );
  }

  public post(list: Partial<IDataSmartList>): Promise<IDataSmartList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${list.collection_id}/smartlists`;
    return firstValueFrom(this.http.post<any>(endpointUrl, { 'smartlist': this.mapFromSmartlist(list) }).pipe(
      map(body => this.mapToSmartlist(body.smartlist)),
    ));
  }

  public patch(list: Partial<IDataSmartList>): Promise<IDataSmartList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${list.collection_id}/smartlists/${list.id}`;
    return firstValueFrom(this.http.patch<any>(endpointUrl, { 'smartlist': this.mapFromSmartlist(list) }).pipe(
      map(body => this.mapToSmartlist(body.smartlist)),
      catchError((response) => {
        if (response?.error?.error === 'inactive_subscription') {
          return this.get(list.collection_id, list.id);
        }
        return null;
      })
    ));
  }

  public delete(collectionId: string, listId: string): Promise<IDataSmartList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/smartlists/${listId}`;
    return firstValueFrom(this.http.delete<any>(endpointUrl).pipe(
      map(body => this.mapToSmartlist(body.smartlist))
    ));
  }

  public share(collectionId: string, id: string): Observable<IDataSmartList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/enable_public?type=smartlist&id=${id}`;
    return this.http.post<any>(endpointUrl, {}).pipe(
      map(body => this.mapToSmartlist(body.object))
    );
  }

  public stopShare(collectionId: string, id: string): Observable<IDataSmartList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/disable_public?type=smartlist&id=${id}`;
    return this.http.post<any>(endpointUrl, {}).pipe(
      map(body => this.mapToSmartlist(body.object))
    );
  }

  private createResult(data: any): IDataResult<IDataSmartList> {
    return {
      refinements: data['refinements'] || {},
      rows: (data['smartlists'] || []).map(d => this.mapToSmartlist(d)),
      totalHuman: data['total_human'] || 'No smartlists',
      total: data['total'] || 0
    };
  }

  private mapToSmartlist(data: any): IDataSmartList {
    let field = null;
    let order = null;
    if (Array.isArray(data['sort']) && data['sort'].length > 0) {
      field = data['sort'][0].split(',')[0];
      order = data['sort'][0].split(',')[1];
    }

    return {
      id: data['id'],
      collection_id: data['collection_id'],
      name: data['name'] || '',
      deleted: data['deleted'] || false,
      query: data['query'] || '',
      created: data['created'] || null,
      modified: data['created'] || null,
      incomplete: data['incomplete'] || false,
      public_id: data['public_id'] || null,
      sortField: field,
      sortOrder: order
    };
  }

  private mapFromSmartlist(list: Partial<IDataSmartList>): any {
    let mapped = <any>Object.assign({}, list);

    if (mapped.sortField && mapped.sortOrder) {
      mapped.sort = [`${mapped.sortField},${mapped.sortOrder}`];
    }

    delete mapped.sortField;
    delete mapped.sortOrder;

    return mapped;
  }
}
