import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCommaFirstAuthor'
})
export class FormatCommaFirstAuthorPipe implements PipeTransform {
  transform(value: string[] | { name: string; }[]): string {
    if (!value || value.length === 0) {
      return '';
    }
    let first = value[0];
    let s = typeof first == 'string' ? first : first.name;
    let i = s.lastIndexOf(',');
    if (i === -1) return s;
    return s.split(',')[0].concat(value.length > 1 ? ' et al.' : '');
  }
}