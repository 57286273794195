import { Component, Input, ChangeDetectionStrategy, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IDataArticle } from '../../search-data';
import { IDataCollection } from '../../library-data';
import { CloseContextMenuEvent, ContextMenuComponent, ContextMenuService } from '@readcube/ngx-contextmenu';

import { AppShellService } from '../../app-shell.service';
import { ReaderService, SharedService } from '../../common';
import { ExporterService } from '../../exporter';
import { SearchService } from '../../search';

@Component({
  selector: 'search-menu',
  templateUrl: './search-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSearchMenuComponent implements OnChanges {
  @Input()
  articles: IDataArticle[] = [];

  @Input()
  collections: IDataCollection[];

  @Output()
  onMenuClose: EventEmitter<CloseContextMenuEvent> = new EventEmitter<CloseContextMenuEvent>();

  @ViewChild('selectedContextMenu', { static: true })
  selectedContextMenu: ContextMenuComponent;

  canViewPDF: boolean;
  canCopy: boolean = true;
  canViewInLibrary: boolean;
  canAddToLibrary: boolean;
  canExport: boolean = true;
  canOpenCopyright: boolean;
  canOrderReprint: boolean;

  constructor(
    public router: Router,
    public search: SearchService,
    public reader: ReaderService,
    public shared: SharedService,
    public contextMenu: ContextMenuService,
    public exporter: ExporterService,
    public shell: AppShellService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    const primary = this.articles[0];

    this.canViewPDF = (this.articles.length === 1) && this.search.canViewArticlePDF(primary);
    this.canViewInLibrary = this.articles.length === 1 && !!primary.item_statuses?.length;
    this.canAddToLibrary = this.articles.length === 1 || !this.articles.some(article => article.item_statuses?.length);
    this.canOpenCopyright = this.articles.length === 1 && this.search.canOpenCopyright(primary);
    this.canOrderReprint = this.articles.length === 1 && this.search.canOrderReprint(primary);
  }

  open(event: MouseEvent, anchor = false) {
    if (!this.articles.length) {
      return;
    }
    this.contextMenu.show.next({
      contextMenu: this.selectedContextMenu,
      anchorElement: anchor ? event.currentTarget : null,
      event: event,
      item: this.articles
    });
    event.stopPropagation();
    event.preventDefault();
  }

  addToLibrary(collectionId: string) {
    if (this.shared.isInLibrary(collectionId, this.articles[0]))
      return;

    this.shared.addToLibrary(this.articles, collectionId);
  }

  viewPDF() {
    const article = this.articles[0];
    const status = this.search.findArticleItemStatus(article);
    if (status?.has_file) {
      this.search.viewArticlePDF(status);
    } else {
      this.reader.viewPublicPDF(article);
    }
  }

  exportBIB(articles: IDataArticle[]) {
    this.exporter.exportArticlesToBIB({ articles });
  }

  exportRIS(articles: IDataArticle[]) {
    this.exporter.exportArticlesToRIS({ articles });
  }

  exportJSON(articles: IDataArticle[]) {
    this.exporter.exportArticlesToJSON({ articles });
  }
}
