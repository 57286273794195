import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoadNextEvent } from '@readcube/rcp-data-view';
import { CloseContextMenuEvent } from '@readcube/ngx-contextmenu';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, of, asyncScheduler } from 'rxjs';
import { tap, map, mergeWith, switchMap, distinctUntilChanged, startWith, filter, catchError, shareReplay, mergeMap, throttleTime } from 'rxjs/operators';

import { SharedService, ModalSubscriptionComponent, HistoryService } from '../../common';
import { DataUserService, IDataUser } from '../../common-data';
import { DataCollectionService, IDataCollection } from '../../library-data';
import { DataSearchService, IDataArticle } from '../../search-data';

import { AppShellService, shouldRemoveHighlight } from '../../app-shell.service';
import { SearchService } from '../services/search.service';
import { SearchSidepanelService } from '../../search-sidepanel';
import { SearchQueryHeaderComponent } from './search-query-header.component';
import { ImporterService } from '../../importer';

@Component({
  templateUrl: './search-query.component.html',
  styleUrls: ['./search-query.component.scss']
})
export class SearchQueryComponent implements OnInit, OnDestroy, AfterContentInit {
  viewName: string;

  selected: IDataArticle[] = [];
  highlighted: IDataArticle[] = [];

  fields = [
    { key: 'title', field: 'title', display: 'Title'},
    { key: 'author', field: 'author', display: 'Author'},
    { key: 'journal', field: 'journal', display: 'Journal'},
    { key: 'year', field: 'year', display: 'Year'},
  ];

  fieldInputCapabilities = {
    'year': ['is', 'is_not', 'exists', 'range'],
  };

  fieldDefaultInputCapabilities = {
    'year': 'is',
  };

  fieldInputOptions = {
  };

  loading$ = new ReplaySubject<boolean>(1);
  finished$ = new ReplaySubject<boolean>(1);
  itemQuickPush$ = new ReplaySubject<IDataArticle>(1);

  collections$: Observable<IDataCollection[]>;
  sidePanelOpen$: Observable<boolean>;
  user$: Observable<IDataUser>;
  query$: Observable<string>;
  queryType$: Observable<string>;
  articles$: Observable<IDataArticle[]>;
  sort$: Observable<string>;
  order$: Observable<string>;
  statusText$: Observable<string>;

  @ViewChild('searchHeader', { static: true })
  searchHeader: SearchQueryHeaderComponent;

  @ViewChild('selectionContainer', { static: true })
  selectionContainer: ElementRef;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public search: SearchService,
    public importer: ImporterService,
    public shared: SharedService,
    public history: HistoryService,
    public modal: NgbModal,
    public dataUser: DataUserService,
    public dataSearch: DataSearchService,
    public dataCollection: DataCollectionService,
    public sidepanel: SearchSidepanelService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.viewName = this.search.getViewName();

    this.sidepanel.close(this.route);

    this.user$ = this.dataUser.get().pipe(
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.collections$ = this.dataCollection.query().pipe(
      map(arr => arr.filter(c => c.status === 'active')),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.sidePanelOpen$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !!this.route.children.find(child => child.outlet === 'sidepanel')),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    // Filter streams...
    this.query$ = this.route.queryParams.pipe(
      map(params => params?.query),
      distinctUntilChanged(),
      map(value => value || ''),
      tap(value => this.history.pushSearchHistory('readcube', value)),
      tap(value => this.history.lastSearchQuery$.next(value))
    );

    this.queryType$ = this.route.queryParams.pipe(
      map(params => params?.type),
      distinctUntilChanged()
    );

    this.sort$ = this.route.queryParams.pipe(
      map(params => params?.sort),
      distinctUntilChanged(),
      map(value => value || this.search.getSorting()),
      tap(value => this.search.setSorting(value))
    );

    this.order$ = this.route.queryParams.pipe(
      map(params => params?.order),
      distinctUntilChanged(),
      map(value => value || this.search.getOrdering()),
      tap(value => this.search.setOrdering(value))
    );

    // Content streams...
    this.articles$ = this.getItemsParams$().pipe(
      filter(params => !!params['query']),
      tap(() => this.loading$.next(true)),
      switchMap(params => this.dataSearch.query(params)),
      tap(() => this.loading$.next(false)),
      tap(() => this.finished$.next(this.dataSearch.isDone())),
      catchError(() => of([])),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.sidepanel.article$ = this.route.queryParams.pipe(
      filter(params => 'article_id' in params),
      map(params => params['article_id']),
      distinctUntilChanged((x, y) => x === y),
      tap(() => this.sidepanel.loading$.next(true)),
      mergeMap(params => this.importer.done$.pipe(
        // https://readcube.atlassian.net/browse/ELECTRON-1630
        map(() => params),
        throttleTime(3000, asyncScheduler, { leading: false, trailing: true }),
        startWith(params),
      )),
      switchMap(articleId => this.dataSearch.get(articleId).pipe(
        tap(() => this.sidepanel.loading$.next(false)),
      )),
      mergeWith(this.itemQuickPush$),
      filter(article => article != null),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.statusText$ = this.articles$.pipe(
      map(() => {
        const s = (n: number) => (n > 1) ? 's' : '';
        const total = this.dataSearch.total,
          totalHuman = this.dataSearch.totalHuman;
        if (total === 0) {
          return 'No articles found.';
        }
        return `Found ${totalHuman} article${s(total)}.`;
      })
    );
  }

  ngOnDestroy() {

  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.searchHeader.focusOnSearchInput();
    }, 200);
  }

  onViewNameChange(viewName: string) {
    this.search.setViewName(viewName);
  }

  onLoadNext(event: LoadNextEvent) {
    if (!this.route.snapshot.queryParamMap.get('query')) {
      return;
    }
    this.loading$.next(true);
    this.dataSearch.queryNext({}).catch(response => {
      if (response?.error === 'scroll_id_expired') {
        this.shared.openSessionExpired().then(() => {
          const url = this.router.url;
          this.router.navigateByUrl('.', { skipLocationChange: true })
            .then(() => this.router.navigateByUrl(url));
        });
      }
    });
  }

  selectItems(articles: IDataArticle[]) {
    const params = this.route.snapshot.queryParamMap;
    const isFirstSelect = !params.has('articles_ids');
    if (articles.length === 1) {
      if (isFirstSelect) {
        this.sidepanel.setActive('details');
      }
      const panel = this.sidepanel.getActive();
      this.sidepanel.open(this.route, articles, panel).then(opened => {
        if (opened) {
          this.itemQuickPush$.next(articles[0]);
        }
      });
    } else {
      this.sidepanel.close(this.route);
    }

    this.selected = articles.slice(0);
    this.highlighted = [];
  }

  highlightItems(articles: IDataArticle[]) {
    this.highlighted = articles.slice(0);
  }

  onMenuClose(event: CloseContextMenuEvent) {
    if (shouldRemoveHighlight(this.selectionContainer, event))
      this.highlightItems([]);
  }

  openSidePanel(path: string) {
    this.sidepanel.navigate(this.route, path);
  }

  closeSidePanel() {
    this.selected = [];
    this.sidepanel.close(this.route);
  }

  openSubscriptionModal() {
    this.modal.open(ModalSubscriptionComponent);
  }

  protected getItemsParams$() {
    const relevantParams = ['query', 'type', 'sort', 'order'];
    return this.route.queryParams.pipe(
      map(params => Object.assign({}, params, {
        'type': params.type || 'publications',
        'query': params.query || undefined,
        'sort_by': params.sort || this.search.getSorting(),
        'order': params.order || this.search.getOrdering(),
        'size': 50
      })),
      distinctUntilChanged((x, y) => relevantParams.every(p => x[p] === y[p]))
    );
  }
}
