<div class="alert alert-danger">
  <h5 class="alert-heading">{{error.title}}</h5>

  <hr>

  <ng-container [ngSwitch]="[].constructor.isArray(error.message)">
    <ul class="mb-0" *ngSwitchCase="true">
      <li *ngFor="let message of error.message" [innerHTML]="message | sanitizedHtml"></li>
    </ul>

    <p class="mb-0" *ngSwitchCase="false" [innerHTML]="error.message | sanitizedHtml"></p>
  </ng-container>

</div>
