import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { DataListService, DataCollectionService, IDataCollection, IDataList } from '../../library-data';
import { ListTreeService } from '../services/list-tree.service';
import { SharedService } from '../services/shared.service';

@Component({
  templateUrl: './modal-edit-list.component.html'
})
export class ModalEditListComponent implements OnInit {
  @Input()
  list: IDataList;

  @Input()
  maxListDepth: number;

  collections$: Observable<IDataCollection[]>;
  lists$: Observable<IDataList[]>;

  disabled$: Observable<boolean>;
  error$: Observable<string>;
  disabledSubject = new Subject<boolean>();
  errorSubject = new Subject<string>();

  protected original: IDataList;

  constructor(
    public activeModal: NgbActiveModal,
    public dataCollection: DataCollectionService,
    public dataList: DataListService,
    public listTree: ListTreeService,
    public shared: SharedService
  ) {
    this.disabled$ = this.disabledSubject.pipe(startWith(false));
    this.error$ = this.errorSubject.asObservable();
  }

  ngOnInit() {
    this.original = Object.assign({}, this.list);
    if (!this.list.parent_id) {
      this.list.parent_id = '';
    }
    this.collections$ = this.dataCollection.query();
    this.updateLists();
  }

  onCollectionChange() {
    this.list.parent_id = '';
    this.updateLists();
  }

  submit() {
    this.errorSubject.next('');
    this.disabledSubject.next(true);
    let promise;
    if (!this.list.id) {
      promise = this.dataList.create(this.list);
    } else {
      const patch = <Partial<IDataList>>{
        id: this.list.id,
        collection_id: this.list.collection_id
      };
      if (this.original.name !== this.list.name) {
        patch.name = this.list.name;
      }
      if (this.original.parent_id !== this.list.parent_id) {
        patch.parent_id = this.list.parent_id;
      }
      promise = this.dataList.update(patch);
    }
    promise.then(list => {
      this.activeModal.close(list);
    }).catch(response => {
      this.errorSubject.next(response?.error?.error);
      this.disabledSubject.next(false);
    });
  }

  close() {
    this.activeModal.close();
  }

  protected updateLists() {
    this.lists$ = this.dataList.query({ collection_id: this.list.collection_id }).pipe(
      map(lists => this.listTree.fromLists(lists)),
      map(root => ({ root, lists: this.listTree.flatten(root, this.maxListDepth) })),
      map(result => {
        return result.lists.filter(list => {
          const node = result.root.findNode(list.id);
          return list.id !== this.list.id && !node.isParentOf(this.list.id);
        })
      })
    );
  }
}
