import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './modal-import-search.component.html'
})
export class ModalImportSearchComponent {

  confirmed: boolean;
  query: string;
  resultLimit: string = '50';
  searchDimensions: boolean = true;
  mergeDuplicates: boolean = true;

  resultLimits = ['10', '50', '100', '500', '1000'];

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  submit() {
    this.activeModal.close({
      confirmed: true,
      resultLimit: this.resultLimit,
      searchDimensions: this.searchDimensions,
      query: this.query,
      mergeDuplicates: this.mergeDuplicates
    });
  }

  cancel() {
    this.activeModal.close({ confirmed: false });
  }
}
