import { Injectable } from '@angular/core';

import { AppSessionService } from './app-session.service';

class MemoryStorage implements Partial<Storage> {
  protected data = new Map<string, string>();

  getItem(key: string): string {
    return this.data.get(key);
  }

  setItem(key: string, value: string) {
    this.data.set(key, value);
  }

  removeItem(key: string) {
    this.data.delete(key);
  }

  clear() {
    this.data.clear();
  }
}

@Injectable()
export class AppStorageService {
  protected storage: Partial<Storage>;

  constructor(
    protected session: AppSessionService
  ) {
    try {
      const testKey = 'storageTest';
      this.storage = window['localStorage'];
      this.storage.setItem(testKey, 'ok');
      this.storage.removeItem(testKey);
    } catch (ex) {
      this.storage = new MemoryStorage();
    }
  }

  remove(key: string): void {
    const id = this.session.userId;
    const lsKey = [id, key].join('_');
    this.storage.removeItem(lsKey);
  }

  set<T>(key: string, value: T): void {
    const id = this.session.userId;
    const lsKey = [id, key].join('_');
    this.storage.setItem(lsKey, JSON.stringify(value));
  }

  get<T>(key: string, stock?: T): T {
    const id = this.session.userId;
    const lsKey = [id, key].join('_');
    const str = this.storage.getItem(lsKey);
    const value = str ? JSON.parse(str) : stock;
    return value;
  }
}
