<ng-template #inLibraryHeadTemplate let-column="column">
  <div class="head-icon">
    <fa-icon [icon]="['fal', 'archive']"></fa-icon>
  </div>
</ng-template>

<ng-template #inLibraryCellTemplate let-item="item">
  <div class="cell-icon" *ngIf="!!item.item_statuses?.length">
    <fa-icon  [icon]="['fas', 'archive']"></fa-icon>
  </div>
</ng-template>

<ng-template #libraryCellTemplate let-item="item">
  <ng-container *ngFor="let s of item.item_statuses; let last = last;">
    <a href (click)="shared.viewInLibrary(s.collection_id, s.id); $event.preventDefault()">{{s.collection_name || 'My Library'}}</a>
    <ng-container *ngIf="!last">&comma; </ng-container>
  </ng-container>
</ng-template>

<ng-template #authorsCellTemplate let-item="item">{{item?.authors | formatFirstAuthor}}</ng-template>

<ng-template #lastAuthorCellTemplate let-item="item">{{item?.authors | formatLastAuthor}}</ng-template>

<ng-template #titleCellTemplate let-item="item">
  <rcp-katex-paragraph [paragraph]="getHighlightedTitle(item)" [forceInline]="true"></rcp-katex-paragraph>
</ng-template>

<ng-template #journalCellTemplate let-item="item">{{item.journal}}</ng-template>

<ng-template #yearCellTemplate let-item="item">{{item.year}}</ng-template>


<data-view-table
  [items]="items"
  [itemSize]="32"
  [columns]="columns"
  [multiselect]="multiselect"
  (columnsChange)="onColumsChange($event)"
  (open)="open.next($event)"
  [(selected)]="selected"
  [highlighted]="highlighted"
  (selectedChange)="onSelectItems($event)"
  (highlightedChange)="onHighlightItems($event)"
  (headClick)="onTableHeadClick($event)"
  (loadNext)="onLoadNext($event)">

  <common-loader-circles class="loader" [ngClass]="{'visible': loading}"></common-loader-circles>
</data-view-table>
