<form #listForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">{{list.id ? 'Edit Smart List' : 'Create Smart List'}}</h4>
    <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body">
    <div [hidden]="!(error$ | async)" class="alert alert-danger">{{error$ | async}}</div>

    <div class="row">
      <div class="fcol-sm-12">
        <div class="form-floating">
          <input #name="ngModel" name="list-name-input" type="text" class="form-control"
            [(ngModel)]="list.name" required maxlength="50" autocomplete="off" ngbAutofocus>
          <span class="form-text text-muted" [hidden]="name.valid || name.pristine">List name is required.</span>
          <label for="list-name-input">List name</label>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="fcol-sm-12">
        <div class="form-floating">
          <input #name="ngModel" name="search-query-input" type="text" class="form-control"
            [(ngModel)]="list.query" required autocomplete="off">
          <span class="form-text text-muted" [hidden]="name.valid || name.pristine">Search query is required.</span>
          <label for="search-query-input">Search query</label>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="fcol-sm-12 col-md-6">
        <div class="form-floating">
          <select #sortField="ngModel" name="sort-field-input" class="form-select" [(ngModel)]="list.sortField" [required]="!!list.sortOrder">
            <option [value]="opt.key" *ngFor="let opt of collection.sortable_fields">{{opt.display}}</option>
          </select>
          <span class="form-text text-muted" *ngIf="sortField.invalid">Sort field is required.</span>
          <label for="sort-field-input">Sort by</label>
        </div>
      </div>

      <div class="col-sm-12 col-md-6">
        <div class="form-floating">
          <select #sortOrder="ngModel" name="sort-order-input" class="form-select" [(ngModel)]="list.sortOrder" [required]="!!list.sortField">
            <option [value]="order.key" *ngFor="let order of collection.order_options">{{order.display}}</option>
          </select>
          <span class="form-text text-muted" *ngIf="sortOrder.invalid">Sort order is required.</span>
          <label for="sort-order-input">Order by</label>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-link me-auto" (click)="shell.openURL('http://support.readcube.com/support/solutions/articles/30000037316-smartlists/')">Popular Examples</button>
    <button type="button" class="btn btn-lg btn-secondary" (click)="close()" ngbAutofocus>Close</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!listForm.form.valid || (disabled$ | async)">{{list.id ? 'Save' : 'Create'}}</button>
  </div>

</form>
