<div class="modal-header">
  <h4 class="modal-title">Shared Library Limit</h4>
  <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <p>Papers allows you to create up to {{user.licence.collection_count_limit}} shared libraries for collaboration.</p>
  <p>
    To create additional shared libraries, consider upgrading to an enterprise plan.<br>
    More information is available at <a target="_blank" href="https://www.papersapp.com/enterprise-plans/">https://www.papersapp.com/enterprise-plans/</a>
  </p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-lg btn-primary" (click)="close()" ngbAutofocus>OK</button>
</div>
