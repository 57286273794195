<div class="modal-body" [style.width.px]="600">
  <div class="mt-4" *ngIf="!params.items?.length">
    <rcp-exporter-source-select
      [(collection)]="params.collection"
      [(list)]="params.list">
    </rcp-exporter-source-select>
  </div>

  <div class="mt-4" *ngIf="params.items?.length">
    Exporting {{params.items.length}} selected references...
  </div>

  <div class="mt-4">
    <rcp-exporter-destination-select
      [availableExportTypes]="availableExportTypes"
      [(exportType)]="params.type">
    </rcp-exporter-destination-select>
  </div>
</div>

<div class="modal-footer mt-4">
  <button type="button" class="btn btn-lg btn-secondary me-1" (click)="cancel()">Cancel</button>

  <button type="button" class="btn btn-lg btn-primary" (click)="next()" *ngIf="hasOptions">
    Next <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
  </button>

  <button type="button" class="btn btn-lg btn-primary" (click)="export()" *ngIf="!hasOptions">
    <fa-icon [icon]="['fas', 'file-import']"></fa-icon> Export
  </button>
</div>
