<div *ngFor="let item of headerItems" class="drawer_header-item">
  <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
  <div (click)="item.action()">{{item.name}}</div>
</div>

<div class="drawer_section">
  <div class="drawer_section-title">Saved Folders</div>
  <div *ngFor="let item of savedFolders" class="drawer_item-container">
    <div class="drawer_item-icon">
      <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
    </div>
    <div class="drawer_item-content">
      <div class="drawer_item-title">{{item.title}}</div>
      <div class="drawer_item-info">
        <div>{{item.info}}</div>
        <ng-container *ngIf="item.notification">
          <rcp-svg-icon name="notification" width="6" height="6"></rcp-svg-icon>
          <div>{{item.notification}}</div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="drawer_section">
  <div class="drawer_section-title">Recent PDFs</div>
  <div *ngFor="let item of recentPdfs" class="drawer_item-container">
    <div class="drawer_item-icon">
      <rcp-svg-icon [name]="item.icon"></rcp-svg-icon>
    </div>
    <div class="drawer_item-content">
      <div class="drawer_item-title">{{item.title}}</div>
      <div class="drawer_item-info">
        <div>{{item.info}}</div>
      </div>
    </div>
  </div>
</div>