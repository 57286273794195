import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeTokenFieldModule } from '@readcube/rcp-token-field';
import { ReadCubeTypeaheadModule } from '@readcube/rcp-typeahead';
import { ReadCubeDragAndDropModule } from '@readcube/rcp-drag-and-drop';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';

import { CommonDialogAlertComponent } from './components/common-dialog-alert.component';
import { CommonDialogConfirmComponent } from './components/common-dialog-confirm.component';
import { CommonDialogProgressComponent } from './components/common-dialog-progress.component';
import { CommonDialogPromptComponent } from './components/common-dialog-prompt.component';
import { CommonSidepanelLayoutComponent } from './components/common-sidepanel-layout.component';
import { CommonNavigationButtonComponent } from './components/common-navigation-button.component';
import { CommonNavigationButtonAddonComponent } from './components/common-navigation-button-addon.component';
import { CommonTemplatesMenuComponent } from './components/common-templates-menu.component';

import { CommonInputDateComponent } from './components/common-input-date.component';
import { CommonColorPickerComponent } from './components/common-color-picker.component';
import { CommonDropzoneComponent } from './components/common-dropzone.component';
import { CommonIconDimensionsComponent } from './components/common-icon-dimensions.component';
import { CommonIconReadCubeComponent } from './components/common-icon-readcube.component';
import { CommonLoaderCirclesComponent } from './components/common-loader-circles.component';
import { CommonStarsRatingComponent } from './components/common-stars-rating.component';
import { CommonToggleFlagComponent } from './components/common-toggle-flag.component';

import { ModalEditCollectionComponent } from './modals/modal-edit-collection.component';
import { ModalEditCollectionFieldsComponent } from './modals/modal-edit-collection-fields.component';
import { ModalEditListComponent } from './modals/modal-edit-list.component';
import { ModalEditSmartListComponent } from './modals/modal-edit-smartlist.component';
import { ModalSubscriptionComponent } from './modals/modal-subscription.component';
import { ModalSharedLibraryLimitComponent } from './modals/modal-shared-library-limit.component';
import { ModalLeaveLibraryComponent } from './modals/modal-leave-library.component';
import { ModalShareComponent } from './modals/modal-share.component';
import { ModalNotificationsComponent } from './modals/modal-notifications.component';
import { ModalReviewComponent } from './modals/modal-review.component';
import { ModalReviewLinkComponent } from './modals/modal-review-link.component';

import { FormatAuthorPipe } from './pipes/format-author.pipe';
import { FormatFirstAuthorPipe } from './pipes/format-first-author.pipe';
import { FormatLastAuthorPipe } from './pipes/format-last-author.pipe';
import { FormatCommaFirstAuthorPipe } from './pipes/format-comma-first-author.pipe';
import { FormatCommaLastAuthorPipe } from './pipes/format-comma-last-author.pipe';

import { EmailValidator } from './validators/email-validator.directive';
import { EmailInEnterpriseValidator } from './validators/email-in-enterprise-validator.directive';
import { EmailInvitedValidator } from './validators/email-invited-validator.directive';
import { EmptyValidator } from './validators/empty.validator.directive';
import { GroupInvitedValidator } from './validators/group-invitied.validator.directive';
import { FieldNameValidator } from './validators/field-name-validator.directive';

import { ListTreeService } from './services/list-tree.service';
import { SharedService } from './services/shared.service';
import { DialogService } from './services/dialog.service';
import { HistoryService } from './services/history.service';
import { ReaderService } from './services/reader.service';

export * from './pipes/format-author.pipe';
export * from './pipes/format-first-author.pipe';
export * from './pipes/format-last-author.pipe';
export * from './pipes/format-comma-first-author.pipe';
export * from './pipes/format-comma-last-author.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
    ReadCubeDragAndDropModule,
    ReadCubeSvgIconsModule,
    ReadCubeTokenFieldModule,
    ReadCubeTypeaheadModule
  ],
  declarations: [
    CommonDialogAlertComponent,
    CommonDialogConfirmComponent,
    CommonDialogProgressComponent,
    CommonDialogPromptComponent,
    CommonSidepanelLayoutComponent,
    CommonTemplatesMenuComponent,
    CommonNavigationButtonComponent,
    CommonNavigationButtonAddonComponent,
    CommonInputDateComponent,
    CommonColorPickerComponent,
    CommonDropzoneComponent,
    CommonIconDimensionsComponent,
    CommonIconReadCubeComponent,
    CommonLoaderCirclesComponent,
    CommonStarsRatingComponent,
    CommonToggleFlagComponent,
    ModalEditCollectionComponent,
    ModalEditListComponent,
    ModalEditSmartListComponent,
    ModalEditCollectionFieldsComponent,
    ModalSubscriptionComponent,
    ModalSharedLibraryLimitComponent,
    ModalLeaveLibraryComponent,
    ModalShareComponent,
    ModalNotificationsComponent,
    ModalReviewComponent,
    ModalReviewLinkComponent,
    FormatAuthorPipe,
    FormatFirstAuthorPipe,
    FormatLastAuthorPipe,
    FormatCommaFirstAuthorPipe,
    FormatCommaLastAuthorPipe,
    EmailValidator,
    EmailInEnterpriseValidator,
    EmailInvitedValidator,
    GroupInvitedValidator,
    EmptyValidator,
    FieldNameValidator
  ],
  exports: [
    CommonDialogAlertComponent,
    CommonDialogConfirmComponent,
    CommonDialogProgressComponent,
    CommonDialogPromptComponent,
    CommonSidepanelLayoutComponent,
    CommonTemplatesMenuComponent,
    CommonNavigationButtonComponent,
    CommonNavigationButtonAddonComponent,
    CommonInputDateComponent,
    CommonColorPickerComponent,
    CommonDropzoneComponent,
    CommonIconDimensionsComponent,
    CommonIconReadCubeComponent,
    CommonLoaderCirclesComponent,
    CommonStarsRatingComponent,
    CommonToggleFlagComponent,
    ModalEditCollectionComponent,
    ModalEditListComponent,
    ModalEditSmartListComponent,
    ModalEditCollectionFieldsComponent,
    ModalSubscriptionComponent,
    ModalSharedLibraryLimitComponent,
    ModalLeaveLibraryComponent,
    ModalShareComponent,
    ModalNotificationsComponent,
    ModalReviewComponent,
    ModalReviewLinkComponent,
    FormatAuthorPipe,
    FormatFirstAuthorPipe,
    FormatLastAuthorPipe,
    FormatCommaFirstAuthorPipe,
    FormatCommaLastAuthorPipe,
    EmailValidator,
    EmailInEnterpriseValidator,
    EmailInvitedValidator,
    GroupInvitedValidator,
    EmptyValidator,
    FieldNameValidator
  ]
})
export class AppCommonModule {
  static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        ListTreeService,
        SharedService,
        DialogService,
        HistoryService,
        ReaderService,
      ]
    };
  }
}
