import { AfterViewInit, ChangeDetectionStrategy, Component,
  ElementRef, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { QueryFormValue } from '../services/query-builder.service';

@Component({
  selector: 'query-words',
  templateUrl: './query-words.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QueryWordsComponent),
      multi: true
    }
  ]
})
export class QueryWordsComponent implements AfterViewInit, ControlValueAccessor {
  value: QueryFormValue;
  onChange: (value: QueryFormValue) => any;
  onTouched: () => any;

  @ViewChild('focusInput')
  focusInput: ElementRef;

  ngAfterViewInit() {
    this.focus();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: QueryFormValue) {
    this.value = value;
  }

  focus() {
    setTimeout(() => this.focusInput?.nativeElement.focus());
  }
}
