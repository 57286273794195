import { Component, Input, EventEmitter, ChangeDetectionStrategy, ViewChild, ViewEncapsulation, SimpleChanges, Output, OnChanges } from '@angular/core';
import { CloseContextMenuEvent, ContextMenuComponent, ContextMenuService } from '@readcube/ngx-contextmenu';
import { lastValueFrom } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { AppShellService } from '../../app-shell.service';
import { ReaderService, SharedService } from '../../common';
import { LibraryService } from '../../library';
import { DataItemService, DataListService, IDataCollection, IDataItem } from '../../library-data';
import { ImporterService } from '../../importer';
import { ExporterService } from '../../exporter';
import { BulkService } from '../../bulk';

import { environment } from 'environment';

import {
  MERGE_ITEM_MODAL_TITLE,
  MERGE_ITEM_MODAL_TEXT,
  DELETE_ITEM_MODAL_TITLE,
  DELETE_ITEM_MODAL_TEXT,
  UPDATE_DETAILS_ITEM_MODAL_TITLE,
  UPDATE_DETAILS_ITEM_MODAL_TEXT,
  CLEAR_DETAILS_ITEM_MODAL_TITLE,
  CLEAR_DETAILS_ITEM_MODAL_TEXT,
} from 'strings';

@Component({
  selector: 'library-menu',
  templateUrl: './library-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLibraryMenuComponent implements OnChanges {
  @Input()
  collectionId: string;

  @Input()
  collection: IDataCollection;

  @Input()
  listId: string;

  @Input()
  items: IDataItem[];

  @ViewChild('selectedContextMenu', { static: true })
  selectedContextMenu: ContextMenuComponent;

  @Output()
  onEditDetails = new EventEmitter<{ item: IDataItem; }>();

  @Output()
  onOpenResolver = new EventEmitter<{ item: IDataItem; }>();

  @Output()
  onCreated = new EventEmitter<IDataItem>();

  @Output()
  onDeleted = new EventEmitter<IDataItem[]>();

  @Output()
  onImportFiles = new EventEmitter<string>();

  @Output()
  onMenuClose = new EventEmitter<CloseContextMenuEvent>();

  canOpen: boolean;
  canViewPDF: boolean;
  canMerge: boolean;
  canAddFlag: boolean;
  canRemoveFlag: boolean;
  canMarkRead: boolean;
  canMarkUnread: boolean;
  canCopyCitekey: boolean;
  canLocatePDF: boolean;
  canDownloadFile: boolean;
  canOpenFile: boolean;
  canCopyFiles: boolean;
  canOpenCopyright: boolean;
  canSetCopyright: boolean;
  canOrderReprint: boolean;
  canViewInLibrary: boolean;
  canEditDetails: boolean;
  canResolve: boolean;
  canClearDetails: boolean;
  canUpdateDetails: boolean;
  canCopyToMyLibrary: boolean;
  canLinkFile: boolean;

  // permission based flags
  canCopyItem: boolean;
  canManageItem: boolean;
  canDeleteItem: boolean;
  canManageList: boolean;
  canDownloadFileForCollection: boolean;
  canLiteratureReview: boolean;

  constructor(
    public shared: SharedService,
    public dataItem: DataItemService,
    public dataList: DataListService,
    public contextMenu: ContextMenuService,
    public reader: ReaderService,
    public importer: ImporterService,
    public exporter: ExporterService,
    public library: LibraryService,
    public bulk: BulkService,
    public shell: AppShellService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('items' in changes) {
      const items = changes['items'].currentValue as IDataItem[];
      this.canOpen = items.length === 1 && (this.library.canViewItemPDF(items[0]) || this.library.isIdentified(items[0]));
      this.canViewPDF = items.length === 1 && this.library.canViewItemPDF(items[0]);
      this.canMerge = this.collectionId && items.length === 2;
      this.canAddFlag = items.some(item => !item.user_data.star);
      this.canRemoveFlag = items.some(item => item.user_data.star);
      this.canMarkRead = items.some(item => !item.user_data.unread);
      this.canMarkUnread = items.some(item => item.user_data.unread);
      this.canCopyCitekey = items.every(item => item.user_data.citekey);
      this.canLocatePDF = items.some(item => !item.primary_file_hash && this.library.canLocateItemPDF(item));
      this.canDownloadFile = items.length === 1 && this.library.canDownloadFile(items[0]);
      this.canOpenCopyright = items.length === 1 && this.isCopyrightAvailable(items[0]);
      this.canSetCopyright = this.shared.user?.licence?.copyright_show_status;
      this.canOrderReprint = items.length === 1 && this.shared.user?.organization_id &&
        (!!items[0].ext_ids.doi || !!items[0].ext_ids.pmid) && !!items[0].primary_file_hash;
      this.canViewInLibrary = items.length === 1 && !this.collectionId;
      this.canEditDetails = items.length === 1;
      this.canClearDetails = this.collectionId && items.some(item => item.primary_file_hash && this.library.isIdentified(item));
      this.canUpdateDetails = this.collectionId && items.some(item => this.library.isIdentified(item));
      this.canResolve = this.collectionId && items.length === 1 && !this.library.isIdentified(items[0]) && items[0].primary_file_type == 'pdf';
      this.canCopyToMyLibrary = this.collectionId !== this.shared.user?.id;
      this.canLinkFile = this.shared.user?.licence?.link_files && this.collectionId
        && items.some(item => !item.primary_file_hash && this.library.isIdentified(item));
    }
    if ('collection' in changes) {
      this.canCopyItem = !!changes['collection'].currentValue?.user?.can_copy_item;
      this.canManageItem = !!changes['collection'].currentValue?.user?.can_create_item;
      this.canDeleteItem = !!changes['collection'].currentValue?.user?.can_delete_item;
      this.canManageList = !!changes['collection'].currentValue?.user?.can_manage_list;
      this.canDownloadFileForCollection = !!changes['collection'].currentValue?.user?.can_download_file;
      this.canLiteratureReview = ['admin', 'reviewer'].includes(this.shared.user?.review_role);
    }
  }

  open(event: MouseEvent, anchor = false) {
    if (!this.items.length) {
      return;
    }
    this.contextMenu.show.next({
      contextMenu: this.selectedContextMenu,
      anchorElement: anchor ? event.currentTarget : null,
      event: event,
      item: this.items
    });
    event.stopPropagation();
    event.preventDefault();
  }

  createReference(title = '') {
    return this.dataItem.create({
      'collection_id': this.collectionId,
      'article': { title }
    }).then(item => {
      if (this.listId) {
        this.dataList.addItems(this.collectionId, this.listId, [item.id]).then(() => item);
      }
      this.onCreated.next(item);
    });
  }

  viewPDF(items: IDataItem[], event: MouseEvent) {
    this.reader.viewItemPDF({ item: items[0] });
  }

  copyURL(items: IDataItem[]) {
    this.shell.copyToClipboard({
      text: `https:${environment.baseUrls.webapp}/library/${items[0].collection_id}/item/${items[0].id}`
    });
  }

  merge(items: IDataItem[], targetItem: IDataItem) {
    const sourceItem = items.find(item => item.id !== targetItem.id);
    return this.shell.openConfirm<IDataItem[]>({
      title: MERGE_ITEM_MODAL_TITLE,
      message: MERGE_ITEM_MODAL_TEXT,
      confirmButtonText: 'Merge',
      progressText: 'Merging...'
    }, () => {
      return this.dataItem.merge(this.collectionId, sourceItem.id, targetItem.id);
    });
  }

  setFlag(items: IDataItem[], value = true) {
    const collectionId = items.length === 1 ? items[0].collection_id : this.collectionId;
    this.library.setFlag(items, collectionId, value);
  }

  setUnread(items: IDataItem[], value = true) {
    const collectionId = items.length === 1 ? items[0].collection_id : this.collectionId;
    this.library.setUnread(items, collectionId, value);
  }

  exportBIB(items: IDataItem[]) {
    this.exporter.openExportItemsDialog({ type: 'bib', collection: this.collection, items });
  }

  exportRIS(items: IDataItem[]) {
    this.exporter.openExportItemsDialog({ type: 'ris', collection: this.collection, items });
  }

  exportCSV(items: IDataItem[]) {
    this.exporter.openExportItemsDialog({ type: 'csv', collection: this.collection, items });
  }

  exportXLSX(items: IDataItem[]) {
    this.exporter.openExportItemsDialog({ type: 'xlsx', collection: this.collection, items });
  }

  exportJSON(items: IDataItem[]) {
    this.exporter.openExportItemsDialog({ type: 'json', collection: this.collection, items });
  }

  delete(items: IDataItem[]) {
    const collectionId = items.length === 1 ? items[0].collection_id : this.collectionId;
    const itemIds = items.map(item => item.id);
    return this.shell.openConfirm<IDataItem[]>({
      title: DELETE_ITEM_MODAL_TITLE,
      message: DELETE_ITEM_MODAL_TEXT,
      confirmButtonText: 'Delete',
      progressText: 'Deleting...'
    }, (helper) => {
      helper.setPercent(0);
      return lastValueFrom(this.dataItem.bulkDelete(collectionId, itemIds).pipe(
        tap(r => helper.setPercent(r.percent)),
        map(r => r.data)
      ));
    }).then(deleted => {
      this.onDeleted.next(deleted);
    });
  }

  linkFile(items: IDataItem[]) {
    const collectionId = items.length === 1 ? items[0].collection_id : this.collectionId;
    this.library.linkFile(items, collectionId);
  }

  removeFromList(collectionId: string, listId: string, items: IDataItem[]) {
    this.library.removeFromList(collectionId, listId, items)
  }

  downloadFile(items: IDataItem[]) {
    const item = items[0];
    const file = item.files[0];
    const params = { hash: file.sha256, file_name: file.name };
    this.dataItem.downloadFileURL(item.collection_id, item.id, params).then(url => window.open(url));
  }

  locatePDF(items: IDataItem[]) {
    this.bulk.locateAllPDFs(this.collectionId, this.listId, items);
  }

  openPublisherWithProxy(items: IDataItem[]) {
    const item = items[0];
    const proxyUrl = this.shared.user?.organization_access_proxy_url || this.shared.user?.proxy_url;
    const url = this.reader.getDirectUrl(item, proxyUrl);
    this.shell.openURL(url);
  }

  canOpenPublisherWithProxy(item: IDataItem): boolean {
    if (!this.library.isIdentified(item)) return false;
    return !!this.shared.user?.proxy_url || !!this.shared.user?.organization_access_proxy_url;
  }

  openPublisher(items: IDataItem[]) {
    const item = items[0];
    this.shared.openPublisherById(item.ext_ids.doi, 'doi');
  }

  canOpenPublisher(item: IDataItem): boolean {
    return this.library.isIdentified(item);
  }

  openDimensions(items: IDataItem[]) {
    const item = items[0];
    this.shell.openURL(`https://app.dimensions.ai/doi/${item.ext_ids.doi}`);
  }

  canOpenDimensions(item: IDataItem): boolean {
    return !!item.ext_ids.doi;
  }

  openPubmed(items: IDataItem[]) {
    const item = items[0];
    this.shared.openPubmed(item.ext_ids.pmid);
  }

  canOpenPubmed(item: IDataItem): boolean {
    return !!item.ext_ids.pmid;
  }

  openEBSCO(items: IDataItem[]) {
    const item = items[0];
    this.shell.openURL(`https://openurl.ebsco.com/openurl?rft_id=info:doi/${item.ext_ids.doi}`);
  }

  canOpenEBSCO(item: IDataItem): boolean {
    // https://readcube.atlassian.net/browse/WEBAPP-1792
    // Temporarily disabled.
    return false;
    return !!item.ext_ids.doi;
  }

  openCopyright(items: IDataItem[]) {
    const item = items[0];
    if (this.shared.user?.licence?.copyright_licence == 'acl_advisor') {
      const id = item.article.isbn || item.article.eisbn || item.article.eissn || item.article.issn;
      this.shell.openURL(`https://rightfind.copyright.com/rs-ui-web/search#journal/${encodeURIComponent(id)}`);
    } else if (this.shared.user?.licence?.copyright_licence == 'acl_enterprise' && item.ext_ids.doi) {
      this.shell.openURL(`https://www.rightfind.com/vlib/order/OpenURLReceive.aspx?clientid=${this.shared.user?.licence?.copyright_acl_id}&action=rights&doi=${encodeURIComponent(item.ext_ids.doi)}`);
    } else if (this.shared.user?.licence?.copyright_licence == 'acl_enterprise' && item.ext_ids.pmid) {
      this.shell.openURL(`https://www.rightfind.com/vlib/order/OpenURLReceive.aspx?clientid=${this.shared.user?.licence?.copyright_acl_id}&action=rights&pmid=${encodeURIComponent(item.ext_ids.pmid)}`);
    }
  }

  setCopyrightStatus(items: IDataItem[], status: 'covered' | 'not_covered') {
    const collectionId = items.length === 1 ? items[0].collection_id : this.collectionId;
    this.library.setCopyrightStatus(items, collectionId, status);
  }

  openOrderReprint(items: IDataItem[]) {
    const item = items[0];
    const id = item.ext_ids.doi || item.ext_ids.pmid;
    const field317 = encodeURIComponent(id);
    const field4 = encodeURIComponent(this.shared.user.email);
    const field320 = encodeURIComponent(this.shared.user.name);
    const field3 = encodeURIComponent(this.shared.user.organization_name);
    this.shell.openURL(`https://readcube.wufoo.com/forms/?formname=zalpmf804x4ngz&field317=${field317}&field4=${field4}&field320=${field320}&field3=${field3}`);
  }

  viewInLibrary(items: IDataItem[]) {
    const item = items[0];
    this.shared.viewInLibrary(item.collection_id, item.id);
  }

  editDetails(items: IDataItem[]) {
    const firstItem = items[0];
    this.onEditDetails.next({ item: firstItem });
  }

  updateDetails(items: IDataItem[]): Promise<IDataItem[]> {
    if (items.length < environment.bulkRequestSize) {
      return lastValueFrom(this.dataItem.bulkResolve(this.collectionId, items).pipe(
        map(r => r.data)
      ));
    }
    return this.shell.openConfirm<IDataItem[]>({
      title: UPDATE_DETAILS_ITEM_MODAL_TITLE,
      message: UPDATE_DETAILS_ITEM_MODAL_TEXT
    }, (helper) => {
      helper.setPercent(0);
      return lastValueFrom(this.dataItem.bulkResolve(this.collectionId, items).pipe(
        tap(r => helper.setPercent(r.percent)),
        map(r => r.data)
      ));
    });
  }

  clearDetails(items: IDataItem[]): Promise<IDataItem[]> {
    return this.shell.openConfirm<IDataItem[]>({
      title: CLEAR_DETAILS_ITEM_MODAL_TITLE,
      message: CLEAR_DETAILS_ITEM_MODAL_TEXT
    }, (helper) => {
      helper.setPercent(0);
      return lastValueFrom(this.dataItem.bulkClearMetadata(this.collectionId, items).pipe(
        tap(r => helper.setPercent(r.percent)),
        map(r => r.data)
      ));
    });
  }

  openResolver(items: IDataItem[]) {
    this.onOpenResolver.next({ item: items[0] });
  }

  copyToMyLibrary(items: IDataItem[]) {
    this.library.copyItems(items, { collectionId: this.shared.user.id });
  }

  deleteSelection() {
    this.delete(this.items);
  }

  copyTextToClipboard(text: string) {
    this.shell.copyToClipboard({ text });
  }

  private isCopyrightAvailable(item: IDataItem): boolean {
    if (this.shared.user?.licence?.copyright_licence == 'acl_advisor' && (!!item.article?.isbn || !!item.article?.issn || !!item.article?.eisbn || !!item.article?.eissn))
      return true;
    return this.shared.user?.licence?.copyright_licence == 'acl_enterprise' && (!!item.ext_ids?.doi || !!item.ext_ids?.pmid);
  }

  addToLiteratureReview(items: IDataItem[]) {
    this.bulk.bulkLiteratureReview(this.collection.id, this.listId, items);
  }
}
