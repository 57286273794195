<ng-template #loadingTemplate>
  <common-loader-circles></common-loader-circles>
</ng-template>

<context-menu #rolesContextMenu (close)="isRolesMenuOpen = false" (open)="isRolesMenuOpen = true">
  <ng-template contextMenuItem (execute)="setExistingMemberRole($event.item, role.key)" *ngFor="let role of memberRoles | keyvalue" [visible]="role.key != 'owner'">
    {{role.value}}
  </ng-template>
</context-menu>

<form #collectionForm="ngForm" (ngSubmit)="submit()">

  <div class="modal-header">
    <h4 class="modal-title">{{ getTitle() }}</h4>
    <button type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body">
    <div [hidden]="!(error$ | async)" class="alert alert-danger">{{error$ | async}}</div>

    <p *ngIf="!isRenameMode">
      Papers supports group collaboration with other Papers users using shared libraries,
      where you can share and organize references, papers and annotations with colleagues.
      Papers supports responsible sharing. Learn more at <a href="http://www.howcanishareit.com" target="_blank">howcanishareit.com</a>.
    </p>

    <ng-container class="loader" *ngIf="!(loading$ | async), else loadingTemplate">

      <div class="mt-2" *ngIf="!collectionId || isRenameMode">
        <div class="col-sm-12" style="min-height: 95px;">
          <ng-container *ngIf="!!collectionEditable">
            <div class="form-floating">
              <input name="library-name-input" type="text" class="form-control"
              #name="ngModel"
                [(ngModel)]="collectionEditable.name"
                autocomplete="off"
                maxlength="50"
                ngbAutofocus
                emptyValidator
                required>
              <span class="form-text text-muted" *ngIf="name.control.errors?.required && name.touched">Library name is required.</span>
              <span class="form-text text-muted" *ngIf="name.control.errors?.empty && name.touched">Library name cant't be empty.</span>
              <label for="library-name-input">Library name</label>
            </div>
          </ng-container>
        </div>
      </div>
  
      <ng-container *ngIf="!isRenameMode">
        <div class="mt-2" *ngIf="collectionEditable?.members?.length">
          <div class="col-sm-12">
            <label class="fw-bold">Who has access</label>
            <div class="members-component papers-scrollbar papers-border" (scroll)="closeContextMenus()">
              <ul>
                <ng-container *ngFor="let member of collectionEditable.members">
                  <li>
                    <ng-container *ngIf="member.type === memberTypes.user, else groupInfoTemplate">
                      <div class="icon" [ngSwitch]="isRegistered(member.email)">
                        <rcp-svg-icon name="person" *ngSwitchCase="true"></rcp-svg-icon>
                        <rcp-svg-icon name="email" *ngSwitchCase="false"></rcp-svg-icon>
                      </div>
                      <div class="email">{{member.email}}</div>
                      <div class="pending" *ngIf="isPending(member.email)">PENDING INVITE</div>
                    </ng-container>
  
                    <ng-template #groupInfoTemplate>
                      <div class="icon">
                        <fa-icon [icon]="['fas', 'users']"></fa-icon>
                      </div>
                      <div class="email">{{member.name}}</div>
                    </ng-template>
  
                    <div class="access" *ngIf="canManageRoles">
                      <div class="dropdown d-inline-block">
                        <button type="button" class="btn btn-sm btn-secondary dropdown-toggle" (click)="openRolesMenu($event, member)" [disabled]="!canChangeMemberRole(member)">{{memberRoles[member.role]}}</button>
                      </div>
                    </div>
                    <div class="remove" [ngClass]="{ 'invisible': !canDeleteMember(member) }">
                      <a href (click)="onRemoveMemberClick($event, member)" ngbTooltip="Remove user">
                        <rcp-svg-icon name="close"></rcp-svg-icon>
                      </a>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
  
        <div class="mt-2">
          <div class="col-sm-12">
            <label
              for="invite-input"
              class="invite-tab"
              [ngClass]="{
                'invite-tab--underline': availableGroups?.length && invitePeople,
                'pointer': availableGroups?.length
              }"
              (click)="invitePeople = true">
              Invite people
            </label>
            <ng-container *ngIf="availableGroups?.length">
              &nbsp;&#124;&nbsp;
              <label
                for="group-input"
                class="pointer invite-tab"
                (click)="invitePeople = false"
                [ngClass]="{'invite-tab--underline': !invitePeople}" >
                Invite groups
              </label>
            </ng-container>
  
            <div [hidden]="!invitePeople">
              <rcp-token-field
                #invites="ngModel"
                name="invites-input"
                class="form-control"
                [search]="emailType$"
                [(text)]="emailText"
                [(ngModel)]="emails"
                (ngModelChange)="updateEmailsInfo($event)"
                [errors]="getEmailErrors(invites.errors)"
                [focusFirst]="true"
                placeholder="Enter emails to invite users..."
                emailValidator
                emailInEnterpriseValidator
                emailInvitedValidator
                [emailInvitedList]="emailInvitedList">
  
                <ng-template #tokenTemplate let-email="item">
                  <span class="icon" [ngSwitch]="isRegistered(email)">
                    <rcp-svg-icon name="person" *ngSwitchCase="true"></rcp-svg-icon>
                    <rcp-svg-icon name="email" *ngSwitchCase="false"></rcp-svg-icon>
                  </span> {{email}}
                </ng-template>
  
                <ng-template #resultTemplate let-result="result">
                  {{result}}
                </ng-template>
              </rcp-token-field>
              <span class="form-text text-muted" *ngIf="invites.pending">Checking...</span>
            </div>
  
            <div [hidden]="invitePeople">
              <rcp-token-field
                #groupInvites="ngModel"
                name="groups-input"
                class="form-control"
                [(ngModel)]="groups"
                [search]="searchGroups$"
                [delimiter]="null"
                (ngModelChange)="onGroupChange($event)"
                [placeholder]="'Start typing to select group...'"
                [errors]="getGroupErrors(groupInvites.errors)"
                groupInvitedValidator
                [groupInvitedList]="groupInvitedList">
  
                <ng-template #tokenTemplate let-group="item">
                  <span class="icon">
                    <fa-icon [icon]="['fas', 'users']"></fa-icon>
                  </span> {{group.name}}
                </ng-template>
  
                <ng-template #resultTemplate let-result="result" let-term="term">
                  <span>{{result.name}}</span>
                </ng-template>
              </rcp-token-field>
            </div>
  
          </div>
        </div>
  
        <div class="mt-4" *ngIf="canManageRoles">
          <div class="col-sm-12">
            <label for="tags-input" class="fw-bold">
              Invite with permissions:
              <div ngbDropdown placement="bottom-right" class="d-inline-block">
                <button type="button" class="btn btn-sm btn-secondary" ngbDropdownToggle>{{memberRoles[invitationRole]}}</button>
                <div ngbDropdownMenu>
                  <ng-container *ngFor="let role of memberRoles | keyvalue">
                    <button type="button" class="dropdown-item" (click)="onChangeInvitationRole(role.key)" *ngIf="role.key != 'owner'">{{role.value}}</button>
                  </ng-container>
                </div>
              </div>
            </label>
          </div>
        </div>
  
        <p class="text-danger" *ngIf="invites.errors && invites.errors.emailNotInEnterprise">
          Your enterprise security policies only allow you to invite members within your organization. If the email you are attempting to add is a member of your organization, please contact your administrator as they may not yet be added to the account.
        </p>
  
        <div class="mt-2" *ngIf="canManageRoles">
          <div class="col-sm-12">
            <div class="sharing-label mb-2">
              <span (click)="onSharingExpandedToggle()" class="icon">
                <fa-icon [icon]="['fas', sharingExpanded ? 'caret-down': 'caret-right']"></fa-icon>
              </span>
              <div (click)="onSharingExpandedToggle()" class="fw-bold pointer">Advanced Sharing Options</div>
              <a class="ms-1" href="https://support.papersapp.com/support/solutions/articles/30000044559-enterprise-permissions-for-shared-libraries" target="_blank">Learn More</a>
            </div>
  
            <ng-container *ngIf="sharingExpanded">
              <div class="sharing-table__row">
                <div class="sharing-table__head">
                </div>
                <div class="sharing-table__head">
                  Member
                </div>
                <div class="sharing-table__head">
                  Viewer
                </div>
              </div>
  
              <div class="sharing-table__body papers-scrollbar papers-border">
                <div class="sharing-table__row" *ngFor="let group of sharingGroups">
                  <div class="sharing-table__cell">
                    {{ group.displayName }}
                  </div>
  
                  <div class="sharing-table__cell">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" [id]="group.memberId" [name]="group.memberId" [(ngModel)]="collectionEditable.role_settings[group.memberId]">
                    </div>
                  </div>
                  <div class="sharing-table__cell">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" [id]="group.viewerId" [name]="group.viewerId" [(ngModel)]="collectionEditable.role_settings[group.viewerId]">
                    </div>
                  </div>
                </div>
              </div>
  
            </ng-container>
  
          </div>
        </div>
      </ng-container>

    </ng-container>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-lg btn-secondary" (click)="close()">Close</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!collectionForm.form.valid || (disabled$ | async)">{{collectionId ? 'Save' : 'Create'}}</button>
  </div>

</form>
