import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';

import { QueryMenuComponent } from './components/query-builder.component';
import { QueryLabelDropdownComponent } from './components/query-label-dropdown.component';
import { QueryFieldSelectComponent } from './components/query-field-select.component';

import { QueryWordsComponent } from './components/query-words.component';
import { QueryFieldValueComponent } from './components/query-field-value.component';

import { QueryBuilderService } from './services/query-builder.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
  ],
  declarations: [
    QueryMenuComponent,
    QueryWordsComponent,
    QueryFieldSelectComponent,
    QueryFieldValueComponent,
    QueryLabelDropdownComponent
  ],
  exports: [QueryMenuComponent],
  providers: [QueryBuilderService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppQueryMenuModule {
  static forRoot(): ModuleWithProviders<AppQueryMenuModule> {
    return {
      ngModule: AppQueryMenuModule,
      providers: []
    };
  }
}
