import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';

import { AppWebSocketService } from '../../app-websocket.service';
import { IDataResult } from '../../app-data-result.model';
import { environment } from 'environment';

export interface IDataList {
  id: string;
  collection_id: string;
  parent_id: string | null;
  name: string;
  deleted: boolean;
  public_id: string;
}

export interface IDataListQueryParams {
  collection_id: string;
  created_since?: string;
  modified_since?: string;
}

export interface IDataListNotification {
  action: 'created' | 'updated' | 'deleted';
  id: string;
  type: 'list';
}

@Injectable()
export class DataListApiService {

  constructor(
    private http: HttpClient,
    private ws: AppWebSocketService
  ) { }

  public listen(collectionId: string, listId?: string): Observable<IDataListNotification[]> {
    return this.ws.listen(`/collections/${collectionId}/changes`).pipe(
      map(response => response.changes.find(c => {
        return c.type === 'list' && (c.id === listId || !listId);
      })),
      filter(c => Array.isArray(c) && c.length > 0)
    );
  }

  public query(params?: IDataListQueryParams): Observable<IDataResult<IDataList>> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${params.collection_id}/lists`;
    return this.http.get<IDataResult<IDataList>>(endpointUrl).pipe(
      map(body => this.createResult(body))
    );
  }

  public get(collectionId: string, listId: string): Observable<IDataList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/lists/${listId}`;
    return this.http.get<any>(endpointUrl).pipe(
      map(body => this.mapListData(body.list))
    );
  }

  public post(list: Partial<IDataList>): Promise<IDataList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${list.collection_id}/lists`;
    return firstValueFrom(this.http.post<any>(endpointUrl, { 'list': list }).pipe(
      map(body => this.mapListData(body.list)),
    ));
  }

  public patch(list: Partial<IDataList>): Promise<IDataList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${list.collection_id}/lists/${list.id}`;
    return firstValueFrom(this.http.patch<any>(endpointUrl, { 'list': list }).pipe(
      map(body => this.mapListData(body.list)),
      catchError((response) => {
        if (response?.error?.error === 'inactive_subscription') {
          return this.get(list.collection_id, list.id);
        }
        return null;
      })
    ));
  }

  public delete(collectionId: string, listId: string): Promise<IDataList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/lists/${listId}`;
    return firstValueFrom(this.http.delete<any>(endpointUrl).pipe(
      map(body => this.mapListData(body.list))
    ));
  }

  public addItems(collectionId: string, listId: string, itemIds: string[]): Promise<IDataList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/lists/${listId}/add_items`;
    return firstValueFrom(this.http.post<any>(endpointUrl, { item_ids: itemIds }).pipe(
      map(body => this.mapListData(body.list))
    ));
  }

  public removeItems(collectionId: string, listId: string, itemIds: string[]): Promise<IDataList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/lists/${listId}/remove_items`;
    return firstValueFrom(this.http.post<any>(endpointUrl, { item_ids: itemIds }).pipe(
      map(body => this.mapListData(body.list))
    ));
  }

  public share(collectionId: string, id: string): Observable<IDataList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/enable_public?type=list&id=${id}`;
    return this.http.post<any>(endpointUrl, {}).pipe(
      map(body => this.mapListData(body.object))
    );
  }

  public stopShare(collectionId: string, id: string): Observable<IDataList> {
    const endpointUrl = `${environment.baseUrls.sync}/collections/${collectionId}/disable_public?type=list&id=${id}`;
    return this.http.post<any>(endpointUrl, {}).pipe(
      map(body => this.mapListData(body.object))
    );
  }

  private createResult(data: any): IDataResult<IDataList> {
    return {
      refinements: data['refinements'] || {},
      rows: (data['lists'] || []).map(d => this.mapListData(d)),
      totalHuman: data['total_human'] || 'No lists',
      total: data['total'] || 0
    };
  }

  private mapListData(data: any): IDataList {
    return {
      id: data['id'],
      collection_id: data['collection_id'],
      parent_id: data['parent_id'] || null,
      name: data['name'] ? data['name'].toString() : '',
      deleted: data['deleted'] || false,
      public_id: data['public_id'] || null
    };
  }
}
