import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataOrganizationApiService, IDataOrganization } from './data-organization-api.service';

@Injectable()
export class DataOrganizationService {

  constructor(
    public api: DataOrganizationApiService
  ) { }

  query(): Observable<IDataOrganization> {
    return this.api.query();
  }
}
