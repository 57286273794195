import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeKatexModule } from '@readcube/rcp-katex';
import { ReadCubeSvgIconsModule } from '@readcube/rcp-svg-icons';

import { AppCommonModule } from '../common';
import { AppMetricsModule } from '../metrics';

import { SearchSidepanelHeaderComponent } from './components/search-sidepanel-header.component';
import { SearchSidepanelDetailsComponent } from './components/search-sidepanel-details.component';
import { SearchSidepanelMetricsComponent } from './components/search-sidepanel-metrics.component';

import { SearchSidepanelService } from './services/search-sidepanel.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    ReadCubeCommonModule,
    ReadCubeSvgIconsModule,
    ReadCubeKatexModule,
    AppCommonModule,
    AppMetricsModule,
  ],
  declarations: [
    SearchSidepanelHeaderComponent,
    SearchSidepanelDetailsComponent,
    SearchSidepanelMetricsComponent
  ],
  providers: [
    SearchSidepanelService
  ]
})
export class AppSearchSidepanelModule {}
