<ng-container *ngIf="!(available$ | async)">
  <p class="na-icon"><fa-icon [icon]="['fas', 'ban']" size="6x"></fa-icon></p>
  <div class="na-text">PREVIEW NOT AVAILABLE</div>
</ng-container>

<div class="error" *ngIf="error$ | async as error">
  <p class="default-font">{{error.message || error}}</p>
</div>

<div #container class="preview-container" [hidden]="(loading$ | async) || !(available$ | async)"></div>

<div class="loading-container" *ngIf="(loading$ | async) && (available$ | async)">
  <common-loader-circles class="preview-loading" color="white"></common-loader-circles>
</div>
