import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDataUser } from '../../common-data';

@Component({
  templateUrl: './modal-shared-library-limit.component.html'
})
export class ModalSharedLibraryLimitComponent {
  user: IDataUser;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.close();
  }
}
