import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadCubeCommonModule } from '@readcube/rcp-common';
import { ReadCubeContextMenuModule } from '@readcube/ngx-contextmenu';

import { AppSearchMenuComponent } from './components/search-menu.component';

@NgModule({
  imports: [
    CommonModule,
    ReadCubeCommonModule,
    ReadCubeContextMenuModule,
  ],
  declarations: [
    AppSearchMenuComponent,
  ],
  exports: [
    AppSearchMenuComponent,
  ]
})
export class AppSearchMenuModule { }
