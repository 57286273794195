import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataStatusApiService } from './services/data-status-api.service';
import { DataUserApiService } from './services/data-user-api.service';
import { DataDimensionsApiService } from './services/data-dimensions-api.service';
import { DataOrganizationApiService } from './services/data-organization-api.service';
import { DataStylesApiService } from './services/data-styles-api.service';

import { DataUserService } from './services/data-user.service';
import { DataOrganizationService } from './services/data-organization.service';
import { DataDimensionsService } from './services/data-dimensions.service';
import { DataStatusService } from './services/data-status.service';
import { DataStylesService } from './services/data-styles.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DataStatusApiService,
    DataUserApiService,
    DataDimensionsApiService,
    DataOrganizationApiService,
    DataStatusApiService,
    DataStylesApiService,
    DataUserApiService,
  ]
})
export class AppCommonDataModule {
  static forRoot(): ModuleWithProviders<AppCommonDataModule> {
    return {
      ngModule: AppCommonDataModule,
      providers: [
        DataUserService,
        DataOrganizationService,
        DataDimensionsService,
        DataStatusService,
        DataStylesService
      ]
    };
  }
}
