export const MERGE_ITEM_MODAL_TITLE = 'Confirm merge';
export const MERGE_ITEM_MODAL_TEXT = 'Are you sure you want to merge selected references?';

export const DELETE_ITEM_MODAL_TITLE = 'Confirm delete';
export const DELETE_ITEM_MODAL_TEXT = 'Are you sure you want to permanently delete selected items from your library?';

export const UPDATE_DETAILS_ITEM_MODAL_TITLE = 'Confirm refresh';
export const UPDATE_DETAILS_ITEM_MODAL_TEXT = 'Are you sure you want to update metadata for selected references?';

export const CLEAR_DETAILS_ITEM_MODAL_TITLE = 'Clear details';
export const CLEAR_DETAILS_ITEM_MODAL_TEXT = 'Are you sure you want to clear details from selected items?';

export const FLAG_ITEM_MODAL_TITLE = 'Confirm update';
export const FLAG_ITEM_MODAL_TEXT = 'Are you sure you want to update flag for selected references?';

export const LINK_FILE_ITEM_MODAL_TITLE = 'Confirm link';
export const LINK_FILE_ITEM_MODAL_TEXT = 'Are you sure you want to link existing files for selected references?';

export const SET_COPYRIGHT_STATUS_ITEM_MODAL_TITLE = 'Confirm update';
export const SET_COPYRIGHT_STATUS_ITEM_MODAL_TEXT = 'Are you sure you want to set copyright status for selected references?';

export const UNREAD_ITEM_MODAL_TITLE = 'Confirm update';
export const UNREAD_ITEM_MODAL_TEXT = 'Are you sure you want to update read status for selected references?';

export const READCUBE_CUSTOM_STYLE = `
<?xml version="1.0" encoding="utf-8"?>
<style xmlns="http://purl.org/net/xbiblio/csl" class="in-text" version="1.0" demote-non-dropping-particle="never">
  <!-- This style was edited with the Visual CSL Editor (https://editor.citationstyles.org/visualEditor/) -->
  <info>
    <title>ReadCube Custom Style</title>
    <title-short>RC Style</title-short>
    <id>http://www.zotero.org/styles/readcube-custom-style</id>
    <link rel="self" href="http://www.zotero.org/styles/readcube-custom-style"/>
    <author>
      <email>jacob@readcube.com</email>
      <name>Jacob Mulberry</name>
    </author>
    <author>
      <name>Josip Jurjevic</name>
      <email>josip@readcube.com</email>
    </author>
    <author>
      <name>Renato Bebić</name>
      <email>renato@readcube.com</email>
    </author>
    <updated>2020-11-06T17:29:16+00:00</updated>
  </info>
  <citation>
    <layout>
      <text term="close-inner-quote"/>
    </layout>
  </citation>
  <bibliography>
    <layout>
      <text variable="title" font-weight="bold" display="block"/>
      <names variable="author editor recipient collection-editor interviewer composer" font-style="italic" display="block"/>
      <text variable="abstract" display="block"/>
      <group text-decoration="underline" display="block">
        <text variable="container-title" text-decoration="none" suffix=" "/>
        <date date-parts="year" form="text" variable="issued" text-decoration="none"/>
      </group>
      <group text-decoration="underline">
        <choose>
          <if match="any" variable="DOI">
            <text value="https://dx.doi.org/" text-decoration="none"/>
            <text variable="DOI"/>
          </if>
          <if match="any" variable="PMID">
            <text value="https://pubmed.ncbi.nlm.nih.gov/" text-decoration="none"/>
            <text variable="PMID"/>
          </if>
        </choose>
      </group>
    </layout>
  </bibliography>
</style>`;

export const NATURE_STYLE = `
<?xml version="1.0" encoding="utf-8"?>
<style xmlns="http://purl.org/net/xbiblio/csl" class="in-text" version="1.0" demote-non-dropping-particle="sort-only" default-locale="en-GB">
  <info>
    <title>Nature</title>
    <id>http://www.zotero.org/styles/nature</id>
    <link href="https://smartcite-assets.readcube.com/merged-styles/nature" rel="self"/>
    <link href="http://www.nature.com/nature/authors/gta/index.html#a5.4" rel="documentation"/>
    <link href="http://www.nature.com/srep/publish/guidelines#references" rel="documentation"/>
    <author>
      <name>Michael Berkowitz</name>
      <email>mberkowi@gmu.edu</email>
    </author>
    <category citation-format="numeric"/>
    <category field="science"/>
    <category field="generic-base"/>
    <issn>0028-0836</issn>
    <eissn>1476-4687</eissn>
    <updated>2019-10-10T03:01:26+00:00</updated>
    <rights license="http://creativecommons.org/licenses/by-sa/3.0/">This work is licensed under a Creative Commons Attribution-ShareAlike 3.0 License</rights>
  </info>
  <macro name="title">
    <choose>
      <if type="bill book graphic legal_case legislation motion_picture report song" match="any">
        <text variable="title" font-style="italic"/>
      </if>
      <else>
        <text variable="title"/>
      </else>
    </choose>
  </macro>
  <macro name="author">
    <names variable="author">
      <name sort-separator=", " delimiter=", " and="symbol" initialize-with=". " delimiter-precedes-last="never" name-as-sort-order="all"/>
      <label form="short" prefix=", "/>
      <et-al font-style="italic"/>
    </names>
  </macro>
  <macro name="access">
    <choose>
      <if variable="volume"/>
      <else-if variable="DOI">
        <text variable="DOI" prefix="doi:"/>
      </else-if>
    </choose>
  </macro>
  <macro name="issuance">
    <choose>
      <if type="bill book graphic legal_case legislation motion_picture song thesis chapter paper-conference" match="any">
        <group delimiter="; " suffix=".">
          <group delimiter=", " prefix="(" suffix=")">
            <text variable="publisher" form="long"/>
            <date variable="issued">
              <date-part name="year"/>
            </date>
          </group>
        </group>
      </if>
      <else-if type="report webpage post post-weblog" match="any">
        <group delimiter=" ">
          <text variable="URL"/>
          <date date-parts="year" form="text" variable="issued" prefix="(" suffix=")"/>
        </group>
      </else-if>
      <else>
        <date variable="issued" prefix="(" suffix=")">
          <date-part name="year"/>
        </date>
      </else>
    </choose>
  </macro>
  <macro name="container-title">
    <choose>
      <if type="article-journal">
        <text variable="container-title" font-style="italic" form="short"/>
      </if>
      <else>
        <text variable="container-title" font-style="italic"/>
      </else>
    </choose>
  </macro>
  <macro name="editor">
    <choose>
      <if type="chapter paper-conference" match="any">
        <names variable="editor" prefix="(" suffix=")">
          <label form="short" suffix=" "/>
          <name and="symbol" delimiter-precedes-last="never" initialize-with=". " name-as-sort-order="all"/>
        </names>
      </if>
    </choose>
  </macro>
  <macro name="volume">
    <choose>
      <if type="article-journal" match="any">
        <text variable="volume" font-weight="bold" suffix=","/>
      </if>
      <else>
        <group delimiter=" ">
          <label variable="volume" form="short"/>
          <text variable="volume"/>
        </group>
      </else>
    </choose>
  </macro>
  <citation collapse="citation-number">
    <sort>
      <key variable="citation-number"/>
    </sort>
    <layout vertical-align="sup" delimiter=",">
      <text variable="citation-number"/>
    </layout>
  </citation>
  <bibliography et-al-min="6" et-al-use-first="1" second-field-align="flush" entry-spacing="0" line-spacing="2">
    <layout suffix=".">
      <text variable="citation-number" suffix="."/>
      <group delimiter=" ">
        <text macro="author" suffix="."/>
        <text macro="title" suffix="."/>
        <choose>
          <if type="chapter paper-conference" match="any">
            <text term="in"/>
          </if>
        </choose>
        <text macro="container-title"/>
        <text macro="editor"/>
        <text macro="volume"/>
        <text variable="page"/>
        <text macro="issuance"/>
        <text macro="access"/>
      </group>
    </layout>
  </bibliography>
</style>`;