import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
    ngOnInit() {

    }
}
