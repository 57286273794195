import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDropdown, NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge, of, switchMap } from 'rxjs';

import { AppShellService } from '../../app-shell.service';
import { HistoryService, SharedService } from '../../common';
import { IDataUser } from '../../common-data';
import { IDataCollectionField } from '../../library-data';
import { SearchService } from '../services/search.service';
import { FieldInputCapabilities, FieldInputOptions, QueryBuilderService, QueryFormData, QueryFormValueType } from '../../query-builder';

@Component({
  selector: 'search-query-header',
  styleUrls: ['./search-query-header.component.scss'],
  templateUrl: './search-query-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchQueryHeaderComponent {
  @Input()
  viewName: string;

  @Input()
  user: IDataUser;

  @Input()
  searchPlaceholder: string;

  @Input()
  sort: string;

  @Input()
  order: string;

  @Input()
  query: string = '';

  @Input()
  queryType: string; // TODO

  @Output()
  viewNameChange = new EventEmitter<string>();

  @Input()
  fields: IDataCollectionField[] = [];

  @Input()
  fieldInputCapabilities: FieldInputCapabilities = {};

  @Input()
  fieldDefaultInputCapabilities: { [key: string]: QueryFormValueType } = {};

  @Input()
  fieldInputOptions: FieldInputOptions = {};

  @ViewChild('filterToggleButton', { static: false })
  filterToggleButton: ElementRef<any>;

  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef<any>;

  @ViewChild('searchTypeahead', { static: false })
  searchTypeahead: NgbTypeahead;

  @ViewChild('filterDropdown', { static: false })
  filterDropdown: NgbDropdown;

  @ViewChild('filterDropdownMenu', { static: false, read: ElementRef })
  filterDropdownMenuRef: ElementRef<HTMLElement>;

  @HostListener('window:keydown', ['$event'])
  handleWindowKeyDown(event: KeyboardEvent) {
    if (event.key == 'f' && (event.ctrlKey || event.metaKey)) {
      this.focusOnSearchInput();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onDocumentClick(event: PointerEvent) {
    // Workaround to fix autoClose when clicking inside nested dropdown menu.
    if (!this.filterDropdown?.isOpen()) return;
    const targetElement = event.target as HTMLElement;
    const isClickInsideFilterDropdownMenu = this.filterDropdownMenuRef.nativeElement.contains(targetElement);
    const isClickInsideAnotherDropdownMenu = Array.from(document.body.querySelectorAll('.dropdown-menu'))
      .filter(element => this.filterDropdownMenuRef.nativeElement != element)
      .some(element => element.contains(targetElement));

    if (!isClickInsideFilterDropdownMenu && !isClickInsideAnotherDropdownMenu) {
      this.filterDropdown.close();
      event.stopPropagation();
    }
  }

  queryFormData: QueryFormData = null;
  searchClick$ = new Subject<string>();

  searchTypeaheadFilter: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged(), map(text => text?.toLowerCase()));
    const clicksWithClosedPopup$ = this.searchClick$.pipe(filter(() => !this.searchTypeahead.isPopupOpen()));

    return merge(debouncedText$, clicksWithClosedPopup$).pipe(switchMap(term => {
      return of(this.history.getSearchHistory('readcube')
      .filter(s => s.toLowerCase().includes(term))
      .slice(0, 5)
      .map(value => {
        return { type: 'history', value };
      }));
    }));
  };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public modal: NgbModal,
    public shared: SharedService,
    public searchService: SearchService,
    public queryBuilder: QueryBuilderService,
    public history: HistoryService,
    public shell: AppShellService
  ) { }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.queryFormData = this.queryBuilder.parseQueryFragment(fragment);
    });

    if (this.queryBuilder.parseQueryFragment(this.route.snapshot.fragment)) {
      setTimeout(() => this.filterDropdown?.open(), 500);
    }
  }

  focusOnSearchInput() {
    if (this.searchInput) {
      const event = new Event('blur');
      document.dispatchEvent(event);
      this.searchInput.nativeElement.focus();
    }
  }

  changeView(viewName: string) {
    this.viewName = viewName;
    this.viewNameChange.next(viewName);
  }

  onTypeChange(value: string) {
    this.router.navigate([], {
      queryParams: { type: value, size: null },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onSortChange(value: string) {
    this.router.navigate([], {
      queryParams: { sort: value, size: null },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onOrderChange(value: string) {
    this.router.navigate([], {
      queryParams: { order: value, size: null },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onSearchClearClick(event: MouseEvent) {
    this.query = '';
    this.submit();
  }

  onSearchEnterKey(event: KeyboardEvent) {
    this.submit();
  }

  onSearchSelectItem(event: NgbTypeaheadSelectItemEvent) {
    if (event.item.type === 'tag') {
      this.query = `tag:"${event.item.value}"`;
    } else {
      this.query = event.item.value;
    }
    this.submit();
    event.preventDefault();
  }

  onSearchTabKey(event: KeyboardEvent) {
    this.filterDropdown.open();
    this.filterToggleButton.nativeElement.focus();
    event.preventDefault();
  }

  updateQuery(data: QueryFormData) {
    this.query = this.queryBuilder.serialize(data);
    this.queryFormData = data;
  }

  submit(closeSidepanel = false) {
    const queryParams = { query: this.query.trim(), reload: Date.now().toString() };
    const queryParamsHandling = 'merge';
    const fragment = this.queryBuilder.toQueryFragment(this.queryFormData);

    this.router.navigate(closeSidepanel ? [{
      outlets: { 'sidepanel': null }
    }] : [], {
      queryParams,
      queryParamsHandling,
      fragment,
    }).then(() => {
      this.focusOnSearchInput();
    });
  }

  /// TODO: remove when WEBAPP-1820?
  // onSearchKeyClick(event: MouseEvent, key: string) {
  //   let q = (this.query || '').trim().concat(' ').concat(key).trim();
  //   let i = q.indexOf('(CARET)');
  //   q = q.replace('(CARET)', '');
  //   setTimeout(() => {
  //     setCaretPosition(this.searchInput.nativeElement, i);
  //   }, 100);
  //   this.query = q;
  //   this.filterDropdown.close();
  // }
}

// function setCaretPosition(elem: any, pos: number) {
//   if (elem.setSelectionRange) {
//     elem.focus();
//     elem.setSelectionRange(pos, pos);
//   } else if (elem.createTextRange) {
//     var range = elem.createTextRange();
//     range.collapse(true);
//     range.moveEnd('character', pos);
//     range.moveStart('character', pos);
//     range.select();
//   }
// }