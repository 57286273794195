import { Component, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { LibraryCollectionComponent } from './library-collection.component';

@Component({
  selector: 'library-collection-list',
  templateUrl: './library-collection.component.html',
  styleUrls: ['./library-collection.component.scss']
})
export class LibraryCollectionListComponent extends LibraryCollectionComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();

    this.searchPlaceholder$ = this.list$.pipe(
      map(list => list.name),
      map(name => ['Search within ', name.toLowerCase(), '...'].join('')),
      startWith('')
    );
  }
}
