import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

import {
  SharedService,
  ListTreeService,
  ModalEditCollectionComponent,
  ModalEditListComponent,
  ModalEditSmartListComponent,
  ModalLeaveLibraryComponent,
  ModalShareComponent,
  ModalEditCollectionFieldsComponent,
} from '../../common';

import { DataUserService } from '../../common-data';
import { DataCollectionService, DataItemService, DataListService, DataSmartListService, IDataCollection, IDataList, IDataSmartList } from '../../library-data';
import { AppShellService } from '../../app-shell.service';
import { AppStorageService } from '../../app-storage.service';

import { environment } from 'environment';

@Injectable()
export class LibraryNavigationService {
  protected expandedCollections: any;
  protected expandedDirectories: any;
  protected expandedListExplorerDirectories: any;

  constructor(
    protected modal: NgbModal,
    protected dataCollection: DataCollectionService,
    protected dataList: DataListService,
    protected dataSmartList: DataSmartListService,
    protected dataItem: DataItemService,
    protected dataUser: DataUserService,
    protected shared: SharedService,
    protected listTree: ListTreeService,
    protected shell: AppShellService,
    protected storage: AppStorageService,
  ) {
    this.expandedCollections = this.storage.get('navigation-expanded-collections', {});
    this.expandedDirectories = this.storage.get('navigation-expanded-directories', {});
    this.expandedListExplorerDirectories = this.storage.get('navigation-expanded-list-explorer-directories', {});
  }

  expandPathToList(collectionId: string, listId: string) {
    this.dataList.getAllByCollectionId(collectionId).pipe(first()).subscribe(lists => {
      let listNode = this.listTree.fromLists(lists).findNode(listId);
      this.setCollectionExpanded(collectionId, true);
      while (listNode = listNode.parent) {
        this.setListNodeExpanded(listNode.list.id, true);
      }
    });
  }

  renameList(list: IDataList, name: string): Promise<IDataList> {
    return this.dataList.update({ id: list.id, collection_id: list.collection_id, name: name });
  }

  renameSmartList(list: IDataSmartList, name: string): Promise<IDataSmartList> {
    return this.dataSmartList.update({ id: list.id, collection_id: list.collection_id, name: name });
  }

  renameTag(collectionId: string, name: string, newName: string): Promise<any> {
    return this.dataItem.renameTag(collectionId, name, newName);
  }

  moveList(list: IDataList, toListId: string | null): Promise<IDataList> {
    return this.dataList.update({ id: list.id, collection_id: list.collection_id, parent_id: toListId });
  }

  isCollectionExpanded(collectionId: string): boolean {
    return this.expandedCollections[collectionId];
  }

  isListNodeExpanded(listId: string): boolean {
    return this.expandedDirectories[listId];
  }

  setCollectionExpanded(collectionId: string, expanded: boolean) {
    //Object.keys(this.expandedCollections).forEach(collectionId => {
    //  this.expandedCollections[collectionId] = false;
    //});
    this.expandedCollections[collectionId] = expanded;
    this.storage.set('navigation-expanded-collections', this.expandedCollections);
  }

  setListNodeExpanded(listId: string, expanded: boolean) {
    this.expandedDirectories[listId] = expanded;
    this.storage.set('navigation-expanded-directories', this.expandedDirectories);
  }

  openDeleteList(list: IDataList): Promise<IDataList> {
    const DELETE_LIST_MODAL_TITLE = 'Delete List';
    const DELETE_LIST_MODAL_TEXT = `Are you sure you want to delete list &quot;<i>${list.name}</i>&quot;?<br><br>References will not be deleted.`;
    return this.shell.openConfirm<IDataList>({
      title: DELETE_LIST_MODAL_TITLE,
      message: DELETE_LIST_MODAL_TEXT,
      confirmButtonText: 'Delete',
      progressText: 'Deleting...'
    }, () => {
      return this.dataList.delete(list.collection_id, list.id);
    });
  }

  openRemoveTag(collectionId: string, name: string): Promise<IDataList> {
    const DELETE_TAG_MODAL_TITLE = 'Delete Tag';
    const DELETE_TAG_MODAL_TEXT = `Are you sure you want to permanently delete tag &quot;<i>${name}</i>&quot; from your library?`;
    return this.shell.openConfirm<IDataList>({
      title: DELETE_TAG_MODAL_TITLE,
      message: DELETE_TAG_MODAL_TEXT,
      confirmButtonText: 'Remove',
      progressText: 'Removing...'
    }, () => {
      return this.dataItem.removeTag(collectionId, name);
    });
  }

  openDeleteSmartList(list: IDataSmartList): Promise<IDataList> {
    const DELETE_SMARTLIST_MODAL_TITLE = 'Delete Smart List';
    const DELETE_SMARTLIST_MODAL_TEXT = `Are you sure you want to delete smart list &quot;<i>${list.name}</i>&quot;?<br><br>References will not be deleted.`;
    return this.shell.openConfirm<IDataList>({
      title: DELETE_SMARTLIST_MODAL_TITLE,
      message: DELETE_SMARTLIST_MODAL_TEXT,
      confirmButtonText: 'Delete',
      progressText: 'Deleting...'
    }, () => {
      return this.dataSmartList.delete(list.collection_id, list.id);
    });
  }

  openDeleteCollection(collection: IDataCollection): Promise<Partial<IDataCollection>> {
    const DELETE_COLLECTION_MODAL_TITLE = 'Delete Shared Library';
    const DELETE_COLLECTION_MODAL_TEXT = `Deleting a library will permanently delete the entire library and all references and files.<br><br>Any references that were copied to a personal library will not be affected.`;
    return this.shell.openConfirm<IDataCollection>({
      title: DELETE_COLLECTION_MODAL_TITLE,
      message: DELETE_COLLECTION_MODAL_TEXT,
      confirmButtonText: 'Delete',
      progressText: 'Deleting...'
    }, () => {
      return this.dataCollection.delete(collection.id);
    });
  }

  openArchiveCollection(collection: IDataCollection): Promise<IDataCollection> {
    const ARCHIVE_COLLECTION_MODAL_TITLE = 'Archive Shared Library';
    const ARCHIVE_COLLECTION_MODAL_TEXT = `Archiving a library will move it to a separate Team Archive section.<br><br>References in the archived library can still be viewed, and the library can be restored at any point.`;
    return this.shell.openConfirm<IDataCollection>({
      title: ARCHIVE_COLLECTION_MODAL_TITLE,
      message: ARCHIVE_COLLECTION_MODAL_TEXT,
      confirmButtonText: 'Archive',
      progressText: 'Archiving...'
    }, () => {
      return this.dataCollection.archive(collection.id);
    });
  }

  openLeaveCollection(collection: IDataCollection): Promise<Partial<IDataCollection>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalLeaveLibraryComponent);
        const componentInstance = <ModalLeaveLibraryComponent>modalRef.componentInstance;
        componentInstance.collectionId = collection.id;
        return modalRef.result.then(resolve, reject);
      });
    });
  }

  openEditCollection(collection: Partial<IDataCollection>, isRenameMode: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalEditCollectionComponent, { windowClass: 'papers-scrollbar' });
        const componentInstance = <ModalEditCollectionComponent>modalRef.componentInstance;
        componentInstance.collectionId = collection.id;
        componentInstance.isRenameMode = isRenameMode;
        modalRef.result.then(resolve, reject);
      });
    });
  }

  openEditList(list: Partial<IDataList>, listDepth: number = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalEditListComponent);
        const componentInstance = <ModalEditListComponent>modalRef.componentInstance;
        componentInstance.list = <IDataList>list;
        componentInstance.maxListDepth = environment.maxListsDepth - listDepth;
        modalRef.result.then(resolve, reject);
      });
    });
  }

  openEditSmartList(collection: IDataCollection, list: Partial<IDataSmartList>): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalEditSmartListComponent, { backdrop: true });
        const instance = <ModalEditSmartListComponent>modalRef.componentInstance;
        instance.collection = collection;
        instance.list = list;
        modalRef.result.then(resolve, reject);
      });
    });
  }

  openEditCustomFields(collection: IDataCollection) {
    const modalRef = this.modal.open(ModalEditCollectionFieldsComponent, { modalDialogClass: 'modal-dialog-max-content' });
    const componentInstance = <ModalEditCollectionFieldsComponent>modalRef.componentInstance;
    componentInstance.collectionId = collection.id;
    return modalRef.result.catch(err => {
      // Dialog dismissed
    });
  }

  share(type: 'collection' | 'smartlist' | 'list', entity: IDataCollection | IDataSmartList | IDataList): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const modalRef = this.modal.open(ModalShareComponent, { backdrop: true });
        const instance = <ModalShareComponent>modalRef.componentInstance;
        instance.entity = entity;
        instance.type = type;
        modalRef.result.then(resolve, reject);
      });
    });
  }
}
