import { Injectable, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { environment } from 'environment';
import { detect } from 'detect-browser';
import { lt, coerce } from 'semver';

import { IDataItem } from '../../library-data';
import { IDataArticle } from '../../search-data';
import { ModalExtensionDownloadFailedComponent } from '../modals/modal-extension-download-failed.component';
import { ModalExtensionNotAvailableComponent } from '../modals/modal-extension-not-available.component';
import { ModalExtensionInstallComponent } from '../modals/modal-extension-install.component';

export interface IExtensionParams {
  doi: string;
  env?: string;
  pdf_url?: string;
  skip_cache?: boolean;
  skip_proxy?: boolean;
  bulk_id?: number;
}

export type ExtensionStatus = 'started' | 'downloading' | 'failed' | 'download_completed';
export type ExtensionEvent = Event & { detail: IExtensionEvent };

export interface IExtensionEvent {
  arrayBuffer?: ArrayBuffer;
  fileName?: string;
  doi: string;
  env: 'staging' | 'production';
  status: ExtensionStatus;
  error?: 'invalid_params' | 'not_found' | 'failed';
  progress?: number;
  source?: string;
}

export class ExtensionDownload {
  inProgress$ = new BehaviorSubject<boolean>(false);
  percent$ = new BehaviorSubject<number>(0);
  status$ = new ReplaySubject<ExtensionStatus>(1);
  progress$ = new ReplaySubject<IExtensionEvent>(1);
  success$ = new ReplaySubject<IExtensionEvent>(1);
  failed$ = new ReplaySubject<IExtensionEvent>(1);

  constructor(
    public params: IExtensionParams
  ) { }

  start() {
    document.dispatchEvent(new CustomEvent('rcExtDownloadInit', {
      detail: Object.assign({ env: environment.name }, this.params),
      bubbles: false
    }));
  }

  update(event: IExtensionEvent) {
    this.progress$.next(event);
    this.status$.next(event.status);

    switch (event.status) {
      case 'started':
        this.inProgress$.next(true);
        break;
      case 'downloading':
        this.percent$.next(event.progress);
        break;
      case 'failed':
        this.failed$.next(event);
        this.inProgress$.next(false);
        this.failed$.complete();
        this.percent$.complete();
        this.progress$.complete();
        this.inProgress$.complete();
        break;
      case 'download_completed':
        this.success$.next(event);
        this.inProgress$.next(false);
        this.success$.complete();
        this.percent$.complete();
        this.progress$.complete();
        this.inProgress$.complete();
        break;
    }
  }
}

@Injectable()
export class ExtensionService {
  browser = detect();
  downloads = new Map<string, ExtensionDownload>();

  constructor(
    protected zone: NgZone,
    protected modal: NgbModal,
  ) {
    document.addEventListener('rcExtDownload', (event: ExtensionEvent) => {
      this.zone.run(() => this.downloads.get(event.detail.doi)?.update(event.detail));
    });
  }

  downloadPDFWithProgress(params: IExtensionParams): Observable<IExtensionEvent> {
    return new Observable<IExtensionEvent>(observer => {
      const downloadInitEvent = new CustomEvent('rcExtDownloadInit', {
        detail: Object.assign({ env: environment.name }, params),
        bubbles: false
      });
      const eventHandlerFn = (event: ExtensionEvent) => {
        if (event.detail.doi != params.doi) return;
        observer.next(event.detail);
        switch (event.detail.status) {
          case 'failed':
            observer.error(event.detail);
            break;
          case 'download_completed':
            observer.complete();
            break;
        }
      };

      document.addEventListener('rcExtDownload', eventHandlerFn);
      document.dispatchEvent(downloadInitEvent);
      return () => {
        document.removeEventListener('rcExtDownload', eventHandlerFn);
      };
    });
  }

  downloadPDF(params: IExtensionParams): ExtensionDownload {
    const d = new ExtensionDownload(params);
    this.downloads.set(params.doi, d);
    d.start();
    return d;
  }

  getDownload(id: string): ExtensionDownload {
    return this.downloads.get(id);
  }

  getDownloadedFile(event: IExtensionEvent, fileName: string) {
    const parts = [new Uint8Array(event.arrayBuffer)];
    const blob = new Blob(parts, { type: 'application/pdf' });
    return new File([blob], event.fileName || fileName);
  }

  isInstalled(): boolean {
    const getMetaTagContent = function(name: string): string {
      const metas = document.getElementsByTagName('meta');
      for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === name) {
          return metas[i].getAttribute('content');
        }
      }
      return '';
    };
    const extId = getMetaTagContent(environment.extensionIdMetaTag);
    if (!extId) {
      return false;
    }
    const version = getMetaTagContent(environment.extensionVersionMetaTag);
    if (lt(coerce(version).version, environment.minExtensionVersion)) {
      return false;
    }
    return true;
  }

  canInstall(): boolean {
    switch (this.browser && this.browser.name) {
      case 'chrome':
        return !!environment.chromeExtensionId;
      case 'firefox':
        return !!environment.firefoxExtensionId;
      case 'edge':
        return !!environment.edgeExtensionId;
      case 'edge-chromium':
        return !!environment.edgeChromiumExtensionId;
    }
    return false;
  }

  openDownloadFailedDialog(doi: string, item?: IDataItem) {
    const modalRef = this.modal.open(ModalExtensionDownloadFailedComponent, { backdrop: true });
    const instance = <ModalExtensionDownloadFailedComponent>modalRef.componentInstance;
    instance.doi = doi;
    instance.item = item;
  }

  getFileNameFor(itemOrArticle: IDataItem | IDataArticle) {
    if ('article' in itemOrArticle) {
      const fileName = [
        ((itemOrArticle.article.authors || [])[0] || '').split(/[\s]+/).pop(),
        (itemOrArticle.article.year || ''),
        (itemOrArticle.article.title || '').split(/[,\.\s]+/)
          .filter(part => part && /^[a-z0-9]/i.test(part))[0]
      ].join('').toLowerCase();
      return `${fileName}.pdf`;
    }
    let id = '';
    const extIds = itemOrArticle.ext_ids || {};
    if (!id) id = extIds.doi;
    if (!id) id = extIds.pmcid;
    if (!id) id = extIds.pmid;
    if (!id) id = extIds[Object.keys(extIds)[0]];
    return `${(id || '').replace(/[\W]+/g, '')}.pdf`;
  }

  openInstallExtensionDialog(): Promise<void> {
    if (!this.canInstall()) {
      this.modal.open(ModalExtensionNotAvailableComponent, { backdrop: true });
      return Promise.reject();
    }
    return new Promise(resolve => {
      const modalRef = this.modal.open(ModalExtensionInstallComponent, { backdrop: true });
      const instance = <ModalExtensionInstallComponent>modalRef.componentInstance;
      instance.onInstalled = () => resolve();
    });
  }

  get webstoreURL() {
    switch (this.browser && this.browser.name) {
      case 'chrome':
        return `https://chrome.google.com/webstore/detail/${environment.chromeExtensionId}`;
      case 'firefox':
        return `https://addons.mozilla.org/en-US/firefox/addon/readcube-papers/`;
      case 'edge':
        return `https://www.microsoft.com/en-us/p/readcube-papers-anywhere-access/9nv3c77fptj9?activetab=pivot:overviewtab`;
      case 'edge-chromium':
        return `https://microsoftedge.microsoft.com/addons/detail/readcube-papers-anywher/${environment.edgeChromiumExtensionId}`;
    }
    return environment.baseUrls.papers;
  }
}
