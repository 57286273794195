import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, ReplaySubject, Observable, throwError } from 'rxjs';
import { tap, catchError, shareReplay } from 'rxjs/operators';

import { SharedService } from '../../common';
import { DataItemService, IDataItem } from '../../library-data';
import { DataSearchService, IDataArticle, IDataArticleAuthor } from '../../search-data';
import { PreviewService, ISelectionMessage } from '../../library-preview';
import { AppShellService } from '../../app-shell.service';
import { LibrarySidepanelService } from '../services/library-sidepanel.service';

const msgTypeToFieldMap = {
  'Title': { name: 'title' },
  'Authors': { name: 'authors' },
  'Journal': { name: 'journal' },
  'Year': { name: 'year' },
  'DOI': { name: 'doi' },
  'PMID': { name: 'pmid' },
  'ISBN': { name: 'isbn' },
  'Patent ID': { name: 'patent_id' }
};

const CONFIRM_MATCH_MODAL_TITLE = 'Confirm match';
const CONFIRM_MATCH_MODAL_TEXT = 'Are you sure you want to auto-complete your reference with selected result?';

@Component({
  templateUrl: './library-sidepanel-resolve.component.html',
  styleUrls: [
    './library-sidepanel.component.scss',
    './library-sidepanel-resolve.component.scss'
  ]
})
export class LibrarySidepanelResolveComponent implements OnInit, OnDestroy {
  item: IDataItem;
  form: FormGroup;
  error: string;
  resultCount: number;

  formType = '';
  formTypes = [{
    key: 'journal_article',
    value: 'Journal Article'
  }, {
    key: 'patent',
    value: 'Patent'
  }];

  itemSub: Subscription;
  selectionSub: Subscription;
  valueChangeSub: Subscription;

  results$: Observable<IDataArticle[]>;
  loading$ = new ReplaySubject<boolean>(1);

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public sidepanel: LibrarySidepanelService,
    public preview: PreviewService,
    public dataItem: DataItemService,
    public dataSearch: DataSearchService,
    public shared: SharedService,
    public shell: AppShellService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.selectionSub = this.preview.selection$.subscribe(msg => {
      if (msg.type === 'marked' && this.form) {
        this.onTextSelected(msg);
      } else if (msg.type === 'title-found' && this.form) {
        this.onTitleFound(msg);
      }
    });

    this.itemSub = this.sidepanel.item$.subscribe(item => {
      if (!this.formType) {
        this.formType = 'journal_article';
      }
      this.item = item;
      this.createFormGroup(item, this.formType);
    });
  }

  canUsePatent() {
    return !!this.shared.user?.licence.patent_support;
  }

  ngOnDestroy() {
    this.itemSub.unsubscribe();
    this.selectionSub.unsubscribe();
    if (this.valueChangeSub) {
      this.valueChangeSub.unsubscribe();
    }
  }

  onFormTypeChange(formType: string) {
    this.createFormGroup(this.item, formType);
    this.preview.showResolve(this.route.parent, this.item, formType);
  }

  createFormGroup(item: IDataItem, formType: string) {
    this.formType = formType;
    if (formType === 'journal_article') {
      this.form = new FormGroup({
        'title': new FormControl({
          value: item.article.title,
          disabled: false
        }, []),
        'authors': new FormControl({
          value: item.article.authors?.join(', '),
          disabled: false
        }, []),
        'journal': new FormControl({
          value: item.article.journal,
          disabled: false
        }, []),
        'year': new FormControl({
          value: item.article.year,
          disabled: false
        }, [
          Validators.pattern(/^[1-9][0-9]{0,3}$/)
        ]),
        'doi': new FormControl({
          value: item.ext_ids.doi,
          disabled: false
        }, [
          Validators.pattern(/^10\.\d{3,5}\/[^\s]+$/),
        ]),
        'pmid': new FormControl({
          value: item.ext_ids.pmid,
          disabled: false
        }, [
          Validators.pattern(/[0-9 $]+/)
        ]),
        'isbn': new FormControl({
          value: item.article.isbn,
          disabled: false
        }, [
          Validators.pattern(/(ISBN[-]*(1[03])*[ ]*(: ){0,1})*(([0-9Xx][- ]*){13}|([0-9Xx][- ]*){10})/)
        ])
      });
    } else if (formType === 'patent') {
      this.form = new FormGroup({
        'patent_id': new FormControl({
          value: item.ext_ids.patent_id,
          disabled: false
        }, [])
      });
    }
    if (this.valueChangeSub) {
      this.valueChangeSub.unsubscribe();
    }
    this.valueChangeSub = this.form.valueChanges.subscribe(() => {
      this.resultCount = null;
    });
  }

  submit() {
    const data = {};
    for (let key in this.form.value) {
      data[key] = this.form.value[key];
    }
    this.loading$.next(true);
    this.results$ = this.dataSearch.query({
      query: this.createSearchQuery(data),
      size: 5
    }).pipe(
      tap(() => this.loading$.next(false)),
      catchError(this.handleError),
      tap(results => this.resultCount = results.length),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  confirmMatch(article: IDataArticle) {
    this.shell.openConfirm({
      title: CONFIRM_MATCH_MODAL_TITLE,
      message: CONFIRM_MATCH_MODAL_TEXT
    }, () => {
      return this.dataItem.updateDetails(this.item.collection_id, this.item.id, article.ext_ids);
    }).then((item: IDataItem) => {
      if (!item) return;

      if (this.item.id !== item.id) {
        this.dataItem.reloadRows({ collectionId: this.item.collection_id });
        this.router.navigateByUrl(`/library/${this.item.collection_id}/all/(sidepanel:details)?item_id=${item.id}&collection_id=${this.item.collection_id}`);
        return;
      }

      this.router.navigate([{
        outlets: {
          'sidepanel': 'details',
          'bottompanel': null
        }
      }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge'
      });
    });
  }

  clearResults() {
    this.resultCount = null;
    this.results$ = null;
  }

  getAuthorNames(authors: IDataArticleAuthor[]): string {
    return authors?.map(a => a.name).join(', ');
  }

  createSearchQuery(data: any): string {
    const query = [];
    if (data.patent_id && data.patent_id.replace) {
      data.patent_id = data.patent_id.replace(/,/g, '');
      data.patent_id = data.patent_id.replace(/\n/g, '');

      query.push(`patent_id:${data.patent_id}`);
    }
    if (data.doi || data.pmid || data.isbn) {
      if (data.doi) {
        query.push(`doi:"${data.doi}"`);
      }
      if (data.isbn) {
        query.push(`isbn:${data.isbn}`);
      }
      if (data.pmid) {
        query.push(`pmid:${data.pmid}`);
      }
      return query.join(' OR ');
    }
    const r = /[^\w\s]/gi;
    let tmp = '';
    if (data.title) {
      tmp += `${data.title.replace(r, ' ').trim()}`;
    }
    if (data.authors) {
      tmp += ` ${data.authors.replace(r, ' ').trim()}`;
    }
    if (data.journal) {
      tmp += ` ${data.journal.replace(r, ' ').trim()}`;
    }
    if (tmp) {
      query.push(tmp);
    }
    if (data.year) {
      query.push(`year:${parseInt(data.year, 10)}`);
    }
    return query.join(' AND ');
  }

  onTextSelected(msg: ISelectionMessage) {
    const field = msgTypeToFieldMap[msg.data.type];
    if (!field) {
      return;
    }
    const ctrl = this.form.controls[field.name];
    if (!ctrl) {
      return;
    }
    let value = msg.data.text.trim();

    if (['DOI', 'PMID', 'ISBN'].includes(msg.data.type)) {
      value = value.replace(/\r?\n|\r/g, '');
    }

    if (field.concat) {
      const arr = ctrl.value.concat([value]);
      ctrl.setValue(arr);
    } else {
      ctrl.setValue(value);
    }
    this.changeDetectorRef.detectChanges();
  }

  onTitleFound(msg: ISelectionMessage) {
    const ctrl = this.form.controls['title'];
    const value = msg.data?.trim();
    if (ctrl && value) {
      ctrl.setValue(value);
      this.submit();
    }
  }

  protected handleError(err: any): Observable<any> {
    this.error = err;
    return throwError(err);
  }
}
